<template>
  <nav class="z-depth-0 accessibility-menu">
    <div class="nav-wrapper">
      <ul>
        <li>
          <a
            href="#!"
            accesskey="1"
            title="Ativar/Desativar alto-contraste"
            v-on:click="toggleContrast"
          >
            <i class="material-icons">invert_colors</i>
          </a>
        </li>
        <li>
          <a
            href="#!"
            accesskey="2"
            title="Aumentar tamanho da fonte"
            v-on:click="increaseFontSize"
            >A+</a
          >
        </li>
        <li>
          <a
            href="#!"
            accesskey="3"
            title="Diminuir tamanho da fonte"
            v-on:click="decreaseFontSize"
            >A-</a
          >
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import AccessibilityService from "../services/Accessibility";

export default {
  name: "TheAccessibilityMenu",
  inheritAttrs: false,
  mounted() {
    AccessibilityService.applyContrastState().applyFontSizeDelta();
  },
  methods: {
    decreaseFontSize() {
      AccessibilityService.decreaseFontSize(1);
    },
    increaseFontSize() {
      AccessibilityService.increaseFontSize(1);
    },
    toggleContrast() {
      AccessibilityService.toggleContrast();
    }
  }
};
</script>

<style scoped>
.accessibility-menu {
  background-color: inherit;
}
.accessibility-menu ul {
  display: flex;
  justify-content: space-evenly;
}
.accessibility-menu a i.material-icons {
  color: inherit;
}
</style>
