<template>
  <section class="section">
    <component v-bind:is="tag" class="pae-title pae-header">
      <i class="material-icons left">video_library</i>Mídia externa
    </component>

    <p v-if="!hasUrl" class="flow-text center">
      Nenhuma URL para mídia externa foi definida.
    </p>
    <p v-else-if="!isEmbeddableUrl" class="flow-text center">
      Acesse <a v-bind:href="value" target="_blank">aqui</a> a mídia externa.
    </p>
    <div v-else class="video-container center">
      <iframe v-bind="embedded" />
    </div>
  </section>
</template>

<script>
import { ConteudoService } from "../../../services";

export default {
  name: "SectionConteudoMidiaExterna",
  inheritAttrs: false,
  props: {
    tag: {
      type: String,
      default: "h4",
      validator: value =>
        ["h1", "h2", "h3", "h4", "h5", "h6"].indexOf(value) !== -1
    },

    value: String
  },
  computed: {
    hasUrl() {
      return this.value;
    },
    isEmbeddableUrl() {
      return ConteudoService.isEmbeddableUrl(this.value);
    },
    embedded() {
      return ConteudoService.buildEmbeddedContent(this.value);
    }
  }
};
</script>
