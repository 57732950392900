/**
 * @module router
 * @category Gerenciador de rotas
 * @summary Módulo do gerenciador de rotas do sistema.
 *
 * @description
 * Expõe uma instância de {@link external:VueRouter VueRouter} contendo as
 * configurações de rota ({@link external:RouteConfig RouteConfig}) do sistema.
 */
import Vue from "vue";
import VueRouter from "vue-router";

import ControleAcesso from "../views/ControleAcesso.vue";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import CadastroSenha from "../views/CadastroSenha.vue";
import Eventos from "../views/Eventos.vue";
import EmptyContainer from "../views/EmptyContainer.vue";
import Evento from "../views/Evento.vue";
import Estande from "../views/Estande.vue";
import GestaoEvento from "../views/GestaoEvento.vue";
import GestaoEstande from "../views/GestaoEstande.vue";
import GestaoConteudoProgramado from "../views/GestaoConteudoProgramado.vue";
import GestaoConteudoExtra from "../views/GestaoConteudoExtra.vue";

Vue.use(VueRouter);

/**
 * _Array_ de configurações de rotas do container do sistema.
 * @type {Array<external:RouteConfig>}
 * @readonly
 */
const routes = [
  {
    path: "",
    name: "empty",
    component: Home
  },
  {
    path: "/home",
    name: "home",
    component: Home
  },
  {
    path: "/administrativo",
    name: "admin",
    component: EmptyContainer,
    children: [
      {
        path: "",
        name: "adminEmpty",
        redirect: { name: "home" }
      },
      {
        path: "controle-acesso",
        name: "controleAcesso",
        component: ControleAcesso,
        meta: {
          title: "Controle de Acesso",
          permissions: ["GESTAO_SISTEMA"]
        }
      },
      {
        path: "eventos",
        name: "adminEventos",
        component: Eventos,
        meta: {
          title: "Gestão de Eventos",
          permissions: ["GESTAO_EVENTO", "GESTAO_ESTANDE"]
        }
      },
      {
        path: "eventos/:idEvento?",
        component: EmptyContainer,
        children: [
          {
            path: "",
            name: "editEvento",
            component: GestaoEvento,
            meta: {
              title: "Gestão do Evento",
              permissions: ["GESTAO_EVENTO", "GESTAO_ESTANDE"]
            },
            props: true
          },
          {
            path: "estandes/:idEstande?",
            component: EmptyContainer,
            children: [
              {
                path: "",
                name: "editEstande",
                component: GestaoEstande,
                meta: {
                  title: "Gestão de Estande",
                  permissions: ["GESTAO_EVENTO", "GESTAO_ESTANDE"]
                }
              },
              {
                path: "conteudos-programados/:idConteudo?",
                name: "editConteudoProgramado",
                component: GestaoConteudoProgramado,
                meta: {
                  title: "Gestão de Conteúdo Programado",
                  permissions: ["GESTAO_EVENTO", "GESTAO_ESTANDE"]
                }
              },
              {
                path: "conteudos-extras/:idConteudo?",
                name: "editConteudoExtra",
                component: GestaoConteudoExtra,
                meta: {
                  title: "Gestão de Conteúdo Extra",
                  permissions: ["GESTAO_EVENTO", "GESTAO_ESTANDE"]
                }
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      title: "Login"
    }
  },
  {
    path: "/senha",
    name: "senha",
    component: CadastroSenha,
    meta: {
      title: "Cadastro de Senha"
    },
    props: route => ({ token: route.query.token })
  },
  {
    path: "/eventos",
    name: "listEventos",
    component: Eventos,
    meta: {
      title: "Eventos"
    }
  },
  {
    path: "/eventos/:slugEvento",
    component: EmptyContainer,
    children: [
      {
        path: "",
        name: "viewEvento",
        component: Evento
      },
      {
        path: "estandes/:slugEstande",
        name: "viewEstande",
        component: Estande
      }
    ]
  }
];

/**
 * Estrutura com as opções utilizadas pelo gerenciador de rotas do sistema
 * ({@link external:VueRouter VueRouter}).
 * @type {object}
 * @property {string} mode - Modo de utilização do histórico do browser. Valor
 * definido: "history".
 * @property {Array<external:RouteConfig>} routes - {@link module:router~routes}
 * @readonly
 */
const routerOptions = {
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    let position = null;

    if (savedPosition) {
      position = savedPosition;
    } else {
      let wrapper = null;

      if (to.hash) {
        //position = { selector: to.hash };
        wrapper = document.getElementById(to.hash.substring(1));
      } else {
        //position = { x: 0, y: 0, behavior: "auto" };
        wrapper = document.getElementById("pae-wrapper");
      }

      if (wrapper) {
        wrapper.scrollIntoView({ behavior: "auto" });
      }
    }

    return position;
  }
};

export default new VueRouter(routerOptions);
