/**
 * @module services/Apresentador
 * @category Serviços
 * @summary Módulo de importação do serviço de apresentadores.
 *
 * @description
 * Expõe o serviço de apresentadores do sistema para utilização interna.
 *
 * @example <caption>Importando o serviço de apresentadores como membro default do módulo</caption>
 * import ApresentadorService from "./services/Apresentador";
 *
 * @example <caption>Importando o serviço de apresentadores como membro do módulo</caption>
 * import { ApresentadorService } from "./services/Apresentador";
 */
import { default as ApresentadorService } from "./Apresentador";

export default ApresentadorService;

export { ApresentadorService };
