<template>
  <div v-bind:class="gridExpression">
    <div class="file-field input-field">
      <BaseButton
        v-bind:has-waves="false"
        icon-name="file_upload"
        v-bind:disabled="disabled"
        v-on:click="openFileChooser"
      >
        {{ buttonContent }}
        <input
          ref="fileInput"
          type="file"
          v-bind:id="id"
          v-bind:name="name"
          v-bind:disabled="disabled"
          v-bind:required="required"
          v-bind:accept="accept"
          v-bind:multiple="multiple"
          v-on="listeners"
          tabindex="-1"
        />
      </BaseButton>
      <div class="file-path-wrapper">
        <input
          ref="textInput"
          type="text"
          class="file-path"
          v-bind:disabled="disabled"
          v-bind:class="{ invalid: errorMessage }"
          v-bind:placeholder="placeholder"
          tabindex="-1"
        />
        <a
          v-if="!disabled"
          href="#!"
          class="clear-btn"
          title="Limpar campo"
          v-on:click.prevent="clear"
        >
          <i class="material-icons">close</i>
        </a>
      </div>
      <span
        v-if="errorMessage"
        class="helper-text"
        v-bind:class="{ invalid: errorMessage }"
        v-bind:data-error="errorMessage"
      >
      </span>

      <div v-if="hasFiles" class="selected-files-wrapper">
        <span
          v-for="(item, index) in selectedFiles"
          v-bind:key="index"
          class="selected-file left-align"
        >
          <b>Selecionado:</b> {{ item }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @module components/base/BaseFileInput/BaseFileInput
 * @category Componentes-base
 * @summary _Single File Component_ (SFC) de _file_ _input_ do {@link external:Materialize Materialize}.
 *
 * @description
 * Este componente possui um elemento HTML `div` com classe `input-field` do
 * {@link external:Materialize Materialize}, e, externamente, como _wrapper_,
 * outra `div` com as configurações de responsividade definidas por
 * `gridExpression`.
 *
 * Aleḿ disso, o componente não realiza o _upload_ do(s) arquivo(s), delegando
 * essa tarefa para o componente exterior.
 *
 * Este componente não possui _slot_ _default_.
 *
 * @requires module:utils/lang.isEmpty
 *
 * @vue-prop {string} [gridExpression="col s12"] - Classes CSS de grid do {@link external:Materialize Materialize}.
 * @vue-prop {string} [id] - Atributo `id` do elemento HTML utilizado
 * internamente pelo componente.
 * @vue-prop {string} [name] - Atributo `name` do elemento HTML utilizado
 * internamente pelo componente.
 * @vue-prop {boolean} [disabled=false] - Indica se o componente estará
 * desabilitado.
 * @vue-prop {boolean} [required=false] - Indica se o componente possui
 * preenchimento obrigatório.
 * @vue-prop {boolean} [validate=false] - Indica se o componente fará a
 * validação do conteúdo preenchido.
 * @vue-prop {boolean} [multiple=false] - Indica se o componente permite
 * seleção múltipla de arquivos.
 * @vue-prop {string} [placeholder] - Atributo `placeholder` do elemento HTML
 * utilizado internamente pelo componente.
 * @vue-prop {string} [accept] - Indica as extensões e tipos MIME que o
 * componente permitirá para seleção.
 * @vue-prop {number} [size=10485760] - Indica, em _bytes_, o tamanho máximo
 * permitido para cada arquivo selecionado. Aceita números positivos. Valor
 * padrão equivalente a 10MiB.
 * @vue-prop {string} [buttonContent="Arquivo"] - Texto exibido no botão de
 * escolha de arquivo.
 * @vue-event {external:FileList} onInput - Emite o valor atual do componente ou `null`.
 * Pode ser capturado pela diretiva `v-on:input` (ou `v-model`) do {@link external:Vue Vue}.
 * @vue-event {external:FileList} onChange - Emite o valor atual do componente ou `null`.
 * Pode ser capturado pela diretiva `v-on:change` do {@link external:Vue Vue}.
 *
 * @example
 * <BaseFileInput
 *  id="usuario-foto"
 *  name="usuario_foto"
 *  button-content="Imagem"
 *  placeholder="Selecione uma imagem para o usuário"
 *  v-model="user.foto" />
 */
import { isEmpty } from "../../../utils/lang";

const DEFAULT_SIZE = 10 * 1024 * 1024;

export default {
  name: "BaseFileInput",
  inheritAttrs: false,
  props: {
    gridExpression: {
      type: String,
      default: "col s12"
    },

    id: String,
    name: String,

    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    validate: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },

    placeholder: String,
    accept: String,

    size: {
      type: Number,
      default: DEFAULT_SIZE,
      validator: value => value > 0
    },
    buttonContent: {
      type: String,
      default: "Arquivo"
    }
  },
  data() {
    return {
      files: null,
      errorMessage: null
    };
  },
  computed: {
    hasFiles() {
      return !isEmpty(this.files);
    },
    selectedFiles() {
      return Array.from(this.files || []).map(
        item => `${item.name} (${item.type}, ${item.size} bytes)`
      );
    },
    listeners() {
      return Object.assign({}, this.$listeners, {
        change: this.onChange,
        // É necessário um evento "dummy" para que outros disparos da tag input
        // não gerem problemas em componentes que utilizam o BaseFileInput
        input: () => {}
      });
    }
  },
  methods: {
    clear() {
      this.$refs.fileInput.value = null;
      this.$refs.textInput.value = null;
      this.errorMessage = null;
      this.files = null;

      this.$emit("input", this.files);
      this.$emit("change", this.files);
    },
    openFileChooser() {
      this.$refs.fileInput.click();
    },
    validateFiles() {
      const errors = [];

      const files = Array.from(this.$refs.fileInput.files).map(item => ({
        name: item.name,
        size: item.size,
        type: item.type
      }));

      if (this.size) {
        if (files.findIndex(item => item.size > this.size) !== -1) {
          errors.push(
            new Error(
              `Algum arquivo está excedendo o tamanho permitido (${this.size} bytes)`
            )
          );
        }
      }

      return errors;
    },
    onChange() {
      const errors = this.validate ? this.validateFiles() : null;

      if (errors && errors.length) {
        this.clear();

        this.errorMessage = errors.map(error => error.message).join("; ");
      } else {
        this.errorMessage = null;

        this.files = this.$refs.fileInput.files;

        this.$emit("input", this.files);
        this.$emit("change", this.files);
      }
    }
  }
};
</script>

<style scoped>
.file-field {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.file-field .file-path-wrapper {
  flex: 1 0 auto;
  display: flex;
  position: relative;
}
.file-field input[type="file"] {
  height: 3rem;
}

.file-path.invalid {
  border-bottom: 1px solid #f44336;
  box-shadow: 0 1px 0 0 #f44336;
}

.input-field /deep/ i.material-icons {
  height: 3rem;
  line-height: 3rem;
}

.helper-text.invalid::after {
  content: attr(data-error);
  color: #f44336;
}
.helper-text + .selected-files-wrapper {
  margin-top: 8px;
}

.clear-btn {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 1rem;
  color: inherit;
}

.selected-files-wrapper {
  flex: 1 0 100%;
}

.selected-file {
  display: block;
  font-size: 0.8rem;
}

@media screen and (max-width: 600px) {
  .file-field /deep/ .btn {
    flex: 1 0 auto;
  }
  .file-field .file-path-wrapper {
    margin-top: 8px;
    padding-left: 0;
  }
}

.contrast .input-field /deep/ i.material-icons {
  background-color: transparent !important;
}
</style>
