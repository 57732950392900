<template>
  <span
    v-bind:id="id"
    class="chip"
    v-bind:title="$attrs.title"
    v-bind:tabindex="$attrs.tabindex"
    v-on:click="selectChip"
    v-on:keydown.enter.self="selectChip"
    v-on:keydown.delete.self="deleteChip"
  >
    <img v-if="image" v-bind="image" />
    <slot>{{ content }}</slot>
    <i
      v-if="isCloseable"
      class="close material-icons"
      v-on:click.stop="deleteChip"
      >close</i
    >
  </span>
</template>

<script>
/**
 * @module components/base/BaseChip/BaseChip
 * @category Componentes-base
 * @summary _Single File Component_ (SFC) de _chip_ do {@link external:Materialize Materialize}.
 *
 * @description
 * A cor de seleção do _chip_ é obtida automaticamente a partir da configuração
 * do ambiente.
 *
 * O _slot_ _default_ recebe o conteúdo/_label_ do _chip_.
 *
 * Atributos HTML de acessibilidade, e.g., `title`, podem ser usados
 * diretamente no componente.
 *
 * @requires module:constants.DEFAULT_COLOR
 * @requires module:constants.DEFAULT_LUMINOSITY
 * @requires module:utils/web.createStyleElement
 * @requires module:utils/web.getMaterializeColorCode
 *
 * @vue-prop {string} [id] - Atributo `id` do elemento HTML utilizado
 * internamente pelo componente.
 * @vue-prop {boolean} [isCloseable=true] - Indica se o componente exibirá o
 * ícone de fechar.
 * @vue-prop {string} [content] - Conteúdo a ser exibido no _chip_. É usado
 * como substituto quando nenhum dado é passado através do _slot_ _default_.
 * @vue-prop {object} [image] - Objeto com os atributos do elemento HTML `img`
 * (`src`, `alt`, `title`, etc) para compor a imagem do _chip_.
 * @vue-event {external:Event} onSelect - Emite o evento de click ou de
 * pressionamento de tecla quando o _chip_ é selecionado. O pressionamento é
 * acionado através da tecla _enter_ quando o _chip_ está em foco.
 * @vue-event {external:Event} onDelete - Emite o evento de click ou de
 * pressionamento de tecla quando o _chip_ é excluído. O click é acionado
 * através do ícone de fechar. O pressionamento é acionado através das teclas
 * _delete_ ou _backspace_ quando o _chip_ está em foco.
 *
 * @example
 * <BaseChip id="hashtag-1234" />#javascript</BaseChip>
 */
import { DEFAULT_COLOR, DEFAULT_LUMINOSITY } from "../../../constants";
import {
  createStyleElement,
  getMaterializeColorCode
} from "../../../utils/web";

export default {
  name: "BaseChip",
  inheritAttrs: false,
  props: {
    id: String,

    isCloseable: {
      type: Boolean,
      default: true
    },

    content: String,
    image: Object
  },
  created() {
    this.overrideMaterializeStyle();
  },
  methods: {
    overrideMaterializeStyle() {
      const colorCode = getMaterializeColorCode(
        DEFAULT_COLOR,
        DEFAULT_LUMINOSITY
      );

      if (colorCode) {
        let CSSStyleSheet = `
          .chip:focus {
            background-color: ${colorCode};
          }
        `;

        createStyleElement({
          id: "stylesheet-chip",
          type: "text/css",
          innerHTML: CSSStyleSheet
        });
      }
    },
    selectChip($event) {
      this.$emit("select", $event);
    },
    deleteChip($event) {
      this.$emit("delete", $event);
    }
  }
};
</script>

<style scoped>
.contrast .chip {
  background-color: black;
  border: 1px solid white;
  color: black;
}
.contrast .chip:focus {
  background-color: white;
  border: none;
  color: black !important;
}
.contrast .chip i.material-icons {
  background-color: transparent !important;
  color: inherit !important;
}
</style>
