/**
 * @module directives
 * @category Diretivas
 * @summary Módulo de importação das diretivas do sistema.
 *
 * @description
 * Expõe as diretivas do sistema para utilização pelo container e pelos
 * módulos.
 *
 * Este módulo funciona como um _plug-in_ do {@link external:Vue Vue}, permitindo
 * a importação de todas as diretivas do sistema. Opcionalmente, é
 * possível importar cada diretiva individualmente.
 *
 * Cada diretiva também funciona como um _plug-in_ do {@link external:Vue Vue},
 * permitindo sua importação, associando-o ao objeto `Vue` global, ou
 * importando-a para uso interno em outro componente.
 *
 * Nos casos em que o {@link external:Vue Vue} é carregado através de um
 * elemento HTML `script`, este módulo automaticamente importa as diretivas
 * para o objeto `Vue` global.
 *
 * @example <caption>Importando somente o Scrollspy</caption>
 * import { Scrollspy } from "./directives/base";
 *
 * Vue.use(Scrollspy);
 *
 * @example <caption>Importando todas as diretivas</caption>
 * import directives from "./directives";
 *
 * Vue.use(directives);
 */
import { default as Autofocus } from "./Autofocus";
import { default as Pushpin } from "./Pushpin";
import { default as Scrollspy } from "./Scrollspy";

const directives = {
  Autofocus,
  Pushpin,
  Scrollspy
};

/**
 * @summary Associa todas as diretivas do sistema à instância do {@link external:Vue Vue}.
 * @param {external:Vue} Vue - Instância do {@link external:Vue Vue}.
 * @param {object} options - Objeto cujas propriedades são os nomes das
 * diretivas e os valores são as opções para cada diretiva.
 */
const install = function(Vue, options = {}) {
  Object.keys(directives).forEach(name => {
    // Vue.directive(directives[name].name, options[name]);
    Vue.use(directives[name], options[name]);
  });
};

if (window && window.Vue) {
  install(window.Vue);
}

export default install;

export { Autofocus } from "./Autofocus";
export { Pushpin } from "./Pushpin";
export { Scrollspy } from "./Scrollspy";
