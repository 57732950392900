<template>
  <div ref="scrollspy" class="scrollspy-wrapper">
    <slot></slot>
  </div>
</template>

<script>
/**
 * @module components/base/BaseScrollspy/BaseScrollspy
 * @category Componentes-base
 * @summary _Single File Component_ (SFC) de _scrollspy_ do {@link external:Materialize Materialize}.
 *
 * @description
 * Este componente ativa a funcionalidade de _scrollspy_ do {@link external:Materialize Materialize}
 * em todos os elementos HTML que possuírem a classe CSS "scrollspy" que
 * componham o conteúdo do componente.
 *
 * O _slot_ _default_ recebe os elementos HTML que contenham a classe
 * "scrollspy".
 *
 * Para correto funcionamento, é necessário que o _framework_ {@link external:Materialize Materialize}
 * tenha sido importado, tornando seu objeto principal acessível globalmente.
 *
 * @vue-prop {object} [scrollspyOptions] - Opções aceitas pelo _scrollspy_ do {@link external:Materialize Materialize}.
 *
 * @example
 * <BaseScrollspy>
 *  <div class="section scrollspy">
 *    ...
 *  </div>
 *  ...
 * </BaseScrollspy>
 */
export default {
  name: "BaseScrollspy",
  inheritAttrs: false,
  props: {
    scrollspyOptions: Object
  },
  data() {
    return {
      instance: null
    };
  },
  beforeCreate() {
    if (!window.M) {
      window.console.error(
        "Objeto do Materialize não encontrado. Verifique se essa biblioteca foi carregada corretamente"
      );
    }
  },
  mounted() {
    if (!window.M) {
      return;
    }

    const scrollspies = this.$refs.scrollspy.querySelectorAll(".scrollspy");

    this.instance = window.M.ScrollSpy.init(scrollspies, this.scrollspyOptions);
  },
  beforeDestroy() {
    (this.instance || []).forEach(item => item.destroy());
  }
};
</script>
