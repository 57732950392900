<template>
  <section v-bind:id="id" class="section">
    <h4>
      <i class="material-icons left">{{ iconName }}</i>
      {{ title }}
    </h4>

    <BaseInfoCard title="Gerência de usuários">
      <p>
        Para realizar a busca por usuários, pode-se, opcionalmente, preencher o
        filtro abaixo com dados da pessoa (servidor ou funcionário) responsável
        pelo usuário do sistema. Em seguida, clique no botão "Buscar". Uma lista
        paginada será exibida com os resultados possíveis. Após a identificação
        do usuário desejado, pode-se alterar suas permissões clicando no botão
        de edição correspondente.
      </p>
      <p>
        Para cadastro de usuários no sistema, primeiramente, verifique se a
        pessoa (servidor ou funcionário) responsável já foi criada no sistema
        realizando uma busca. Caso ela não tenha sido criada, clique no botão
        "Nova". Um modal será aberto contendo o formulário de dados da pessoa
        responsável pelo usuário. Por enquanto, apenas usuários externos (e.g.,
        funcionários terceirizados da UFRJ) podem ser criados manualmente, já
        que usuários de servidores são criados automaticamente durante seu
        primeiro acesso via intranet da UFRJ. Após preenchimento do formulário,
        clique no botão "Salvar" para que a pessoa seja criada. Quando a pessoa
        é previamente cadastrada, você pode criar um novo usuário clicando no
        botão "Novo" que aparece junto aos dados da pessoa identificada após a
        busca. Você pode também editar os dados pessoais dela clicando no botão
        correspondente.
      </p>
    </BaseInfoCard>

    <div class="row">
      <BaseTextInput v-bind="form.nome" v-model="form.nome.value" />
      <BaseTextInput v-bind="form.cpf" v-model="form.cpf.value" />
      <BaseTextInput v-bind="form.passaporte" v-model="form.passaporte.value" />

      <div class="col s12 buttons-row">
        <BaseButton v-bind="form.buttonAddPessoa" v-on:click="addPessoa" />
        <BaseButton v-bind="form.buttonSearch" v-on:click="search" />
      </div>
    </div>

    <div v-if="searched">
      <BaseProgressBar v-if="loading" v-bind="progressBar"
        >Carregando pessoas e seus usuários...</BaseProgressBar
      >
      <p v-else-if="!hasPessoas" class="flow-text center">
        Não foram encontradas pessoas (e seus usuários) para os parâmetros
        informados.
      </p>
      <div v-else class="usuarios-list">
        <BaseCollection>
          <template v-slot:items>
            <li
              v-for="(item, index) in pessoaCollection"
              v-bind:key="index"
              class="collection-item"
            >
              <BaseBuscaPessoaItem v-bind="item" v-on="listeners" />
            </li>
          </template>
        </BaseCollection>

        <BasePagination
          class="center"
          v-bind="pagination"
          v-on:change="onPageChange"
        />
      </div>
    </div>

    <PessoaModal
      v-bind="pessoaModal"
      v-bind:tipos-nacionalidade="tiposNacionalidade"
      v-bind:escolaridades="escolaridades"
    />
    <AddUsuarioConfirmModal
      v-bind="addUsuarioConfirmModal"
      v-on:confirm="onConfirmAddUsuario"
    />
    <UsuarioPermissoesModal v-bind="usuarioPermissoesModal" />
    <ResetSenhaConfirmModal
      v-bind="resetSenhaConfirmModal"
      v-on:confirm="onConfirmResetSenha"
    />
  </section>
</template>

<script>
import {
  EscolaridadeService,
  PermissaoService,
  SisPessoalService,
  PessoaService,
  UsuarioService
} from "../../services";
import PessoaModal from "./PessoaModal.vue";
import AddUsuarioConfirmModal from "./AddUsuarioConfirmModal.vue";
import UsuarioPermissoesModal from "./UsuarioPermissoesModal.vue";
import ResetSenhaConfirmModal from "./ResetSenhaConfirmModal.vue";
import BaseBuscaPessoaItem from "./BaseBuscaPessoaItem.vue";

const SEARCH_PAGINATION_SIZE = 5;

export default {
  name: "Usuarios",
  components: {
    BaseBuscaPessoaItem,
    PessoaModal,
    AddUsuarioConfirmModal,
    UsuarioPermissoesModal,
    ResetSenhaConfirmModal
  },
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      required: true
    },

    title: String,
    iconName: String
  },
  data() {
    return {
      loading: false,
      searched: false,
      pessoaModal: {
        id: "pae-controleacesso-pessoa-modal",
        value: {}
      },
      addUsuarioConfirmModal: {
        id: "pae-controleacesso-addusuario-confirmmodal",
        value: {}
      },
      usuarioModal: {
        id: "pae-controleacesso-usuario-modal",
        value: {}
      },
      usuarioPermissoesModal: {
        id: "pae-controleacesso-usuariopermissoes-modal",
        permissoes: [],
        value: {}
      },
      resetSenhaConfirmModal: {
        id: "pae-controleacesso-resetsenha-confirmmodal",
        value: {}
      },
      progressBar: {
        useContainer: false
      },
      pagination: {
        value: 1,
        numPages: 0
      },
      form: {
        nome: {
          id: "pae-controleacesso-usuario-nome",
          label: "Nome/Nome social",
          placeholder:
            "Preencha um trecho do nome/nome social da pessoa responsável",
          gridExpression: "col s12",
          value: null
        },
        cpf: {
          id: "pae-controleacesso-usuario-cpf",
          label: "CPF",
          gridExpression: "col s12 xl6",
          value: null
        },
        passaporte: {
          id: "pae-controleacesso-usuario-passaporte",
          label: "Passaporte",
          gridExpression: "col s12 xl6",
          value: null
        },
        buttonAddPessoa: {
          iconName: "person_add",
          content: "Nova"
        },
        buttonSearch: {
          iconName: "search",
          content: "Buscar"
        }
      },
      pessoaCollection: [],
      tiposNacionalidade: [],
      escolaridades: []
    };
  },
  computed: {
    hasPessoas() {
      return !this.$utils.Lang.isEmpty(this.pessoaCollection);
    },
    listeners() {
      const listeners = {};

      listeners.edit = this.onEdit;
      listeners["add-usuario"] = this.onAddUsuario;
      listeners["reset-senha"] = this.onResetSenha;
      listeners["edit-permissoes"] = this.onEditPermissoes;

      return listeners;
    }
  },
  created() {
    this.getPermissoes();
    this.getTiposNacionalidade();
    this.getEscolaridades();
  },
  methods: {
    getPermissoes() {
      this.usuarioPermissoesModal.permissoes = [];

      PermissaoService.getAll()
        .then(
          response =>
            (this.usuarioPermissoesModal.permissoes = response.data || [])
        )
        .catch(error =>
          this.$notification.pushError(
            `Houve um erro ao obter a lista de permissões: ${error.message}`
          )
        );
    },
    getTiposNacionalidade() {
      this.tiposNacionalidade = [];

      SisPessoalService.getTiposNacionalidade()
        .then(response => (this.tiposNacionalidade = response.data || []))
        .catch(error =>
          this.$notification.pushError(
            `Houve um erro ao obter os tipos de nacionalidade: ${error.message}. Algumas funcionalidades poderão não funcionar corretamente.`
          )
        );
    },
    getEscolaridades() {
      this.escolaridades = [];

      EscolaridadeService.getAll()
        .then(response => (this.escolaridades = response.data || []))
        .catch(error =>
          this.$notification.pushError(
            `Houve um erro ao obter os níveis de escolaridade: ${error.message}. Algumas funcionalidades poderão não funcionar corretamente.`
          )
        );
    },
    onEdit(event) {
      const pessoa = this.pessoaCollection.find(item => item.id === event);

      if (pessoa) {
        this.pessoaModal.value = pessoa;

        this.$nextTick().then(() =>
          this.$utils.Components.openModal(this.pessoaModal.id)
        );
      }
    },
    onAddUsuario(event) {
      const pessoa = this.pessoaCollection.find(item => item.id === event);

      if (pessoa) {
        this.addUsuarioConfirmModal.value = pessoa;

        this.$nextTick().then(() =>
          this.$utils.Components.openModal(this.addUsuarioConfirmModal.id)
        );
      }
    },
    onConfirmAddUsuario(event) {
      if (event) {
        const usuario = {
          tipoUsuarioId: UsuarioService.EXTERNO
        };

        let errors = UsuarioService.validate(usuario);

        if (errors.length) {
          errors.forEach(item => this.$notification.pushError(item.message));
          return;
        }

        const pessoaId = this.addUsuarioConfirmModal.value.id;

        return PessoaService.addUsuario(pessoaId, usuario)
          .then(() =>
            this.$notification.pushSuccess(
              "Usuário externo adicionado com sucesso"
            )
          )
          .catch(error =>
            this.$notification.pushError(
              `Houve um erro ao adicionar usuário: ${error.message}`
            )
          );
      }
    },
    onEditPermissoes(event) {
      for (let i = 0; this.pessoaCollection.length; i++) {
        const pessoa = this.pessoaCollection[i];

        const usuario = pessoa.usuarios.find(u => u.id === event);

        if (usuario) {
          this.usuarioPermissoesModal.value = { ...usuario, pessoa: pessoa };

          this.$nextTick().then(() =>
            this.$utils.Components.openModal(this.usuarioPermissoesModal.id)
          );

          break;
        }
      }
    },
    onResetSenha(event) {
      for (let i = 0; this.pessoaCollection.length; i++) {
        const pessoa = this.pessoaCollection[i];

        const usuario = pessoa.usuarios.find(u => u.id === event);

        if (usuario) {
          this.resetSenhaConfirmModal.value = { ...usuario, pessoa: pessoa };

          this.$nextTick().then(() =>
            this.$utils.Components.openModal(this.resetSenhaConfirmModal.id)
          );

          break;
        }
      }
    },
    onConfirmResetSenha(event) {
      if (event) {
        const usuario = this.resetSenhaConfirmModal.value;

        return UsuarioService.resetSenha(usuario.login)
          .then(() =>
            this.$notification.pushSuccess(
              "Senha do usuário resetada com sucesso"
            )
          )
          .catch(error =>
            this.$notification.pushError(
              `Houve um erro ao resetar senha do usuário: ${error.message}`
            )
          );
      }
    },
    resetPagination() {
      this.pagination.value = 1;
      this.pagination.numPages = 0;
    },
    doSearch() {
      const params = {
        nome: this.form.nome.value,
        cpf: this.form.cpf.value,
        passaporte: this.form.passaporte.value,
        page: this.pagination.value - 1,
        size: SEARCH_PAGINATION_SIZE
      };

      this.searched = true;

      this.loading = true;

      this.pessoaCollection = [];

      PessoaService.search(params)
        .then(response => {
          const pagina = response.data;

          this.pessoaCollection = pagina.elementos;

          this.pagination.numPages = pagina.totalPaginas;
        })
        .catch(error =>
          this.$notification.pushError(
            `Houve um erro ao obter a lista de pessoas e seus usuários: ${error.message}`
          )
        )
        .finally(() => (this.loading = false));
    },
    onPageChange(event) {
      this.pagination.value = event;
      this.doSearch();
    },
    search() {
      this.resetPagination();
      this.doSearch();
    },
    addPessoa() {
      this.pessoaModal.value = {
        id: null,
        cpf: null,
        passaporte: null,
        nome: null,
        nomeSocial: null,
        email: null,
        nacionalidade: {
          tipoCodigo: null,
          paisOrigemId: null
        },
        escolaridadeId: null,
        localizacaoCodigo: null
      };

      this.$nextTick().then(() =>
        this.$utils.Components.openModal(this.pessoaModal.id)
      );
    }
  }
};
</script>
