/**
 * @module directives/Pushpin
 * @category Diretivas
 * @summary Módulo de importação da diretiva de _pushpin_.
 *
 * @description
 * Expõe a diretiva de _pushpin_ para utilização pelo container e pelos
 * módulos.
 *
 * Ao chamar o método `use` do objeto `Vue` global, torna-se possível
 * acessar a diretiva dentro de componentes através do atributo HTML
 * `v-autocomplete`.
 *
 * @example <caption>Importando a diretiva de pushpin como membro default do módulo</caption>
 * import Pushpin from "./directives/Pushpin";
 *
 * Vue.use(Pushpin);
 *
 * @example <caption>Importando a diretiva de pushpin como membro do módulo</caption>
 * import { Pushpin } from "./directives/Pushpin";
 *
 * Vue.use(Pushpin);
 */
import { default as Pushpin } from "./Pushpin";

/**
 * Associa esta diretiva à instância do {@link external:Vue Vue}, tornando-se
 * acessível pelos componentes através do atributo HTML `v-pushpin`.
 * @param {external:Vue} Vue - Instância do {@link external:Vue Vue}.
 * @param {object} options - Objeto com as opções desta diretiva.
 */
const install = function(Vue, options = {}) {
  Pushpin.options = Object.assign({}, options);

  Vue.directive("pushpin", Pushpin);
};

// Atribui a função install ao objeto da diretiva.
Pushpin.install = install;

export default install;

export { Pushpin };
