/**
 * @module components/base/BaseTableOfContents
 * @category Componentes-base
 * @summary Módulo de importação do componente-base de tabela de conteúdos do {@link external:Materialize Materialize}.
 *
 * @description
 * Expõe o componente-base de tabela de conteúdos do {@link external:Materialize Materialize}
 * para utilização pelo container e pelos módulos.
 *
 * @example <caption>Importando como plug-in</caption>
 * // main.js
 * import BaseTableOfContents from "./components/base/BaseTableOfContents";
 *
 * Vue.use(BaseTableOfContents);
 *
 * @example <caption>Importando diretamente em um componente</caption>
 * // componente.vue
 * // ou import BaseTableOfContents from "./components/base/BaseTableOfContents/BaseTableOfContents.vue";
 * import { BaseTableOfContents } from "./components/base/BaseTableOfContents";
 *
 * export default {
 *  name: // ...
 *  components: {
 *    BaseTableOfContents
 *  },
 *  // ...
 * }
 */
import { default as BaseTableOfContents } from "./BaseTableOfContents.vue";

/**
 * Associa o componente-base de tabela de conteúdos à instância do {@link external:Vue Vue}.
 * @param {external:Vue} Vue - Instância do {@link external:Vue Vue}.
 */
const install = Vue =>
  Vue.component(BaseTableOfContents.name, BaseTableOfContents);

if (window && window.Vue) {
  install(window.Vue);
}

export default install;

export { BaseTableOfContents };
