<template>
  <article class="container">
    <header>
      <h1 class="thin center">
        {{ isCreate ? "Criação" : "Edição" }} de conteúdo programado
      </h1>
    </header>

    <BaseProgressBar v-if="loading" v-bind="progressBar" />
    <div v-else>
      <BaseInfoCard title="Seção principal">
        <p>
          Para {{ isCreate ? "criação" : "edição" }} deste conteúdo, deve-se
          preencher os campos nome e data/hora início e término. Em seguida,
          clique no botão "Salvar" para persistir os dados preenchidos.
        </p>
        <p>
          Opcionalmente, você pode definir um ou mais apresentadores para o
          conteúdo e informar, quando necessário, o endereço (URL) da
          apresentação, vídeo ou áudio realizado externamente (e.g., hospedado
          pelo YouTube).
        </p>
      </BaseInfoCard>

      <div class="row-fix row">
        <BaseTextInput v-bind="form.nome" v-model="conteudo.nome" />

        <BaseDatePicker
          v-bind="form.dataInicio"
          v-bind:disabled="isReadonly"
          v-bind:value="dataInicio"
          v-on:input="onInputDate('inicio', $event)"
        />
        <BaseTimePicker
          v-bind="form.horaInicio"
          v-bind:disabled="isReadonly"
          v-bind:value="horaInicio"
          v-on:input="onInputTime('inicio', $event)"
        />
        <BaseDatePicker
          v-bind="form.dataTermino"
          v-bind:disabled="isReadonly"
          v-bind:value="dataTermino"
          v-on:input="onInputDate('termino', $event)"
        />
        <BaseTimePicker
          v-bind="form.horaTermino"
          v-bind:disabled="isReadonly"
          v-bind:value="horaTermino"
          v-on:input="onInputTime('termino', $event)"
        />

        <BaseTextarea
          v-bind="form.descricao"
          v-bind:disabled="isReadonly"
          v-model="conteudo.descricao"
        />

        <BaseTextInput
          v-bind="form.url"
          v-bind:disabled="isReadonly"
          v-model="conteudo.url"
        />

        <div class="col s12 buttons-row">
          <!-- <BaseButton
            v-if="!isCreate"
              v-bind="form.buttonPublicar"
              v-on:click="publicar"
            /> -->
          <BaseButton
            v-bind="form.buttonSave"
            v-bind:disabled="isReadonly || disabled"
            v-on:click="save"
          />
        </div>
      </div>

      <div v-if="mayShowSections">
        <EditableSectionApresentadores
          v-bind:readonly="isReadonly"
          v-bind:entidade-id="conteudo.id"
          v-bind:tipo-conteudo-id="tipoConteudoId"
        />
      </div>
    </div>

    <div v-if="mayShowSections" class="fixed-action-btn back-btn">
      <BaseButton
        class="btn-floating"
        v-bind="backButton"
        v-on:click="backButton.onClick"
      />
    </div>
    <div v-if="mayShowSections" class="fixed-action-btn top-btn">
      <BaseButton
        class="btn-floating"
        v-bind="topButton"
        v-on:click="topButton.onClick"
      />
    </div>
  </article>
</template>

<script>
import { APP_INFO } from "../constants";
import {
  isISODate,
  isISODateTime,
  isISOTime,
  toBrazilianDate,
  toBrazilianTime,
  toISODate,
  toISOTime
} from "../utils/datetime";
import {
  ConteudoService,
  EventoService,
  EstandeService,
  PermissaoService
} from "../services";
import EditableSectionApresentadores from "../components/Common/EditableSection/EditableSectionApresentadores.vue";

export default {
  name: "GestaoConteudoProgramado",
  components: {
    EditableSectionApresentadores
  },
  inheritAttrs: false,
  data() {
    return {
      loading: false,
      disabled: false,
      conteudo: null,
      conteudoOriginal: null,
      estande: null,
      tipoConteudoId: ConteudoService.PROGRAMADO,
      progressBar: {
        useContainer: false
      },
      form: {
        nome: {
          id: "conteudo-nome",
          name: "conteudo-nome",
          required: true,
          label: "Nome",
          value: null
        },
        dataInicio: {
          id: "conteudo-datainicio",
          required: true,
          label: "Data início",
          gridExpression: "col s12 m3",
          value: null
        },
        horaInicio: {
          id: "conteudo-horainicio",
          required: true,
          label: "Hora início",
          gridExpression: "col s12 m3",
          value: null
        },
        dataTermino: {
          id: "conteudo-datatermino",
          required: true,
          label: "Data término",
          gridExpression: "col s12 m3",
          value: null
        },
        horaTermino: {
          id: "conteudo-horatermino",
          required: true,
          label: "Hora término",
          gridExpression: "col s12 m3",
          value: null
        },
        descricao: {
          id: "conteudo-descricao",
          name: "conteudo-descricao",
          label: "Descrição",
          value: null
        },
        url: {
          id: "conteudo-url",
          name: "conteudo-url",
          label: "URL (mídia externa)",
          value: null
        },
        buttonSave: {
          iconName: "save",
          content: "Salvar"
        }
      },
      backButton: {
        iconName: "arrow_back",
        size: "large",
        title: "Voltar para o estande",
        onClick: () =>
          this.$router.push({
            name: "editEstande",
            params: {
              idEvento: this.$route.params.idEvento,
              idEstande: this.$route.params.idEstande
            }
          })
      },
      topButton: {
        iconName: "arrow_upward",
        size: "large",
        title: "Ir para o topo da página",
        onClick: () => window.scroll(0, 0)
      }
    };
  },
  computed: {
    isCreate() {
      return !this.$route.params.idConteudo;
    },
    isGestorEstandes() {
      return this.$authorization.hasPermission(PermissaoService.GESTAO_ESTANDE);
    },
    isReadonly() {
      return !this.isGestorEstandes;
    },
    mayShowSections() {
      return !this.loading && this.conteudo && this.conteudo.id;
    },
    dataInicio() {
      if (!this.conteudo) {
        return null;
      }

      const inicio = this.conteudo.inicio;

      if (isISODateTime(inicio) || isISODate(inicio)) {
        return this.formatComputedDateProp(toBrazilianDate, inicio);
      } else {
        return null;
      }
    },
    horaInicio() {
      if (!this.conteudo) {
        return null;
      }

      const inicio = this.conteudo.inicio;

      if (isISODateTime(inicio) || isISOTime(inicio)) {
        return this.formatComputedDateProp(toBrazilianTime, inicio);
      } else {
        return null;
      }
    },
    dataTermino() {
      if (!this.conteudo) {
        return null;
      }

      const termino = this.conteudo.termino;

      if (isISODateTime(termino) || isISODate(termino)) {
        return this.formatComputedDateProp(toBrazilianDate, termino);
      } else {
        return null;
      }
    },
    horaTermino() {
      if (!this.conteudo) {
        return null;
      }

      const termino = this.conteudo.termino;

      if (isISODateTime(termino) || isISOTime(termino)) {
        return this.formatComputedDateProp(toBrazilianTime, termino);
      } else {
        return null;
      }
    }
  },
  created() {
    const { idEvento, idEstande, idConteudo } = this.$route.params;

    this.getConteudo(idEvento, idEstande, idConteudo);
  },
  methods: {
    formatComputedDateProp(formatter, value) {
      let newValue = null;

      try {
        newValue = formatter(value);
      } catch (e) {
        // TODO: verificar a necessidade de tratamento
        window.console.error(e.message);
      }

      return newValue;
    },
    getConteudo(idEvento, idEstande, idConteudo) {
      this.loading = true;

      this.conteudo = null;
      this.estande = null;

      EventoService.getEstande(idEvento, idEstande)
        .then(response => {
          this.estande = response.data;

          return EstandeService.getConteudoProgramado(
            this.estande.id,
            idConteudo
          );
        })
        .then(response => {
          this.conteudo = response.data;

          this.conteudoOriginal = { ...this.conteudo };

          document.title = `${APP_INFO.name} - ${this.conteudo.nome}`;
        })
        .catch(error =>
          this.$notification.pushError(
            `Houve um erro ao obter os dados do conteúdo programado: ${error.message}`
          )
        )
        .finally(() => (this.loading = false));
    },
    onInputDate(propertyName, event) {
      const value = this.formatComputedDateProp(toISODate, event);

      const old = this.conteudo[propertyName];

      if (isISODateTime(old)) {
        this.conteudo[propertyName] =
          (value ? `${value}T` : "") + toISOTime(old);
      } else if (isISOTime(old)) {
        if (value) {
          this.conteudo[propertyName] = `${value}T${old}`;
        }
      } else {
        this.conteudo[propertyName] = value;
      }
    },
    onInputTime(propertyName, event) {
      const value = this.formatComputedDateProp(
        toISOTime,
        event && event.length === 8 ? event : event + ":00"
      );

      const old = this.conteudo[propertyName];

      if (isISODateTime(old)) {
        this.conteudo[propertyName] =
          toISODate(old) + (value ? `T${value}` : "");
      } else if (isISODate(old)) {
        if (value) {
          this.conteudo[propertyName] = `${old}T${value}`;
        }
      } else {
        this.conteudo[propertyName] = value;
      }
    },
    save() {
      if (this.isReadonly) {
        return;
      }

      const errors = ConteudoService.validate(this.conteudo);

      if (errors.length) {
        errors.forEach(item => this.$notification.pushError(item.message));
        return;
      }

      const estandeId = this.estande.id;

      const { nome, inicio, termino, descricao, url } = this.conteudo;

      const data = { nome, descricao, url, inicio, termino, estandeId };

      this.saveConteudo(data).finally(() => {
        if (this.isCreate) {
          this.$router.push({
            name: "editEstande",
            params: {
              idEvento: this.$route.params.idEvento,
              idEstande: this.$route.params.idEstande
            }
          });
        }
      });
    },
    saveConteudo(conteudo) {
      if (this.isCreate) {
        const idEstande = this.$route.params.idEstande;

        this.conteudo = conteudo;
        this.conteudo.apresentadores = [];

        return EstandeService.createConteudoProgramado(idEstande, conteudo)
          .then(response => {
            this.conteudo.id = response.data.id;

            this.conteudoOriginal = response.data;

            this.$notification.pushSuccess("Conteúdo criado com sucesso");
          })
          .catch(error =>
            this.$notification.pushError(
              `Houve um erro ao criar o conteudo: ${error.message}`
            )
          );
      } else {
        const idEstande = this.estande.id;
        const idConteudo = this.conteudo.id;

        if (
          this.conteudoOriginal.nome !== conteudo.nome ||
          this.conteudoOriginal.inicio !== conteudo.inicio ||
          this.conteudoOriginal.termino !== conteudo.termino ||
          this.conteudoOriginal.descricao !== conteudo.descricao ||
          this.conteudoOriginal.url !== conteudo.url
        ) {
          return EstandeService.updateConteudoProgramado(
            idEstande,
            idConteudo,
            conteudo
          )
            .then(() => {
              this.$notification.pushSuccess("Conteúdo atualizado com sucesso");

              this.conteudoOriginal = { ...conteudo };
            })
            .catch(error =>
              this.$notification.pushError(
                `Houve um erro ao atualizar o conteúdo: ${error.message}`
              )
            );
        }
      }

      return Promise.resolve();
    }
  }
};
</script>

<style scoped>
.top-btn {
  bottom: 94px;
}

.buttons-row {
  justify-content: right;
}
</style>
