<template>
  <div class="carousel-wrapper">
    <div
      ref="carousel"
      class="carousel"
      v-bind:class="{ 'carousel-slider': isFullWidth }"
    >
      <a
        v-for="(item, index) in items"
        v-bind:key="index"
        v-bind:data-item="index"
        class="carousel-item valign-wrapper black"
        v-bind:href="item.href || '#!'"
        tabindex="-1"
        ><img v-bind="item.image"
      /></a>

      <span class="carousel-page">
        {{ imageNumber + 1 }}/{{ totalImages }}
      </span>
      <a
        v-if="showControls"
        href="#!"
        class="carousel-control carousel-prev"
        title="Imagem anterior"
        v-on:click="prevImage"
        v-on:keydown.enter="prevImage"
        tabindex="0"
      >
        <i class="material-icons">arrow_back_ios_new</i>
      </a>
      <a
        v-if="showControls"
        href="#!"
        class="carousel-control carousel-next"
        title="Próxima imagem"
        v-on:click="nextImage"
        v-on:keydown.enter="nextImage"
        tabindex="0"
      >
        <i class="material-icons">arrow_forward_ios</i>
      </a>
      <a
        href="#!"
        class="carousel-control carousel-enterfullscreen"
        title="Ver em tela cheia"
        v-on:click="enterFullscreen"
        v-on:keydown.enter="enterFullscreen"
        tabindex="0"
      >
        <i class="material-icons">fullscreen</i>
      </a>
    </div>

    <div v-if="showFullscreen" class="carousel-fullscreen-wrapper">
      <div class="carousel-fullscreen-image valign-wrapper">
        <a
          href="#!"
          class="carousel-control carousel-exitfullscreen"
          title="Sair da tela cheia"
          v-on:click="exitFullscreen"
          v-on:keydown.enter="exitFullscreen"
          tabindex="0"
          ><i class="material-icons">fullscreen_exit</i></a
        >
        <img class="responsive-img" v-bind="fullscreenImage.image" />
      </div>
      <div class="carousel-fullscreen-description">
        <h5>{{ fullscreenImage.title }}</h5>
        <p v-show="fullscreenImage.description">
          {{ fullscreenImage.description }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @module components/base/BaseCarousel/BaseCarousel
 * @category Componentes-base
 * @summary _Single File Component_ (SFC) de _carousel_ do {@link external:Materialize Materialize}.
 *
 * @description
 * Este componente não possui _slot_ _default_.
 *
 * @vue-prop {Array<object>} [items=[]] - Itens a serem exibidos na galeria.
 * @vue-prop {string} [items[].title] - Título do item.
 * @vue-prop {string} [items[].description] - Descrição do item.
 * @vue-prop {object} [items[].image] - Objeto com opções para a imagem do item
 * a ser exibida na galeria. Aceita os atributos do elemento HTML `img`
 * (`src`, `alt`, `title`, etc).
 * @vue-prop {object} carouselOptions - Opções aceitas pelo _carousel_ do
 * {@link external:Materialize Materialize}.
 * @vue-event {string} onChange - Emite o valor atual do componente, i.e., o
 * número (iniciando por 0) do item atual na galeria. Pode ser capturado pela
 * diretiva `v-on:change` do {@link external:Vue Vue}.
 *
 * @example <caption>Informando os itens por props</caption>
 * <BaseCarousel v-bind:items="[{ title: 'Foto 1', image: { ... } }, ..., { title: 'Foto 2', image: { ... } }]" />
 */
import { isEmpty, isFunction } from "../../../utils/lang";

export default {
  name: "BaseCarousel",
  inheritAttrs: false,
  props: {
    items: {
      type: Array,
      default: () => []
    },

    carouselOptions: Object
  },
  data() {
    return {
      instance: null,
      imageNumber: 0,
      fullscreenImage: null
    };
  },
  computed: {
    isFullWidth() {
      return this.carouselOptions && this.carouselOptions.fullWidth;
    },
    showControls() {
      return this.isFullWidth;
    },
    showFullscreen() {
      return this.fullscreenImage;
    },
    totalImages() {
      return (this.items || []).length;
    }
  },
  beforeCreate() {
    if (!window.M) {
      window.console.error(
        "Objeto do Materialize não encontrado. Verifique se essa biblioteca foi carregada corretamente"
      );
    }
  },
  mounted() {
    if (!window.M) {
      return;
    }

    const { carousel } = this.$refs;

    const carouselOptions = Object.assign({}, this.carouselOptions);
    const originalOnCycleTo = this.carouselOptions.onCycleTo;

    carouselOptions.onCycleTo = isFunction(originalOnCycleTo)
      ? (item, event) => {
          originalOnCycleTo(item, event);
          this.changeImageNumberAfterCycle(item);
        }
      : item => this.changeImageNumberAfterCycle(item);

    this.instance = window.M.Carousel.init(carousel, carouselOptions);
  },
  beforeDestroy() {
    if (this.instance) {
      this.instance.destroy();
    }
  },
  methods: {
    changeImageNumberAfterCycle(item) {
      this.imageNumber = window.parseInt(item.dataset.item);

      this.onChange();
    },
    decrement() {
      if (this.totalImages) {
        this.imageNumber =
          (this.imageNumber - 1 + this.totalImages) % this.totalImages;
      }
    },
    increment() {
      if (this.totalImages) {
        this.imageNumber = (this.imageNumber + 1) % this.totalImages;
      }
    },
    prevImage() {
      if (this.instance) {
        this.instance.set(this.imageNumber - 1, () => {
          this.decrement();

          this.onChange();
        });
      }
    },
    nextImage() {
      if (this.instance) {
        this.instance.set(this.imageNumber + 1, () => {
          this.increment();

          this.onChange();
        });
      }
    },
    enterFullscreen() {
      if (!isEmpty(this.items)) {
        this.fullscreenImage = this.items[this.imageNumber];
      }
    },
    exitFullscreen() {
      this.fullscreenImage = null;
    },
    onChange() {
      this.$emit("change", this.imageNumber);
    }
  }
};
</script>

<style scoped>
.carousel,
.carousel-item {
  /* Manter a resolução da imagem do carrossel em 16:9 */
  padding-top: 56.25%;
}
.carousel-item img {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.carousel-page,
.carousel-prev,
.carousel-next,
.carousel-enterfullscreen,
.carousel-exitfullscreen {
  position: absolute;
  z-index: 1;
  cursor: pointer;
}
.carousel-page,
.carousel-prev,
.carousel-next,
.carousel-enterfullscreen {
  bottom: 50%;
  bottom: calc(50% - 24px);
}
.carousel-prev,
.carousel-next {
  visibility: hidden;
}
.carousel:hover .carousel-prev,
.carousel:hover .carousel-next {
  visibility: visible;
}
.carousel-fullscreen-wrapper:hover .carousel-prev,
.carousel-fullscreen-wrapper:hover .carousel-next {
  visibility: visible;
}
.carousel-page {
  left: 15px;
  bottom: 20px;
  cursor: default;
}
.carousel-prev {
  left: 7px;
}
.carousel-next {
  right: 7px;
}
.carousel-enterfullscreen {
  right: 15px;
  bottom: 12px;
}
.carousel-exitfullscreen {
  right: 15px;
  top: 12px;
  bottom: unset;
}
.carousel-page,
.carousel-prev i.material-icons,
.carousel-next i.material-icons,
.carousel-enterfullscreen i.material-icons,
.carousel-exitfullscreen i.material-icons {
  font-size: 48px;
  color: rgba(255, 255, 255, 0.5);
  text-shadow: 1px 1px rgba(0, 0, 0, 0.75);
}
.carousel-page {
  font-size: 20px;
}
.carousel-enterfullscreen i.material-icons,
.carousel-exitfullscreen i.material-icons {
  font-size: 32px;
}
.carousel-page:hover,
.carousel-prev:hover i.material-icons,
.carousel-next:hover i.material-icons,
.carousel-enterfullscreen:hover i.material-icons,
.carousel-exitfullscreen:hover i.material-icons {
  color: white;
}

.carousel-fullscreen-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: black;
}
.carousel-fullscreen-image {
  position: relative;
  background-color: inherit;
  flex: 1 1 auto;
  min-height: 0;
}
.carousel-fullscreen-image img {
  margin: 0 auto;
  max-height: 100%;
}
.carousel-fullscreen-description {
  flex: 0 0 auto;
  padding: 0.5rem 1.5rem;
  background-color: inherit;
  color: white;
}
.carousel-fullscreen-description p {
  white-space: pre-wrap;
}

.contrast .carousel a.carousel-control,
.contrast .carousel a.carousel-control i.material-icons {
  background-color: transparent !important;
}
/* TODO: verificar porque esse seletor não é identificado pelo browser */
.contrast .carousel .indicators .indicator-item.active {
  background-color: white !important;
}
</style>
