/**
 * @module services/Log/Log
 * @category Serviços
 * @summary Módulo do serviço de _log_.
 *
 * @description
 * Expõe o objeto/_namespace_ do serviço de _log_ do sistema para utilização
 * interna.
 *
 * @requires module:constants.API_URL
 * @requires module:constants.HOST_URL
 * @requires module:utils/lang.isNull
 * @requires module:utils/lang.isNumber
 * @requires module:utils/lang.isObject
 * @requires module:utils/lang.isString
 * @requires module:utils/lang.isUndefined
 *
 * @example
 * import LogService from "./services/Log/Log";
 *
 * // Capturando o erro global e enviando para o _endpoint_ de _log_.
 * window.onerror = (message, source, lineno, colno, error) => {
 *  LogService.sendError(
 *    // ...
 *  );
 *  return true;
 * };
 */
import { API_URL, HOST_URL } from "../../constants";
import {
  isNull,
  isNumber,
  isObject,
  isString,
  isUndefined
} from "../../utils/lang";

/**
 * Constante que indica a versão do {@link external:GELF GELF} utilizada.
 * @type {string}
 * @constant
 * @default
 * @ignore
 */
const GELF_VERSION = "1.1";

/**
 * Constante que caracteriza uma severidade de emergência.
 * @type {number}
 * @constant
 * @default
 * @ignore
 */
const EMERG = 0;

/**
 * Constante que caracteriza uma severidade de alerta.
 * @type {number}
 * @constant
 * @default
 * @ignore
 */
const ALERT = 1;

/**
 * Constante que caracteriza uma severidade crítica.
 * @type {number}
 * @constant
 * @default
 * @ignore
 */
const CRIT = 2;

/**
 * Constante que caracteriza uma severidade de erro.
 * @type {number}
 * @constant
 * @default
 * @ignore
 */
const ERR = 3;

/**
 * Constante que caracteriza uma severidade de aviso.
 * @type {number}
 * @constant
 * @default
 * @ignore
 */
const WARNING = 4;

/**
 * Constante que caracteriza uma severidade de notificação.
 * @type {number}
 * @constant
 * @default
 * @ignore
 */
const NOTICE = 5;

/**
 * Constante que caracteriza uma severidade de informação.
 * @type {number}
 * @constant
 * @default
 * @ignore
 */
const INFO = 6;

/**
 * Constante que caracteriza uma severidade de _debug_.
 * @type {number}
 * @constant
 * @default
 * @ignore
 */
const DEBUG = 7;

/**
 * Faz uma requisição à API.
 * @param {string} url - URL de acesso à API.
 * @param {object} options - Objeto com as opções da requisição.
 * @returns {external:Promise} Retorno da API.
 * @ignore
 */
const fetch = function(url, options) {
  return window.fetch(url, options).then(response => {
    if (!response.ok) {
      return response
        .json()
        .then(body => Promise.reject(new Error(body.mensagem || body.message)));
    } else {
      return response;
    }
  });
};

/**
 * Constrói um objeto {@link external:GELF GELF} para ser enviado ao {@link external:GrayLog GrayLog}.
 * @param {string} host - Nome do _host_, arquivo fonte ou aplicação que gerou o _log_.
 * @param {string} shortMessage - Mensagem (curta) a ser enviada.
 * @param {string} fullMessage - Mensagem (completa) a ser enviada.
 * Pode conter, por exemplo, uma _stacktrace_.
 * @param {number} level - Nível de severidade.
 * @param {object} fields - Objeto com campos adicionais para o _log_.
 * @ignore
 */
const buildGELFObject = function(
  host,
  shortMessage,
  fullMessage,
  level,
  fields
) {
  if (!isString(host)) {
    throw new Error("Campo `host` inválido");
  }

  if (!isString(shortMessage)) {
    throw new Error("Campo `short_message` inválido");
  }

  if (
    !isNull(fullMessage) &&
    !isUndefined(fullMessage) &&
    !isString(fullMessage)
  ) {
    throw new Error("Campo `full_message` inválido");
  }

  if (!isNumber(level) || level > 7) {
    throw new Error("Campo `level` inválido");
  }

  const additionalFields = fields || {};

  if (isObject(additionalFields)) {
    Object.keys(additionalFields).forEach(key => {
      if (!/^_[\w.-]+$/.test(key)) {
        throw new Error(
          "Os nomes dos campos adicionais precisam iniciar com `_` e somente são aceitos letras, números, `.`, `-` e `_`"
        );
      }
    });
  }

  return {
    version: GELF_VERSION,
    host,
    short_message: shortMessage,
    full_message: fullMessage,
    timestamp: new Date().getTime(),
    level,
    ...additionalFields
  };
};

/**
 * @namespace LogService
 * @category Serviços
 * @summary Objeto/_namespace_ do serviço de _log_.
 */
const LogService = {
  /**
   * URL de acesso à API para _log_.
   * @type {string}
   * @private
   * @readonly
   */
  baseURL: `${API_URL}/log`,

  /**
   * Indica se o envio de dados ao servidor de _log_ está habilitado.
   * @type {boolean}
   * @private
   * @readonly
   * @default false
   */
  isEnabled: false,

  /**
   * Constante que caracteriza uma severidade de emergência.
   * @type {number}
   * @readonly
   * @default 0
   */
  EMERG,

  /**
   * Constante que caracteriza uma severidade de alerta.
   * @type {number}
   * @readonly
   * @default 1
   */
  ALERT,

  /**
   * Constante que caracteriza uma severidade crítica.
   * @type {number}
   * @readonly
   * @default 2
   */
  CRIT,

  /**
   * Constante que caracteriza uma severidade de erro.
   * @type {number}
   * @readonly
   * @default 3
   */
  ERR,

  /**
   * Constante que caracteriza uma severidade de aviso.
   * @type {number}
   * @readonly
   * @default 4
   */
  WARNING,

  /**
   * Constante que caracteriza uma severidade de notificação.
   * @type {number}
   * @readonly
   * @default 5
   */
  NOTICE,

  /**
   * Constante que caracteriza uma severidade de informação.
   * @type {number}
   * @readonly
   * @default 6
   */
  INFO,

  /**
   * Constante que caracteriza uma severidade de _debug_.
   * @type {number}
   * @readonly
   * @default 7
   */
  DEBUG,

  /**
   * Inicia o serviço de _log_, permitindo que os dados sejam enviados ao
   * servidor de _log_.
   * @returns {module:services/Log/Log~LogService} O próprio namespace.
   */
  start() {
    this.isEnabled = true;
    return this;
  },

  /**
   * Pára o serviço de _log_, impedindo que os dados sejam enviados ao servidor
   * de _log_.
   * @returns {module:services/Log/Log~LogService} O próprio namespace.
   */
  stop() {
    this.isEnabled = false;
    return this;
  },

  /**
   * Envia uma mensagem ao servidor de _log_.
   * @param {string} shortMessage - Mensagem (curta) a ser enviada.
   * @param {string} fullMessage - Mensagem (completa) a ser enviada.
   * Pode conter, por exemplo, uma _stacktrace_.
   * @param {number} level - Nível de severidade.
   * @param {object} fields - Objeto com campos adicionais para o _log_.
   * @returns {external:Promise}
   */
  send(shortMessage, fullMessage, level, fields) {
    if (this.isEnabled) {
      return fetch(this.baseURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Origin: "http://localhost:8080"
        },
        body: JSON.stringify(
          buildGELFObject(HOST_URL, shortMessage, fullMessage, level, fields)
        )
      });
    } else {
      return Promise.resolve();
    }
  },

  /**
   * Envia uma mensagem ao servidor de _log_ com nível de severidade de
   * emergência.
   * @param {string} shortMessage - Mensagem (curta) a ser enviada.
   * @param {string} fullMessage - Mensagem (completa) a ser enviada.
   * Pode conter, por exemplo, uma _stacktrace_.
   * @param {object} fields - Objeto com campos adicionais para o _log_.
   * @returns {external:Promise}
   */
  sendEmergency(shortMessage, fullMessage, fields) {
    return this.send(shortMessage, fullMessage, this.EMERG, fields);
  },

  /**
   * Envia uma mensagem ao servidor de _log_ com nível de severidade de alerta.
   * @param {string} shortMessage - Mensagem (curta) a ser enviada.
   * @param {string} fullMessage - Mensagem (completa) a ser enviada.
   * Pode conter, por exemplo, uma _stacktrace_.
   * @param {object} fields - Objeto com campos adicionais para o _log_.
   * @returns {external:Promise}
   */
  sendAlert(shortMessage, fullMessage, fields) {
    return this.send(shortMessage, fullMessage, this.ALERT, fields);
  },

  /**
   * Envia uma mensagem ao servidor de _log_ com nível de severidade crítica.
   * @param {string} shortMessage - Mensagem (curta) a ser enviada.
   * @param {string} fullMessage - Mensagem (completa) a ser enviada.
   * Pode conter, por exemplo, uma _stacktrace_.
   * @param {object} fields - Objeto com campos adicionais para o _log_.
   * @returns {external:Promise}
   */
  sendCritical(shortMessage, fullMessage, fields) {
    return this.send(shortMessage, fullMessage, this.CRIT, fields);
  },

  /**
   * Envia uma mensagem ao servidor de _log_ com nível de severidade de erro.
   * @param {string} shortMessage - Mensagem (curta) a ser enviada.
   * @param {string} fullMessage - Mensagem (completa) a ser enviada.
   * Pode conter, por exemplo, uma _stacktrace_.
   * @param {object} fields - Objeto com campos adicionais para o _log_.
   * @returns {external:Promise}
   */
  sendError(shortMessage, fullMessage, fields) {
    return this.send(shortMessage, fullMessage, this.ERR, fields);
  },

  /**
   * Envia uma mensagem ao servidor de _log_ com nível de severidade de aviso.
   * @param {string} shortMessage - Mensagem (curta) a ser enviada.
   * @param {string} fullMessage - Mensagem (completa) a ser enviada.
   * Pode conter, por exemplo, uma _stacktrace_.
   * @param {object} fields - Objeto com campos adicionais para o _log_.
   * @returns {external:Promise}
   */
  sendWarning(shortMessage, fullMessage, fields) {
    return this.send(shortMessage, fullMessage, this.WARNING, fields);
  },

  /**
   * Envia uma mensagem ao servidor de _log_ com nível de severidade de
   * notificação.
   * @param {string} shortMessage - Mensagem (curta) a ser enviada.
   * @param {string} fullMessage - Mensagem (completa) a ser enviada.
   * Pode conter, por exemplo, uma _stacktrace_.
   * @param {object} fields - Objeto com campos adicionais para o _log_.
   * @returns {external:Promise}
   */
  sendNotice(shortMessage, fullMessage, fields) {
    return this.send(shortMessage, fullMessage, this.NOTICE, fields);
  },

  /**
   * Envia uma mensagem ao servidor de _log_ com nível de severidade de
   * informação.
   * @param {string} shortMessage - Mensagem (curta) a ser enviada.
   * @param {string} fullMessage - Mensagem (completa) a ser enviada.
   * Pode conter, por exemplo, uma _stacktrace_.
   * @param {object} fields - Objeto com campos adicionais para o _log_.
   * @returns {external:Promise}
   */
  sendInformational(shortMessage, fullMessage, fields) {
    return this.send(shortMessage, fullMessage, this.INFO, fields);
  },

  /**
   * Envia uma mensagem ao servidor de _log_ com nível de severidade de debug.
   * @param {string} shortMessage - Mensagem (curta) a ser enviada.
   * @param {string} fullMessage - Mensagem (completa) a ser enviada.
   * Pode conter, por exemplo, uma _stacktrace_.
   * @param {object} fields - Objeto com campos adicionais para o _log_.
   * @returns {external:Promise}
   */
  sendDebug(shortMessage, fullMessage, fields) {
    return this.send(shortMessage, fullMessage, this.DEBUG, fields);
  }
};

export default LogService;
