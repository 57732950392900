<template>
  <component v-bind:is="tag" v-bind:colspan="colspan" v-bind:rowspan="rowspan">
    <slot>{{ content }}</slot>
  </component>
</template>

<script>
/**
 * @module components/base/BaseTableCell/BaseTableCell
 * @category Componentes-base
 * @summary _Single File Component_ (SFC) de célula de tabela do {@link external:Materialize Materialize}.
 *
 * @description
 * O _slot_ _default_ recebe o conteúdo da célula da tabela.
 *
 * @vue-prop {boolean} [isHeader=false] - Indica se a célula será uma coluna de
 * cabeçalho ou uma coluna de dado da tabela.
 * @vue-prop {number} [colspan] - Número de colunas que a célula irá mesclar.
 * @vue-prop {number} [rowspan] - Número de linhas que a célula irá mesclar.
 *
 * @example
 * <BaseTableCell v-bind:colspan="2" v-bind:content="Mil-folhas" />
 */

export default {
  name: "BaseTableCell",
  inheritAttrs: false,
  props: {
    isHeader: {
      type: Boolean,
      default: false
    },

    colspan: Number,
    rowspan: Number,
    content: [Number, Boolean, String]
  },
  computed: {
    tag() {
      return this.isHeader ? "th" : "td";
    }
  }
};
</script>
