<template>
  <router-link
    v-bind:to="to"
    v-bind:tag="tag"
    v-bind:title="$attrs.title"
    v-on:click.native="onClick"
  >
    <i v-if="showIcon && iconName" class="material-icons" v-bind:class="iconPosition">{{ iconName }}</i>
    <slot>{{ content }}</slot>
  </router-link>
</template>

<script>
/**
 * @module components/base/BaseRouterLink/BaseRouterLink
 * @category Componentes-base
 * @summary _Single File Component_ (SFC) que encapsula um {@link external:RouterLink RouterLink}.
 *
 * @description
 * Permite o uso de ícone junto ao link.
 *
 * O _slot_ _default_ recebe o conteúdo/_label_ do link.
 *
 * Atributos HTML de acessibilidade, e.g., `title`, podem ser usados
 * diretamente no componente.
 *
 * @vue-prop {string|Location} to - Rota alvo do link.
 * @vue-prop {string} [tag="a"] - Nome do elemento HTML em que o {@link external:RouterLink RouterLink} será renderizado.
 * @vue-prop {boolean} [showIcon=true] - Indica se o componente deve exibir o ícone.
 * @vue-prop {string} [content] - Conteúdo a ser exibido no elemento HTML
 * utilizado iternamente pelo componente. É usado como substituto quando nenhum
 *  dado é passado através do _slot_ _default_.
 * @vue-prop {string} [iconName] - Nome do [ícone do Material Design]{@link external:MaterialDesignIcons}.
 * @vue-prop {string} [iconPosition="left"] - Posição em que o ícone será
 * localizado. Aceita "left" ou "right".
 * @vue-event {external:Event} onClick - Emite o evento de click. Pode ser capturado
 * pela diretiva `v-on:click` do {@link external:Vue Vue}.
 *
 * @example
 * <BaseRouterLink to="/login" icon-name="account_circle">
 *  Login
 * </BaseRouterLink>
 */
export default {
  name: "BaseRouterLink",
  inheritAttrs: false,
  props: {
    to: {
      type: [String, Object],
      required: true
    },
    tag: {
      type: String,
      default: "a"
    },
    showIcon: {
      type: Boolean,
      default: true
    },

    content: String,

    iconName: String,

    iconPosition: {
      type: String,
      default: "left",
      validator: value => ["left", "right"].indexOf(value) !== -1
    }
  },
  methods: {
    onClick($event) {
      this.$emit("click", $event);
    }
  }
};
</script>
