/**
 * @module plugins/Authorization
 * @category Plug-ins
 * @summary Módulo de importação do _plug-in_ de permissões.
 *
 * @description
 * Expõe o _plug-in_ de permissões para utilização pelo container e pelos
 * módulos.
 *
 * Ao chamar o método `use` do objeto `Vue` global, torna-se possível
 * acessar o _plug-in_ dentro de componentes através da propriedade
 * `$authorization`.
 *
 * @example <caption>Importando o plug-in de permissões como membro default do módulo</caption>
 * import store from "./store";
 * import Authorization from "./plugins/Authorization";
 *
 * Vue.use(Authorization, { store });
 *
 * @example <caption>Importando o plug-in de permissões como membro do módulo</caption>
 * import store from "./store";
 * import { Authorization } from "./plugins/Authorization";
 *
 * Vue.use(Authorization, { store });
 */
import { default as Authorization } from "./Authorization";

/**
 * Associa este _plug-in_ à instância do {@link external:Vue Vue}, atribuindo a
 * ela a propriedade `$authorization`, tornando-se acessível pelos componentes.
 * @param {external:Vue} Vue - Instância do {@link external:Vue Vue}.
 * @param {object} options - Objeto com as opções deste _plug-in_. Para correto
 * funcionamento do mesmo, é necessário que seja passado o gerenciador de
 * estados ({@link external:Store Store}) utilizado pelo sistema.
 */
const install = function(Vue, options = {}) {
  Authorization.options = Object.assign({}, Authorization.options, options);

  Authorization.store = Authorization.options.store;

  Object.defineProperty(Vue.prototype, "$authorization", {
    get() {
      return Authorization;
    }
  });
};

// Atribui a função install ao objeto do _plug-in_.
Authorization.install = install;

export default install;

export { Authorization };
