/**
 * @module plugins/Http/Http
 * @category Plug-ins
 * @summary Módulo do _plug-in_ de requisições HTTP.
 *
 * @description
 * Utiliza o objeto `axios` ({@link external:axios axios}) como base para
 * requisições HTTP do sistema para utilização pelo container e pelos módulos.
 *
 * @requires {@link external:axios axios}
 * @requires module:constants.HOST_URL
 * @requires module:utils/lang.isEmpty
 * @requires module:utils/web.escapeHtml
 *
 * @example
 * // main.js
 * import { AuthenticationService } from "...";
 * import Http from "./plugins/Http";
 *
 * Vue.use(Http, { AuthenticationService });
 *
 * // componente.vue
 * export default {
 *  data() {
 *    return {
 *      url: //...
 *    }
 *  },
 *  // ...
 *  mounted() {
 *    this.$http.get(this.url)
 *    // ...
 *  }
 * }
 */
import axios from "axios";
import { HOST_URL } from "../../constants";
import { isEmpty } from "../../utils/lang";
import { escapeHtml } from "../../utils/web";

/**
 * Constante que caracteriza o _status_ HTTP "100 Continue".
 * @type {number}
 * @constant
 * @default
 * @ignore
 */
const HTTP_STATUS_INFO_CONTINUE = 100;

/**
 * Constante que caracteriza o _status_ HTTP "101 Switching Protocols".
 * @type {number}
 * @constant
 * @default
 * @ignore
 */
const HTTP_STATUS_INFO_SWITCHING_PROTOCOLS = 101;

/**
 * Constante que caracteriza o _status_ HTTP "200 OK".
 * @type {number}
 * @constant
 * @default
 * @ignore
 */
const HTTP_STATUS_SUCCESSFUL_OK = 200;

/**
 * Constante que caracteriza o _status_ HTTP "201 Created".
 * @type {number}
 * @constant
 * @default
 * @ignore
 */
const HTTP_STATUS_SUCCESSFUL_CREATED = 201;

/**
 * Constante que caracteriza o _status_ HTTP "202 Accepted".
 * @type {number}
 * @constant
 * @default
 * @ignore
 */
const HTTP_STATUS_SUCCESSFUL_ACCEPTED = 202;

/**
 * Constante que caracteriza o _status_ HTTP "204 No Content".
 * @type {number}
 * @constant
 * @default
 * @ignore
 */
const HTTP_STATUS_SUCCESSFUL_NO_CONTENT = 204;

/**
 * Constante que caracteriza o _status_ HTTP "301 Moved Permanently".
 * @type {number}
 * @constant
 * @default
 * @ignore
 */
const HTTP_STATUS_REDIRECTION_MOVED_PERMANENTLY = 301;

/**
 * Constante que caracteriza o _status_ HTTP "302 Found".
 * @type {number}
 * @constant
 * @default
 * @ignore
 */
const HTTP_STATUS_REDIRECTION_FOUND = 302;

/**
 * Constante que caracteriza o _status_ HTTP "303 See Other".
 * @type {number}
 * @constant
 * @default
 * @ignore
 */
const HTTP_STATUS_REDIRECTION_SEE_OTHER = 303;

/**
 * Constante que caracteriza o _status_ HTTP "304 Not Modified".
 * @type {number}
 * @constant
 * @default
 * @ignore
 */
const HTTP_STATUS_REDIRECTION_NOT_MODIFIED = 304;

/**
 * Constante que caracteriza o _status_ HTTP "400 Bad Request".
 * @type {number}
 * @constant
 * @default
 * @ignore
 */
const HTTP_STATUS_CLIENT_ERROR_BAD_REQUEST = 400;

/**
 * Constante que caracteriza o _status_ HTTP "401 Unauthorized".
 * @type {number}
 * @constant
 * @default
 * @ignore
 */
const HTTP_STATUS_CLIENT_ERROR_UNAUTHORIZED = 401;

/**
 * Constante que caracteriza o _status_ HTTP "403 Forbidden".
 * @type {number}
 * @constant
 * @default
 * @ignore
 */
const HTTP_STATUS_CLIENT_ERROR_FORBIDDEN = 403;

/**
 * Constante que caracteriza o _status_ HTTP "404 Not Found ".
 * @type {number}
 * @constant
 * @default
 * @ignore
 */
const HTTP_STATUS_CLIENT_ERROR_NOT_FOUND = 404;

/**
 * Constante que caracteriza o _status_ HTTP "405 Method Not Allowed".
 * @type {number}
 * @constant
 * @default
 * @ignore
 */
const HTTP_STATUS_CLIENT_ERROR_METHOD_NOT_ALLOWED = 405;

/**
 * Constante que caracteriza o _status_ HTTP "409 Conflict".
 * @type {number}
 * @constant
 * @default
 * @ignore
 */
const HTTP_STATUS_CLIENT_ERROR_CONFLICT = 409;

/**
 * Constante que caracteriza o _status_ HTTP "415 Unsupported Media Type".
 * @type {number}
 * @constant
 * @default
 * @ignore
 */
const HTTP_STATUS_CLIENT_ERROR_UNSUPPORTED_MEDIA_TYPE = 415;

/**
 * Constante que caracteriza o _status_ HTTP "422 Unprocessable Entity".
 * @type {number}
 * @constant
 * @default
 * @ignore
 */
const HTTP_STATUS_CLIENT_ERROR_UNPROCESSABLE_ENTITY = 422;

/**
 * Constante que caracteriza o _status_ HTTP "500 Internal Server Error".
 * @type {number}
 * @constant
 * @default
 * @ignore
 */
const HTTP_STATUS_SERVER_ERROR_INTERNAL_SERVER_ERROR = 500;

/**
 * Constante que caracteriza o _status_ HTTP "501 Not Implemented".
 * @type {number}
 * @constant
 * @default
 * @ignore
 */
const HTTP_STATUS_SERVER_ERROR_NOT_IMPLEMENTED = 501;

/**
 * Constante que caracteriza o _status_ HTTP "502 Bad Gateway".
 * @type {number}
 * @constant
 * @default
 * @ignore
 */
const HTTP_STATUS_SERVER_ERROR_BAD_GATEWAY = 502;

/**
 * Constante que caracteriza o _status_ HTTP "503 Service Unavailable".
 * @type {number}
 * @constant
 * @default
 * @ignore
 */
const HTTP_STATUS_SERVER_ERROR_SERVICE_UNAVAILABLE = 503;

/**
 * Constante que caracteriza o _status_ HTTP "504 Gateway Timeout".
 * @type {number}
 * @constant
 * @default
 * @ignore
 */
const HTTP_STATUS_SERVER_ERROR_GATEWAY_TIMEOUT = 504;

/**
 * Fila de requisições para armazenar objetos contendo as configurações de
 * requisição utilizadas pelo {@link external:axios axios}.
 * @type {Array}
 * @default []
 * @ignore
 */
const queue = [];

/**
 * Enfileira, na fila de requisições, um objeto contendo as configurações de
 * requisição utilizadas pelo {@link external:axios axios}.
 * @param {object} request
 * @param {boolean} [priority=false] - Indica se o elemento deve ser inserido na frente
 * da fila.
 * @returns {number} - O novo tamanho da fila de requisições.
 * @ignore
 */
const enqueue = function(request, priority = false) {
  return priority ? queue.unshift(request) : queue.push(request);
};

/**
 * Remove o primeiro objeto da fila de requisições.
 * @returns {object} - Um objeto contendo as configurações de requisição
 * utilizadas pelo {@link external:axios axios}.
 * @ignore
 */
const dequeue = function() {
  return queue.shift();
};

/**
 * Executa a requisição que encabeça a fila de requisições, representada por um
 * objeto que contém as configurações utilizadas pelo {@link external:axios axios}. Ele é
 * apenas removido da fila quando este _plug-in_ não possui um serviço de
 * autenticação associado ou quando a sessão do usuário logado ainda não
 * expirou. Caso contrário, uma requisição para atualizar a sessão é enviada e
 * a fila é travada até que a atualização da sessão seja concluída. Em caso de
 * a sessão ser atualizada com sucesso, o objeto que representa uma requisição
 * é removido da fila. Essa solução foi baseada no uso de semáforos, adaptada de:
 *
 * https://medium.com/swlh/semaphores-in-javascript-e415b0d684bc
 *
 * Copyright 2020 Weiming Wu
 * Permission is hereby granted, free of charge, to any person obtaining a
 * copy of this software and associated documentation files (the "Software"),
 * to deal in the Software without restriction, including without limitation
 * the rights to use, copy, modify, merge, publish, distribute, sublicense,
 * and/or sell copies of the Software, and to permit persons to whom the
 * Software is furnished to do so, subject to the following conditions:
 *
 * The above copyright notice and this permission notice shall be included in
 * all copies or substantial portions of the Software.
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 * IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
 * AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
 * LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING
 * FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER
 * DEALINGS IN THE SOFTWARE.
 * @param {module:plugins/Http/Http~Http} http - O próprio namespace.
 * @ignore
 */
const executeNext = function(http) {
  if (!queue.length) {
    return;
  }

  if (http.AuthenticationService.isAuthenticated()) {
    const { resolve, config } = dequeue();
    resolve(http.request(config));
    executeNext(http);
  } else {
    http.AuthenticationService.refresh()
      .then(() => {
        const { resolve, config } = dequeue();
        resolve(http.request(config));
      })
      .catch(error => {
        window.console.warn(error.message);
      })
      .finally(() => executeNext(http));
  }
};

/**
 * Lança uma exceção cuja mensagem é definida a partir da fonte causadora do
 * erro.
 * @param {object} error - Objeto de erro.
 * @throws {external:Error} Objeto de erro com a mensagem obtida dependendo da origem
 * do erro.
 * @ignore
 */
const handleError = function(error) {
  let message = null;

  // Baseado na documentação do axios: https://github.com/axios/axios#handling-errors
  // Quando o back-end recebeu a requisição e
  // retornou a mensagem de erro no corpo da resposta.
  if (error.response) {
    let errors = [];

    const data = error.response.data || {};

    message = data.message || data.mensagem;

    if (!isEmpty(data.ocorrencias)) {
      if (data.ocorrencias.length === 1) {
        message = escapeHtml(data.ocorrencias[0].mensagem);
      } else {
        errors = data.ocorrencias.map(
          item => `<li>${escapeHtml(item.mensagem)}</li>`
        );
      }
    }

    if (errors.length) {
      message =
        (message ? escapeHtml(message) : "") +
        '<br /><ul class="browser-default">' +
        errors.join("\n") +
        "</ul>";
    }

    if (!message) {
      const status = error.response.status;

      switch (status) {
        case HTTP_STATUS_CLIENT_ERROR_BAD_REQUEST:
          message = "Dados enviados mal formados";
          break;
        // Não deve acontecer em situações normais
        case HTTP_STATUS_CLIENT_ERROR_UNAUTHORIZED:
          message = "Sessão inválida";
          break;
        // Não deve acontecer em situações normais
        case HTTP_STATUS_CLIENT_ERROR_FORBIDDEN:
          message = "Sem permissão para realizar a operação";
          break;
        case HTTP_STATUS_CLIENT_ERROR_NOT_FOUND:
          message = "Recurso não encontrado";
          break;
        case HTTP_STATUS_CLIENT_ERROR_METHOD_NOT_ALLOWED:
          message = "Operação não permitida";
          break;
        case HTTP_STATUS_CLIENT_ERROR_CONFLICT:
          message = "Conflito com o recurso já existente";
          break;
        case HTTP_STATUS_CLIENT_ERROR_UNSUPPORTED_MEDIA_TYPE:
          message = "Tipo de mídia solicitada não suportado";
          break;
        case HTTP_STATUS_CLIENT_ERROR_UNPROCESSABLE_ENTITY:
          message = "Dados enviados em desacordo com as regras implementadas";
          break;
        case HTTP_STATUS_SERVER_ERROR_INTERNAL_SERVER_ERROR:
          message = "Erro interno do servidor";
          break;
        case HTTP_STATUS_SERVER_ERROR_NOT_IMPLEMENTED:
          message = "Operação não implementada";
          break;
        case HTTP_STATUS_SERVER_ERROR_BAD_GATEWAY:
          message = "Resposta inválida retornada pelo servidor";
          break;
        case HTTP_STATUS_SERVER_ERROR_SERVICE_UNAVAILABLE:
          message = "Servidor indisponível";
          break;
        case HTTP_STATUS_SERVER_ERROR_GATEWAY_TIMEOUT:
          message = "Excedido o tempo de resposta do servidor";
          break;
        default:
          message = "Houve um erro ao realizar a operação";
          break;
      }
    }
  }
  // Quando o browser enviou a requisição, mas não foi obtida resposta.
  else if (error.request) {
    message = error.message
      ? error.message
      : "Houve um erro ao realizar a requisição ao servidor";
  }
  // Ocorreu algum outro tipo de erro.
  else {
    message = error.message || error.mensagem;
  }

  throw new Error(message);
};

/**
 * @namespace Http
 * @category Plug-ins
 * @summary Objeto/_namespace_ do _plug-in_ de requisições HTTP.
 */
const Http = {
  /**
   * Opções definidas durante a associação do _plug-in_ à instância do Vue.
   * @type {object}
   * @readonly
   * @default {}
   */
  options: {},

  /**
   * Constante que caracteriza o _status_ HTTP "100 Continue".
   * @type {number}
   * @readonly
   * @default 100
   */
  HTTP_STATUS_INFO_CONTINUE,

  /**
   * Constante que caracteriza o _status_ HTTP "101 Switching Protocols".
   * @type {number}
   * @readonly
   * @default 101
   */
  HTTP_STATUS_INFO_SWITCHING_PROTOCOLS,

  /**
   * Constante que caracteriza o _status_ HTTP "200 OK".
   * @type {number}
   * @readonly
   * @default 200
   */
  HTTP_STATUS_SUCCESSFUL_OK,

  /**
   * Constante que caracteriza o _status_ HTTP "201 Created".
   * @type {number}
   * @readonly
   * @default 201
   */
  HTTP_STATUS_SUCCESSFUL_CREATED,

  /**
   * Constante que caracteriza o _status_ HTTP "202 Accepted".
   * @type {number}
   * @readonly
   * @default 202
   */
  HTTP_STATUS_SUCCESSFUL_ACCEPTED,

  /**
   * Constante que caracteriza o _status_ HTTP "204 No Content".
   * @type {number}
   * @readonly
   * @default 204
   */
  HTTP_STATUS_SUCCESSFUL_NO_CONTENT,

  /**
   * Constante que caracteriza o _status_ HTTP "301 Moved Permanently".
   * @type {number}
   * @readonly
   * @default 301
   */
  HTTP_STATUS_REDIRECTION_MOVED_PERMANENTLY,

  /**
   * Constante que caracteriza o _status_ HTTP "302 Found".
   * @type {number}
   * @readonly
   * @default
   * @ignore
   */
  HTTP_STATUS_REDIRECTION_FOUND,

  /**
   * Constante que caracteriza o _status_ HTTP "303 See Other".
   * @type {number}
   * @readonly
   * @default 303
   */
  HTTP_STATUS_REDIRECTION_SEE_OTHER,

  /**
   * Constante que caracteriza o _status_ HTTP "304 Not Modified".
   * @type {number}
   * @readonly
   * @default 304
   */
  HTTP_STATUS_REDIRECTION_NOT_MODIFIED,

  /**
   * Constante que caracteriza o _status_ HTTP "400 Bad Request".
   * @type {number}
   * @readonly
   * @default 400
   */
  HTTP_STATUS_CLIENT_ERROR_BAD_REQUEST,

  /**
   * Constante que caracteriza o _status_ HTTP "401 Unauthorized".
   * @type {number}
   * @readonly
   * @default 401
   */
  HTTP_STATUS_CLIENT_ERROR_UNAUTHORIZED,

  /**
   * Constante que caracteriza o _status_ HTTP "403 Forbidden".
   * @type {number}
   * @readonly
   * @default 403
   */
  HTTP_STATUS_CLIENT_ERROR_FORBIDDEN,

  /**
   * Constante que caracteriza o _status_ HTTP "404 Not Found ".
   * @type {number}
   * @readonly
   * @default 404
   */
  HTTP_STATUS_CLIENT_ERROR_NOT_FOUND,

  /**
   * Constante que caracteriza o _status_ HTTP "405 Method Not Allowed".
   * @type {number}
   * @readonly
   * @default 405
   */
  HTTP_STATUS_CLIENT_ERROR_METHOD_NOT_ALLOWED,

  /**
   * Constante que caracteriza o _status_ HTTP "409 Conflict".
   * @type {number}
   * @readonly
   * @default 409
   */
  HTTP_STATUS_CLIENT_ERROR_CONFLICT,

  /**
   * Constante que caracteriza o _status_ HTTP "415 Unsupported Media Type".
   * @type {number}
   * @readonly
   * @default 415
   */
  HTTP_STATUS_CLIENT_ERROR_UNSUPPORTED_MEDIA_TYPE,

  /**
   * Constante que caracteriza o _status_ HTTP "422 Unprocessable Entity".
   * @type {number}
   * @readonly
   * @default 422
   */
  HTTP_STATUS_CLIENT_ERROR_UNPROCESSABLE_ENTITY,

  /**
   * Constante que caracteriza o _status_ HTTP "500 Internal Server Error".
   * @type {number}
   * @readonly
   * @default 500
   */
  HTTP_STATUS_SERVER_ERROR_INTERNAL_SERVER_ERROR,

  /**
   * Constante que caracteriza o _status_ HTTP "501 Not Implemented".
   * @type {number}
   * @readonly
   * @default 501
   */
  HTTP_STATUS_SERVER_ERROR_NOT_IMPLEMENTED,

  /**
   * Constante que caracteriza o _status_ HTTP "502 Bad Gateway".
   * @type {number}
   * @readonly
   * @default 502
   */
  HTTP_STATUS_SERVER_ERROR_BAD_GATEWAY,

  /**
   * Constante que caracteriza o _status_ HTTP "503 Service Unavailable".
   * @type {number}
   * @readonly
   * @default 503
   */
  HTTP_STATUS_SERVER_ERROR_SERVICE_UNAVAILABLE,

  /**
   * Constante que caracteriza o _status_ HTTP "504 Gateway Timeout".
   * @type {number}
   * @readonly
   * @default 504
   */
  HTTP_STATUS_SERVER_ERROR_GATEWAY_TIMEOUT,

  /**
   * _Namespace_ de um serviço de autenticação.
   * @type {object}
   * @readonly
   * @default null
   */
  AuthenticationService: null,

  /**
   * Instância do axios.
   * @type {external:axios}
   * @readonly
   */
  axios,

  /**
   * Interceptador de requisições a ser usado pela instância do {@link external:axios axios}
   * deste _plug-in_.
   * @type {object}
   * @readonly
   * @default null
   */
  requestInterceptor: null,

  /**
   * Interceptador de respostas a ser usado pela instância do {@link external:axios axios}
   * deste _plug-in_.
   * @type {object}
   * @readonly
   * @default null
   */
  responseInterceptor: null,

  /**
   * Realiza uma requisição HTTP.
   * @param {object} config - Objeto com as opções aceitas pelo {@link external:axios axios}.
   * @returns {external:Promise} - Dados da requisição.
   */
  request(config) {
    return this.axios.request(config).catch(error => handleError(error));
  },

  /**
   * Realiza uma requisição com método HTTP GET.
   * @param {string} url - Endereço (URL) do servidor a ser feita a requisição.
   * @param {object} config - Objeto com as opções aceitas pelo {@link external:axios axios}.
   * @returns {external:Promise} - Dados da requisição.
   */
  get(url, config) {
    return this.request({
      url,
      method: "get",
      ...config
    });
  },

  /**
   * Realiza uma requisição com método HTTP POST.
   * @param {string} url - Endereço (URL) do servidor a ser feita a requisição.
   * @param {object} data - Dados a serem enviados ao servidor.
   * @param {object} config - Objeto com as opções aceitas pelo {@link external:axios axios}.
   * @returns {external:Promise} - Dados da requisição.
   */
  post(url, data, config) {
    return this.request({
      url,
      method: "post",
      data,
      ...config
    });
  },

  /**
   * Realiza uma requisição com método HTTP PUT.
   * @param {string} url - Endereço (URL) do servidor a ser feita a requisição.
   * @param {object} data - Dados a serem enviados ao servidor.
   * @param {object} config - Objeto com as opções aceitas pelo {@link external:axios axios}.
   * @returns {external:Promise} - Dados da requisição.
   */
  put(url, data, config) {
    return this.request({
      url,
      method: "put",
      data,
      ...config
    });
  },

  /**
   * Realiza uma requisição com método HTTP PATCH.
   * @param {string} url - Endereço (URL) do servidor a ser feita a requisição.
   * @param {object} data - Dados a serem enviados ao servidor.
   * @param {object} config - Objeto com as opções aceitas pelo {@link external:axios axios}.
   * @returns {external:Promise} - Dados da requisição.
   */
  patch(url, data, config) {
    return this.request({
      url,
      method: "patch",
      data,
      ...config
    });
  },

  /**
   * Realiza uma requisição com método HTTP DELETE.
   * @param {string} url - Endereço (URL) do servidor a ser feita a requisição.
   * @param {object} config - Objeto com as opções aceitas pelo {@link external:axios axios}.
   * @returns {external:Promise} - Dados da requisição.
   */
  delete(url, config) {
    return this.request({
      url,
      method: "delete",
      ...config
    });
  },

  /**
   * Indica se um _status_ HTTP é informativo.
   * @param {number} status - _Status_ HTTP
   * @returns {boolean}
   */
  isInformationalStatus(status) {
    return status >= 100 && status <= 199;
  },

  /**
   * Indica se um _status_ HTTP é de sucesso.
   * @param {number} status - _Status_ HTTP
   * @returns {boolean}
   */
  isSuccessfulStatus(status) {
    return status >= 200 && status <= 299;
  },

  /**
   * Indica se um _status_ HTTP é de redirecionamento.
   * @param {number} status - _Status_ HTTP
   * @returns {boolean}
   */
  isRedirectionStatus(status) {
    return status >= 300 && status <= 399;
  },

  /**
   * Indica se um _status_ HTTP é de erro de cliente.
   * @param {number} status - _Status_ HTTP
   * @returns {boolean}
   */
  isClientErrorStatus(status) {
    return status >= 400 && status <= 499;
  },

  /**
   * Indica se um _status_ HTTP é de erro de servidor.
   * @param {number} status - _Status_ HTTP
   * @returns {boolean}
   */
  isServerErrorStatus(status) {
    return status >= 500 && status <= 599;
  },

  /**
   * Atribui um _interceptor_ à requisições feitas pelo {@link external:axios axios} para atribuir
   * o token de acesso ao cabeçalho da requisição.
   * @returns {module:plugins/Http/Http~Http} - O próprio namespace.
   */
  registerRequestInterceptor() {
    if (!this.AuthenticationService) {
      window.console.warn(
        "Não foi registrado um serviço de autenticação para o plug-in de requisição HTTP. O cabeçalho de autorização não será definido nas requisições e, por isso, algumas delas não serão autorizadas"
      );
    }

    if (!this.requestInterceptor) {
      this.requestInterceptor = this.axios.interceptors.request.use(
        config => {
          if (this.AuthenticationService) {
            // Algumas requisições não exigem dados de autenticação
            if (!isEmpty(this.AuthenticationService.getAuthenticationData())) {
              config.headers.Authorization = (
                this.AuthenticationService.getAuthenticationData() || {}
              ).accessToken;
            }
          }

          return config;
        },
        error => Promise.reject(error)
      );
    }

    return this;
  },

  /**
   * Atribui um interceptor às respostas do {@link external:axios axios} para
   * realizar, automaticamente, a autenticação do usuário caso o retorno seja
   * "403 Forbidden".
   * @returns {module:plugins/Http/Http~Http} - O próprio namespace.
   */
  registerResponseInterceptor() {
    if (!this.AuthenticationService) {
      window.console.warn(
        "Não foi registrado um serviço de autenticação para o plug-in de requisição HTTP. O cabeçalho de autorização não será definido nas requisições e, por isso, algumas delas não serão autorizadas"
      );
    }

    if (!this.responseInterceptor) {
      this.responseInterceptor = this.axios.interceptors.response.use(
        response => response,
        error => {
          if (
            error.response &&
            error.response.status === this.HTTP_STATUS_CLIENT_ERROR_UNAUTHORIZED
          ) {
            if (this.AuthenticationService) {
              // Algumas requisições não exigem dados de autenticação
              if (
                !isEmpty(this.AuthenticationService.getAuthenticationData())
              ) {
                // Somente enfileira a requisição caso a sessão ainda seja válida,
                // i.e., caso ainda seja possível atualizar o token
                if (this.AuthenticationService.isSessionValid()) {
                  return new Promise((resolve, reject) => {
                    enqueue({ resolve, reject, config: error.config });
                    executeNext(this);
                  });
                } else {
                  window.location.assign(`${HOST_URL}/login`);
                }
              }
            }
          }

          return Promise.reject(error);
        }
      );
    }

    return this;
  }
};

export default Http;
