<template>
  <nav v-bind:class="[color, luminosity]">
    <div class="nav-wrapper">
      <form
        v-bind:id="formId"
        v-bind:name="formName"
        v-on:submit.prevent="onSubmit"
      >
        <BaseTextInput
          v-bind:id="inputId"
          type="search"
          grid-expression=""
          v-bind:name="inputName"
          v-bind:disabled="disabled"
          v-bind:placeholder="placeholder"
          v-bind:autofocus="autofocus"
          v-bind:title="$attrs.title"
          label-class="label-icon"
          v-model="searchValue"
          v-on="listeners"
        >
          <template v-slot:default>
            <i v-if="showIcon" class="material-icons">{{ iconName }}</i>
          </template>
          <template v-slot:extra-content>
            <a
              v-if="showClearButton"
              href="#!"
              title="Limpar campo"
              v-on:click.prevent="clear"
              ><i class="material-icons">close</i></a
            >
          </template>
        </BaseTextInput>
      </form>
    </div>
  </nav>
</template>

<script>
/**
 * @module components/base/BaseSearchBar/BaseSearchBar
 * @category Componentes-base
 * @summary _Single File Component_ (SFC) de barra de busca.
 *
 * @description
 * Possui um elemento HTML `form` com um único `input` responsável pelo
 * conteúdo a ser buscado. Ao submeter o formulário, o componente emite o
 * evento para que possa ser capturado externamente e, com isso, ter a busca
 * realizada.
 *
 * Este componente não possui _slot_ _default_.
 *
 * Atributos HTML de acessibilidade, e.g., `title`, podem ser usados
 * diretamente no componente.
 *
 * @requires module:components/base/BaseTextInput/BaseTextInput
 *
 * @vue-prop {string} [color] - Classe do {@link external:Materialize Materialize}
 * para a cor de fundo padrão do componente.
 * @vue-prop {string} [luminosity] - Classe do {@link external:Materialize Materialize}
 * para a luminosidade/nível de cor de fundo padrão do componente.
 * @vue-prop {string} [formId] - Atributo `id` do elemento HTML `form`
 * utilizado internamente pelo componente.
 * @vue-prop {string} [formName] - Atributo `name` do elemento HTML `form`
 * utilizado internamente pelo no componente.
 * @vue-prop {string} [inputId] - Atributo `id` do elemento HTML `input`
 * utilizado internamente pelo componente.
 * @vue-prop {string} [inputName] - Atributo `name` do elemento HTML `input`
 * utilizado internamente pelo componente.
 * @vue-prop {boolean} [disabled=false] - Indica se o componente estará desabilitado.
 * @vue-prop {string} [placeholder] - Atributo `placeholder` do elemento HTML
 * utilizado internamente pelo componente.
 * @vue-prop {boolean} [showClearButton=true] - Indica se o componente deve exibir o
 * botão de fechar.
 * @vue-prop {boolean} [showIcon=true] - Indica se o componente deve exibir o
 * ícone especificado.
 * @vue-prop {string} [iconName="search"] - Nome do [ícone do Material Design]{@link external:MaterialDesignIcons}
 * a ser exibido na lateral esquerda da barra.
 * @vue-prop {boolean} [autofocus=false] - Indica se o elemento HTML `input`
 * utilizado internamente pelo componente receberá o evento de foco automaticamente.
 * @vue-prop {string} [value] - Valor inicial do elemento HTML `input`
 * utilizado internamente pelo componente.
 * @vue-event {string} onInput - Emite o valor atual do elemento HTML `input`
 * utilizado internamente pelo componente. Pode ser capturado pela diretiva
 * `v-on:input` (ou `v-model`) do {@link external:Vue Vue}.
 * @vue-event {string} onSubmit - Emite o valor atual do elemento HTML `input`
 * utilizado internamente pelo componente e indica que a busca deve ser
 * realizada. O modificador `prevent` já é utilizado, evitando que o
 * comportamento padrão do evento `submit` seja realizado. Pode ser capturado
 * pela diretiva `v-on:submit` do {@link external:Vue Vue}.
 *
 * @example
 * <BaseSearchBar
 *  color="teal"
 *  luminosity="darken-1"
 *  input-id="busca-servidor"
 *  placeholder="Busca por nome, matrícula siape ou CPF"
 *  v-on:submit="searchFunction" />
 */
import BaseTextInput from "../BaseTextInput/BaseTextInput.vue";

export default {
  name: "BaseSearchBar",
  components: {
    BaseTextInput
  },
  inheritAttrs: false,
  props: {
    color: String,
    luminosity: String,
    formId: String,
    formName: String,
    inputId: String,
    inputName: String,

    disabled: {
      type: Boolean,
      default: false
    },

    placeholder: String,

    showClearButton: {
      type: Boolean,
      default: true
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    iconName: {
      type: String,
      default: "search"
    },

    autofocus: {
      type: Boolean,
      default: false
    },

    value: String
  },
  data() {
    return {
      searchValue: null
    };
  },
  computed: {
    listeners() {
      return Object.assign({}, this.$listeners, { input: this.onInput });
    }
  },
  watch: {
    value: {
      immediate: true,

      handler(newValue) {
        this.searchValue = newValue;
      }
    }
  },
  methods: {
    clear() {
      this.searchValue = null;
      this.$emit("input", this.searchValue);
    },
    onInput(event) {
      this.$emit("input", event);
    },
    onSubmit() {
      this.$emit("submit", this.searchValue);
    }
  }
};
</script>

<style scoped>
/* Correção para browsers baseados no WebKit */
nav /deep/ .input-field {
  height: inherit;
}

/* Correção para browsers baseados no WebKit */
@media screen and (max-width: 600px) {
  nav /deep/ .input-field {
    height: inherit;
  }
}

nav /deep/ .input-field input[type="search"] ~ a {
  position: absolute;
  top: 0;
  right: 1rem;
  color: #444;
}

nav /deep/ .input-field label i.material-icons {
  color: #444;
}

/*
Quando usa-se autocomplete, um elemento é criado entre as tags input e label. Com isso,
a regra do Materialize CSS não é aplicada, já que um adjacent (+) sibling combinator é utilizado.
Portanto, usa-se o general (~) sibling combinator
*/
nav /deep/ .input-field input[type="search"] ~ .label-icon {
  -webkit-transform: none;
  transform: none;
  left: 1rem;
}

.contrast nav /deep/ .input-field i.material-icons {
  background-color: transparent !important;
}
</style>
