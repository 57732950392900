/**
 * @module components/base/BaseCollection
 * @category Componentes-base
 * @summary Módulo de importação do componente-base de coleções do {@link external:Materialize Materialize}.
 *
 * @description
 * Expõe o componente-base de coleções do {@link external:Materialize Materialize}
 * para utilização pelo container e pelos módulos.
 *
 * @example <caption>Importando como plug-in</caption>
 * // main.js
 * import BaseCollection from "./components/base/BaseCollection";
 *
 * Vue.use(BaseCollection);
 *
 * @example <caption>Importando diretamente em um componente</caption>
 * // componente.vue
 * // ou import BaseCollection from "./components/base/BaseCollection/BaseCollection.vue";
 * import { BaseCollection } from "./components/base/BaseCollection";
 *
 * export default {
 *  name: // ...
 *  components: {
 *    BaseCollection
 *  },
 *  // ...
 * }
 */
import { default as BaseCollection } from "./BaseCollection.vue";

/**
 * Associa o componente-base de input à instância do {@link external:Vue Vue}.
 * @param {external:Vue} Vue - Instância do {@link external:Vue Vue}.
 */
const install = Vue => Vue.component(BaseCollection.name, BaseCollection);

if (window && window.Vue) {
  install(window.Vue);
}

export default install;

export { BaseCollection };
