<template>
  <article>
    <h5>
      <i class="material-icons left" v-bind:title="icon.title">{{
        icon.name
      }}</i>
      {{ login }}
    </h5>

    <p v-if="bloqueado" class="yellow-text text-darken-3">
      Usuário ainda não acessou o sistema ou solicitou nova senha.
    </p>

    <section class="section">
      <h6>Pessoa responsável</h6>

      <p>
        <b>Nome:</b>&nbsp;
        <i v-if="hasNomeSocial" class="material-icons right" title="Nome social"
          >info</i
        >
        {{ nomeExibicao }}
        <br />
        <b>{{ identificador.title }}:</b>&nbsp;
        {{ identificador.value }}
        <br />
        <b>E-mail:</b>&nbsp;
        {{ pessoa.email }}
      </p>
    </section>

    <div v-if="hasAnyEvent" class="buttons-row">
      <BaseButton
        v-if="hasEventDeleteEditor"
        v-bind="buttonDeleteEditor"
        v-on:click="deleteEditor"
      />
    </div>
  </article>
</template>

<script>
import { UsuarioService } from "../../services";

export default {
  name: "BaseUsuario",
  inheritAttrs: false,
  props: {
    readonly: Boolean,

    id: {
      type: Number,
      required: true
    },
    tipoUsuarioId: {
      type: Number,
      required: true
    },
    login: {
      type: String,
      required: true
    },

    bloqueado: Boolean,

    pessoa: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      buttonDeleteEditor: {
        iconName: "delete",
        color: "red",
        content: "Excluir",
        title: "Remover editor do estande"
      }
    };
  },
  computed: {
    icon() {
      switch (this.tipoUsuarioId) {
        case UsuarioService.SERVIDOR:
          return { name: "person", title: "Usuário servidor" };
        case UsuarioService.EXTERNO:
          return { name: "business", title: "Usuário externo" };
        default:
          return {
            name: "question_mark",
            title: "Tipo de usuário desconhecido"
          };
      }
    },
    hasNomeSocial() {
      return !!this.pessoa.nomeSocial;
    },
    nomeExibicao() {
      return this.pessoa.nomeExibicao;
    },
    identificador() {
      if (this.pessoa.cpf) {
        return {
          title: "CPF",
          value: this.pessoa.cpf
        };
      } else {
        return {
          title: "Passaporte",
          value: this.pessoa.passaporte
        };
      }
    },
    hasEventDeleteEditor() {
      return typeof this.$listeners["delete-editor"] === "function";
    },
    hasAnyEvent() {
      return !!this.$listeners.length || this.hasEventDeleteEditor;
    }
  },
  methods: {
    deleteEditor() {
      this.$emit("delete-editor", this.id);
    }
  }
};
</script>

<style scoped>
.buttons-row {
  justify-content: flex-end;
}
</style>
