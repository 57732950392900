/**
 * @module plugins
 * @category Plug-ins
 * @summary Módulo de importação de _plug-ins_.
 *
 * @description
 * Expõe os _plug-ins_ do sistema para utilização pelo container e pelos módulos.
 *
 * Este módulo funciona como um _plug-in_ do {@link external:Vue Vue}, permitindo
 * a importação de todos os _plug-ins_ do sistema. Opcionalmente, é possível
 * importar cada _plug-in_ do sistema individualmente.
 *
 * Cada _plug-in_ do sistema também funciona como um _plug-in_ do {@link external:Vue Vue},
 * permitindo sua importação, associando-o ao objeto `Vue` global, ou
 * importando-o para uso interno em outro local do código. No primeiro caso,
 * torna-se possível acessar um _plug-in_ de dentro de componentes através de
 * propriedades iniciadas por "$".
 *
 * Nos casos em que o {@link external:Vue Vue} é carregado através de um
 * elemento HTML `script`, este módulo automaticamente importa os
 * componentes-base para o objeto `Vue` global.
 *
 * @example <caption>Importando os plug-ins de requisições HTTP e de notificações</caption>
 * import { Http, Notification } from "./plugins";
 *
 * Vue.use(Http);
 * Vue.use(Notification);
 */
import { default as Authorization } from "./Authorization";
import { default as Http } from "./Http";
import { default as Notification } from "./Notification";
import { default as Utils } from "./Utils";

const plugins = {
  Authorization,
  Http,
  Notification,
  Utils
};

/**
 * Associa todos os _plug-ins_ do sistema à instância do {@link external:Vue Vue}.
 * @param {external:Vue} Vue - Instância do {@link external:Vue Vue}.
 * @param {object} options - Objeto cujas propriedades são os nomes dos
 * _plug-in_ e os valores são as opções para cada _plug-in_.
 */
const install = function(Vue, options = {}) {
  Object.keys(plugins).forEach(name => {
    Vue.use(plugins[name], options[name]);
  });
};

if (window && window.Vue) {
  install(window.Vue);
}

export default install;

export { Authorization } from "./Authorization";
export { Http } from "./Http";
export { Notification } from "./Notification";
export { Utils } from "./Utils";
