<template>
  <div ref="pushpin" class="pushpin-wrapper pin-top">
    <slot></slot>
  </div>
</template>

<script>
/**
 * @module components/base/BasePushpin/BasePushpin
 * @category Componentes-base
 * @summary _Single File Component_ (SFC) de _pushpin_ do {@link external:Materialize Materialize}.
 *
 * @description
 * Este componente ativa a funcionalidade de _pushpin_ do {@link external:Materialize Materialize}
 * em todos os elementos HTML que componham o conteúdo do componente.
 *
 * O _slot_ _default_ recebe os elementos HTML que devem possuir o
 * comportamento do _pushpin_.
 *
 * Para correto funcionamento, é necessário que o _framework_ {@link external:Materialize Materialize}
 * tenha sido importado, tornando seu objeto principal acessível globalmente.
 *
 * @vue-prop {boolean} [calcTop=false] - Indica se o componente-base calculará
 * a propriedade `top` do _pushpin_ do {@link external:Materialize Materialize}.
 * @vue-prop {boolean} [calcBottom=false] - Indica se o componente-base calculará
 * a propriedade `bottom` do _pushpin_ do {@link external:Materialize Materialize}.
 * @vue-prop {string} targetId - Identificador do elemento HTML para referência
 * (posição base) para o _pushpin_. Quando não informado, o elemento pai deste
 * componente será utilizado.
 * @vue-prop {object} [pushpinOptions] - Opções aceitas pelo _pushpin_ do
 * {@link external:Materialize Materialize}. Caso as propriedades `top` e
 * `bottom` não sejam informadas, as mesmas são calculadas automaticamente pela
 * diretiva.
 *
 * @example
 * <BasePushpin v-bind:calc-top="true">
 *  <ul class="section table-of-contents">
 *    ...
 *  </ul>
 *  ...
 * </BasePushpin>
 */
export default {
  name: "BasePushpin",
  inheritAttrs: false,
  props: {
    calcTop: {
      type: Boolean,
      default: false
    },
    calcBottom: {
      type: Boolean,
      default: false
    },

    targetId: String,
    pushpinOptions: Object
  },
  data() {
    return {
      instance: null
    };
  },
  beforeCreate() {
    if (!window.M) {
      window.console.error(
        "Objeto do Materialize não encontrado. Verifique se essa biblioteca foi carregada corretamente"
      );
    }
  },
  mounted() {
    if (!window.M) {
      return;
    }

    const { pushpin } = this.$refs;

    const newOptions = Object.assign({}, this.pushpinOptions);

    const pushpinTarget = this.targetId
      ? document.getElementById(this.targetId)
      : null;

    if (
      this.calcTop &&
      (newOptions.top === null || newOptions.top === undefined)
    ) {
      newOptions.top = (pushpinTarget || pushpin.parentNode).offsetTop;
    }

    if (
      this.calcBottom &&
      (newOptions.bottom === null || newOptions.bottom === undefined)
    ) {
      newOptions.bottom =
        pushpin.offsetTop -
        (pushpinTarget || pushpin.parentNode).offsetHeight -
        pushpin.offsetHeight;
    }

    this.instance = window.M.Pushpin.init(pushpin, newOptions);
  },
  beforeDestroy() {
    if (this.instance) {
      this.instance.destroy();
    }
  }
};
</script>

<style scoped>
.pin-top,
.pin-bottom {
  position: relative;
}

.pinned {
  position: fixed !important;
}
</style>
