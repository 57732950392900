/**
 * @module plugins/Notification/Notification
 * @category Plug-ins
 * @summary Módulo do _plug-in_ de notificações.
 *
 * @description
 * Expõe o objeto/_namespace_ do _plug-in_ de notificações para utilização pelo
 * container e pelos módulos.
 *
 * Para correto funcionamento, é necessário que o _framework_ {@link external:Materialize Materialize}
 * tenha sido importado, tornando seu objeto principal acessível globalmente.
 *
 * @example
 * // main.js
 * import Notification from "./plugins/Notification";
 *
 * Vue.use(Notification);
 *
 * // componente.vue
 * export default {
 *  // ...
 *  methods: {
 *    salvar() {
 *      if (!isFormValido()) {
 *        this.$notification.pushError("Dados inválidos")
 *      }
 *      // ...
 *    }
 *  }
 * }
 */

import { createStyleElement } from "../../utils/web";

/**
 * Objeto principal do {@link external:Materialize Materialize}.
 * @type {object}
 * @ignore
 */
const M = window ? window.M : null;

/**
 * Constante que caracteriza uma notificação com severidade de informação.
 * @type {string}
 * @constant
 * @default
 * @ignore
 */
const INFO = "info";

/**
 * Constante que caracteriza uma notificação com severidade de sucesso.
 * @type {string}
 * @constant
 * @default
 * @ignore
 */
const SUCCESS = "success";

/**
 * Constante que caracteriza uma notificação com severidade de aviso.
 * @type {string}
 * @constant
 * @default
 * @ignore
 */
const WARNING = "warning";

/**
 * Constante que caracteriza uma notificação com severidade de erro.
 * @type {string}
 * @constant
 * @default
 * @ignore
 */
const ERROR = "error";

/**
 * Regras CSS para os _toasts_.
 * @type {string}
 * @ignore
 */
const CSS_STYLESHEET = `
.contrast #toast-container {
  background: transparent !important;
}

.contrast div.toast {
  background: black !important;
  color: white !important;
  border: 1px solid white;
}
`;

/**
 * Atributo `id` do elemento HTML `style` contendo as regras CSS para os
 * _toasts_.
 * @type {string}
 * @ignore
 */
const CSS_STYLESHEET_ID = "stylesheet-notification";

/**
 * Exibe uma notificação a partir do método `toast` do {@link external:Materialize Materialize}.
 * @param {object} options - Objeto com opções aceitas pelo método `toast` do
 * {@link external:Materialize Materialize}.
 * @returns {object} Objeto `toast` do {@link external:Materialize Materialize}.
 * @ignore
 */
const push = function(options) {
  if (!M) {
    window.console.error(
      "Objeto do Materialize não encontrado. Verifique se essa biblioteca foi carregada corretamente"
    );
    return;
  }

  return M.toast(options);
};

/**
 * Gera as classes de CSS dependendo do nível de severidade.
 * @param {string} severity - Nível de severidade da notificação.
 * @ignore
 */
const buildClasses = function(severity) {
  switch (severity) {
    case INFO:
      return "blue lighten-3 blue-text text-darken-4";
    case SUCCESS:
      return "green lighten-3 green-text text-darken-4";
    case WARNING:
      return "yellow lighten-3 yellow-text text-darken-4";
    case ERROR:
      return "red lighten-3 red-text text-darken-4";
    default:
      break;
  }

  return "";
};

/**
 * Gera as opções aceitas pelo `toast` do {@link external:Materialize Materialize}
 * a partir dos parâmetros.
 * @param {string} message - Mensagem a ser exibida na notificação.
 * @param {string} severity - Nível de severidade da notificação.
 * @param {number} displayLength - Duração (em ms) da notificação.
 * @param {function} callback - Função a ser chamada após o encerramento da
 * notificação.
 * @returns {object} Objeto com as opções aceitas pelo `toast` do
 * {@link external:Materialize Materialize}.
 * @ignore
 */
const buildCustomOptions = function(
  message,
  severity,
  displayLength,
  callback
) {
  return {
    html: message,
    classes: buildClasses(severity),
    displayLength: displayLength,
    completeCallback: callback
  };
};

/**
 * Calcula o tempo necessário de exibição de um `toast` do {@link external:Materialize Materialize}
 * a partir da extensão de um texto.
 * @param {string} message - Mensagem a ser exibida na notificação.
 * @param {number} min - Duração mínima em milissegundos. Valor padrão de 4000.
 * @param {number} max - Duração máxima em milissegundos. Valor padrão de 60000.
 * @returns {number} Tempo de exibição.
 * @ignore
 */
const calculateDisplayLength = function(message, min, max) {
  // Média de 1000 caracteres por minuto:
  // https://en.wikipedia.org/wiki/Words_per_minute#Reading_and_comprehension
  //
  // Em caso de refinamento:
  // https://iovs.arvojournals.org/article.aspx?articleid=2166061
  const CHARS_PER_MINUTE = 1000;

  const element = document.createElement("div");
  element.innerHTML = message;

  const sanitized = (element.innerText || "").replace(/\r\n|\n|\r|\t/g, "");

  const words = sanitized.split(" ").filter(item => item);

  const wordCount = words.length;
  const wordsLength = words.reduce((acc, item) => item.length + acc, 0);

  const wordAvgLength = wordsLength / (wordCount || 1);

  // Quantidade de caracteres por milissegundo: CHARS_PER_MINUTE / 60000
  // Regra de três: CHARS_PER_MINUTE / 60000 = (wordAvgLength * wordCount) / x
  const displayLength = (wordAvgLength * wordCount * 60000) / CHARS_PER_MINUTE;

  return Math.max(min || 4000, Math.min(max || 60000, displayLength));
};

/**
 * Gera um objeto de opções mesclando as opções definidas durante a associação
 * do _plug-in_ à instancia do {@link external:Vue Vue} e as opções construídas a partir dos
 * parâmetros escolhidos para uma instância de notificação.
 * @param {object} options - Opções definidas durante a associação do _plug-in_ à
 * instância do {@link external:Vue Vue}.
 * @param {object} customOptions - Opções construídas a partir dos parâmetros
 * [escolhidos para uma instância de notificação]{@link module:plugins/Notification/Notification~buildCustomOptions}.
 * @returns {object} Objeto com as opções mescladas.
 * @ignore
 */
const buildOptions = function(options, customOptions) {
  const obj = Object.assign({}, options);

  Object.keys(customOptions).forEach(key => {
    if (customOptions[key] !== undefined) {
      obj[key] = customOptions[key];
    }
  });

  return obj;
};

/**
 * @namespace Notification
 * @category Plug-ins
 * @summary Objeto/_namespace_ do _plug-in_ de notificações.
 * @requires module:utils/web.createStyleElement
 */
const Notification = {
  /**
   * Constante que caracteriza uma notificação com severidade de informação.
   * @type {string}
   * @readonly
   * @default "info"
   */
  INFO,

  /**
   * Constante que caracteriza uma notificação com severidade de sucesso.
   * @type {string}
   * @readonly
   * @default "success"
   */
  SUCCESS,

  /**
   * Constante que caracteriza uma notificação com severidade de aviso.
   * @type {string}
   * @readonly
   * @default "warning"
   */
  WARNING,

  /**
   * Constante que caracteriza uma notificação com severidade de erro.
   * @type {string}
   * @readonly
   * @default "error"
   */
  ERROR,

  /**
   * Opções definidas durante a associação do _plug-in_ à instância do Vue.
   * @type {object}
   * @readonly
   * @default {}
   */
  options: {},

  /**
   * Carrega as regras CSS dos _toasts_ criando um elemento HTML`style`.
   * @returns {module:plugins/Notification/Notification~Notification} - O próprio namespace.
   */
  loadCSSStyleSheet() {
    createStyleElement({
      id: CSS_STYLESHEET_ID,
      type: "text/css",
      innerHTML: CSS_STYLESHEET
    });

    return this;
  },

  /**
   * Exibe uma notificação a partir do método `toast` do {@link external:Materialize Materialize}.
   * Em casos em que a propriedade `displayLength` não foi informada pelo
   * parâmetro `options` nem pelo objeto `options` deste _plug-in_, o tempo de
   * duração do _toast_ é calculado a partir na extensão de seu texto.
   * @param {object} options - Objeto com opções aceitas pelo método `toast` do
   * {@link external:Materialize Materialize}.
   * @returns {object} Objeto `toast` do {@link external:Materialize Materialize}.
   */
  push(options) {
    if (!options || !options.html) {
      return;
    }

    const clickEvent = `
        const instance = window.M.Toast.getInstance(this.parentNode.parentNode);

        if (instance) {
            instance.dismiss();
        }
    `;

    options.html = `
        <div>${options.html}</div>
        <div><i class="material-icons right" style="cursor: pointer;" onclick="${clickEvent}">clear</i></div>
    `;

    if (!options.displayLength && !this.options.displayLength) {
      options.displayLength = calculateDisplayLength(options.html);
    }

    return push(buildOptions(this.options, options));
  },

  /**
   * Exibe uma notificação com nível de severidade de informação.
   * @param {string} message - Mensagem a ser exibida na notificação.
   * @param {number} displayLength - Duração (em ms) da notificação.
   * @param {function} callback - Função a ser chamada após o encerramento da notificação.
   * @returns {object} Objeto `toast` do {@link external:Materialize Materialize}.
   */
  pushInfo(message, displayLength, callback) {
    return this.push(
      buildCustomOptions(message, this.INFO, displayLength, callback)
    );
  },

  /**
   * Exibe uma notificação com nível de severidade de erro.
   * @param {string} message - Mensagem a ser exibida na notificação.
   * @param {number} displayLength - Duração (em ms) da notificação.
   * @param {function} callback - Função a ser chamada após o encerramento da notificação.
   * @returns {object} Objeto `toast` do {@link external:Materialize Materialize}.
   */
  pushError(message, displayLength, callback) {
    return this.push(
      buildCustomOptions(message, this.ERROR, displayLength, callback)
    );
  },

  /**
   * Exibe uma notificação com nível de severidade de sucesso.
   * @param {string} message - Mensagem a ser exibida na notificação.
   * @param {number} displayLength - Duração (em ms) da notificação.
   * @param {function} callback - Função a ser chamada após o encerramento da notificação.
   * @returns {object} Objeto `toast` do {@link external:Materialize Materialize}.
   */
  pushSuccess(message, displayLength, callback) {
    return this.push(
      buildCustomOptions(message, this.SUCCESS, displayLength, callback)
    );
  },

  /**
   * Exibe uma notificação com nível de severidade de aviso.
   * @param {string} message - Mensagem a ser exibida na notificação.
   * @param {number} displayLength - Duração (em ms) da notificação.
   * @param {function} callback - Função a ser chamada após o encerramento da notificação.
   * @returns {object} Objeto `toast` do {@link external:Materialize Materialize}.
   */
  pushWarning(message, displayLength, callback) {
    return this.push(
      buildCustomOptions(message, this.WARNING, displayLength, callback)
    );
  }
};

export default Notification;
