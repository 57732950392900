<template>
  <li>
    <BaseDropdown
      id="pae-header-navbar-login-dropdown"
      class="collection"
      trigger-element-id="pae-header-navbar-login-dropdown-trigger"
      v-bind:dropdown-options="{ constrainWidth: false }"
    >
      <li tabindex="0">
        <a
          href="#!"
          title="Acesso ao sistema via intranet da UFRJ"
          v-on:click.prevent="authenticateByCAS"
          >Acesso Interno (UFRJ)</a
        >
      </li>
      <li tabindex="0">
        <BaseRouterLink
          title="Acesso ao sistema para usuários externos de apoio aos eventos e estandes"
          v-bind:to="{ name: 'login' }"
          >Acesso Externo (Apoio)</BaseRouterLink
        >
      </li>
    </BaseDropdown>
    <a
      id="pae-header-navbar-login-dropdown-trigger"
      href="#!"
      data-target="pae-header-navbar-login-dropdown"
    >
      Entrar
    </a>
  </li>
</template>

<script>
import { HOST_URL } from "../constants";
import { AuthenticationService } from "../services";

export default {
  name: "TheHeaderNavbarLogin",
  inheritAttrs: false,
  methods: {
    authenticateByCAS() {
      return AuthenticationService.authenticateByCAS(HOST_URL);
    }
  }
};
</script>
