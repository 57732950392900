<template>
  <div class="row-fix row">
    <BaseTextInput
      v-bind="form.nome"
      v-bind:disabled="readonly"
      v-on:input="onInput('nome', $event)"
    />
  </div>
</template>

<script>
export default {
  name: "EditableSectioConteudoExtraItem",
  inheritAttrs: false,
  props: {
    readonly: Boolean,

    value: Object
  },
  data() {
    return {
      form: {
        nome: {
          id: this.buildElementId(this.value.id, "nome"),
          required: true,
          label: "Nome",
          value: this.value.nome
        }
      }
    };
  },
  methods: {
    buildElementId(itemId, name) {
      return itemId
        ? `editablesection-conteudo-${itemId}-${name}`
        : this.$utils.Object.uuid();
    },
    onInput(propertyName, event) {
      this.$emit("input", { ...this.value, [propertyName]: event });
    }
  }
};
</script>

<style scoped>
.row {
  margin-bottom: 0;
}
</style>
