/**
 * @module components/base/BaseChip
 * @category Componentes-base
 * @summary Módulo de importação do componente-base de _chip_ do {@link external:Materialize Materialize}.
 *
 * @description
 * Expõe o componente-base de _chip_ do {@link external:Materialize Materialize} para utilização pelo container e pelos
 * módulos.
 *
 * @example <caption>Importando como plug-in</caption>
 * // main.js
 * import BaseChip from "./components/base/BaseChip";
 *
 * Vue.use(BaseChip);
 *
 * @example <caption>Importando diretamente em um componente</caption>
 * // componente.vue
 * // ou import BaseChip from "./components/base/BaseChip/BaseChip.vue";
 * import { BaseChip } from "./components/base/BaseChip";
 *
 * export default {
 *  name: // ...
 *  components: {
 *    BaseChip
 *  },
 *  // ...
 * }
 */
import { default as BaseChip } from "./BaseChip.vue";

/**
 * Associa o componente-base de _chip_ à instância do {@link external:Vue Vue}.
 * @param {external:Vue} Vue - Instância do {@link external:Vue Vue}.
 */
const install = Vue => Vue.component(BaseChip.name, BaseChip);

if (window && window.Vue) {
  install(window.Vue);
}

export default install;

export { BaseChip };
