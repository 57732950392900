<template>
  <BaseModal v-bind="modalUsuario">
    <template v-slot:header>
      <h4>Editar permissões do usuário</h4>
    </template>

    <template v-slot:default>
      <BaseInfoCard title="Edição de permissões do usuário">
        <p>
          Para remover uma permissão do usuário, basta clicar no respectivo
          ícone de fechar. Para adicionar uma permissão, digite um trecho do
          nome da permissão e escolha o item desejado dentre as opções da lista
          suspensa que será exibida.
        </p>
      </BaseInfoCard>

      <!-- TODO: melhorar a forma de evitar o margin collapse -->
      <BaseUsuario v-if="usuario" v-bind="usuario" style="padding: 1px 0" />

      <BaseProgressBar v-if="loading" v-bind="progressBar"
        >Carregando permissões do usuário...</BaseProgressBar
      >
      <section v-else class="section">
        <h5>Permissões</h5>

        <div class="row">
          <BaseAutocomplete
            v-bind="form.autocomplete"
            v-bind:disabled="saving"
          />
        </div>

        <BaseChip
          v-for="(item, index) in usuarioPermissoes"
          v-bind:key="index"
          v-bind:id="`permissaousuario-${item.id}`"
          tabindex="0"
          v-on:delete="deletePermissao(index)"
          >{{ item.nome }}</BaseChip
        >
      </section>
    </template>

    <template v-slot:footer>
      <BaseButton
        v-bind:disabled="saving"
        class="modal-close"
        material-type="flat"
        title="Cancelar alterações de permissões do usuário"
        >Cancelar</BaseButton
      >
      <BaseButton
        v-bind:disabled="saving"
        material-type="flat"
        title="Salvar alterações de permissões do usuário"
        v-on:click="save"
        >Salvar</BaseButton
      >
    </template>
  </BaseModal>
</template>

<script>
import { UsuarioService } from "../../services";
import BaseUsuario from "./BaseUsuario.vue";

export default {
  name: "UsuarioPermissoesModal",
  components: {
    BaseUsuario
  },
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      required: true
    },
    permissoes: {
      type: Array,
      required: true
    },
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      saving: false,
      usuario: null,
      usuarioPermissoes: null,
      permissoesMap: {},
      form: {
        autocomplete: {
          id: `${this.id}-permissao-autocomplete`,
          name: "permissao",
          label: "Permissão",
          autofocus: true,
          autocompleteOptions: {
            data: {},
            onAutocomplete: this.addPermissao
          }
        }
      },
      modalUsuario: {
        id: this.id,
        hasFixedFooter: true,
        modalOptions: {
          onOpenStart: this.onModalOpen,
          dismissible: false
        }
      },
      progressBar: {
        useContainer: false
      }
    };
  },
  watch: {
    permissoes: {
      immediate: true,

      handler(newPermissoes) {
        const data = {};

        if (!this.$utils.Lang.isEmpty(newPermissoes)) {
          newPermissoes.forEach(item => (data[item.nome] = null));
        }

        this.form.autocomplete.autocompleteOptions.data = data;
      }
    }
  },
  methods: {
    addPermissao(event) {
      if (!event) {
        return;
      }

      // Verifica, se a permissão já foi adicionada no array
      if (this.usuarioPermissoes.findIndex(item => item.nome === event) < 0) {
        this.usuarioPermissoes.push(this.permissoesMap[event]);
      }
    },
    deletePermissao(index) {
      if (index >= 0) {
        this.usuarioPermissoes.splice(index, 1);
      }
    },
    onModalOpen() {
      // Reseta algumas variáveis
      this.loading = false;
      this.saving = false;

      this.usuario = { ...this.value };
      this.usuarioPermissoes = null;
      this.usuarioPermissoesOriginal = null;

      this.permissoesMap = {};
      this.permissoes.forEach(item => (this.permissoesMap[item.nome] = item));

      this.loading = true;

      const usuarioId = this.usuario.id;

      UsuarioService.getPermissoes(usuarioId)
        .then(response => {
          this.usuarioPermissoes = response.data || [];

          this.usuarioPermissoesOriginal = this.usuarioPermissoes.map(
            item => item
          );
        })
        .catch(error =>
          this.$notification.pushError(
            `Houve um erro ao obter as permissões do usuário: ${error.message}`
          )
        )
        .finally(() => (this.loading = false));
    },
    getPermissaoPutData() {
      const putData = [];

      this.usuarioPermissoes.forEach(item => {
        const isItemFound =
          this.usuarioPermissoesOriginal.findIndex(
            originalItem => originalItem.id === item.id
          ) >= 0;

        if (!isItemFound) {
          putData.push(item);
        }
      });

      return putData;
    },
    getPermissaoDeleteData() {
      const deleteData = [];
      const permissoes = this.usuarioPermissoes;

      this.usuarioPermissoesOriginal.forEach(originalItem => {
        const isItemFound =
          permissoes.findIndex(item => originalItem.id === item.id) >= 0;

        if (!isItemFound) {
          deleteData.push(originalItem);
        }
      });

      return deleteData;
    },
    save() {
      const usuarioId = this.usuario.id;

      this.saving = true;

      const usuarioPermissoesOriginal = this.usuarioPermissoesOriginal;

      const permissaoPutData = this.getPermissaoPutData();
      const permissaoDeleteData = this.getPermissaoDeleteData();

      const saveData = [].concat(
        permissaoPutData.map(item =>
          UsuarioService.addPermissao(usuarioId, item.id).then(() => {
            // Atualiza os registros originais cujos correspondentes foram
            // criados com sucesso para que, em caso de erro, os mesmos não
            // sejam reenviados ao servidor desnecessariamente.
            usuarioPermissoesOriginal.push(item);

            this.$notification.pushSuccess(
              `Permissão "${item.nome}" associada ao usuário com sucesso`
            );
          })
        ),
        permissaoDeleteData.map(item =>
          UsuarioService.removePermissao(usuarioId, item.id).then(() => {
            const index = usuarioPermissoesOriginal.findIndex(
              itemOriginal => itemOriginal.id === item.id
            );

            // Atualiza os registros originais cujos correspondentes foram
            // excluídos com sucesso para que, em caso de erro, os mesmos não
            // sejam reenviados ao servidor desnecessariamente.
            if (index >= 0) {
              usuarioPermissoesOriginal.splice(index, 1);
            }

            this.$notification.pushSuccess(
              `Permissão "${item.nome}" desassociada do usuário com sucesso`
            );
          })
        )
      );

      Promise.all(saveData)
        .then(() => this.$utils.Components.closeModal(this.id))
        .catch(error => {
          this.$notification.pushError(
            `Houve um erro ao atualizar usuário: ${error.message}`
          );
        })
        .finally(() => (this.saving = false));
    }
  }
};
</script>
