<template>
  <div class="row-fix row">
    <BaseTextInput
      v-bind="form.nome"
      v-bind:disabled="readonly"
      v-on:input="onInput('nome', $event)"
    />
    <BaseDatePicker
      v-bind="form.dataInicio"
      v-bind:disabled="readonly"
      v-on:input="onInput('dataInicio', $event)"
    />
    <BaseTimePicker
      v-bind="form.horaInicio"
      v-bind:disabled="readonly"
      v-on:input="onInput('horaInicio', $event)"
    />
    <BaseDatePicker
      v-bind="form.dataTermino"
      v-bind:disabled="readonly"
      v-on:input="onInput('dataTermino', $event)"
    />
    <BaseTimePicker
      v-bind="form.horaTermino"
      v-bind:disabled="readonly"
      v-on:input="onInput('horaTermino', $event)"
    />
  </div>
</template>

<script>
import { isEmpty } from "../../../utils/lang";
import {
  isISODate,
  isISODateTime,
  isISOTime,
  toBrazilianDate,
  toBrazilianTime,
  toISODate
} from "../../../utils/datetime";

export default {
  name: "EditableSectionConteudoProgramadoItem",
  inheritAttrs: false,
  props: {
    readonly: Boolean,

    value: Object
  },
  data() {
    return {
      form: {
        nome: {
          id: this.buildElementId(this.value.id, "nome"),
          required: true,
          label: "Nome",
          value: null
        },
        dataInicio: {
          id: this.buildElementId(this.value.id, "datainicio"),
          required: true,
          label: "Data início",
          gridExpression: "col s12 m3",
          value: null
        },
        horaInicio: {
          id: this.buildElementId(this.value.id, "horainicio"),
          required: true,
          label: "Hora início",
          gridExpression: "col s12 m3",
          value: null
        },
        dataTermino: {
          id: this.buildElementId(this.value.id, "datatermino"),
          required: true,
          label: "Data término",
          gridExpression: "col s12 m3",
          value: null
        },
        horaTermino: {
          id: this.buildElementId(this.value.id, "horatermino"),
          required: true,
          label: "Hora término",
          gridExpression: "col s12 m3",
          value: null
        }
      }
    };
  },
  watch: {
    value: {
      immediate: true,

      handler(newValue) {
        const { nome, dataInicio, horaInicio, dataTermino, horaTermino } =
          this.form;

        if (isEmpty(newValue)) {
          nome.value = null;
          dataInicio.value = null;
          horaInicio.value = null;
          dataTermino.value = null;
          horaTermino.value = null;
        } else {
          nome.value = newValue.nome;

          if (isISODateTime(newValue.inicio)) {
            dataInicio.value = this.formatComputedDateProp(
              toBrazilianDate,
              newValue.inicio
            );
            horaInicio.value = this.formatComputedDateProp(
              toBrazilianTime,
              newValue.inicio
            );
          } else if (isISODate(newValue.inicio)) {
            dataInicio.value = this.formatComputedDateProp(
              toBrazilianDate,
              newValue.inicio
            );
            horaInicio.value = null;
          } else if (isISOTime(newValue.inicio)) {
            dataInicio.value = null;
            horaInicio.value = this.formatComputedDateProp(
              toBrazilianTime,
              newValue.inicio
            );
          }

          if (isISODateTime(newValue.termino)) {
            dataTermino.value = this.formatComputedDateProp(
              toBrazilianDate,
              newValue.termino
            );
            horaTermino.value = this.formatComputedDateProp(
              toBrazilianTime,
              newValue.termino
            );
          } else if (isISODate(newValue.termino)) {
            dataTermino.value = this.formatComputedDateProp(
              toBrazilianDate,
              newValue.termino
            );
            horaTermino.value = null;
          } else if (isISOTime(newValue.termino)) {
            dataTermino.value = null;
            horaTermino.value = this.formatComputedDateProp(
              toBrazilianTime,
              newValue.termino
            );
          }
        }
      }
    }
  },
  methods: {
    formatComputedDateProp(formatter, value) {
      let newValue = null;

      try {
        newValue = formatter(value);
      } catch (e) {
        // TODO: verificar a necessidade de tratamento
        window.console.error(e.message);
      }

      return newValue;
    },
    toDateTime(date, time) {
      const convDate = this.formatComputedDateProp(toISODate, date);

      const convTime = time && time.length !== 8 ? time + ":00" : time;

      if (convDate && convTime) {
        return `${convDate}T${convTime}`;
      }

      return convDate ? convDate : convTime;
    },
    buildElementId(itemId, name) {
      return itemId
        ? `editablesection-conteudo-${itemId}-${name}`
        : this.$utils.Object.uuid();
    },
    onInput(propertyName, event) {
      if (propertyName.endsWith("Inicio")) {
        const inicio =
          propertyName === "dataInicio"
            ? this.toDateTime(event, this.form.horaInicio.value)
            : this.toDateTime(this.form.dataInicio.value, event);

        this.$emit("input", { ...this.value, inicio });
      } else if (propertyName.endsWith("Termino")) {
        const termino =
          propertyName === "dataTermino"
            ? this.toDateTime(event, this.form.horaTermino.value)
            : this.toDateTime(this.form.dataTermino.value, event);

        this.$emit("input", { ...this.value, termino });
      } else {
        this.$emit("input", { ...this.value, [propertyName]: event });
      }
    }
  }
};
</script>

<style scoped>
.row {
  margin-bottom: 0;
}
</style>
