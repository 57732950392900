<template>
  <div class="eventospanel-result row">
    <div
      v-for="(itemEvento, indexEvento) in eventosCard"
      v-bind:key="indexEvento"
      class="card-container col s12 m4 l3"
    >
      <a
        href="#!"
        v-bind:title="`Acessar evento ${itemEvento.title}`"
        v-on:keyup.enter="onKeyup(itemEvento.actions)"
        v-on:click.prevent="onClick(itemEvento.actions)"
      >
        <BaseCard
          v-bind:is-hoverable="itemEvento.isHoverable"
          v-bind:disabled="itemEvento.disabled"
        >
          <template v-if="itemEvento.image" v-slot:card-image>
            <div class="image-wrapper valign-wrapper">
              <img
                class="responsive-img"
                v-bind="itemEvento.image"
                v-on:error="showFallbackImage"
              />
            </div>
          </template>
          <template v-slot:card-content>
            <div v-if="!itemEvento.image" class="evento-banner valign-wrapper">
              <i
                class="material-icons large"
                v-bind:class="[textColor, textLuminosity]"
                >event</i
              >
            </div>
            <div class="badges-container">
              <BaseBadge
                v-for="(itemBadge, indexBadge) in itemEvento.badges"
                v-bind:key="indexBadge"
                v-bind="itemBadge"
              />
            </div>
            <div class="evento-info-container">
              <h6>{{ itemEvento.cardTitle }}</h6>
              <p v-if="itemEvento.content" class="grey-text text-darken-1">
                <i class="material-icons left">event</i>{{ itemEvento.content }}
              </p>
            </div>
          </template>
        </BaseCard>
      </a>
    </div>
  </div>
</template>

<script>
import { getDateString } from "../../utils/datetime";
import {
  DEFAULT_COLOR,
  DEFAULT_LUMINOSITY,
  NOPIC_IMG_SRC
} from "../../constants";
import { EventoService } from "../../services";

export default {
  name: "EventosPanel",
  inheritAttrs: false,
  props: {
    eventos: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      textColor: `${DEFAULT_COLOR}-text`,
      textLuminosity: DEFAULT_LUMINOSITY ? `text-${DEFAULT_LUMINOSITY}` : "",
      progressBar: {
        useContainer: false
      }
    };
  },
  computed: {
    eventosCard() {
      return (this.eventos || []).map(item => this.mapToCard(item));
    },
    isAdminRoute() {
      return (
        (this.$route.matched || []).findIndex(item => item.name === "admin") !==
        -1
      );
    }
  },
  methods: {
    mapToCard(evento) {
      if (!evento) {
        return null;
      }

      const badges = [];

      if (evento.online) {
        badges.push({
          color: DEFAULT_COLOR,
          luminosity: DEFAULT_LUMINOSITY,
          content: "Online",
          caption: ""
        });
      }

      if (evento.continuo) {
        badges.push({
          color: DEFAULT_COLOR,
          luminosity: DEFAULT_LUMINOSITY,
          content: "Contínuo",
          caption: ""
        });
      }

      const image = {
        src: EventoService.getBannerConteudoURL(evento.id),
        title: evento.nome,
        alt: evento.nome
      };

      const routeName = this.isAdminRoute ? "editEvento" : "viewEvento";

      const routeParams = this.isAdminRoute
        ? { idEvento: evento.id }
        : { slugEvento: evento.slug };

      return {
        cardTitle: evento.nome,
        title: evento.nome,
        badges,
        image,
        content: evento.inicio ? getDateString(evento.inicio) : null,
        actions: [
          {
            name: routeName,
            params: routeParams,
            content: "Acessar",
            iconName: "launch"
          }
        ]
      };
    },
    accessRoute(actions) {
      if (!actions || !actions.length) {
        return;
      }

      this.$router.push({ name: actions[0].name, params: actions[0].params });
    },
    showFallbackImage(event) {
      const image = event.target;

      image.src = NOPIC_IMG_SRC;
      image.title = "Sem foto";
      image.alt = "Sem foto";
    },
    onClick(actions) {
      this.accessRoute(actions);
    },
    onKeyup(actions) {
      this.accessRoute(actions);
    }
  }
};
</script>

<style scoped>
.eventospanel-result {
  display: flex;
  flex-wrap: wrap;
  align-content: stretch;
}

.eventospanel-result div.col.card-container {
  margin: 0.5rem 0 1rem 0;
}

.card {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.card /deep/ .card-image {
  /* Manter a resolução da imagem do card em, no máximo, 16:9 */
  padding-top: 56.25%;
}
.card /deep/ .card-image .image-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.card /deep/ .card-content {
  padding: 14px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.card /deep/ .card-content .evento-info-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.card /deep/ .card-content .evento-info-container i.material-icons.left {
  margin-right: 5px;
}
.card .evento-banner {
  justify-content: center;
}

/* Ajuste para aumentar a seletividade das regras de alto contraste */
.contrast .card /deep/ .badge {
  color: black !important;
  background-color: white !important;
}
</style>
