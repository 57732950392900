<template>
  <div id="pae-wrapper">
    <TheHeader />
    <main id="pae-content">
      <router-view />
      <!-- <TheVLibras /> -->
      <!-- <BaseHelpInfotic v-bind="helpInfotic" /> -->
    </main>
    <TheFooter />
  </div>
</template>

<script>
import {
  APP_INFO,
  DEFAULT_COLOR,
  DEFAULT_LUMINOSITY,
  HELP_INFOTIC_URL
} from "./constants";

import TheHeader from "./components/TheHeader.vue";
import TheFooter from "./components/TheFooter.vue";
// import TheVLibras from "./components/TheVLibras.vue";
import { createStyleElement, getMaterializeColorCode } from "./utils";

export default {
  name: "app",
  components: {
    TheFooter,
    TheHeader
    // TheVLibras
  },
  inheritAttrs: false,
  data() {
    return {
      helpInfotic: {
        appName: APP_INFO.name,
        contacts: [{ name: "PR-5", email: "atendimento@pr5.ufrj.br" }],
        helpUrl: `${HELP_INFOTIC_URL}/duvidas-sistema/${APP_INFO.name}`
      }
    };
  },
  created() {
    this.overrideMaterializeStyle();
  },
  methods: {
    overrideMaterializeStyle() {
      const colorCode = getMaterializeColorCode(
        DEFAULT_COLOR,
        DEFAULT_LUMINOSITY
      );

      if (colorCode) {
        const CSSStyleSheet = `
        .pae-title {
          color: ${colorCode};
        }
      `;

        createStyleElement({
          id: "stylesheet-general",
          type: "text/css",
          innerHTML: CSSStyleSheet
        });
      }
    }
  }
};
</script>

<style>
html,
body {
  height: 100%;
}

main {
  flex-grow: 1;
}

#pae-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

h1 i.material-icons,
h2 i.material-icons,
h3 i.material-icons,
h4 i.material-icons,
h5 i.material-icons {
  line-height: 110%;
  font-size: inherit;
}

.checkbox-wrapper {
  margin-top: 20px;
  margin-bottom: 20px;
}

.row-fix {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}

.justify-align {
  text-align: justify;
}

.buttons-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media screen and (max-width: 600px) {
  .buttons-row .btn {
    display: block;
    width: 100%;
    margin: 0 0 20px 0;
  }
  .buttons-row .btn:last-of-type {
    margin-bottom: 0;
  }
}

.contrast main {
  background-color: black !important;
}

/* Classes para cores personalizadas (fora da paleta do Materialize) */
.custom-blue {
  background-color: #0000ff !important;
}
.custom-blue-text {
  color: #0000ff !important;
}
</style>
