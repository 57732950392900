/**
 * @module services/SisPessoal/SisPessoal
 * @category Serviços
 * @summary Módulo do serviço de requisições ao SisPessoal.
 *
 * @description
 * Expõe o objeto/_namespace_ do serviço de requisições ao SisPessoal para
 * utilização interna.
 *
 * Para correto funcionamento, é necessário que o _plug-in_ de requisições HTTP
 * seja registrado a este serviço.
 *
 * @requires external:axios
 * @requires module:constants.SISPESSOAL_API_URL
 *
 * @example
 * import SisPessoalService from "./services/SisPessoal/SisPessoal";
 *
 * // Registrando o _plug-in_ de requisições HTTP do container.
 * SisPessoalService.registerHttp(Http);
 *
 * const params = // ...
 *
 * // Obtendo a lista de tipos de nacionalidade
 * SisPessoalService.getTiposNacionalidade(params)
 *  .then(response => {
 *    // ...
 *  })
 *  .catch(error => {
 *    // ...
 *  })
 */
import axios from "axios";
import { SISPESSOAL_API_URL } from "../../constants";

/**
 * @namespace SisPessoalService
 * @category Serviços
 * @summary Objeto/_namespace_ do serviço de requisições ao SisPessoal.
 */
const SisPessoalService = {
  /**
   * URL de acesso à API para obtenção de dados do SisPessoal.
   * @type {string}
   * @private
   * @readonly
   */
  baseUrl: SISPESSOAL_API_URL,

  /**
   * _Framework_ de requisições HTTP.
   * @type {external:axios}
   * @private
   * @readonly
   */
  axios: axios.create(),

  /**
   * Envia uma requisição para obter os tipos de nacionalidade, retornando uma Promise.
   * @returns {external:Promise}
   */
  getTiposNacionalidade() {
    return this.axios.get(`${this.baseUrl}/core/tipoNacionalidade`);
  },

  /**
   * Envia uma requisição para obter uma lista de países baseada em um
   * parâmetro de busca textual, retornando uma Promise.
   * @param {string} query - Parâmetro de busca textual.
   * @returns {external:Promise}
   */
  searchPais(query) {
    return this.axios.get(`${this.baseUrl}/core/pais/busca?query=${query}`);
  },

  /**
   * Envia uma requisição para obter uma lista de locais baseada em um
   * parâmetro de busca textual, retornando uma Promise.
   * @param {string} query - Parâmetro de busca textual.
   * @returns {external:Promise}
   */
  searchLocal(query) {
    return this.axios.get(`${this.baseUrl}/core/localOficial?busca=${query}`);
  },

  /**
   * Envia uma requisição para obter um país, retornando uma Promise.
   * @param {number} id - Identificador do país.
   * @returns {external:Promise}
   */
  getPais(id) {
    return this.axios.get(`${this.baseUrl}/core/pais/${id}`);
  },

  /**
   * Envia uma requisição para obter um local, retornando uma Promise.
   * @param {string} codigo - Código identificador do local.
   * @returns {external:Promise}
   */
  getLocal(codigo) {
    return this.axios.get(`${this.baseUrl}/core/localOficial/${codigo}`);
  }
};

export default SisPessoalService;
