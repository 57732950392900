<template>
  <component ref="tableOfContents" v-bind:is="tag" class="table-of-contents">
    <slot></slot>
  </component>
</template>

<script>
/**
 * @module components/base/BaseTableOfContents/BaseTableOfContents
 * @category Componentes-base
 * @summary _Single File Component_ (SFC) de tabela de conteúdos do {@link external:Materialize Materialize}.
 *
 * @description
 * Este componente encapsula e agrupa elementos como uma tabela de conteúdos,
 * possuindo a classe "table-of-contents" do {@link external:Materialize Materialize}.
 *
 * As cores para a marcação lateral no item ativo da tabela de conteúdo são
 * obtidas automaticamente a partir da configuração do ambiente.
 *
 * O _slot_ _default_ recebe os elementos HTML que devem compor a tabela de
 * conteúdos.
 *
 * @requires module:constants.DEFAULT_COLOR
 * @requires module:constants.DEFAULT_LUMINOSITY
 * @requires module:utils/web.createStyleElement
 * @requires module:utils/web.getMaterializeColorCode
 *
 * @vue-prop {string} [tag="ul"] - Nome do elemento HTML em que a tabela de
 * conteúdos será renderizada.
 *
 * @example
 * <BaseTableOfContents v-bind:color="teal" v-bind:luminosity="darken-1">
 *  <li>...</li>
 *  ...
 * </BaseTableOfContents>
 */
import { DEFAULT_COLOR, DEFAULT_LUMINOSITY } from "../../../constants";
import {
  createStyleElement,
  getMaterializeColorCode
} from "../../../utils/web";

export default {
  name: "BaseTableOfContents",
  inheritAttrs: false,
  props: {
    tag: {
      type: String,
      default: "ul"
    }
  },
  created() {
    this.overrideMaterializeStyle();
  },
  methods: {
    overrideMaterializeStyle() {
      const colorCode = getMaterializeColorCode(
        DEFAULT_COLOR,
        DEFAULT_LUMINOSITY
      );

      if (colorCode) {
        const CSSStyleSheet = `
        .table-of-contents a.active,
        .table-of-contents a:hover {
            border-left-color: ${colorCode};
        }
        `;

        createStyleElement({
          id: "stylesheet-tableofcontents",
          type: "text/css",
          innerHTML: CSSStyleSheet
        });
      }
    }
  }
};
</script>

<style scoped>
.contrast .table-of-contents a.active,
.contrast .table-of-contents a:hover {
  border-left-color: white;
}
</style>
