<template>
  <div class="breadcrumbs-container">
    <slot>
      <BaseRouterLink
        v-for="(item, index) in breadcrumbs"
        v-bind:key="index"
        v-bind="item"
        class="breadcrumb"
      >{{ item.content }}</BaseRouterLink>
    </slot>
  </div>
</template>

<script>
/**
 * @module components/base/BaseBreadcrumbs/BaseBreadcrumbs
 * @category Componentes-base
 * @summary _Single File Component_ (SFC) de _breadcrumbs_ do {@link external:Materialize Materialize}.
 *
 * @description
 * Exibe um conjunto de {@link module:components/base/BaseRouterLink/BaseRouterLink BaseRouterLink}s como _breadcrumbs_.
 *
 * O _slot_ _default_ recebe os _breadcrumbs_.
 *
 * @requires module:components/base/BaseRouterLink/BaseRouterLink
 *
 * @vue-prop {Array<object>} [breadcrumbs=[]] - _Array_ de objetos com dados das
 * rotas. Esses objetos devem possuir as props aceitas pelo componente
 * {@link module:components/base/BaseRouterLink/BaseRouterLink BaseRouterLink}
 * e uma propriedade `content`, que é passada para o _slot_ _default_ desse
 * componente. É usado como substituto quando nenhum dado é passado através do
 * _slot_ _default_.
 *
 * @example
 * <BaseBreadcrumbs v-bind:breadcrumbs="[{ to: 'implanta/busca-servidor', content: 'Acessar módulo' }]" />
 */
import BaseRouterLink from "../BaseRouterLink/BaseRouterLink.vue";

export default {
  name: "BaseBreadcrumbs",
  components: {
    BaseRouterLink
  },
  inheritAttrs: false,
  props: {
    breadcrumbs: {
      type: Array,
      default: () => []
    }
  }
};
</script>
