<template>
  <section class="section">
    <component v-bind:is="tag" class="pae-title pae-header">
      <i class="material-icons left">attachment</i>Arquivos
    </component>

    <p v-if="!hasArquivos" class="flow-text center">
      Nenhum arquivo foi cadastrado.
    </p>
    <BaseEditableCollection v-else v-bind="form.arquivoCollection" />
  </section>
</template>

<script>
import { isEmpty } from "../../../utils/lang";
import { EstandeService } from "../../../services";
import ArquivoItem from "../ArquivoItem.vue";

export default {
  name: "SectionArquivos",
  components: {
    ArquivoItem
  },
  inheritAttrs: false,
  props: {
    tag: {
      type: String,
      default: "h4",
      validator: value =>
        ["h1", "h2", "h3", "h4", "h5", "h6"].indexOf(value) !== -1
    },
    estandeId: {
      type: Number,
      require: true
    },
    conteudoId: {
      type: Number,
      require: true
    },
    arquivos: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      form: {
        arquivoCollection: {
          component: ArquivoItem,
          itemOperations: [
            {
              iconName: "file_download",
              color: "blue",
              title: "Baixar arquivo",
              onClick: this.download
            }
          ],
          items: this.arquivos.map(item => ({
            id: item.id,
            value: item
          }))
        }
      }
    };
  },
  computed: {
    hasArquivos() {
      return !isEmpty(this.arquivos);
    }
  },
  methods: {
    download(item, index) {
      const arquivos = this.form.arquivoCollection.items;

      if (!this.readonly && index >= 0) {
        const arquivo = arquivos[index].value;

        const url = EstandeService.getArquivoConteudoURLFromConteudoExtra(
          this.estandeId,
          this.conteudoId,
          arquivo.id
        );

        window.open(url, "_blank", "noopener");
      }
    }
  }
};
</script>
