/**
 * @module services/Evento
 * @category Serviços
 * @summary Módulo de importação do serviço de eventos.
 *
 * @description
 * Expõe o serviço de eventos do sistema para utilização interna.
 *
 * @example <caption>Importando o serviço de eventos como membro default do módulo</caption>
 * import EventoService from "./services/Evento";
 *
 * @example <caption>Importando o serviço de eventos como membro do módulo</caption>
 * import { EventoService } from "./services/Evento";
 */
import { default as EventoService } from "./Evento";

export default EventoService;

export { EventoService };
