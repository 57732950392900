/**
 * @module services/Pessoa/Pessoa
 * @category Serviços
 * @summary Módulo do serviço de pessoas.
 *
 * @description
 * Expõe o objeto/_namespace_ do serviço de pessoas do sistema para
 * utilização interna.
 *
 * Para correto funcionamento, é necessário que o _plug-in_ de requisições HTTP
 * seja registrado a este serviço.
 *
 * @requires module:constants.API_URL
 * @requires module:utils/lang.isEmpty
 * @requires module:utils/lang.isNullOrUndefined
 * @requires module:utils/string.isEmail
 *
 * @example
 * import PessoaService from "./services/Pessoa/Pessoa";
 *
 * // Registrando o _plug-in_ de requisições HTTP do container.
 * PessoaService.registerHttp(Http);
 *
 * const params = // ...
 *
 * // Obtendo a lista de pessoas
 * PessoaService.search(params)
 *  .then(response => {
 *    // ...
 *  })
 *  .catch(error => {
 *    // ...
 *  })
 */
import { API_URL } from "../../constants";
import { isEmpty, isNullOrUndefined } from "../../utils/lang";
import { isEmail } from "../../utils/string";

/**
 * @namespace PessoaService
 * @category Serviços
 * @summary Objeto/_namespace_ do serviço de pessoas.
 */
const PessoaService = {
  /**
   * URL de acesso à API para obtenção de pessoas.
   * @type {string}
   * @private
   * @readonly
   */
  baseUrl: `${API_URL}/pessoas`,

  /**
   * _Plug-in_ de requisições HTTP.
   * @type {module:plugins/Http/Http}
   * @private
   * @readonly
   */
  http: null,

  /**
   * Tipo de nacionalidade "Brasileiro nato".
   * Baseado nos valores do SisPessoal.
   * @type {string}
   * @readonly
   */
  BRASILEIRO_NATO: "1",

  /**
   * Tipo de nacionalidade "Brasileiro naturalizado".
   * Baseado nos valores do SisPessoal.
   * @type {string}
   * @readonly
   */
  BRASILEIRO_NATURALIZADO: "2",

  /**
   * Tipo de nacionalidade "Equiparado".
   * Baseado nos valores do SisPessoal.
   * @type {string}
   * @readonly
   */
  EQUIPARADO: "3",

  /**
   * Tipo de nacionalidade "Estrangeiro".
   * Baseado nos valores do SisPessoal.
   * @type {string}
   * @readonly
   */
  ESTRANGEIRO: "4",

  /**
   * Realiza a validação de uma pessoa.
   * @param {object} val - Pessoa a ser validada.
   * @returns {Array<Error>} - _Array_ de possíveis erros.
   */
  validate(val) {
    const errors = [];

    if (isEmpty(val)) {
      errors.push(new Error("Pessoa não preenchida"));
    } else {
      const { cpf, passaporte, nome, email, nacionalidade } = val;

      if (isEmpty(nome)) {
        errors.push(new Error("Nome não preenchido"));
      }

      if (!isEmail(email)) {
        errors.push(new Error("E-mail inválido"));
      }

      if (nacionalidade) {
        const { tipoCodigo, paisOrigemId } = nacionalidade;

        if (
          [
            this.BRASILEIRO_NATO,
            this.BRASILEIRO_NATURALIZADO,
            this.EQUIPARADO,
            this.ESTRANGEIRO
          ].indexOf(tipoCodigo) === -1
        ) {
          errors.push(new Error("Tipo de nacionalidade inválido"));
        } else if (tipoCodigo !== this.BRASILEIRO_NATO && !paisOrigemId) {
          errors.push(new Error("País de origem não preenchido"));
        } else if (tipoCodigo === this.BRASILEIRO_NATO && isEmpty(cpf)) {
          errors.push(new Error("CPF não preenchido"));
        } else if (isEmpty(cpf) && isEmpty(passaporte)) {
          errors.push(new Error("CPF ou Passaporte deve ser preenchido"));
        }
      } else {
        if (isEmpty(cpf) && isEmpty(passaporte)) {
          errors.push(new Error("CPF ou Passaporte deve ser preenchido"));
        }
      }

      if (!isEmpty(cpf) && cpf.length != 11) {
        errors.push(new Error("CPF inválido"));
      } else if (!isEmpty(passaporte) && passaporte.length != 9) {
        errors.push(new Error("Passaporte inválido"));
      }
    }

    return errors;
  },

  /**
   * Envia uma requisição para obter uma lista de pessoas baseada em
   * parâmetros de busca, retornando uma Promise.
   * @param {object} params - Parâmetros de busca.
   * @returns {external:Promise}
   */
  search(params) {
    let querystring = [];

    if (!isEmpty(params)) {
      if (!isEmpty(params.nome)) {
        querystring.push(`nome=${params.nome}`);
      }

      if (!isEmpty(params.cpf)) {
        querystring.push(`cpf=${params.cpf}`);
      }

      if (!isEmpty(params.passaporte)) {
        querystring.push(`passaporte=${params.passaporte}`);
      }

      if (!isNullOrUndefined(params.page)) {
        querystring.push(`page=${params.page}`);
      }

      if (!isNullOrUndefined(params.size)) {
        querystring.push(`size=${params.size}`);
      }
    }

    querystring = querystring.length ? "?" + querystring.join("&") : "";

    return this.http.get(`${this.baseUrl}${querystring}`);
  },

  /**
   * Envia uma requisição para obter uma pessoa, retornando uma Promise.
   * @param {number} id - Identificador numérico da pessoa.
   * @returns {external:Promise}
   */
  get(id) {
    return this.http.get(`${this.baseUrl}/${id}`);
  },

  /**
   * Envia uma requisição para criar uma pessoa, retornando uma Promise.
   * @param {object} pessoa - Dados da pessoa.
   * @returns {external:Promise}
   */
  create(pessoa) {
    return this.http.post(`${this.baseUrl}`, pessoa);
  },

  /**
   * Envia uma requisição para atualizar uma pessoa, retornando uma Promise.
   * @param {number} id - Identificador numérico da pessoa.
   * @param {object} pessoa - Dados da pessoa.
   * @returns {external:Promise}
   */
  update(id, pessoa) {
    return this.http.put(`${this.baseUrl}/${id}`, pessoa);
  },

  /**
   * Envia uma requisição para obter os usuários de uma pessoa, retornando
   * uma Promise.
   * @param {number} id - Identificador da pessoa.
   * @returns {external:Promise}
   */
  getUsuarios(id) {
    return this.http.get(`${this.baseUrl}/${id}/usuarios`);
  },

  /**
   * Envia uma requisição para associar uma permissão ao usuário, retornando
   * uma Promise.
   * @param {number} id - Identificador da pessoa.
   * @param {object} usuario - Objeto com os dados do usuário.
   * @returns {external:Promise}
   */
  addUsuario(id, usuario) {
    return this.http.post(`${this.baseUrl}/${id}/usuarios`, usuario);
  },

  /**
   * Registra o _plug-in_ de requisições HTTP no _namespace_ do serviço.
   * @param {module:plugins/Http/Http} http - _Plug-in_ de requisições HTTP.
   * @returns {module:services/Pessoa/Pessoa~PessoaService} O próprio namespace.
   */
  registerHttp(http) {
    this.http = http;
    return this;
  }
};

export default PessoaService;
