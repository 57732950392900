/**
 * @module utils/web/Storage/Session
 * @category Utilidades
 * @summary Módulo de utilidades para manipulação de {@link external:Storage Storage} do tipo "session".
 *
 * @description
 * Expõe um _namespace_ com funções de utilidades para manipulação de {@link external:Storage Storage}
 * do tipo "session".
 *
 * @requires module:utils/lang.isArray
 * @requires module:utils/lang.isFunction
 * @requires module:utils/lang.isObject
 */
import { isArray, isFunction, isObject } from "../../lang";

const storage = window.sessionStorage;

const hasStorage = function() {
  return !!storage;
};

/**
 * @namespace Session
 * @category Plug-ins
 * @summary Objeto/_namespace_ do _plug-in_ de utilidades para manipulação de {@link external:Storage Storage} do tipo "session".
 *
 * @requires module:utils/lang.isArray
 * @requires module:utils/lang.isFunction
 * @requires module:utils/lang.isObject
 */
const Session = {
  /**
   * Obtém a quantidade de itens armazenados no {@link external:Storage Storage}.
   * @returns {number|boolean} - A quantidade de itens armazenados ou `false`
   * caso não seja possível utilizar o {@link external:Storage Storage} do _browser_.
   */
  size() {
    if (!hasStorage()) {
      window.console.warn(
        "O browser utilizado não possui suporte ao SessionStorage. Alguns dados não poderão ser armazenados."
      );
      return false;
    }

    return storage.length;
  },

  /**
   * Obtém a chave correspondente ao índice informado.
   * @param {number} index - Índice desejado.
   * @returns {boolean} - `false` caso não seja possível utilizar o {@link external:Storage Storage} do _browser_.
   */
  key(index) {
    if (!hasStorage()) {
      window.console.warn(
        "O browser utilizado não possui suporte ao SessionStorage. Alguns dados não poderão ser armazenados."
      );
      return false;
    }

    return storage.key(index);
  },

  /**
   * Obtém valor correspondente à chave informada.
   * @param {string} keyName - Chave desejada.
   * @returns {string|boolean} - Valor correspondente à chave informada ou
   * `false` caso não seja possível utilizar o {@link external:Storage Storage} do _browser_.
   */
  get(keyName) {
    if (!hasStorage()) {
      window.console.warn(
        "O browser utilizado não possui suporte ao SessionStorage. Alguns dados não poderão ser armazenados."
      );
      return false;
    }

    return storage.getItem(keyName);
  },

  /**
   * Obtém o valor correspondente à chave informada ou, caso não exista,
   * o adiciona.
   * @param {string} keyName - Chave desejada.
   * @param {any} keyValue - Valor desejado.
   * @returns {string|boolean} - Valor correspondente à chave informada ou
   * `false` caso não seja possível utilizar o {@link external:Storage Storage} do _browser_.
   */
  getOrSet(keyName, keyValue) {
    if (!hasStorage()) {
      window.console.warn(
        "O browser utilizado não possui suporte ao SessionStorage. Alguns dados não poderão ser armazenados."
      );
      return false;
    }

    const value = this.get(keyName);

    if (value === null) {
      this.set(keyName, keyValue);

      return keyValue;
    }

    return value;
  },

  /**
   * Adiciona o valor desejado para a chave informada ou, caso já exista, o
   * atualiza.
   * @param {string} keyName - Chave desejada.
   * @param {any} keyValue - Valor desejado.
   * @returns {boolean} - `false` caso não seja possível utilizar o {@link external:Storage Storage} do _browser_.
   * Todo caso, retorna `true`.
   */
  set(keyName, keyValue) {
    if (!hasStorage()) {
      window.console.warn(
        "O browser utilizado não possui suporte ao SessionStorage. Alguns dados não poderão ser armazenados."
      );
      return false;
    }

    if (isFunction(keyValue)) {
      throw new Error(
        "Não é permitido armazenar funções no SessionStorage através do plug-in do sistema."
      );
    } else if (isObject(keyValue) || isArray(keyValue)) {
      storage.setItem(keyName, JSON.stringify(keyValue));
    } else {
      storage.setItem(keyName, keyValue);
    }

    return true;
  },

  /**
   * Remove o valor correspondente à chave informada.
   * @param {string} keyName - Chave desejada.
   * @returns {boolean} - `false` caso não seja possível utilizar o {@link external:Storage Storage} do _browser_.
   * Todo caso, retorna `true`.
   */
  remove(keyName) {
    if (!hasStorage()) {
      window.console.warn(
        "O browser utilizado não possui suporte ao SessionStorage. Alguns dados não poderão ser armazenados."
      );
      return false;
    }

    storage.removeItem(keyName);

    return true;
  }
};

export default Session;
