<template>
  <section class="section">
    <component v-bind:is="tag" class="pae-title pae-header">
      <i v-if="secao.icone" class="material-icons left">{{ secao.icone }}</i
      >{{ secao.nome }}
    </component>

    <BaseProgressBar v-if="loading" v-bind="progressBar" />
    <p v-else-if="!hasConteudos" class="flow-text center">
      Nenhum conteúdo extra foi cadastrado.
    </p>
    <div v-else>
      <BaseEditableCollection v-bind="conteudoCollection" />
    </div>

    <SectionConteudoExtraModal v-bind="conteudoModal" />
  </section>
</template>

<script>
import { DEFAULT_COLOR, DEFAULT_LUMINOSITY } from "../../../constants";
import { isEmpty } from "../../../utils/lang";
import { EstandeService } from "../../../services";
import SectionConteudoItem from "./SectionConteudoItem.vue";
import SectionConteudoExtraModal from "./SectionConteudoExtraModal.vue";

export default {
  name: "SectionConteudosExtras",
  components: {
    SectionConteudoExtraModal
  },
  inheritAttrs: false,
  props: {
    tag: {
      type: String,
      default: "h3",
      validator: value =>
        ["h1", "h2", "h3", "h4", "h5", "h6"].indexOf(value) !== -1
    },
    entidadeId: {
      type: Number,
      required: true
    },
    modelo: {
      type: Object,
      required: true
    },
    secao: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      conteudoModal: {
        id: "pae-conteudoextra-modal",
        entidadeId: this.entidadeId,
        value: null
      },
      progressBar: {
        useContainer: false
      },
      conteudoCollection: {
        component: SectionConteudoItem,
        items: null,
        itemOperations: [
          {
            iconName: "search",
            color: DEFAULT_COLOR,
            luminosity: DEFAULT_LUMINOSITY,
            onClick: this.openConteudoExtraModal,
            title: "Ver conteúdo extra"
          }
        ]
      }
    };
  },
  computed: {
    hasConteudos() {
      return !isEmpty(this.conteudoCollection.items);
    }
  },
  created() {
    this.getConteudosExtras();
  },
  methods: {
    getConteudosExtras() {
      if (!this.entidadeId) {
        return;
      }

      const id = this.entidadeId;

      this.loading = true;

      this.conteudoCollection.items = [];

      EstandeService.getConteudosExtras(id)
        .then(
          response =>
            (this.conteudoCollection.items = (response.data || []).map(
              item => ({
                value: { ...item }
              })
            ))
        )
        .catch(error =>
          this.$notification.pushError(
            `Houve um erro ao obter os conteúdos extras: ${error.message}`
          )
        )
        .finally(() => (this.loading = false));
    },
    openConteudoExtraModal(item, index) {
      const conteudos = this.conteudoCollection.items;

      if (index >= 0 && index < conteudos.length) {
        this.conteudoModal.value = conteudos[index].value.id;
      }

      this.$nextTick().then(() =>
        this.$utils.Components.openModal(this.conteudoModal.id)
      );
    }
  }
};
</script>
