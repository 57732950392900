<template>
  <section class="section">
    <component v-bind:is="tag" class="pae-title pae-header">
      <i v-if="secao.icone" class="material-icons left">{{ secao.icone }}</i
      >{{ secao.nome }}
    </component>

    <BaseInfoCard v-if="modeloSecao.instrucoes" title="Seção de descrição">
      <p>{{ modeloSecao.instrucoes }}</p>
    </BaseInfoCard>

    <BaseAlert
      v-if="modeloSecao.obrigatorio"
      severity="warning"
      content="Seção de preenchimento obrigatório para publicação"
      style="margin: 1rem 0"
    />

    <div v-if="editor" class="editor-wrapper">
      <div class="toolbar">
        <BaseButton
          title="Negrito (Ctrl+B)"
          icon-name="format_bold"
          v-bind="toolbar.buttons.style"
          v-bind:disabled="readonly"
          v-bind:class="{ 'is-active': editor.isActive('bold') }"
          v-on:click="editor.chain().focus().toggleBold().run()"
        />
        <BaseButton
          title="Itálico (Ctrl+I)"
          icon-name="format_italic"
          v-bind="toolbar.buttons.style"
          v-bind:disabled="readonly"
          v-bind:class="{ 'is-active': editor.isActive('italic') }"
          v-on:click="editor.chain().focus().toggleItalic().run()"
        />
        <BaseButton
          title="Sublinhado (Ctrl+U)"
          icon-name="format_underlined"
          v-bind="toolbar.buttons.style"
          v-bind:disabled="readonly"
          v-bind:class="{ 'is-active': editor.isActive('underline') }"
          v-on:click="editor.chain().focus().toggleUnderline().run()"
        />
        <BaseButton
          title="Riscado (Ctrl+Shift+X)"
          icon-name="format_strikethrough"
          v-bind="toolbar.buttons.style"
          v-bind:disabled="readonly"
          v-bind:class="{ 'is-active': editor.isActive('strike') }"
          v-on:click="editor.chain().focus().toggleStrike().run()"
        />

        <div class="vertical-divider"></div>

        <BaseButton
          title="Superescrito"
          icon-name="superscript"
          v-bind="toolbar.buttons.style"
          v-bind:disabled="readonly"
          v-bind:class="{ 'is-active': editor.isActive('superscript') }"
          v-on:click="editor.chain().focus().toggleSuperscript().run()"
        />
        <BaseButton
          title="Subscrito"
          icon-name="subscript"
          v-bind="toolbar.buttons.style"
          v-bind:disabled="readonly"
          v-bind:class="{ 'is-active': editor.isActive('subscript') }"
          v-on:click="editor.chain().focus().toggleSubscript().run()"
        />

        <div class="vertical-divider"></div>

        <BaseButton
          title="Realçar (Ctrl+Shift+H)"
          icon-name="highlight"
          v-bind="toolbar.buttons.style"
          v-bind:disabled="readonly"
          v-bind:class="{ 'is-active': editor.isActive('highlight') }"
          v-on:click="editor.chain().focus().toggleHighlight().run()"
        />

        <div class="vertical-divider"></div>

        <BaseButton
          title="Alinhar à esquerda (Ctrl+Shift+L)"
          icon-name="format_align_left"
          v-bind="toolbar.buttons.style"
          v-bind:disabled="readonly"
          v-bind:class="{
            'is-active': editor.isActive({ textAlign: 'left' })
          }"
          v-on:click="editor.chain().focus().setTextAlign('left').run()"
        />
        <BaseButton
          title="Centralizar (Ctrl+Shift+E)"
          icon-name="format_align_center"
          v-bind="toolbar.buttons.style"
          v-bind:disabled="readonly"
          v-bind:class="{
            'is-active': editor.isActive({ textAlign: 'center' })
          }"
          v-on:click="editor.chain().focus().setTextAlign('center').run()"
        />
        <BaseButton
          title="Alinhar à direita (Ctrl+Shift+R)"
          icon-name="format_align_right"
          v-bind="toolbar.buttons.style"
          v-bind:disabled="readonly"
          v-bind:class="{
            'is-active': editor.isActive({ textAlign: 'right' })
          }"
          v-on:click="editor.chain().focus().setTextAlign('right').run()"
        />
        <BaseButton
          title="Justificar (Ctrl+Shift+J)"
          icon-name="format_align_justify"
          v-bind="toolbar.buttons.style"
          v-bind:disabled="readonly"
          v-bind:class="{
            'is-active': editor.isActive({ textAlign: 'justify' })
          }"
          v-on:click="editor.chain().focus().setTextAlign('justify').run()"
        />

        <div class="vertical-divider"></div>

        <BaseButton
          title="Lista não-ordenada (Ctrl+Shift+8)"
          icon-name="format_list_bulleted"
          v-bind="toolbar.buttons.style"
          v-bind:disabled="readonly"
          v-bind:class="{ 'is-active': editor.isActive('bulletList') }"
          v-on:click="editor.chain().focus().toggleBulletList().run()"
        />
        <BaseButton
          title="Lista ordenada (Ctrl+Shift+7)"
          icon-name="format_list_numbered"
          v-bind="toolbar.buttons.style"
          v-bind:disabled="readonly"
          v-bind:class="{ 'is-active': editor.isActive('orderedList') }"
          v-on:click="editor.chain().focus().toggleOrderedList().run()"
        />

        <div class="vertical-divider"></div>

        <BaseButton
          title="Citação (Ctrl+Shift+B)"
          icon-name="format_quote"
          v-bind="toolbar.buttons.style"
          v-bind:disabled="readonly"
          v-bind:class="{ 'is-active': editor.isActive('blockquote') }"
          v-on:click="editor.chain().focus().toggleBlockquote().run()"
        />

        <div class="vertical-divider"></div>

        <BaseButton
          title="Separador"
          icon-name="horizontal_rule"
          v-bind="toolbar.buttons.style"
          v-bind:disabled="readonly"
          v-on:click="editor.chain().focus().setHorizontalRule().run()"
        />

        <div class="vertical-divider"></div>

        <BaseButton
          title="Adicionar link"
          icon-name="add_link"
          v-bind="toolbar.buttons.style"
          v-bind:disabled="readonly"
          v-on:click="setLink"
        />
        <BaseButton
          title="Remover link"
          icon-name="link_off"
          v-bind="toolbar.buttons.style"
          v-bind:disabled="readonly"
          v-bind:class="{ 'is-active': editor.isActive('link') }"
          v-on:click="editor.chain().focus().unsetLink().run()"
        />
      </div>

      <EditorContent class="editor" v-bind:editor="editor" />

      <div v-if="editor" class="toolbar">
        <BaseButton
          title="Inserir tabela"
          icon-name="grid_on"
          v-bind="toolbar.buttons.style"
          v-bind:disabled="readonly"
          v-on:click="
            editor
              .chain()
              .focus()
              .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
              .run()
          "
        />
        <BaseButton
          title="Adicionar linha acima"
          icon-name="table_rows"
          v-bind="toolbar.buttons.style"
          v-bind:disabled="readonly || !editor.can().addRowBefore()"
          v-on:click="editor.chain().focus().addRowBefore().run()"
        />
        <BaseButton
          title="Adicionar linha abaixo"
          icon-name="table_rows"
          v-bind="toolbar.buttons.style"
          v-bind:disabled="readonly || !editor.can().addRowAfter()"
          v-on:click="editor.chain().focus().addRowAfter().run()"
        />
        <BaseButton
          title="Adicionar coluna à esquerda"
          icon-name="table_chart"
          v-bind="toolbar.buttons.style"
          v-bind:disabled="readonly || !editor.can().addColumnBefore()"
          v-on:click="editor.chain().focus().addColumnBefore().run()"
        />
        <BaseButton
          title="Adicionar coluna à direita"
          icon-name="table_chart"
          v-bind="toolbar.buttons.style"
          v-bind:disabled="readonly || !editor.can().addColumnAfter()"
          v-on:click="editor.chain().focus().addColumnAfter().run()"
        />
        <BaseButton
          title="Remover linha"
          icon-name="table_rows"
          v-bind="toolbar.buttons.style"
          v-bind:disabled="readonly || !editor.can().deleteRow()"
          v-on:click="editor.chain().focus().deleteRow().run()"
        />
        <BaseButton
          title="Remover coluna"
          icon-name="table_chart"
          v-bind="toolbar.buttons.style"
          v-bind:disabled="readonly || !editor.can().deleteColumn()"
          v-on:click="editor.chain().focus().deleteColumn().run()"
        />
        <BaseButton
          title="Mesclar células"
          icon-name="merge_type"
          v-bind="toolbar.buttons.style"
          v-bind:disabled="readonly || !editor.can().mergeCells()"
          v-on:click="editor.chain().focus().mergeCells().run()"
        />
        <BaseButton
          title="Separar células"
          icon-name="call_split"
          v-bind="toolbar.buttons.style"
          v-bind:disabled="!editor.can().splitCell()"
          v-on:click="editor.chain().focus().splitCell().run()"
        />
        <BaseButton
          title="Remover tabela"
          icon-name="delete"
          v-bind="toolbar.buttons.style"
          v-bind:disabled="readonly || !editor.can().deleteTable()"
          v-on:click="editor.chain().focus().deleteTable().run()"
        />

        <div class="vertical-divider"></div>

        <div class="right">
          <BaseButton
            title="Desfazer (Ctrl+Z)"
            icon-name="undo"
            v-bind="toolbar.buttons.style"
            v-bind:disabled="readonly"
            v-on:click="editor.chain().focus().undo().run()"
          />
          <BaseButton
            title="Refazer (Ctrl+Shift+Z)"
            icon-name="redo"
            v-bind="toolbar.buttons.style"
            v-bind:disabled="readonly"
            v-on:click="editor.chain().focus().redo().run()"
          />
        </div>
      </div>
    </div>

    <div class="right-align">
      <BaseButton
        v-bind="form.buttonSave"
        v-bind:disabled="readonly || disabled"
        v-on:click="save"
      />
    </div>

    <div v-if="hasSections">
      <EditableSection
        v-for="(item, index) in secao.secoes"
        v-bind:key="index"
        v-bind:tag="subTag"
        v-bind:entidade-id="entidadeId"
        v-bind:modelo="modelo"
        v-bind:readonly="readonly"
        v-bind:value="item"
      />
    </div>
  </section>
</template>

<script>
import { DEFAULT_COLOR, DEFAULT_LUMINOSITY } from "../../../constants";
import { isEmpty } from "../../../utils/lang";
import {
  EstandeService,
  EventoService,
  ModeloService,
  PermissaoService,
  TipoModeloService
} from "../../../services";
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import Highlight from "@tiptap/extension-highlight";
import Link from "@tiptap/extension-link";
import Subscript from "@tiptap/extension-subscript";
import Superscript from "@tiptap/extension-superscript";
import Table from "@tiptap/extension-table";
import TableRow from "@tiptap/extension-table-row";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";

const EDITOR_NOME = "TipTap";
const EDITOR_VERSAO = "2.0.0";

export default {
  name: "EditableSectionDescricao",
  components: {
    EditorContent
  },
  inheritAttrs: false,
  props: {
    readonly: Boolean,

    tag: {
      type: String,
      default: "h3",
      validator: value =>
        ["h1", "h2", "h3", "h4", "h5", "h6"].indexOf(value) !== -1
    },
    entidadeId: {
      type: Number,
      required: true
    },
    modelo: {
      type: Object,
      required: true
    },
    secao: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      disabled: false,
      service: null,
      modeloSecao: ModeloService.findModeloSecao(
        this.modelo,
        this.secao.modeloSecaoId
      ),
      progressBar: {
        useContainer: false
      },
      form: {
        buttonSave: {
          iconName: "save",
          content: "Salvar",
          disabled: this.readonly
        }
      },
      editor: null,
      toolbar: {
        buttons: {
          style: {
            materialType: "flat",
            hasWaves: false
          }
        }
      },
      // Utilizo as propriedades abaixo apenas para comparar com o conteúdo
      // atual no editor. Se estiverem diferentes, envia requisição de update
      // ao back-end.
      characterCountOriginal: 0,
      htmlOriginal: null
    };
  },
  computed: {
    hasSections() {
      return !isEmpty(this.secao.secoes);
    },
    isGestorEstandes() {
      return this.$authorization.hasPermission(PermissaoService.GESTAO_ESTANDE);
    },
    isGestorEventos() {
      return this.$authorization.hasPermission(PermissaoService.GESTAO_EVENTO);
    },
    isReadonly() {
      return this.modelo.tipoModeloId === TipoModeloService.EVENTO
        ? !this.isGestorEventos
        : !this.isGestorEstandes;
    },
    subTag() {
      const headerNumber = window.parseInt(this.tag.charAt(1)) + 1;

      return headerNumber >= 6 ? "h6" : `h${headerNumber}`;
    }
  },
  watch: {
    readonly: {
      immediate: true,

      handler(newValue) {
        if (this.editor) {
          this.editor.setOptions({ editable: !newValue });
        }
      }
    }
  },
  beforeCreate() {
    // https://vuejs.org/v2/guide/components-edge-cases.html#Circular-References-Between-Components

    this.$options.components.EditableSection =
      require("./EditableSection.vue").default;
  },
  created() {
    this.overrideMaterializeStyle();

    if (this.modelo.tipoModeloId === TipoModeloService.EVENTO) {
      this.service = EventoService;
    } else if (this.modelo.tipoModeloId === TipoModeloService.ESTANDE) {
      this.service = EstandeService;
    } else {
      this.$notification.pushError("Tipo de modelo de página inválido");
      return;
    }

    // TODO: estilizar tabela
    this.editor = new Editor({
      extensions: [
        StarterKit,
        Underline,
        Highlight,
        TextAlign.configure({ types: ["heading", "paragraph"] }),
        Link.configure({ openOnClick: false }),
        Subscript,
        Superscript,
        Table.configure({ resizable: true }),
        TableRow,
        TableHeader,
        TableCell
      ],
      enableInputRules: false,
      editable: !this.readonly,
      content: ""
    });

    this.getDescricao();
  },
  beforeDestroy() {
    if (this.editor) {
      this.editor.destroy();
    }
  },
  methods: {
    overrideMaterializeStyle() {
      const colorCode = this.$utils.Web.getMaterializeColorCode(
        DEFAULT_COLOR,
        DEFAULT_LUMINOSITY
      );

      if (colorCode) {
        const CSSStyleSheet = `
        blockquote {
          border-left-color: ${colorCode};
        }

        .contrast blockquote {
            border-left-color: white;
        }
      `;

        this.$utils.Web.createStyleElement({
          id: "stylesheet-blockquote",
          type: "text/css",
          innerHTML: CSSStyleSheet
        });
      }
    },
    getDescricao() {
      if (!this.entidadeId) {
        return;
      }

      this.loading = true;

      const id = this.entidadeId;

      const idSecao = this.secao.id;

      this.characterCountOriginal = 0;
      this.htmlOriginal = null;

      this.service
        .getDescricao(id, idSecao, {
          validateStatus: status =>
            this.$http.isSuccessfulStatus(status) ||
            status === this.$http.HTTP_STATUS_CLIENT_ERROR_NOT_FOUND
        })
        .then(response => {
          if (response.data.conteudoJson) {
            this.editor.commands.setContent(
              JSON.parse(response.data.conteudoJson)
            );

            this.characterCountOriginal = this.editor.getCharacterCount();
            this.htmlOriginal = this.editor.getHTML();
          }
        })
        .catch(error =>
          this.$notification.pushError(
            `Houve um erro ao obter a descrição: ${error.message}`
          )
        )
        .finally(() => (this.loading = false));
    },
    setLink() {
      const prompted = window.prompt(
        "Por favor, preencha o endereço (URL ou de e-mail) para incluir o link"
      );

      if (isEmpty(prompted)) {
        this.$notification.pushError("Endereço (URL ou de e-mail) inválido");
        return;
      }

      const MAILTO = "mailto:";

      const url = prompted.startsWith(MAILTO)
        ? prompted.substring(MAILTO.length)
        : prompted;

      if (this.$utils.String.isEmail(url)) {
        const email = `${MAILTO}${url}`;

        this.editor.chain().focus().setLink({ href: email }).run();
      } else if (this.$utils.String.isUrl(url)) {
        this.editor.chain().focus().setLink({ href: url }).run();
      } else {
        this.$notification.pushError("Endereço (URL ou de e-mail) inválido");
        return;
      }
    },
    save() {
      if (this.readonly) {
        return;
      }

      if (!this.editor) {
        this.$notification.pushError("O editor de texto não foi iniciado");
        return;
      }

      const characterCount = this.editor.getCharacterCount();
      const html = this.editor.getHTML();

      if (this.modeloSecao.obrigatorio && !characterCount) {
        this.$notification.pushError(
          `Seçao "${this.secao.nome}" possui preenchimento obrigatório e não foi encontrado nenhum conteúdo textual nela`
        );
        return;
      }

      // Não utilizo uma comparação estrita para que sejam considerados, por exemplo, casos de `null` == ""
      if (
        (characterCount === 0 &&
          this.characterCountOriginal === characterCount) ||
        (characterCount > 0 && this.htmlOriginal == html)
      ) {
        this.$notification.pushError(
          `Para atualizar a seçao "${this.secao.nome}", é necessário que haja alguma alteração em seu conteúdo`
        );
        return;
      }

      const data = {
        conteudoHtml: html,
        conteudoJson: JSON.stringify(this.editor.getJSON()),
        nomeEditor: EDITOR_NOME,
        versaoEditor: EDITOR_VERSAO
      };

      this.disabled = true;

      const id = this.entidadeId;

      const idSecao = this.secao.id;

      this.service
        .setDescricao(id, idSecao, data)
        .then(() => {
          this.$notification.pushSuccess(`Descrição atualizada com sucesso`);

          this.characterCountOriginal = characterCount;
          this.htmlOriginal = html;
        })
        .catch(error =>
          this.$notification.pushError(
            `Houve um erro ao salvar a descrição: ${error.message}`
          )
        )
        .finally(() => (this.disabled = false));
    }
  }
};
</script>

<style scoped>
.editor-wrapper {
  margin-bottom: 20px;
}

.toolbar {
  border-style: solid;
  border-color: #9e9e9e;
}
.toolbar:first-of-type {
  border-width: 1px 1px 0 1px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.toolbar:last-of-type {
  border-width: 0 1px 1px 1px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}
.toolbar button {
  padding: 0 8px;
  font-weight: bold;
  border-radius: 0;
  transition: 0.3s ease-out;
}
.toolbar button.is-active,
.toolbar button:hover {
  background-color: #9e9e9e;
}
.toolbar .vertical-divider {
  display: inline-table;
  height: 36px;
  width: 1px;
  vertical-align: middle;
  background-color: #e0e0e0;
}

/* Override de regras do editor */
.editor /deep/ .ProseMirror {
  min-height: 200px;
  max-height: 500px;
  overflow-y: scroll;
  border: 1px solid #9e9e9e;
}
.editor /deep/ .ProseMirror ul:not(.browser-default) {
  padding-left: 40px;
}
.editor /deep/ .ProseMirror ul:not(.browser-default),
.editor /deep/ .ProseMirror ul:not(.browser-default) > li {
  list-style-type: disc;
}
.editor /deep/ .ProseMirror table p {
  margin: 0;
}
</style>
