<template>
  <div v-bind:class="gridExpression">
    <div class="input-field">
      <i
        v-if="showIcon && iconName && iconPosition === 'left'"
        class="material-icons prefix"
        tabindex="0"
        v-bind:class="{ clickable: !!iconListeners.click }"
        v-bind:title="iconTitle"
        v-on="iconListeners"
        >{{ iconName }}</i
      >
      <input
        v-bind:id="id"
        ref="input"
        v-bind:type="type"
        v-bind:name="name"
        v-bind:disabled="disabled"
        v-bind:required="required"
        v-bind:placeholder="placeholder"
        v-bind:data-length="dataLength"
        v-bind:class="{
          validate: validate,
          'before-prefix': iconPosition === 'right'
        }"
        v-bind:value="maskedValue"
        v-bind:title="$attrs.title"
        v-bind="extraAttrs"
        v-on="listeners"
      />
      <i
        v-if="showIcon && iconName && iconPosition === 'right'"
        class="material-icons prefix"
        tabindex="0"
        v-bind:class="{ clickable: !!iconListeners.click }"
        v-bind:title="iconTitle"
        v-on="iconListeners"
        >{{ iconName }}</i
      >
      <label v-bind:for="id" class="active" v-bind:class="labelClass">
        <slot>{{ label }}</slot>
      </label>
      <span
        v-if="errorMessage || successMessage"
        class="helper-text"
        v-bind:data-error="errorMessage"
        v-bind:data-success="successMessage"
      ></span>
      <slot name="extra-content"></slot>
    </div>
  </div>
</template>

<script>
/**
 * @module components/base/BaseTextInput/BaseTextInput
 * @category Componentes-base
 * @summary _Single File Component_ (SFC) de _input_ do {@link external:Materialize Materialize}.
 *
 * @description
 * Este componente possui um elemento HTML `div` com classe `input-field` do
 * {@link external:Materialize Materialize}, e, externamente, como _wrapper_,
 * outra `div` com as configurações de responsividade definidas por
 * `gridExpression`.
 *
 * As cores de borda do _input_ e do _label_ são obtidas automaticamente a
 * partir da configuração do ambiente.
 *
 * O _slot_ _default_ recebe o _label_ do elemento HTML `input` utilizado
 * internamente. Há ainda um _slot_ de nome "extra-content" para que seja
 * inserido qualquer tipo de conteúdo extra junto ao `input`.
 *
 * Atributos HTML de acessibilidade, e.g., `title`, podem ser usados
 * diretamente no componente.
 *
 * Para correto funcionamento da contagem de caracteres, é necessário que o
 * _framework_ {@link external:Materialize Materialize} tenha sido importado,
 * tornando seu objeto principal acessível globalmente.
 *
 * @requires module:constants.DEFAULT_COLOR
 * @requires module:constants.DEFAULT_LUMINOSITY
 * @requires module:utils/web.createStyleElement
 * @requires module:utils/web.getMaterializeColorCode
 * @requires module:utils/lang.isFunction
 *
 * @vue-prop {string} [gridExpression="col s12"] - Classes CSS de grid do {@link external:Materialize Materialize}.
 * @vue-prop {string} [id] - Atributo `id` do elemento HTML utilizado
 * internamente pelo componente.
 * @vue-prop {string} [name] - Atributo `name` do elemento HTML utilizado
 * internamente pelo componente.
 * @vue-prop {string} [type="text"] - Atributo `type` do elemento HTML
 * utilizado pelo componente. Aceita "text", "number", "email", "password" e
 * "search".
 * @vue-prop {boolean} [disabled=false] - Indica se o componente estará desabilitado.
 * @vue-prop {boolean} [required=false] - Indica se o componente possui
 * preenchimento obrigatório.
 * @vue-prop {string} [placeholder] - Atributo `placeholder` do elemento HTML
 * utilizado internamente pelo componente.
 * @vue-prop {number} [dataLength] - Quando preenchido, ativa o contador de
 * caracteres do {@link external:Materialize Materialize}. Aceita números positivos.
 * @vue-prop {string} [label] - Conteúdo do _label_ do elemento HTML `input`
 * utilizado internamente. É usado como substituto quando nenhum dado é passado
 * através do _slot_ _default_.
 * @vue-prop {string|Array|object} [labelClass] - Classes CSS para o elemento
 * HTML `label` utilizado internamente pelo componente.
 * @vue-prop {boolean} [showIcon=true] - Indica se o componente deve exibir o
 * ícone especificado.
 * @vue-prop {string} [iconName] - Nome do [ícone do Material Design]{@link external:MaterialDesignIcons}.
 * @vue-prop {string} [iconPosition="left"] - Posição em que o ícone será
 * localizado. Aceita "left" ou "right".
 * @vue-prop {string} [iconTitle] - Contéudo para o atributo `title` do ícone.
 * @vue-prop {function} [iconClick] - Função para ser chamada após evento de
 * click do ícone.
 * @vue-prop {boolean} [autofocus=false] - Indica se o elemento HTML `input`
 * utilizado internamente pelo componente receberá o evento de foco automaticamente.
 * @vue-prop {boolean} [validate=false] - Indica se o componente fará a
 * validação do conteúdo preenchido.
 * @vue-prop {string} [successMessage] - Mensagem de sucesso caso a validação seja positiva.
 * @vue-prop {string} [errorMessage] - Mensagem de sucesso caso a validação seja negativa.
 * @vue-prop {Mask} [mask] - Objeto de máscara para formatação do valor exibido
 * no componente.
 * @vue-prop {object} [extraAttrs] - Atributos extras a serem usados no
 * elemento HTML utilizado internamente pelo componente.
 * @vue-prop {string} [value] - Valor inicial do componente e utilizado internamente
 * para a diretiva `v-model` do {@link external:Vue Vue}.
 * @vue-event {string} onInput - Emite o valor atual do componente. Caso uma
 * máscara tenha sido definida, o valor a ser retornado será formatado. Além
 * disso, este evento também é emitido quando há uma alteração externa na _prop_
 * `value` de modo que esse valor esteja garantido de ser válido de acordo com
 * a máscara escolhida. Pode ser capturado pela diretiva `v-on:input` (ou `v-model`)
 * do {@link external:Vue Vue}.
 *
 * @example
 * <BaseTextInput
 *  id="email-pessoal"
 *  name="email-pessoal"
 *  type="email"
 *  placeholder="E-mail pessoal do servidor"
 *  data-length="80"
 *  v-model="servidor.emailPessoal">
 *  E-mail (pessoal)
 * </BaseTextInput>
 */
import { DEFAULT_COLOR, DEFAULT_LUMINOSITY } from "../../../constants";
import {
  createStyleElement,
  getMaterializeColorCode
} from "../../../utils/web";
import { isFunction } from "../../../utils/lang";

export default {
  name: "BaseTextInput",
  inheritAttrs: false,
  props: {
    gridExpression: {
      type: String,
      default: "col s12"
    },

    id: String,
    name: String,

    type: {
      type: String,
      default: "text",
      validator: value =>
        ["text", "number", "email", "password", "search"].indexOf(value) !== -1
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },

    placeholder: String,

    dataLength: {
      type: Number,
      validator: value => value > 0
    },

    label: String,
    labelClass: [String, Array, Object],

    showIcon: {
      type: Boolean,
      default: true
    },

    iconName: String,

    iconPosition: {
      type: String,
      default: "left",
      validator: value => ["left", "right"].indexOf(value) !== -1
    },

    iconTitle: String,
    iconClick: Function,

    autofocus: {
      type: Boolean,
      default: false
    },
    validate: {
      type: Boolean,
      default: false
    },

    errorMessage: String,
    successMessage: String,

    mask: Object,

    extraAttrs: Object,

    value: String
  },
  data() {
    return {
      instance: null,
      maskedValue: null
    };
  },
  computed: {
    listeners() {
      return Object.assign({}, this.$listeners, { input: this.onInput });
    },
    iconListeners() {
      const listeners = {};

      if (isFunction(this.iconClick)) {
        listeners.click = this.iconClick;

        listeners.keydown = this.iconClickByKey;
      }

      return listeners;
    }
  },
  watch: {
    value: {
      immediate: true,

      handler(newValue) {
        if (this.mask) {
          this.maskedValue = this.mask.format(newValue);

          // Emite um evento de input para garantir que o valor recebido
          // esteja correto fora do componente
          this.$emit("input", this.maskedValue);
        } else {
          this.maskedValue = newValue;
        }
      }
    }
  },
  beforeCreate() {
    if (!window.M) {
      window.console.error(
        "Objeto do Materialize não encontrado. Verifique se essa biblioteca foi carregada corretamente"
      );
    }
  },
  created() {
    this.overrideMaterializeStyle();
  },
  mounted() {
    if (!window.M) {
      return;
    }

    const { input } = this.$refs;

    if (this.dataLength) {
      this.instance = window.M.CharacterCounter.init(input);
    }

    if (this.autofocus) {
      input.focus();
    }
  },
  beforeDestroy() {
    if (this.instance) {
      this.instance.destroy();
    }
  },
  methods: {
    overrideMaterializeStyle() {
      const colorCode = getMaterializeColorCode(
        DEFAULT_COLOR,
        DEFAULT_LUMINOSITY
      );

      if (colorCode) {
        const CSSStyleSheet = `
          input:not([type]):not(.browser-default):focus:not([readonly]),
          input[type="text"]:not(.browser-default):focus:not([readonly]),
          input[type="password"]:not(.browser-default):focus:not([readonly]),
          input[type="email"]:not(.browser-default):focus:not([readonly]),
          input[type="url"]:not(.browser-default):focus:not([readonly]),
          input[type="time"]:not(.browser-default):focus:not([readonly]),
          input[type="date"]:not(.browser-default):focus:not([readonly]),
          input[type="datetime"]:not(.browser-default):focus:not([readonly]),
          input[type="datetime-local"]:not(.browser-default):focus:not([readonly]),
          input[type="tel"]:not(.browser-default):focus:not([readonly]),
          input[type="number"]:not(.browser-default):focus:not([readonly]),
          input[type="search"]:not(.browser-default):focus:not([readonly]) {
            border-bottom-color: ${colorCode};
            box-shadow: 0 1px 0 0 ${colorCode}; /* A regra inteira foi reescrita, pois ainda não existe uma regra do tipo \`box-shadow-color\` */
          }
          input:not([type]):not(.browser-default):focus:not([readonly]) + label,
          input[type="text"]:not(.browser-default):focus:not([readonly]) + label,
          input[type="password"]:not(.browser-default):focus:not([readonly]) + label,
          input[type="email"]:not(.browser-default):focus:not([readonly]) + label,
          input[type="url"]:not(.browser-default):focus:not([readonly]) + label,
          input[type="time"]:not(.browser-default):focus:not([readonly]) + label,
          input[type="date"]:not(.browser-default):focus:not([readonly]) + label,
          input[type="datetime"]:not(.browser-default):focus:not([readonly]) + label,
          input[type="datetime-local"]:not(.browser-default):focus:not([readonly]) + label,
          input[type="tel"]:not(.browser-default):focus:not([readonly]) + label,
          input[type="number"]:not(.browser-default):focus:not([readonly]) + label,
          input[type="search"]:not(.browser-default):focus:not([readonly]) + label {
            color: ${colorCode};
          }

          .input-field .prefix.active {
            color: ${colorCode};
          }
        `;

        createStyleElement({
          id: "stylesheet-textinput",
          type: "text/css",
          innerHTML: CSSStyleSheet
        });
      }
    },
    iconClickByKey($event) {
      if (["Enter", " ", "Spacebar"].indexOf($event.key) !== -1) {
        if (isFunction(this.iconClick)) {
          this.iconClick();
        }
      }
    },
    onInput($event) {
      const { value } = $event.target;

      // Tratamento específico para máscaras
      if (this.mask) {
        this.maskedValue = this.mask.unformat(this.mask.format(value));

        // Sobrescreve o valor do elemento HTML input. Se o valor novo é válido,
        // ele será atualizado com o valor correto. Caso contrário, ele vai ser revertido
        // para o valor interno anterior.
        this.$refs.input.value = this.maskedValue;
      } else {
        this.maskedValue = value;
      }

      this.$emit("input", this.maskedValue);
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
</style>
