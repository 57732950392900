const i18n = Object.freeze({
  "pt-BR": {
    cancel: "Cancelar",
    clear: "Limpar",
    done: "Ok"
  }
});

export default i18n;

export { i18n };
