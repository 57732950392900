/**
 * @module components/base/BaseSearchBar
 * @category Componentes-base
 * @summary Módulo de importação do componente-base de barra de busca.
 *
 * @description
 * Expõe o componente-base de barra de busca para utilização pelo container e pelos
 * módulos.
 *
 * @example <caption>Importando como plug-in</caption>
 * // main.js
 * import BaseSearchBar from "./components/base/BaseSearchBar";
 *
 * Vue.use(BaseSearchBar);
 *
 * @example <caption>Importando diretamente em um componente</caption>
 * // componente.vue
 * // ou import BaseSearchBar from "./components/base/BaseSearchBar/BaseSearchBar.vue";
 * import { BaseSearchBar } from "./components/base/BaseSearchBar";
 *
 * export default {
 *  name: // ...
 *  components: {
 *    BaseSearchBar
 *  },
 *  // ...
 * }
 */
import { default as BaseSearchBar } from "./BaseSearchBar.vue";

/**
 * Associa o componente-base de barra de busca à instância do {@link external:Vue Vue}.
 * @param {external:Vue} Vue - Instância do {@link external:Vue Vue}.
 */
const install = Vue => Vue.component(BaseSearchBar.name, BaseSearchBar);

if (window && window.Vue) {
  install(window.Vue);
}

export default install;

export { BaseSearchBar };
