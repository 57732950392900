/**
 * @module utils/web
 * @category Utilidades
 * @summary Módulo de utilidades para manipulação de elementos HTML e
 * relacionados.
 *
 * @description
 * Expõe funções de utilidades para manipulação de elementos HTML e
 * relacionados.
 *
 * @requires module:constants.MATERIALIZE_COLOR_PALETTE
 * @requires module:utils/lang.isEmpty
 * @requires module:utils/lang.isFunction
 * @requires module:utils/lang.isString
 * @requires module:utils/web/Storage
 */

import MATERIALIZE_COLOR_PALETTE from "../../constants/MaterializeColorPalette";
import { isEmpty, isFunction, isString } from "../lang";
import { default as StorageUtils } from "./Storage";

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Remainder
const mod = function(dividend, divisor) {
  return ((dividend % divisor) + divisor) % divisor;
};

/**
 * Concatena as propriedades de um objeto para montar uma querystring.
 * @function
 * @param {object} params - Objeto a ter suas propriedades concatenadas.
 * @returns {string} Texto no formato de querystring.
 */
export const buildQueryString = function(params) {
  let queryString = "";

  if (!params) {
    return queryString;
  }

  queryString = Object.keys(params)
    .filter(key => !isFunction(params[key]))
    .map(key => `${key}=${params[key]}`)
    .join("&");

  return queryString ? `?${queryString}` : queryString;
};

/**
 * Cria um elemento HTML `style` no `head` do documento HTML. Útil para criar
 * folhas de estilos dinamicamente.
 * @function
 * @param {object} props - Opções aceitas pelo elemento HTML `style`.
 * O atributo `id` é obrigatório para que evite-se estilos iguais criados mais
 * de uma vez.
 */
export const createStyleElement = function(props) {
  if (!props || isEmpty(props.id)) {
    throw new Error(
      "Para criar um elemento de folha de estilo é necessário um `id`"
    );
  }

  if (!document.getElementById(props.id)) {
    document.head.appendChild(
      Object.assign(document.createElement("style"), props)
    );
  }
};

/**
 * Cria um elemento HTML `script` no `head` do documento HTML. Útil para
 * importar códigos javascript dinamicamente.
 * @function
 * @param {object} props - Opções aceitas pelo elemento HTML `script`.
 * O atributo `id` é obrigatório para que evite-se _scrips_ iguais criados mais
 * de uma vez.
 */
export const createScriptElement = function(props) {
  if (!props || isEmpty(props.id)) {
    throw new Error("Para criar um elemento de script é necessário um `id`");
  }

  if (!document.getElementById(props.id)) {
    document.head.appendChild(
      Object.assign(document.createElement("script"), props)
    );
  }
};

/**
 * Escapa um texto que possivelmente contenha entidades HTML para ser exibido
 * corretamente na estrutura da página.
 * @function
 * @param {string} html
 * @returns {string}
 */
export const escapeHtml = function(html) {
  if (isEmpty(html)) {
    return null;
  }

  return html
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;"); // &apos; não é suportado em HTML4
};

/**
 * Obtém o código RGB da paleta de cores do ({@link external:Materialize Materialize} a partir das classes de cor e de luminosidade.
 * @function
 * @param {string} color - Classe de cor aceita pelo Materialize.
 * @param {string} luminosity - Classe de luminosidade aceita pelo Materialize.
 * @returns {string}
 */
export const getMaterializeColorCode = function(color, luminosity) {
  return (
    MATERIALIZE_COLOR_PALETTE[luminosity ? `${color} ${luminosity}` : color] ||
    null
  );
};

/**
 * Decompõe a querystring ({@link external:Location Location}.`search`) em um objeto.
 * @function
 * @param {string} search
 * @returns {object}
 */
export const getQueryStringParams = function(search) {
  const obj = {};

  if (!search || !search.startsWith("?")) {
    return obj;
  }

  let s = search.substring(1);

  if (!s) {
    return obj;
  }

  s.split("&").forEach(value => {
    const [p, v] = value.split("=");

    obj[p] = v;
  });

  return obj;
};

/**
 * Diminui a luminosidade de uma cor em determinado valor. Baseado em {@link https://sass-lang.com/documentation/modules/color#darken}.
 * @function
 * @param {string} color - Código hexadecimal (RGB) de uma cor. Aceita iniciar com "#".
 * @param {number} amount - Valor a ser reduzido da saturação. Precisa estar entre 0 e 1.
 * @returns {string} - Código hexadecimal (RGB) após a diminuição da luminosidade.
 */
export const darken = function(color, amount) {
  const { h, s, l } = hsl(color);

  const newL = Math.max(l - amount, 0);

  const { r, g, b } = hslToRgb(h, s, newL, false);

  return (
    "#" +
    [
      r.toString(16).padStart(2, "0"),
      g.toString(16).padStart(2, "0"),
      b.toString(16).padStart(2, "0")
    ].join("")
  );
};

/**
 * Reduz a saturação de uma cor em determinado valor. Baseado em {@link https://sass-lang.com/documentation/modules/color#desaturate}.
 * @function
 * @param {string} color - Código hexadecimal (RGB[A]) de uma cor. Aceita
 * iniciar com "#".
 * @param {number} amount - Valor a ser reduzido da saturação. Precisa estar
 * entre 0 e 1.
 * @returns {string} - Código hexadecimal (RGB) após a redução da saturação.
 */
export const desaturate = function(color, amount) {
  const { h, s, l } = hsl(color);

  const newS = Math.max(s - amount, 0);

  const { r, g, b } = hslToRgb(h, newS, l, false);

  return (
    "#" +
    [
      r.toString(16).padStart(2, "0"),
      g.toString(16).padStart(2, "0"),
      b.toString(16).padStart(2, "0")
    ].join("")
  );
};

/**
 * Converte uma cor em código hexadecimal (RGB[A]) para representação CMYK.
 * @function
 * @param {string} color - Código hexadecimal (RGB[A]) de uma cor. Aceita
 * iniciar com "#".
 * @returns {object} - Objeto com propriedades `c`, `m`, `y` e `k`.
 */
export const cmyk = function(color) {
  const { r, g, b } = rgb(color, true);

  if (r === 0 && g === 0 && b === 0) {
    return { c: 0, m: 0, y: 0, k: 1 };
  }

  const k = 1 - Math.max(r, g, b);

  const c = (1 - r - k) / (1 - k);
  const m = (1 - g - k) / (1 - k);
  const y = (1 - b - k) / (1 - k);

  return { c, m, y, k };
};

/**
 * Converte uma cor em código hexadecimal (RGB[A]) para representação HSL.
 * @function
 * @param {string} color - Código hexadecimal (RGB[A]) de uma cor. Aceita
 * iniciar com "#".
 * @returns {object} - Objeto com propriedades `h`, `s` e `l`.
 */
export const hsl = function(color) {
  // Fórmula baseada em:
  // https://en.wikipedia.org/wiki/HSL_and_HSV
  const { r, g, b } = rgb(color, true);

  const xmax = Math.max(r, g, b);
  const xmin = Math.min(r, g, b);

  const v = xmax;
  const c = xmax - xmin;
  const l = v - c / 2;

  let h = null;

  if (c === 0) {
    h = 0;
  } else if (v === r) {
    h = 60 * (0 + mod((g - b) / c, 6));
  } else if (v === g) {
    h = 60 * (2 + (b - r) / c);
  } else if (v === b) {
    h = 60 * (4 + (r - g) / c);
  }

  const s = c === 0 || l === 0 || l === 1 ? 0 : c / (1 - Math.abs(2 * l - 1));

  return { h, s, l };
};

/**
 * Converte uma cor em código hexadecimal (RGB[A]) para representação HSV.
 * @function
 * @param {string} color - Código hexadecimal (RGB[A]) de uma cor. Aceita
 * iniciar com "#".
 * @returns {object} - Objeto com propriedades `h`, `s` e `v`.
 */
export const hsv = function(color) {
  // Fórmula baseada em:
  // https://en.wikipedia.org/wiki/HSL_and_HSV
  const { r, g, b } = rgb(color, true);

  const xmax = Math.max(r, g, b);
  const xmin = Math.min(r, g, b);

  const v = xmax;
  const c = xmax - xmin;

  let h = null;

  if (c === 0) {
    h = 0;
  } else if (v === r) {
    h = 60 * (0 + mod((g - b) / c, 6));
  } else if (v === g) {
    h = 60 * (2 + (b - r) / c);
  } else if (v === b) {
    h = 60 * (4 + (r - g) / c);
  }

  const s = v === 0 ? 0 : c / v;

  return { h, s, v };
};

/**
 * Converte componentes CMYK em componentes RGB.
 * @function
 * @param {string} c - Componente que representa o cian, no intervalo [0, 1].
 * @param {string} m - Componente que representa o magenta, no intervalo [0, 1].
 * @param {string} y - Componente que representa o amarelo, no intervalo [0, 1].
 * @param {string} k - Componente que representa o preto, no intervalo [0, 1].
 * @param {boolean} range - Indica se cada componente estará no intervalo entre
 * 0 e 1.
 * @returns {object} - Objeto com propriedades `r`, `g` e `b`.
 */
export const cmykToRgb = function(c, m, y, k, range) {
  const r = (1 - c) * (1 - k);
  const g = (1 - m) * (1 - k);
  const b = (1 - y) * (1 - k);

  if (range) {
    return { r, g, b };
  } else {
    return {
      r: Math.round(r * 255),
      g: Math.round(g * 255),
      b: Math.round(b * 255)
    };
  }
};

/**
 * Converte componentes HSL em componentes RGB.
 * @function
 * @param {number} h - Componente que representa a matiz, no intervalo [0, 360].
 * @param {number} s - Componente que representa a saturação, no intervalo [0, 1].
 * @param {number} l - Componente que representa a luminosidade, no intervalo [0, 1].
 * @param {boolean} range - Indica se cada componente estará no intervalo entre
 * 0 e 1.
 * @returns {object} - Objeto com propriedades `r`, `g` e `b`.
 */
export const hslToRgb = function(h, s, l, range) {
  // Fórmula baseada em:
  // https://en.wikipedia.org/wiki/HSL_and_HSV
  const f = function(n) {
    const k = (n + h / 30) % 12;
    const a = s * Math.min(l, 1 - l);

    return l - a * Math.max(-1, Math.min(k - 3, 9 - k, 1));
  };

  if (range) {
    return { r: f(0), g: f(8), b: f(4) };
  } else {
    return {
      r: Math.round(f(0) * 255),
      g: Math.round(f(8) * 255),
      b: Math.round(f(4) * 255)
    };
  }
};

/**
 * Converte componentes HSV em componentes RGB.
 * @function
 * @param {number} h - Componente que representa a matiz, no intervalo [0, 360].
 * @param {number} s - Componente que representa a saturação, no intervalo [0, 1].
 * @param {number} v - Componente que representa o valor, no intervalo [0, 1].
 * @param {boolean} range - Indica se cada componente estará no intervalo entre
 * 0 e 1.
 * @returns {object} - Objeto com propriedades `r`, `g` e `b`.
 */
export const hsvToRgb = function(h, s, v, range) {
  // Fórmula baseada em:
  // https://en.wikipedia.org/wiki/HSL_and_HSV
  const f = function(n) {
    const k = (n + h / 60) % 6;

    return v - v * s * Math.max(0, Math.min(k, 4 - k, 1));
  };

  if (range) {
    return { r: f(5), g: f(3), b: f(1) };
  } else {
    return {
      r: Math.round(f(5) * 255),
      g: Math.round(f(3) * 255),
      b: Math.round(f(1) * 255)
    };
  }
};

/**
 * Aumenta a luminosidade de uma cor em determinado valor. Baseado em {@link https://sass-lang.com/documentation/modules/color#lighten}.
 * @function
 * @param {string} color - Código hexadecimal (RGB) de uma cor. Aceita iniciar com "#".
 * @param {number} amount - Valor a ser reduzido da saturação. Precisa estar entre 0 e 1.
 * @returns {string} - Código hexadecimal (RGB) após o aumento da luminosidade.
 */
export const lighten = function(color, amount) {
  const { h, s, l } = hsl(color);

  const newL = Math.min(l + amount, 1);

  const { r, g, b } = hslToRgb(h, s, newL, false);

  return (
    "#" +
    [
      r.toString(16).padStart(2, "0"),
      g.toString(16).padStart(2, "0"),
      b.toString(16).padStart(2, "0")
    ].join("")
  );
};

/**
 * Reduz a transparência de uma cor em determinado valor. Baseado em {@link https://sass-lang.com/documentation/modules/color#transparentize}.
 * @function
 * @param {string} color - Código hexadecimal (RGB[A]) de uma cor. Aceita
 * iniciar com "#".
 * @param {number} amount - Valor a ser reduzido da transparência. Precisa
 * estar entre 0 e 1.
 * @returns {string} - Código hexadecimal (RGBA) após a redução da saturação.
 */
export const transparentize = function(color, amount) {
  const { r, g, b, a } = rgba(color);

  const newA = Math.max(a - amount * 100, 0);

  return (
    "#" +
    [
      r.toString(16).padStart(2, "0"),
      g.toString(16).padStart(2, "0"),
      b.toString(16).padStart(2, "0"),
      newA.toString(16).padStart(2, "0")
    ].join("")
  );
};

/**
 * Decompõe um código hexadecimal (RGB[A]) em componentes.
 * @function
 * @param {string} color - Código hexadecimal (RGB[A]) de uma cor. Aceita
 * iniciar com "#".
 * @param {boolean} range - Indica se cada componente estará no intervalo entre
 * 0 e 1.
 * @returns {object} - Objeto com propriedades `r`, `g` e `b`.
 */
export const rgb = function(color, range) {
  const { r, g, b } = rgba(color, range);

  return { r, g, b };
};

/**
 * Decompõe um código hexadecimal (RGB[A]) em componentes.
 * @function
 * @param {string} color - Código hexadecimal (RGB[A]) de uma cor. Aceita
 * iniciar com "#".
 * @param {boolean} range - Indica se cada componente estará no intervalo entre
 * 0 e 1.
 * @returns {object} - Objeto com propriedades `r`, `g`, `b` e `a`.
 */
export const rgba = function(color, range) {
  let r = null;
  let g = null;
  let b = null;
  let a = "64"; // 100

  if (!isString(color)) {
    throw new Error("Cor inválida");
  }

  const hex = color.startsWith("#") ? color.substr(1) : color;

  if (hex.length === 3 || hex.length === 4) {
    r = hex.charAt(0) + hex.charAt(0);
    g = hex.charAt(1) + hex.charAt(1);
    b = hex.charAt(2) + hex.charAt(2);

    if (hex.length === 4) {
      a = hex.charAt(3) + hex.charAt(3);
    }
  } else if (hex.length === 6 || hex.length === 8) {
    r = hex.slice(0, 2);
    g = hex.slice(2, 4);
    b = hex.slice(4, 6);

    if (hex.length === 8) {
      a = hex.slice(6, 8);
    }
  } else {
    throw new Error("Cor inválida");
  }

  const max = range ? 255 : 1;
  const maxa = range ? 100 : 1;

  return {
    r: window.parseInt(r, 16) / max,
    g: window.parseInt(g, 16) / max,
    b: window.parseInt(b, 16) / max,
    a: window.parseInt(a, 16) / maxa
  };
};

/**
 * Objeto/_namespace_ com funções de utilidades para manipulação de {@link external:Storage Storage}
 * dos tipos "local" e "session".
 * @type {object}
 * @readonly
 */
export const Storage = StorageUtils;

export default {
  buildQueryString,
  createScriptElement,
  createStyleElement,
  escapeHtml,
  getMaterializeColorCode,
  getQueryStringParams,
  cmyk,
  hsl,
  hsv,
  cmykToRgb,
  hslToRgb,
  hsvToRgb,
  lighten,
  rgb,
  rgba,
  Storage
};
