<template>
  <article>
    <h5>
      <i v-if="hasNomeSocial" class="material-icons left" title="Nome social"
        >info</i
      >
      {{ nomeExibicao }}
    </h5>

    <p>
      <b>{{ identificador.title }}:</b>&nbsp;
      {{ identificador.value }}
      <br />
      <b>E-mail:</b>&nbsp;
      {{ email }}
    </p>

    <section class="section">
      <h6>Usuários</h6>

      <p v-if="!hasUsuarios" class="flow-text center">Nenhum usuário criado.</p>
      <BaseCollection v-else>
        <template v-slot:items>
          <li
            v-for="(item, index) in usuarios"
            v-bind:key="index"
            class="collection-item"
          >
            <BaseBuscaUsuarioItem v-bind="item" v-on="listenersUsuario" />
          </li>
        </template>
      </BaseCollection>
    </section>

    <div
      v-if="hasAnyEvent"
      class="buttons-row"
      v-bind:class="{ 'buttons-row-single': !mayAddUsuario }"
    >
      <BaseButton
        v-if="hasEventAddUsuario && mayAddUsuario"
        v-bind="buttonAddUsuario"
        v-on:click="addUsuario"
      />
      <BaseButton v-if="hasEventEdit" v-bind="buttonEdit" v-on:click="edit" />
    </div>
  </article>
</template>

<script>
import { UsuarioService } from "../../services";
import BaseBuscaUsuarioItem from "./BaseBuscaUsuarioItem.vue";

export default {
  name: "BaseBuscaPessoaItem",
  components: {
    BaseBuscaUsuarioItem
  },
  inheritAttrs: false,
  props: {
    readonly: Boolean,

    id: {
      type: Number,
      required: true
    },
    nome: {
      type: String,
      required: true
    },

    nomeSocial: String,

    nomeExibicao: {
      type: String,
      required: true
    },

    cpf: String,
    passaporte: String,

    email: {
      type: String,
      required: true
    },

    usuarios: Array
  },
  data() {
    return {
      buttonAddUsuario: {
        iconName: "business",
        content: "Novo",
        title: "Criar usuário externo"
      },
      buttonEdit: {
        iconName: "edit",
        color: "green",
        content: "Editar",
        title: "Editar dados pessoais"
      }
    };
  },
  computed: {
    hasUsuarios() {
      return !this.$utils.Lang.isEmpty(this.usuarios);
    },
    hasNomeSocial() {
      return !!this.nomeSocial;
    },
    identificador() {
      if (this.cpf) {
        return {
          title: "CPF",
          value: this.cpf
        };
      } else {
        return {
          title: "Passaporte",
          value: this.passaporte
        };
      }
    },
    hasEventAddUsuario() {
      return typeof this.$listeners["add-usuario"] === "function";
    },
    hasEventEdit() {
      return typeof this.$listeners.edit === "function";
    },
    hasEventAddEditor() {
      return typeof this.$listeners["add-editor"] === "function";
    },
    hasEventEditPermissoes() {
      return typeof this.$listeners["edit-permissoes"] === "function";
    },
    hasEventResetSenha() {
      return typeof this.$listeners["reset-senha"] === "function";
    },
    hasAnyEvent() {
      return (
        !!this.$listeners.length || this.hasEventAddUsuario || this.hasEventEdit
      );
    },
    mayAddUsuario() {
      return (
        !this.hasUsuarios ||
        this.usuarios.findIndex(
          item => item.tipoUsuarioId === UsuarioService.EXTERNO
        ) === -1
      );
    },
    listenersUsuario() {
      const listeners = {};

      if (this.hasEventAddEditor) {
        listeners["add-editor"] = this.$listeners["add-editor"];
      }

      if (this.hasEventEditPermissoes) {
        listeners["edit-permissoes"] = this.$listeners["edit-permissoes"];
      }

      if (this.hasEventResetSenha) {
        listeners["reset-senha"] = this.$listeners["reset-senha"];
      }

      return listeners;
    }
  },
  methods: {
    addUsuario() {
      if (!this.mayAddUsuario) {
        return;
      }

      this.$emit("add-usuario", this.id);
    },
    edit() {
      this.$emit("edit", this.id);
    }
  }
};
</script>

<style scoped>
.buttons-row-single {
  justify-content: flex-end;
}
</style>
