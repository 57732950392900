<template>
  <label v-bind:for="id">
    <input
      v-bind:id="id"
      type="radio"
      v-bind:name="name"
      v-bind:disabled="disabled"
      v-bind:class="{ 'with-gap': withGap }"
      v-bind:value="value"
      v-bind:checked="isChecked"
      v-bind:title="$attrs.title"
      v-on="listeners"
    />
    <span>
      <slot>{{ content }}</slot>
    </span>
  </label>
</template>

<script>
/**
 * @module components/base/BaseRadioButton/BaseRadioButton
 * @category Componentes-base
 * @summary _Single File Component_ (SFC) de _radiobutton_ do {@link external:Materialize Materialize}.
 *
 * @description
 * A cor de preenchimento e de marcação do _radiobutton_ é obtida
 * automaticamente a partir da configuração do ambiente.
 *
 * O _slot_ _default_ recebe o conteúdo/_label_ do _radiobutton_.
 *
 * Atributos HTML de acessibilidade, e.g., `title`, podem ser usados
 * diretamente no componente.
 *
 * @requires module:constants.DEFAULT_COLOR
 * @requires module:constants.DEFAULT_LUMINOSITY
 * @requires module:utils/web.createStyleElement
 * @requires module:utils/web.getMaterializeColorCode
 *
 * @vue-prop {string} [id] - Atributo `id` do elemento HTML utilizado
 * internamente pelo componente.
 * @vue-prop {string} [name] - Atributo `name` do elemento HTML utilizado
 * internamente pelo componente.
 * @vue-prop {boolean} [disabled=false] - Indica se o componente estará
 * desabilitado.
 * @vue-prop {boolean} [withGap=false] - Indica se o círculo de seleção terá um
 * estilo preenchido.
 * @vue-prop {string} [content] - Conteúdo a ser exibido no elemento HTML
 * utilizado iternamente pelo componente. É usado como substituto quando nenhum
 * dado é passado através do _slot_ _default_.
 * @vue-prop {*} [value] - Valor inicial do componente e utilizado internamente
 * para a diretiva `v-model` do {@link external:Vue Vue}.
 * @vue-prop {boolean|string|number|object} [vValue=false] - Valor intrínseco e
 * representativo do componente.
 * @vue-event {boolean|string|number|object} onInput - Emite o valor
 * intrínseco e representativo do componente ou `null` (`false` no caso do
 * tipo ser `boolean`). Pode ser capturado pela diretiva `v-on:input`
 * (ou `v-model`) do {@link external:Vue Vue}.
 * @vue-event {boolean|string|number|object} onChange - Emite o valor
 * intrínseco e representativo do componente ou `null` (`false` no caso do
 * tipo ser `boolean`). Pode ser capturado pela diretiva e `v-on:change` do {@link external:Vue Vue}.
 *
 * @example
 * <BaseRadioButton id="imagem-pequena" name="exibicao-imagem" v-model="servidor.foto.tamanhoDescritivo">
 *  Pequena
 * </BaseRadioButton>
 * <BaseRadioButton id="imagem-media" name="exibicao-imagem" v-model="servidor.foto.tamanhoDescritivo">
 *  Média
 * </BaseRadioButton>
 * <BaseRadioButton id="imagem-grande" name="exibicao-imagem" v-model="servidor.foto.tamanhoDescritivo">
 *  Grande
 * </BaseRadioButton>
 */
import { DEFAULT_COLOR, DEFAULT_LUMINOSITY } from "../../../constants";
import {
  createStyleElement,
  getMaterializeColorCode
} from "../../../utils/web";

export default {
  name: "BaseRadioButton",
  inheritAttrs: false,
  props: {
    id: String,
    name: String,

    disabled: {
      type: Boolean,
      default: false
    },
    withGap: {
      type: Boolean,
      default: false
    },

    content: String,

    value: {},
    vValue: {
      type: [Boolean, String, Number, Object],
      default: false
    }
  },
  computed: {
    isChecked() {
      return this.vValue === this.value;
    },
    listeners() {
      return Object.assign({}, this.$listeners, { change: this.onChange });
    }
  },
  created() {
    this.overrideMaterializeStyle();
  },
  methods: {
    overrideMaterializeStyle() {
      const colorCode = getMaterializeColorCode(
        DEFAULT_COLOR,
        DEFAULT_LUMINOSITY
      );

      if (colorCode) {
        const CSSStyleSheet = `
            [type="radio"]:checked + span::after,
            [type="radio"].with-gap:checked + span::after {
                background-color: ${colorCode};
            }
            [type="radio"]:checked + span::after,
            [type="radio"].with-gap:checked + span::before,
            [type="radio"].with-gap:checked + span::after {
                border-color: ${colorCode};
            }
      `;

        createStyleElement({
          id: "stylesheet-radiobutton",
          type: "text/css",
          innerHTML: CSSStyleSheet
        });
      }
    },
    onChange() {
      this.$emit("input", this.vValue);
      this.$emit("change", this.vValue);
    }
  }
};
</script>

<style scoped>
.contrast [type="radio"]:checked + span::after,
.contrast [type="radio"].with-gap:checked + span::after {
  background-color: white !important;
}
.contrast [type="radio"]:checked + span::after,
.contrast [type="radio"].with-gap:checked + span::before,
.contrast [type="radio"].with-gap:checked + span::after {
  border-color: white;
}
</style>
