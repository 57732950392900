/**
 * @module services/Pessoa
 * @category Serviços
 * @summary Módulo de importação do serviço de pessoas.
 *
 * @description
 * Expõe o serviço de pessoas do sistema para utilização interna.
 *
 * @example <caption>Importando o serviço de pessoas como membro default do módulo</caption>
 * import PessoaService from "./services/Pessoa";
 *
 * @example <caption>Importando o serviço de pessoas como membro do módulo</caption>
 * import { PessoaService } from "./services/Pessoa";
 */
import { default as PessoaService } from "./Pessoa";

export default PessoaService;

export { PessoaService };
