<template>
  <div v-bind:id="computedId">
    <h5 class="pae-title">{{ formattedNome }}</h5>
    <p>{{ value.descricao }}</p>
  </div>
</template>

<script>
import { toISOTime } from "../../../utils/datetime";

export default {
  name: "SectionConteudoItem",
  inheritAttrs: false,
  props: {
    value: Object
  },
  computed: {
    computedId() {
      return `conteudo-${this.value.id}`;
    },
    formattedNome() {
      const { inicio, nome } = this.value || {};

      return inicio ? `${toISOTime(inicio).substr(0, 5)} - ${nome}` : nome;
    }
  }
};
</script>
