<template>
  <BaseConfirmModal v-bind="confirmModal" v-on:confirm="onConfirm">
    <template v-slot:header>
      <h4>Reset de senha usuário</h4>
    </template>

    <template v-slot:default>
      <p>
        Ao solicitar um reset de senha para o usuário, será enviado à pessoa
        responsável um email contendo um código de acesso e as instruções para
        definição de nova senha. Além disso, o usuário se tornará bloqueado até
        a definição de nova senha.
      </p>
      <p>
        Confirma o reset de senha do usuário "{{ value.login }}" pertencente a
        "{{ nomeExibicao }}" ({{ identificacao.title }}:
        {{ identificacao.value }})?
      </p>
    </template>
  </BaseConfirmModal>
</template>

<script>
export default {
  name: "ResetSenhaConfirmModal",
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      required: true
    },
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      confirmModal: {
        id: this.id
      }
    };
  },
  computed: {
    pessoa() {
      return this.value.pessoa || {};
    },
    nomeExibicao() {
      return this.pessoa.nomeExibicao;
    },
    identificacao() {
      const { cpf, passaporte } = this.pessoa;

      const identificacao = {};

      if (cpf) {
        identificacao.title = "CPF";
        identificacao.value = cpf;
      } else {
        identificacao.title = "Passaporte";
        identificacao.value = passaporte;
      }

      return identificacao;
    }
  },
  methods: {
    onConfirm(event) {
      this.$emit("confirm", event);
    }
  }
};
</script>
