/**
 * @module plugins/Utils
 * @category Plug-ins
 * @summary Módulo de importação do _plug-in_ de utilidades.
 *
 * @description
 * Expõe o _plug-in_ de utilidades para utilização pelo container e pelos
 * módulos.
 *
 * Ao chamar o método `use` do objeto `Vue` global, torna-se possível
 * acessar o _plug-in_ dentro de componentes através da propriedade
 * `$utils`.
 *
 * @example <caption>Importando o plug-in de utilidades como membro default do módulo</caption>
 * import Utils from "./plugins/Utils";
 *
 * Vue.use(Utils);
 *
 * @example <caption>Importando o plug-in de utilidades como membro do módulo</caption>
 * import { Utils } from "./plugins/Utils";
 *
 * Vue.use(Utils);
 */
import { default as Utils } from "./Utils";

/**
 * Associa este _plug-in_ à instância do {@link external:Vue Vue}, atribuindo a
 * ela a propriedade `$utils`, tornando-se acessível pelos componentes.
 * Carrega, automaticamente, as folhas de estilo deste _plug-in_.
 * @param {external:Vue} Vue - Instância do {@link external:Vue Vue}.
 * @param {object} options - Objeto com as opções deste _plug-in_.
 */
const install = function(Vue, options = {}) {
  Utils.options = Object.assign({}, Utils.options, options);

  Object.defineProperty(Vue.prototype, "$utils", {
    get() {
      return Utils;
    }
  });
};

// Atribui a função install ao objeto do _plug-in_.
Utils.install = install;

export default install;

export { Utils };
