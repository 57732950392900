/**
 * @module directives/Autofocus/Autofocus
 * @category Diretivas
 * @summary Módulo da diretiva de _autofocus_.
 *
 * @description
 * Expõe o objeto/_namespace_ da diretiva de _autofocus_ para utilização pelo
 * container e pelos módulos.
 *
 * Esta diretiva ativa a funcionalidade de _autofocus_ a um elemento HTML ou a
 * um componente. No caso do uso em componentes, seu elemento mais externo
 * receberá o evento de foco.
 *
 * @example <caption>Utilizando a diretiva em um componente</caption>
 * <CustomComponent v-autofocus>
 *  ...
 * </CustomComponent>
 *
 * @example <caption>Utilizando a diretiva em um elemento HTML input</caption>
 * <input v-autofocus type="text" name="busca-servidor" />
 */

/**
 * @namespace Autofocus
 * @category Diretivas
 * @summary Objeto/_namespace_ da diretiva de _autofocus_.
 */
const Autofocus = {
  /**
   * Cria uma instância de _autofocus_ para o elemento HTML em que a diretiva
   * foi atribuída.
   * @param {external:Element} el - Elemento HTML em que a diretiva foi atribuída.
   */
  inserted(el) {
    el.focus();
  }
};

export default Autofocus;
