<template>
  <component
    v-bind:is="component"
    v-bind:id="id"
    v-bind:tag="tag"
    v-bind:entidade-id="entidadeId"
    v-bind:modelo="modelo"
    v-bind:secao="value"
  ></component>
</template>

<script>
import { TipoSecaoService } from "../../../services";
import SectionDescricao from "./SectionDescricao.vue";
import SectionLinks from "./SectionLinks.vue";
import SectionGaleria from "./SectionGaleria.vue";
import SectionEstandes from "./SectionEstandes.vue";
import SectionConteudosProgramados from "./SectionConteudosProgramados.vue";
import SectionConteudosExtras from "./SectionConteudosExtras.vue";

export default {
  name: "Section",
  inheritAttrs: false,
  props: {
    tag: {
      type: String,
      default: "h3",
      validator: value =>
        ["h1", "h2", "h3", "h4", "h5", "h6"].indexOf(value) !== -1
    },
    entidadeId: {
      type: Number,
      required: true
    },
    modelo: {
      type: Object,
      required: true
    },
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      id: this.getId(),
      component: this.getComponent()
    };
  },
  methods: {
    getId() {
      switch (this.value.tipoSecaoId) {
        case TipoSecaoService.DESCRICAO:
          return `descricao-${this.value.id}`;
        case TipoSecaoService.LINKS:
          return `links-${this.value.id}`;
        case TipoSecaoService.GALERIA:
          return `galeria-${this.value.id}`;
        case TipoSecaoService.ESTANDES:
          return `estandes-${this.value.id}`;
        case TipoSecaoService.CONTEUDOS_PROGRAMADOS:
          return `conteudos-programados-${this.value.id}`;
        case TipoSecaoService.CONTEUDOS_EXTRAS:
          return `conteudos-extras-${this.value.id}`;
        default:
          return null;
      }
    },
    getComponent() {
      switch (this.value.tipoSecaoId) {
        case TipoSecaoService.DESCRICAO:
          return SectionDescricao;
        case TipoSecaoService.LINKS:
          return SectionLinks;
        case TipoSecaoService.GALERIA:
          return SectionGaleria;
        case TipoSecaoService.ESTANDES:
          return SectionEstandes;
        case TipoSecaoService.CONTEUDOS_PROGRAMADOS:
          return SectionConteudosProgramados;
        case TipoSecaoService.CONTEUDOS_EXTRAS:
          return SectionConteudosExtras;
        default:
          return null;
      }
    }
  }
};
</script>
