/**
 * @module components/base/BaseModal
 * @category Componentes-base
 * @summary Módulo de importação do componente-base de modal do {@link external:Materialize Materialize}.
 *
 * @description
 * Expõe o componente-base de modal do {@link external:Materialize Materialize} para utilização pelo container e pelos
 * módulos.
 *
 * @example <caption>Importando como plug-in</caption>
 * // main.js
 * import BaseModal from "./components/base/BaseModal";
 *
 * Vue.use(BaseModal);
 *
 * @example <caption>Importando diretamente em um componente</caption>
 * // componente.vue
 * // ou import BaseModal from "./components/base/BaseModal/BaseModal.vue";
 * import { BaseModal } from "./components/base/BaseModal";
 *
 * export default {
 *  name: // ...
 *  components: {
 *    BaseModal
 *  },
 *  // ...
 * }
 */
import { default as BaseModal } from "./BaseModal.vue";

/**
 * Associa o componente-base de modal à instância do {@link external:Vue Vue}.
 * @param {external:Vue} Vue - Instância do {@link external:Vue Vue}.
 */
const install = Vue => Vue.component(BaseModal.name, BaseModal);

if (window && window.Vue) {
  install(window.Vue);
}

export default install;

export { BaseModal };
