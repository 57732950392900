<template>
  <div class="row-fix row valign-wrapper">
    <div class="col s12 m3 image-container valign-wrapper">
      <img
        class="responsive-img"
        v-bind="thumbnail"
        v-on:error="showFallbackImage"
      />
    </div>
    <div class="col s12 m9 input-container">
      <h5>{{ value.nomeSocial ? value.nomeSocial : value.nome }}</h5>
      <p>{{ value.descricao }}</p>
    </div>
  </div>
</template>

<script>
import { NOPIC_IMG_SRC } from "../../constants";
import { ApresentadorService } from "../../services";

export default {
  name: "ApresentadorItem",
  inheritAttrs: false,
  props: {
    value: {
      type: Object,
      require: true
    }
  },
  computed: {
    thumbnail() {
      return {
        src: ApresentadorService.getFotoConteudoURL(this.value.id),
        alt: this.value.nome,
        title: this.value.nome
      };
    }
  },
  methods: {
    showFallbackImage(event) {
      const image = event.target;

      image.src = NOPIC_IMG_SRC;
      image.title = "Sem foto";
      image.alt = "Sem foto";
    }
  }
};
</script>

<style scoped>
.image-container {
  flex: 0 0 auto;
  justify-content: center;
}
.image-container img.responsive-img {
  max-height: 100%;
}
.input-container {
  flex: 0 0 auto;
}

@media screen and (max-width: 600px) {
  .row.valign-wrapper {
    flex-direction: column;
  }
}
</style>
