/**
 * Módulo principal do do sistema.
 * @module pae
 */

/**
 * Objeto que representa a eventual conclusão (ou falha) de uma operação assíncrona.
 * @external Promise
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise
 */

/**
 * Objeto que representa um momento em um formato independente de plataforma.
 * @external Date
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date
 */

/**
 * Objeto que representa a erros em tempo de execução.
 * @external Error
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error
 */

/**
 * Interface que representa a janela contendo um documento DOM.
 * @external Window
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Window
 */

/**
 * Interface base para os elementos de um documento DOM.
 * @external Node
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Node
 */

/**
 * Classe base para os elementos de um documento DOM.
 * @external Element
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Element
 */

/**
 * Interface que representa a localização (URL) de um objeto.
 * @external Location
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Location
 */

/**
 * Interface que representa um evento no DOM.
 * @external Event
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Event
 */

/**
 * Interface da Web Storage API que fornece acesso a um armazenamento local ou
 * de sessão para um domínio.
 * @external Storage
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Storage
 */

/**
 * Interface/API nativa para buscar recursos.
 * @external FetchAPI
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API
 */

/**
 * Interface da {@link external:FetchAPI FetchAPI} que representa a resposta a uma requisição.
 * @external Response
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Response
 */

/**
 * _Framework_ responsivo moderno para _front-end_ baseado no {@link https://material.io Material Design}.
 * @external Materialize
 * @see https://materializecss.github.io/materialize/
 */

/**
 * Coleção de ícones seguindo o padrão do {@link https://material.io Material Design}.
 * @external MaterialDesignIcons
 * @see https://material.io/resources/icons/
 */

/**
 * _Framework_ para construção de GUI em Javascript.
 * @external Vue
 * @see https://vuejs.org/
 */

/**
 * Gerenciador de estados para Vue.js.
 * @external Vuex
 * @see https://vuex.vuejs.org/
 */

/**
 * Classe responsável pela gerência de estados do {@link external:Vuex Vuex}.
 * @external Store
 * @see https://vuex.vuejs.org/api/#vuex-store
 */

/**
 * Gerenciador de rotas para Vue.js.
 * @external VueRouter
 * @see https://router.vuejs.org/
 */

/**
 * Interface para configurações de rotas do {@link external:VueRouter VueRouter}.
 * @external RouteConfig
 * @see https://router.vuejs.org/api/#router-construction-options
 */

/**
 * Representa o estado da rota ativa.
 * @external Route
 * @see https://router.vuejs.org/api/#the-route-object
 */

/**
 * Registro de rota do {@link external:VueRouter VueRouter}.
 * @external RouteRecord
 * @see https://router.vuejs.org/api/#route-object-properties
 */

/**
 * Componente do {@link external:VueRouter VueRouter} que habilita a navegação.
 * @external RouterLink
 * @see https://router.vuejs.org/api/#router-link
 */

/**
 * Cliente HTTP baseado em {@link external:Promise Promise}s.
 * @external axios
 * @see https://github.com/axios/axios
 */

/**
 * Software de gerenciamento de logs.
 * @external GrayLog
 * @see https://www.graylog.org/products/open-source
 */

/**
 * GrayLog Extended Log Format. Formato de log do {@link external:GrayLog GrayLog}.
 * @external GELF
 * @see https://www.graylog.org/features/gelf
 */

/**
 * O HelpInfoTIC provê uma maneira centralizada de manter o cadastro de itens
 * de ajuda para os diversos sistemas da InfoTIC.
 * @external HelpInfoTIC
 * @see https://help-infotic.tic.ufrj.br/
 */

import "@materializecss/materialize";
import "@materializecss/materialize/dist/css/materialize.css";

import {
  // APP_INFO,
  HOST_URL,
  ENABLE_ANALYTICS,
  ENABLE_LOG_SERVICE,
  UFRJ_ANALYTICS_SITE_ID,
  UFRJ_ANALYTICS_URL
} from "./constants";
import { buildQueryString, createScriptElement } from "./utils/web";

import Vue from "vue";
import store from "./store";
import router from "./router";

import App from "./App.vue";

import {
  AccessibilityService,
  AuthenticationService,
  LogService
} from "./services";

if (ENABLE_LOG_SERVICE) {
  LogService.start();
}

AccessibilityService.loadCSSStyleSheet();

AuthenticationService.registerStore(store).registerRouter(router);

import { Authorization, Http, Notification, Utils } from "./plugins";

Vue.use(Authorization, { store });
Vue.use(Http, { AuthenticationService });
Vue.use(Notification);
Vue.use(Utils);

import {
  ApresentadorService,
  AssuntoService,
  EscolaridadeService,
  EstandeService,
  EventoService,
  ModeloService,
  PessoaService,
  PermissaoService,
  //   SisPessoalService,
  StatusService,
  TipoLinkService,
  TipoModeloService,
  TipoSecaoService,
  UsuarioService
} from "./services";

ApresentadorService.registerHttp(Http);
AssuntoService.registerHttp(Http);
EscolaridadeService.registerHttp(Http);
EstandeService.registerHttp(Http);
EventoService.registerHttp(Http);
ModeloService.registerHttp(Http);
// SisPessoalService.registerHttp(Http);
StatusService.registerHttp(Http);
PessoaService.registerHttp(Http);
PermissaoService.registerHttp(Http);
TipoLinkService.registerHttp(Http);
TipoModeloService.registerHttp(Http);
TipoSecaoService.registerHttp(Http);
UsuarioService.registerHttp(Http);

import directives from "./directives";

Vue.use(directives);

import components from "./components/base";

Vue.use(components);

import { isEmpty } from "./utils/lang/index";

/**
 * Configuração de rota padrão para os casos em que o caminho não consegue
 * ser associado a nenhuma página ou módulo previamente carregado.
 * @type {external:RouteConfig}
 * @ignore
 */
const defaultRoute = {
  path: "*",
  name: "default",
  beforeEnter(to, from, next) {
    Notification.pushError("Caminho não encontrado");
    next({ name: "empty" });
  }
};

router.addRoutes([defaultRoute]);

router.beforeEach((to, from, next) => {
  // Se o usuário não tem permissão para acessar à rota, redireciona
  // para a página inicial.
  if (!Authorization.hasAnyPermission((to.meta || {}).permissions)) {
    Notification.pushError("Você não tem permissão para acessar essa tela");
    next({ name: "empty" });
    return;
  }

  if (AuthenticationService.isAuthenticated()) {
    next();
  } else {
    const ticket = to.query.ticket;
    const hasPrevAuthData =
      AuthenticationService.getAuthenticationData() &&
      AuthenticationService.isSessionValid();

    // O usuário está tentando acessar uma rota pública e que não exige
    // permissões e nem que ele esteja logado
    if (isEmpty(ticket) && !hasPrevAuthData) {
      next();
    } else {
      let auth = null;

      // Remove o parâmetro de querystring com o ticket recebido pelo CAS.
      delete to.query.ticket;

      if (hasPrevAuthData) {
        auth = AuthenticationService.refresh();
      } else {
        const queryString = buildQueryString(to.query);

        auth = AuthenticationService.authenticateByCAS(
          `${HOST_URL}${to.path}${queryString}`
        ).then(response => {
          const usuario = response.usuario;

          (usuario.permissoes || []).forEach(item =>
            Authorization.addPermission(item)
          );

          Promise.all([
            UsuarioService.get(usuario.id),
            PessoaService.get(usuario.pessoaId)
          ]).then(responses => {
            const usuario = responses[0].data;
            const pessoa = responses[1].data;

            delete usuario.pessoaId;

            usuario.pessoa = pessoa;

            store.commit("setUser", usuario);
          });
        });
      }

      auth
        .then(() => {
          // Redireciona para uma rota com as mesmas propriedades da original,
          // porém sem o ticket do CAS na querystring
          next({ ...to });
        })
        .catch(error => {
          // TODO: verificar se é necessário redirecionar para alguma página.
          Notification.pushError(error.message);

          next(false);
        });
    }
  }
});

router.afterEach((to, from) => {
  if (window._paq) {
    window._paq.push(["setReferrerUrl", `${HOST_URL}${from.fullPath}`]);
    window._paq.push(["setCustomUrl", to.fullPath]);
    window._paq.push(["setDocumentTitle", document.title]);

    const authData = AuthenticationService.getAuthenticationData();

    if (authData) {
      window._paq.push(["setUserId", authData.user.id.toString()]);
    }

    window._paq.push(["deleteCustomVariables", "page"]);
    window._paq.push(["setGenerationTimeMs", 0]);

    window._paq.push(["trackPageView"]);
    window._paq.push(["enableLinkTracking"]);
  }
});

// Erros gerados pelo gerenciador de rotas do Vue.js.
router.onError(error => {
  LogService.sendError((error || {}).message, (error || {}).stack, {
    _url: window.location.href
  });
  window.console.error(error);
});

// Erros gerados pelo framework do Vue.js.
Vue.config.errorHandler = (error, component, eventName) => {
  LogService.sendError((error || {}).message, (error || {}).stack, {
    _url: window.location.href,
    _component: component.$options._componentTag,
    _event_name: eventName
  });
  window.console.error(error);
};

// Erros de execução do javascript.
window.onerror = (message, source, lineno, colno, error) => {
  LogService.sendError((error || {}).message, (error || {}).stack, {
    _url: window.location.href
  });
  window.console.error(error);
  return true;
};

// Habilita o Matomo
if (ENABLE_ANALYTICS) {
  window._paq = window._paq || [];

  window._paq.push(["setTrackerUrl", `${UFRJ_ANALYTICS_URL}/matomo.php`]);
  window._paq.push(["setSiteId", UFRJ_ANALYTICS_SITE_ID]);

  const scriptProps = {
    id: `script-analytics-matomo`,
    src: `${UFRJ_ANALYTICS_URL}/matomo.js`,
    async: true,
    onload: () => {},
    onerror: () =>
      new Error(`Não foi possível obter do servidor o script do Matomo.`)
  };

  createScriptElement(scriptProps);
}

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#pae");
