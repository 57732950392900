<template>
  <section class="section">
    <component v-bind:is="tag" class="pae-title pae-header">
      <i v-if="secao.icone" class="material-icons left">{{ secao.icone }}</i
      >{{ secao.nome }}
    </component>

    <BaseInfoCard v-if="modeloSecao.instrucoes" title="Seção de links">
      <p>{{ modeloSecao.instrucoes }}</p>
    </BaseInfoCard>

    <BaseProgressBar v-if="loading" v-bind="progressBar" />
    <p v-else-if="!hasLinks" class="flow-text center">
      Nenhum link foi cadastrado.
    </p>
    <BaseEditableCollection v-else v-bind="form.linkCollection" />

    <div class="right-align">
      <BaseButton
        v-bind="form.buttonAdd"
        v-bind:disabled="readonly || disabled"
        style="margin-right: 20px"
        v-on:click="add"
      />
      <BaseButton
        v-bind="form.buttonSave"
        v-bind:disabled="readonly || disabled"
        v-on:click="save"
      />
    </div>
  </section>
</template>

<script>
import { isEmpty } from "../../../utils/lang";
import { isUrl } from "../../../utils/string";
import {
  EstandeService,
  EventoService,
  ModeloService,
  PermissaoService,
  TipoLinkService,
  TipoModeloService
} from "../../../services";
import EditableSectionLinkItem from "./EditableSectionLinkItem.vue";

export default {
  name: "EditableSectionLinks",
  inheritAttrs: false,
  props: {
    readonly: Boolean,

    tag: {
      type: String,
      default: "h3",
      validator: value =>
        ["h1", "h2", "h3", "h4", "h5", "h6"].indexOf(value) !== -1
    },
    entidadeId: {
      type: Number,
      required: true
    },
    modelo: {
      type: Object,
      required: true
    },
    secao: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      disabled: false,
      service: false,
      tiposLink: null,
      tiposLinkMap: null,
      modeloSecao: ModeloService.findModeloSecao(
        this.modelo,
        this.secao.modeloSecaoId
      ),
      progressBar: {
        useContainer: false
      },
      form: {
        linkCollection: {
          component: EditableSectionLinkItem,
          itemOperations: [
            {
              iconName: "delete",
              color: "red",
              disabled: false,
              title: "Excluir link",
              onClick: this.delete
            }
          ],
          isSortable: false,
          items: []
        },
        buttonAdd: {
          iconName: "add_link",
          content: "Novo",
          disabled: this.readonly
        },
        buttonSave: {
          iconName: "save",
          content: "Salvar",
          disabled: this.readonly
        }
      },
      linksOriginais: []
    };
  },
  computed: {
    hasLinks() {
      return !isEmpty(this.form.linkCollection.items);
    },
    isGestorEstandes() {
      return this.$authorization.hasPermission(PermissaoService.GESTAO_ESTANDE);
    },
    isGestorEventos() {
      return this.$authorization.hasPermission(PermissaoService.GESTAO_EVENTO);
    },
    isReadonly() {
      return this.modelo.tipoModeloId === TipoModeloService.EVENTO
        ? !this.isGestorEventos
        : !this.isGestorEstandes;
    }
  },
  watch: {
    readonly: {
      immediate: true,

      handler(newValue) {
        (this.form.linkCollection.items || []).forEach(
          item => (item.readonly = newValue)
        );
      }
    }
  },
  created() {
    if (this.modelo.tipoModeloId === TipoModeloService.EVENTO) {
      this.service = EventoService;
    } else if (this.modelo.tipoModeloId === TipoModeloService.ESTANDE) {
      this.service = EstandeService;
    } else {
      this.$notification.pushError("Tipo de modelo de página inválido");
      return;
    }

    this.getLinks();
  },
  methods: {
    getLinks() {
      if (!this.entidadeId) {
        return;
      }

      this.loading = true;

      this.tiposLink = null;

      this.form.linkCollection.items = [];

      const id = this.entidadeId;

      TipoLinkService.getAll()
        .then(response => {
          this.tiposLink = (response.data || []).map(item => ({
            ...item,
            id: item.id.toString()
          }));

          this.tiposLinkMap = {};

          this.tiposLink.forEach(item => (this.tiposLinkMap[item.id] = item));

          return this.service.getLinks(id);
        })
        .then(response => {
          const links = (response.data || []).map(item => ({
            ...item,
            tipoLinkId: item.tipoLinkId.toString()
          }));

          this.form.linkCollection.items = links.map(item => ({
            id: item.id,
            readonly: this.readonly,
            tiposLink: this.tiposLink,
            value: item
          }));

          this.linksOriginais = links.map(item =>
            this.$utils.Object.copy(item)
          );
        })
        .catch(error =>
          this.$notification.pushError(
            `Houve um erro ao obter os links: ${error.message}`
          )
        )
        .finally(() => (this.loading = false));
    },
    add() {
      if (!this.readonly) {
        this.form.linkCollection.items.push({
          id: null,
          readonly: this.readonly,
          tiposLink: this.tiposLink,
          value: {
            id: null,
            url: null,
            tipoLinkId: null
          }
        });
      }
    },
    delete(item, index) {
      if (!this.readonly && index >= 0) {
        this.form.linkCollection.items.splice(index, 1);
      }
    },
    getPostData() {
      const postData = [];
      const { items } = this.form.linkCollection;

      items.forEach(item => {
        if (!item.readonly && !item.value.id) {
          postData.push({
            ...item.value,
            tipoLinkId: window.parseInt(item.value.tipoLinkId)
          });
        }
      });

      return postData;
    },
    getPutData() {
      const putData = [];
      const { items } = this.form.linkCollection;

      items.forEach(item => {
        if (item.value.id) {
          const obj = this.linksOriginais.find(
            originalItem => originalItem.id === item.value.id
          );

          if (obj) {
            const objTipoLinkId = obj.tipoLinkId;
            const objUrl = obj.url;
            const itemTipoLinkId = item.value.tipoLinkId;
            const itemUrl = item.value.url;

            if (objTipoLinkId !== itemTipoLinkId || objUrl !== itemUrl) {
              putData.push({ ...item.value });
            }
          }
        }
      });

      return putData;
    },
    getDeleteData() {
      const deleteData = [];
      const { items } = this.form.linkCollection;

      this.linksOriginais.forEach(originalItem => {
        const isItemFound =
          items.findIndex(item => originalItem.id === item.value.id) >= 0;

        if (!isItemFound) {
          deleteData.push({ ...originalItem });
        }
      });

      return deleteData;
    },
    validate() {
      const errors = [];

      (this.form.linkCollection.items || []).forEach((item, index) => {
        const linkNo = index + 1;

        if (!isUrl(item.value.url)) {
          errors.push(new Error(`${linkNo}º link possui uma URL inválida`));
        }

        if (isEmpty(item.value.tipoLinkId)) {
          errors.push(new Error(`${linkNo}º link não teve o tipo informado`));
        }
      });

      return errors;
    },
    isUnique() {
      const obj = {};

      this.form.linkCollection.items.forEach(item => {
        const key = item.value.tipoLinkId;

        if (!obj[key]) {
          obj[key] = 0;
        }

        obj[key]++;
      });

      return Object.values(obj).findIndex(item => item > 1) === -1;
    },
    save() {
      if (this.readonly) {
        return;
      }

      const errors = this.validate();

      if (errors.length) {
        errors.forEach(item => this.$notification.pushError(item.message));
        return;
      }

      if (!this.isUnique()) {
        this.$notification.pushError("Alguns links possuem tipos repetidos");
        return;
      }

      const postData = this.getPostData();
      const putData = this.getPutData();
      const deleteData = this.getDeleteData();

      const linksOriginais = this.linksOriginais;
      const linkCollection = this.form.linkCollection.items;

      const id = this.entidadeId;

      const saveData = [].concat(
        postData.map(item =>
          this.service.createLink(id, item).then(response => {
            const link = response.data;

            // Atualiza os registros originais cujos correspondentes foram
            // criados com sucesso para que, em caso de erro, os mesmos não
            // sejam reenviados ao servidor desnecessariamente.
            if (link && link.id) {
              const l = { ...link, tipoLinkId: link.tipoLinkId.toString() };

              linksOriginais.push(l);

              for (let i = 0; i < linkCollection.length; i++) {
                const lc = linkCollection[i].value;

                if (!lc.id && lc.tipoLinkId === l.tipoLinkId) {
                  lc.id = l.id;
                  lc.url = l.url;
                  break;
                }
              }
            }

            this.$notification.pushSuccess(
              `Link de tipo "${
                this.tiposLinkMap[item.tipoLinkId].nome
              }" cadastrado com sucesso`
            );
          })
        ),
        putData.map(item =>
          this.service.updateLink(id, item.id, item).then(() => {
            // Atualiza os registros originais cujos correspondentes foram
            // atualizados com sucesso para que, em caso de erro, os mesmos
            // não sejam reenviados ao servidor desnecessariamente.
            const l = { ...item };

            for (let i = 0; i < linksOriginais.length; i++) {
              const original = linksOriginais[i];

              if (original.id === l.id) {
                original.url = l.url;
                original.tipoLinkId = l.tipoLinkId;
                break;
              }
            }

            this.$notification.pushSuccess(
              `Link de tipo "${
                this.tiposLinkMap[item.tipoLinkId].nome
              }" atualizado com sucesso`
            );
          })
        ),
        deleteData.map(item =>
          this.service.deleteLink(id, item.id).then(() => {
            const id = item.id;

            // Atualiza os registros originais cujos correspondentes foram
            // excluídos com sucesso para que, em caso de erro, os mesmos não
            // sejam reenviados ao servidor desnecessariamente.
            for (let i = 0; i < linksOriginais.length; i++) {
              if (linksOriginais[i].id === id) {
                linksOriginais.splice(i, 1);
                break;
              }
            }

            this.$notification.pushSuccess(
              `Link de tipo "${
                this.tiposLinkMap[item.tipoLinkId].nome
              }" excluído com sucesso`
            );
          })
        )
      );

      if (saveData.length) {
        this.disabled = true;

        Promise.all(saveData)
          .catch(error =>
            this.$notification.pushError(
              `Houve um erro ao salvar os links: ${error.message}`
            )
          )
          .finally(() => (this.disabled = false));
      }
    }
  }
};
</script>
