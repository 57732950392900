/**
 * @module components/base/BaseConfirmModal
 * @category Componentes-base
 * @summary Módulo de importação do componente-base de caixas de diálogo de
 * confirmação.
 *
 * @description
 * Expõe o componente-base de caixas de diálogo de confirmação para utilização
 * pelo container e pelos módulos.
 *
 * @example <caption>Importando como plug-in</caption>
 * // main.js
 * import BaseConfirmModal from "./components/base/BaseConfirmModal";
 *
 * Vue.use(BaseConfirmModal);
 *
 * @example <caption>Importando diretamente em um componente</caption>
 * // componente.vue
 * // ou import BaseConfirmModal from "./components/base/BaseConfirmModal/BaseConfirmModal.vue";
 * import { BaseConfirmModal } from "./components/base/BaseConfirmModal";
 *
 * export default {
 *  name: // ...
 *  components: {
 *    BaseConfirmModal
 *  },
 *  // ...
 * }
 */
import { default as BaseConfirmModal } from "./BaseConfirmModal.vue";

/**
 * Associa o componente-base de modal à instância do {@link external:Vue Vue}.
 * @param {external:Vue} Vue - Instância do {@link external:Vue Vue}.
 */
const install = Vue => Vue.component(BaseConfirmModal.name, BaseConfirmModal);

if (window && window.Vue) {
  install(window.Vue);
}

export default install;

export { BaseConfirmModal };
