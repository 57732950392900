/**
 * @module directives/Autofocus
 * @category Diretivas
 * @summary Módulo de importação da diretiva de _autofocus_.
 *
 * @description
 * Expõe a diretiva de _autofocus_ para utilização pelo container e pelos
 * módulos.
 *
 * Ao chamar o método `use` do objeto `Vue` global, torna-se possível
 * acessar a diretiva dentro de componentes através do atributo HTML
 * `v-autocomplete`.
 *
 * @example <caption>Importando a diretiva de autofocus como membro default do módulo</caption>
 * import Autofocus from "./directives/Autofocus";
 *
 * Vue.use(Autofocus);
 *
 * @example <caption>Importando a diretiva de autofocus como membro do módulo</caption>
 * import { Autofocus } from "./directives/Autofocus";
 *
 * Vue.use(Autofocus);
 */
import { default as Autofocus } from "./Autofocus";

/**
 * Associa esta diretiva à instância do {@link external:Vue Vue}, tornando-se
 * acessível pelos componentes através do atributo HTML `v-autofocus`.
 * @param {external:Vue} Vue - Instância do {@link external:Vue Vue}.
 * @param {object} options - Objeto com as opções desta diretiva.
 */
const install = function(Vue, options = {}) {
  Autofocus.options = Object.assign({}, options);

  Vue.directive("autofocus", Autofocus);
};

// Atribui a função install ao objeto da diretiva.
Autofocus.install = install;

export default install;

export { Autofocus };
