<template>
  <div class="row-fix row">
    <BaseFileInput
      v-if="!hasId"
      v-bind="form.fileInput"
      v-bind:disabled="readonly"
      v-on:input="onInput"
    />
    <div v-else class="col s12">
      <ArquivoItem v-bind:value="value" />
    </div>
  </div>
</template>

<script>
import ArquivoItem from "../ArquivoItem.vue";

export default {
  name: "EditableSectionArquivoItem",
  components: {
    ArquivoItem
  },
  inheritAttrs: false,
  props: {
    readonly: Boolean,

    value: Object
  },
  data() {
    return {
      form: {
        fileInput: {
          id: this.buildElementId(),
          required: true,
          validate: true,
          size: 1024 * 1024,
          label: "Arquivo",
          gridExpression: "col s12",
          value: null
        }
      }
    };
  },
  computed: {
    hasId() {
      return this.value && this.value.id;
    }
  },
  methods: {
    buildElementId() {
      if (this.hasId) {
        return `editablesection-file-${this.value.id}`;
      }

      return this.$utils.Object.uuid();
    },
    onInput(event) {
      let data = null;

      if (event) {
        const file = event[0];
        const fileReader = new FileReader();

        fileReader.onerror = () =>
          this.$notification.pushError(
            `Houve um erro ao carregar imagem: ${fileReader.error}`
          );

        fileReader.onload = event => {
          data = {
            nome: file.name,
            tamanho: file.size,
            conteudo: event.target.result,
            tipo: file.type
          };

          this.$emit("input", data);
        };

        fileReader.readAsDataURL(file);
      } else {
        this.$emit("input", data);
      }
    }
  }
};
</script>

<style scoped>
.row {
  margin-bottom: 0;
}
</style>
