<template>
  <section class="section">
    <component v-bind:is="tag" class="pae-title pae-header">
      <i v-if="secao.icone" class="material-icons left">{{ secao.icone }}</i
      >{{ secao.nome }}
    </component>

    <BaseProgressBar v-if="loading" v-bind="progressBar" />
    <p v-else-if="!hasLinks" class="flow-text center">
      Nenhum link foi cadastrado.
    </p>
    <div v-else class="links-wrapper">
      <a
        v-for="(item, index) in computedLinks"
        v-bind:key="index"
        v-bind:href="item.url"
        class="link-container"
        target="_blank"
      >
        <component v-bind:is="item.tag" v-bind="item.component">{{
          item.component.content
        }}</component>
      </a>
    </div>
  </section>
</template>

<script>
import { isEmpty } from "../../../utils/lang";
import {
  EventoService,
  EstandeService,
  TipoLinkService,
  TipoModeloService
} from "../../../services";

export default {
  name: "SectionLinks",
  inheritAttrs: false,
  props: {
    tag: {
      type: String,
      default: "h3",
      validator: value =>
        ["h1", "h2", "h3", "h4", "h5", "h6"].indexOf(value) !== -1
    },
    entidadeId: {
      type: Number,
      required: true
    },
    modelo: {
      type: Object,
      required: true
    },
    secao: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      progressBar: {
        useContainer: false
      },
      links: []
    };
  },
  computed: {
    hasLinks() {
      return !isEmpty(this.links);
    },
    computedLinks() {
      return this.links.map(item => {
        const link = { url: item.url, tag: "img" };

        let component = null;

        switch (item.tipoLinkId) {
          case TipoLinkService.INSTITUCIONAL:
            component = {
              class: "link-icons",
              src: TipoLinkService.IMG_INSTITUCIONAL,
              alt: "Minerva - Brasão da UFRJ",
              title: "Acessar página institucional no domínio da UFRJ"
            };
            break;
          case TipoLinkService.YOUTUBE:
            component = {
              class: "link-icons",
              src: TipoLinkService.IMG_YOUTUBE,
              alt: "Logo do YouTube",
              title: "Acessar canal no YouTube"
            };
            break;
          case TipoLinkService.FACEBOOK:
            component = {
              class: "link-icons",
              src: TipoLinkService.IMG_FACEBOOK,
              alt: "Logo do Facebook",
              title: "Acessar página no Facebook"
            };
            break;
          case TipoLinkService.INSTAGRAM:
            component = {
              class: "link-icons",
              src: TipoLinkService.IMG_INSTAGRAM,
              alt: "Logo do Instagram",
              title: "Acessar perfil no Instagram"
            };
            break;
          case TipoLinkService.TWITTER:
            component = {
              class: "link-icons",
              src: TipoLinkService.IMG_TWITTER,
              alt: "Logo do Twitter",
              title: "Acessar perfil no Twitter"
            };
            break;
          case TipoLinkService.TIKTOK:
            link.tag = "i";
            component = {
              class: "material-icons",
              title: "Acessar canal no TikTok",
              content: TipoLinkService.ICON_TIKTOK
            };
            break;
          case TipoLinkService.SPOTIFY:
            component = {
              class: "link-icons",
              src: TipoLinkService.IMG_SPOTIFY,
              alt: "Logo do Spotify",
              title: "Acessar perfil/playlist no Spotify"
            };
            break;
          case TipoLinkService.PODCAST:
            link.tag = "i";
            component = {
              class: "material-icons",
              title: "Acessar podcast",
              content: TipoLinkService.ICON_PODCAST
            };
            break;
          default:
            link.tag = "i";
            component = {
              class: "material-icons",
              title: "Acessar página própria",
              content: TipoLinkService.ICON_EXTERNO
            };
            break;
        }

        link.component = component;

        return link;
      });
    }
  },
  created() {
    this.getLinks();
  },
  methods: {
    getLinks() {
      if (!this.entidadeId) {
        return;
      }

      this.loading = true;

      this.links = [];

      const id = this.entidadeId;

      let promise = null;

      if (this.modelo.tipoModeloId === TipoModeloService.EVENTO) {
        promise = EventoService.getLinks(id);
      } else if (this.modelo.tipoModeloId === TipoModeloService.ESTANDE) {
        promise = EstandeService.getLinks(id);
      } else {
        this.$notification.pushError("Tipo de modelo de página inválido");
        return;
      }

      promise
        .then(response => (this.links = response.data))
        .catch(error =>
          this.$notification.pushError(
            `Houve um erro ao obter os links: ${error.message}`
          )
        )
        .finally(() => (this.loading = false));
    }
  }
};
</script>

<style scoped>
/* .links-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 20px;
} */
.link-container {
  margin: 0 12px;
  color: inherit;
}
.link-container:first-of-type {
  margin-left: 0;
}
.link-container:last-of-type {
  margin-right: 0;
}
.link-container img.link-icons {
  width: 48px;
}
.link-container i.material-icons {
  font-size: 48px;
}
</style>
