<template>
  <article>
    <header>
      <h1 class="thin center">Controle de Acesso</h1>
    </header>

    <div class="row">
      <div class="col s12 m9">
        <BaseScrollspy>
          <Permissoes v-bind="permissoes" class="scrollspy" />

          <Usuarios v-bind="usuarios" class="scrollspy" />
        </BaseScrollspy>
      </div>

      <div class="col s12 m3 hide-on-small-only">
        <BasePushpin v-bind:calc-top="true">
          <BaseTableOfContents class="section">
            <li>
              <a v-bind:href="permissoes.href">{{ permissoes.title }}</a>
            </li>
            <li>
              <a v-bind:href="usuarios.href">{{ usuarios.title }}</a>
            </li>
          </BaseTableOfContents>
        </BasePushpin>
      </div>
    </div>
  </article>
</template>

<script>
import Permissoes from "../components/ControleAcesso/Permissoes.vue";
import Usuarios from "../components/ControleAcesso/Usuarios.vue";

export default {
  name: "ControleAcesso",
  components: {
    Permissoes,
    Usuarios
  },
  inheritAttrs: false,
  computed: {
    permissoes() {
      const id = "permissoes";
      const href = `#${id}`;
      const title = "Permissões";
      const iconName = "assignment_turned_in";

      return {
        id,
        href,
        title,
        iconName
      };
    },
    usuarios() {
      const id = "usuarios";
      const href = `#${id}`;
      const title = "Usuários";
      const iconName = "supervised_user_circle";

      return {
        id,
        href,
        title,
        iconName
      };
    }
  }
};
</script>

<style scoped>
.row {
  margin-bottom: 0;
}
</style>
