<template>
  <article>
    <BaseProgressBar v-if="loading" v-bind="progressBar" class="progressbar" />

    <div v-if="!loading && principal">
      <SectionPrincipal v-bind="principal" />
    </div>

    <div v-if="!loading" class="sections-container container">
      <Section
        v-for="(item, index) in secoes"
        v-bind:key="index"
        v-bind="item"
      />
    </div>

    <div class="fixed-action-btn top-btn">
      <BaseButton
        class="btn-floating"
        v-bind="topButton"
        v-on:click="topButton.onClick"
      />
    </div>
    <div class="fixed-action-btn">
      <BaseButton
        class="btn-floating"
        v-bind="backButton"
        v-on:click="backButton.onClick"
      />
    </div>
  </article>
</template>

<script>
import {
  EventoService,
  EstandeService,
  ModeloService,
  TipoModeloService
} from "../services";
import SectionPrincipal from "../components/Common/Section/SectionPrincipal.vue";
import Section from "../components/Common/Section/Section.vue";

const TIPO_MODELO_ID = TipoModeloService.ESTANDE;

export default {
  name: "Estande",
  components: {
    SectionPrincipal,
    Section
  },
  inheritAttrs: false,
  data() {
    return {
      loading: false,
      principal: null,
      modelo: null,
      estande: null,
      evento: null,
      secoes: null,
      progressBar: {
        useContainer: false
      },
      topButton: {
        iconName: "arrow_upward",
        size: "large",
        title: "Ir ao topo da página",
        onClick: () => window.scroll(0, 0)
      },
      backButton: {
        iconName: "arrow_back",
        size: "large",
        title: "Voltar para o evento",
        onClick: () =>
          this.$router.push({
            name: "viewEvento",
            params: this.$route.params.slugEvento
          })
      }
    };
  },
  created() {
    // this.$store.commit("setExtraNavbar", [
    //   { id: "estande-sobre", title: "Sobre" },
    //   { id: "estande-links", title: "Links" },
    //   { id: "estande-galeria", title: "Galeria" },
    //   { id: "estande-programacao", title: "Programação" },
    //   { id: "estande-extras", title: "Extras" }
    // ]);

    this.getEstande();
  },
  beforeRouteLeave(to, from, next) {
    const { name } = to;

    if (name !== "viewEstande" && name !== "viewEvento") {
      EventoService.resetPageStyle();
    }

    next();
  },
  methods: {
    getEstande() {
      this.loading = true;

      this.principal = null;
      this.modelo = null;
      this.estande = null;
      this.evento = null;

      const slugEvento = this.$route.params.slugEvento;
      const slugEstande = this.$route.params.slugEstande;

      const tipoModeloId = TIPO_MODELO_ID;

      EventoService.get(slugEvento)
        .then(response => {
          this.evento = response.data;

          return EventoService.getEstilo(this.evento.id, {
            validateStatus: status =>
              this.$http.isSuccessfulStatus(status) ||
              status === this.$http.HTTP_STATUS_CLIENT_ERROR_NOT_FOUND
          });
        })
        .then(response => {
          this.evento.estilo = response.data;

          this.updatePageStyle();

          return EventoService.getEstande(this.evento.id, slugEstande);
        })
        .then(response => {
          this.estande = response.data;

          document.title += `- ${this.estande.nome}`;

          const id = this.estande.id;

          return Promise.all([
            ModeloService.get(this.estande.modeloId, { deep: true }),
            EstandeService.getSecoes(id),
            EstandeService.getBannerConteudoURL(id),
            EstandeService.getAssuntos(id)
          ]);
        })
        .then(response => {
          let i = 0;

          this.modelo = response[i++].data;

          this.secoes = [];

          (response[i++].data || []).forEach(item => {
            const entidadeId = this.estande.id;

            const secao = {
              entidadeId,
              modelo: this.modelo,
              value: { ...item }
            };

            this.secoes.push(secao);
          });

          this.estande.banner = response[i++];

          this.estande.assuntos = response[i++].data || [];

          this.principal = { tipoModeloId, value: this.estande };
        })
        .catch(error => {
          this.$notification.pushError(
            `Houve um erro ao obter os dados do estande: ${error.message}`
          );

          if (!this.evento || !this.estande) {
            this.$router.push({ name: "listEventos" });
          }
        })
        .finally(() => (this.loading = false));
    },
    updatePageStyle() {
      if (!this.evento) {
        return;
      }

      const { estilo } = this.evento;

      if (!estilo || !estilo.ativo) {
        EventoService.resetPageStyle();
      } else {
        EventoService.setPageStyle(estilo);
      }
    }
  }
};
</script>

<style scoped>
article {
  background-image: linear-gradient(#cecece, white);
  background-attachment: fixed;
}

.top-btn {
  bottom: 94px;
}

.progressbar /deep/ .progress {
  margin: 0;
  border-radius: 0;
}

.sections-container {
  background-color: white;
  padding: 0 0.75rem;
}
</style>
