<template>
  <div class="usuario-wrapper">
    <div class="usuario-info valign-wrapper">
      <span>
        <i class="material-icons left" v-bind:title="icon.title">{{
          icon.name
        }}</i>
        {{ login }}
      </span>

      <p v-if="bloqueado" class="yellow-text text-darken-3">
        Usuário ainda não acessou o sistema ou solicitou nova senha.
      </p>
    </div>

    <div v-if="hasAnyEvent" class="usuario-actions">
      <BaseButton
        v-if="hasEventAddEditor"
        v-bind="buttonAddEditor"
        v-on:click="addEditor"
      />
      <BaseButton
        v-if="hasEventEditPermissoes"
        v-bind="buttonEditPermissoes"
        v-on:click="editPermissoes"
      />
      <BaseButton
        v-if="hasEventResetSenha && mayResetSenha"
        v-bind="buttonResetSenha"
        v-on:click="resetSenha"
      />
    </div>
  </div>
</template>

<script>
import { UsuarioService } from "../../services";

export default {
  name: "BaseBuscaUsuarioItem",
  inheritAttrs: false,
  props: {
    readonly: Boolean,

    id: {
      type: Number,
      required: true
    },
    tipoUsuarioId: {
      type: Number,
      required: true
    },
    login: {
      type: String,
      required: true
    },

    bloqueado: Boolean
  },
  data() {
    return {
      buttonEditPermissoes: {
        iconName: "security",
        color: "yellow darken-2",
        title: "Editar permissões do usuário"
      },
      buttonResetSenha: {
        iconName: "key",
        color: "green",
        title: "Resetar senha do usuário"
      },
      buttonAddEditor: {
        iconName: "person_add",
        color: "blue",
        title: "Definir usuário como editor do estande"
      }
    };
  },
  computed: {
    icon() {
      switch (this.tipoUsuarioId) {
        case UsuarioService.SERVIDOR:
          return { name: "person", title: "Usuário servidor" };
        case UsuarioService.EXTERNO:
          return { name: "business", title: "Usuário externo" };
        default:
          return {
            name: "question_mark",
            title: "Tipo de usuário desconhecido"
          };
      }
    },
    mayResetSenha() {
      return this.tipoUsuarioId !== UsuarioService.SERVIDOR;
    },
    hasEventAddEditor() {
      return typeof this.$listeners["add-editor"] === "function";
    },
    hasEventEditPermissoes() {
      return typeof this.$listeners["edit-permissoes"] === "function";
    },
    hasEventResetSenha() {
      return typeof this.$listeners["reset-senha"] === "function";
    },
    hasAnyEvent() {
      return (
        !!this.$listeners.length ||
        this.hasEventAddEditor ||
        this.hasEventEditPermissoes ||
        this.hasEventResetSenha
      );
    }
  },
  methods: {
    addEditor() {
      this.$emit("add-editor", this.id);
    },
    editPermissoes() {
      this.$emit("edit-permissoes", this.id);
    },
    resetSenha() {
      if (!this.mayResetSenha) {
        return;
      }

      this.$emit("reset-senha", this.id);
    }
  }
};
</script>

<style scoped>
.usuario-wrapper {
  display: flex;
  justify-content: space-between;
}

.usuario-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-right: 10px;
}

.usuario-actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.usuario-actions .btn {
  margin: 4px 0;
}
.usuario-actions .btn:first-of-type {
  margin-top: 0;
}
.usuario-actions .btn:last-of-type {
  margin-bottom: 0;
}
</style>
