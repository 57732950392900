/**
 * @module services/TipoLink
 * @category Serviços
 * @summary Módulo de importação do serviço de tipos de _link_.
 *
 * @description
 * Expõe o serviço de tipos de _link_ do sistema para utilização interna.
 *
 * @example <caption>Importando o serviço de tipos de _link_ como membro default do módulo</caption>
 * import TipoLinkService from "./services/TipoLink";
 *
 * @example <caption>Importando o serviço de tipos de _link_ como membro do módulo</caption>
 * import { TipoLinkService } from "./services/TipoLink";
 */
import { default as TipoLinkService } from "./TipoLink";

export default TipoLinkService;

export { TipoLinkService };
