/**
 * @module services/SisPessoal
 * @category Serviços
 * @summary Módulo de importação do serviço de requisições ao SisPessoal.
 *
 * @description
 * Expõe o serviço de requisições ao SisPessoal do sistema para utilização
 * interna.
 *
 * @example <caption>Importando o serviço de requisições ao SisPessoal como membro default do módulo</caption>
 * import SisPessoalService from "./services/SisPessoal";
 *
 * @example <caption>Importando o serviço de requisições ao SisPessoal como membro do módulo</caption>
 * import { SisPessoalService } from "./services/SisPessoal";
 */
import { default as SisPessoalService } from "./SisPessoal";

export default SisPessoalService;

export { SisPessoalService };
