<template>
  <article class="container">
    <header>
      <h1 class="thin center">
        {{ isCreate ? "Criação" : "Edição" }} de evento
      </h1>
    </header>

    <BaseProgressBar v-if="loading" v-bind="progressBar" />

    <div v-if="!loading && principal">
      <EditableSectionPrincipal
        v-bind="principal"
        v-bind:readonly="isReadonly"
        v-on:statuschange="onStatusChange"
        v-on:save="onSave"
      />
    </div>

    <div v-if="mayShowSections">
      <EditableSection
        v-for="(item, index) in secoes"
        v-bind:key="index"
        v-bind="item"
        v-bind:readonly="isReadonly"
      />
    </div>

    <div v-if="mayShowSections" class="fixed-action-btn top-btn">
      <BaseButton
        class="btn-floating"
        v-bind="topButton"
        v-on:click="topButton.onClick"
      />
    </div>
  </article>
</template>

<script>
import { APP_INFO } from "../constants";
import { isEmpty } from "../utils/lang";
import {
  EventoService,
  ModeloService,
  PermissaoService,
  StatusService,
  TipoModeloService
} from "../services";
import EditableSectionPrincipal from "../components/Common/EditableSection/EditableSectionPrincipal.vue";
import EditableSection from "../components/Common/EditableSection/EditableSection.vue";

const TIPO_MODELO_ID = TipoModeloService.EVENTO;

export default {
  name: "GestaoEvento",
  components: {
    EditableSectionPrincipal,
    EditableSection
  },
  inheritAttrs: false,
  props: {
    modeloId: Number
  },
  data() {
    return {
      loading: false,
      principal: null,
      modelo: null,
      evento: null,
      secoes: null,
      progressBar: {
        useContainer: false
      },
      topButton: {
        iconName: "arrow_upward",
        size: "large",
        title: "Ir ao topo da página",
        onClick: () => window.scroll(0, 0)
      }
    };
  },
  computed: {
    isCreate() {
      return !this.$route.params.idEvento;
    },
    isStatusEditavel() {
      return this.evento && this.evento.statusId === StatusService.EM_EDICAO;
    },
    isGestorEventos() {
      return this.$authorization.hasPermission(PermissaoService.GESTAO_EVENTO);
    },
    isReadonly() {
      return (
        !this.isCreate && (!this.isGestorEventos || !this.isStatusEditavel)
      );
    },
    mayShowSections() {
      return !this.loading && this.evento && this.evento.id;
    }
  },
  beforeRouteUpdate(to, from, next) {
    const idEventoOld = from.params ? from.params.idEvento : null;
    const idEventoNew = to.params ? to.params.idEvento : null;

    // Prossegue a rota porque é uma criação de evento. A rota é atualizada
    // com o id do evento recém-criado.
    if (!idEventoOld && idEventoNew) {
      this.getEvento(to.params.idEvento);

      next();
    }
  },
  created() {
    const { idEvento } = this.$route.params;

    if (idEvento) {
      //   this.$store.commit("setExtraNavbar", [
      //     { id: "evento-sobre", title: "Sobre" },
      //     { id: "evento-links", title: "Links" },
      //     { id: "evento-galeria", title: "Galeria" },
      //     { id: "evento-estandes", title: "Estandes" }
      //   ]);

      this.getEvento(idEvento);
    } else {
      this.principal = { tipoModeloId: TIPO_MODELO_ID, value: null };
    }
  },
  methods: {
    getEvento(idEvento) {
      this.loading = true;

      this.principal = null;
      this.modelo = null;
      this.evento = null;

      const tipoModeloId = TIPO_MODELO_ID;

      EventoService.get(idEvento)
        .then(response => {
          this.evento = response.data;

          document.title = `${APP_INFO.name} - ${this.evento.nome}`;

          const id = this.evento.id;

          return Promise.all([
            ModeloService.get(this.evento.modeloId, { deep: true }),
            EventoService.getSecoes(id),
            EventoService.getEstilo(id, {
              validateStatus: status =>
                this.$http.isSuccessfulStatus(status) ||
                status === this.$http.HTTP_STATUS_CLIENT_ERROR_NOT_FOUND
            }),
            EventoService.getBanner(id, {
              validateStatus: status =>
                this.$http.isSuccessfulStatus(status) ||
                status === this.$http.HTTP_STATUS_CLIENT_ERROR_NOT_FOUND
            }),
            EventoService.getAssuntos(id),
            EventoService.getApoiadores(id, {
              validateStatus: status =>
                this.$http.isSuccessfulStatus(status) ||
                status === this.$http.HTTP_STATUS_CLIENT_ERROR_NOT_FOUND
            })
          ]);
        })
        .then(response => {
          let i = 0;

          this.modelo = response[i++].data;

          this.secoes = [];

          (response[i++].data || []).forEach(item => {
            const entidadeId = this.evento.id;

            const secao = {
              entidadeId,
              modelo: this.modelo,
              value: { ...item }
            };

            this.secoes.push(secao);
          });

          this.evento.estilo = response[i++].data;

          this.evento.banner = response[i++].data;

          this.evento.assuntos = (response[i++].data || []).map(
            item => item.id
          );

          this.evento.apoiadores = response[i++].data;

          this.principal = { tipoModeloId, value: this.evento };
        })
        .catch(error => {
          this.$notification.pushError(
            `Houve um erro ao obter os dados do evento: ${error.message}`
          );

          if (!this.evento) {
            this.$router.push({ name: "adminEventos" });
          }
        })
        .finally(() => (this.loading = false));
    },
    onStatusChange(event) {
      const id = this.evento.id;

      EventoService.setStatus(id, event)
        .then(() => {
          this.evento.statusId = event;

          this.$notification.pushSuccess(
            "Status do evento alterado com sucesso"
          );

          if (event === StatusService.PUBLICADO) {
            this.$router.push({ name: "adminEventos" });
          }
        })
        .catch(error =>
          this.$notification.pushError(
            `Houve um erro ao alterar o status do evento: ${error.message}`
          )
        );
    },
    onSave(event) {
      if (this.isReadonly) {
        return;
      }

      const errors = EventoService.validate(event);

      if (errors.length) {
        errors.forEach(item => this.$notification.pushError(item.message));
        return;
      }

      const modeloId = this.isCreate ? this.modeloId : this.modelo.id;

      const {
        nome,
        inicio,
        termino,
        continuo,
        online,
        banner,
        assuntos,
        apoiadores,
        estilo
      } = event;

      const data = { nome, inicio, termino, continuo, online, modeloId };

      if (this.isCreate) {
        this.createEvento(data);
      } else {
        this.updateEvento(data)
          .then(() => this.saveEstilo(estilo))
          .then(() => this.saveBanner(banner))
          .then(() => this.saveAssuntos(assuntos))
          .then(() => this.saveApoiadores(apoiadores));
      }
    },
    createEvento(evento) {
      this.evento = evento;
      this.evento.banner = null;
      this.evento.assuntos = [];

      EventoService.create(evento)
        .then(response => {
          this.evento.id = response.data.id;
          this.evento.slug = response.data.slug;

          this.$notification.pushSuccess("Evento criado com sucesso");

          this.$router.push({
            name: "editEvento",
            params: { idEvento: this.evento.id }
          });
        })
        .catch(error =>
          this.$notification.pushError(
            `Houve um erro ao criar evento: ${error.message}`
          )
        );
    },
    updateEvento(evento) {
      const id = this.evento.id;

      if (
        this.evento.nome !== evento.nome ||
        this.evento.inicio !== evento.inicio ||
        this.evento.termino !== evento.termino ||
        !!this.evento.continuo !== !!evento.continuo ||
        !!this.evento.online !== !!evento.online
      ) {
        return EventoService.update(id, evento)
          .then(() => {
            this.evento.nome = evento.nome;
            this.evento.inicio = evento.inicio;
            this.evento.termino = evento.termino;
            this.evento.continuo = evento.continuo;
            this.evento.online = evento.online;

            this.$notification.pushSuccess(
              "Dados do evento atualizados com sucesso"
            );
          })
          .catch(error =>
            this.$notification.pushError(
              `Houve um erro ao atualizar os dados do evento: ${error.message}`
            )
          );
      }

      return Promise.resolve();
    },
    saveEstilo(estilo) {
      const id = this.evento.id;
      const oldEstilo = this.evento.estilo;

      let promise = null;

      if (
        !oldEstilo ||
        !!oldEstilo.ativo !== !!estilo.ativo ||
        oldEstilo.cor !== estilo.cor
      ) {
        promise = EventoService.setEstilo(id, estilo);
      } else {
        return Promise.resolve();
      }

      promise
        .then(() => {
          this.evento.estilo = estilo;

          this.$notification.pushSuccess("Estilo atualizado com sucesso");
        })
        .catch(error =>
          this.$notification.pushError(
            `Houve um erro ao atualizar o estilo: ${error.message}`
          )
        );
    },
    saveBanner(banner) {
      const id = this.evento.id;
      const oldBanner = this.evento.banner;

      let promise = null;

      if (oldBanner && !banner) {
        promise = EventoService.deleteBanner(id);
      } else if (!oldBanner && !banner) {
        return Promise.resolve();
      } else if (!oldBanner && banner) {
        promise = EventoService.setBanner(id, banner);
      } else if (
        oldBanner.tipo !== banner.tipo ||
        oldBanner.nome !== banner.nome ||
        oldBanner.tamanho !== banner.tamanho ||
        oldBanner.conteudo !== banner.conteudo
      ) {
        promise = EventoService.setBanner(id, banner);
      } else {
        return Promise.resolve();
      }

      return promise
        .then(() => {
          this.evento.banner = banner;

          this.$notification.pushSuccess("Banner atualizado com sucesso");
        })
        .catch(error =>
          this.$notification.pushError(
            `Houve um erro ao atualizar o banner: ${error.message}`
          )
        );
    },
    saveAssuntos(assuntos) {
      const id = this.evento.id;
      const oldAssuntos = this.evento.assuntos;

      let putAssuntos = [];
      let deleteAssuntos = [];

      if (isEmpty(assuntos) && isEmpty(oldAssuntos)) {
        return Promise.resolve();
      } else if (isEmpty(assuntos)) {
        deleteAssuntos = oldAssuntos;
      } else if (isEmpty(oldAssuntos)) {
        putAssuntos = assuntos;
      } else {
        assuntos
          .filter(item => oldAssuntos.findIndex(a => a === item) === -1)
          .forEach(item => putAssuntos.push(item));

        oldAssuntos
          .filter(item => assuntos.findIndex(a => a === item) === -1)
          .forEach(item => deleteAssuntos.push(item));
      }

      return Promise.allSettled(
        putAssuntos.map(item => EventoService.addAssunto(id, item))
      ).then(response => {
        if (response.length) {
          if (response.findIndex(item => item.reason) === -1) {
            this.$notification.pushSuccess("Assuntos associados com sucesso");
          } else {
            this.$notification.pushError(
              "Alguns assuntos não foram associados"
            );
          }

          response.forEach((item, index) => {
            if (!item.reason) {
              oldAssuntos.push(putAssuntos[index]);
            }
          });
        }

        return Promise.allSettled(
          deleteAssuntos.map(item => EventoService.removeAssunto(id, item))
        ).then(response => {
          if (response.length) {
            if (response.findIndex(item => item.reason) === -1) {
              this.$notification.pushSuccess(
                "Assuntos desassociados com sucesso"
              );
            } else {
              this.$notification.pushError(
                "Alguns assuntos não foram desassociados"
              );
            }

            response.forEach((item, index) => {
              if (!item.reason) {
                oldAssuntos.splice(
                  oldAssuntos.indexOf(deleteAssuntos[index]),
                  1
                );
              }
            });
          }
        });
      });
    },
    saveApoiadores(apoiadores) {
      const id = this.evento.id;
      const oldApoiadores = this.evento.apoiadores;

      let promise = null;

      if (oldApoiadores && !apoiadores) {
        promise = EventoService.deleteApoiadores(id);
      } else if (!oldApoiadores && !apoiadores) {
        return Promise.resolve();
      } else {
        // Realiza-se apenas uma validação básica
        if (!apoiadores.banner) {
          promise = Promise.reject(
            new Error("Arquivo para banner de apoiadores não informado")
          );
        } else if (!oldApoiadores) {
          promise = EventoService.setApoiadores(id, apoiadores);
        } else {
          const oldDescricao = oldApoiadores.descricao;
          const oldBanner = oldApoiadores.banner;
          const { descricao, banner } = apoiadores;

          if (
            !oldBanner ||
            oldBanner.tipo !== banner.tipo ||
            oldBanner.nome !== banner.nome ||
            oldBanner.tamanho !== banner.tamanho ||
            oldBanner.conteudo !== banner.conteudo
          ) {
            promise = EventoService.setApoiadores(id, apoiadores);
          } else if (oldDescricao !== descricao) {
            promise = EventoService.setApoiadores(id, { descricao });
          } else {
            return Promise.resolve();
          }
        }
      }

      return promise
        .then(() => {
          this.evento.apoiadores = apoiadores;

          this.$notification.pushSuccess(
            "Banner de apoiadores atualizado com sucesso"
          );
        })
        .catch(error =>
          this.$notification.pushError(
            `Houve um erro ao atualizar o banner de apoiadores: ${error.message}`
          )
        );
    }
  }
};
</script>
