/**
 * @module components/base/BaseEditableCollection
 * @category Componentes-base
 * @summary Módulo de importação do componente-base de coleções editáveis.
 *
 * @description
 * Expõe o componente-base de coleções editáveis para utilização pelo container e
 * pelos módulos.
 *
 * @example <caption>Importando como plug-in</caption>
 * // main.js
 * import BaseEditableCollection from "./components/base/BaseEditableCollection";
 *
 * Vue.use(BaseEditableCollection);
 *
 * @example <caption>Importando diretamente em um componente</caption>
 * // componente.vue
 * // ou import BaseEditableCollection from "./components/base/BaseEditableCollection/BaseEditableCollection.vue";
 * import { BaseEditableCollection } from "./components/base/BaseEditableCollection";
 *
 * export default {
 *  name: // ...
 *  components: {
 *    BaseEditableCollection
 *  },
 *  // ...
 * }
 */
import { default as BaseEditableCollection } from "./BaseEditableCollection.vue";

/**
 * Associa o componente-base de input à instância do {@link external:Vue Vue}.
 * @param {external:Vue} Vue - Instância do {@link external:Vue Vue}.
 */
const install = Vue =>
  Vue.component(BaseEditableCollection.name, BaseEditableCollection);

if (window && window.Vue) {
  install(window.Vue);
}

export default install;

export { BaseEditableCollection };
