<template>
  <div class="row-fix row">
    <BaseTextInput
      v-bind="form.url"
      v-bind:disabled="readonly"
      v-on:input="onInput('url', $event)"
    />

    <BaseSelect
      v-bind="form.tipo"
      v-bind:disabled="readonly"
      v-on:input="onInput('tipoLinkId', $event)"
    />
  </div>
</template>

<script>
export default {
  name: "EditableSectionLinkItem",
  inheritAttrs: false,
  props: {
    readonly: Boolean,

    tiposLink: {
      type: Array,
      required: true,
      default: () => []
    },

    value: Object
  },
  data() {
    return {
      form: {
        url: {
          id: this.buildElementId(this.value.id, "url"),
          disabled: this.readonly,
          required: true,
          label: "Endereço (URL)",
          gridExpression: "col s12 m6",
          value: this.value.url
        },
        tipo: {
          id: this.buildElementId(this.value.id, "tipo"),
          disabled: this.readonly,
          required: true,
          label: "Tipo",
          gridExpression: "col s12 m6",
          optionElements: (this.tiposLink || []).map(item =>
            this.$utils.Components.toOptionElement(item, "id", "nome")
          ),
          value: this.value.tipoLinkId
        }
      },
      tiposLinkMap: this.buildTiposMap()
    };
  },
  watch: {
    value: {
      immediate: true,

      handler(newValue) {
        const { url, tipo } = this.form;

        if (newValue) {
          url.value = newValue.url;
          tipo.value = this.tiposLinkMap[newValue.tipoLinkId]
            ? newValue.tipoLinkId
            : null;
        }
      }
    }
  },
  methods: {
    buildTiposMap() {
      const obj = {};

      (this.tiposLink || []).forEach(item => (obj[item.id] = item));

      return obj;
    },
    buildElementId(itemId, name) {
      return itemId
        ? `editablesection-link-${itemId}-${name}`
        : this.$utils.Object.uuid();
    },
    onInput(propertyName, event) {
      this.$emit("input", { ...this.value, [propertyName]: event });
    }
  }
};
</script>

<style scoped>
.row {
  margin-bottom: 0;
}
</style>
