/**
 * @module components/base/BaseRouterLink
 * @category Componentes-base
 * @summary Módulo de importação do componente-base que encapsula um `RouterLink` do {@link external:VueRouter VueRouter}.
 *
 * @description
 * Expõe o componente-base que encapsula um `RouterLink` do {@link external:VueRouter VueRouter} para utilização pelo container e pelos
 * módulos.
 *
 * @example <caption>Importando como plug-in</caption>
 * // main.js
 * import BaseRouterLink from "./components/base/BaseRouterLink";
 *
 * Vue.use(BaseRouterLink);
 *
 * @example <caption>Importando diretamente em um componente</caption>
 * // componente.vue
 * // ou import BaseRouterLink from "./components/base/BaseRouterLink/BaseRouterLink.vue";
 * import { BaseRouterLink } from "./components/base/BaseRouterLink";
 *
 * export default {
 *  name: // ...
 *  components: {
 *    BaseRouterLink
 *  },
 *  // ...
 * }
 */
import { default as BaseRouterLink } from "./BaseRouterLink.vue";

/**
 * Associa o componente-base que encapsula um `RouterLink` à instância do {@link external:Vue Vue}.
 * @param {external:Vue} Vue - Instância do {@link external:Vue Vue}.
 */
const install = Vue => Vue.component(BaseRouterLink.name, BaseRouterLink);

if (window && window.Vue) {
  install(window.Vue);
}

export default install;

export { BaseRouterLink };
