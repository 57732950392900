<template>
  <tr>
    <slot>
      <BaseTableCell
        v-for="(item, index) in cells"
        v-bind:key="index"
        v-bind="item"
      />
    </slot>
  </tr>
</template>

<script>
/**
 * @module components/base/BaseTableRow/BaseTableRow
 * @category Componentes-base
 * @summary _Single File Component_ (SFC) de linha de tabela do {@link external:Materialize Materialize}.
 *
 * @description
 * O _slot_ _default_ recebe as células da linha da tabela.
 *
 * @vue-prop {Array<object>} [cells=[]] - Colunas da linha da tabela. Aceita as
 * `props` do componente-base {@link module:components/base/BaseTableCell/BaseTableCell BaseTableCell}.
 *
 * @example
 * <BaseTableRow
 *   v-bind:columns="[{content: 'Bomba' }, { content: 'R$ 8,00'}]"
 * />
 */
import BaseTableCell from "../BaseTableCell/BaseTableCell.vue";

export default {
  name: "BaseTableRow",
  components: {
    BaseTableCell
  },
  inheritAttrs: false,
  props: {
    cells: {
      type: Array,
      default: () => []
    }
  }
};
</script>
