<template>
  <div class="row-fix row valign-wrapper">
    <div class="col s12 m3 image-container valign-wrapper">
      <img class="responsive-img" v-bind="thumbnail" />
    </div>
    <div class="col s12 m9 input-container">
      <div class="row">
        <BaseTextInput
          v-bind="form.nome"
          v-bind:disabled="readonly"
          v-on:input="onInput('nome', $event)"
        />

        <BaseTextarea
          v-bind="form.descricao"
          v-bind:disabled="readonly"
          v-on:input="onInput('descricao', $event)"
        />

        <BaseFileInput
          v-bind="form.imagem"
          v-bind:disabled="readonly"
          v-on:input="onInput('imagem', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { NOPIC_IMG_SRC } from "../../../constants";

export default {
  name: "EditableSectionGaleriaItem",
  inheritAttrs: false,
  props: {
    readonly: Boolean,

    value: Object
  },
  data() {
    return {
      form: {
        nome: {
          id: this.buildElementId(this.value.id, "nome"),
          disabled: this.readonly,
          required: true,
          label: "Nome",
          value: this.value.nome
        },
        descricao: {
          id: this.buildElementId(this.value.id, "descricao"),
          disabled: this.readonly,
          required: true,
          label: "Descrição",
          value: this.value.descricao
        },
        imagem: {
          id: this.buildElementId(this.value.id, "imagem"),
          disabled: this.readonly,
          placeholder: "Selecione uma imagem para a galeria",
          multiple: false,
          validate: true,
          accept: "image/*",
          size: 1024 * 1024,
          buttonContent: "Imagem",
          value: null
        }
      },
      imagemTmp: null,
      thumbnail: {
        src: null,
        alt: null,
        title: null
      }
    };
  },
  watch: {
    value: {
      deep: true,
      immediate: true,

      handler(newValue) {
        const { nome, descricao } = this.form;

        if (newValue) {
          nome.value = newValue.nome;
          descricao.value = newValue.descricao;

          if (newValue.imagem) {
            this.thumbnail.src = newValue.imagem.conteudo;
            this.thumbnail.alt = nome.value;
            this.thumbnail.title = nome.value;
            this.imagemTmp = newValue.imagem;
          } else {
            this.thumbnail.src = NOPIC_IMG_SRC;
            this.thumbnail.alt = "Sem foto";
            this.thumbnail.title = "Sem foto";
            this.imagemTmp = null;
          }
        }
      }
    }
  },
  methods: {
    buildElementId(itemId, name) {
      return itemId
        ? `editablesection-galeria-${itemId}-${name}`
        : this.$utils.Object.uuid();
    },
    onInput(propertyName, event) {
      let data = event;

      if (propertyName === "imagem") {
        if (event) {
          const { nome } = this.form;
          const file = event[0];
          const fileReader = new FileReader();

          fileReader.onerror = () => {
            // Utilizo a imagem atual em caso de erro
            data = this.imagemTmp;

            this.$notification.pushError(
              `Houve um erro ao carregar imagem: ${fileReader.error}`
            );
          };

          fileReader.onload = event => {
            this.thumbnail.src = event.target.result;
            this.thumbnail.alt = nome.value;
            this.thumbnail.title = nome.value;
            this.imagemTmp = {
              nome: file.name,
              tamanho: file.size,
              conteudo: event.target.result,
              tipo: file.type
            };
            data = this.imagemTmp;

            this.$emit("input", { ...this.value, [propertyName]: data });
          };

          fileReader.readAsDataURL(file);
        } else {
          this.thumbnail.src = NOPIC_IMG_SRC;
          this.thumbnail.alt = "Sem foto";
          this.thumbnail.title = "Sem foto";
          this.imagemTmp = null;
          data = this.imagemTmp;

          this.$emit("input", { ...this.value, [propertyName]: data });
        }
      } else {
        this.$emit("input", { ...this.value, [propertyName]: data });
      }
    }
  }
};
</script>

<style scoped>
.row {
  margin-bottom: 0;
}

.image-container {
  flex: 0 0 auto;
}
.image-container img.responsive-img {
  max-height: 100%;
}
.input-container {
  flex: 0 0 auto;
}

@media screen and (max-width: 600px) {
  .row.valign-wrapper {
    flex-direction: column;
  }
}
</style>
