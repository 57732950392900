<template>
  <section v-bind:id="id" class="section">
    <h4>
      <i class="material-icons left">{{ iconName }}</i>
      {{ title }}
    </h4>

    <BaseProgressBar v-if="loading" v-bind="progressBar"
      >Carregando permissões...</BaseProgressBar
    >
    <p v-else-if="!hasPermissoes" class="flow-text center-align">
      Ainda não há permissões cadastradas.
    </p>
    <BaseCollection v-else v-bind="permissaoCollection" />
  </section>
</template>

<script>
import { PermissaoService } from "../../services";

export default {
  name: "Permissoes",
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      required: true
    },

    title: String,
    iconName: String
  },
  data() {
    return {
      loading: false,
      permissaoCollection: {
        items: null
      },
      progressBar: {
        useContainer: false
      }
    };
  },
  computed: {
    hasPermissoes() {
      return (this.permissaoCollection.items || []).length !== 0;
    }
  },
  created() {
    this.getPermissoes();
  },
  methods: {
    getPermissoes() {
      this.loading = true;

      this.permissaoCollection.items = null;

      PermissaoService.getAll()
        .then(response => {
          this.permissaoCollection.items = (response.data || []).map(item => ({
            ...item,
            title: item.nome
          }));
        })
        .catch(error =>
          this.$notification.pushError(
            `Houve um erro ao obter a lista de permissões: ${error.message}`
          )
        )
        .finally(() => (this.loading = false));
    }
  }
};
</script>
