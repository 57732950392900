<template>
  <section class="section">
    <component v-bind:is="tag" class="pae-title pae-header">
      <i v-if="secao.icone" class="material-icons left">{{ secao.icone }}</i
      >{{ secao.nome }}
    </component>

    <BaseProgressBar v-if="loading" v-bind="progressBar" />
    <p v-else-if="!hasConteudos" class="flow-text center">
      Nenhuma programação foi definida.
    </p>
    <div v-else>
      <BaseTabs v-bind="tabs" v-bind:tabs="conteudosTabs" />
      <div
        v-for="(item, index) in computedConteudos"
        v-bind:key="index"
        v-bind:id="item.id"
      >
        <BaseEditableCollection
          v-bind="conteudoCollection"
          v-bind:items="item.conteudos"
        />
      </div>
    </div>

    <SectionConteudoProgramadoModal v-bind="conteudoModal" />
  </section>
</template>

<script>
import { DEFAULT_COLOR, DEFAULT_LUMINOSITY } from "../../../constants";
import { isEmpty } from "../../../utils/lang";
import { toISODate, toBrazilianDate } from "../../../utils/datetime";
import { EstandeService } from "../../../services";
import SectionConteudoItem from "./SectionConteudoItem.vue";
import SectionConteudoProgramadoModal from "./SectionConteudoProgramadoModal.vue";

export default {
  name: "SectionConteudosProgramados",
  components: {
    SectionConteudoProgramadoModal
  },
  inheritAttrs: false,
  props: {
    tag: {
      type: String,
      default: "h3",
      validator: value =>
        ["h1", "h2", "h3", "h4", "h5", "h6"].indexOf(value) !== -1
    },
    entidadeId: {
      type: Number,
      required: true
    },
    modelo: {
      type: Object,
      required: true
    },
    secao: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      conteudoModal: {
        id: "pae-conteudoprogramado-modal",
        entidadeId: this.entidadeId,
        value: null
      },
      tabs: {
        hasFixedWidth: true,
        zDepth: 0
      },
      progressBar: {
        useContainer: false
      },
      conteudoCollection: {
        component: SectionConteudoItem,
        items: null,
        itemOperations: [
          {
            iconName: "search",
            color: DEFAULT_COLOR,
            luminosity: DEFAULT_LUMINOSITY,
            onClick: this.openConteudoProgramadoModal,
            title: "Ver apresentação"
          }
        ]
      },
      conteudos: []
    };
  },
  computed: {
    hasConteudos() {
      return !isEmpty(this.conteudos);
    },
    conteudosTabs() {
      return (this.computedConteudos || []).map(item => ({
        href: item.id,
        title: `Programação do dia ${item.data}`,
        content: item.data
      }));
    },
    computedConteudos() {
      const conteudos = [];

      let conteudosItem = null;

      (this.conteudos || [])
        .sort((a, b) => (a.inicio < b.inicio ? -1 : 1))
        .forEach(item => {
          const date = toISODate(item.inicio);

          if (!conteudosItem || toISODate(conteudosItem.data) !== date) {
            conteudosItem = {
              id: `conteudoprogramado-data-${date}`,
              data: toBrazilianDate(date),
              conteudos: []
            };

            conteudos.push(conteudosItem);
          }

          conteudosItem.conteudos.push({
            value: { ...item }
          });
        });

      return conteudos;
    }
  },
  created() {
    this.getConteudosProgramados();
  },
  methods: {
    getConteudosProgramados() {
      if (!this.entidadeId) {
        return;
      }

      const id = this.entidadeId;

      this.loading = true;

      this.conteudos = [];

      EstandeService.getConteudosProgramados(id)
        .then(response => (this.conteudos = response.data))
        .catch(error =>
          this.$notification.pushError(
            `Houve um erro ao obter os conteúdos programados: ${error.message}`
          )
        )
        .finally(() => (this.loading = false));
    },
    openConteudoProgramadoModal(item, index) {
      if (index >= 0) {
        const conteudoId = window.parseInt(item.id.split("-")[1]);
        const conteudo = this.conteudos.find(item => item.id === conteudoId);

        if (conteudo) {
          this.conteudoModal.value = conteudo.id;
        }
      }

      this.$nextTick().then(() =>
        this.$utils.Components.openModal(this.conteudoModal.id)
      );
    }
  }
};
</script>
