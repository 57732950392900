/**
 * @module components/base/BaseTableCell
 * @category Componentes-base
 * @summary Módulo de importação do componente-base de célula de tabela do
 * {@link external:Materialize Materialize}.
 *
 * @description
 * Expõe o componente-base de célula de tabela do {@link external:Materialize Materialize} para utilização
 * pelo container e pelos módulos.
 *
 * @example <caption>Importando como plug-in</caption>
 * // main.js
 * import BaseTableCell from "./components/base/BaseTableCell";
 *
 * Vue.use(BaseTableCell);
 *
 * @example <caption>Importando diretamente em um componente</caption>
 * // componente.vue
 * // ou import BaseTableCell from "./components/base/BaseTableCell/BaseTableCell.vue";
 * import { BaseTableCell } from "./components/base/BaseTableCell";
 *
 * export default {
 *  name: // ...
 *  components: {
 *    BaseTableCell
 *  },
 *  // ...
 * }
 */
import { default as BaseTableCell } from "./BaseTableCell.vue";

/**
 * Associa o componente-base de célula de tabela à instância do {@link external:Vue Vue}.
 * @param {external:Vue} Vue - Instância do {@link external:Vue Vue}.
 */
const install = Vue => Vue.component(BaseTableCell.name, BaseTableCell);

if (window && window.Vue) {
  install(window.Vue);
}

export default install;

export { BaseTableCell };
