/**
 * @module services/Assunto
 * @category Serviços
 * @summary Módulo de importação do serviço de assuntos.
 *
 * @description
 * Expõe o serviço de assuntos do sistema para utilização interna.
 *
 * @example <caption>Importando o serviço de assuntos como membro default do módulo</caption>
 * import AssuntoService from "./services/Assunto";
 *
 * @example <caption>Importando o serviço de assuntos como membro do módulo</caption>
 * import { AssuntoService } from "./services/Assunto";
 */
import { default as AssuntoService } from "./Assunto";

export default AssuntoService;

export { AssuntoService };
