/**
 * @module utils/lang
 * @category Utilidades
 * @summary Módulo de utilidades para a linguagem javascript.
 *
 * @description
 * Expõe funções de utilidades para a linguagem javascript.
 */

/**
 * Indica se um dado é do tipo "undefined".
 * @function
 * @param {any} val
 * @returns {boolean}.
 */
export const isUndefined = function(val) {
  return typeof val === "undefined";
};

/**
 * Indica se um dado é nulo.
 * @function
 * @param {any} val
 * @returns {boolean}.
 */
export const isNull = function(val) {
  return val === null;
};

/**
 * Indica se um dado é nulo ou tem tipo "undefined".
 * @function
 * @param {any} val
 * @returns {boolean}.
 */
export const isNullOrUndefined = function(val) {
  return val === null || isUndefined(val);
};

/**
 * Indica se um dado é do tipo "boolean".
 * @function
 * @param {any} val
 * @returns {boolean}.
 */
export const isBoolean = function(val) {
  return typeof val === "boolean";
};

/**
 * Indica se um dado é do tipo "number".
 * @function
 * @param {any} val
 * @returns {boolean}.
 */
export const isNumber = function(val) {
  return typeof val === "number";
};

/**
 * Indica se um dado é do tipo "string".
 * @function
 * @param {any} val
 * @returns {boolean}.
 */
export const isString = function(val) {
  return typeof val === "string";
};

/**
 * Indica se um dado é do tipo "symbol".
 * @function
 * @param {any} val
 * @returns {boolean}.
 */
export const isSymbol = function(val) {
  return typeof val === "symbol";
};

/**
 * Indica se um dado é do tipo "object". Via operador `typeof`, _arrays_ são
 * considerados objetos. Entretanto, este método não faz essa consideração.
 * @function
 * @param {any} val
 * @returns {boolean}.
 */
export const isObject = function(val) {
  return !isArray(val) && typeof val === "object";
};

/**
 * Indica se um dado é do tipo "function".
 * @function
 * @param {any} val
 * @returns {boolean}.
 */
export const isFunction = function(val) {
  return typeof val === "function";
};

/**
 * Indica se um dado é do tipo _array_.
 * @function
 * @param {any} val
 * @returns {boolean}.
 */
export const isArray = function(val) {
  return Array.isArray(val);
};

/**
 * Indica se um dado está vazio (`null`, `undefined`, `0`, `false`, `""`, `{}`, `[]`).
 * @function
 * @param {any} val.
 * @returns {boolean}.
 */
export const isEmpty = function(val) {
  if (isArray(val)) {
    return val.length === 0;
  } else if (isNull(val)) {
    return true;
  } else if (isObject(val)) {
    return isEmpty(Object.keys(val));
  } else {
    return !val;
  }
};

export default {
  isArray,
  isBoolean,
  isEmpty,
  isFunction,
  isNull,
  isNullOrUndefined,
  isNumber,
  isObject,
  isString,
  isSymbol,
  isUndefined
};
