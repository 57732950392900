/**
 * @module plugins/Authorization/Authorization
 * @category Plug-ins
 * @summary Módulo do _plug-in_ de permissões.
 *
 * @description
 * Expõe o objeto/_namespace_ do _plug-in_ de permissões para utilização pelo
 * container e pelos módulos.
 *
 * Para correto funcionamento deste _plug-in_, é necessário que seja passado o
 * gerenciador de estados ({@link external:Store Store}) utilizado pelo sistema.
 *
 * @requires module:utils/lang.isNullOrUndefined
 * @requires module:utils/lang.isNumber
 * @requires module:utils/lang.isString
 *
 * @example
 * // main.js
 * import store from "./store";
 * import Authorization from "./plugins/Authorization";
 *
 * Vue.use(Authorization, { store });
 *
 * // componente.vue
 * export default {
 *  // ...
 *  data: {
 *    return {
 *      isAdmin: this.$authorization.isAdmin()
 *    }
 *  }
 * }
 */

import { isNullOrUndefined, isNumber, isString } from "../../utils/lang";

/**
 * Constante que caracteriza uma permissão/_role_ de administrador do sistema.
 * @type {string}
 * @constant
 * @default
 * @ignore
 */
const ADMIN = "ADMIN";

/**
 * Informa se o gerenciador de estados do sistema possui uma permissão/_role_
 * específica.
 * @param {external:Store} - Gerenciador de estados do sistema.
 * @param {string|number} permission - Nome ou `id` da permissão/_role_ a ser
 * verificada.
 * @returns {boolean}.
 * @ignore
 */
const hasPermissionInStore = function(store, permission) {
  if (isNullOrUndefined(permission)) {
    // Considera-se garantido o acesso caso a permissão esteja "vazia"
    return true;
  }

  const permissions = store.state.permissions || {};

  if (isNumber(permission)) {
    return (
      Object.values(permissions).findIndex(item => item.id === permission) >= 0
    );
  } else if (isString(permission)) {
    const permissionName = permission.toUpperCase();

    return !!permissions[permissionName] && permissions[permissionName].id;
  } else {
    return false;
  }
};

/**
 * @namespace Authorization
 * @category Plug-ins
 * @summary Objeto/_namespace_ do _plug-in_ de permissões.
 */
const Authorization = {
  /**
   * Opções definidas durante a associação do _plug-in_ à instância do Vue.
   * @type {object}
   * @readonly
   * @default {}
   */
  options: {},

  /**
   * Gerenciador de estados utilizado pelo sistema.
   * @type {external:Store}
   * @readonly
   * @default null
   */
  store: null,

  /**
   * Adiciona uma permissão/_role_ ao estado global do sistema.
   * @param {object} permissions - Objeto com os dados da permissão/_role_.
   */
  addPermission(permission) {
    if (!this.store) {
      window.console.error(
        "Um gerenciador de estados não foi definido. As permissões não serão verificadas e o acesso às funcionalidades será negado!"
      );
      return;
    }

    this.store.commit("addPermission", permission);
  },

  /**
   * Informa se o usuário logado tem todas as permissões/_roles_ de um conjunto
   * de permissões/_roles_.
   * @param {Array<string|number>} permissions - Array com nomes ou `id`s das
   * permissões/_roles_ a serem verificadas.
   * @returns {boolean}.
   */
  hasAllPermissions(permissions) {
    if (!this.store) {
      window.console.error(
        "Um gerenciador de estados não foi definido. As permissões não serão verificadas e o acesso às funcionalidades será negado!"
      );
      return false;
    }

    const perms = permissions || [];

    return (
      this.isAdmin() ||
      // Considera-se garantido o acesso caso a lista de permissões esteja "vazia"
      !perms.length ||
      perms.reduce(
        (acc, item) => acc && hasPermissionInStore(this.store, item),
        true
      )
    );
  },

  /**
   * Informa se o usuário logado tem pelo menos uma entre um conjunto de
   * permissões/_roles_.
   * @param {Array<string|number>} permissions - Array com nomes ou `id`s das
   * permissões/_roles_ a serem verificadas.
   * @returns {boolean}.
   */
  hasAnyPermission(permissions) {
    if (!this.store) {
      window.console.error(
        "Um gerenciador de estados não foi definido. As permissões não serão verificadas e o acesso às funcionalidades será negado!"
      );
      return false;
    }

    const perms = permissions || [];

    return (
      this.isAdmin() ||
      // Considera-se garantido o acesso caso a lista de permissões esteja "vazia"
      !perms.length ||
      perms.reduce(
        (acc, item) => acc || hasPermissionInStore(this.store, item),
        false
      )
    );
  },

  /**
   * Informa se o usuário logado tem uma permissão/_role_ específica.
   * @param {string|number} permission - Nome ou `id` da permissão/_role_ a ser
   * verificada.
   * @returns {boolean}.
   */
  hasPermission(permission) {
    if (!this.store) {
      window.console.error(
        "Um gerenciador de estados não foi definido. As permissões não serão verificadas e o acesso às funcionalidades será negado!"
      );
      return false;
    }

    return this.isAdmin() || hasPermissionInStore(this.store, permission);
  },

  /**
   * Informa se o usuário logado tem permissões de administrador do sistema.
   * @returns {boolean}.
   */
  isAdmin() {
    if (!this.store) {
      window.console.error(
        "Um gerenciador de estados não foi definido. As permissões não serão verificadas e o acesso às funcionalidades será negado!"
      );
      return false;
    }

    return hasPermissionInStore(this.store, ADMIN);
  }
};

export default Authorization;
