<template>
  <div>
    <h5>
      <i class="material-icons left" v-bind:title="value.tipo">{{ iconName }}</i
      >{{ value.nome }}
    </h5>
    <p>
      <b>Tamanho:</b>&nbsp;
      {{ tamanho }}
    </p>
  </div>
</template>

<script>
const REGEXP_TEXT = /text\/.*/;
const REGEXP_IMAGE = /image\/.*/;
const REGEXP_AUDIO = /audio\/.*/;
const REGEXP_VIDEO = /video\/.*/;
const REGEXP_PDF = /application\/.*pdf.*/;
const REGEXP_COMPRESSED = /application\/.*(zip|rar|tar|arc|compressed).*/;

export default {
  name: "ArquivoItem",
  inheritAttrs: false,
  props: {
    value: {
      type: Object,
      require: true
    }
  },
  computed: {
    iconName() {
      const tipo = this.value ? this.value.tipo : null;

      if (REGEXP_TEXT.test(tipo)) {
        return "article";
      } else if (REGEXP_IMAGE.test(tipo)) {
        return "photo";
      } else if (REGEXP_AUDIO.test(tipo)) {
        return "audiotrack";
      } else if (REGEXP_VIDEO.test(tipo)) {
        return "movie";
      } else if (REGEXP_PDF.test(tipo)) {
        // TODO: trocar por outro ícone
        return "picture_as_pdf";
      } else if (REGEXP_COMPRESSED.test(tipo)) {
        // TODO: trocar por outro ícone
        return "folder";
      } else {
        // TODO: trocar por outro ícone
        return "file_present";
      }
    },
    tamanho() {
      const tamanho = this.value ? this.value.tamanho : null;
      return tamanho + " bytes";
    }
  }
};
</script>
