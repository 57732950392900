<template>
  <BaseCard v-bind="infoCard" class="infocard">
    <template v-slot:card-title>
      <span class="card-title">
        <i class="material-icons left">help_outline</i> {{ title }}
      </span>
    </template>
    <template v-slot:card-content>
      <slot>{{ content }}</slot>
    </template>
  </BaseCard>
</template>

<script>
/**
 * @module components/base/BaseInfoCard/BaseInfoCard
 * @category Componentes-base
 * @summary _Single File Component_ (SFC) de _card_ de informações para o sistema.
 *
 * @description
 * Pequeno componente utilitário para se criar cards com informações explicativas pelo sistema.
 *
 * O _slot_ padrão recebe o conteúdo do _card_. A _prop_ `title` recebe o título que aparecerá no
 * _card_.
 *
 * @requires module:components/base/BaseCard/BaseCard
 * @requires module:constants.INFO_CARD_BG_COLOR
 * @requires module:constants.INFO_CARD_BG_LUMINOSITY
 * @requires module:constants.INFO_CARD_TEXT_COLOR
 *
 * @vue-prop {string} [title] - Título do _card_. Como alternativa, pode-se
 * utilizar o _slot_ de nome "card-title".
 * @vue-prop {string} [content] - Conteúdo do _card_. Como alternativa, pode-se
 * utilizar o _slot_ de nome "card-content".
 *
 * @example
 * <BaseInfoCard title="CUIDADO!">Esta ação é irreversível. Contate nosso suporte.</BaseInfoCard>
 */
import {
  INFO_CARD_BG_COLOR,
  INFO_CARD_BG_LUMINOSITY,
  INFO_CARD_TEXT_COLOR
} from "../../../constants";
import BaseCard from "../BaseCard/BaseCard.vue";

export default {
  name: "BaseInfoCard",
  components: {
    BaseCard
  },
  inheritAttrs: false,
  data() {
    return {
      infoCard: {
        color: INFO_CARD_BG_COLOR,
        luminosity: INFO_CARD_BG_LUMINOSITY,
        textColor: INFO_CARD_TEXT_COLOR
      }
    };
  },
  props: {
    title: String,
    content: String
  }
};
</script>
