<template>
  <article class="container">
    <header>
      <h1 class="thin center">Login</h1>
    </header>

    <div class="row-fix row">
      <form class="col s12 m8 offset-m2" v-on:submit.prevent="onSubmit">
        <div class="row">
          <BaseTextInput
            v-bind="form.identifier"
            v-model="form.identifier.value"
          />
          <BaseTextInput v-bind="form.password" v-model="form.password.value" />
        </div>

        <div class="row">
          <div class="col s12 buttons-row">
            <BaseButton v-bind="form.button" />

            <BaseRouterLink v-bind:to="{ name: 'senha' }"
              >Solicitar nova senha</BaseRouterLink
            >
          </div>
        </div>

        <BaseProgressBar v-if="loading" v-bind="progressBar" />
      </form>
    </div>
  </article>
</template>

<script>
import { APP_INFO } from "../constants";
import {
  AuthenticationService,
  PessoaService,
  UsuarioService
} from "../services";

export default {
  name: "Login",
  inheritAttrs: false,
  data() {
    return {
      loading: false,
      form: {
        identifier: {
          id: "pae-login-idenficador",
          label: "Identificador",
          autofocus: true,
          value: null
        },
        password: {
          id: "pae-login-senha",
          label: "Senha",
          type: "password",
          value: null
        },
        button: {
          tag: "button",
          type: "submit",
          content: "Login",
          iconName: "login"
        }
      },
      progressBar: {
        useContainer: false,
        content: `Realizando login na ${APP_INFO.name}...`
      }
    };
  },
  created() {
    if (AuthenticationService.isAuthenticated()) {
      // TODO: obter a rota anterior para evitar o redirecionamento para a página inicial
      // TODO: decidir se deve redirecionar por replace de rotas
      this.$router.push({ name: "empty" });
    }
  },
  methods: {
    onSubmit() {
      const { identifier, password } = this.form;

      if (!identifier.value || !password.value) {
        this.$notification.pushError(
          "Favor preencher os campos de identificação e de senha"
        );
        return;
      }

      this.loading = this.form.button.disabled = true;

      this.processLogin(
        AuthenticationService.authenticateByPassword(
          identifier.value,
          password.value
        )
      ).finally(() => (this.loading = this.form.button.disabled = false));
    },
    processLogin(auth) {
      return auth
        .then(response => {
          const usuario = response.usuario;

          (usuario.permissoes || []).forEach(item =>
            this.$authorization.addPermission(item)
          );

          Promise.all([
            UsuarioService.get(usuario.id),
            PessoaService.get(usuario.pessoaId)
          ])
            .then(responses => {
              const usuario = responses[0].data;
              const pessoa = responses[1].data;

              delete usuario.pessoaId;

              usuario.pessoa = pessoa;

              this.$store.commit("setUser", usuario);
            })
            .catch(error =>
              this.$notification.pushError(
                `Houve um erro ao obter os dados do usuário logado: ${error.message}`
              )
            );
        })
        .then(() => {
          this.$router.push({ name: "empty" });
        })
        .catch(error => {
          // TODO: verificar se é necessário redirecionar para alguma página.
          this.$notification.pushError(error.message);
        });
    }
  }
};
</script>

<style scoped>
.buttons-row {
  flex-direction: row-reverse;
}
.buttons-row a {
  color: inherit;
  line-height: 36px;
}
.buttons-row a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 992px) {
  .buttons-row {
    flex-direction: row;
  }

  .buttons-row .btn {
    display: block;
    width: 100%;
    margin: 0 0 20px 0 !important;
  }
}
</style>
