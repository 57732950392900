/**
 * @module utils/datetime
 * @category Utilidades
 * @summary Módulo de utilidades para manipulação de datas.
 *
 * @description
 * Expõe funções de utilidades para manipulação de datas. Por utilizar
 * internamente o tipo {@link external:Date Date}, ao converter representações
 * textuais de data e horário, o fuso utilizado será o configurado na máquina
 * do usuário.
 *
 * @requires module:utils/lang.isEmpty
 * @requires module:utils/lang.isNumber
 */

import { isEmpty, isNumber } from "../lang/index";

/**
 * Lista de nomes dos meses do ano.
 * @type {Array<String>}
 * @constant
 * @ignore
 */
const MONTH_NAMES = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro"
];

/**
 * Lista de nomes abreviados dos meses do ano.
 * @type {Array<String>}
 * @constant
 * @ignore
 */
const MONTH_SHORTNAMES = [
  "Jan",
  "Fev",
  "Mar",
  "Abr",
  "Mai",
  "Jun",
  "Jul",
  "Ago",
  "Set",
  "Out",
  "Nov",
  "Dez"
];

/**
 * Indica se um dado é do tipo {@link external:Date Date}.
 * @function
 * @param {any} val
 * @returns {boolean}
 */
var isDate = function(val) {
  return val instanceof Date;
};

/**
 * Indica se um texto é a representação de uma data no formato utilizado pelo
 * Brasil.
 * @function
 * @param {string} val - Texto que representa uma data.
 * @returns {boolean}
 */
export const isBrazilianDate = function(val) {
  return /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(val);
};

/**
 * Indica se um texto é a representação de uma data no formato ISO.
 * @function
 * @param {string} val - Texto que representa uma data.
 * @returns {boolean}
 */
export const isISODate = function(val) {
  return /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(val);
};

/**
 * Indica se um texto é a representação de um horário no formato utilizado pelo
 * Brasil.
 * @function
 * @param {string} val - Texto que representa um horário.
 * @returns {boolean}
 */
export const isBrazilianTime = function(val) {
  return /^[0-9]{2}:[0-9]{2}:[0-9]{2}$/.test(val);
};

/**
 * Indica se um texto é a representação de um horário no formato ISO.
 * @function
 * @param {string} val - Texto que representa um horário.
 * @returns {boolean}
 */
export const isISOTime = function(val) {
  return /^[0-9]{2}:[0-9]{2}:[0-9]{2}$/.test(val);
};

/**
 * Indica se um texto é a representação de uma data com horário no formato
 * utilizado pelo Brasil.
 * @function
 * @param {string} val - Texto que representa uma data com horário.
 * @returns {boolean}
 */
export const isBrazilianDateTime = function(val) {
  return /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}(,){0,1} [0-9]{2}:[0-9]{2}:[0-9]{2}$/.test(
    val
  );
};

/**
 * Indica se um texto é a representação de uma data com horário no formato ISO
 * aceito pelo objeto {@link external:Date Date}.
 * @function
 * @param {string} val - Texto que representa uma data com horário.
 * @returns {boolean}
 */
export const isISODateTime = function(val) {
  return /^[0-9]{4}-[0-9]{2}-[0-9]{2}(T| )[0-9]{2}:[0-9]{2}:[0-9]{2}(\.[0-9]+){0,1}(Z|((\+|-)[0-9]{2}:[0-9]{2})){0,1}$/.test(
    val
  );
};

/**
 * Compara se uma data é posterior a outra. Cada uma pode ser uma quantidade de
 * milissegundos, um texto representando uma data (com ou sem horário) no
 * formato ISO ou um horário nesse formato em um texto de data no formato
 * utilizado pelo Brasil, utilizando o _timezone_ configurado da máquina do usuário.
 * @function
 * @param {number|string|external:Date} val1 - Milissegundos ou texto que
 * representa uma data ou horário.
 * @param {number|string|external:Date} val2 - Milissegundos ou texto que
 * representa uma data ou horário.
 * @returns {boolean}
 * @throws {external:Error} Objeto de erro caso `val` não esteja no
 * formato ISO.
 */
export const isAfter = function(val1, val2) {
  return toDate(val1) > toDate(val2);
};

/**
 * Compara se uma data é anterior a outra. Cada uma pode ser uma quantidade de
 * milissegundos, um texto representando uma data (com ou sem horário) no
 * formato ISO ou um horário nesse formato em um texto de data no formato
 * utilizado pelo Brasil, utilizando o _timezone_ configurado da máquina do usuário.
 * @function
 * @param {number|string|external:Date} val1 - Milissegundos ou texto que
 * representa uma data ou horário.
 * @param {number|string|external:Date} val2 - Milissegundos ou texto que
 * representa uma data ou horário.
 * @returns {boolean}
 * @throws {external:Error} Objeto de erro caso `val` não esteja no
 * formato ISO.
 */
export const isBefore = function(val1, val2) {
  return toDate(val1) < toDate(val2);
};

/**
 * Compara se uma data é igual a outra. Cada uma pode ser uma quantidade de
 * milissegundos, um texto representando uma data (com ou sem horário) no
 * formato ISO ou um horário nesse formato em um texto de data no formato
 * utilizado pelo Brasil, utilizando o _timezone_ configurado da máquina do usuário.
 * @function
 * @param {number|string|external:Date} val1 - Milissegundos ou texto que
 * representa uma data ou horário.
 * @param {number|string|external:Date} val2 - Milissegundos ou texto que
 * representa uma data ou horário.
 * @returns {boolean}
 * @throws {external:Error} Objeto de erro caso `val` não esteja no
 * formato ISO.
 */
export const isEqual = function(val1, val2) {
  return toDate(val1).valueOf() === toDate(val2).valueOf();
};

/**
 * Obtém o nome do mês a partir de seu número.
 * @function
 * @param {number} val - Número do mês.
 * @returns {string} Texto representando o mês.
 */
export const getMonthName = function(val) {
  return MONTH_NAMES[val - 1];
};

/**
 * Obtém o nome abreviado do mês a partir de seu número.
 * @function
 * @param {number} val - Número do mês.
 * @returns {string} Texto abreviado representando o mês.
 */
export const getMonthShortName = function(val) {
  return MONTH_SHORTNAMES[val - 1];
};

/**
 * Obtém a descrição de uma data no formato "DD de MMM de YYYY" em português.
 * @function
 * @param {number|string|external:Date} val - Data.
 * @returns {string} Texto representando a data.
 */
export const getDateString = function(val) {
  const date = toDate(val);

  const dateDay = date
    .getDate()
    .toString()
    .padStart(2, "0");
  const dateMonth = date.getMonth();
  const dateYear = date.getFullYear();

  return `${dateDay} de ${getMonthShortName(dateMonth + 1)} de ${dateYear}`;
};

/**
 * Obtém uma descrição do intervalo entre duas datas.
 * @function
 * @param {number|string|external:Date} start - Data inicial.
 * @param {number|string|external:Date} end - Data término.
 * @returns {string} Texto representando o intervalo.
 */
export const getIntervalString = function(start, end) {
  if (isEmpty(start)) {
    return null;
  }

  if (isEmpty(end)) {
    return toBrazilianDate(start);
  }

  const dateStart = toDate(start);
  const dateEnd = toDate(end);

  const dateStartDay = dateStart
    .getDate()
    .toString()
    .padStart(2, "0");
  const dateStartMonth = dateStart.getMonth();
  const dateStartYear = dateStart.getFullYear();

  const dateEndDay = dateEnd
    .getDate()
    .toString()
    .padStart(2, "0");
  const dateEndMonth = dateEnd.getMonth();
  const dateEndYear = dateEnd.getFullYear();

  if (dateStartYear === dateEndYear) {
    if (dateStartMonth === dateEndMonth) {
      if (dateStartDay === dateEndDay) {
        return getDateString(dateStart);
      }

      return `${dateStartDay} à ${dateEndDay} de ${getMonthShortName(
        dateStartMonth + 1
      )} de ${dateStartYear}`;
    }

    return `${dateStartDay} de ${getMonthShortName(
      dateStartMonth + 1
    )} à ${dateEndDay} de ${getMonthShortName(
      dateEndMonth + 1
    )} de ${dateStartYear}`;
  }

  return `${toBrazilianDate(dateStart)} à ${toBrazilianDate(dateEnd)}`;
};

/**
 * Converte uma quantidade de milissegundos, um texto representando uma data
 * (com ou sem horário) no formato ISO ou um horário nesse formato em um texto
 * de data no formato utilizado pelo Brasil, utilizando o _timezone_
 * configurado da máquina do usuário.
 * @function
 * @param {number|string|external:Date} val - Milissegundos ou texto que
 * representa uma data ou horário.
 * @returns {string} Texto representando a data no formato utilizado pelo
 * Brasil.
 * @throws {external:Error} Objeto de erro caso `val` não esteja no
 * formato ISO.
 */
export const toBrazilianDate = function(val) {
  const d = toDate(val);

  const year = d.getFullYear();
  const mon = (d.getMonth() + 1).toString().padStart(2, "0");
  const day = d
    .getDate()
    .toString()
    .padStart(2, "0");

  return `${day}/${mon}/${year}`;
};

/**
 * Converte uma quantidade de milissegundos, um texto representando uma data
 * (com ou sem horário) no formato utilizado pelo Brasil ou um horário nesse
 * formato em um texto de data no formato ISO, utilizando o _timezone_
 * configurado da máquina do usuário.
 * @function
 * @param {number|string|external:Date} val - Milissegundos ou texto que
 * representa uma data ou horário.
 * @returns {string} Texto representando a data no formato ISO.
 * @throws {external:Error} Objeto de erro caso `val` não esteja no
 * formato utilizado pelo Brasil.
 */
export const toISODate = function(val) {
  const d = toDate(val);

  const year = d.getFullYear();
  const mon = (d.getMonth() + 1).toString().padStart(2, "0");
  const day = d
    .getDate()
    .toString()
    .padStart(2, "0");

  return `${year}-${mon}-${day}`;
};

/**
 * Converte uma quantidade de milissegundos, um texto representando uma data
 * (com ou sem horário) no formato ISO ou um horário nesse formato em um texto
 * de data e horário no formato utilizado pelo Brasil, utilizando o _timezone_
 * configurado da máquina do usuário.
 * @function
 * @param {number|string|external:Date} val - Milissegundos ou texto que
 * representa uma data ou horário.
 * @returns {string} Texto representando a data (com horário) no formato
 * utilizado pelo Brasil.
 * @throws {external:Error} Objeto de erro caso `val` não esteja no
 * formato ISO.
 */
export const toBrazilianDateTime = function(val) {
  const d = toDate(val);

  const year = d
    .getFullYear()
    .toString()
    .padStart(2, "0");
  const mon = (d.getMonth() + 1).toString().padStart(2, "0");
  const day = d
    .getDate()
    .toString()
    .padStart(2, "0");
  const hour = d
    .getHours()
    .toString()
    .padStart(2, "0");
  const min = d
    .getMinutes()
    .toString()
    .padStart(2, "0");
  const sec = d
    .getSeconds()
    .toString()
    .padStart(2, "0");

  return `${day}/${mon}/${year}, ${hour}:${min}:${sec}`;
};

/**
 * Converte uma quantidade de milissegundos, um texto representando uma data
 * (com ou sem horário) no formato utilizado pelo Brasil ou um horário nesse
 * formato em um texto de data e horário no formato ISO, utilizando o _timezone_
 * configurado da máquina do usuário.
 * @function
 * @param {number|string|external:Date} val - Milissegundos ou texto que
 * representa uma data ou horário.
 * @returns {string} Texto representando a data (com horário) no formato ISO.
 * @throws {external:Error} Objeto de erro caso `val` não esteja no
 * formato utilizado pelo Brasil.
 */
export const toISODateTime = function(val) {
  const d = toDate(val);

  const year = d.getFullYear();
  const mon = (d.getMonth() + 1).toString().padStart(2, "0");
  const day = d
    .getDate()
    .toString()
    .padStart(2, "0");
  const hour = d
    .getHours()
    .toString()
    .padStart(2, "0");
  const min = d
    .getMinutes()
    .toString()
    .padStart(2, "0");
  const sec = d
    .getSeconds()
    .toString()
    .padStart(2, "0");
  const milli = d
    .getMilliseconds()
    .toString()
    .padEnd(3, "0");

  // Por natureza, o offset do timezone é invertido
  const tzSignal = d.getTimezoneOffset() < 0 ? "+" : "-";
  const tzHour = Math.floor(d.getTimezoneOffset() / 60)
    .toString()
    .padStart(2, "0");
  const tzMin = (d.getTimezoneOffset() % 60).toString().padStart(2, "0");

  return `${year}-${mon}-${day}T${min}:${hour}:${sec}.${milli}${tzSignal}${tzHour}:${tzMin}`;
};

/**
 * Converte uma quantidade de milissegundos, um texto representando uma data
 * (com ou sem horário) no formato ISO ou um horário nesse formato em um texto
 * de horário no formato utilizado pelo Brasil, utilizando o _timezone_
 * configurado da máquina do usuário.
 * @function
 * @param {number|string|external:Date} val - Milissegundos ou texto que
 * representa uma data ou horário.
 * @returns {string} Texto representando a data no formato utilizado pelo
 * Brasil.
 * @throws {external:Error} Objeto de erro caso `val` não esteja no
 * formato ISO.
 */
export const toBrazilianTime = function(val) {
  const d = toDate(val);

  const hour = d
    .getHours()
    .toString()
    .padStart(2, "0");
  const min = d
    .getMinutes()
    .toString()
    .padStart(2, "0");
  const sec = d
    .getSeconds()
    .toString()
    .padStart(2, "0");

  return `${hour}:${min}:${sec}`;
};

/**
 * Converte uma quantidade de milissegundos, um texto representando uma data
 * (com ou sem horário) no formato utilizado pelo Brasil ou um horário nesse
 * formato em um texto de horário no formato ISO, utilizando o _timezone_
 * configurado da máquina do usuário.
 * @function
 * @param {number|string|external:Date} val - Milissegundos ou texto que
 * representa uma data ou horário.
 * @returns {string} Texto representando a data no formato ISO.
 * @throws {external:Error} Objeto de erro caso `val` não esteja no
 * formato utilizado pelo Brasil.
 */
export const toISOTime = function(val) {
  const d = toDate(val);

  const hour = d
    .getHours()
    .toString()
    .padStart(2, "0");
  const min = d
    .getMinutes()
    .toString()
    .padStart(2, "0");
  const sec = d
    .getSeconds()
    .toString()
    .padStart(2, "0");

  return `${hour}:${min}:${sec}`;
};

/**
 * Converte uma quantidade de milissegundos, um texto representando uma data
 * (com ou sem horário) no formato utilizado pelo Brasil ou no formato ISO ou
 * um horário em um desses formatos em um objeto {@link external:Date Date},
 * utilizando o _timezone_ configurado da máquina do usuário.
 * @function
 * @param {number|string|external:Date} val - Milissegundos ou texto que
 * representa uma data ou horário.
 * @returns {external:Date}
 * @throws {external:Error} Objeto de erro caso `val` não esteja nos
 * formatos aceitos.
 */
export const toDate = function(val) {
  let year = null;
  let mon = null;
  let day = null;
  let hour = null;
  let min = null;
  let sec = null;
  let milli = null;

  let split = null;

  if (isDate(val)) {
    return val;
  } else if (isNumber(val)) {
    return new Date(val);
  } else if (isISOTime(val) || isBrazilianTime(val)) {
    split = val.split(":");

    hour = window.parseInt(split[0]);
    min = window.parseInt(split[1]);
    sec = window.parseInt(split[2]);

    split = [0, 1, 0];
  } else if (isISODate(val)) {
    split = val.split("-");
  } else if (isISODateTime(val)) {
    // De acordo com o Mozilla Developer Network (MDN), não é recomendado
    // instanciar um objeto `Date` a partir de uma representação textual.
    // TODO: fazer o cálculo manualmente.
    return new Date(val);
  } else if (isBrazilianDate(val)) {
    split = val.split("/").reverse();
  } else if (isBrazilianDateTime(val)) {
    split = val
      .substr(10)
      .replace(/[, ]/g, "")
      .split(":");

    hour = window.parseInt(split[0]);
    min = window.parseInt(split[1]);
    sec = window.parseInt(split[2]);

    split = val
      .substr(0, 10)
      .split("/")
      .reverse();
  } else {
    throw new Error("Data inválida");
  }

  year = window.parseInt(split[0]);
  mon = window.parseInt(split[1]) - 1;
  day = window.parseInt(split[2]);

  return new Date(year, mon, day, hour, min, sec, milli);
};

/**
 * Formata uma valor representativo de data/hora no formato especificado.
 * @function
 * @param {*} val - Valor representativo de data/hora.
 * @param {function} formatter - Função de formatação de data/hora.
 * @param {*} [defaultVal=null] - Valor padrão de retorno em caso de erro
 * @param {boolean} [logError=true] - Indica se a mensagem de erro, caso haja,
 * durante a formatação, será exibida no console.
 * @returns {*}
 */
export const format = function(
  val,
  formatter,
  defaultVal = null,
  logError = true
) {
  try {
    return formatter(val);
  } catch (e) {
    if (logError) {
      window.console.error(e.message);
    }
  }

  return defaultVal;
};

export default {
  isAfter,
  isBefore,
  isBrazilianDate,
  isDate,
  isEqual,
  isISODate,
  isBrazilianDateTime,
  isISODateTime,
  isBrazilianTime,
  isISOTime,
  getDateString,
  getMonthName,
  getMonthShortName,
  toBrazilianDate,
  toBrazilianDateTime,
  toDate,
  toISODate,
  toISODateTime,
  toBrazilianTime,
  toISOTime
};
