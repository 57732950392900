/**
 * @module utils/object
 * @category Utilidades
 * @summary Módulo de utilidades para manipulação de objetos.
 *
 * @description
 * Expõe funções de utilidades para manipulação de objetos.
 *
 * @requires module:utils/lang.isArray
 * @requires module:utils/lang.isBoolean
 * @requires module:utils/lang.isNull
 * @requires module:utils/lang.isNullOrUndefined
 * @requires module:utils/lang.isNumber
 * @requires module:utils/lang.isObject
 * @requires module:utils/lang.isString
 */

import {
  isArray,
  isBoolean,
  isNull,
  isNullOrUndefined,
  isNumber,
  isObject,
  isString
} from "../lang";

/**
 * Aplica, recursivamente, um _schema_ (objeto com as propriedades esperadas)
 * a um objeto. `schema` pode ser apenas nulo, um objeto ou um _array_. Caso
 * seja nulo, indica que `target` precisa ser um dos tipos primitivos
 * aceitos ("boolean", "number" ou "string"). Quando é um objeto, cada
 * propriedade pode ser nulo, um objeto ou um _array_. Em caso de ser um _array_,
 * precisa possuir apenas um elemento, sendo ele nulo, um objeto ou um _array_,
 * indicando que todo elemento de `target` deverá ser um primitivo, um objeto
 * ou um _array_.
 *
 * Este método é útil, por exemplo, para certificar que um recurso recebido de
 * uma requisição ao _back-end_ possua todas as propriedades esperadas ao
 * utilizá-lo como variável em um componente do Vue. Possuindo toda sua
 * estrutura preenchida, evita-se erros de acesso a elementos ou propriedades
 * inexistentes.
 * @function
 * @param {object} target - Objeto alvo a ter o _schema_ aplicado.
 * @param {object} schema - Objeto com as propriedades esperadas.
 * @returns {object} Objeto resultante da aplicação do _schema_.
 */
export const applySchema = function(target, schema) {
  let obj = null;

  // Se `schema` é nulo, significa que o tipo deve ser primitivo
  if (isNull(schema)) {
    if (isNullOrUndefined(target)) {
      obj = null;
    } else {
      // Se `target` não for um dos tipos primitivos aceitos, lança erro
      if (!isBoolean(target) && !isNumber(target) && !isString(target)) {
        throw new Error(
          "Valor alvo inválido. Esperava-se um dos tipos primitivos aceitos"
        );
      }

      obj = target;
    }
  } else if (isArray(schema)) {
    obj = [];

    if (target !== null && target !== undefined) {
      // Se `target` não for um array, lança erro
      if (!isArray(target)) {
        throw new Error("Valor alvo inválido. Esperava-se um array");
      }

      if (schema.length) {
        target.forEach(item => obj.push(applySchema(item, schema[0])));
      } else {
        // Se `schema` está vazio, considera-se que é para ser um array de
        // primitivos
        target.forEach(item => obj.push(applySchema(item, null)));
      }
    }
  } else if (isObject(schema)) {
    obj = {};

    if (isNullOrUndefined(target)) {
      for (let [key, value] of Object.entries(schema)) {
        obj[key] = applySchema(null, value);
      }
    } else {
      // Se `target` não for um objeto, lança erro
      if (isArray(target) || !isObject(target)) {
        throw new Error("Valor alvo inválido. Esperava-se um objeto");
      }

      for (let [key, value] of Object.entries(schema)) {
        obj[key] = applySchema(target[key], value);
      }
    }
  } else {
    throw new Error("Schema inválido. Esperava-se nulo, objeto ou array");
  }

  return obj;
};

/**
 * Realiza uma cópia em profundidade de qualquer objeto _javascript_ através de
 * conversão em JSON.
 * @param {any} obj
 */
export const copy = function(obj) {
  return JSON.parse(JSON.stringify(obj));
};

/**
 * Gera um UUID versão 4.
 * @function
 * @returns {string}.
 */
export const uuid = function() {
  // Um UUID é um número de 128 bits representado em blocos de 8, 4, 4, 4 e 12
  // dígitos hexadecimais cada.
  const blockSize = [8, 4, 4, 4, 12];

  // Para cada bloco, gero um número aleatório e utilizo os `n` dígitos
  // hexadecimais do meio, sendo `n` o tamanho do bloco.
  // Como gero um número aleatório entre 0 e `MAX_SAFE_INTEGER` (53 bits), ao
  // ser representado em hexadecimal, sua string possui 14 caracteres.
  const blocks = blockSize.map(item =>
    Math.floor(Math.random() * Number.MAX_SAFE_INTEGER)
      .toString(16)
      .padStart(14, "0")
      .substr(Math.floor((14 - item) / 2), item)
  );

  const version = 4;

  blocks[2] = `${version}${blocks[2].substr(1)}`;

  const variantInitialBytes = window.parseInt(blocks[3].substr(0, 2));
  const variant = ((variantInitialBytes ^ 0x3f) | 0x80).toString(16);

  blocks[3] = `${variant}${blocks[3].substr(2)}`;

  return blocks.join("-");
};

export default { applySchema, copy, uuid };
