/**
 * @module plugins/Http
 * @category Plug-ins
 * @summary Módulo de importação do _plug-in_ de requisições HTTP.
 *
 * @description
 * Expõe o _plug-in_ de requisições HTTP para utilização pelo container e pelos
 * módulos.
 *
 * Ao chamar o método `use` do objeto `Vue` global, torna-se possível
 * acessar o _plug-in_ dentro de componentes através da propriedade
 * `$http`.
 *
 * Internamente, caso seja definido um serviço de autenticação, são atribuídos
 * um interceptador às requisições e um interceptador às respostas do {@link external:axios axios}.
 * O primeiro é para realizar, automaticamente, a autenticação do usuário caso
 * ele não esteja logado no sistema. De qualquer forma, atribui o token de
 * acesso ao cabeçalho da requisição. O segundo é para realizar,
 * automaticamente, a autenticação do usuário caso o retorno seja
 * "401 Unauthorized".
 *
 * @example <caption>Importando o plug-in de requisições HTTP como membro default do módulo</caption>
 * import { AuthenticationService } from "...";
 * import Http from "./plugins/Http";
 *
 * Vue.use(Http, { AuthenticationService });
 *
 * @example <caption>Importando o plug-in de requisições HTTP como membro do módulo</caption>
 * import { AuthenticationService } from "...";
 * import { Http } from "./plugins/Http";
 *
 * Vue.use(Http, { AuthenticationService });
 */
import { default as Http } from "./Http";

/**
 * Associa este _plug-in_ à instância do {@link external:Vue Vue}, atribuindo a
 * ela a propriedade `$http`, tornando-se acessível pelos componentes.
 * @param {external:Vue} Vue - Instância do {@link external:Vue Vue}.
 * @param {object} options - Objeto com as opções deste _plug-in_. Aceita a
 * propriedade `AuthenticationService` como um objeto referente a um serviço de
 * autenticação.
 */
const install = function(Vue, options = {}) {
  Http.options = Object.assign({}, Http.options, options);

  Http.AuthenticationService = Http.options.AuthenticationService;

  Http.registerRequestInterceptor().registerResponseInterceptor();

  Object.defineProperty(Vue.prototype, "$http", {
    get() {
      return Http;
    }
  });
};

// Atribui a função install ao objeto do _plug-in_.
Http.install = install;

export default install;

export { Http };
