<template>
  <div
    v-bind:id="id"
    class="card"
    v-bind:class="[color, luminosity, { hoverable: isHoverable }]"
    v-bind:title="$attrs.title"
  >
    <div class="card-image">
      <slot name="card-image">
        <img
          v-if="cardImage"
          v-bind="cardImage"
          v-bind:class="{ activator: hasCardReveal }"
        />
      </slot>
      <slot name="fab">
        <BaseFloatingActionButton
          v-if="floatingActionButton"
          v-bind="floatingActionButton"
        />
      </slot>
    </div>
    <div class="card-content" v-bind:class="textColor">
      <slot name="card-title">
        <span
          v-if="cardTitle"
          class="card-title"
          v-bind:class="{ activator: hasCardReveal }"
        >
          {{ cardTitle }}
          <i v-if="hasCardReveal" class="material-icons right">more_vert</i>
        </span>
      </slot>
      <slot name="card-content">
        <p>{{ cardContent }}</p>
      </slot>
    </div>
    <div class="card-reveal">
      <slot name="card-title">
        <span v-if="cardTitle" class="card-title">
          {{ cardTitle }}
          <i class="material-icons right">close</i>
        </span>
      </slot>
      <slot name="card-reveal">
        <p>{{ cardReveal }}</p>
      </slot>
    </div>
    <div v-if="hasPropsActions" class="card-action">
      <BaseRouterLink
        v-for="(item, index) in cardAction"
        v-bind:key="index"
        v-bind="item"
        >{{ item.content }}</BaseRouterLink
      >
    </div>
    <div v-if="hasSlotActions" class="card-action">
      <slot name="card-action"></slot>
    </div>
  </div>
</template>

<script>
/**
 * @module components/base/BaseCard/BaseCard
 * @category Componentes-base
 * @summary _Single File Component_ (SFC) de _card_ do {@link external:Materialize Materialize}.
 *
 * @description
 * O _slot_ "card-content" recebe o conteúdo do _card_. O conteúdo de _card reveal_ deve ser
 * inserido no slot "card-reveal". O _slot_ "card-title" recebe o título que aparecerá no
 * _card_, tanto em seu conteúdo principal, como no _card reveal_. O _slot_ "card-image"
 * recebe a imagem do card. É possível também exibir um {@link module:components/base/BaseFloatingActionButton/BaseFloatingActionButton}
 * através do _slot_ "fab". O componente não possui _slot_ _default_.
 *
 * @requires module:components/base/BaseFloatingActionButton/BaseFloatingActionButton
 * @requires module:components/base/BaseRouterLink/BaseRouterLink
 *
 * @vue-prop {string} [id] - Atributo `id` do elemento HTML utilizado
 * internamente pelo componente.
 * @vue-prop {string} [color="white"] - Classe do {@link external:Materialize Materialize}
 * para a cor de fundo padrão do componente.
 * @vue-prop {string} [luminosity] - Classe do {@link external:Materialize Materialize}
 * para a luminosidade/nível de cor de fundo padrão do componente.
 * @vue-prop {string} [textColor="black-text"] - Classe do {@link external:Materialize Materialize}
 * para a cor de texto padrão do componente.
 * @vue-prop {boolean} [isHoverable=false] - Indica se o componente exibirá
 * sombreamento quando o cursor do mouse é passado por cima.
 * @vue-prop {object} [floatingActionButton] - Objeto com as propriedades de um
 * {@link module:components/base/BaseFloatingActionButton/BaseFloatingActionButton}.
 * @vue-prop {object} [cardImage] - Objeto com os atributos do elemento HTML `img`
 * (`src`, `alt`, `title`, etc) para compor a imagem do _card_. Como
 * alternativa, pode-se utilizar o _slot_ de nome "card-image".
 * @vue-prop {string} [cardTitle] - Título do _card_. Como alternativa, pode-se
 * utilizar o _slot_ de nome "card-title".
 * @vue-prop {string} [cardContent] - Conteúdo do _card_. Como alternativa, pode-se
 * utilizar o _slot_ de nome "card-content".
 * @vue-prop {string} [cardReveal] - Conteúdo revelado quando clica-se no
 * _card_. Como alternativa, pode-se utilizar o _slot_ de nome "card-reveal".
 * @vue-prop {Array<object>} [cardAction] - _Array_ com as ações do _card_ (propriedades
 * aceitas pelo {@link module:components/base/BaseRouterLink/BaseRouterLink BaseRouterLink}).
 * Como alternativa, pode-se utilizar o _slot_ de nome "card-action".
 *
 * @example
 * <BaseCard
 *  v-bind:is-hoverable="true"
 *  card-title="Implanta"
 *  card-content="Módulo de implantação de novos servidores"
 *  v-bind:card-action="[{ to: 'implanta', name: 'Acessar', iconName: 'launch' }]" />
 */
import BaseFloatingActionButton from "../BaseFloatingActionButton/BaseFloatingActionButton.vue";
import BaseRouterLink from "../BaseRouterLink/BaseRouterLink.vue";

export default {
  name: "BaseCard",
  components: {
    BaseFloatingActionButton,
    BaseRouterLink
  },
  inheritAttrs: false,
  props: {
    id: String,

    color: {
      type: String,
      default: "white"
    },

    luminosity: String,

    textColor: {
      type: String,
      default: "black-text"
    },
    isHoverable: {
      type: Boolean,
      default: false
    },

    floatingActionButton: Object,
    cardImage: Object,
    cardTitle: String,
    cardContent: String,
    cardReveal: String,
    cardAction: Array
  },
  computed: {
    hasCardReveal() {
      return (
        this.cardReveal ||
        (this.$slots["card-reveal"] && this.$slots["card-reveal"].length)
      );
    },
    hasPropsActions() {
      return this.cardAction && this.cardAction.length;
    },
    hasSlotActions() {
      return this.$slots["card-action"] && this.$slots["card-action"].length;
    }
  }
};
</script>

<style>
.contrast .card {
  border: 1px solid white;
}
</style>
