<template>
  <section class="section">
    <h3 class="pae-title pae-header">
      <i class="material-icons left">group</i>Apresentadores
    </h3>

    <BaseInfoCard title="Seção de apresentadores">
      <p>
        Para adicionar apresentadores, primeiramente, realize a busca através do
        nome/nome social. Em seguida, clique no botão de adição correspondente
        ao apresentador a ser adicionado no conteúdo. Caso o apresentador ainda
        não tenha sido cadastrado no sistema, clique no botão "Novo". Será
        aberto um modal para cadastro do apresentador. Após sua conclusão, ele
        será automaticamente incluído na lista de apresentadores do conteúdo.
        Para remover um apresentador da lista, basta clicar no ícone de lixeira
        correspondente. Por fim, clique no botão "Salvar" para efetuar as
        alterações.
      </p>
    </BaseInfoCard>

    <div class="row-fix row">
      <BaseTextInput
        v-bind="form.filter.nome"
        v-model="form.filter.nome.value"
      />

      <div class="col s12 l6 right right-align">
        <BaseButton
          v-bind="form.filter.buttonSearch"
          v-on:click="onClickSearch"
        />
      </div>
    </div>

    <div v-if="searched" class="row-fix row">
      <div class="col s12">
        <BaseProgressBar
          v-if="loadingApresentadoresFilter"
          v-bind="progressBar"
        />
        <p v-else-if="!hasApresentadoresSearch" class="flow-text center">
          Não há apresentadores para os parâmetros informados.
        </p>
        <div v-else>
          <BaseEditableCollection v-bind="form.filter.apresentadorCollection" />
          <BasePagination
            class="center"
            v-bind="form.filter.pagination"
            v-on:change="onPageChange"
          />
        </div>
      </div>
    </div>

    <section>
      <h4 class="pae-title">Apresentadores adicionados</h4>

      <BaseProgressBar v-if="loadingApresentadores" v-bind="progressBar" />
      <p v-else-if="!hasApresentadores" class="flow-text center-align">
        Nenhum apresentador foi adicionado.
      </p>
      <BaseEditableCollection v-else v-bind="form.apresentadorCollection" />

      <EditableApresentadorModal
        v-bind="apresentadorModal"
        v-on:create="createApresentador"
        v-on:update="updateApresentador"
      />

      <div class="right-align">
        <BaseButton
          style="margin-right: 20px"
          v-bind="form.buttonCreate"
          v-bind:disabled="readonly || disabled"
          v-on:click="create"
        />
        <BaseButton
          v-bind="form.buttonSave"
          v-bind:disabled="readonly || disabled"
          v-on:click="save"
        />
      </div>
    </section>
  </section>
</template>

<script>
import { isEmpty } from "../../../utils/lang";
import {
  ApresentadorService,
  ConteudoService,
  EstandeService
} from "../../../services";
import ApresentadorItem from "../ApresentadorItem.vue";
import EditableApresentadorModal from "./EditableApresentadorModal.vue";

const SEARCH_PAGINATION_SIZE = 5;

export default {
  name: "EditableSectionApresentadores",
  components: {
    EditableApresentadorModal
  },
  inheritAttrs: false,
  props: {
    entidadeId: {
      type: Number,
      require: true
    },
    tipoConteudoId: {
      type: Number,
      require: true
    },

    readonly: Boolean
  },
  data() {
    return {
      disabled: false,
      searched: false,
      loadingApresentadores: false,
      loadingApresentadoresFilter: false,
      apresentadorModal: {
        id: "pae-editablesection-apresentador-modal",
        value: null
      },
      progressBar: {
        useContainer: false
      },
      form: {
        filter: {
          apresentadorCollection: {
            component: ApresentadorItem,
            itemOperations: [
              {
                iconName: "person_add",
                color: "blue",
                title: "Adicionar apresentador ao conteúdo",
                onClick: this.addApresentador
              }
            ],
            //   isSortable: true,
            //   sortMimeType: "application/apresentador-item",
            items: []
          },
          nome: {
            id: "pae-editablesection-apresentadores-nome",
            label: "Nome/Nome social",
            placeholder:
              "Preencha um trecho do nome/nome social do apresentador",
            gridExpression: "col s12",
            value: null
          },
          buttonSearch: {
            iconName: "search",
            content: "Buscar"
          },
          pagination: {
            value: 1,
            numPages: 0
          }
        },
        buttonCreate: {
          iconName: "person_add",
          content: "Novo"
        },
        buttonSave: {
          iconName: "save",
          content: "Salvar"
        },
        apresentadorCollection: {
          component: ApresentadorItem,
          itemOperations: [
            {
              iconName: "edit",
              color: "green",
              title: "Editar apresentador",
              onClick: this.edit
            },
            {
              iconName: "delete",
              color: "red",
              title: "Remover apresentador",
              onClick: this.delete
            }
          ],
          //   isSortable: true,
          //   sortMimeType: "application/apresentador-item",
          items: []
        }
      },
      apresentadoresOriginais: []
    };
  },
  computed: {
    hasApresentadores() {
      return !isEmpty(this.form.apresentadorCollection.items);
    },
    hasApresentadoresSearch() {
      return !isEmpty(this.form.filter.apresentadorCollection.items);
    }
  },
  created() {
    this.getApresentadores(this.$route.params.idEstande, this.entidadeId);
  },
  methods: {
    getApresentadores(idEstande, idConteudo) {
      if (!idEstande || !idConteudo) {
        return;
      }

      let method = null;

      if (this.tipoConteudoId === ConteudoService.PROGRAMADO) {
        method = EstandeService.getApresentadoresOfConteudoProgramado;
      } else if (this.tipoConteudoId === ConteudoService.EXTRA) {
        method = EstandeService.getApresentadoresFromConteudoExtra;
      } else {
        this.$notification.pushError("Tipo de conteúdo inválido");
        return;
      }

      this.loadingApresentadores = true;

      let apresentadores = [];

      method
        .apply(EstandeService, [idEstande, idConteudo])
        .then(response => {
          apresentadores = response.data || [];

          return Promise.allSettled(
            apresentadores.map(item => ApresentadorService.getFoto(item.id))
          );
        })
        .then(response => {
          response.forEach((item, index) => {
            apresentadores[index].foto = item.value ? item.value.data : null;

            this.form.apresentadorCollection.items = apresentadores.map(
              item => ({
                id: item.id,
                readonly: this.readonly,
                value: item
              })
            );

            this.apresentadoresOriginais = apresentadores.map(item =>
              this.$utils.Object.copy(item)
            );
          });
        })
        .catch(error =>
          this.$notification.pushError(
            `Houve um erro ao obter os apresentadores: ${error.message}`
          )
        )
        .finally(() => (this.loadingApresentadores = false));
    },
    resetPagination() {
      this.form.filter.pagination.value = 1;
      this.form.filter.pagination.numPages = 0;
    },
    doSearch() {
      const params = {
        nome: this.form.filter.nome.value,
        page: this.form.filter.pagination.value - 1,
        size: SEARCH_PAGINATION_SIZE
      };

      this.searched = true;

      this.loadingApresentadoresFilter = true;

      this.form.filter.apresentadorCollection.items = [];

      let apresentadores = null;

      ApresentadorService.search(params)
        .then(response => {
          const pagina = response.data;

          apresentadores = pagina.elementos || [];

          this.form.filter.pagination.numPages = pagina.totalPaginas;

          return Promise.allSettled(
            apresentadores.map(item =>
              ApresentadorService.getFoto(item.id, {
                validateStatus: status =>
                  this.$http.isSuccessfulStatus(status) ||
                  status === this.$http.HTTP_STATUS_CLIENT_ERROR_NOT_FOUND
              })
            )
          );
        })
        .then(responses => {
          responses.map((item, index) => {
            if (item.status === "fulfilled") {
              apresentadores[index].foto = item.value.data;
            } else {
              // TODO: verificar se a mensagem de erro não deve ser silenciosa
              window.console.error(
                `Houve um erro ao obter a foto: ${item.reason.message}`
              );
            }
          });

          this.form.filter.apresentadorCollection.items = apresentadores.map(
            item => ({
              id: item.id,
              readonly: this.readonly,
              value: item
            })
          );
        })
        .catch(error =>
          this.$notification.pushError(
            `Houve um erro ao realizar a busca por apresentadores: ${error.message}`
          )
        )
        .finally(() => (this.loadingApresentadoresFilter = false));
    },
    onPageChange(event) {
      this.form.filter.pagination.value = event;
      this.doSearch();
    },
    onClickSearch() {
      this.resetPagination();
      this.doSearch();
    },
    openApresentadorModal(item, index) {
      const apresentadores = this.form.apresentadorCollection.items;

      if (index >= 0 && index < apresentadores.length) {
        this.apresentadorModal.value = apresentadores[index].value.id;
      } else {
        this.apresentadorModal.value = null;
      }

      this.$nextTick().then(() =>
        this.$utils.Components.openModal(this.apresentadorModal.id)
      );
    },
    create() {
      if (!this.readonly) {
        this.openApresentadorModal(null, -1);
      }
    },
    createApresentador(event) {
      if (!this.readonly) {
        this.form.apresentadorCollection.items.push({
          id: event.id,
          readonly: this.readonly,
          value: event
        });
      }
    },
    updateApresentador(event) {
      if (!this.readonly) {
        const obj = this.form.apresentadorCollection.items.find(
          item => item.id === event.id
        );

        if (obj) {
          obj.value = this.$utils.Object.copy(event);
        }
      }
    },
    addApresentador(item, index) {
      if (!this.readonly) {
        const filter = this.form.filter;
        const apresentadores = filter.apresentadorCollection.items;

        if (index !== null && index >= 0 && index < apresentadores.length) {
          const apresentador = apresentadores[index].value;

          if (
            this.form.apresentadorCollection.items.findIndex(
              item => item.id === apresentador.id
            ) === -1
          ) {
            this.form.apresentadorCollection.items.push({
              id: apresentador.id,
              readonly: this.readonly,
              value: apresentador
            });
          }
        }
      }
    },
    edit(item, index) {
      if (!this.readonly && index >= 0) {
        this.openApresentadorModal(item, index);
      }
    },
    delete(item, index) {
      if (!this.readonly && index >= 0) {
        this.form.apresentadorCollection.items.splice(index, 1);
      }
    },
    getPutData() {
      const putData = [];
      const { items } = this.form.apresentadorCollection;

      items.forEach(item => {
        if (item.value.id) {
          const obj = this.apresentadoresOriginais.find(
            originalItem => originalItem.id === item.value.id
          );

          if (!obj) {
            putData.push({ ...item.value });
          }
        }
      });

      return putData;
    },
    getDeleteData() {
      const deleteData = [];
      const { items } = this.form.apresentadorCollection;

      this.apresentadoresOriginais.forEach(originalItem => {
        const isItemFound =
          items.findIndex(item => originalItem.id === item.value.id) >= 0;

        if (!isItemFound) {
          deleteData.push({ ...originalItem });
        }
      });

      return deleteData;
    },
    save() {
      if (this.readonly) {
        return;
      }

      let putMethod = null;
      let deleteMethod = null;

      if (this.tipoConteudoId === ConteudoService.PROGRAMADO) {
        putMethod = EstandeService.addApresentadorToConteudoProgramado;
        deleteMethod = EstandeService.removeApresentadorFromConteudoProgramado;
      } else if (this.tipoConteudoId === ConteudoService.EXTRA) {
        putMethod = EstandeService.addApresentadorToConteudoExtra;
        deleteMethod = EstandeService.removeApresentadorFromConteudoExtra;
      } else {
        this.$notification.pushError("Tipo de conteúdo inválido");
        return;
      }

      const putData = this.getPutData();
      const deleteData = this.getDeleteData();

      const apresentadoresOriginais = this.apresentadoresOriginais;

      const idEstande = this.$route.params.idEstande;
      const idConteudo = this.entidadeId;

      const saveData = [].concat(
        putData.map(item =>
          putMethod
            .apply(EstandeService, [idEstande, idConteudo, item.id])
            .then(() => {
              apresentadoresOriginais.push({ ...item });

              this.$notification.pushSuccess(
                `Apresentador "${item.nome}" incluído com sucesso`
              );
            })
        ),
        deleteData.map(item =>
          deleteMethod
            .apply(EstandeService, [idEstande, idConteudo, item.id])
            .then(() => {
              const id = item.id;

              // Atualiza os registros originais cujos correspondentes foram
              // excluídos com sucesso para que, em caso de erro, os mesmos não
              // sejam reenviados ao servidor desnecessariamente.
              for (let i = 0; i < apresentadoresOriginais.length; i++) {
                if (apresentadoresOriginais[i].id === id) {
                  apresentadoresOriginais.splice(i, 1);
                  break;
                }
              }

              this.$notification.pushSuccess(
                `Apresentador "${item.nome}" removido com sucesso`
              );
            })
        )
      );

      if (saveData.length) {
        this.disabled = true;

        Promise.all(saveData)
          .catch(error =>
            this.$notification.pushError(
              `Houve um erro ao adicionar/remover os apresentadores: ${error.message}`
            )
          )
          .finally(() => (this.disabled = false));
      }
    }
  }
};
</script>
