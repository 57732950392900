<template>
  <div class="collection-wrapper">
    <div class="add-fab-wrapper right-align">
      <BaseFloatingActionButton
        v-if="floatingActionButton"
        class="add-fab"
        v-bind="floatingActionButton"
      />
    </div>
    <ul class="collection" v-bind:class="{ 'with-header': hasSlotHeader }">
      <li v-if="hasSlotHeader" class="collection-header">
        <slot name="header"></slot>
      </li>
      <li
        v-for="(item, index) in items"
        v-bind:key="index"
        v-bind:data-key="index"
        class="collection-item"
        v-bind:draggable="isSortable"
        v-on:dragstart="onDragStart"
        v-on:dragover="onDragOver"
        v-on:dragend="onDragEnd"
        v-on:drop="onDrop"
      >
        <div class="item-wrapper">
          <div class="component-wrapper">
            <component
              v-bind:is="component"
              v-bind="item"
              v-bind:value="item.value"
              v-on:input="onInput($event, item)"
            />
          </div>
        </div>
        <div v-if="itemOperations && itemOperations.length" class="operations-wrapper">
          <BaseButton
            v-for="(opItem, opIndex) in itemOperationsWithEvents"
            v-bind:key="opIndex"
            v-bind="opItem"
            v-bind:disabled="disabled(opItem, item)"
            v-on:click="opItem.onClick"
          />
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
/**
 * @module components/base/BaseEditableCollection/BaseEditableCollection
 * @category Componentes-base
 * @summary _Single File Component_ (SFC) de coleções editáveis.
 *
 * @description
 * O _slot_ "header" recebe o cabeçalho da coleção. Este componente não possui
 * _slot_ _default_.
 *
 * Caso a operação de _drag and drop_ seja habilitada (através da prop
 * `isSortable`), o programador deverá se atentar à atualização de dados
 * derivados. Ao terminar o drag and drop, os itens da coleção são trocados de
 * posição em seu array. Com isso, o {@link external:Vue Vue} atualiza internamente
 * os valores de cada campo e, consequentemente, são atualizados corretamente
 * na tela, mas não atualiza os dados derivados, pois eles são obtidos
 * externamente ao Vue. O componente utilizado para representar cada item da
 * coleção precisa ser programado para atualizar seus dados derivados quando
 * houver um indicativo de atualização desses dados pelo Vue (e.g., usando um
 * _watch_).
 *
 * @requires module:components/base/BaseButton/BaseButton
 * @requires module:components/base/BaseFloatingActionButton/BaseFloatingActionButton
 *
 * @vue-prop {function|object} component - Componente {@link external:Vue Vue} a ser
 * utilizado como item da coleção. Pode ser função do componente obtida por
 * `this.$root.$options.components` ou o objeto com as definições do componente.
 * @vue-prop {Array<object>} [items=[]] - _Array_ com os itens a serem exibidos
 * na coleção. Cada item desse _array_ deve possuir as opções aceitas pelo
 * componente passado para a propriedade `component`.
 * @vue-prop {object} [floatingActionButton] - Objeto com as opções aceitas
 * pelo componente {@link module:components/base/BaseFloatingActionButton/BaseFloatingActionButton}.
 * Um FloatingActionButton será exibido acima da coleção.
 * @vue-prop {Array<object>} [itemOperations=[]] - _Array_ de operações que
 * serão definidas para cada item. Cada objeto pode possuir as opções aceitas
 * por {@link module:components/base/BaseButton/BaseButton}.
 * Em especial, a propriedade `disabled` pode aceitar uma função que receberá
 * os dados do item correspondente para verificar se o botão da operação ficará
 * desabilitado ou não.
 * @vue-prop {boolean} [isSortable=true] - Indica se os itens deste componente
 * poderão ser ordenáveis através de operações de _drag and drop_.
 * @vue-prop {string} [sortMimeType="application/data"] - MIME type utilizado
 * internamente para armazenar o índice do item que está sendo movido.
 * Recomenda-se definir um MIME type para evitar que itens de um
 * `BaseEditableCollection` sejam movidos para outro equivocadamente.
 *
 * @example
 * <BaseEditableCollection
 *  v-bind:component="$root.$options.component.BaseTextInput"
 *  v-bind:items="[{ id: 1, value: '...' }]"
 *  v-bind:item-operations="[{ iconName: 'delete', color: 'red', onClick: '...'}]">
 *  <template v-slot:header>
 *    <span>Telefones</span>
 *  </template>
 * </BaseEditableCollection>
 */
import BaseButton from "../BaseButton/BaseButton.vue";
import BaseFloatingActionButton from "../BaseFloatingActionButton/BaseFloatingActionButton.vue";

export default {
  name: "BaseEditableCollection",
  components: {
    BaseButton,
    BaseFloatingActionButton
  },
  inheritAttrs: false,
  props: {
    component: {
      type: [Function, Object],
      required: true
    },
    items: {
      type: Array,
      default: () => []
    },

    floatingActionButton: Object,

    itemOperations: {
      type: Array,
      default: () => []
    },
    isSortable: {
      type: Boolean,
      default: false
    },
    sortMimeType: {
      type: String,
      default: "application/data"
    }
  },
  data() {
    return {
      dragAndDrop: {
        targetKey: null
      }
    };
  },
  computed: {
    hasSlotHeader() {
      return this.$slots.header && this.$slots.header.length;
    },
    itemOperationsWithEvents() {
      return this.itemOperations.map(item => {
        return {
          ...item,
          onClick: event => {
            if (item.onClick) {
              // event (click) / currentTarget (button) / parentNode(div.operations-wrapper) / previousSibling(div.item-wrapper)
              const itemWrapper =
                event.currentTarget.parentNode.previousSibling;

              // firstChild (div.component-wrapper) / firstChild (component)
              const component = itemWrapper.firstChild.firstChild;

              const index = itemWrapper.parentNode.dataset.key;

              item.onClick(component, index);
            }
          }
        };
      });
    }
  },
  methods: {
    disabled(operation, item) {
      return (
        (typeof operation.disabled === "function" &&
          operation.disabled(item)) ||
        (typeof operation.disabled !== "function" && operation.disabled)
      );
    },
    getKeyFromElement(el) {
      // Espera-se que o elemento passado como parâmetro seja o detentor do
      // evento de drag (li.item-wrapper), que possui a propriedade `key` com o
      // índice do elemento do array `items` que está sendo movido.
      if (el) {
        if (el.dataset.key !== undefined) {
          const key = window.parseInt(el.dataset.key);

          return !window.isNaN(key) ? key : null;
        }
        // Caso não seja o elemento com o evento de drag, verifica no elemento
        // pai e, assim, sucessivamente até o encontrar.
        else {
          return this.getKeyFromElement(el.parentNode);
        }
      }

      return null;
    },
    onDragStart(event) {
      const { dataTransfer, target } = event;

      const sourceKey = this.getKeyFromElement(target);

      if (sourceKey !== null) {
        dataTransfer.setData(this.sortMimeType, sourceKey);
      }

      this.dragAndDrop.targetKey = null;

      dataTransfer.dropEffect = "move";
    },
    onDragOver(event) {
      const { dataTransfer, target } = event;

      const targetKey = this.getKeyFromElement(target);
      const sourceKey = window.parseInt(
        dataTransfer.getData(this.sortMimeType)
      );

      // Apenas permite o drag and drop se o evento possuir dado para o
      // MIME type definido para este componente e os índices dos itens forem
      // diferentes.
      if (targetKey !== null && sourceKey !== null && targetKey !== sourceKey) {
        event.preventDefault();
      }

      dataTransfer.dropEffect = "move";
    },
    onDrop(event) {
      const { dataTransfer, target } = event;

      const targetKey = this.getKeyFromElement(target);
      const sourceKey = window.parseInt(
        dataTransfer.getData(this.sortMimeType)
      );

      // Apenas permite o drag and drop se o evento possuir dado para o
      // MIME type definido para este componente e os índices dos itens forem
      // diferentes.
      if (targetKey !== null && sourceKey !== null && targetKey !== sourceKey) {
        event.preventDefault();

        // Armazena o índice do item destino para correta detecção da
        // remoção do item origem.
        this.dragAndDrop.targetKey = targetKey;

        if (targetKey === this.items.length - 1) {
          this.items.push(this.items[sourceKey]);
        } else if (targetKey > sourceKey) {
          this.items.splice(targetKey + 1, 0, this.items[sourceKey]);
        } else {
          this.items.splice(targetKey, 0, this.items[sourceKey]);
        }
      }
    },
    onDragEnd(event) {
      const { dataTransfer } = event;

      const sourceKey = window.parseInt(
        dataTransfer.getData(this.sortMimeType)
      );

      // Nos casos em que o evento foi interrompido/cancelado ou se tornou
      // inválido, a propriedade `dropEffect` vale "none".
      if (event.dataTransfer.dropEffect === "move") {
        if (this.dragAndDrop.targetKey < sourceKey) {
          this.items.splice(sourceKey + 1, 1);
        } else {
          this.items.splice(sourceKey, 1);
        }
      }
    },
    onInput(event, item) {
      item.value = event;
      // Preparado para emitir um objeto copiado (para não ferir a boa prática de um componente filho não alterar um dado do componente pai)
      // this.$emit("input", { ...item, value: event });
    }
  }
};
</script>

<style scoped>
.add-fab-wrapper {
  position: relative;
}
.add-fab-wrapper .add-fab {
  position: absolute;
  right: 0;
  bottom: 0;
}

.collection {
  overflow: visible;
}
.collection .collection-item {
  display: flex;
  flex-direction: row;
  padding: 0;
}
.collection.with-header .collection-item {
  padding: 0;
}
.collection .collection-item .item-wrapper {
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding: 10px 20px;
}
.collection.with-header .collection-item .item-wrapper {
  padding-left: 30px;
}
.collection .collection-item .item-wrapper .component-wrapper {
  flex-basis: 100%;
}
.collection .collection-item .operations-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 20px 10px 10px;
  border-left: 1px solid #e0e0e0;
}
.collection .collection-item .operations-wrapper a,
.collection .collection-item .operations-wrapper button {
  margin: 5px 0;
}
.collection .collection-item .operations-wrapper a:first-of-type,
.collection .collection-item .operations-wrapper button:first-of-type {
  margin-top: 0;
}
.collection .collection-item .operations-wrapper a:last-of-type,
.collection .collection-item .operations-wrapper button:last-of-type {
  margin-bottom: 0;
}
</style>
