/**
 * @module directives/Scrollspy
 * @category Diretivas
 * @summary Módulo de importação da diretiva de _scrollspy_ do {@link external:Materialize Materialize}.
 *
 * @description
 * Expõe a diretiva de _scrollspy_ do {@link external:Materialize Materialize}
 * para utilização pelo container e pelos módulos.
 *
 * Ao chamar o método `use` do objeto `Vue` global, torna-se possível
 * acessar a diretiva dentro de componentes através do atributo HTML
 * `v-scrollspy`.
 *
 * @example <caption>Importando a diretiva de scrollspy como membro default do módulo</caption>
 * import Scrollspy from "./directives/Scrollspy";
 *
 * Vue.use(Scrollspy);
 *
 * @example <caption>Importando a diretiva de scrollspy como membro do módulo</caption>
 * import { Scrollspy } from "./directives/Scrollspy";
 *
 * Vue.use(Scrollspy);
 */
import { default as Scrollspy } from "./Scrollspy";

/**
 * Associa esta diretiva à instância do {@link external:Vue Vue}, tornando-se
 * acessível pelos componentes através do atributo HTML `v-scrollspy`.
 * @param {external:Vue} Vue - Instância do {@link external:Vue Vue}.
 * @param {object} options - Objeto com as opções desta diretiva.
 */
const install = function(Vue, options = {}) {
  Scrollspy.options = Object.assign({}, options);

  Vue.directive("scrollspy", Scrollspy);
};

// Atribui a função install ao objeto da diretiva.
Scrollspy.install = install;

export default install;

export { Scrollspy };
