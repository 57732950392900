/**
 * @module services/Accessibility/Accessibility
 * @category Serviços
 * @summary Módulo do serviço de acessibilidade.
 *
 * @description
 * Expõe o objeto/_namespace_ do serviço de acessibilidade do sistema para
 * utilização interna.
 *
 * @example
 * import AccessibilityService from "./services/Accessibility/Accessibility";
 *
 * export default {
 *  name: // ...
 *  mounted() {
 *    AccessibilityService.applyContrastState();
 *  },
 *  // ...
 * }
 */

import { createStyleElement } from "../../utils/web";

/**
 * Instância de {@link external:Storage Storage} do tipo "local". Utilizado
 * para armazenar o estado das funções de acessibilidade ativadas pelo usuário.
 * @type {external:Storage}
 * @ignore
 */
const localStorage = window && window.localStorage ? window.localStorage : null;

/**
 * Regras CSS para o alto contraste.
 * @type {string}
 * @ignore
 */
const CSS_STYLESHEET = `
.contrast body,
.contrast nav,
.contrast div,
.contrast li,
.contrast ol,
.contrast header,
.contrast footer,
.contrast section,
.contrast main,
.contrast aside,
.contrast article {
  background: black !important;
  color: white !important;
}

.contrast h1,
.contrast h2,
.contrast h3,
.contrast h4,
.contrast h5,
.contrast h6,
.contrast p,
.contrast label,
.contrast strong,
.contrast em,
.contrast cite,
.contrast q,
.contrast i,
.contrast b,
.contrast u,
.contrast span {
  color: white !important;
}

.contrast a {
  color: yellow !important;
}

.contrast button,
.contrast input[type="button"],
.contrast input[type="reset"],
.contrast input[type="submit"] {
  background: black !important;
  color: yellow !important;
  border: none !important;
}

.contrast input[type="text"],
.contrast input[type="password"],
.contrast input[type="url"],
.contrast input[type="search"],
.contrast input[type="email"],
.contrast input[type="tel"],
.contrast input[type="date"],
.contrast input[type="month"],
.contrast input[type="week"],
.contrast input[type="datetime"],
.contrast input[type="datetime-local"],
.contrast textarea,
.contrast input[type="number"] {
  background: black !important;
  border: 1px solid white !important;
  color: white !important;
}

.contrast img.on-contrast-force-gray {
  filter: grayscale(100%) contrast(120%);
}

.contrast img.on-contrast-force-white {
  filter: brightness(0) invert(1);
}

.contrast img {
  filter: gray !important;/* IE */
  -webkit-filter: grayscale(1) !important; /* Old WebKit */
  -webkit-filter: grayscale(100%) !important;/* New WebKit */
  filter: url(resources.svg#desaturate) !important; /* older Firefox */
  filter: grayscale(100%) !important; /* Current draft standard */
}

/* Estilos específicos para o sistema */
.contrast i.material-icons {
  background: black !important;
}

.contrast .btn {
  background: black !important;
  border: 1px solid white !important;
}

.contrast .sidenav {
  background-color: black;
  border-right: 1px solid white;
}
.contrast .sidenav .divider {
  background-color: white !important;
}
.contrast .drag-target {
  background-color: transparent !important;
}
`;

/**
 * Atributo `id` do elemento HTML `style` contendo as regras CSS para o alto
 * contraste.
 * @type {string}
 * @ignore
 */
const CSS_STYLESHEET_ID = "stylesheet-contrast";

/**
 * Nome da propriedade preenchida no {@link external:Storage Storage} que
 * armazena o estado do contraste (ativo ou não).
 * @type {string}
 * @constant
 * @ignore
 */
const CONTRAST_PROPNAME = "contrastState";

/**
 * Nome da propriedade preenchida no {@link external:Storage Storage} que
 * armazena a variação/delta do tamanho da fonte dos elementos HTML na tela.
 * @type {string}
 * @constant
 * @ignore
 */
const FONTSIZE_PROPNAME = "fontSizeDelta";

/**
 * Adiciona ou remove a classe CSS "contrast" ao elemento HTML `body`
 * dependendo se o alto contraste está ativo ou não.
 * @param {boolean} state - Indica se o alto contraste está ativo ou não.
 * @ignore
 */
const changeContrast = function(state) {
  if (state) {
    document.body.classList.add("contrast");
  } else {
    document.body.classList.remove("contrast");
  }
};

/**
 * Aplica uma variação/delta no tamanho da fonte dos elementos da página.
 * @param {number} delta - Variação/delta a ser aplicado.
 */
const changeFontSize = function(delta) {
  if (delta) {
    document
      .querySelectorAll(
        "h1, h2, h3, h4, h5, h6, span, li, a, p, i, q, u, b, em, cite, strong, label, button, input, select, textarea"
      )
      .forEach(node => {
        const fontSize = window
          .getComputedStyle(node)
          .getPropertyValue("font-size");

        // TODO: considerar tamanhos em casos absolutos e relativos, com valores ou porcentagens
        node.style.fontSize = parseInt(fontSize) + delta + "px";
      });
  }
};

/**
 * Obtém o estado do alto contrast (ativo ou não) a partir do
 * {@link external:Storage Storage} de tipo "local".
 * @returns {boolean} - Indica se o alto contraste está ativo ou não.
 */
const getContrastState = function() {
  if (!localStorage) {
    window.console.warn(
      'Não há suporte para Storage do tipo "local". O armazenamento dos dados de acessibilidade não será gerenciado pela aplicação'
    );
    return false;
  } else {
    return JSON.parse(localStorage.getItem(CONTRAST_PROPNAME)) || false;
  }
};

/**
 * Obtém a variação/delta a ser aplicado no tamanho da fonte dos elementos da
 * página a partir do {@link external:Storage Storage} de tipo "local".
 * @returns {boolean} - Variação/delta a ser aplicado.
 */
const getFontSizeDelta = function() {
  if (!localStorage) {
    window.console.warn(
      'Não há suporte para Storage do tipo "local". O armazenamento dos dados de acessibilidade não será gerenciado pela aplicação'
    );
    return 0;
  } else {
    return JSON.parse(localStorage.getItem(FONTSIZE_PROPNAME)) || 0;
  }
};

/**
 * Define o estado do alto contrast (ativo ou não) no
 * {@link external:Storage Storage} de tipo "local".
 * @param {boolean} state - Indica se o alto contraste foi ativado ou desativado.
 */
const setContrastState = function(state) {
  if (!localStorage) {
    return window.console.warn(
      'Não há suporte para Storage do tipo "local". O armazenamento dos dados de acessibilidade não será gerenciado pela aplicação'
    );
  } else {
    localStorage.setItem(CONTRAST_PROPNAME, state);
  }
};

/**
 * Define a variação/delta a ser aplicado no tamanho da fonte dos elementos da
 * página no {@link external:Storage Storage} de tipo "local".
 * @param {boolean} delta - Variação/delta definido.
 */
const setFontSizeDelta = function(delta) {
  if (!localStorage) {
    return window.console.warn(
      'Não há suporte para Storage do tipo "local". O armazenamento dos dados de acessibilidade não será gerenciado pela aplicação'
    );
  } else {
    localStorage.setItem(FONTSIZE_PROPNAME, delta);
  }
};

/**
 * @namespace AccessibilityService
 * @category Serviços
 * @summary Objeto/_namespace_ do serviço de acessibilidade.
 * @requires module:utils/web.createStyleElement
 */
const AccessibilityService = {
  /**
   * Aplica (ou não) o alto contraste.
   * @returns {module:services/Accessibility/Accessibility~AccessibilityService} - O próprio namespace.
   */
  applyContrastState() {
    changeContrast(getContrastState());

    return this;
  },

  /**
   * Aplica (ou não) a variação/delta no tamanho da fonte dos elementos da
   * página.
   * @returns {module:services/Accessibility/Accessibility~AccessibilityService} - O próprio namespace.
   */
  applyFontSizeDelta() {
    changeFontSize(getFontSizeDelta());

    return this;
  },

  /**
   * Carrega as regras CSS para o alto contraste criando um elemento HTML
   * `style`.
   * @returns {module:services/Accessibility/Accessibility~AccessibilityService} - O próprio namespace.
   */
  loadCSSStyleSheet() {
    createStyleElement({
      id: CSS_STYLESHEET_ID,
      type: "text/css",
      innerHTML: CSS_STYLESHEET
    });

    return this;
  },

  /**
   * Reduz o tamanho da fonte dos elementos da página.
   * @param {number} value - Valor a ser reduzido.
   * @returns {module:services/Accessibility/Accessibility~AccessibilityService} - O próprio namespace.
   */
  decreaseFontSize(value) {
    setFontSizeDelta(getFontSizeDelta() - value);
    changeFontSize(-value);

    return this;
  },

  /**
   * Aumenta o tamanho da fonte dos elementos da página.
   * @param {number} value - Valor a ser aumentado.
   * @returns {module:services/Accessibility/Accessibility~AccessibilityService} - O próprio namespace.
   */
  increaseFontSize(value) {
    setFontSizeDelta(getFontSizeDelta() + value);
    changeFontSize(+value);

    return this;
  },

  /**
   * Inverte o estado do alto contraste.
   * @returns {module:services/Accessibility/Accessibility~AccessibilityService} - O próprio namespace.
   */
  toggleContrast() {
    const contrastState = !getContrastState();

    setContrastState(contrastState);
    changeContrast(contrastState);

    return this;
  }
};

export default AccessibilityService;
