/**
 * @module utils/components
 * @category Utilidades
 * @summary Módulo de utilidades para manipulação dos componentes comuns do
 * sistema.
 *
 * @description
 * Expõe funções de utilidades para manipulação dos componentes comuns do
 * sistema.
 *
 * @requires module:utils/lang.isEmpty
 * @requires module:utils/lang.isObject
 * @requires module:utils/object.copy
 */

import { isEmpty, isObject } from "../lang";
import { copy } from "../object";

/**
 * Fecha um modal do {@link external:Materialize Materialize}.
 * @function
 * @param {string} id - Id do elemento que foi utilizado para o modal.
 */
export const closeModal = function(id) {
  if (!window.M) {
    window.console.error(
      "Objeto do Materialize não encontrado. Verifique se essa biblioteca foi carregada corretamente"
    );
    return;
  }

  const instance = window.M.Modal.getInstance(document.getElementById(id));

  if (instance) {
    instance.close();
  }
};

/**
 * Abre um modal do {@link external:Materialize Materialize}.
 * @function
 * @param {string} id - Id do elemento que foi utilizado para o modal.
 */
export const openModal = function(id) {
  if (!window.M) {
    window.console.error(
      "Objeto do Materialize não encontrado. Verifique se essa biblioteca foi carregada corretamente"
    );
    return;
  }

  const instance = window.M.Modal.getInstance(document.getElementById(id));

  if (instance) {
    instance.open();
  }
};

/**
 * Converte um objeto para outro possuindo as propriedades necessárias para
 * uso do {@link module:components/base/BaseDropdown/BaseDropdown BaseDropdown}.
 * @function
 * @param {object} item - Objeto a ser convertido em um item do _dropdown_.
 * @param {string} [propertyId="id"] - Nome da propriedade para considerar como id para o item do _dropdown_.
 * @param {string} [propertyContent="content"] - Nome da propriedade para considerar como conteúdo para o item do _dropdown_.
 * @param {boolean} [keepProperties=false] - Indica se as propriedades do objeto a ser convertido serão mantidas.
 * @returns {object} Objeto convertido.
 */
export const toDropdownItem = function(
  item,
  propertyId = "id",
  propertyContent = "content",
  keepProperties = false
) {
  if (isEmpty(item) || !isObject(item)) {
    return null;
  }

  const obj = !keepProperties ? {} : copy(item);

  obj.id = item[propertyId];
  obj.content = item[propertyContent];

  return obj;
};

/**
 * Converte um item do _dropdown_ ({@link module:components/base/BaseDropdown/BaseDropdown BaseDropdown}) para um objeto.
 * @function
 * @param {object} item - Item do _dropdown_ a ser convertido.
 * @param {string} [propertyId="id"] - Nome da propriedade para utilizar o id do item do _dropdown_.
 * @param {string} [propertyContent="content"] - Nome da propriedade para utilizar o conteúdo do item do _dropdown_.
 * @param {boolean} [keepProperties=false] - Indica se as propriedades do objeto a ser convertido serão mantidas.
 * @returns {object} Objeto convertido.
 */
export const fromDropdownItem = function(
  item,
  propertyId,
  propertyContent,
  keepProperties = false
) {
  if (isEmpty(item) || !isObject(item)) {
    return null;
  }

  const obj = !keepProperties ? {} : copy(item);

  obj[propertyId] = item.id;
  obj[propertyContent] = item.content;

  return obj;
};

/**
 * Converte um objeto para outro possuindo as propriedades necessárias para
 * uso do {@link module:components/base/BaseSelect/BaseSelect BaseSelect}.
 * @function
 * @param {object} item - Objeto a ser convertido em um item do _select_.
 * @param {string} [propertyValue="id"] - Nome da propriedade para considerar como valor para o item do _select_.
 * @param {string} [propertyContent="content"] - Nome da propriedade para considerar como conteúdo para o item do _select_.
 * @param {boolean} [keepProperties=false] - Indica se as propriedades do objeto a ser convertido serão mantidas.
 * @returns {object} Objeto convertido.
 */
export const toOptionElement = function(
  item,
  propertyValue = "id",
  propertyContent = "content",
  keepProperties = false
) {
  if (isEmpty(item) || !isObject(item)) {
    return null;
  }

  const obj = !keepProperties ? {} : copy(item);

  obj.value = item[propertyValue];
  obj.content = item[propertyContent];

  return obj;
};

/**
 * Converte um item do _select_ ({@link module:components/base/BaseSelect/BaseSelect BaseSelect}) para um objeto.
 * @function
 * @param {object} item - Item do _select_ a ser convertido.
 * @param {string} [propertyValue="id"] - Nome da propriedade para utilizar o valor do item do _select_.
 * @param {string} [propertyContent="content"] - Nome da propriedade para utilizar o conteúdo do item do _select_.
 * @param {boolean} [keepProperties=false] - Indica se as propriedades do objeto a ser convertido serão mantidas.
 * @returns {object} Objeto convertido.
 */
export const fromOptionElement = function(
  item,
  propertyValue = "id",
  propertyContent = "content",
  keepProperties = false
) {
  if (isEmpty(item) || !isObject(item)) {
    return null;
  }

  const obj = !keepProperties ? {} : copy(item);

  obj[propertyValue] = item.value;
  obj[propertyContent] = item.content;

  return obj;
};

export default {
  closeModal,
  fromDropdownItem,
  fromOptionElement,
  openModal,
  toDropdownItem,
  toOptionElement
};
