<template>
  <BaseModal v-bind="conteudoModal">
    <template v-slot:header
      ><h3 class="pae-title">{{ modalTitle }}</h3></template
    >

    <BaseProgressBar v-if="loading" v-bind="progressBar" />
    <div v-else-if="hasConteudo">
      <p class="flow-text justify-align descricao">{{ conteudo.descricao }}</p>

      <SectionApresentadores v-bind:apresentadores="conteudo.apresentadores" />

      <SectionConteudoMidiaExterna v-bind:value="conteudo.url" />

      <SectionArquivos
        v-bind:estande-id="entidadeId"
        v-bind:conteudo-id="conteudo.id"
        v-bind:arquivos="conteudo.arquivos"
      />
    </div>

    <template v-slot:footer>
      <BaseButton class="modal-close" material-type="flat">Fechar</BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import { EstandeService } from "../../../services";
import SectionApresentadores from "./SectionApresentadores.vue";
import SectionArquivos from "./SectionArquivos.vue";
import SectionConteudoMidiaExterna from "./SectionConteudoMidiaExterna.vue";

export default {
  name: "SectionConteudoExtraModal",
  components: {
    SectionApresentadores,
    SectionArquivos,
    SectionConteudoMidiaExterna
  },
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      required: true
    },
    entidadeId: {
      type: Number,
      required: true
    },

    value: Number
  },
  data() {
    return {
      loading: false,
      conteudoModal: {
        id: this.id,
        hasFixedFooter: true,
        modalOptions: {
          onOpenStart: this.getConteudo
        }
      },
      progressBar: {
        useContainer: false
      },
      conteudo: null
    };
  },
  computed: {
    hasConteudo() {
      return this.conteudo;
    },
    modalTitle() {
      return this.conteudo ? this.conteudo.nome : "";
    }
  },
  methods: {
    getConteudo() {
      if (!this.entidadeId) {
        return;
      }

      const idEstande = this.entidadeId;
      const idConteudo = this.value;

      this.loading = true;

      this.conteudo = null;

      EstandeService.getConteudoExtra(idEstande, idConteudo)
        .then(response => {
          this.conteudo = response.data;

          return EstandeService.getApresentadoresFromConteudoExtra(
            idEstande,
            idConteudo
          );
        })
        .then(response => {
          this.conteudo.apresentadores = response.data || [];

          return EstandeService.getArquivosFromConteudoExtra(
            idEstande,
            idConteudo
          );
        })
        .then(response => (this.conteudo.arquivos = response.data || []))
        .catch(error =>
          this.$notification.pushError(
            `Houve um erro ao obter os dados do conteúdo: ${error.message}`
          )
        )
        .finally(() => (this.loading = false));
    }
  }
};
</script>

<style scoped>
.descricao {
  white-space: pre-wrap;
}
</style>
