<template>
  <section class="section">
    <component v-bind:is="tag" class="pae-title pae-header">
      <i class="material-icons left">group</i>Apresentadores
    </component>

    <p v-if="!hasApresentadores" class="flow-text center">
      Nenhum apresentador foi cadastrado.
    </p>
    <div v-else>
      <ApresentadorItem
        v-for="(item, index) in apresentadores"
        v-bind:key="index"
        v-bind:value="item"
      />
    </div>
  </section>
</template>

<script>
import { isEmpty } from "../../../utils/lang";
import ApresentadorItem from "../ApresentadorItem.vue";

export default {
  name: "SectionApresentadores",
  components: {
    ApresentadorItem
  },
  inheritAttrs: false,
  props: {
    tag: {
      type: String,
      default: "h4",
      validator: value =>
        ["h1", "h2", "h3", "h4", "h5", "h6"].indexOf(value) !== -1
    },
    apresentadores: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    hasApresentadores() {
      return !isEmpty(this.apresentadores);
    }
  }
};
</script>

<style scoped>
.apresentador-container {
  display: flex;
}
.apresentador-container .apresentador-photo {
  margin: 0.7666666667rem auto 0 auto;
  flex: 0 0 auto;
}
.apresentador-container .apresentador-photo img {
  max-height: 100%;
  box-shadow: 0px 0px 5px black;
  /* border-radius: 50%; */
  border: 4px solid white;
}
.apresentador-container .apresentador-description {
  flex: 0 0 auto;
}

@media screen and (max-width: 600px) {
  .apresentador-container {
    flex-direction: column;
  }
  /* .apresentador-container .apresentador-photo {
    margin: 0 auto 20px auto;
    width: 180px;
  } */
  .apresentador-container .apresentador-description {
    text-align: center;
    /* flex-grow: 1; */
  }
}

@media screen and (min-width: 600px) {
  /* .apresentador-container .apresentador-photo {
    margin: 0 0 20px 0;
    padding-right: 15px;
    flex: 1 0 0;
    max-width: 180px;
  } */
  .apresentador-container .apresentador-description {
    /* flex: 1 0 0; */
    /* text-align: left; */
  }
}
</style>
