<template>
  <div class="row-fix row valign-wrapper">
    <div class="col s12 m3 image-container valign-wrapper">
      <img class="responsive-img" v-bind="thumbnail" />
    </div>
    <div class="col s12 m9 input-container">
      <div class="row">
        <BaseTextInput
          v-bind="form.nome"
          v-bind:disabled="readonly"
          v-on:input="onInput('nome', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { NOPIC_IMG_SRC } from "../../../constants";

export default {
  name: "EditableSectionEstandeItem",
  inheritAttrs: false,
  props: {
    readonly: Boolean,

    value: Object
  },
  data() {
    return {
      form: {
        nome: {
          id: this.buildElementId(this.value.id, "nome"),
          required: true,
          label: "Nome",
          value: this.value.nome
        }
      }
    };
  },
  computed: {
    thumbnail() {
      const thumbnail = {};

      if (this.value && this.value.banner) {
        const { nome } = this.form;

        thumbnail.src = this.value.banner.conteudo;
        thumbnail.alt = nome.value;
        thumbnail.title = nome.value;
      } else {
        thumbnail.src = NOPIC_IMG_SRC;
        thumbnail.alt = "Sem foto";
        thumbnail.title = "Sem foto";
      }

      return thumbnail;
    }
  },
  methods: {
    buildElementId(itemId, name) {
      return itemId
        ? `editablesection-estande-${itemId}-${name}`
        : this.$utils.Object.uuid();
    },
    onInput(propertyName, event) {
      this.$emit("input", { ...this.value, [propertyName]: event });
    }
  }
};
</script>

<style scoped>
.row {
  margin-bottom: 0;
}

.image-container {
  flex: 0 0 auto;
}
.image-container img.responsive-img {
  max-height: 100%;
}
.input-container {
  flex: 0 0 auto;
}

@media screen and (max-width: 600px) {
  .row.valign-wrapper {
    flex-direction: column;
  }
}
</style>
