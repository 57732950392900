/**
 * @module plugins/Utils/Utils
 * @category Plug-ins
 * @summary Módulo do _plug-in_ de utilidades.
 *
 * @description
 * Expõe o objeto/_namespace_ do _plug-in_ de utilidades para utilização pelo
 * container e pelos módulos.
 *
 * @example
 * // main.js
 * import Utils from "./plugins/Utils";
 *
 * Vue.use(Utils);
 *
 * // componente.vue
 * export default {
 *  // ...
 *  methods: {
 *    salvar() {
 *      if (!this.$utils.DateTime.isISODate(this.date)) {
 *        // ...
 *      }
 *      // ...
 *    }
 *  }
 * }
 */
import { default as ArrayUtils } from "../../utils/array";
import { default as ComponentsUtils } from "../../utils/components";
import { default as DateTimeUtils } from "../../utils/datetime";
import { default as LangUtils } from "../../utils/lang";
import { create } from "../../utils/mask";
import { default as ObjectUtils } from "../../utils/object";
import { default as StringUtils } from "../../utils/string";
import { default as WebUtils } from "../../utils/web";

/**
 * @namespace Utils
 * @category Plug-ins
 * @summary Objeto/_namespace_ do _plug-in_ de utilidades.
 *
 * @requires module:utils/array
 * @requires module:utils/components
 * @requires module:utils/datetime
 * @requires module:utils/lang
 * @requires module:utils/mask.create
 * @requires module:utils/object
 * @requires module:utils/string
 * @requires module:utils/web
 */
const Utils = {
  /**
   * Objeto que fornece funções utilitárias para manipulação de _arrays_.
   * @type {object}
   * @readonly
   */
  Array: Object.freeze(Object.assign({}, ArrayUtils)),

  /**
   * Objeto que fornece funções utilitárias para manipulação dos components
   * comuns do sistema.
   * @type {object}
   * @readonly
   */
  Components: Object.freeze(Object.assign({}, ComponentsUtils)),

  /**
   * Objeto que fornece funções utilitárias para manipulação de datas.
   * @type {object}
   * @readonly
   */
  DateTime: Object.freeze(Object.assign({}, DateTimeUtils)),

  /**
   * Objeto que fornece funções utilitárias para a linguagem javascript.
   * @type {object}
   * @readonly
   */
  Lang: Object.freeze(Object.assign({}, LangUtils)),

  /**
   * Objeto que fornece funções utilitárias para criação de máscaras de
   * elementos HTML de _input_, além de máscaras pré-definidas.
   * @type {object}
   * @readonly
   */
  Mask: Object.freeze({
    create,
    CPF: create("###.###.###-##"),
    BrazilianDate: create("##/##/####"),
    ISODate: create("####-##-##")
  }),

  /**
   * Objeto que fornece funções utilitárias para manipulação de objetos.
   * @type {object}
   * @readonly
   */
  Object: Object.freeze(Object.assign({}, ObjectUtils)),

  /**
   * Objeto que fornece funções utilitárias para manipulação de _strings_.
   * @type {object}
   * @readonly
   */
  String: Object.freeze(Object.assign({}, StringUtils)),

  /**
   * Objeto que fornece funções utilitárias para manipulação de elementos HTML
   * e relacionados.
   * @type {object}
   * @readonly
   */
  Web: Object.freeze(Object.assign({}, WebUtils))
};

export default Utils;
