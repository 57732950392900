/**
 * @module services/Assunto/Assunto
 * @category Serviços
 * @summary Módulo do serviço de assuntos.
 *
 * @description
 * Expõe o objeto/_namespace_ do serviço de assuntos do sistema para
 * utilização interna.
 *
 * Para correto funcionamento, é necessário que o _plug-in_ de requisições HTTP
 * seja registrado a este serviço.
 *
 * @requires module:constants.API_URL
 * @requires module:utils/lang.isEmpty
 * @requires module:utils/lang.isNullOrUndefined
 *
 * @example
 * import AssuntoService from "./services/Assunto/Assunto";
 *
 * // Registrando o _plug-in_ de requisiçõe HTTP do container.
 * AssuntoService.registerHttp(Http);
 *
 * // Obtendo a lista de assuntos
 * AssuntoService.getAll()
 *  .then(response => {
 *    // ...
 *  })
 *  .catch(error => {
 *    // ...
 *  })
 */
import { API_URL } from "../../constants";

/**
 * @namespace AssuntoService
 * @category Serviços
 * @summary Objeto/_namespace_ do serviço de assuntos.
 */
const AssuntoService = {
  /**
   * URL de acesso à API para obtenção de assuntos.
   * @type {string}
   * @private
   * @readonly
   */
  baseUrl: `${API_URL}/assuntos`,

  /**
   * _Plug-in_ de requisições HTTP.
   * @type {module:plugins/Http/Http}
   * @private
   * @readonly
   */
  http: null,

  /**
   * Envia uma requisição para obter todos os assuntos, retornando uma Promise.
   * @returns {external:Promise}
   */
  getAll() {
    return this.http.get(`${this.baseUrl}/`);
  },

  /**
   * Envia uma requisição para obter um assunto, retornando uma Promise.
   * @param {number} id - Identificador do assunto.
   * @returns {external:Promise}
   */
  get(id) {
    return this.http.get(`${this.baseUrl}/${id}`);
  },

  /**
   * Registra o _plug-in_ de requisições HTTP no _namespace_ do serviço.
   * @param {module:plugins/Http/Http} http - _Plug-in_ de requisições HTTP.
   * @returns {module:services/Assunto/Assunto~AssuntoService} O próprio namespace.
   */
  registerHttp(http) {
    this.http = http;
    return this;
  }
};

export default AssuntoService;
