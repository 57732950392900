/**
 * @module components/base/BaseFileInput
 * @category Componentes-base
 * @summary Módulo de importação do componente-base de _file input_ do {@link external:Materialize Materialize}.
 *
 * @description
 * Expõe o componente-base de _file input_ do {@link external:Materialize Materialize} para utilização pelo container e pelos
 * módulos.
 *
 * @example <caption>Importando como plug-in</caption>
 * // main.js
 * import BaseFileInput from "./components/base/BaseFileInput";
 *
 * Vue.use(BaseFileInput);
 *
 * @example <caption>Importando diretamente em um componente</caption>
 * // componente.vue
 * // ou import BaseFileInput from "./components/base/BaseFileInput/BaseFileInput.vue";
 * import { BaseFileInput } from "./components/base/BaseFileInput";
 *
 * export default {
 *  name: // ...
 *  components: {
 *    BaseFileInput
 *  },
 *  // ...
 * }
 */
import { default as BaseFileInput } from "./BaseFileInput.vue";

/**
 * Associa o componente-base de _file input_ à instância do {@link external:Vue Vue}.
 * @param {external:Vue} Vue - Instância do {@link external:Vue Vue}.
 */
const install = Vue => Vue.component(BaseFileInput.name, BaseFileInput);

if (window && window.Vue) {
  install(window.Vue);
}

export default install;

export { BaseFileInput };
