<template>
  <router-view />
</template>

<script>
export default {
  name: "EmptyContainer",
  inheritAttrs: false
};
</script>
