<template>
  <div class="progressbar-wrapper" v-bind:class="{ container: useContainer }">
    <slot>{{ content }}</slot>
    <div class="progress">
      <div v-if="width === null" class="indeterminate"></div>
      <div v-else class="determinate" v-bind:style="`width: ${width}%;`"></div>
    </div>
  </div>
</template>

<script>
/**
 * @module components/base/BaseProgressBar/BaseProgressBar
 * @category Componentes-base
 * @summary _Single File Component_ (SFC) de barra de progresso do {@link external:Materialize Materialize}.
 *
 * @description
 * As cores do _slider_ e do fundo são obtidas automaticamente a partir da
 * configuração do ambiente.
 *
 * O _slot_ _default_ serve para inserir algum conteúdo enquanto o _slider_ é
 * exibido.
 *
 * @requires module:constants.DEFAULT_COLOR
 * @requires module:constants.DEFAULT_LUMINOSITY
 * @requires module:utils/web.createStyleElement
 * @requires module:utils/web.getMaterializeColorCode
 * @requires module:utils/web.lighten
 *
 * @vue-prop {string} [content] - Conteúdo a ser exibido pelo componente. É
 * usado como substituto quando nenhum dado é passado através do _slot_ _default_.
 * @vue-prop {boolean} [useContainer=true] - Indica se a barra de progresso
 * estará dentro de um elemento HTML `div` com classe "container" do {@link external:Materialize Materialize}.
 * @vue-prop {number} [value=null] - Quando o valor for não numérico, a barra
 * de progresso se torna como indeterminada (com _sliding_ contínuo).
 *
 * @example
 * <BaseProgressBar>Carregando...</BaseProgressBar>
 */
import { DEFAULT_COLOR, DEFAULT_LUMINOSITY } from "../../../constants";
import {
  createStyleElement,
  getMaterializeColorCode,
  lighten
} from "../../../utils/web";

export default {
  name: "BaseProgressBar",
  inheritAttrs: false,
  props: {
    useContainer: {
      type: Boolean,
      default: true
    },

    content: String,

    value: {
      type: Number,
      default: null
    }
  },
  computed: {
    width() {
      const width = window.parseFloat(this.value);

      return !window.isNaN(width) ? width : null;
    }
  },
  created() {
    this.overrideMaterializeStyle();
  },
  methods: {
    overrideMaterializeStyle() {
      const colorCode = getMaterializeColorCode(
        DEFAULT_COLOR,
        DEFAULT_LUMINOSITY
      );

      if (colorCode) {
        const CSSStyleSheet = `
        .progress {
          background-color: ${lighten(
            colorCode,
            0.4
          )}; /* lighten($progress-bar-color, 40%) */
        }

        .progress .determinate,
        .progress .indeterminate {
          background-color: ${colorCode}; /* $progress-bar-color ($secondary-color) */
        }
      `;

        createStyleElement({
          id: "stylesheet-progressbar",
          type: "text/css",
          innerHTML: CSSStyleSheet
        });
      }
    }
  }
};
</script>

<style scoped>
.contrast .progress {
  border: 1px solid white;
  background-color: white !important;
}
.contrast .progress .determinate,
.contrast .progress .indeterminate {
  background-color: black !important;
}
</style>
