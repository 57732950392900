<template>
  <article class="container">
    <header>
      <h1 class="thin center">{{ title }}</h1>
    </header>

    <div class="row-fix row">
      <div class="col s12 m8 offset-m2">
        <BaseInfoCard v-bind="infoCard" />

        <form v-if="!token" class="row" v-on:submit.prevent="onSubmit">
          <BaseTextInput
            v-bind="form.identifier"
            v-model="form.identifier.value"
          />

          <div class="col s12 buttons-row">
            <BaseButton v-bind="form.buttonRequest" />
          </div>
        </form>
        <form v-else class="row" v-on:submit.prevent="onSubmit">
          <BaseTextInput
            v-bind="form.identifier"
            v-model="form.identifier.value"
          />
          <BaseTextInput
            v-bind="form.codigoAcesso"
            v-model="form.codigoAcesso.value"
          />
          <BaseTextInput v-bind="form.password" v-model="form.password.value" />
          <BaseTextInput
            v-bind="form.confirmPassword"
            v-model="form.confirmPassword.value"
          />

          <div class="col s12 buttons-row">
            <BaseButton v-bind="form.buttonSave" />
          </div>
        </form>

        <BaseProgressBar v-if="saving" v-bind="progressBar" />
      </div>
    </div>
  </article>
</template>

<script>
import { AuthenticationService, UsuarioService } from "../services";

export default {
  name: "CadastroSenha",
  inheritAttrs: false,
  props: {
    token: String
  },
  data() {
    return {
      saving: false,
      form: {
        identifier: {
          id: "pae-senha-idenficador",
          label: "Identificador",
          autofocus: true,
          value: null
        },
        codigoAcesso: {
          id: "pae-senha-codigoAcesso",
          label: "Código de acesso",
          value: null
        },
        password: {
          id: "pae-senha-senha",
          label: "Nova senha",
          type: "password",
          value: null
        },
        confirmPassword: {
          id: "pae-senha-confirmacaosenha",
          label: "Confirmação de nova senha",
          type: "password",
          value: null
        },
        buttonRequest: {
          tag: "button",
          type: "submit",
          content: "Solicitar",
          iconName: "key"
        },
        buttonSave: {
          tag: "button",
          type: "submit",
          content: "Salvar",
          iconName: "key"
        }
      },
      progressBar: {
        useContainer: false,
        content: null
      }
    };
  },
  computed: {
    isEmpty() {
      return this.$utils.Lang.isEmpty;
    },
    title() {
      return this.token ? "Cadastro de senha" : "Solicitação de nova senha";
    },
    infoCard() {
      const infoCard = {
        title: this.title
      };

      if (this.token) {
        infoCard.content =
          "Para cadastrar nova senha, primeiramente, preencha o identificador (login) e o código de acesso informados no e-mail enviado. " +
          'Em seguida, defina uma nova senha preenchendo os campos correspondentes. Para efetivar o cadastro, clique no botão "Salvar".';
      } else {
        infoCard.content =
          "Para solicitar nova senha, preencha o identificador (login) utilizado para acesso ao sistema. " +
          "Um e-mail será enviado contendo um endereço do sistema e instruções para a definição de uma nova senha.";
      }

      return infoCard;
    }
  },
  created() {
    if (AuthenticationService.isAuthenticated()) {
      // TODO: obter a rota anterior para evitar o redirecionamento para a página inicial
      // TODO: decidir se deve redirecionar por replace de rotas
      this.$router.push({ name: "empty" });
    }
  },
  methods: {
    resetSenha() {
      const { identifier, buttonRequest } = this.form;

      if (this.isEmpty(identifier.value)) {
        this.$notification.pushError(
          "Favor preencher o identificador (login) do usuário"
        );
        return;
      }

      this.saving = buttonRequest.disabled = true;

      this.progressBar.content = "Solicitando nova senha...";

      UsuarioService.resetSenha(identifier.value)
        .then(() =>
          this.$notification.pushSuccess(
            "Solicitação de senha realizada com sucesso. Aguarde o recebimento do e-mail contendo as instruções e o endereço de definição de nova senha"
          )
        )
        .catch(error =>
          this.$notification.pushError(
            `Houve um erro ao solicitar nova senha: ${error.message}`
          )
        )
        .finally(() => (this.saving = buttonRequest.disabled = false));
    },
    updateSenha() {
      const {
        identifier,
        codigoAcesso,
        password,
        confirmPassword,
        buttonSave
      } = this.form;

      if (this.isEmpty(codigoAcesso.value)) {
        this.$notification.pushError("Favor informar o código de acesso");
        return;
      }

      if (this.isEmpty(password.value)) {
        this.$notification.pushError("Favor informar uma nova senha");
        return;
      }

      if (password.value !== confirmPassword.value) {
        this.$notification.pushError(
          'Os campos "Nova senha" e "Confirmação de nova senha" estão diferentes'
        );
        return;
      }

      this.saving = buttonSave.disabled = true;

      const params = {
        codigoAcesso: codigoAcesso.value,
        senha: password.value
      };

      this.progressBar.content = "Cadastrando nova senha...";

      UsuarioService.updateSenha(identifier.value, this.token, params)
        .then(() =>
          this.$notification.pushSuccess("Senha cadastrada com sucesso")
        )
        .catch(error =>
          this.$notification.pushError(
            `Houve um erro ao cadastrar nova senha: ${error.message}`
          )
        )
        .finally(() => (this.saving = buttonSave.disabled = false));
    },
    onSubmit() {
      if (this.token) {
        this.updateSenha();
      } else {
        this.resetSenha();
      }
    }
  }
};
</script>

<style scoped>
.buttons-row {
  justify-content: right;
}

@media screen and (max-width: 992px) {
  .buttons-row .btn {
    display: block;
    width: 100%;
    margin: 0 0 20px 0;
  }
}
</style>
