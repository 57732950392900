import { render, staticRenderFns } from "./BaseUsuario.vue?vue&type=template&id=258a5b1d&scoped=true&"
import script from "./BaseUsuario.vue?vue&type=script&lang=js&"
export * from "./BaseUsuario.vue?vue&type=script&lang=js&"
import style0 from "./BaseUsuario.vue?vue&type=style&index=0&id=258a5b1d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "258a5b1d",
  null
  
)

export default component.exports