<template>
  <BaseModal v-bind="confirmModal" v-bind:modal-options="computedModalOptions">
    <template v-slot:header><slot name="header"></slot></template>
    <template v-slot:default><slot></slot></template>
    <template v-slot:footer>
      <BaseButton class="modal-close red" v-on:click="value = false"
        >Não</BaseButton
      >
      <BaseButton class="modal-close green" v-on:click="value = true"
        >Sim</BaseButton
      >
    </template>
  </BaseModal>
</template>

<script>
/**
 * @module components/base/BaseConfirmModal/BaseConfirmModal
 * @category Componentes-base
 * @summary _Single File Component_ (SFC) de caixa de diálogo de confirmação.
 *
 * @description
 * Este componente exibe um _modal_ de caixa de diálogo de confirmação para
 * alguma operação.
 *
 * O _slot_ "header" recebe o cabeçalho do _modal_. O _slot_ _default_ recebe o
 * conteúdo do _modal_. Todos os _slots_ são repassados para o componente {@link module:components/base/BaseModal/BaseModal BaseModal}.
 *
 * @vue-prop {string} id - Vide {@link module:components/base/BaseModal/BaseModal BaseModal}.
 * @vue-prop {boolean} [hasFixedFooter] - Vide {@link module:components/base/BaseModal/BaseModal BaseModal}.
 * @vue-prop {boolean} [isBottomSheet] - Vide {@link module:components/base/BaseModal/BaseModal BaseModal}.
 * @vue-prop {object} [modalOptions] - Vide {@link module:components/base/BaseModal/BaseModal BaseModal}.
 * @vue-event {boolean} onConfirm - Emite o valor atual do componente sempre
 * que o modal for fechado. O valor do componente é alterado para `true` quando
 * o usuário clica no botão "Sim". Em caso contrário, o valor emitido é `false`.
 * Pode ser capturado pela diretiva `v-on:confirm`.
 *
 * @example
 * <BaseConfirmModal id="confirm-modal1" v-on:confirm="...">
 *  <template v-slot:header>
 *    <h3>Título</h3>
 *  </template>
 *  <template v-slot:default>
 *    <p>Confirma a operação?</p>
 *  </template>
 * </BaseConfirmModal>
 */
import BaseModal from "../BaseModal/BaseModal.vue";

export default {
  name: "BaseConfirmModal",
  components: {
    BaseModal
  },
  inheritAttrs: false,
  props: {
    id: String,
    hasFixedFooter: Boolean,
    isBottomSheet: Boolean,

    modalOptions: Object
  },
  data() {
    return {
      confirmModal: {
        id: this.id,
        hasFixedFooter: this.hasFixedFooter,
        isBottomSheet: this.isBottomSheet
      },
      value: false
    };
  },
  computed: {
    computedModalOptions() {
      const modalOptions = Object.assign({}, this.modalOptions, {});

      const originalOnOpenStart = (this.modalOptions || {}).onOpenStart;

      const originalOnCloseEnd = (this.modalOptions || {}).onCloseEnd;

      modalOptions.onOpenStart =
        originalOnOpenStart && typeof originalOnOpenStart === "function"
          ? () => {
              originalOnOpenStart();
              this.reset();
            }
          : () => this.reset();

      modalOptions.onCloseEnd =
        originalOnCloseEnd && typeof originalOnCloseEnd === "function"
          ? () => {
              originalOnCloseEnd();
              this.confirm();
            }
          : () => this.confirm();

      return modalOptions;
    }
  },
  methods: {
    confirm() {
      //this.$emit("input", this.value);
      this.$emit("confirm", this.value);
    },
    reset() {
      this.value = false;
    }
  }
};
</script>

<style scoped>
.modal-close.red {
  margin-right: 20px;
}
</style>
