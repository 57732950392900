import { isEmpty, isString } from "../lang";

const patterns = Object.freeze({
  "#": /\d/
});

const validate = function(expression) {
  if (!isString(expression)) {
    throw new Error("Expressão de máscara não textual");
  }

  if (isEmpty(expression)) {
    throw new Error("Expressão de máscara em branco");
  }

  let i = 0;
  let c = "";

  while (i < expression.length) {
    c = expression.charAt(i++);

    // Tratamento de caracteres de escape
    if (c === "\\") {
      // Se "\" é o último caracter
      if (i >= expression.length) {
        throw new Error("Expressão de máscara inválida");
      }

      c = expression.charAt(i++);

      // Se o caracter seguinte a "\" não é um caracter reservado
      if (!patterns[c] && c !== "\\") {
        throw new Error(
          "Mal uso de caractere de escape na expressão de máscara"
        );
      }
    }
  }
};

const buildPlaceholder = function(expression) {
  const placeholder = [];

  let i = 0;
  let c = "";

  while (i < expression.length) {
    c = expression.charAt(i++);

    // Tratamento de caracteres de escape
    if (c === "\\") {
      c = expression.charAt(i++);

      // Após o caractere de escape, preenche o placeholder
      // com o próprio caractere
      placeholder.push(c);
    } else {
      placeholder.push(patterns[c] ? "_" : c);
    }
  }

  return placeholder.join("");
};

const numSlots = function(expression) {
  let i = 0;
  let c = "";
  let count = 0;

  while (i < expression.length) {
    c = expression.charAt(i++);

    // Tratamento de caracteres de escape
    if (c === "\\") {
      // Apenas pula o caractere
      i++;
    } else if (patterns[c]) {
      count++;
    }
  }

  return count;
};

/**
 * Classe que representa máscaras para elementos HTML de _input_.
 * @class
 */
class Mask {
  /**
   * Cria um objeto de máscara.
   * @param {string} expression - A expressão utilizada para a máscara.
   * @throws {external:Error} Objeto de erro caso `expression` não seja válido.
   */
  constructor(expression) {
    validate(expression);

    this.expression = expression;
    this.placeholder = buildPlaceholder(expression);
    this.numSlots = numSlots(expression);
  }

  /**
   * Formata um valor de acordo com a máscara definida neste objeto.
   * @param {string} value - Texto a ser formatado.
   * @param {boolean} [fill=false] - Indica se o valor retornado deve ser completado
   * com os _placeholders_ baseados na máscara definida.
   * @returns {string}
   */
  format(value, fill = false) {
    if (!isString(value) || isEmpty(value)) {
      return fill ? this.placeholder : "";
    }

    const formatted = [];

    const expression = this.expression;

    let i = 0;
    let j = 0;
    let c = null;

    while (i < expression.length && j < value.length) {
      c = expression.charAt(i++);

      // Tratamento de caracteres de escape
      if (c === "\\") {
        c = expression.charAt(i++);

        formatted.push(c);

        // Se o caractere após o escape estiver no valor, avança
        if (c === value.charAt(j)) {
          j++;
        }
      } else {
        const pattern = patterns[c];

        // Se é caractere reservado
        if (pattern) {
          c = value.charAt(j++);

          // Se um caractere é inválido, interrompe para retornar o conteúdo preenchido até então
          if (!pattern.test(c)) {
            break;
          }
        }
        // Se o caractere estiver no valor, avança
        else if (c === value.charAt(j)) {
          j++;
        }

        formatted.push(c);
      }
    }

    if (fill) {
      const placeholder = this.placeholder;

      for (i = formatted.length; i < placeholder.length; i++) {
        formatted.push(placeholder.charAt(i));
      }
    }

    return formatted.join("");
  }

  /**
   * Remove a formatação de um valor de acordo com a máscara definida neste objeto.
   * @param {string} value - Texto a ter a formatação removida.
   * @returns {string}
   */
  unformat(value) {
    if (!isString(value) || isEmpty(value)) {
      return "";
    }

    const unformatted = [];

    const expression = this.expression;
    let i = 0;
    let j = 0;
    let c = null;

    while (i < expression.length && j < value.length) {
      c = expression.charAt(i++);

      // Tratamento de caracteres de escape
      if (c === "\\") {
        c = expression.charAt(i++);

        // Se o caractere após o escape estiver no valor, avança
        if (c === value.charAt(j)) {
          j++;
        }
      } else {
        const pattern = patterns[c];

        // Se é caractere reservado
        if (pattern) {
          c = value.charAt(j++);

          // Se um caractere é inválido, interrompe para retornar o conteúdo válido até então
          if (!pattern.test(c)) {
            break;
          }

          unformatted.push(c);
        }
        // Se o caractere estiver no valor, avança
        else if (c === value.charAt(j)) {
          j++;
        }
      }
    }

    // Completa com o resto do valor, caso ele seja maior que o esperado
    while (j < value.length) {
      unformatted.push(value.charAt(j++));
    }

    return unformatted.join("");
  }
}

export default Mask;

export { Mask };
