/**
 * @module services/Escolaridade
 * @category Serviços
 * @summary Módulo de importação do serviço de escolaridades.
 *
 * @description
 * Expõe o serviço de escolaridades do sistema para utilização interna.
 *
 * @example <caption>Importando o serviço de escolaridades como membro default do módulo</caption>
 * import EscolaridadeService from "./services/Escolaridade";
 *
 * @example <caption>Importando o serviço de escolaridades como membro do módulo</caption>
 * import { EscolaridadeService } from "./services/Escolaridade";
 */
import { default as EscolaridadeService } from "./Escolaridade";

export default EscolaridadeService;

export { EscolaridadeService };
