<template>
  <ul
    ref="tabs"
    class="tabs"
    v-bind:class="[`z-depth-${zDepth}`, { 'tabs-fixed-width': hasFixedWidth }]"
  >
    <li
      v-for="(item, index) in tabs"
      v-bind:key="index"
      class="tab"
      v-bind:class="{ disabled: item.disabled, col: !hasFixedWidth }"
    >
      <a
        v-bind:target="item.target"
        v-bind:href="`#${item.href}`"
        v-bind:title="item.title"
        >{{ item.content }}</a
      >
    </li>
  </ul>
</template>

<script>
/**
 * @module components/base/BaseTabs/BaseTabs
 * @category Componentes-base
 * @summary _Single File Component_ (SFC) de abas do {@link external:Materialize Materialize}.
 *
 * @description
 * Obrigatoriamente, a ordem dos conteúdos precisa ser a mesma do _Array_ de
 * configurações das abas passadas por parâmetro (`tabs`).
 *
 * As cores de fundo, do sublinhado e de marcação das abas são obtidas
 * automaticamente a partir da configuração do ambiente.
 *
 * O _slot_ _default_ recebe os conteúdos de cada aba.
 *
 * Para correto funcionamento, é necessário que o _framework_ {@link external:Materialize Materialize}
 * tenha sido importado, tornando seu objeto principal acessível globalmente.
 *
 * @requires module:constants.DEFAULT_COLOR
 * @requires module:constants.DEFAULT_LUMINOSITY
 * @requires module:utils/web.createStyleElement
 * @requires module:utils/web.getMaterializeColorCode
 * @requires module:utils/web.lighten
 * @requires module:utils/web.transparentize
 *
 * @vue-prop {Array<object>} tabs - _Array_ de objetos com dados das abas. Cada
 * objeto possui as propriedades `href`, `target` e `title` (pertencentes ao
 * elemento HTML `a`), `disabled` para indicar que a aba está desabilitada e
 * `content`, correspondendo ao título da aba.
 * @vue-prop {boolean} [hasFixedWidth=false] - Indica se o componente utilizará a
 * classe CSS "tabs-fixed-width" do {@link external:Materialize Materialize},
 * exibindo cada tab com mesma largura.
 * @vue-prop {number} [zDepth=1] - Indica o nível de sombra que o componente
 * terá aplicando a classe CSS "z-depth-#" do {@link external:Materialize Materialize},
 * onde "#" é o valor desta propriedade.
 * @vue-prop {object} [tabsOptions] - Opções aceitas pelo componente de abas do
 * {@link external:Materialize Materialize}.
 * @example
 * <BaseTabs
 *  v-bind:tabs="[{ href: 'dados-pessoais', content: 'Dados Pessoais'}, { href: 'dados-funcionais', content: 'Dados Funcionais'}]">
 * </BaseTabs>
 * <div id="dados-pessoais" class="col s12">
 *   ...
 * </div>
 * <div id="dados-funcionais" class="col s12">
 *   ...
 * </div>
 */
import { DEFAULT_COLOR, DEFAULT_LUMINOSITY } from "../../../constants";
import {
  createStyleElement,
  getMaterializeColorCode,
  lighten,
  transparentize
} from "../../../utils/web";

export default {
  name: "BaseTabs",
  inheritAttrs: false,
  props: {
    tabs: Array,

    hasFixedWidth: {
      type: Boolean,
      default: false
    },

    zDepth: {
      type: Number,
      default: 1,
      validator: value => value >= 0 && value <= 5
    },

    tabsOptions: Object
  },
  data() {
    return {
      instance: null
    };
  },
  beforeCreate() {
    if (!window.M) {
      window.console.error(
        "Objeto do Materialize não encontrado. Verifique se essa biblioteca foi carregada corretamente"
      );
    }
  },
  created() {
    this.overrideMaterializeStyle();
  },
  mounted() {
    if (!window.M) {
      return;
    }

    const { tabs } = this.$refs;

    this.instance = window.M.Tabs.init(tabs, this.tabsOptions);
  },
  beforeDestroy() {
    if (this.instance) {
      this.instance.destroy();
    }
  },
  methods: {
    overrideMaterializeStyle() {
      const colorCode = getMaterializeColorCode(
        DEFAULT_COLOR,
        DEFAULT_LUMINOSITY
      );

      if (colorCode) {
        const CSSStyleSheet = `
        .tabs {
          background-color: white; /* $tabs-bg-color (#fff) */
        }
        .tabs .indicator {
          background-color: ${lighten(
            colorCode,
            0.15
          )}; /* $tabs-underline-color ($primary-color-light) */
        }
        .tabs .tab a {
          color: ${colorCode}46; /* rgba($tabs-text-color, .7) */
        }
        .tabs .tab a:focus,
        .tabs .tab a:focus.active {
          background-color: ${transparentize(
            colorCode,
            0.8
          )}; /* transparentize($tabs-underline-color, .8) */
        }
        .tabs .tab a:hover,
        .tabs .tab a.active {
          color: ${colorCode}; /* $tabs-text-color ($primary-text-color) */
        }
        .tabs .tab.disabled a,
        .tabs .tab.disabled a:hover {
          color: ${colorCode}28; /* rgba($tabs-text-color, .4) */
        }
      `;

        createStyleElement({
          id: "stylesheet-tabs",
          type: "text/css",
          innerHTML: CSSStyleSheet
        });
      }
    }
  }
};
</script>

<style scoped>
.contrast .tabs {
  background-color: black !important;
}
.contrast .tabs .tab a:hover,
.contrast .tabs .tab a.active {
  background-color: white !important;
  color: black !important;
}
.contrast .tabs .indicator {
  border: 1px solid white;
}
</style>
