<template>
  <section class="section">
    <h3 class="pae-title pae-header">
      <i class="material-icons left">attachment</i>Arquivos
    </h3>

    <BaseInfoCard title="Seção de arquivos">
      <p>
        Para adicionar arquivos, clique no botão "Novo". Em seguida, clique no
        botão "Arquivo" da nova linha criada e escolha o arquivo desejado em seu
        dispositivo. Para baixar um arquivo já criado, clique no botão de
        download correspondente. Para remover um arquivo da lista, basta clicar
        no ícone de lixeira correspondente. Por fim, clique no botão "Salvar"
        para efetuar as alterações.
      </p>
    </BaseInfoCard>

    <BaseProgressBar v-if="loading" v-bind="progressBar" />
    <p v-else-if="!hasArquivos" class="flow-text center-align">
      Nenhum arquivo foi adicionado.
    </p>
    <BaseEditableCollection v-else v-bind="form.arquivoCollection" />

    <div class="right-align">
      <BaseButton
        style="margin-right: 20px"
        v-bind="form.buttonCreate"
        v-bind:disabled="readonly || disabled"
        v-on:click="add"
      />
      <BaseButton
        v-bind="form.buttonSave"
        v-bind:disabled="readonly || disabled"
        v-on:click="save"
      />
    </div>
  </section>
</template>

<script>
import { isEmpty } from "../../../utils";
import { EstandeService } from "../../../services";
import EditableSectionArquivoItem from "./EditableSectionArquivoItem.vue";

export default {
  name: "EditableSectionArquivos",
  inheritAttrs: false,
  props: {
    estandeId: {
      type: Number,
      require: true
    },
    conteudoId: {
      type: Number,
      require: true
    },

    readonly: Boolean
  },
  data() {
    return {
      disabled: false,
      loading: false,
      progressBar: {
        useContainer: false
      },
      form: {
        arquivoCollection: {
          component: EditableSectionArquivoItem,
          itemOperations: [
            {
              iconName: "file_download",
              color: "blue",
              title: "Baixar arquivo",
              onClick: this.download
            },
            {
              iconName: "delete",
              color: "red",
              title: "Excluir arquivo",
              onClick: this.delete
            }
          ],
          items: []
        },
        buttonCreate: {
          iconName: "upload_file",
          content: "Novo"
        },
        buttonSave: {
          iconName: "save",
          content: "Salvar"
        }
      },
      arquivosOriginais: []
    };
  },
  computed: {
    hasArquivos() {
      return !isEmpty(this.form.arquivoCollection.items);
    }
  },
  created() {
    this.getArquivos(this.estandeId, this.conteudoId);
  },
  methods: {
    getArquivos(idEstande, idConteudo) {
      if (!idEstande || !idConteudo) {
        return;
      }

      this.loadingArquivos = true;

      this.form.arquivoCollection.items = [];
      this.arquivosOriginais = [];

      EstandeService.getArquivosFromConteudoExtra(idEstande, idConteudo)
        .then(response => {
          const arquivos = response.data || [];

          this.form.arquivoCollection.items = arquivos.map(item => ({
            id: item.id,
            readonly: true,
            value: item
          }));

          this.arquivosOriginais = arquivos.map(item =>
            this.$utils.Object.copy(item)
          );
        })
        .catch(error =>
          this.$notification.pushError(
            `Houve um erro ao obter os arquivos: ${error.message}`
          )
        )
        .finally(() => (this.loading = false));
    },
    add() {
      if (!this.readonly) {
        this.form.arquivoCollection.items.push({
          id: null,
          readonly: this.readonly,
          value: {
            nome: null,
            tipo: null,
            tamanho: null,
            conteudo: null
          }
        });
      }
    },
    download(item, index) {
      const arquivos = this.form.arquivoCollection.items;

      if (!this.readonly && index >= 0) {
        let arquivo = arquivos[index];

        if (!arquivo || !arquivo.value || !arquivo.value.id) {
          this.$notification.pushInfo("O arquivo ainda não foi cadastrado");
          return;
        }

        arquivo = arquivo.value;

        const idEstande = this.estandeId;
        const idConteudo = this.conteudoId;
        const idArquivo = arquivo.id;

        this.$notification.pushInfo(`Baixando arquivo "${arquivo.nome}"...`);

        EstandeService.getArquivoFromConteudoExtra(
          idEstande,
          idConteudo,
          idArquivo
        )
          .then(response => {
            const a = document.createElement("a");
            a.href = response.data.conteudo;
            a.download = arquivo.nome;
            a.click();
          })
          .catch(error =>
            this.$notification.pushError(
              `Houve um erro ao baixar arquivo: ${error.message}`
            )
          );
      }
    },
    delete(item, index) {
      if (!this.readonly && index >= 0) {
        this.form.arquivoCollection.items.splice(index, 1);
      }
    },
    getPostData() {
      const postData = [];
      const { items } = this.form.arquivoCollection;

      items.forEach(item => {
        if (!item.readonly && !item.value.id) {
          postData.push({ ...item.value });
        }
      });

      return postData;
    },
    getDeleteData() {
      const deleteData = [];
      const { items } = this.form.arquivoCollection;

      this.arquivosOriginais.forEach(originalItem => {
        const isItemFound =
          items.findIndex(item => originalItem.id === item.value.id) >= 0;

        if (!isItemFound) {
          deleteData.push({ ...originalItem });
        }
      });

      return deleteData;
    },
    validate() {
      const errors = [];

      (this.form.arquivoCollection.items || []).forEach((item, index) => {
        const arquivoNo = index + 1;

        const arquivo = item.value;

        if (!arquivo) {
          errors.push(new Error(`${arquivoNo}º arquivo não foi informado`));
        } else {
          if (
            !arquivo.nome ||
            !arquivo.tipo ||
            !arquivo.tamanho ||
            (!arquivo.id && !arquivo.conteudo)
          ) {
            errors.push(
              new Error(`${arquivoNo}º arquivo possui dados inválidos`)
            );
          }
        }
      });

      return errors;
    },
    isUnique() {
      const obj = {};

      this.form.arquivoCollection.items.forEach(item => {
        const key = `${item.value.nome}-${item.value.tipo}-${item.value.tamanho}`;

        if (!obj[key]) {
          obj[key] = 0;
        }

        obj[key]++;
      });

      return Object.values(obj).findIndex(item => item > 1) === -1;
    },
    save() {
      if (this.readonly) {
        return;
      }

      const errors = this.validate();

      if (errors.length) {
        errors.forEach(item => this.$notification.pushError(item.message));
        return;
      }

      if (!this.isUnique()) {
        this.$notification.pushError("Alguns arquivos estão repetidos");
        return;
      }

      const postData = this.getPostData();
      const deleteData = this.getDeleteData();

      const arquivosOriginais = this.arquivosOriginais;
      const arquivoCollection = this.form.arquivoCollection.items;

      const idEstande = this.estandeId;
      const idConteudo = this.conteudoId;

      const saveData = [].concat(
        postData.map(item =>
          EstandeService.createArquivoToConteudoExtra(
            idEstande,
            idConteudo,
            item
          ).then(response => {
            const arquivo = response.data;

            // Atualiza os registros originais cujos correspondentes foram
            // criados com sucesso para que, em caso de erro, os mesmos não
            // sejam reenviados ao servidor desnecessariamente.
            if (arquivo && arquivo.id) {
              const a = { ...arquivo };

              arquivosOriginais.push(a);

              for (let i = 0; i < arquivoCollection.length; i++) {
                const ac = arquivoCollection[i].value;

                if (!ac.id && ac.nome === a.nome) {
                  ac.id = a.id;

                  arquivoCollection[i].readonly = true;
                  break;
                }
              }
            }

            this.$notification.pushSuccess(
              `Arquivo "${item.nome}" incluído com sucesso`
            );
          })
        ),
        deleteData.map(item =>
          EstandeService.deleteArquivoFromConteudoExtra(
            idEstande,
            idConteudo,
            item.id
          ).then(() => {
            const id = item.id;

            // Atualiza os registros originais cujos correspondentes foram
            // excluídos com sucesso para que, em caso de erro, os mesmos não
            // sejam reenviados ao servidor desnecessariamente.
            for (let i = 0; i < arquivosOriginais.length; i++) {
              if (arquivosOriginais[i].id === id) {
                arquivosOriginais.splice(i, 1);
                break;
              }
            }

            this.$notification.pushSuccess(
              `Arquivo "${item.nome}" removido com sucesso`
            );
          })
        )
      );

      if (saveData.length) {
        this.disabled = true;

        Promise.all(saveData)
          .catch(error =>
            this.$notification.pushError(
              `Houve um erro ao adicionar/excluir os arquivos: ${error.message}`
            )
          )
          .finally(() => (this.disabled = false));
      }
    }
  }
};
</script>
