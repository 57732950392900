<template>
  <article class="container">
    <header>
      <h1 class="thin center">
        {{ isCreate ? "Criação" : "Edição" }} de conteúdo extra
      </h1>
    </header>

    <BaseProgressBar v-if="loading" v-bind="progressBar" />
    <div v-else>
      <BaseInfoCard title="Seção principal">
        <p>
          Para {{ isCreate ? "criação" : "edição" }} deste conteúdo, deve-se
          preencher o campo nome e, em seguida, clique no botão "Salvar" para
          persistir os dados preenchidos.
        </p>
        <p>
          Opcionalmente, você pode definir um ou mais apresentadores para o
          conteúdo, informar, quando necessário, o endereço (URL) de um vídeo ou
          áudio realizado externamente (e.g., hospedado pelo YouTube) e arquivos
          como imagens ou material complementar.
        </p>
      </BaseInfoCard>

      <div class="row-fix row">
        <BaseTextInput
          v-bind="form.nome"
          v-bind:disabled="isReadonly"
          v-model="conteudo.nome"
        />

        <BaseTextarea
          v-bind="form.descricao"
          v-bind:disabled="isReadonly"
          v-model="conteudo.descricao"
        />

        <BaseTextInput
          v-bind="form.url"
          v-bind:disabled="isReadonly"
          v-model="conteudo.url"
        />

        <div class="col s12 buttons-row">
          <!-- <BaseButton
              v-if="!isCreate"
              v-bind="form.buttonPublicar"
              v-on:click="publicar"
            /> -->
          <BaseButton
            v-bind="form.buttonSave"
            v-bind:disabled="isReadonly || disabled"
            v-on:click="save"
          />
        </div>
      </div>

      <div v-if="mayShowSections">
        <EditableSectionApresentadores
          v-bind:readonly="isReadonly"
          v-bind:entidade-id="conteudo.id"
          v-bind:tipo-conteudo-id="tipoConteudoId"
        />

        <EditableSectionArquivos
          v-bind:readonly="isReadonly"
          v-bind:estande-id="estande.id"
          v-bind:conteudo-id="conteudo.id"
        />
      </div>

      <div v-if="mayShowSections" class="fixed-action-btn back-btn">
        <BaseButton
          class="btn-floating"
          v-bind="backButton"
          v-on:click="backButton.onClick"
        />
      </div>
      <div v-if="mayShowSections" class="fixed-action-btn top-btn">
        <BaseButton
          class="btn-floating"
          v-bind="topButton"
          v-on:click="topButton.onClick"
        />
      </div>
    </div>
  </article>
</template>

<script>
import { APP_INFO } from "../constants";
import {
  ConteudoService,
  EventoService,
  EstandeService,
  PermissaoService
} from "../services";
import EditableSectionApresentadores from "../components/Common/EditableSection/EditableSectionApresentadores.vue";
import EditableSectionArquivos from "../components/Common/EditableSection/EditableSectionArquivos.vue";

export default {
  name: "GestaoConteudoExtra",
  components: {
    EditableSectionApresentadores,
    EditableSectionArquivos
  },
  inheritAttrs: false,
  data() {
    return {
      loading: false,
      disabled: false,
      conteudo: null,
      conteudoOriginal: null,
      estande: null,
      tipoConteudoId: ConteudoService.EXTRA,
      progressBar: {
        useContainer: false
      },
      form: {
        nome: {
          id: "conteudo-nome",
          name: "conteudo-nome",
          required: true,
          label: "Nome",
          value: null
        },
        descricao: {
          id: "conteudo-descricao",
          name: "conteudo-descricao",
          label: "Descrição",
          value: null
        },
        url: {
          id: "conteudo-url",
          name: "conteudo-url",
          label: "URL (mídia externa)",
          value: null
        },
        buttonSave: {
          iconName: "save",
          content: "Salvar"
        }
      },
      backButton: {
        iconName: "arrow_back",
        size: "large",
        title: "Voltar para o estande",
        onClick: () =>
          this.$router.push({
            name: "editEstande",
            params: {
              idEvento: this.$route.params.idEvento,
              idEstande: this.$route.params.idEstande
            }
          })
      },
      topButton: {
        iconName: "arrow_upward",
        size: "large",
        title: "Ir para o topo da página",
        onClick: () => window.scroll(0, 0)
      }
    };
  },
  computed: {
    isCreate() {
      return !this.$route.params.idConteudo;
    },
    isGestorEstandes() {
      return this.$authorization.hasPermission(PermissaoService.GESTAO_ESTANDE);
    },
    isReadonly() {
      return !this.isGestorEstandes;
    },
    mayShowSections() {
      return !this.loading && this.conteudo && this.conteudo.id;
    }
  },
  created() {
    const { idEvento, idEstande, idConteudo } = this.$route.params;

    this.getConteudo(idEvento, idEstande, idConteudo);
  },
  methods: {
    getConteudo(idEvento, idEstande, idConteudo) {
      this.loading = true;

      this.conteudo = null;
      this.estande = null;

      EventoService.getEstande(idEvento, idEstande)
        .then(response => {
          this.estande = response.data;

          return EstandeService.getConteudoExtra(this.estande.id, idConteudo);
        })
        .then(response => {
          this.conteudo = response.data;

          this.conteudoOriginal = { ...this.conteudo };

          document.title = `${APP_INFO.name} - ${this.conteudo.nome}`;
        })
        .catch(error =>
          this.$notification.pushError(
            `Houve um erro ao obter os dados do conteúdo extra: ${error.message}`
          )
        )
        .finally(() => (this.loading = false));
    },
    save() {
      if (this.isReadonly) {
        return;
      }

      const errors = ConteudoService.validate(this.conteudo);

      if (errors.length) {
        errors.forEach(item => this.$notification.pushError(item.message));
        return;
      }

      const estandeId = this.estande.id;

      const { nome, descricao, url } = this.conteudo;

      const data = { nome, descricao, url, estandeId };

      this.saveConteudo(data).finally(() => {
        if (this.isCreate) {
          this.$router.push({
            name: "editEstande",
            params: {
              idEvento: this.$route.params.idEvento,
              idEstande: this.$route.params.idEstande
            }
          });
        }
      });
    },
    saveConteudo(conteudo) {
      if (this.isCreate) {
        const idEstande = this.$route.params.idEstande;

        this.conteudo = conteudo;
        this.conteudo.apresentadores = [];

        return EstandeService.createConteudoExtra(idEstande, conteudo)
          .then(response => {
            this.conteudo.id = response.data.id;

            this.conteudoOriginal = response.data;

            this.$notification.pushSuccess("Conteúdo criado com sucesso");
          })
          .catch(error =>
            this.$notification.pushError(
              `Houve um erro ao criar o conteudo: ${error.message}`
            )
          );
      } else {
        const idEstande = this.estande.id;
        const idConteudo = this.conteudo.id;

        if (
          this.conteudoOriginal.nome !== conteudo.nome ||
          this.conteudoOriginal.descricao !== conteudo.descricao ||
          this.conteudoOriginal.url !== conteudo.url
        ) {
          return EstandeService.updateConteudoExtra(
            idEstande,
            idConteudo,
            conteudo
          )
            .then(() => {
              this.$notification.pushSuccess("Conteúdo atualizado com sucesso");

              this.conteudoOriginal = { ...conteudo };
            })
            .catch(error =>
              this.$notification.pushError(
                `Houve um erro ao atualizar o conteúdo: ${error.message}`
              )
            );
        }
      }

      return Promise.resolve();
    }
  }
};
</script>

<style scoped>
.top-btn {
  bottom: 94px;
}

.buttons-row {
  justify-content: right;
}
</style>
