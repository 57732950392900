/**
 * @module services/Conteudo
 * @category Serviços
 * @summary Módulo de importação do serviço de conteúdos.
 *
 * @description
 * Expõe o serviço de conteúdos do sistema para utilização interna.
 *
 * @example <caption>Importando o serviço de conteúdos como membro default do módulo</caption>
 * import ConteudoService from "./services/Conteudo";
 *
 * @example <caption>Importando o serviço de conteúdos como membro do módulo</caption>
 * import { ConteudoService } from "./services/Conteudo";
 */
import { default as ConteudoService } from "./Conteudo";

export default ConteudoService;

export { ConteudoService };
