/**
 * @module components/base/BaseDropdown
 * @category Componentes-base
 * @summary Módulo de importação do componente-base de _dropdown_ do {@link external:Materialize Materialize}.
 *
 * @description
 * Expõe o componente-base de _dropdown_ do {@link external:Materialize Materialize} para utilização pelo container e pelos
 * módulos.
 *
 * @example <caption>Importando como plug-in</caption>
 * // main.js
 * import BaseDropdown from "./components/base/BaseDropdown";
 *
 * Vue.use(BaseDropdown);
 *
 * @example <caption>Importando diretamente em um componente</caption>
 * // componente.vue
 * // ou import BaseDropdown from "./components/base/BaseDropdown/BaseDropdown.vue";
 * import { BaseDropdown } from "./components/base/BaseDropdown";
 *
 * export default {
 *  name: // ...
 *  components: {
 *    BaseDropdown
 *  },
 *  // ...
 * }
 */
import { default as BaseDropdown } from "./BaseDropdown.vue";

/**
 * Associa o componente-base de _dropdown_ à instância do {@link external:Vue Vue}.
 * @param {external:Vue} Vue - Instância do {@link external:Vue Vue}.
 */
const install = Vue => Vue.component(BaseDropdown.name, BaseDropdown);

if (window && window.Vue) {
  install(window.Vue);
}

export default install;

export { BaseDropdown };
