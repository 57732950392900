/**
 * @module constants
 * @category Constantes
 * @summary Módulo com constantes globais do sistema.
 */

/**
 * Endereço (URL) base da API (back-end). O valor é obtido da variável de
 * ambiente `VUE_APP_API_URL` durante o _build_ do projeto.
 * @type {string}
 * @constant
 */
export const API_URL = process.env.VUE_APP_API_URL;

/**
 * Informações básicas do sistema.
 * @type {object}
 * @property {string} name - Nome do sistema.
 * @property {string} version - Versão do sistema.
 * @property {object} owner - Proprietário do sistema.
 * @property {string} owner.fullName - Nome (por extenso) do proprietário do sistema.
 * @property {string} owner.shortName - Nome (curto ou abreviado) do proprietário do sistema.
 * @constant
 */
export const APP_INFO = Object.freeze({
  name: process.env.VUE_APP_TITLE,
  version: process.env.VUE_APP_VERSION,
  buildDateTime: process.env.VUE_APP_BUILD_DATETIME,
  owner: {
    fullName: "Superintendência de Tecnologia da Informação e Comunicação",
    shortName: "TIC"
  }
});

/**
 * Classe do {@link external:Materialize Materialize} para a cor de fundo
 * padrão dos componentes do sistema. O valor é obtido da variável de
 * ambiente `VUE_APP_STYLE_DEFAULT_COLOR` durante o _build_ do projeto.
 * @type {string}
 * @constant
 */
export const DEFAULT_COLOR = process.env.VUE_APP_STYLE_DEFAULT_COLOR;

/**
 * Classe do {@link external:Materialize Materialize} para a luminosidade/nível
 * de cor de fundo padrão dos componentes do sistema. O valor é obtido da
 * variável de ambiente `VUE_APP_STYLE_DEFAULT_LUMINOSITY` durante o _build_
 * do projeto.
 * @type {string}
 * @constant
 */
export const DEFAULT_LUMINOSITY = process.env.VUE_APP_STYLE_DEFAULT_LUMINOSITY;

/**
 * Indica se o envio de dados ao servidor de _analytics_ está habilitado. O
 * valor é obtido da variável de ambiente `VUE_APP_ENABLE_ANALYTICS` durante o
 * _build_ do projeto. Essa variável aceita os valores "1", "on", "true",
 * "enabled".
 * @type {boolean}
 * @constant
 */
export const ENABLE_ANALYTICS =
  ["1", "on", "true", "enabled"].indexOf(
    (process.env.VUE_APP_ENABLE_ANALYTICS || "").trim().toLowerCase()
  ) >= 0;

/**
 * Indica se o envio de dados ao servidor de _log_ está habilitado. O valor é
 * obtido da variável de ambiente `VUE_APP_ENABLE_LOG_SERVICE` durante o
 * _build_ do projeto. Essa variável aceita os valores "1", "on", "true",
 * "enabled".
 * @type {boolean}
 * @constant
 */
export const ENABLE_LOG_SERVICE =
  ["1", "on", "true", "enabled"].indexOf(
    (process.env.VUE_APP_ENABLE_LOG_SERVICE || "").trim().toLowerCase()
  ) >= 0;

/**
 * Endereço (URL) do sistema Help InfoTIC. O valor é obtido da variável de
 * ambiente `VUE_APP_HELP_INFOTIC_URL` durante o _build_ do projeto.
 * @type {string}
 * @constant
 */
export const HELP_INFOTIC_URL = process.env.VUE_APP_HELP_INFOTIC_URL;

/**
 * Endereço (URL) base do sistema. O valor é obtido da propriedade `origin`
 * de {@link external:Location Location}
 * @type {string}
 * @constant
 */
export const HOST_URL = process.env.VUE_APP_HOST_URL || window.location.origin;

/**
 * Classe do {@link external:Materialize Materialize} para a cor de fundo
 * padrão dos _cards_ de informação e painéis do sistema.
 * @type {string}
 * @constant
 */
export const INFO_CARD_BG_COLOR = "grey";

/**
 * Classe do {@link external:Materialize Materialize} para a luminosidade/nível
 * de cor de fundo padrão dos _cards_ de informação e painéis do sistema.
 * @type {string}
 * @constant
 */
export const INFO_CARD_BG_LUMINOSITY = "darken-1";

/**
 * Classe do {@link external:Materialize Materialize} para a cor de texto
 * padrão dos _cards_ de informação e painéis do sistema.
 * @type {string}
 * @constant
 */
export const INFO_CARD_TEXT_COLOR = "white-text";

/**
 * Objeto (mapa) que associa um conjunto de classes (cor e luminosidade) da
 * paleta de cores do {@link external:Materialize Materialize} ao valor
 * hexadecimal da cor correspondente.
 * @type {object}
 * @constant
 */
export { MATERIALIZE_COLOR_PALETTE } from "./MaterializeColorPalette.js";

/**
 * Trecho do endereço (URL) de imagem placeholder quando um registro não possui
 * imagem associada.
 * @type {string}
 * @constant
 */
export const NOPIC_IMG_SRC = require("../assets/images/no-pic.png");

/**
 * Endereço (URL) base da API (back-end) do SisPessoal. O valor é obtido da
 * variável de ambiente `VUE_APP_SISPESSOAL_API_URL` durante o _build_ do
 * projeto.
 * @type {string}
 * @constant
 */
export const SISPESSOAL_API_URL = process.env.VUE_APP_SISPESSOAL_API_URL;

/**
 * Endereço (URL) do serviço de _analytics_ da UFRJ ({@link https://matomo.org Matomo}).
 * O valor é obtido da variável de ambiente `VUE_APP_UFRJ_ANALYTICS_URL` durante o
 * _build_ do projeto.
 * @type {string}
 * @constant
 */
export const UFRJ_ANALYTICS_URL = process.env.VUE_APP_UFRJ_ANALYTICS_URL;

/**
 * Identificador do sistema cadastrado no serviço de _analytics_ da UFRJ ({@link https://matomo.org Matomo}).
 * O valor é obtido da variável de ambiente `VUE_APP_UFRJ_ANALYTICS_SITE_ID`
 * durante o _build_ do projeto.
 * @type {string}
 * @constant
 */
export const UFRJ_ANALYTICS_SITE_ID =
  process.env.VUE_APP_UFRJ_ANALYTICS_SITE_ID;

/**
 * Endereço (URL) do {@link https://cas.ufrj.br/ CAS} da UFRJ. O valor é obtido da variável de
 * ambiente `VUE_APP_UFRJ_CAS_URL` durante o _build_ do projeto.
 * @type {string}
 * @constant
 */
export const UFRJ_CAS_URL = process.env.VUE_APP_UFRJ_CAS_URL;
