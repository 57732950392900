<template>
  <section class="section">
    <component v-bind:is="tag" class="pae-title pae-header">
      <i v-if="secao.icone" class="material-icons left">{{ secao.icone }}</i
      >{{ secao.nome }}
    </component>

    <BaseProgressBar v-if="loading" v-bind="progressBar" />
    <p v-else-if="!hasGaleria" class="flow-text center">
      Nenhuma imagem foi cadastrada.
    </p>
    <div v-else>
      <BaseCarousel
        v-bind:carousel-options="carouselOptions"
        v-bind:items="computedGaleria"
      />
    </div>
  </section>
</template>

<script>
import { isEmpty } from "../../../utils/lang";
import {
  EventoService,
  EstandeService,
  TipoModeloService
} from "../../../services";

export default {
  name: "SectionGaleria",
  inheritAttrs: false,
  props: {
    tag: {
      type: String,
      default: "h3",
      validator: value =>
        ["h1", "h2", "h3", "h4", "h5", "h6"].indexOf(value) !== -1
    },
    entidadeId: {
      type: Number,
      required: true
    },
    modelo: {
      type: Object,
      required: true
    },
    secao: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      carouselOptions: {
        fullWidth: true,
        indicators: true
      },
      progressBar: {
        useContainer: false
      },
      galeria: []
    };
  },
  computed: {
    service() {
      if (this.modelo.tipoModeloId === TipoModeloService.EVENTO) {
        return EventoService;
      } else if (this.modelo.tipoModeloId === TipoModeloService.ESTANDE) {
        return EstandeService;
      } else {
        this.$notification.pushError("Tipo de modelo de página inválido");
        return null;
      }
    },
    hasGaleria() {
      return !isEmpty(this.galeria);
    },
    computedGaleria() {
      if (!this.service) {
        return [];
      }

      const entidadeId = this.entidadeId;

      return this.galeria.map(item => ({
        //href: `evento-galeria-${item.id}`,
        title: item.nome,
        description: item.descricao,
        image: {
          alt: item.nome,
          title: item.nome,
          src: this.service.getImagemConteudoURLOfGaleriaItem(
            entidadeId,
            item.id
          )
        }
      }));
    }
  },
  created() {
    this.getGaleriaItens();
  },
  methods: {
    getGaleriaItens() {
      if (!this.entidadeId) {
        return;
      }

      if (!this.service) {
        return;
      }

      this.galeria = [];

      const id = this.entidadeId;

      this.loading = true;

      this.service
        .getGaleriaItens(id)
        .then(response => (this.galeria = response.data || []))
        .catch(error =>
          this.$notification.pushError(
            `Houve um erro ao obter os itens da galeria: ${error.message}`
          )
        )
        .finally(() => (this.loading = false));
    }
  }
};
</script>
