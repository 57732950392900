/**
 * @module components/base
 * @category Componentes-base
 * @summary Módulo de importação dos componentes-base do sistema.
 *
 * @description
 * Expõe os componentes-base do sistema para utilização pelo container e pelos
 * módulos.
 *
 * Este módulo funciona como um _plug-in_ do {@link external:Vue Vue}, permitindo
 * a importação de todos os componentes-base do sistema. Opcionalmente, é
 * possível importar cada componente-base individualmente.
 *
 * Cada componente-base também funciona como um _plug-in_ do {@link external:Vue Vue},
 * permitindo sua importação, associando-o ao objeto `Vue` global, ou
 * importando-o para uso interno em outro componente.
 *
 * Nos casos em que o {@link external:Vue Vue} é carregado através de um
 * elemento HTML `script`, este módulo automaticamente importa os
 * componentes-base para o objeto `Vue` global.
 *
 * @example <caption>Importando somente o BaseButton</caption>
 * import { BaseButton } from "./components/base";
 *
 * Vue.use(BaseButton);
 *
 * @example <caption>Importando todos os componentes-base</caption>
 * import components from "./components/base";
 *
 * Vue.use(components);
 */
import { default as BaseAlert } from "./BaseAlert";
import { default as BaseAutocomplete } from "./BaseAutocomplete";
import { default as BaseBreadcrumbs } from "./BaseBreadcrumbs";
import { default as BaseBadge } from "./BaseBadge";
import { default as BaseButton } from "./BaseButton";
import { default as BaseCard } from "./BaseCard";
import { default as BaseCarousel } from "./BaseCarousel";
import { default as BaseCheckbox } from "./BaseCheckbox";
import { default as BaseChip } from "./BaseChip";
import { default as BaseChips } from "./BaseChips";
import { default as BaseCollection } from "./BaseCollection";
import { default as BaseColorPicker } from "./BaseColorPicker";
import { default as BaseConfirmModal } from "./BaseConfirmModal";
import { default as BaseDatePicker } from "./BaseDatePicker";
import { default as BaseDropdown } from "./BaseDropdown";
import { default as BaseEditableCollection } from "./BaseEditableCollection";
import { default as BaseFileInput } from "./BaseFileInput";
import { default as BaseFloatingActionButton } from "./BaseFloatingActionButton";
import { default as BaseHelpInfotic } from "./BaseHelpInfotic";
import { default as BaseInfoCard } from "./BaseInfoCard";
import { default as BaseModal } from "./BaseModal";
import { default as BasePagination } from "./BasePagination";
import { default as BaseProgressBar } from "./BaseProgressBar";
import { default as BasePushpin } from "./BasePushpin";
import { default as BaseRadioButton } from "./BaseRadioButton";
import { default as BaseRouterLink } from "./BaseRouterLink";
import { default as BaseScrollspy } from "./BaseScrollspy";
import { default as BaseSearchBar } from "./BaseSearchBar";
import { default as BaseSelect } from "./BaseSelect";
import { default as BaseSwitch } from "./BaseSwitch";
import { default as BaseTable } from "./BaseTable";
import { default as BaseTableCell } from "./BaseTableCell";
import { default as BaseTableOfContents } from "./BaseTableOfContents";
import { default as BaseTableRow } from "./BaseTableRow";
import { default as BaseTabs } from "./BaseTabs";
import { default as BaseTextarea } from "./BaseTextarea";
import { default as BaseTextInput } from "./BaseTextInput";
import { default as BaseTimePicker } from "./BaseTimePicker";
import { default as BaseTypeahead } from "./BaseTypeahead";

const components = {
  BaseAlert,
  BaseAutocomplete,
  BaseBadge,
  BaseBreadcrumbs,
  BaseButton,
  BaseCard,
  BaseCarousel,
  BaseCheckbox,
  BaseChip,
  BaseChips,
  BaseCollection,
  BaseColorPicker,
  BaseConfirmModal,
  BaseDatePicker,
  BaseDropdown,
  BaseEditableCollection,
  BaseFileInput,
  BaseFloatingActionButton,
  BaseHelpInfotic,
  BaseInfoCard,
  BaseModal,
  BasePagination,
  BaseProgressBar,
  BasePushpin,
  BaseRadioButton,
  BaseRouterLink,
  BaseScrollspy,
  BaseSearchBar,
  BaseSelect,
  BaseSwitch,
  BaseTable,
  BaseTableCell,
  BaseTableOfContents,
  BaseTableRow,
  BaseTabs,
  BaseTextarea,
  BaseTextInput,
  BaseTimePicker,
  BaseTypeahead
};

/**
 * @summary Associa todos os componentes-base do sistema à instância do {@link external:Vue Vue}.
 * @param {external:Vue} Vue - Instância do {@link external:Vue Vue}.
 */
const install = function(Vue) {
  Object.values(components).forEach(component => {
    // Vue.component(component.name, components);
    Vue.use(component);
  });
};

if (window && window.Vue) {
  install(window.Vue);
}

export default install;

export { BaseAlert };
export { BaseAutocomplete };
export { BaseBreadcrumbs };
export { BaseBadge };
export { BaseButton };
export { BaseCard };
export { BaseCarousel };
export { BaseCheckbox };
export { BaseChip };
export { BaseChips };
export { BaseCollection };
export { BaseColorPicker };
export { BaseConfirmModal };
export { BaseDatePicker };
export { BaseDropdown };
export { BaseEditableCollection };
export { BaseFileInput };
export { BaseFloatingActionButton };
export { BaseHelpInfotic };
export { BaseInfoCard };
export { BaseModal };
export { BasePagination };
export { BaseProgressBar };
export { BasePushpin };
export { BaseRadioButton };
export { BaseRouterLink };
export { BaseScrollspy };
export { BaseSearchBar };
export { BaseSelect };
export { BaseSwitch };
export { BaseTable };
export { BaseTableCell };
export { BaseTableOfContents };
export { BaseTableRow };
export { BaseTabs };
export { BaseTextarea };
export { BaseTextInput };
export { BaseTimePicker };
export { BaseTypeahead };

// export { BaseAlert } from "./BaseAlert";
// export { BaseAutocomplete } from "./BaseAutocomplete";
// export { BaseBreadcrumbs } from "./BaseBreadcrumbs";
// export { BaseBadge } from "./BaseBadge";
// export { BaseButton } from "./BaseButton";
// export { BaseCard } from "./BaseCard";
// export { BaseCarousel } from "./BaseCarousel";
// export { BaseCheckbox } from "./BaseCheckbox";
// export { BaseChip } from "./BaseChip";
// export { BaseChips } from "./BaseChips";
// export { BaseCollection } from "./BaseCollection";
// export { BaseColorPicker } from "./BaseColorPicker";
// export { BaseConfirmModal } from "./BaseConfirmModal";
// export { BaseDatePicker } from "./BaseDatePicker";
// export { BaseDropdown } from "./BaseDropdown";
// export { BaseEditableCollection } from "./BaseEditableCollection";
// export { BaseFileInput } from "./BaseFileInput";
// export { BaseFloatingActionButton } from "./BaseFloatingActionButton";
// export { BaseHelpInfotic } from "./BaseHelpInfotic";
// export { BaseModal } from "./BaseModal";
// export { BaseProgressBar } from "./BaseProgressBar";
// export { BasePushpin } from "./BasePushpin";
// export { BaseRadioButton } from "./BaseRadioButton";
// export { BaseRouterLink } from "./BaseRouterLink";
// export { BaseScrollspy } from "./BaseScrollspy";
// export { BaseSearchBar } from "./BaseSearchBar";
// export { BaseSelect } from "./BaseSelect";
// export { BaseSwitch } from "./BaseSwitch";
// export { BaseTable } from "./BaseTable";
// export { BaseTableCell } from "./BaseTableCell";
// export { BaseTableOfContents } from "./BaseTableOfContents";
// export { BaseTableRow } from "./BaseTableRow";
// export { BaseTabs } from "./BaseTabs";
// export { BaseTextarea } from "./BaseTextarea";
// export { BaseTextInput } from "./BaseTextInput";
// export { BaseTimePicker } from "./BaseTimePicker";
// export { BaseTypeahead } from "./BaseTypeahead";
