/**
 * @module components/base/BaseCarousel
 * @category Componentes-base
 * @summary Módulo de importação do componente-base de _carousel_ do {@link external:Materialize Materialize}.
 *
 * @description
 * Expõe o componente-base de _carousel_ do {@link external:Materialize Materialize}
 * para utilização pelo container e pelos módulos.
 *
 * @example <caption>Importando como plug-in</caption>
 * // main.js
 * import BaseCarousel from "./components/base/BaseCarousel";
 *
 * Vue.use(BaseCarousel);
 *
 * @example <caption>Importando diretamente em um componente</caption>
 * // componente.vue
 * // ou import BaseCarousel from "./components/base/BaseCarousel/BaseCarousel.vue";
 * import { BaseCarousel } from "./components/base/BaseCarousel";
 *
 * export default {
 *  name: // ...
 *  components: {
 *    BaseCarousel
 *  },
 *  // ...
 * }
 */
import { default as BaseCarousel } from "./BaseCarousel.vue";

/**
 * Associa o componente-base de input à instância do {@link external:Vue Vue}.
 * @param {external:Vue} Vue - Instância do {@link external:Vue Vue}.
 */
const install = Vue => Vue.component(BaseCarousel.name, BaseCarousel);

if (window && window.Vue) {
  install(window.Vue);
}

export default install;

export { BaseCarousel };
