/**
 * @module services/Usuario
 * @category Serviços
 * @summary Módulo de importação do serviço de usuários.
 *
 * @description
 * Expõe o serviço de usuários do sistema para utilização interna.
 *
 * @example <caption>Importando o serviço de usuários como membro default do módulo</caption>
 * import UsuarioService from "./services/Usuario";
 *
 * @example <caption>Importando o serviço de usuários como membro do módulo</caption>
 * import { UsuarioService } from "./services/Usuario";
 */
import { default as UsuarioService } from "./Usuario";

export default UsuarioService;

export { UsuarioService };
