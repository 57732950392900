/**
 * @module services/Authentication/Authentication
 * @category Serviços
 * @summary Módulo do serviço de autenticação.
 *
 * @description
 * Expõe o objeto/_namespace_ do serviço de autenticação do sistema para
 * utilização interna.
 *
 * Para correto funcionamento, é necessário que o gerenciador de rotas seja
 * registrado a este serviço.
 *
 * @requires module:constants.API_URL
 * @requires module:constants.HOST_URL
 * @requires module:constants.UFRJ_CAS_URL
 * @requires module:utils/lang.isEmpty
 * @requires module:utils/lang.isNullOrUndefined
 * @requires module:utils/lang.isString
 * @requires module:utils/web.getQueryStringParams
 *
 * @example
 * import AuthenticationService from "./services/Authentication/Authentication";
 *
 * // Registrando o gerenciador de rotas do container.
 * AuthenticationService.registerStore(store);
 *
 * const casUrl = // ...
 *
 * // Autenticando o usuário
 * AuthenticationService.authenticate(casUrl)
 *  .then(response => {
 *    // ...
 *  })
 *  .catch(error => {
 *    // ...
 *  })
 */
import { API_URL, HOST_URL, UFRJ_CAS_URL } from "../../constants";
import { isEmpty, isNullOrUndefined, isString } from "../../utils/lang";
import { isAfter, isISODateTime } from "../../utils/datetime";
import { getQueryStringParams } from "../../utils/web";

/**
 * Instância de {@link external:Storage Storage} do tipo "sessão". Utilizado
 * para armazenar temporariamente o ticket recebido pelo {@link https://cas.ufrj.br CAS} da UFRJ.
 * @type {external:Storage}
 * @ignore
 */
const sessionStorage =
  window && window.sessionStorage ? window.sessionStorage : null;

/**
 * Constante que indica uma autenticação via {@link https://cas.ufrj.br CAS} da UFRJ.
 * @type {string}
 * @constant
 * @default
 * @ignore
 */
const AUTH_MODE_CAS = "CAS";

/**
 * Constante que indica uma autenticação via senha.
 * @type {string}
 * @constant
 * @default
 * @ignore
 */
const AUTH_MODE_PASSWORD = "SENHA";

/**
 * Variável de controle que funciona como um _lock_ para sincronismo durante a
 * tentativa de atualização da sessão quando requisições HTTP são executadas
 * com uma sessão expirada. Isso é necessário, pois, quando o front-end tenta
 * atualizar uma sessão expirada em vários requests disparados simultaneamente,
 * o Spring (no back-end) gera mensagens de erro de concorrência (via soft
 * lock) na entidade `Sessao`.
 * @type {external:Promise}
 * @default null
 * @ignore
 */
let refreshLock = null;

/**
 * @summary Autentica o usuário no sistema.
 *
 * @description
 * Para autenticações por senha, utiliza-se os parâmetros `identifier` e
 * `password` para realizar o _login_. Para autenticações via {@link http://cas.ufrj.br CAS},
 * verifica-se pela existência de um ticket, seja pela _querystring_ ou por um
 * já armazenado pelo estado global do sistema. Caso não haja, ou se o ticket
 * for igual a um ticket já utilizado previamente (armazenado no {@link external:Storage Storage}),
 * redireciona para o CAS da UFRJ (usando o parâmetro `urlCasLogin`) para
 * obter um novo ticket. Caso contrário, realiza o login do usuário.
 *
 * @param {external:Store} store - Gerenciador do estado global do sistema.
 * @param {string} apiUrl - URL da API do serviço de acesso.
 * @param {string} mode - Modo de autenticação a ser realizado. Aceita "CAS"
 * ou "SENHA".
 * @param {string} identifier - Identificador do usuário (login). Utilizado
 * apenas no modo de autenticação "SENHA".
 * @param {string} password - Senha do usuário. Utilizado apenas no modo de
 * autenticação "SENHA".
 * @param {string} casServiceParam - Parâmetro service da _querystring_ do
 * CAS da UFRJ, que serve também como identificador. Utilizado apenas no modo
 * de autenticação "CAS".
 * @param {string} urlCasLogin - URL para acessar o serviço de {@link http://cas.ufrj.br CAS}
 * da UFRJ, contendo o parâmetro `service` preenchido.
 * @returns {external:Promise} Dados de autenticação.
 */
const authenticate = function(
  store,
  apiUrl,
  mode,
  identifier,
  password,
  casServiceParam,
  urlCasLogin
) {
  const redirectTimeout = 2 ** 31 - 1;

  if (mode === AUTH_MODE_PASSWORD) {
    return login(apiUrl, identifier, password, mode).then(response => {
      setTicket(store, null);
      return handleAuthentication(store, mode, response);
    });
  } else if (mode === AUTH_MODE_CAS) {
    let ticket =
      getQueryStringParams(window.location.search).ticket || getTicket(store);

    if (ticket) {
      if (sessionStorage && sessionStorage.getItem("ticket") === ticket) {
        window.location.assign(urlCasLogin);
        // Rejeita-se com um erro sem mensagem para que ela não seja exibida durante o redirecionamento
        return new Promise((resolve, reject) => {
          window.setTimeout(() => reject(new Error()), redirectTimeout);
        });
      }

      if (!isTicketValid(ticket)) {
        return Promise.reject(new Error("Ticket (CAS) inválido"));
      }

      return login(apiUrl, casServiceParam, ticket, mode).then(response => {
        setTicket(store, ticket);

        if (sessionStorage) {
          sessionStorage.setItem("ticket", ticket);
        }

        return handleAuthentication(store, mode, response);
      });
    } else {
      window.location.assign(urlCasLogin);
      // Rejeita-se com um erro sem mensagem para que ela não seja exibida durante o redirecionamento
      return new Promise((resolve, reject) => {
        window.setTimeout(() => reject(new Error()), redirectTimeout);
      });
    }
  } else {
    return Promise.reject(new Error(`Modo inválido de authenticação: ${mode}`));
  }
};

/**
 * Limpa todos os dados de autenticação.
 * @param {external:Store} store - Gerenciador do estado global do sistema.
 * @ignore
 */
const clearAuthentication = function(store) {
  if (!store) {
    return window.console.warn(
      "Não foi registrado um store para o serviço de autenticação. O armazenamento dos dados da autenticação não será gerenciado pela aplicação"
    );
  }

  store.commit("setTicket", null);
  store.commit("setAuthenticationData", null);
  store.commit("setAuthenticationMode", null);
};

/**
 * Limpa as permissões do usuário logado.
 * @param {external:Store} store - Gerenciador do estado global do sistema.
 * @ignore
 */
const clearPermissions = function(store) {
  if (!store) {
    return window.console.warn(
      "Não foi registrado um store para o serviço de autenticação. O armazenamento dos dados da autenticação não será gerenciado pela aplicação"
    );
  }

  store.commit("clearPermissions");
};

/**
 * Faz uma requisição à API.
 * @param {string} url - URL de acesso à API.
 * @param {object} options - Objeto com as opções da requisição.
 * @returns {external:Promise} Retorno da API.
 * @ignore
 */
const fetch = function(url, options) {
  return window.fetch(url, options).then(response => {
    if (!response.ok) {
      return response
        .json()
        .then(body => Promise.reject(new Error(body.mensagem || body.message)));
    } else {
      return response;
    }
  });
};

/**
 * Obtém o ticket do {@link http://cas.ufrj.br CAS} da UFRJ armazenado no
 * estado global do sistema.
 * @param {external:Store} store - Gerenciador do estado global do sistema.
 * @returns {string} Ticket do {@link http://cas.ufrj.br CAS} da UFRJ.
 */
const getTicket = function(store) {
  return store.state.ticket;
};

/**
 * Trata a resposta com os dados de autenticação.
 * @param {external:Store} store - Gerenciador do estado global do sistema.
 * @param {string} mode - Modo de autenticação.
 * @param {external:Response} response - Resposta retornada por uma tentativa de
 * autenticação.
 * @returns {external:Promise} Dados de autenticação.
 * @ignore
 */
const handleAuthentication = function(store, mode, response) {
  return response.json().then(body => {
    if (!isAuthenticationDataValid(body)) {
      return Promise.reject(new Error("Dados de autenticação inválidos"));
    }

    const data = {
      accessToken: body.tokenAcesso,
      refreshToken: body.tokenAtualizacao,
      expiresOn: body.expiracaoAcesso,
      revokesOn: body.expiracaoSessao,
      user: {
        id: body.usuario.id,
        pessoaId: body.usuario.pessoaId
      }
    };

    setAuthenticationData(store, data);
    setAuthenticationMode(store, mode);

    return body;
  });
};

/**
 * Valida os dados de autenticação.
 * @param {object} data - Dados de autenticação.
 * @returns {boolean} `true` se os dados de autenticação são válidos.
 * `false`, caso contrário.
 * @ignore
 */
const isAuthenticationDataValid = function(data) {
  return (
    data &&
    isString(data.tokenAcesso) &&
    !isEmpty(data.tokenAcesso) &&
    isString(data.tokenAtualizacao) &&
    !isEmpty(data.tokenAtualizacao) &&
    isISODateTime(data.expiracaoAcesso) &&
    isISODateTime(data.expiracaoSessao)
  );
};

/**
 * Valida um ticket do {@link https://cas.ufrj.br CAS} da UFRJ.
 * @param {string} ticket - Ticket do {@link https://cas.ufrj.br CAS} da UFRJ.
 * @returns {boolean} `true` se o ticket é válido. `false`, caso contrário.
 * @ignore
 */
const isTicketValid = function(ticket) {
  return isString(ticket) && ticket.startsWith("ST-");
};

/**
 * Faz uma requisição de login à API.
 * @param {string} url - URL de acesso à API.
 * @param {string} identificador - Identificador do usuário.
 * @param {string} senha - Credencial do usuário. Pode ser a senha
 * definida pelo usuário ou o ticket recebido pelo {@link https://cas.ufrj.br CAS} da UFRJ.
 * @param {string} tipoCredencial - Tipo de credencial enviada. Pode ser
 * "SENHA" ou "CAS")
 * @returns {external:Promise} Retorno da API.
 * @ignore
 */
const login = function(url, identificador, senha, tipoCredencial) {
  return fetch(url, {
    method: "POST",
    mode: "cors",
    credentials: "same-origin",
    cache: "no-cache",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      identificador,
      senha,
      tipoCredencial
    })
  }).catch(error => {
    throw new Error(`Houve um erro ao realizar login: ${error.message}`);
  });
};

/**
 * Faz uma requisição de logout à API.
 * @param {string} url - URL de acesso à API.
 * @param {string} accessToken - Token de acesso do usuário logado.
 * @returns {external:Promise} Retorno da API.
 * @ignore
 */
const logout = function(url, accessToken) {
  return fetch(url, {
    method: "DELETE",
    mode: "cors",
    credentials: "same-origin",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
      Authorization: accessToken
    }
  }).catch(error => {
    throw new Error(`Houve um erro ao realizar logout: ${error.message}`);
  });
};

/**
 * Faz uma requisição de atualização de autenticação à API.
 * @param {string} url - URL de acesso à API.
 * @param {string} refreshToken - Token de atualização de acesso do usuário logado.
 * @returns {external:Promise} Retorno da API.
 * @ignore
 */
const refresh = function(url, refreshToken) {
  return fetch(`${url}/${refreshToken}`, {
    method: "PUT",
    mode: "cors",
    credentials: "same-origin",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json"
    }
  }).catch(error => {
    throw new Error(`Houve um erro ao atualizar sessão: ${error.message}`);
  });
};

/**
 * Atribui os dados de autenticação ao estado global do sistema.
 * @param {external:Store} store - Gerenciador do estado global do sistema.
 * @param {object} data - Dados de autenticação.
 * @ignore
 */
const setAuthenticationData = function(store, data) {
  if (!store) {
    return window.console.warn(
      "Não foi registrado um store para o serviço de autenticação. O armazenamento dos dados da autenticação não será gerenciado pela aplicação"
    );
  }

  store.commit("setAuthenticationData", data);
};

/**
 * Atribui o modo de autenticação ao estado global do sistema.
 * @param {external:Store} store - Gerenciador do estado global do sistema.
 * @param {string} mode - Modo de autenticação.
 * @ignore
 */
const setAuthenticationMode = function(store, mode) {
  if (!store) {
    return window.console.warn(
      "Não foi registrado um store para o serviço de autenticação. O armazenamento do modo de autenticação não será gerenciado pela aplicação"
    );
  }

  store.commit("setAuthenticationMode", mode);
};

/**
 * Atribui um ticket ao estado global do sistema.
 * @param {external:Store} store - Gerenciador do estado global do sistema.
 * @param {string} ticket - Ticket recebido pelo {@link https://cas.ufrj.br CAS} da UFRJ.
 * @ignore
 */
const setTicket = function(store, ticket) {
  if (!store) {
    return window.console.warn(
      "Não foi registrado um store para o serviço de autenticação. O armazenamento dos dados da autenticação não será gerenciado pela aplicação"
    );
  }

  store.commit("setTicket", ticket);
};

/**
 * @namespace AuthenticationService
 * @category Serviços
 * @summary Objeto/_namespace_ do serviço de autenticação.
 */
const AuthenticationService = {
  /**
   * URL de acesso à API para autenticação.
   * @type {string}
   * @private
   * @readonly
   */
  baseUrl: `${API_URL}/sessoes`,

  /**
   * Gerenciador de rotas do sistema.
   * @type {external:VueRouter}
   * @private
   * @readonly
   */
  router: null,

  /**
   * Gerenciador do estado global do sistema.
   * @type {external:Store}
   * @private
   * @readonly
   */
  store: null,

  /**
   * Limite de tempo adicional (em milissegundos) para identificar se o token
   * de acesso ou a sessão de um usuário expirou ou não.
   * @type {number}
   * @private
   * @readonly
   * @default
   */
  timeThreshold: 2000,

  /**
   * Constante que indica uma autenticação via {@link https://cas.ufrj.br CAS} da UFRJ.
   * @type {number}
   * @readonly
   * @default "CAS"
   */
  AUTH_MODE_CAS,

  /**
   * Constante que indica uma autenticação via senha.
   * @type {number}
   * @readonly
   * @default "SENHA"
   */
  AUTH_MODE_PASSWORD,

  /**
   * @summary Autentica o usuário no sistema via {@link http://cas.ufrj.br CAS} da UFRJ.
   *
   * @description
   * Inicialmente, verifica-se pela existência de um token de atualização de
   * sessão. Caso haja, realiza a atualização da sessão. Caso contrário,
   * verifica-se pela existência de um ticket do {@link http://cas.ufrj.br CAS} da UFRJ,
   * seja pela _querystring_ ou já armazenado pelo estado global do sistema.
   * Caso não haja, ou se o ticket for igual a um ticket já utilizado
   * previamente (armazenado no {@link external:Storage Storage}), redireciona
   * para o CAS da UFRJ (usando o parâmetro `casServiceParam`) para obter um
   * novo ticket. Caso contrário, realiza o login do usuário.
   *
   * @param {string} casServiceParam - Parâmetro service da _querystring_ do
   * {@link http://cas.ufrj.br CAS} da UFRJ. Utilizado apenas no modo de
   * autenticação "CAS".
   * @returns {external:Promise} Dados de autenticação.
   */
  authenticateByCAS(casServiceParam) {
    return authenticate(
      this.store,
      this.baseUrl,
      this.AUTH_MODE_CAS,
      null,
      null,
      casServiceParam,
      this.getUrlCasLogin(casServiceParam)
    );
  },

  /**
   * @summary Autentica o usuário no sistema via senha.
   *
   * @description
   * Inicialmente, verifica-se pela existência de um token de atualização de
   * sessão. Caso haja, realiza a atualização da sessão. Caso contrário,
   * utiliza os parâmetros `identifier` e `password` para realizar o _login_.
   *
   * @param {string} identifier - Identificador do usuário (login).
   * @param {string} password - Senha do usuário.
   * @returns {external:Promise} Dados de autenticação.
   */
  authenticateByPassword(identifier, password) {
    return authenticate(
      this.store,
      this.baseUrl,
      this.AUTH_MODE_PASSWORD,
      identifier,
      password,
      null,
      null
    );
  },

  /**
   * Realiza o logout e remove a autenticação do usuário no sistema.
   * @returns {external:Promise}
   */
  deauthenticate() {
    const mode = this.getAuthenticationMode();
    const { accessToken } = this.getAuthenticationData() || {};

    let deauthPromise = null;

    if (!this.isAuthenticated()) {
      deauthPromise = Promise.resolve();
    } else {
      deauthPromise = logout(this.baseUrl, accessToken);
    }

    return deauthPromise.then(() => {
      clearAuthentication(this.store);
      clearPermissions(this.store);

      if (mode === this.AUTH_MODE_CAS) {
        window.location.assign(this.getUrlCasLogout());
      } else if (mode === this.AUTH_MODE_PASSWORD) {
        this.router.push({ name: "login" });
      } else {
        // Não deveria entrar nessa condição
        this.router.push({ name: "empty" });
      }
    });
  },

  /**
   * Obtém os dados de autenticação armazenados no estado global do sistema.
   * @returns {object} Dados de autenticação.
   */
  getAuthenticationData() {
    const { authenticationData } = this.store.state;

    if (isNullOrUndefined(authenticationData)) {
      return null;
    }

    return Object.assign({}, this.store.state.authenticationData);
  },

  /**
   * Obtém o modo de autenticação utilizado armazenado no estado global do sistema.
   * @returns {string} Modo de autenticação.
   */
  getAuthenticationMode() {
    return this.store.state.authenticationMode;
  },

  /**
   * Obtém o ticket do {@link http://cas.ufrj.br CAS} da UFRJ armazenado no
   * estado global do sistema.
   * @returns {string} Ticket do {@link http://cas.ufrj.br CAS} da UFRJ.
   */
  getTicket() {
    return this.getTicket(this.store);
  },

  /**
   * Obtém a URL de login do {@link http://cas.ufrj.br CAS} da UFRJ.
   * @param {string} service - Parâmetro `service` aceito pelo {@link http://cas.ufrj.br CAS} da UFRJ.
   * @returns {string}
   */
  getUrlCasLogin(service) {
    let encodedService = window.encodeURIComponent(
      service ? service : HOST_URL
    );

    return `${UFRJ_CAS_URL}/login?service=${encodedService}`;
  },

  /**
   * Obtém a URL de logout do {@link http://cas.ufrj.br CAS} da UFRJ.
   * @returns {string}
   */
  getUrlCasLogout() {
    return `${UFRJ_CAS_URL}/logout`;
  },

  /**
   * Indica se o usuário está autenticado ou não.
   * @returns {boolean} `true` se o usuário está autenticado. `false`, caso
   * contrário.
   */
  isAuthenticated() {
    const { expiresOn } = this.getAuthenticationData() || {};

    return expiresOn && !isAfter(Date.now() + this.timeThreshold, expiresOn);
  },

  /**
   * Indica se a sessão ainda pode ser atualizada ou não.
   * @returns {boolean} `true` se a sessão pode ser atualizada. `false`, caso
   * contrário.
   */
  isSessionValid() {
    const { revokesOn } = this.getAuthenticationData() || {};

    return revokesOn && !isAfter(Date.now() + this.timeThreshold, revokesOn);
  },

  /**
   * Atualiza a sessão previamente autenticada. Apenas uma requisição pode ser
   * efetuada por vez. Caso múltiplas requisições sejam feitas, é retornada a
   * `{@link external:Promise Promise}` correspondente à requisição previamente
   * feita.
   * @returns {external:Promise} Dados do servidor responsável pelo usuário
   * logado.
   * @throws {external:Error} Objeto de erro caso o token de atualização não
   * seja encontrado.
   */
  refresh() {
    const { mode, refreshToken } = this.getAuthenticationData() || {};

    if (refreshToken) {
      if (!refreshLock) {
        refreshLock = refresh(this.baseUrl, refreshToken)
          .then(response => handleAuthentication(this.store, mode, response))
          .finally(() => (refreshLock = null));
      }

      return refreshLock;
    } else {
      return Promise.reject(
        new Error("Token de atualização de sessão não encontrado")
      );
    }
  },

  /**
   * Registra o gerenciador de rotas do sistema no _namespace_ do serviço.
   * @param {external:VueRouter} router - Gerenciador de rotas do sistema.
   * @returns {module:services/Authentication/Authentication~AuthenticationService} O próprio namespace.
   */
  registerRouter(router) {
    this.router = router;
    return this;
  },

  /**
   * Registra o gerenciador do estado global do sistema no _namespace_ do serviço.
   * @param {external:Store} store - Gerenciador do estado global do sistema.
   * @returns {module:services/Authentication/Authentication~AuthenticationService} O próprio namespace.
   */
  registerStore(store) {
    this.store = store;
    return this;
  }
};

export default AuthenticationService;
