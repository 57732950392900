<template>
  <header>
    <TheHeaderNavbar />
    <TheHeaderSidenav />
  </header>
</template>

<script>
import TheHeaderNavbar from "./TheHeaderNavbar.vue";
import TheHeaderSidenav from "./TheHeaderSidenav.vue";

export default {
  name: "TheHeader",
  components: {
    TheHeaderNavbar,
    TheHeaderSidenav
  },
  inheritAttrs: false
};
</script>
