/**
 * @module services/Modelo/Modelo
 * @category Serviços
 * @summary Módulo do serviço de modelo/template.
 *
 * @description
 * Expõe o objeto/_namespace_ do serviço de modelo/template do sistema para
 * utilização interna.
 *
 * Para correto funcionamento, é necessário que o _plug-in_ de requisições HTTP
 * seja registrado a este serviço.
 *
 * @requires module:constants.API_URL
 * @requires module:utils/lang.isEmpty
 * @requires module:utils/lang.isNullOrUndefined
 *
 * @example
 * import ModeloService from "./services/Modelo/Modelo";
 *
 * // Registrando o _plug-in_ de requisiçõe HTTP do container.
 * ModeloService.registerHttp(Http);
 *
 * const params = // ...
 *
 * // Obtendo a lista de modelos/templates
 * ModeloService.search(params)
 *  .then(response => {
 *    // ...
 *  })
 *  .catch(error => {
 *    // ...
 *  })
 */
import { API_URL } from "../../constants";
import { isEmpty, isNullOrUndefined } from "../../utils/lang";

/**
 * Encontra o modelo de seção de um modelo a partir de seu identificador.
 * @function
 * @ignore
 * @param {object} modelo - Modelo.
 * @param {number} id - Identificador do modelo de seção.
 * @returns {object}
 */
const findModeloSecao = function(modeloSecoes, id) {
  if (!id || isEmpty(modeloSecoes)) {
    return null;
  }

  let modeloSecao = modeloSecoes.find(item => item.id === id);

  if (!modeloSecao) {
    for (let i = 0; i < modeloSecoes.length; i++) {
      modeloSecao = findModeloSecao(modeloSecoes[i].secoes, id);

      if (modeloSecao) {
        break;
      }
    }
  }

  return modeloSecao;
};

/**
 * @namespace ModeloService
 * @category Serviços
 * @summary Objeto/_namespace_ do serviço de modelos/templates.
 */
const ModeloService = {
  /**
   * URL de acesso à API para obtenção de modelos.
   * @type {string}
   * @private
   * @readonly
   */
  baseUrl: `${API_URL}/modelos`,

  /**
   * _Plug-in_ de requisições HTTP.
   * @type {module:plugins/Http/Http}
   * @private
   * @readonly
   */
  http: null,

  /**
   * Encontra o modelo de seção de um modelo a partir de seu identificador.
   * @param {object} modelo - Modelo.
   * @param {number} id - Identificador do modelo de seção.
   * @returns {object}
   */
  findModeloSecao(modelo, id) {
    if (!id || isEmpty(modelo) || isEmpty(modelo.secoes)) {
      return null;
    }

    return findModeloSecao(modelo.secoes, id);
  },

  /**
   * Envia uma requisição para obter todos os modelos/templates, retornando uma Promise.
   * @param {object} params - Parâmetros de busca.
   * @returns {external:Promise}
   */
  search(params) {
    let querystring = [];

    if (!isEmpty(params)) {
      if (!isNullOrUndefined(params.tipoModelo)) {
        querystring.push(`tipoModeloId=${params.tipoModelo}`);
      }

      if (!isNullOrUndefined(params.page)) {
        querystring.push(`page=${params.page}`);
      }

      if (!isNullOrUndefined(params.size)) {
        querystring.push(`size=${params.size}`);
      }
    }

    querystring = querystring.length ? "?" + querystring.join("&") : "";

    return this.http.get(`${this.baseUrl}${querystring}`);
  },

  /**
   * Envia uma requisição para obter um modelo, retornando uma Promise.
   * @param {number} id - Identificador do modelo.
   * @param {object} params - Parâmetros de busca.
   * @returns {external:Promise}
   */
  get(id, params) {
    let querystring = [];

    if (!isEmpty(params)) {
      if (!isNullOrUndefined(params.deep)) {
        querystring.push(`deep=${params.deep}`);
      }
    }

    querystring = querystring.length ? "?" + querystring.join("&") : "";

    return this.http.get(`${this.baseUrl}/${id}${querystring}`);
  },

  /**
   * Registra o _plug-in_ de requisições HTTP no _namespace_ do serviço.
   * @param {module:plugins/Http/Http} http - _Plug-in_ de requisições HTTP.
   * @returns {module:services/Modelo/Modelo~ModeloService} O próprio namespace.
   */
  registerHttp(http) {
    this.http = http;
    return this;
  }
};

export default ModeloService;
