<template>
  <div ref="chips" v-bind:id="id" class="chips"></div>
</template>

<script>
/**
 * @module components/base/BaseChips/BaseChips
 * @category Componentes-base
 * @summary _Single File Component_ (SFC) de _chips_ do {@link external:Materialize Materialize}.
 *
 * @description
 * As alterações realizadas nos _chips_ podem ser obtidas através das opções
 * aceitas pela _prop_ `chipOptions`, através das propriedades `onChipAdd` e
 * `onChipDelete`, por exemplo.
 *
 * As cores de borda do _input_ e do _autocomplete_, e as de seleção do _chip_
 * são obtidas automaticamente a partir da configuração do ambiente.
 *
 * O _slot_ _default_ recebe o conteúdo/_label_ do _chip_.
 *
 * Atributos HTML de acessibilidade, e.g., `title`, podem ser usados
 * diretamente no componente.
 *
 * @requires module:constants.DEFAULT_COLOR
 * @requires module:constants.DEFAULT_LUMINOSITY
 * @requires module:utils/web.createStyleElement
 * @requires module:utils/web.getMaterializeColorCode
 *
 * @vue-prop {string} [id] - Atributo `id` do elemento HTML utilizado
 * internamente pelo componente.
 * @vue-prop {boolean} [isCloseable=true] - Indica se o componente exibirá o
 * ícone de fechar.
 * @vue-prop {object} [chipOptions] - Opções aceitas pelo `chips`
 * do {@link external:Materialize Materialize}.
 *
 * @example
 * <BaseChips color="red" text-color="red" text-luminosity="darken-3" />...</BaseChips>
 */
import { DEFAULT_COLOR, DEFAULT_LUMINOSITY } from "../../../constants";
import {
  createStyleElement,
  getMaterializeColorCode
} from "../../../utils/web";

export default {
  name: "BaseChips",
  inheritAttrs: false,
  props: {
    id: String,

    isChipCloseable: {
      type: Boolean,
      default: true
    },

    chipsOptions: Object
  },
  data() {
    return {
      instance: null
    };
  },
  beforeCreate() {
    if (!window.M) {
      window.console.error(
        "Objeto do Materialize não encontrado. Verifique se essa biblioteca foi carregada corretamente"
      );
    }
  },
  created() {
    this.overrideMaterializeStyle();
  },
  mounted() {
    if (!window.M) {
      return;
    }

    const { chips } = this.$refs;

    this.instance = window.M.Chips.init(chips, this.chipsOptions);
  },
  beforeDestroy() {
    if (this.instance) {
      this.instance.destroy();
    }
  },
  methods: {
    overrideMaterializeStyle() {
      const colorCode = getMaterializeColorCode(
        DEFAULT_COLOR,
        DEFAULT_LUMINOSITY
      );

      if (colorCode) {
        let CSSStyleSheet = `
          .chips.focus {
            border-bottom-color: ${colorCode};
            box-shadow: 0 1px 0 0 ${colorCode}; /* A regra inteira foi reescrita, pois ainda não existe uma regra do tipo \`box-shadow-color\` */
          }
          .chips .chip:focus {
            background-color: ${colorCode};
          }
        `;

        createStyleElement({
          id: "stylesheet-chips",
          type: "text/css",
          innerHTML: CSSStyleSheet
        });

        CSSStyleSheet = `
        .dropdown-content li > a,
        .dropdown-content li > span {
          color: ${colorCode};
        }
      `;

        // Utiliza-se o mesmo `id` de stylesheet do BaseDropdown
        createStyleElement({
          id: "stylesheet-dropdown",
          type: "text/css",
          innerHTML: CSSStyleSheet
        });
      }
    }
  }
};
</script>

<style scoped>
.contrast .chips /deep/ input {
  color: white !important;
}
.contrast .chips /deep/ .chip:focus,
.contrast .chips /deep/ .chip:focus i.material-icons {
  background-color: white !important;
  color: black !important;
}
.contrast .chips /deep/ .dropdown-content li:hover,
.contrast .chips /deep/ .dropdown-content li.active {
  background-color: white !important;
  color: black !important;
}
.contrast .chips /deep/ .dropdown-content li:hover a,
.contrast .chips /deep/ .dropdown-content li.active a,
.contrast .chips /deep/ .dropdown-content li:hover span,
.contrast .chips /deep/ .dropdown-content li.active span {
  color: black !important;
}
</style>
