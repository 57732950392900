/**
 * @module services/Accessibility
 * @category Serviços
 * @summary Módulo de importação do serviço de acessibilidade.
 *
 * @description
 * Expõe o serviço de acessibilidade do sistema para utilização interna.
 *
 * @example <caption>Importando o serviço de acessibilidade como membro default do módulo</caption>
 * import AccessibilityService from "./services/Accessibility";
 *
 * @example <caption>Importando o serviço de acessibilidade como membro do módulo</caption>
 * import { AccessibilityService } from "./services/Accessibility";
 */
import { default as AccessibilityService } from "./Accessibility";

export default AccessibilityService;

export { AccessibilityService };
