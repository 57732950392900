/**
 * @module services/Evento/Evento
 * @category Serviços
 * @summary Módulo do serviço de eventos.
 *
 * @description
 * Expõe o objeto/_namespace_ do serviço de eventos do sistema para
 * utilização interna.
 *
 * Para correto funcionamento, é necessário que o _plug-in_ de requisições HTTP
 * seja registrado a este serviço.
 *
 * @requires module:constants.API_URL
 * @requires module:utils/lang.isEmpty
 * @requires module:utils/lang.isNullOrUndefined
 * @requires module:utils/datetime.isISODate
 * @requires module:utils/web.createStyleElement
 * @requires module:utils/web.lighten
 * @requires module:utils/web.transparentize
 *
 * @example
 * import EventoService from "./services/Evento/Evento";
 *
 * // Registrando o _plug-in_ de requisiçõe HTTP do container.
 * EventoService.registerHttp(Http);
 *
 * const params = // ...
 *
 * // Obtendo a lista de eventos
 * EventoService.search(params)
 *  .then(response => {
 *    // ...
 *  })
 *  .catch(error => {
 *    // ...
 *  })
 */
import { API_URL } from "../../constants";
import { isEmpty, isNullOrUndefined } from "../../utils/lang";
import { isAfter, isISODate } from "../../utils/datetime";
import { createStyleElement, lighten, transparentize } from "../../utils/web";

/**
 * @namespace EventoService
 * @category Serviços
 * @summary Objeto/_namespace_ do serviço de eventos.
 */
const EventoService = {
  /**
   * URL de acesso à API para obtenção de eventos.
   * @type {string}
   * @private
   * @readonly
   */
  baseUrl: `${API_URL}/eventos`,

  /**
   * _Plug-in_ de requisições HTTP.
   * @type {module:plugins/Http/Http}
   * @private
   * @readonly
   */
  http: null,

  /**
   * Restaura as cores originais (definidas pelo sistema) dos elementos que
   * possuem suas cores alteradas quando um evento é carregado.
   */
  resetPageStyle() {
    const oldStyle = document.getElementById("stylesheet-evento");

    if (oldStyle) {
      document.head.removeChild(oldStyle);
    }
  },

  /**
   * Realiza a validação de um evento.
   * @param {object} estilo - Objeto com os dados de estilo para as páginas de
   * um evento.
   */
  setPageStyle(estilo) {
    if (!estilo || !estilo.ativo) {
      return;
    }

    const colorCode = estilo.cor;

    // TODO: o ideal é que os componentes-base recebam as cores
    const CSSStyleSheet = `
      #pae-wrapper header:first-of-type nav:first-of-type {
        background-color: ${colorCode} !important;
      }
      #pae-wrapper footer:first-of-type {
        background-color: ${colorCode} !important;
      }

      .pae-title {
        color: ${colorCode};
      }

      .btn {
        background-color: ${colorCode} !important;
      }

      .fixed-action-btn .btn-floating {
        background-color: ${colorCode};
      }

      .badges-container .badge.new {
        background-color: ${colorCode} !important;
      }

      .pagination li.active {
        background-color: ${colorCode} !important;
      }

      .tabs .indicator {
        background-color: ${lighten(
          colorCode,
          0.15
        )} !important; /* $tabs-underline-color ($primary-color-light) */
      }
      .tabs .tab a {
        color: ${colorCode}46 !important; /* rgba($tabs-text-color, .7) */
      }
      .tabs .tab a:focus,
      .tabs .tab a:focus.active {
        background-color: ${transparentize(
          colorCode,
          0.8
        )} !important; /* transparentize($tabs-underline-color, .8) */
      }
      .tabs .tab a:hover,
      .tabs .tab a.active {
        color: ${colorCode} !important; /* $tabs-text-color ($primary-text-color) */
      }
      .tabs .tab.disabled a,
      .tabs .tab.disabled a:hover {
        color: ${colorCode}28 !important; /* rgba($tabs-text-color, .4) */
      }
    `;

    const oldStyle = document.getElementById("stylesheet-evento");

    if (oldStyle) {
      document.head.removeChild(oldStyle);
    }

    createStyleElement({
      id: "stylesheet-evento",
      type: "text/css",
      innerHTML: CSSStyleSheet
    });
  },

  /**
   * Realiza a validação de um evento.
   * @param {object} val - Evento a ser validado.
   * @returns {Array<Error>} - _Array_ de possíveis erros.
   */
  validate(val) {
    const errors = [];

    if (isEmpty(val)) {
      errors.push(new Error("Evento não preenchido"));
    } else {
      if (isEmpty(val.nome)) {
        errors.push(new Error("Nome do evento inválido"));
      }

      const { inicio, termino } = val;
      const oneNull = (inicio && !termino) || (!inicio && termino);
      const noneNull = inicio && termino;

      if (oneNull) {
        errors.push(
          new Error("Data de início ou de término do evento em branco")
        );
      } else if (noneNull) {
        let isInicioValid = false;
        let isTerminoValid = false;

        try {
          if (!isISODate(inicio)) {
            errors.push(new Error("Data início do evento inválida"));
          } else {
            isInicioValid = true;
          }
        } catch (e) {
          errors.push(new Error("Data início do evento inválida"));
        }

        try {
          if (!isISODate(termino)) {
            errors.push(new Error("Data término do evento inválida"));
          } else {
            isTerminoValid = true;
          }
        } catch (e) {
          errors.push(new Error("Data término do evento inválida"));
        }

        if (isInicioValid && isTerminoValid && isAfter(inicio, termino)) {
          errors.push(
            new Error("Datas de início e de término do evento conflitantes")
          );
        }
      }
    }

    return errors;
  },

  /**
   * Envia uma requisição para obter uma lista de eventos baseada em parâmetros
   * de busca, retornando uma Promise.
   * @param {object} params - Parâmetros de busca.
   * @returns {external:Promise}
   */
  search(params) {
    let querystring = [];

    if (!isEmpty(params)) {
      if (!isEmpty(params.nome)) {
        querystring.push(`nome=${params.nome}`);
      }

      if (!isEmpty(params.inicio)) {
        querystring.push(`inicio=${params.inicio}`);
      }

      if (!isEmpty(params.termino)) {
        querystring.push(`termino=${params.termino}`);
      }

      if (params.continuo === true) {
        querystring.push(`continuo=true`);
      }

      if (params.online === true) {
        querystring.push(`online=true`);
      }

      if (!isEmpty(params.assuntos)) {
        params.assuntos.forEach(item => querystring.push(`assuntoId=${item}`));
      }

      if (!isNullOrUndefined(params.status)) {
        querystring.push(`statusId=${params.status}`);
      }

      if (!isNullOrUndefined(params.page)) {
        querystring.push(`page=${params.page}`);
      }

      if (!isNullOrUndefined(params.size)) {
        querystring.push(`size=${params.size}`);
      }
    }

    querystring = querystring.length ? "?" + querystring.join("&") : "";

    return this.http.get(`${this.baseUrl}${querystring}`);
  },

  /**
   * Envia uma requisição para obter um evento, retornando uma Promise.
   * @param {number|string} idOrSlug - Identificador numérico (id) ou textual
   * (slug) do evento.
   * @returns {external:Promise}
   */
  get(idOrSlug) {
    return this.http.get(`${this.baseUrl}/${idOrSlug}`);
  },

  /**
   * Envia uma requisição para definir o _status_ de um evento, retornando uma
   * Promise.
   * @param {number} idEvento - Identificador do evento.
   * @param {number} idStatus - Identificador do _status_.
   * @returns {external:Promise}
   */
  setStatus(idEvento, idStatus) {
    return this.http.put(`${this.baseUrl}/${idEvento}/status/${idStatus}`);
  },

  /**
   * Envia uma requisição para criar um evento, retornando uma Promise.
   * @param {object} evento - Dados do evento.
   * @returns {external:Promise}
   */
  create(evento) {
    return this.http.post(`${this.baseUrl}`, evento);
  },

  /**
   * Envia uma requisição para atualizar um evento, retornando uma Promise.
   * @param {number} id - Identificador do evento.
   * @param {object} evento - Dados do evento.
   * @returns {external:Promise}
   */
  update(id, evento) {
    return this.http.put(`${this.baseUrl}/${id}`, evento);
  },

  /**
   * Envia uma requisição para obter seções de um evento, retornando uma
   * Promise.
   * @param {number} id - Identificador do evento.
   * @returns {external:Promise}
   */
  getSecoes(id) {
    return this.http.get(`${this.baseUrl}/${id}/secoes`);
  },

  /**
   * Envia uma requisição para obter o estilo de um evento, retornando uma
   * Promise.
   * @param {number} id - Identificador do evento.
   * @param {object} [options={}] - Opções aceitas pelo {@link external:axios axios}.
   * @returns {external:Promise}
   */
  getEstilo(id, options = {}) {
    return this.http.get(`${this.baseUrl}/${id}/estilo`, options);
  },

  /**
   * Envia uma requisição para definir o estilo de um evento, retornando uma
   * Promise.
   * @param {number} id - Identificador do evento.
   * @param {object} estilo - Dados do estilo.
   * @returns {external:Promise}
   */
  setEstilo(id, estilo) {
    return this.http.put(`${this.baseUrl}/${id}/estilo`, estilo);
  },

  /**
   * Envia uma requisição para obter o _banner_ de um evento, retornando uma
   * Promise.
   * @param {number} id - Identificador do evento.
   * @param {object} [options={}] - Opções aceitas pelo {@link external:axios axios}.
   * @returns {external:Promise}
   */
  getBanner(id, options = {}) {
    return this.http.get(`${this.baseUrl}/${id}/banner`, options);
  },

  /**
   * Constrói a URL de obtenção do conteúdo do _banner_ de um evento.
   * @param {number} id - Identificador do evento.
   * @returns {string}
   */
  getBannerConteudoURL(id) {
    return `${this.baseUrl}/${id}/banner/conteudo`;
  },

  /**
   * Envia uma requisição para definir o _banner_ de um evento, retornando uma
   * Promise.
   * @param {number} id - Identificador do evento.
   * @param {object} banner - Dados do _banner_.
   * @returns {external:Promise}
   */
  setBanner(id, banner) {
    return this.http.put(`${this.baseUrl}/${id}/banner`, banner);
  },

  /**
   * Envia uma requisição para excluir o _banner_ de um evento, retornando uma
   * Promise.
   * @param {number} id - Identificador do evento.
   * @returns {external:Promise}
   */
  deleteBanner(id) {
    return this.http.delete(`${this.baseUrl}/${id}/banner`);
  },

  /**
   * Envia uma requisição para obter os assuntos de um evento, retornando uma
   * Promise.
   * @param {number} id - Identificador do evento.
   * @returns {external:Promise}
   */
  getAssuntos(id) {
    return this.http.get(`${this.baseUrl}/${id}/assuntos`);
  },

  /**
   * Envia uma requisição para associar um assunto ao evento, retornando uma
   * Promise.
   * @param {number} idEvento - Identificador do evento.
   * @param {number} idAssunto - Identificador do assunto.
   * @returns {external:Promise}
   */
  addAssunto(idEvento, idAssunto) {
    return this.http.put(`${this.baseUrl}/${idEvento}/assuntos/${idAssunto}`);
  },

  /**
   * Envia uma requisição para desassociar um assunto ao evento, retornando uma
   * Promise.
   * @param {number} idEvento - Identificador do evento.
   * @param {number} idAssunto - Identificador do assunto.
   * @returns {external:Promise}
   */
  removeAssunto(idEvento, idAssunto) {
    return this.http.delete(
      `${this.baseUrl}/${idEvento}/assuntos/${idAssunto}`
    );
  },

  /**
   * Envia uma requisição para obter o _banner_ de apoiadores de um evento,
   * retornando uma Promise.
   * @param {number} id - Identificador do evento.
   * @param {object} [options={}] - Opções aceitas pelo {@link external:axios axios}.
   * @returns {external:Promise}
   */
  getApoiadores(id, options = {}) {
    return this.http.get(`${this.baseUrl}/${id}/apoiadores`, options);
  },

  /**
   * Envia uma requisição para definir o _banner_ de apoiadores de um
   * evento, retornando uma Promise.
   * @param {number} id - Identificador do evento.
   * @param {object} banner - Dados do banner de apoiadores.
   * @returns {external:Promise}
   */
  setApoiadores(id, banner) {
    return this.http.put(`${this.baseUrl}/${id}/apoiadores`, banner);
  },

  /**
   * Envia uma requisição para excluir o _banner_ de apoiadores de um
   * evento, retornando uma Promise.
   * @param {number} id - Identificador do evento.
   * @returns {external:Promise}
   */
  deleteApoiadores(id) {
    return this.http.delete(`${this.baseUrl}/${id}/apoiadores`);
  },

  /**
   * Envia uma requisição para obter os _links_ de um evento, retornando uma
   * Promise.
   * @param {number} id - Identificador do evento.
   * @returns {external:Promise}
   */
  getLinks(id) {
    return this.http.get(`${this.baseUrl}/${id}/links`);
  },

  /**
   * Envia uma requisição para criar um _link_ no evento, retornando uma
   * Promise.
   * @param {number} id - Identificador do evento.
   * @param {object} link - Dados do _link_.
   * @returns {external:Promise}
   */
  createLink(id, link) {
    return this.http.post(`${this.baseUrl}/${id}/links`, link);
  },

  /**
   * Envia uma requisição para atualizar um _link_ do evento, retornando uma
   * Promise.
   * @param {number} idEvento - Identificador do evento.
   * @param {number} idLink - Identificador do _link_.
   * @param {object} link - Dados do _link_.
   * @returns {external:Promise}
   */
  updateLink(idEvento, idLink, link) {
    return this.http.put(`${this.baseUrl}/${idEvento}/links/${idLink}`, link);
  },

  /**
   * Envia uma requisição para excluir um _link_ do evento, retornando uma
   * Promise.
   * @param {number} idEvento - Identificador do evento.
   * @param {number} idLink - Identificador do _link_.
   * @returns {external:Promise}
   */
  deleteLink(idEvento, idLink) {
    return this.http.delete(`${this.baseUrl}/${idEvento}/links/${idLink}`);
  },

  /**
   * Envia uma requisição para obter uma das possíveis descrições de um evento,
   * retornando uma Promise.
   * @param {number} idEvento - Identificador do evento.
   * @param {number} idSecao - Identificador da seção.
   * @param {object} [options={}] - Opções aceitas pelo {@link external:axios axios}.
   * @returns {external:Promise}
   */
  getDescricao(idEvento, idSecao, options = {}) {
    return this.http.get(
      `${this.baseUrl}/${idEvento}/secoes/${idSecao}/descricao`,
      options
    );
  },

  /**
   * Envia uma requisição para definir uma das possíveis descrições de um
   * evento, retornando uma Promise.
   * @param {number} idEvento - Identificador do evento.
   * @param {number} idSecao - Identificador da seção.
   * @param {object} descricao - Dados da descrição.
   * @returns {external:Promise}
   */
  setDescricao(idEvento, idSecao, descricao) {
    return this.http.put(
      `${this.baseUrl}/${idEvento}/secoes/${idSecao}/descricao`,
      descricao
    );
  },

  /**
   * Envia uma requisição para excluir uma das possíveis descrições de um
   * evento, retornando uma Promise.
   * @param {number} idEvento - Identificador do evento.
   * @param {number} idSecao - Identificador da seção.
   * @returns {external:Promise}
   */
  deleteDescricao(idEvento, idSecao) {
    return this.http.delete(
      `${this.baseUrl}/${idEvento}/secoes/${idSecao}/descricao`
    );
  },

  /**
   * Envia uma requisição para obter os itens de galeria de um evento,
   * retornando uma Promise.
   * @param {number} id - Identificador do evento.
   * @returns {external:Promise}
   */
  getGaleriaItens(id) {
    return this.http.get(`${this.baseUrl}/${id}/galeria-itens`);
  },

  /**
   * Envia uma requisição para obter a imagem de um item de galeria do evento,
   * retornando uma Promise.
   * @param {number} idEvento - Identificador do evento.
   * @param {number} idGaleriaItem - Identificador do item de galeria.
   * @param {object} [options={}] - Opções aceitas pelo {@link external:axios axios}.
   * @returns {external:Promise}
   */
  getImagemOfGaleriaItem(idEvento, idGaleriaItem, options = {}) {
    return this.http.get(
      `${this.baseUrl}/${idEvento}/galeria-itens/${idGaleriaItem}/imagem`,
      options
    );
  },

  /**
   * Constrói a URL de obtenção do conteúdo da imagem de um item de galeria do
   * evento.
   * @param {number} idEvento - Identificador do evento.
   * @param {number} idGaleriaItem - Identificador do item de galeria.
   * @returns {string}
   */
  getImagemConteudoURLOfGaleriaItem(idEvento, idGaleriaItem) {
    return `${this.baseUrl}/${idEvento}/galeria-itens/${idGaleriaItem}/imagem/conteudo`;
  },

  /**
   * Envia uma requisição para criar um item de galeria no evento, retornando
   * uma Promise.
   * @param {number} id - Identificador do evento.
   * @param {object} galeriaItem - Dados do item de galeria.
   * @returns {external:Promise}
   */
  createGaleriaItem(id, galeriaItem) {
    return this.http.post(`${this.baseUrl}/${id}/galeria-itens`, galeriaItem);
  },

  /**
   * Envia uma requisição para atualizar um item de galeria do evento,
   * retornando uma Promise.
   * @param {number} idEvento - Identificador do evento.
   * @param {number} idGaleriaItem - Identificador do item de galeria.
   * @param {object} galeriaItem - Dados do item de galeria.
   * @returns {external:Promise}
   */
  updateGaleriaItem(idEvento, idGaleriaItem, galeriaItem) {
    return this.http.put(
      `${this.baseUrl}/${idEvento}/galeria-itens/${idGaleriaItem}`,
      galeriaItem
    );
  },

  /**
   * Envia uma requisição para excluir um item de galeria do evento, retornando
   * uma Promise.
   * @param {number} idEvento - Identificador do evento.
   * @param {number} idGaleriaItem - Identificador do item de galeria.
   * @returns {external:Promise}
   */
  deleteGaleriaItem(idEvento, idGaleriaItem) {
    return this.http.delete(
      `${this.baseUrl}/${idEvento}/galeria-itens/${idGaleriaItem}`
    );
  },

  /**
   * Envia uma requisição para obter os estandes de um evento, retornando uma
   * Promise.
   * @param {number} id - Identificador do evento.
   * @param {object} params - Parâmetros de busca.
   * @returns {external:Promise}
   */
  searchEstandes(id, params) {
    let querystring = [];

    if (!isEmpty(params)) {
      if (!isEmpty(params.nome)) {
        querystring.push(`nome=${params.nome}`);
      }

      if (!isEmpty(params.inicio)) {
        querystring.push(`inicio=${params.inicio}`);
      }

      if (!isEmpty(params.termino)) {
        querystring.push(`termino=${params.termino}`);
      }

      if (params.continuo === true) {
        querystring.push(`continuo=true`);
      }

      if (params.online === true) {
        querystring.push(`online=true`);
      }

      if (!isEmpty(params.assuntos)) {
        params.assunto.forEach(item => querystring.push(`assuntoId=${item}`));
      }

      if (!isNullOrUndefined(params.status)) {
        querystring.push(`statusId=${params.status}`);
      }

      if (!isNullOrUndefined(params.page)) {
        querystring.push(`page=${params.page}`);
      }

      if (!isNullOrUndefined(params.size)) {
        querystring.push(`size=${params.size}`);
      }
    }

    querystring = querystring.length ? "?" + querystring.join("&") : "";

    return this.http.get(`${this.baseUrl}/${id}/estandes${querystring}`);
  },

  /**
   * Envia uma requisição para obter os estandes de uma seção específica do
   * evento, retornando uma Promise.
   * @param {number} idEvento - Identificador do evento.
   * @param {number} idSecao - Identificador da seção.
   * @param {object} params - Parâmetros de busca.
   * @returns {external:Promise}
   */
  searchEstandesFromSecao(idEvento, idSecao, params) {
    let querystring = [];

    if (!isEmpty(params)) {
      if (!isEmpty(params.nome)) {
        querystring.push(`nome=${params.nome}`);
      }

      if (!isEmpty(params.inicio)) {
        querystring.push(`inicio=${params.inicio}`);
      }

      if (!isEmpty(params.termino)) {
        querystring.push(`termino=${params.termino}`);
      }

      if (params.continuo === true) {
        querystring.push(`continuo=true`);
      }

      if (params.online === true) {
        querystring.push(`online=true`);
      }

      if (!isEmpty(params.assuntos)) {
        params.assuntos.forEach(item => querystring.push(`assuntoId=${item}`));
      }

      if (!isNullOrUndefined(params.status)) {
        querystring.push(`statusId=${params.status}`);
      }

      if (!isEmpty(params.usuarios)) {
        params.usuarios.forEach(item => querystring.push(`usuarioId=${item}`));
      }

      if (!isNullOrUndefined(params.page)) {
        querystring.push(`page=${params.page}`);
      }

      if (!isNullOrUndefined(params.size)) {
        querystring.push(`size=${params.size}`);
      }
    }

    querystring = querystring.length ? "?" + querystring.join("&") : "";

    return this.http.get(
      `${this.baseUrl}/${idEvento}/secoes/${idSecao}/estandes${querystring}`
    );
  },

  /**
   * Envia uma requisição para obter um estande do evento, retornando uma
   * Promise.
   * @param {number|string} idOrSlugEvento - Identificador numérico (id) ou textual
   * (slug) do evento.
   * @param {number|string} idOrSlugEstande - Identificador numérico (id) ou textual
   * (slug) do estande.
   * @returns {external:Promise}
   */
  getEstande(idOrSlugEvento, idOrSlugEstande) {
    return this.http.get(
      `${this.baseUrl}/${idOrSlugEvento}/estandes/${idOrSlugEstande}`
    );
  },

  /**
   * Envia uma requisição para criar um estande no evento, retornando
   * uma Promise.
   * @param {number} id - Identificador do evento.
   * @param {object} estande - Dados do estande.
   * @returns {external:Promise}
   */
  createEstande(id, estande) {
    return this.http.post(`${this.baseUrl}/${id}/estandes`, estande);
  },

  /**
   * Envia uma requisição para atualizar um estande do evento,
   * retornando uma Promise.
   * @param {number} idEvento - Identificador do evento.
   * @param {number} idEstande - Identificador do estande.
   * @param {object} estande - Dados do estande.
   * @returns {external:Promise}
   */
  updateEstande(idEvento, idEstande, estande) {
    return this.http.put(
      `${this.baseUrl}/${idEvento}/estandes/${idEstande}`,
      estande
    );
  },

  /**
   * Envia uma requisição para associar um estande do evento a uma de suas
   * seções, retornando uma Promise.
   * @param {number} idEvento - Identificador do evento.
   * @param {number} idSecao - Identificador da seção.
   * @param {number} idEstande - Identificador do estande.
   * @returns {external:Promise}
   */
  addEstande(idEvento, idSecao, idEstande) {
    return this.http.put(
      `${this.baseUrl}/${idEvento}/secoes/${idSecao}/estandes/${idEstande}`
    );
  },

  /**
   * Envia uma requisição para desassociar um estande do evento a uma de suas
   * seções, retornando uma Promise.
   * @param {number} idEvento - Identificador do evento.
   * @param {number} idSecao - Identificador da seção.
   * @param {number} idEstande - Identificador do estande.
   * @returns {external:Promise}
   */
  removeEstande(idEvento, idSecao, idEstande) {
    return this.http.put(
      `${this.baseUrl}/${idEvento}/secoes/${idSecao}/estandes/${idEstande}`
    );
  },

  /**
   * Registra o _plug-in_ de requisições HTTP no _namespace_ do serviço.
   * @param {module:plugins/Http/Http} http - _Plug-in_ de requisições HTTP.
   * @returns {module:services/Evento/Evento~EventoService} O próprio namespace.
   */
  registerHttp(http) {
    this.http = http;
    return this;
  }
};

export default EventoService;
