/**
 * @module components/base/BaseTextarea
 * @category Componentes-base
 * @summary Módulo de importação do componente-base de _textarea_ do {@link external:Materialize Materialize}.
 *
 * @description
 * Expõe o componente-base de _textarea_ do {@link external:Materialize Materialize} para utilização pelo container e pelos
 * módulos.
 *
 * @example <caption>Importando como plug-in</caption>
 * // main.js
 * import BaseTextarea from "./components/base/BaseTextarea";
 *
 * Vue.use(BaseTextarea);
 *
 * @example <caption>Importando diretamente em um componente</caption>
 * // componente.vue
 * // ou import BaseTextarea from "./components/base/BaseTextarea/BaseTextarea.vue";
 * import { BaseTextarea } from "./components/base/BaseTextarea";
 *
 * export default {
 *  name: // ...
 *  components: {
 *    BaseTextarea
 *  },
 *  // ...
 * }
 */
import { default as BaseTextarea } from "./BaseTextarea.vue";

/**
 * Associa o componente-base de _textarea_ à instância do {@link external:Vue Vue}.
 * @param {external:Vue} Vue - Instância do {@link external:Vue Vue}.
 */
const install = Vue => Vue.component(BaseTextarea.name, BaseTextarea);

if (window && window.Vue) {
  install(window.Vue);
}

export default install;

export { BaseTextarea };
