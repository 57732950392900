<template>
  <section class="section">
    <component v-bind:is="tag" class="pae-title pae-header">
      <i v-if="secao.icone" class="material-icons left">{{ secao.icone }}</i
      >{{ secao.nome }}
    </component>

    <p v-if="!hasDescricao && modelo.obrigatorio" class="flow-text center">
      Nenhuma descrição foi definida.
    </p>
    <div
      v-else-if="hasDescricao"
      class="flow-text justify-align descricao"
      v-html="computedDescricao"
    ></div>

    <div v-if="hasSections">
      <Section
        v-for="(item, index) in secao.secoes"
        v-bind:key="index"
        v-bind:tag="subTag"
        v-bind:entidade-id="entidadeId"
        v-bind:modelo="modelo"
        v-bind:value="item"
      />
    </div>
  </section>
</template>

<script>
import DOMPurify from "dompurify";
import { DEFAULT_COLOR, DEFAULT_LUMINOSITY } from "../../../constants";
import { isEmpty } from "../../../utils/lang";
import {
  EventoService,
  EstandeService,
  TipoModeloService
} from "../../../services";

export default {
  name: "SectionDescricao",
  inheritAttrs: false,
  props: {
    tag: {
      type: String,
      default: "h3",
      validator: value =>
        ["h1", "h2", "h3", "h4", "h5", "h6"].indexOf(value) !== -1
    },
    entidadeId: {
      type: Number,
      required: true
    },
    modelo: {
      type: Object,
      required: true
    },
    secao: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      progressBar: {
        useContainer: false
      },
      descricao: null
    };
  },
  computed: {
    hasSections() {
      return !isEmpty(this.secao.secoes);
    },
    hasDescricao() {
      return !isEmpty(this.descricao);
    },
    computedDescricao() {
      const config = { USE_PROFILES: { html: true }, FORBID_TAGS: ["style"] };

      return DOMPurify.sanitize(this.descricao.conteudoHtml, config);
    },
    subTag() {
      const headerNumber = window.parseInt(this.tag.charAt(1)) + 1;

      return headerNumber >= 6 ? "h6" : `h${headerNumber}`;
    }
  },
  beforeCreate() {
    // https://vuejs.org/v2/guide/components-edge-cases.html#Circular-References-Between-Components

    this.$options.components.Section = require("./Section.vue").default;
  },
  created() {
    this.overrideMaterializeStyle();

    this.getDescricao();
  },
  methods: {
    overrideMaterializeStyle() {
      const colorCode = this.$utils.Web.getMaterializeColorCode(
        DEFAULT_COLOR,
        DEFAULT_LUMINOSITY
      );

      if (colorCode) {
        const CSSStyleSheet = `
        blockquote {
          border-left-color: ${colorCode};
        }

        .contrast blockquote {
            border-left-color: white;
        }
      `;

        this.$utils.Web.createStyleElement({
          id: "stylesheet-blockquote",
          type: "text/css",
          innerHTML: CSSStyleSheet
        });
      }
    },
    getDescricao() {
      if (!this.entidadeId) {
        return;
      }

      const id = this.entidadeId;

      const idSecao = this.secao.id;

      let promise = null;

      const options = {
        validateStatus: status =>
          this.$http.isSuccessfulStatus(status) ||
          status === this.$http.HTTP_STATUS_CLIENT_ERROR_NOT_FOUND
      };

      if (this.modelo.tipoModeloId === TipoModeloService.EVENTO) {
        promise = EventoService.getDescricao(id, idSecao, options);
      } else if (this.modelo.tipoModeloId === TipoModeloService.ESTANDE) {
        promise = EstandeService.getDescricao(id, idSecao, options);
      } else {
        this.$notification.pushError("Tipo de modelo de página inválido");
        return;
      }

      this.loading = true;

      this.descricao = null;

      promise
        .then(response => (this.descricao = response.data))
        .catch(error =>
          this.$notification.pushError(
            `Houve um erro ao obter a descrição: ${error.message}`
          )
        )
        .finally(() => (this.loading = false));
    }
  }
};
</script>

<style scoped>
.descricao /deep/ table p {
  margin: 0;
}
</style>
