/**
 * @module services/Usuario/Usuario
 * @category Serviços
 * @summary Módulo do serviço de usuários.
 *
 * @description
 * Expõe o objeto/_namespace_ do serviço de usuários do sistema para
 * utilização interna.
 *
 * Para correto funcionamento, é necessário que o _plug-in_ de requisições HTTP
 * seja registrado a este serviço.
 *
 * @requires module:constants.API_URL
 * @requires module:utils/lang.isEmpty
 * @requires module:utils/lang.isNullOrUndefined
 *
 * @example
 * import UsuarioService from "./services/Usuario/Usuario";
 *
 * // Registrando o _plug-in_ de requisições HTTP do container.
 * UsuarioService.registerHttp(Http);
 *
 * const params = // ...
 *
 * // Obtendo a lista de usuários
 * UsuarioService.search(params)
 *  .then(response => {
 *    // ...
 *  })
 *  .catch(error => {
 *    // ...
 *  })
 */
import { API_URL } from "../../constants";
import { isEmpty, isNullOrUndefined } from "../../utils/lang";

/**
 * @namespace UsuarioService
 * @category Serviços
 * @summary Objeto/_namespace_ do serviço de usuários.
 */
const UsuarioService = {
  /**
   * URL de acesso à API para obtenção de usuários.
   * @type {string}
   * @private
   * @readonly
   */
  baseUrl: `${API_URL}/usuarios`,

  /**
   * _Plug-in_ de requisições HTTP.
   * @type {module:plugins/Http/Http}
   * @private
   * @readonly
   */
  http: null,

  /**
   * Tipo de usuário "Servidor".
   * @type {number}
   * @readonly
   */
  SERVIDOR: 1,

  /**
   * Tipo de usuário "Externo".
   * @type {number}
   * @readonly
   */
  EXTERNO: 2,

  /**
   * Realiza a validação de um usuário.
   * @param {object} val - Usuário a ser validado.
   * @returns {Array<Error>} - _Array_ de possíveis erros.
   */
  validate(val) {
    const errors = [];

    if (isEmpty(val)) {
      errors.push(new Error("Usuário não preenchido"));
    } else {
      if (val.tipoUsuarioId !== this.EXTERNO) {
        errors.push(new Error("Tipo de usuário inválido"));
      }
    }

    return errors;
  },

  /**
   * Envia uma requisição para obter uma lista de usuários baseada em
   * parâmetros de busca, retornando uma Promise.
   * @param {object} params - Parâmetros de busca.
   * @returns {external:Promise}
   */
  search(params) {
    let querystring = [];

    if (!isEmpty(params)) {
      if (!isEmpty(params.nome)) {
        querystring.push(`nome=${params.nome}`);
      }

      if (!isEmpty(params.cpf)) {
        querystring.push(`cpf=${params.cpf}`);
      }

      if (!isEmpty(params.passaporte)) {
        querystring.push(`passaporte=${params.passaporte}`);
      }

      if (!isNullOrUndefined(params.page)) {
        querystring.push(`page=${params.page}`);
      }

      if (!isNullOrUndefined(params.size)) {
        querystring.push(`size=${params.size}`);
      }
    }

    querystring = querystring.length ? "?" + querystring.join("&") : "";

    return this.http.get(`${this.baseUrl}${querystring}`);
  },

  /**
   * Envia uma requisição para obter um usuário, retornando uma Promise.
   * @param {number} id - Identificador numérico do usuário.
   * @returns {external:Promise}
   */
  get(id) {
    return this.http.get(`${this.baseUrl}/${id}`);
  },

  /**
   * Envia uma requisição para definir uma nova senha de usuário, retornando
   * uma Promise.
   * @param {string} login - Login utilizado para acesso ao sistema.
   * @param {string} token - Token de acesso para definição de nova senha.
   * @param {object} params - Objeto contendo as senhas anterior e nova.
   * @returns {external:Promise}
   */
  updateSenha(login, token, params) {
    return this.http.put(`${this.baseUrl}/${login}/senha/${token}`, params);
  },

  /**
   * Envia uma requisição para solicitar uma nova senha para o usuário,
   * retornando uma Promise.
   * @param {string} login - Login utilizado para acesso ao sistema.
   * @returns {external:Promise}
   */
  resetSenha(login) {
    return this.http.delete(`${this.baseUrl}/${login}/senha`);
  },

  /**
   * Envia uma requisição para obter as permissões de um usuario, retornando
   * uma Promise.
   * @param {number} id - Identificador do usuario.
   * @returns {external:Promise}
   */
  getPermissoes(id) {
    return this.http.get(`${this.baseUrl}/${id}/permissoes`);
  },

  /**
   * Envia uma requisição para associar uma permissão ao usuário, retornando
   * uma Promise.
   * @param {number} idUsuario - Identificador do usuário.
   * @param {number} idPermissao - Identificador da permissão.
   * @returns {external:Promise}
   */
  addPermissao(idUsuario, idPermissao) {
    return this.http.put(
      `${this.baseUrl}/${idUsuario}/permissoes/${idPermissao}`
    );
  },

  /**
   * Envia uma requisição para desassociar uma permissão ao usuário, retornando
   * uma Promise.
   * @param {number} idUsuario - Identificador do usuário.
   * @param {number} idPermissao - Identificador da permissão.
   * @returns {external:Promise}
   */
  removePermissao(idUsuario, idPermissao) {
    return this.http.delete(
      `${this.baseUrl}/${idUsuario}/permissoes/${idPermissao}`
    );
  },

  /**
   * Registra o _plug-in_ de requisições HTTP no _namespace_ do serviço.
   * @param {module:plugins/Http/Http} http - _Plug-in_ de requisições HTTP.
   * @returns {module:services/Usuario/Usuario~UsuarioService} O próprio namespace.
   */
  registerHttp(http) {
    this.http = http;
    return this;
  }
};

export default UsuarioService;
