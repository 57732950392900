<template>
  <li>
    <BaseDropdown
      id="pae-header-navbar-user-dropdown"
      class="collection"
      trigger-element-id="pae-header-navbar-user-dropdown-trigger"
      v-bind:dropdown-options="{ constrainWidth: false }"
    >
      <li v-if="user.name" class="collection-item avatar" tabindex="0">
        <img
          v-if="user.photo"
          v-bind:src="user.photo"
          v-bind:alt="user.name"
          v-bind:title="user.name"
        />
        <i
          v-else
          class="material-icons circle"
          v-bind:class="[color, luminosity]"
          >account_circle</i
        >
        <span class="title name">{{ user.name }}</span>
        <span class="title email">{{ user.email }}</span>
      </li>
      <li v-if="isGestorSistema" tabindex="0">
        <BaseRouterLink v-bind:to="{ name: 'controleAcesso' }"
          >Controle de Acesso</BaseRouterLink
        >
      </li>
      <li v-if="isGestorEventos || isGestorEstandes" tabindex="0">
        <BaseRouterLink v-bind:to="{ name: 'adminEventos' }"
          >Gestão de Eventos</BaseRouterLink
        >
      </li>
      <li v-if="isGestorAny" class="divider" tabindex="-1"></li>
      <li tabindex="0">
        <a href="#" v-on:click="logout">Sair</a>
      </li>
    </BaseDropdown>
    <a
      id="pae-header-navbar-user-dropdown-trigger"
      href="#!"
      data-target="pae-header-navbar-user-dropdown"
      v-bind:title="user.name"
    >
      <i class="material-icons">account_circle</i>
    </a>
  </li>
</template>

<script>
import { DEFAULT_COLOR, DEFAULT_LUMINOSITY } from "../constants";
import { AuthenticationService, PermissaoService } from "../services";

export default {
  name: "TheHeaderNavbarUser",
  inheritAttrs: false,
  data() {
    return {
      color: DEFAULT_COLOR,
      luminosity: DEFAULT_LUMINOSITY
    };
  },
  computed: {
    isGestorSistema() {
      return this.$authorization.hasPermission(PermissaoService.GESTAO_SISTEMA);
    },
    isGestorEventos() {
      return this.$authorization.hasPermission(PermissaoService.GESTAO_EVENTO);
    },
    isGestorEstandes() {
      return this.$authorization.hasPermission(PermissaoService.GESTAO_ESTANDE);
    },
    isGestorAny() {
      return (
        this.isGestorSistema || this.isGestorEventos || this.isGestorEstandes
      );
    },
    user() {
      const { user } = this.$store.state;

      if (!user) {
        return {};
      }

      const pessoa = user.pessoa;

      return {
        name: pessoa.nomeExibicao,
        email: pessoa.email ? pessoa.email : "E-mail não informado"
      };
    }
  },
  methods: {
    logout() {
      AuthenticationService.deauthenticate()
        .then(() => {
          if (window._paq) {
            window._paq.push(["resetUserId"]);
          }

          this.$store.commit("setUser", null);
        })
        .catch(error => this.$notification.pushError(error.message));
    }
  }
};
</script>

<style scoped>
.collection .collection-item.avatar {
  min-height: 65px;
}

.collection .collection-item span {
  padding: 0;
}

/* Ajuste para aumentar a seletividade das regras de alto contraste */
.contrast .collection.dropdown-content .divider {
  background-color: white !important;
}
.contrast .collection.dropdown-content li.collection-item:hover span {
  color: black !important;
}
</style>
