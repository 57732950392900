/**
 * @module services/Permissao/Permissao
 * @category Serviços
 * @summary Módulo do serviço de permissões.
 *
 * @description
 * Expõe o objeto/_namespace_ do serviço de permissões do sistema para
 * utilização interna.
 *
 * Para correto funcionamento, é necessário que o _plug-in_ de requisições HTTP
 * seja registrado a este serviço.
 *
 * @requires module:constants.API_URL
 *
 * @example
 * import PermissaoService from "./services/Permissao/Permissao";
 *
 * // Registrando o _plug-in_ de requisiçõe HTTP do container.
 * PermissaoService.registerHttp(Http);
 *
 * // Obtendo a lista de permissões
 * PermissaoService.getAll()
 *  .then(response => {
 *    // ...
 *  })
 *  .catch(error => {
 *    // ...
 *  })
 */
import { API_URL } from "../../constants";

/**
 * @namespace PermissaoService
 * @category Serviços
 * @summary Objeto/_namespace_ do serviço de permissões.
 */
const PermissaoService = {
  /**
   * URL de acesso à API para obtenção de permissões.
   * @type {string}
   * @private
   * @readonly
   */
  baseUrl: `${API_URL}/permissoes`,

  /**
   * _Plug-in_ de requisições HTTP.
   * @type {module:plugins/Http/Http}
   * @private
   * @readonly
   */
  http: null,

  /**
   * Constante que caracteriza uma permissão/_role_ de administrador do sistema.
   * @type {string}
   * @private
   * @readonly
   */
  ADMIN: "ADMIN",

  /**
   * Constante que caracteriza uma permissão/_role_ de gestor do sistema.
   * @type {string}
   * @private
   * @readonly
   */
  GESTAO_SISTEMA: "GESTAO_SISTEMA",

  /**
   * Constante que caracteriza uma permissão/_role_ de gestor de eventos.
   * @type {string}
   * @private
   * @readonly
   */
  GESTAO_EVENTO: "GESTAO_EVENTO",

  /**
   * Constante que caracteriza uma permissão/_role_ de gestor de estandes.
   * @type {string}
   * @private
   * @readonly
   */
  GESTAO_ESTANDE: "GESTAO_ESTANDE",

  /**
   * Envia uma requisição para obter uma lista de permissões baseada em parâmetros
   * de busca, retornando uma Promise.
   * @returns {external:Promise}
   */
  getAll() {
    return this.http.get(this.baseUrl);
  },

  /**
   * Envia uma requisição para obter um permissao, retornando uma Promise.
   * @param {number} id - Identificador numérico do permissao.
   * @returns {external:Promise}
   */
  get(id) {
    return this.http.get(`${this.baseUrl}/${id}`);
  },

  /**
   * Registra o _plug-in_ de requisições HTTP no _namespace_ do serviço.
   * @param {module:plugins/Http/Http} http - _Plug-in_ de requisições HTTP.
   * @returns {module:services/Permissao/Permissao~PermissaoService} O próprio namespace.
   */
  registerHttp(http) {
    this.http = http;
    return this;
  }
};

export default PermissaoService;
