/**
 * @module services/Apresentador/Apresentador
 * @category Serviços
 * @summary Módulo do serviço de apresentadores.
 *
 * @description
 * Expõe o objeto/_namespace_ do serviço de apresentadores do sistema para
 * utilização interna.
 *
 * Para correto funcionamento, é necessário que o _plug-in_ de requisições HTTP
 * seja registrado a este serviço.
 *
 * @requires module:constants.API_URL
 * @requires module:utils/lang.isEmpty
 *
 * @example
 * import ApresentadorService from "./services/Apresentador/Apresentador";
 *
 * // Registrando o _plug-in_ de requisiçõe HTTP do container.
 * ApresentadorService.registerHttp(Http);
 *
 * const params = // ...
 *
 * // Obtendo a lista de apresentadores
 * ApresentadorService.search(params)
 *  .then(response => {
 *    // ...
 *  })
 *  .catch(error => {
 *    // ...
 *  })
 */
import { API_URL } from "../../constants";
import { isEmpty } from "../../utils/lang";

/**
 * @namespace ApresentadorService
 * @category Serviços
 * @summary Objeto/_namespace_ do serviço de apresentadores.
 */
const ApresentadorService = {
  /**
   * URL de acesso à API para obtenção de apresentadores.
   * @type {string}
   * @private
   * @readonly
   */
  baseUrl: `${API_URL}/apresentadores`,

  /**
   * _Plug-in_ de requisições HTTP.
   * @type {module:plugins/Http/Http}
   * @private
   * @readonly
   */
  http: null,

  /**
   * Realiza a validação de um apresentador.
   * @param {object} val - Apresentador a ser validado.
   * @returns {Array<Error>} - _Array_ de possíveis erros.
   */
  validate(val) {
    const errors = [];

    if (isEmpty(val)) {
      errors.push(new Error("Apresentador não preenchido"));
    } else {
      if (isEmpty(val.nome)) {
        errors.push(new Error("Nome do apresentador inválido"));
      }
    }

    return errors;
  },

  /**
   * Envia uma requisição para obter uma lista de apresentadores baseada em
   * parâmetros de busca, retornando uma Promise.
   * @param {object} params - Parâmetros de busca.
   * @returns {external:Promise}
   */
  search(params) {
    let querystring = [];

    if (!isEmpty(params)) {
      if (!isEmpty(params.nome)) {
        querystring.push(`nome=${params.nome}`);
      }
    }

    querystring = querystring.length ? "?" + querystring.join("&") : "";

    return this.http.get(`${this.baseUrl}${querystring}`);
  },

  /**
   * Envia uma requisição para obter um apresentador, retornando uma Promise.
   * @param {number} id - Identificador do apresentador.
   * @returns {external:Promise}
   */
  get(id) {
    return this.http.get(`${this.baseUrl}/${id}`);
  },

  /**
   * Envia uma requisição para obter a foto de um apresentador, retornando uma
   * Promise.
   * @param {number} id - Identificador do apresentador.
   * @param {object} [options={}] - Opções aceitas pelo {@link external:axios axios}.
   * @returns {external:Promise}
   */
  getFoto(id, options = {}) {
    return this.http.get(`${this.baseUrl}/${id}/foto`, options);
  },

  /**
   * Constrói a URL de obtenção do conteúdo da foto de um apresentador.
   * @param {number} id - Identificador do apresentador.
   * @returns {string}
   */
  getFotoConteudoURL(id) {
    return `${this.baseUrl}/${id}/foto/conteudo`;
  },

  /**
   * Envia uma requisição para criar um apresentador, retornando uma Promise.
   * @param {object} apresentador - Dados do apresentador.
   * @returns {external:Promise}
   */
  create(apresentador) {
    return this.http.post(`${this.baseUrl}`, apresentador);
  },

  /**
   * Envia uma requisição para atualizar um apresentador, retornando uma
   * Promise.
   * @param {number} id - Identificador do apresentador.
   * @param {object} apresentador - Dados do apresentador.
   * @returns {external:Promise}
   */
  update(id, apresentador) {
    return this.http.put(`${this.baseUrl}/${id}`, apresentador);
  },

  /**
   * Envia uma requisição para excluir a foto de um apresentador, retornando
   * uma Promise.
   * @param {number} id - Identificador do apresentador.
   * @returns {external:Promise}
   */
  deleteFoto(id) {
    return this.http.delete(`${this.baseUrl}/${id}/foto`);
  },

  /**
   * Registra o _plug-in_ de requisições HTTP no _namespace_ do serviço.
   * @param {module:plugins/Http/Http} http - _Plug-in_ de requisições HTTP.
   * @returns {module:services/Apresentador/Apresentador~ApresentadorService} O próprio namespace.
   */
  registerHttp(http) {
    this.http = http;
    return this;
  }
};

export default ApresentadorService;
