/**
 * @module store
 * @category Gerenciador de estados
 * @summary Módulo do estado global do sistema.
 *
 * @description
 * Expõe uma instância de {@link external:Store Store}, contendo o estado
 * global do sistema e seus `mutations`.
 *
 * @requires module:store/mutations
 */
import Vue from "vue";
import Vuex from "vuex";

import * as mutations from "./mutations";

Vue.use(Vuex);

/**
 * Estrutura com as opções utilizadas pelo gerenciador de estados do sistema
 * ({@link external:Vuex Vuex}).
 * @type {object}
 * @property {object} state - Estado global do sistema.
 * @property {object} state.authenticationData - Dados de autenticação do
 * usuário logado.
 * @property {string} state.authenticationMode - Modo de autenticação utilizado.
 * @property {Array<object>} state.extraNavbar - Lista com dados para construir
 * os itens de menu extras para navegação.
 * @property {string} state.ticket - Ticket do {@link https://cas.ufrj.br CAS}
 * da UFRJ.
 * @property {object} state.user - Objeto com os dados do usuário logado.
 * @property {object} mutations - {@link module:store/mutations}.
 * @constant
 */
const storeOptions = {
  state: {
    authenticationData: null,
    authenticationMode: null,
    extraNavbar: null,
    permissions: null,
    ticket: null,
    user: null
  },
  mutations
};

export default new Vuex.Store(storeOptions);
