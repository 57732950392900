<template>
  <BaseTextInput
    v-bind:id="id"
    v-bind:grid-expression="gridExpression"
    class="datepicker-wrapper"
    v-bind:name="name"
    v-bind:disabled="disabled"
    v-bind:required="required"
    v-bind:placeholder="placeholder"
    v-bind:label-class="labelClass"
    v-bind:show-icon="showIcon"
    v-bind:icon-name="iconName"
    v-bind:icon-position="iconPosition"
    v-bind:icon-title="iconTitle"
    v-bind:icon-click="iconClick"
    v-bind:title="$attrs.title"
    v-bind:value="value"
    v-on="listeners"
  >
    <template v-slot:default>
      <slot>{{ label }}</slot>
    </template>

    <template v-slot:extra-content>
      <slot name="extra-content"></slot>
    </template>
  </BaseTextInput>
</template>

<script>
/**
 * @module components/base/BaseDatePicker/BaseDatePicker
 * @category Componentes-base
 * @summary _Single File Component_ (SFC) de _datepicker_ do {@link external:Materialize Materialize}.
 *
 * @description
 * Internamente, utiliza o componente {@link module:components/base/BaseTextInput/BaseTextInput BaseTextInput}, recebendo, em grande parte,
 * as mesmas props desse componente.
 *
 * As cores de marcação das datas, do painel lateral e dos botões são obtidas
 * automaticamente a partir da configuração do ambiente.
 *
 * Os _slots_ _default_ e _extra-content_ são repassados para o do componente
 * {@link module:components/base/BaseTextInput/BaseTextInput BaseTextInput}.
 *
 * Atributos HTML de acessibilidade, e.g., `title`, podem ser usados
 * diretamente no componente.
 *
 * Para correto funcionamento, é necessário que o _framework_ {@link external:Materialize Materialize}
 * tenha sido importado, tornando seu objeto principal acessível globalmente.
 *
 * @requires module:components/base/BaseTextInput/BaseTextInput
 * @requires module:constants.DEFAULT_COLOR
 * @requires module:constants.DEFAULT_LUMINOSITY
 * @requires module:utils/web.createStyleElement
 * @requires module:utils/web.getMaterializeColorCode
 *
 * @vue-prop {string} [gridExpression="col s12"] - Classes CSS de grid do {@link external:Materialize Materialize}.
 * @vue-prop {string} id - Vide {@link module:components/base/BaseTextInput/BaseTextInput BaseTextInput}.
 * @vue-prop {string} [name] - Vide {@link module:components/base/BaseTextInput/BaseTextInput BaseTextInput}.
 * @vue-prop {boolean} [disabled] - Vide {@link module:components/base/BaseTextInput/BaseTextInput BaseTextInput}.
 * @vue-prop {boolean} [required] - Vide {@link module:components/base/BaseTextInput/BaseTextInput BaseTextInput}.
 * @vue-prop {string} [placeholder] - Vide {@link module:components/base/BaseTextInput/BaseTextInput BaseTextInput}.
 * @vue-prop {string} [label] - Vide {@link module:components/base/BaseTextInput/BaseTextInput BaseTextInput}.
 * @vue-prop {string|Array|object} [labelClass] - Vide {@link module:components/base/BaseTextInput/BaseTextInput BaseTextInput}.
 * @vue-prop {boolean} [showIcon] - Vide {@link module:components/base/BaseTextInput/BaseTextInput BaseTextInput}.
 * @vue-prop {string} [iconName] - Vide {@link module:components/base/BaseTextInput/BaseTextInput BaseTextInput}.
 * @vue-prop {string} [iconPosition] - Vide {@link module:components/base/BaseTextInput/BaseTextInput BaseTextInput}.
 * @vue-prop {function} [iconTitle] - Vide {@link module:components/base/BaseTextInput/BaseTextInput BaseTextInput}.
 * @vue-prop {function} [iconClick] - Vide {@link module:components/base/BaseTextInput/BaseTextInput BaseTextInput}.
 * @vue-prop {object} [pickerOptions] - Opções aceitas pelo `datepicker`
 * do {@link external:Materialize Materialize}. A opção `format` e o idioma
 * (opção `i18n`) são automaticamente definidos para, respectivamente, o
 * formato brasileiro ("DD/MM/YYYY") e para "pt-BR" quando eles não são
 * previamente preenchidos.
 * @vue-prop {string} [value] - Valor inicial do componente e utilizado internamente
 * para a diretiva `v-model` do {@link external:Vue Vue}.
 * @vue-event {string} onInput - Emite o valor atual do componente. Pode ser
 * capturado pela diretiva `v-on:input` (ou `v-model`) do {@link external:Vue Vue}. Este evento
 * é emitido quando seu valor é alterado diretamente pelo usuário ou quando se
 * escolhe uma data pelo picker do {@link external:Materialize Materialize}.
 * @vue-event {string} onChange - Emite o valor atual do componente. Pode ser
 * capturado pela diretiva `v-on:change` do {@link external:Vue Vue}. Este evento
 * é apenas emitido quando se escolhe uma data pelo picker do {@link external:Materialize Materialize}.
 *
 * @example
 * <BaseDatePicker
 *  id="data-nascimento"
 *  name="data-nascimento"
 *  grid-expression="col s12 m6"
 *  v-model="servidor.dataNascimento">
 *  Data de nascimento
 * </BaseDatePicker>
 */
import i18n from "./BaseDatePicker.js";
import { DEFAULT_COLOR, DEFAULT_LUMINOSITY } from "../../../constants";
import {
  createStyleElement,
  getMaterializeColorCode
} from "../../../utils/web";
import BaseTextInput from "../BaseTextInput/BaseTextInput.vue";

export default {
  name: "BaseDatePicker",
  components: {
    BaseTextInput
  },
  inheritAttrs: false,
  props: {
    gridExpression: {
      type: String,
      default: "col s12"
    },
    id: {
      type: String,
      required: true
    },

    name: String,
    disabled: Boolean,
    required: Boolean,
    placeholder: String,
    label: String,
    labelClass: [String, Array, Object],
    showIcon: Boolean,
    iconName: String,
    iconPosition: String,
    iconTitle: String,
    iconClick: Function,

    pickerOptions: Object,
    value: String
  },
  data() {
    return {
      instance: null
    };
  },
  computed: {
    listeners() {
      return Object.assign({}, this.$listeners, {
        input: this.onInput,
        change: this.onChange
      });
    }
  },
  created() {
    this.overrideMaterializeStyle();
  },
  beforeCreate() {
    if (!window.M) {
      window.console.error(
        "Objeto do Materialize não encontrado. Verifique se essa biblioteca foi carregada corretamente"
      );
    }
  },
  mounted() {
    if (!window.M) {
      return;
    }

    const input = document.getElementById(this.id);

    const pickerOptions = Object.assign({}, this.pickerOptions);

    pickerOptions.format = pickerOptions.format || "dd/mm/yyyy";

    pickerOptions.i18n = pickerOptions.i18n || i18n["pt-BR"];

    this.instance = window.M.Datepicker.init(input, pickerOptions);
  },
  beforeDestroy() {
    if (this.instance) {
      this.instance.destroy();
    }
  },
  methods: {
    overrideMaterializeStyle() {
      const colorCode = getMaterializeColorCode(
        DEFAULT_COLOR,
        DEFAULT_LUMINOSITY
      );

      if (colorCode) {
        const CSSStyleSheet = `
          .datepicker-table td.is-today {
            color: ${colorCode};
          }
          .datepicker-table td.is-selected {
            background-color: ${colorCode};
            color: white;
          }
          .datepicker-day-button:focus {
            background-color: ${colorCode}19; /* Materialize original: $datepicker-day-focus; */
            color: white;
          }
          .datepicker-date-display {
            background-color: ${colorCode};
          }
          .datepicker-cancel, .datepicker-clear,
          .datepicker-today, .datepicker-done {
            color: ${colorCode};
          }
          .month-prev:focus,
          .month-next:focus {
            background-color: ${colorCode};
          }
          .month-prev:focus svg,
          .month-next:focus svg {
            fill: white;
          }
        `;

        createStyleElement({
          id: "stylesheet-datepicker",
          type: "text/css",
          innerHTML: CSSStyleSheet
        });
      }
    },
    onChange(event) {
      const { value } = event.target;

      this.onInput(value);
      this.$emit("change", value);
    },
    onInput(event) {
      this.$emit("input", event);
    }
  }
};
</script>

<style scoped>
.contrast .datepicker-wrapper /deep/ .datepicker-table td.is-today {
  color: white;
}
.contrast .datepicker-wrapper /deep/ .datepicker-table td.is-selected {
  background-color: white;
  color: black;
}
.contrast
  .datepicker-wrapper
  /deep/
  .datepicker-table
  td.is-selected
  .datepicker-day-button {
  background-color: transparent !important;
  color: black !important;
}
.contrast .datepicker-wrapper /deep/ .datepicker-day-button:focus {
  background-color: white !important;
  color: black !important;
}
.contrast .datepicker-wrapper /deep/ .month-prev:focus,
.contrast .datepicker-wrapper /deep/ .month-next:focus {
  background-color: white;
}
.contrast .datepicker-wrapper /deep/ .month-prev:focus svg,
.contrast .datepicker-wrapper /deep/ .month-next:focus svg {
  fill: black;
}
.contrast .datepicker-wrapper /deep/ .month-prev svg,
.contrast .datepicker-wrapper /deep/ .month-next svg {
  fill: white;
}
</style>
