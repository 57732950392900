<template>
  <BaseTextInput
    v-bind:id="id"
    v-bind:grid-expression="gridExpression"
    class="autocomplete-wrapper"
    v-bind:name="name"
    v-bind:disabled="disabled"
    v-bind:required="required"
    v-bind:placeholder="placeholder"
    v-bind:label-class="labelClass"
    v-bind:show-icon="showIcon"
    v-bind:icon-name="iconName"
    v-bind:icon-position="iconPosition"
    v-bind:icon-title="iconTitle"
    v-bind:icon-click="iconClick"
    v-bind:autofocus="autofocus"
    v-bind:title="$attrs.title"
    v-bind:value="value"
    v-on="listeners"
  >
    <template v-slot:default>
      <slot>{{ label }}</slot>
    </template>

    <template v-slot:extra-content>
      <slot name="extra-content"></slot>
    </template>
  </BaseTextInput>
</template>

<script>
/**
 * @module components/base/BaseAutocomplete/BaseAutocomplete
 * @category Componentes-base
 * @summary _Single File Component_ (SFC) de _autocomplete_ do {@link external:Materialize Materialize}.
 *
 * @description
 * Este componente associa um evento de autocompletar ao elemento HTML
 * (`input` com `type` = "text") cujo `id` tenha sido informado por `id`.
 *
 * Internamente, utiliza o componente {@link module:components/base/BaseTextInput/BaseTextInput BaseTextInput},
 * recebendo, em grande parte, as mesmas props desse componente.
 *
 * A cor de texto dos itens do _dropdown_ e de seleção dos _chips_ é obtida
 * automaticamente a partir da configuração do ambiente.
 *
 * Os _slots_ _default_ e _extra-content_ são repassados para o do componente
 * {@link module:components/base/BaseTextInput/BaseTextInput BaseTextInput}.
 *
 * Atributos HTML de acessibilidade, e.g., `title`, podem ser usados
 * diretamente no componente.
 *
 * Para correto funcionamento, é necessário que o _framework_ {@link external:Materialize Materialize}
 * tenha sido importado, tornando seu objeto principal acessível globalmente.
 *
 * @requires module:components/base/BaseTextInput/BaseTextInput
 * @requires module:constants.DEFAULT_COLOR
 * @requires module:constants.DEFAULT_LUMINOSITY
 * @requires module:utils/web.createStyleElement
 * @requires module:utils/web.getMaterializeColorCode
 *
 * @vue-prop {string} [gridExpression="col s12"] - Classes CSS de grid do {@link external:Materialize Materialize}.
 * @vue-prop {string} id - Vide {@link module:components/base/BaseTextInput/BaseTextInput BaseTextInput}.
 * @vue-prop {string} [name] - Vide {@link module:components/base/BaseTextInput/BaseTextInput BaseTextInput}.
 * @vue-prop {boolean} [disabled] - Vide {@link module:components/base/BaseTextInput/BaseTextInput BaseTextInput}.
 * @vue-prop {boolean} [required] - Vide {@link module:components/base/BaseTextInput/BaseTextInput BaseTextInput}.
 * @vue-prop {string} [placeholder] - Vide {@link module:components/base/BaseTextInput/BaseTextInput BaseTextInput}.
 * @vue-prop {string} [label] - Vide {@link module:components/base/BaseTextInput/BaseTextInput BaseTextInput}.
 * @vue-prop {string|Array|object} [labelClass] - Vide {@link module:components/base/BaseTextInput/BaseTextInput BaseTextInput}.
 * @vue-prop {boolean} [showIcon] - Vide {@link module:components/base/BaseTextInput/BaseTextInput BaseTextInput}.
 * @vue-prop {string} [iconName] - Vide {@link module:components/base/BaseTextInput/BaseTextInput BaseTextInput}.
 * @vue-prop {string} [iconPosition] - Vide {@link module:components/base/BaseTextInput/BaseTextInput BaseTextInput}.
 * @vue-prop {function} [iconTitle] - Vide {@link module:components/base/BaseTextInput/BaseTextInput BaseTextInput}.
 * @vue-prop {function} [iconClick] - Vide {@link module:components/base/BaseTextInput/BaseTextInput BaseTextInput}.
 * @vue-prop {boolean} [autofocus] - Vide {@link module:components/base/BaseTextInput/BaseTextInput BaseTextInput}.
 * @vue-prop {object} autocompleteOptions - Opções aceitas pelo _autocomplete_
 * do {@link external:Materialize Materialize}. Sempre que a propriedade `data`
 * deste objeto é alterada, o componente de _autocomplete_ também é alterado
 * para refletir essa mudança.
 * @vue-prop {string} [value] - Valor inicial do componente e utilizado
 * internamente para a diretiva `v-model` do {@link external:Vue Vue}.
 * @vue-event {string} onInput - Emite o valor atual do componente. Pode ser
 * capturado pela diretiva `v-on:input` (ou `v-model`) do {@link external:Vue Vue}.
 * @vue-event {string} onChange - Emite o valor atual do componente. Pode ser
 * capturado pela diretiva `v-on:change` do {@link external:Vue Vue}.
 *
 * @example
 * <BaseAutocomplete
 *  id="autocomplete-empresas"
 *  v-bind:autocomplete-options="{ data: { Google: null, Microsoft: null, Facebook: null } }">
 *  Empresas
 * </BaseAutocomplete>
 */
import { DEFAULT_COLOR, DEFAULT_LUMINOSITY } from "../../../constants";
import {
  createStyleElement,
  getMaterializeColorCode
} from "../../../utils/web";

export default {
  name: "BaseAutocomplete",
  inheritAttrs: false,
  props: {
    gridExpression: {
      type: String,
      default: "col s12"
    },
    id: {
      type: String,
      required: true
    },

    name: String,
    disabled: Boolean,
    required: Boolean,
    placeholder: String,
    label: String,
    labelClass: [String, Array, Object],
    showIcon: Boolean,
    iconName: String,
    iconPosition: String,
    iconTitle: String,
    iconClick: Function,
    autofocus: Boolean,

    autocompleteOptions: {
      type: Object,
      required: true
    },

    value: String
  },
  data() {
    return {
      instance: null
    };
  },
  computed: {
    listeners() {
      return Object.assign({}, this.$listeners, { input: this.onInput });
    }
  },
  watch: {
    "autocompleteOptions.data"(newData) {
      if (this.instance) {
        this.instance.updateData(newData);
      }
    }
  },
  beforeCreate() {
    if (!window.M) {
      window.console.error(
        "Objeto do Materialize não encontrado. Verifique se essa biblioteca foi carregada corretamente"
      );
    }
  },
  created() {
    this.overrideMaterializeStyle();
  },
  mounted() {
    if (!window.M) {
      return;
    }

    const input = document.getElementById(this.id);

    if (input) {
      const autocompleteOptions = Object.assign({}, this.autocompleteOptions);

      const originalOnAutocomplete = autocompleteOptions.onAutocomplete;

      autocompleteOptions.onAutocomplete =
        originalOnAutocomplete && typeof originalOnAutocomplete === "function"
          ? item => {
              originalOnAutocomplete(item);
              this.onAutocomplete(item);
            }
          : item => this.onAutocomplete(item);

      this.instance = window.M.Autocomplete.init(input, autocompleteOptions);
    }
  },
  beforeDestroy() {
    if (this.instance) {
      this.instance.destroy();
    }
  },
  methods: {
    overrideMaterializeStyle() {
      const colorCode = getMaterializeColorCode(
        DEFAULT_COLOR,
        DEFAULT_LUMINOSITY
      );

      if (colorCode) {
        const CSSStyleSheet = `
        .dropdown-content li > a,
        .dropdown-content li > span {
          color: ${colorCode};
        }
      `;

        // Utiliza-se o mesmo `id` de stylesheet do BaseDropdown
        createStyleElement({
          id: "stylesheet-dropdown",
          type: "text/css",
          innerHTML: CSSStyleSheet
        });
      }
    },
    onAutocomplete(item) {
      this.onInput(item);
      this.onChange(item);
    },
    onInput(event) {
      this.$emit("input", event);
    },
    onChange(event) {
      this.$emit("change", event);
    }
  }
};
</script>

<style scoped>
.contrast .autocomplete-wrapper /deep/ .dropdown-content li:hover,
.contrast .autocomplete-wrapper /deep/ .dropdown-content li.active {
  background-color: white !important;
  color: black !important;
}
.contrast .autocomplete-wrapper /deep/ .dropdown-content li:hover a,
.contrast .autocomplete-wrapper /deep/ .dropdown-content li.active a,
.contrast .autocomplete-wrapper /deep/ .dropdown-content li:hover span,
.contrast .autocomplete-wrapper /deep/ .dropdown-content li.active span {
  color: black !important;
}
</style>
