<template>
  <section class="section">
    <component v-bind:is="tag" class="pae-title pae-header">
      <i v-if="secao.icone" class="material-icons left">{{ secao.icone }}</i
      >{{ secao.nome }}
    </component>

    <BaseInfoCard v-if="modeloSecao.instrucoes" title="Seção de galeria">
      <p>{{ modeloSecao.instrucoes }}</p>
    </BaseInfoCard>

    <BaseProgressBar v-if="loading" v-bind="progressBar" />
    <p v-else-if="!hasGaleria" class="flow-text center">
      Nenhuma imagem foi cadastrada.
    </p>
    <BaseEditableCollection v-else v-bind="form.galeriaCollection" />

    <div class="right-align">
      <BaseButton
        v-bind="form.buttonAdd"
        v-bind:disabled="readonly || disabled"
        style="margin-right: 20px"
        v-on:click="add"
      />
      <BaseButton
        v-bind="form.buttonSave"
        v-bind:disabled="readonly || disabled"
        v-on:click="save"
      />
    </div>
  </section>
</template>

<script>
import { isEmpty } from "../../../utils/lang";
import {
  EstandeService,
  EventoService,
  ModeloService,
  PermissaoService,
  TipoModeloService
} from "../../../services";
import EditableSectionGaleriaItem from "./EditableSectionGaleriaItem.vue";

const equalsGaleriaItem = (a, b) => {
  if ((!a && b) || (a && !b)) {
    return false;
  }

  if (!a && !b) {
    return true;
  }

  return (
    a.nome === b.nome && a.descricao === b.descricao && a.ordem === b.ordem
  );
};

const equalsGaleriaItemImagem = (a, b) => {
  if ((!a && b) || (a && !b)) {
    return false;
  }

  if (!a && !b) {
    return true;
  }

  return (
    a.nome === b.nome &&
    a.tamanho === b.tamanho &&
    a.tipo === b.tipo &&
    a.conteudo === b.conteudo
  );
};

export default {
  name: "EditableSectionGaleria",
  inheritAttrs: false,
  props: {
    readonly: Boolean,

    tag: {
      type: String,
      default: "h3",
      validator: value =>
        ["h1", "h2", "h3", "h4", "h5", "h6"].indexOf(value) !== -1
    },
    entidadeId: {
      type: Number,
      required: true
    },
    modelo: {
      type: Object,
      required: true
    },
    secao: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      disabled: false,
      service: null,
      modeloSecao: ModeloService.findModeloSecao(
        this.modelo,
        this.secao.modeloSecaoId
      ),
      progressBar: {
        useContainer: false
      },
      form: {
        galeriaCollection: {
          component: EditableSectionGaleriaItem,
          itemOperations: [
            {
              iconName: "delete",
              color: "red",
              disabled: false,
              title: "Excluir imagem da galeria",
              onClick: this.delete
            }
          ],
          isSortable: true,
          sortMimeType: "application/galeria-item",
          items: []
        },
        buttonAdd: {
          iconName: "add_photo_alternate",
          content: "Nova",
          disabled: this.readonly
        },
        buttonSave: {
          iconName: "save",
          content: "Salvar",
          disabled: this.readonly
        }
      },
      galeriaOriginal: []
    };
  },
  computed: {
    hasGaleria() {
      return !isEmpty(this.form.galeriaCollection.items);
    },
    isGestorEstandes() {
      return this.$authorization.hasPermission(PermissaoService.GESTAO_ESTANDE);
    },
    isGestorEventos() {
      return this.$authorization.hasPermission(PermissaoService.GESTAO_EVENTO);
    },
    isReadonly() {
      return this.modelo.tipoModeloId === TipoModeloService.EVENTO
        ? !this.isGestorEventos
        : !this.isGestorEstandes;
    }
  },
  watch: {
    readonly: {
      immediate: true,

      handler(newValue) {
        (this.form.galeriaCollection.items || []).forEach(
          item => (item.readonly = newValue)
        );
      }
    }
  },
  created() {
    if (this.modelo.tipoModeloId === TipoModeloService.EVENTO) {
      this.service = EventoService;
    } else if (this.modelo.tipoModeloId === TipoModeloService.ESTANDE) {
      this.service = EstandeService;
    } else {
      this.$notification.pushError("Tipo de modelo de página inválido");
      return;
    }

    this.getGaleriaItens();
  },
  methods: {
    getGaleriaItens() {
      if (!this.entidadeId) {
        return;
      }

      this.loading = true;

      this.form.galeriaCollection.items = [];

      const id = this.entidadeId;

      let galeria = null;

      this.service
        .getGaleriaItens(id)
        .then(response => {
          galeria = response.data || [];

          return Promise.allSettled(
            galeria.map(item =>
              this.service.getImagemOfGaleriaItem(id, item.id, {
                validateStatus: status =>
                  this.$http.isSuccessfulStatus(status) ||
                  status === this.$http.HTTP_STATUS_CLIENT_ERROR_NOT_FOUND
              })
            )
          );
        })
        .then(responses => {
          responses.map((item, index) => {
            if (item.status === "fulfilled") {
              galeria[index].imagem = item.value.data;
            } else {
              // TODO: verificar se a mensagem de erro não deve ser silenciosa
              window.console.error(
                `Houve um erro ao obter a imagem: ${item.reason.message}`
              );
            }
          });

          this.form.galeriaCollection.items = galeria.map(item => ({
            id: item.id,
            readonly: this.readonly,
            value: item
          }));

          this.galeriaOriginal = galeria.map(item =>
            this.$utils.Object.copy(item)
          );
        })
        .catch(error =>
          this.$notification.pushError(
            `Houve um erro ao obter os itens da galeria: ${error.message}`
          )
        )
        .finally(() => (this.loading = false));
    },
    add() {
      if (!this.readonly) {
        this.form.galeriaCollection.items.push({
          id: null,
          readonly: this.readonly,
          value: {
            id: null,
            nome: null,
            descricao: null,
            // Quando adiciona-se um registro, a propriedade `imagem` pode ser, inicialmente, `null`
            imagem: null
          }
        });
      }
    },
    delete(item, index) {
      if (!this.readonly && index >= 0) {
        this.form.galeriaCollection.items.splice(index, 1);
      }
    },
    getPostData() {
      const postData = [];
      const { items } = this.form.galeriaCollection;

      items.forEach((item, index) => {
        if (!item.readonly && !item.value.id) {
          postData.push({
            ...item.value,
            ordem: index + 1
          });
        }
      });

      return postData;
    },
    getPutData() {
      const putData = [];
      const { items } = this.form.galeriaCollection;

      items.forEach((item, index) => {
        if (item.value.id) {
          const obj = this.galeriaOriginal.find(
            originalItem => originalItem.id === item.value.id
          );

          if (obj) {
            const galeriaItem = { ...item.value, ordem: index + 1 };

            const equalItems = equalsGaleriaItem(obj, galeriaItem);
            const equalImages = equalsGaleriaItemImagem(
              obj.imagem,
              galeriaItem.imagem
            );

            if (!equalItems || !equalImages) {
              // Não envia a imagem caso ela não tenha sido alterada
              if (equalImages) {
                delete galeriaItem.imagem;
              }

              putData.push(galeriaItem);
            }
          }
        }
      });

      return putData;
    },
    getDeleteData() {
      const deleteData = [];
      const { items } = this.form.galeriaCollection;

      this.galeriaOriginal.forEach(originalItem => {
        const isItemFound =
          items.findIndex(item => originalItem.id === item.value.id) >= 0;

        if (!isItemFound) {
          deleteData.push({ ...originalItem });
        }
      });

      return deleteData;
    },
    validate() {
      const errors = [];

      (this.form.galeriaCollection.items || []).forEach((item, index) => {
        const imgNo = index + 1;

        if (isEmpty(item.value.nome)) {
          errors.push(
            new Error(`${imgNo}º item de galeria possui nome inválido`)
          );
        }

        const imagem = item.value.imagem;

        if (!imagem) {
          errors.push(
            new Error(`${imgNo}º item de galeria não possui imagem definida`)
          );
        } else {
          if (
            !(imagem.nome && imagem.tamanho && imagem.tipo && imagem.conteudo)
          ) {
            errors.push(
              new Error(`${imgNo}º item de galeria possui uma imagem inválida`)
            );
          }
        }
      });

      return errors;
    },
    isUnique() {
      // TODO: verificar se deve permitir imagens duplicadas
      const obj = {};

      this.form.galeriaCollection.items.forEach(item => {
        const key = item.value.nome;

        if (!obj[key]) {
          obj[key] = 0;
        }

        obj[key]++;
      });

      return Object.values(obj).findIndex(item => item > 1) === -1;
    },
    save() {
      if (this.readonly) {
        return;
      }

      const errors = this.validate();

      if (errors.length) {
        errors.forEach(item => this.$notification.pushError(item.message));
        return;
      }

      if (!this.isUnique()) {
        this.$notification.pushError("Alguns itens da galeria estão repetidos");
        return;
      }

      const galeriaOriginal = this.galeriaOriginal;
      const galeriaCollection = this.form.galeriaCollection.items;

      const postData = this.getPostData();
      const putData = this.getPutData();
      const deleteData = this.getDeleteData();

      const id = this.entidadeId;

      const saveData = [].concat(
        postData.map(item =>
          this.service.createGaleriaItem(id, item).then(response => {
            const galeria = response.data;

            // Atualiza os registros originais cujos correspondentes foram
            // criados com sucesso para que, em caso de erro, os mesmos não
            // sejam reenviados ao servidor desnecessariamente.
            if (galeria && galeria.id) {
              const g = { ...galeria };

              for (let i = 0; i < galeriaCollection.length; i++) {
                const gc = galeriaCollection[i].value;

                if (!gc.id && gc.nome === g.nome) {
                  gc.id = g.id;
                  gc.descricao = g.descricao;
                  gc.ordem = g.ordem;

                  g.imagem = { ...gc.imagem };
                  break;
                }
              }

              galeriaOriginal.push(g);
            }

            this.$notification.pushSuccess(
              `Item "${item.nome}" da galeria cadastrado com sucesso`
            );
          })
        ),
        putData.map(item =>
          this.service.updateGaleriaItem(id, item.id, item).then(() => {
            // Atualiza os registros originais cujos correspondentes foram
            // atualizados com sucesso para que, em caso de erro, os mesmos
            // não sejam reenviados ao servidor desnecessariamente.
            const g = { ...item };

            for (let i = 0; i < galeriaOriginal.length; i++) {
              const original = galeriaOriginal[i];

              if (original.id === g.id) {
                original.nome = g.nome;
                original.descricao = g.descricao;
                original.ordem = g.ordem;

                if (g.imagem) {
                  original.imagem = { ...g.imagem };
                }

                break;
              }
            }

            this.$notification.pushSuccess(
              `Item "${item.nome}" da galeria atualizado com sucesso`
            );
          })
        ),
        deleteData.map(item =>
          this.service.deleteGaleriaItem(id, item.id).then(() => {
            const id = item.id;

            // Atualiza os registros originais cujos correspondentes foram
            // excluídos com sucesso para que, em caso de erro, os mesmos não
            // sejam reenviados ao servidor desnecessariamente.
            for (let i = 0; i < galeriaOriginal.length; i++) {
              if (galeriaOriginal[i].id === id) {
                galeriaOriginal.splice(i, 1);
                break;
              }
            }

            this.$notification.pushSuccess(
              `Item "${item.nome}" da galeria excluído com sucesso`
            );
          })
        )
      );

      if (saveData.length) {
        this.disabled = true;

        Promise.all(saveData)
          .catch(error =>
            this.$notification.pushError(
              `Houve um erro ao salvar os itens da galeria: ${error.message}`
            )
          )
          .finally(() => (this.disabled = false));
      }
    }
  }
};
</script>
