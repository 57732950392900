/**
 * @module services/Modelo
 * @category Serviços
 * @summary Módulo de importação do serviço de modelo/template.
 *
 * @description
 * Expõe o serviço de modelo/template do sistema para utilização interna.
 *
 * @example <caption>Importando o serviço de modelo/template como membro default do módulo</caption>
 * import ModeloService from "./services/Modelo";
 *
 * @example <caption>Importando o serviço de modelo/template como membro do módulo</caption>
 * import { ModeloService } from "./services/Modelo";
 */
import { default as ModeloService } from "./Modelo";

export default ModeloService;

export { ModeloService };
