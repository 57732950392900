<template>
  <div v-bind:id="id" ref="modal" v-bind:class="classes">
    <div class="modal-content">
      <slot name="header"></slot>
      <slot></slot>
    </div>
    <div class="modal-footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
/**
 * @module components/base/BaseModal/BaseModal
 * @category Componentes-base
 * @summary _Single File Component_ (SFC) de _modal_ do {@link external:Materialize Materialize}.
 *
 * @description
 * O _slot_ "header" recebe o cabeçalho do _modal_. O footer deve ser inserido no
 * _slot_ de nome correspondente. O _slot_ _default_ recebe o conteúdo do _modal_.
 *
 * Para correto funcionamento, é necessário que o _framework_ {@link external:Materialize Materialize}
 * tenha sido importado, tornando seu objeto principal acessível globalmente.
 *
 * @vue-prop {string} id - Atributo `id` do _modal_ referenciado pelo ativador
 * do _modal_.
 * @vue-prop {boolean} [hasFixedFooter=false] - Indica se o _modal_ terá tamanho
 * fixo, exibindo sempre seu rodapé.
 * @vue-prop {boolean} [isBottomSheet=false] - Indica se o _modal_ será exibido
 * no fundo da página.
 * @vue-prop {object} [modalOptions] - Opções aceitas pelo `modal` do {@link external:Materialize Materialize}.
 *
 * @example
 * <a href="#modal1" class="btn modal-trigger">Abrir Modal</a>
 *
 * <BaseModal id="modal1">
 *  <template v-slot:header>
 *    <h3>Título</h3>
 *  </template>
 *  <template v-slot:default>
 *    <p>Conteúdo do modal</p>
 *  </template>
 *  <template v-slot:footer>
 *    <BaseButton class="modal-close" material-type="flat">Fechar</BaseButton>
 *  </template>
 * </BaseModal>
 */
export default {
  name: "BaseModal",
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      required: true
    },
    hasFixedFooter: {
      type: Boolean,
      default: false
    },
    isBottomSheet: {
      type: Boolean,
      default: false
    },

    modalOptions: Object
  },
  data() {
    return {
      instance: null
    };
  },
  computed: {
    classes() {
      let classes = [];

      classes.push("modal");

      if (this.hasFixedFooter) {
        classes.push("modal-fixed-footer");
      }

      if (this.isBottomSheet) {
        classes.push("bottom-sheet");
      }

      return classes;
    }
  },
  beforeCreate() {
    if (!window.M) {
      window.console.error(
        "Objeto do Materialize não encontrado. Verifique se essa biblioteca foi carregada corretamente"
      );
    }
  },
  mounted() {
    if (!window.M) {
      return;
    }

    const { modal } = this.$refs;

    if (modal) {
      const modalOptions = Object.assign({}, this.modalOptions);

      //   if (modalOptions.dismissible === undefined) {
      //     modalOptions.dismissible = false;
      //   }

      this.instance = window.M.Modal.init(modal, modalOptions);
    }
  },
  beforeDestroy() {
    if (this.instance) {
      this.instance.destroy();
    }
  }
};
</script>
