/**
 * @module services/Escolaridade/Escolaridade
 * @category Serviços
 * @summary Módulo do serviço de escolaridade.
 *
 * @description
 * Expõe o objeto/_namespace_ do serviço de escolaridade do sistema para
 * utilização interna.
 *
 * Para correto funcionamento, é necessário que o _plug-in_ de requisições HTTP
 * seja registrado a este serviço.
 *
 * @requires module:constants.API_URL
 *
 * @example
 * import EscolaridadeService from "./services/Escolaridade/Escolaridade";
 *
 * // Registrando o _plug-in_ de requisiçõe HTTP do container.
 * EscolaridadeService.registerHttp(Http);
 *
 * // Obtendo a lista de escolaridades
 * EscolaridadeService.getAll()
 *  .then(response => {
 *    // ...
 *  })
 *  .catch(error => {
 *    // ...
 *  })
 */
import { API_URL } from "../../constants";

/**
 * @namespace EscolaridadeService
 * @category Serviços
 * @summary Objeto/_namespace_ do serviço de escolaridade.
 */
const EscolaridadeService = {
  /**
   * URL de acesso à API para obtenção de escolaridades.
   * @type {string}
   * @private
   * @readonly
   */
  baseUrl: `${API_URL}/escolaridades`,

  /**
   * _Plug-in_ de requisições HTTP.
   * @type {module:plugins/Http/Http}
   * @private
   * @readonly
   */
  http: null,

  /**
   * Envia uma requisição para obter todas as escolaridades, retornando uma
   * Promise.
   * @returns {external:Promise}
   */
  getAll() {
    return this.http.get(`${this.baseUrl}/`);
  },

  /**
   * Envia uma requisição para obter uma escolaridade, retornando uma Promise.
   * @param {number} id - Identificador da escolaridade.
   * @returns {external:Promise}
   */
  get(id) {
    return this.http.get(`${this.baseUrl}/${id}`);
  },

  /**
   * Registra o _plug-in_ de requisições HTTP no _namespace_ do serviço.
   * @param {module:plugins/Http/Http} http - _Plug-in_ de requisições HTTP.
   * @returns {module:services/Escolaridade/Escolaridade~EscolaridadeService} O próprio namespace.
   */
  registerHttp(http) {
    this.http = http;
    return this;
  }
};

export default EscolaridadeService;
