/**
 * @module utils/web/Storage
 * @category Utilidades
 * @summary Módulo de utilidades para manipulação de {@link external:Storage Storage} dos tipos "local" e "session".
 *
 * @description
 * Expõe um _namespace_ com funções de utilidades para manipulação de {@link external:Storage Storage}
 * dos tipos "local" e "session".
 *
 * @requires module:utils/web/Storage/Local~Local
 * @requires module:utils/web/Storage/Session~Session
 */
import { default as Local } from "./Local.js";
import { default as Session } from "./Session.js";

/**
 * @namespace Storage
 * @category Plug-ins
 * @summary Objeto/_namespace_ do _plug-in_ de utilidades para manipulação de {@link external:Storage Storage} dos tipos "local" e "session".
 *
 * @requires module:utils/web/Storage/Local~Local
 * @requires module:utils/web/Storage/Session~Session
 */
const Storage = {
  /**
   * Objeto que fornece funções utilitárias para manipulação de {@link external:Storage Storage} do tipo "local".
   * @type {object}
   * @readonly
   */
  Local: Object.freeze(Object.assign({}, Local)),

  /**
   * Objeto que fornece funções utilitárias para manipulação de {@link external:Storage Storage} do tipo "session".
   * @type {object}
   * @readonly
   */
  Session: Object.freeze(Object.assign({}, Session))
};

export default Storage;

export { Local, Session };
