const i18n = Object.freeze({
  "pt-BR": {
    cancel: "Cancelar",
    clear: "Limpar",
    done: "Ok",
    previousMonth: "\xe2\x80\xb9",
    nextMonth: "\xe2\x80\xba",
    months: [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro"
    ],
    monthsShort: [
      "Jan",
      "Fev",
      "Mar",
      "Abr",
      "Mai",
      "Jun",
      "Jul",
      "Ago",
      "Set",
      "Out",
      "Nov",
      "Dez"
    ],
    weekdays: [
      "Domingo",
      "Segunda",
      "Terça",
      "Quarta",
      "Quinta",
      "Sexta",
      "Sábado"
    ],
    weekdaysShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
    weekdaysAbbrev: ["D", "S", "T", "Q", "Q", "S", "S"]
  }
});

export default i18n;

export { i18n };
