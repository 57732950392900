<template>
  <div
    v-if="type === 'links'"
    class="collection"
    v-bind:class="{ 'with-header': hasSlotHeader }"
  >
    <div v-if="hasSlotHeader" class="collection-header">
      <slot name="header"></slot>
    </div>
    <a
      v-for="(item, index) in items"
      v-bind:key="index"
      v-bind:href="item.href || '#!'"
      class="collection-item"
      v-bind:class="{ avatar: item.avatar }"
      v-on:click="onClick($event, item)"
    >
      <img v-if="(item.avatar || {}).image" v-bind="item.avatar.image" />
      <i
        v-else-if="item.iconName"
        class="material-icons left"
        v-bind:class="[{ circle: item.avatar }, (item.avatar || {}).iconColor]"
        >{{ item.iconName }}</i
      >

      <span v-bind:class="{ title: item.avatar }">{{ item.title }}</span>

      <p v-if="item.avatar">{{ item.content }}</p>
    </a>

    <slot name="items"></slot>
  </div>
  <ul v-else class="collection" v-bind:class="{ 'with-header': hasSlotHeader }">
    <li v-if="hasSlotHeader" class="collection-header">
      <slot name="header"></slot>
    </li>
    <li
      v-for="(item, index) in items"
      v-bind:key="index"
      class="collection-item"
      v-bind:class="{ avatar: item.avatar }"
      v-on:click="onClick($event, item)"
    >
      <div>
        <img v-if="(item.avatar || {}).image" v-bind="item.avatar.image" />
        <i
          v-else-if="item.iconName"
          class="material-icons left"
          v-bind:class="[
            { circle: item.avatar },
            (item.avatar || {}).iconColor
          ]"
          >{{ item.iconName }}</i
        >

        <span v-bind:class="{ title: item.avatar }">{{ item.title }}</span>

        <p v-if="item.avatar">{{ item.content }}</p>

        <span v-if="item.secondaryContent" class="secondary-content">
          <BaseRouterLink
            v-if="item.secondaryContent.to"
            v-bind:to="item.secondaryContent.to"
            v-bind:icon-name="item.secondaryContent.iconName"
          />
          <a
            v-else
            v-bind:href="item.secondaryContent.href || '#!'"
            v-on:click="secondaryContentOnClick($event, item)"
          >
            <i v-if="item.secondaryContent.iconName" class="material-icons">{{
              item.secondaryContent.iconName
            }}</i>
          </a>
        </span>
      </div>
    </li>

    <slot name="items"></slot>
  </ul>
</template>

<script>
/**
 * @module components/base/BaseCollection/BaseCollection
 * @category Componentes-base
 * @summary _Single File Component_ (SFC) de coleções do {@link external:Materialize Materialize}.
 *
 * @description
 * As cores da coleção do tipo "_link_" e do conteúdo secundário são obtidas
 * automaticamente a partir da configuração do ambiente.
 *
 * O _slot_ "header" recebe o cabeçalho da coleção. O _slot_ "items" serve
 * para exibir os itens da coleção, em situações em que eles não estejam de
 * acordo com a estrutura padrão. Este componente não possui _slot_ _default_.
 *
 * @requires module:constants.DEFAULT_COLOR
 * @requires module:constants.DEFAULT_LUMINOSITY
 * @requires module:utils/web.createStyleElement
 * @requires module:utils/web.getMaterializeColorCode
 * @requires module:utils/web.lighten
 *
 * @vue-prop {string} [type="basic"] - Tipo de coleção a ser exibida. Aceita
 * "basic" e "links". No segundo caso, não há a exibição de conteúdo
 * secundário (ícones à direita).
 * @vue-prop {boolean} [showIcon=true] - Indica se o componente deve exibir os
 * ícones.
 * @vue-prop {Array<object>} [items=[]] - Itens a serem exibidos na coleção.
 * @vue-prop {string} [items[].title] - Título do item.
 * @vue-prop {string} [items[].iconName] - Nome do [ícone do Material Design]{@link external:MaterialDesignIcons}
 * a ser exibido à esquerda do título do item.
 * @vue-prop {function} [items[].onClick] - Função que será associada ao evento
 * de _click_ do item.
 * @vue-prop {object} [items[].secondaryContent] - Objeto com opções de
 * conteúdo secundário do item.
 * @vue-prop {string} [items[].secondaryContent.href] - URL do _link_ do
 * conteúdo secundário. Indicado para acesso a _sites_ externos.
 * @vue-prop {string} [items[].secondaryContent.to] - Rota alvo do _link do
 * conteúdo secundário. Indicado para alterar a rota no sistema.
 * @vue-prop {string} [items[].secondaryContent.iconName] - Nome do [ícone do Material Design]{@link external:MaterialDesignIcons}
 * a ser exibido no conteúdo secundário.
 * @vue-prop {function} [items[].secundaryContent.onClick] - Função que será
 * associada ao evento de _click_ do evento secundário.
 * @vue-prop {object} [items[].avatar] - Objeto com opções para quando o item
 * possui leiaute de avatar. Caso seja preenchido, aplica-se no item a classe
 * CSS "avatar" do {@link external:Materialize Materialize}.
 * @vue-prop {object} [items[].avatar.image] - Objeto com opções para a imagem
 * a ser exibida como avatar. Aceita os atributos do elemento HTML `img`
 * (`src`, `alt`, `title`, etc).
 * @vue-prop {string} [items[].avatar.iconColor] - Cor de fundo para o ícone
 * (definido pela propriedade `items[].iconName`) a ser exibido como avatar.
 * Deve ser preenchido com uma classe CSS da paleta de cores do {@link external:Materialize Materialize}.
 * Utilizado apenas quando `items[].avatar.image` não foi preenchido.
 * @vue-prop {string} [items[].content] - Conteúdo exibido abaixo do
 * título do item. Utilizado apenas quando `items[].avatar` foi preenchido.
 *
 * @example <caption>Informando os itens por props</caption>
 * <BaseCollection v-bind:items="[{ title: 'CCMN' }, ..., { title: 'CCS' }]">
 *  <template v-slot:header>
 *    Locais
 *  </template>
 * </BaseCollection>
 *
 * @example <caption>Informando os itens por slot</caption>
 * <BaseCollection>
 *  <template v-slot:items>
 *    <li class="collection-item">CCMN</li>
 *    <li class="collection-item">CCS</li>
 *    ...
 *  </template>
 * </BaseCollection>
 */
import { DEFAULT_COLOR, DEFAULT_LUMINOSITY } from "../../../constants";
import {
  createStyleElement,
  getMaterializeColorCode,
  lighten
} from "../../../utils/web";

export default {
  name: "BaseCollection",
  inheritAttrs: false,
  props: {
    type: {
      type: String,
      default: "basic",
      validator: value => ["basic", "links"].indexOf(value) >= 0
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    hasSlotHeader() {
      return this.$slots.header && this.$slots.header.length;
    }
  },
  created() {
    this.overrideMaterializeStyle();
  },
  methods: {
    overrideMaterializeStyle() {
      const colorCode = getMaterializeColorCode(
        DEFAULT_COLOR,
        DEFAULT_LUMINOSITY
      );

      if (colorCode) {
        const CSSStyleSheet = `
        .collection a.collection-item {
          color: ${colorCode}; /* $collection-link-color ($secondary-color) */
        }
        .collection a.collection-item.active {
          background-color: ${colorCode}; /* $collection-active-bg-color ($secondary-color) */
          color: ${lighten(
            colorCode,
            0.55
          )}; /* $collection-active-color (lighten($secondary-color, 55%)) */
        }
        .secondary-content {
          color: ${colorCode}; /* $secondary-color */
        }
      `;

        createStyleElement({
          id: "stylesheet-collection",
          type: "text/css",
          innerHTML: CSSStyleSheet
        });
      }
    },
    onClick(event, item) {
      if (item.onClick && typeof item.onClick === "function") {
        item.onClick(event);
      }
    },
    secondaryContentOnClick(event, item) {
      if (
        item.secondaryContent &&
        item.secondaryContent.onClick &&
        typeof item.secondaryContent.onClick === "function"
      ) {
        item.secondaryContent.onClick(event);
      }
    }
  }
};
</script>

<style scoped>
.contrast .collection a.collection-item {
  background-color: black;
  color: yellow !important;
}
.contrast .collection a.collection-item span {
  color: yellow !important;
}
.contrast .collection a.collection-item.active {
  background-color: white;
  color: black !important;
}
.contrast .collection a.collection-item.active span {
  color: black !important;
}
</style>
