<template>
  <article class="container">
    <header>
      <h1 class="thin center">
        {{ isCreate ? "Criação" : "Edição" }} de estande
      </h1>
    </header>

    <BaseProgressBar v-if="loading" v-bind="progressBar" />

    <div v-if="!loading">
      <EditableSectionPrincipal
        v-bind="principal"
        v-bind:readonly="isReadonly"
        v-on:statuschange="onStatusChange"
        v-on:save="onSave"
      />
    </div>

    <div v-if="mayShowSections">
      <EditableSection
        v-for="(item, index) in secoes"
        v-bind:key="index"
        v-bind="item"
        v-bind:readonly="isReadonly"
      />
    </div>

    <div v-if="mayShowSections" class="fixed-action-btn back-btn">
      <BaseButton
        class="btn-floating"
        v-bind="backButton"
        v-on:click="backButton.onClick"
      />
    </div>
    <div v-if="mayShowSections" class="fixed-action-btn top-btn">
      <BaseButton
        class="btn-floating"
        v-bind="topButton"
        v-on:click="topButton.onClick"
      />
    </div>
  </article>
</template>

<script>
import { APP_INFO } from "../constants";
import { isEmpty } from "../utils/lang";
import {
  EventoService,
  EstandeService,
  ModeloService,
  PermissaoService,
  StatusService,
  TipoModeloService
} from "../services";
import EditableSectionPrincipal from "../components/Common/EditableSection/EditableSectionPrincipal.vue";
import EditableSection from "../components/Common/EditableSection/EditableSection.vue";

const TIPO_MODELO_ID = TipoModeloService.ESTANDE;

export default {
  name: "GestaoEstande",
  components: {
    EditableSectionPrincipal,
    EditableSection
  },
  inheritAttrs: false,
  data() {
    return {
      loading: false,
      principal: null,
      modelo: null,
      estande: null,
      evento: null,
      secoes: null,
      progressBar: {
        useContainer: false
      },
      backButton: {
        iconName: "arrow_back",
        size: "large",
        title: "Voltar para o evento",
        onClick: () =>
          this.$router.push({
            name: "editEvento",
            params: { idEvento: this.$route.params.idEvento }
          })
      },
      topButton: {
        iconName: "arrow_upward",
        size: "large",
        title: "Ir para o topo da página",
        onClick: () => window.scroll(0, 0)
      }
    };
  },
  computed: {
    isCreate() {
      return !this.$route.params.idEstande;
    },
    isStatusEditavel() {
      return this.estande && this.estande.statusId === StatusService.EM_EDICAO;
    },
    isGestorEventos() {
      return this.$authorization.hasPermission(PermissaoService.GESTAO_EVENTO);
    },
    isGestorEstandes() {
      return this.$authorization.hasPermission(PermissaoService.GESTAO_ESTANDE);
    },
    isReadonly() {
      return (
        (!this.isGestorEventos && !this.isGestorEstandes) ||
        !this.isStatusEditavel
      );
    },
    mayShowSections() {
      return !this.loading && this.estande && this.estande.id;
    }
  },
  created() {
    const { idEvento, idEstande } = this.$route.params;

    if (idEvento && idEstande) {
      //   this.$store.commit("setExtraNavbar", [
      //     { id: "estande-sobre", title: "Sobre" },
      //     { id: "estande-links", title: "Links" },
      //     { id: "estande-galeria", title: "Galeria" },
      //     { id: "estande-programacao", title: "Programação" },
      //     { id: "estande-extras", title: "Extras" }
      //   ]);

      this.getEstande(idEvento, idEstande);
    } else {
      this.principal = { tipoModeloId: TIPO_MODELO_ID, value: null };
    }
  },
  methods: {
    getEstande(idEvento, idEstande) {
      this.loading = true;

      this.principal = null;
      this.modelo = null;
      this.estande = null;
      this.evento = null;

      const tipoModeloId = TIPO_MODELO_ID;

      EventoService.get(idEvento)
        .then(response => {
          this.evento = response.data;

          return EventoService.getEstande(this.evento.id, idEstande);
        })
        .then(response => {
          this.estande = response.data;

          document.title = `${APP_INFO.name} - ${this.estande.nome}`;

          return EstandeService.getEditores(this.estande.id);
        })
        .then(response => {
          if (!this.hasAccess(response.data)) {
            this.$router.push({
              name: "editEvento",
              params: { idEvento: this.$route.params.idEvento },
              replace: true
            });

            throw new Error(
              "Você não possui permissão para gerenciar o estande"
            );
          }

          const id = this.estande.id;

          return Promise.all([
            ModeloService.get(this.estande.modeloId, { deep: true }),
            EstandeService.getSecoes(id),
            EstandeService.getBanner(id, {
              validateStatus: status =>
                this.$http.isSuccessfulStatus(status) ||
                status === this.$http.HTTP_STATUS_CLIENT_ERROR_NOT_FOUND
            }),
            EstandeService.getAssuntos(id)
          ]);
        })
        .then(response => {
          let i = 0;

          this.modelo = response[i++].data;

          this.secoes = [];

          (response[i++].data || []).forEach(item => {
            const entidadeId = this.estande.id;

            const secao = {
              entidadeId,
              modelo: this.modelo,
              value: { ...item }
            };

            this.secoes.push(secao);
          });

          this.estande.banner = response[i++].data;

          this.estande.assuntos = (response[i++].data || []).map(
            item => item.id
          );

          this.principal = { tipoModeloId, value: this.estande };
        })
        .catch(error => {
          this.$notification.pushError(
            `Houve um erro ao obter os dados do estande: ${error.message}`
          );

          if (!this.evento || !this.estande) {
            this.$router.push({ name: "adminEventos" });
          }
        })
        .finally(() => (this.loading = false));
    },
    onStatusChange(event) {
      const id = this.estande.id;

      EstandeService.setStatus(id, event)
        .then(() => {
          this.estande.statusId = event;

          this.$notification.pushSuccess(
            "Status do estande alterado com sucesso"
          );

          if (event === StatusService.PUBLICADO) {
            this.$router.push({ name: "adminEventos" });
          }
        })
        .catch(error =>
          this.$notification.pushError(
            `Houve um erro ao alterar o status do estande: ${error.message}`
          )
        );
    },
    onSave(event) {
      if (this.isReadonly) {
        return;
      }

      const errors = EstandeService.validate(event);

      if (errors.length) {
        errors.forEach(item => this.$notification.pushError(item.message));
        return;
      }

      const eventoId = this.evento.id;
      const modeloId = this.modelo.id;

      const { nome, inicio, termino, continuo, online, banner, assuntos } =
        event;

      const data = {
        nome,
        inicio,
        termino,
        continuo,
        online,
        modeloId,
        eventoId
      };

      if (this.isCreate) {
        this.createEstande(data);
      } else {
        this.updateEstande(data)
          .then(() => this.saveBanner(banner))
          .then(() => this.saveAssuntos(assuntos));
      }
    },
    hasAccess(editores) {
      if (!editores) {
        return false;
      }

      const userId = this.$store.state.user.id;

      const isAdmin = this.$authorization.isAdmin();

      return (
        isAdmin ||
        this.isGestorEventos ||
        editores.findIndex(item => item.id === userId) >= 0
      );
    },
    createEstande(estande) {
      const idEvento = this.$route.params.idEvento;

      this.estande = estande;
      this.estande.banner = null;
      this.estande.assuntos = [];

      return EventoService.createEstande(idEvento, estande)
        .then(response => {
          this.estande.id = response.data.id;
          this.estande.slug = response.data.slug;

          this.$notification.pushSuccess("Estande criado com sucesso");
        })
        .catch(error =>
          this.$notification.pushError(
            `Houve um erro ao criar o estande: ${error.message}`
          )
        );
    },
    updateEstande(estande) {
      const idEvento = this.evento.id;
      const idEstande = this.estande.id;

      if (
        this.estande.nome !== estande.nome ||
        this.estande.inicio !== estande.inicio ||
        this.estande.termino !== estande.termino ||
        !!this.estande.continuo !== !!estande.continuo ||
        !!this.estande.online !== !!estande.online
      ) {
        return EventoService.updateEstande(idEvento, idEstande, estande)
          .then(() => {
            this.estande.nome = estande.nome;
            this.estande.inicio = estande.inicio;
            this.estande.termino = estande.termino;
            this.estande.continuo = estande.continuo;
            this.estande.online = estande.online;

            this.$notification.pushSuccess(
              "Dados do estande atualizados com sucesso"
            );
          })
          .catch(error =>
            this.$notification.pushError(
              `Houve um erro ao atualizar os dados do estande: ${error.message}`
            )
          );
      }

      return Promise.resolve();
    },
    saveBanner(banner) {
      const id = this.estande.id;
      const oldBanner = this.estande.banner;

      let promise = null;

      if (oldBanner && !banner) {
        promise = EstandeService.deleteBanner(id);
      } else if (!oldBanner && !banner) {
        return Promise.resolve();
      } else if (!oldBanner && banner) {
        promise = EstandeService.setBanner(id, banner);
      } else if (
        oldBanner.tipo !== banner.tipo ||
        oldBanner.nome !== banner.nome ||
        oldBanner.tamanho !== banner.tamanho ||
        oldBanner.conteudo !== banner.conteudo
      ) {
        promise = EstandeService.setBanner(id, banner);
      } else {
        return Promise.resolve();
      }

      return promise
        .then(() => {
          this.evento.banner = banner;

          this.$notification.pushSuccess("Banner atualizado com sucesso");
        })
        .catch(error =>
          this.$notification.pushError(
            `Houve um erro ao atualizar o banner: ${error.message}`
          )
        );
    },
    saveAssuntos(assuntos) {
      const id = this.estande.id;
      const oldAssuntos = this.estande.assuntos;

      let putAssuntos = [];
      let deleteAssuntos = [];

      if (isEmpty(assuntos) && isEmpty(oldAssuntos)) {
        return Promise.resolve();
      } else if (isEmpty(assuntos)) {
        deleteAssuntos = oldAssuntos;
      } else if (isEmpty(oldAssuntos)) {
        putAssuntos = assuntos;
      } else {
        assuntos
          .filter(item => oldAssuntos.findIndex(a => a === item) === -1)
          .forEach(item => putAssuntos.push(item));

        oldAssuntos
          .filter(item => assuntos.findIndex(a => a === item) === -1)
          .forEach(item => deleteAssuntos.push(item));
      }

      return Promise.allSettled(
        putAssuntos.map(item => EstandeService.addAssunto(id, item))
      ).then(response => {
        if (response.length) {
          if (response.findIndex(item => item.reason) === -1) {
            this.$notification.pushSuccess("Assuntos associados com sucesso");
          } else {
            this.$notification.pushError(
              "Alguns assuntos não foram associados"
            );
          }

          response.forEach((item, index) => {
            if (!item.reason) {
              oldAssuntos.push(putAssuntos[index]);
            }
          });
        }

        return Promise.allSettled(
          deleteAssuntos.map(item => EstandeService.removeAssunto(id, item))
        ).then(response => {
          if (response.length) {
            if (response.findIndex(item => item.reason) === -1) {
              this.$notification.pushSuccess(
                "Assuntos desassociados com sucesso"
              );
            } else {
              this.$notification.pushError(
                "Alguns assuntos não foram desassociados"
              );
            }

            response.forEach((item, index) => {
              if (!item.reason) {
                oldAssuntos.splice(
                  oldAssuntos.indexOf(deleteAssuntos[index]),
                  1
                );
              }
            });
          }
        });
      });
    }
  }
};
</script>

<style scoped>
.top-btn {
  bottom: 94px;
}
</style>
