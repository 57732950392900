/**
 * @module components/base/BaseHelpInfotic
 * @category Componentes-base
 * @summary Módulo de importação do componente-base do botão "Help InfoTIC".
 *
 * @description
 * Expõe o componente-base do botão "Help InfoTIC" para utilização pelo container
 * e pelos módulos.
 *
 * @example <caption>Importando como plug-in</caption>
 * // main.js
 * import BaseHelpInfotic from "./components/base/BaseHelpInfotic";
 *
 * Vue.use(BaseHelpInfotic);
 *
 * @example <caption>Importando diretamente em um componente</caption>
 * // componente.vue
 * // ou import BaseHelpInfotic from "./components/base/BaseHelpInfotic/BaseHelpInfotic.vue";
 * import { BaseHelpInfotic } from "./components/base/BaseHelpInfotic";
 *
 * export default {
 *  name: // ...
 *  components: {
 *    BaseHelpInfotic
 *  },
 *  // ...
 * }
 */
import { default as BaseHelpInfotic } from "./BaseHelpInfotic.vue";

/**
 * Associa o componente-base do botão "Help InfoTIC" à instância do {@link external:Vue Vue}.
 * @param {external:Vue} Vue - Instância do {@link external:Vue Vue}.
 */
const install = Vue => Vue.component(BaseHelpInfotic.name, BaseHelpInfotic);

if (window && window.Vue) {
  install(window.Vue);
}

export default install;

export { BaseHelpInfotic };
