<template>
  <BaseTextInput
    v-bind:id="id"
    v-bind:grid-expression="gridExpression"
    class="timepicker-wrapper"
    v-bind:name="name"
    v-bind:disabled="disabled"
    v-bind:required="required"
    v-bind:placeholder="placeholder"
    v-bind:label-class="labelClass"
    v-bind:show-icon="showIcon"
    v-bind:icon-name="iconName"
    v-bind:icon-position="iconPosition"
    v-bind:icon-title="iconTitle"
    v-bind:icon-click="iconClick"
    v-bind:title="$attrs.title"
    v-bind:value="value"
    v-on="listeners"
  >
    <template v-slot:default>
      <slot>{{ label }}</slot>
    </template>

    <template v-slot:extra-content>
      <slot name="extra-content"></slot>
    </template>
  </BaseTextInput>
</template>

<script>
/**
 * @module components/base/BaseTimePicker/BaseTimePicker
 * @category Componentes-base
 * @summary _Single File Component_ (SFC) de _timepicker_ do {@link external:Materialize Materialize}.
 *
 * @description
 * Internamente, utiliza o componente {@link module:components/base/BaseTextInput/BaseTextInput BaseTextInput}, recebendo, em grande parte,
 * as mesmas props desse componente.
 *
 * As cores de marcação das horas e minutos, do painel lateral, do ponteiro e
 * dos botões são obtidas automaticamente a partir da configuração do ambiente.
 *
 * Os _slots_ _default_ e _extra-content_ são repassados para o do componente
 * {@link module:components/base/BaseTextInput/BaseTextInput BaseTextInput}.
 *
 * Atributos HTML de acessibilidade, e.g., `title`, podem ser usados
 * diretamente no componente.
 *
 * Para correto funcionamento, é necessário que o _framework_ {@link external:Materialize Materialize}
 * tenha sido importado, tornando seu objeto principal acessível globalmente.
 *
 * @requires module:components/base/BaseTextInput/BaseTextInput
 * @requires module:constants.DEFAULT_COLOR
 * @requires module:constants.DEFAULT_LUMINOSITY
 * @requires module:utils/web.createStyleElement
 * @requires module:utils/web.getMaterializeColorCode
 * @requires module:utils/web.transparentize
 *
 * @vue-prop {string} [gridExpression="col s12"] - Classes CSS de grid do {@link external:Materialize Materialize}.
 * @vue-prop {string} id - Vide {@link module:components/base/BaseTextInput/BaseTextInput BaseTextInput}.
 * @vue-prop {string} [name] - Vide {@link module:components/base/BaseTextInput/BaseTextInput BaseTextInput}.
 * @vue-prop {boolean} [disabled] - Vide {@link module:components/base/BaseTextInput/BaseTextInput BaseTextInput}.
 * @vue-prop {boolean} [required] - Vide {@link module:components/base/BaseTextInput/BaseTextInput BaseTextInput}.
 * @vue-prop {string} [placeholder] - Vide {@link module:components/base/BaseTextInput/BaseTextInput BaseTextInput}.
 * @vue-prop {string} [label] - Vide {@link module:components/base/BaseTextInput/BaseTextInput BaseTextInput}.
 * @vue-prop {string|Array|object} [labelClass] - Vide {@link module:components/base/BaseTextInput/BaseTextInput BaseTextInput}.
 * @vue-prop {boolean} [showIcon] - Vide {@link module:components/base/BaseTextInput/BaseTextInput BaseTextInput}.
 * @vue-prop {string} [iconName] - Vide {@link module:components/base/BaseTextInput/BaseTextInput BaseTextInput}.
 * @vue-prop {string} [iconPosition] - Vide {@link module:components/base/BaseTextInput/BaseTextInput BaseTextInput}.
 * @vue-prop {function} [iconTitle] - Vide {@link module:components/base/BaseTextInput/BaseTextInput BaseTextInput}.
 * @vue-prop {function} [iconClick] - Vide {@link module:components/base/BaseTextInput/BaseTextInput BaseTextInput}.
 * @vue-prop {object} [pickerOptions] - Opções aceitas pelo `timepicker`
 * do {@link external:Materialize Materialize}. A opção `twelveHour` e o idioma
 * (opção `i18n`) são automaticamente definidos para, respectivamente, o
 * valor `false` (para que seja utilizado o formato de 24 horas) e para "pt-BR"
 * quando eles não são previamente preenchidos.
 * @vue-prop {string} [value] - Valor inicial do componente e utilizado internamente
 * para a diretiva `v-model` do {@link external:Vue Vue}.
 * @vue-event {string} onInput - Emite o valor atual do componente. Pode ser
 * capturado pela diretiva `v-on:input` (ou `v-model`) do {@link external:Vue Vue}. Este evento
 * é emitido quando seu valor é alterado diretamente pelo usuário ou quando se
 * escolhe um horário pelo picker do {@link external:Materialize Materialize}.
 * @vue-event {string} onChange - Emite o valor atual do componente. Pode ser
 * capturado pela diretiva `v-on:change` do {@link external:Vue Vue}. Este evento
 * é apenas emitido quando se escolhe um horário pelo picker do {@link external:Materialize Materialize}.
 *
 * @example
 * <BaseTimePicker
 *  id="hora-cadastro"
 *  name="hora-cadastro"
 *  grid-expression="col s12 m6"
 *  v-model="processo.horaCadastro">
 *  Hora de cadastro
 * </BaseTimePicker>
 */
import i18n from "./BaseTimePicker.js";
import { DEFAULT_COLOR, DEFAULT_LUMINOSITY } from "../../../constants";
import {
  createStyleElement,
  getMaterializeColorCode,
  transparentize
} from "../../../utils/web";
import BaseTextInput from "../BaseTextInput/BaseTextInput.vue";

export default {
  name: "BaseTimePicker",
  components: {
    BaseTextInput
  },
  inheritAttrs: false,
  props: {
    gridExpression: {
      type: String,
      default: "col s12"
    },
    id: {
      type: String,
      required: true
    },

    name: String,
    disabled: Boolean,
    required: Boolean,
    placeholder: String,
    label: String,
    labelClass: [String, Array, Object],
    showIcon: Boolean,
    iconName: String,
    iconPosition: String,
    iconTitle: String,
    iconClick: Function,

    pickerOptions: Object,
    value: String
  },
  data() {
    return {
      instance: null
    };
  },
  computed: {
    listeners() {
      return Object.assign({}, this.$listeners, {
        input: this.onInput,
        change: this.onChange
      });
    }
  },
  created() {
    this.overrideMaterializeStyle();
  },
  beforeCreate() {
    if (!window.M) {
      window.console.error(
        "Objeto do Materialize não encontrado. Verifique se essa biblioteca foi carregada corretamente"
      );
    }
  },
  mounted() {
    if (!window.M) {
      return;
    }

    const input = document.getElementById(this.id);

    const pickerOptions = Object.assign({}, this.pickerOptions);

    pickerOptions.twelveHour = pickerOptions.twelveHour || false;

    pickerOptions.i18n = pickerOptions.i18n || i18n["pt-BR"];

    this.instance = window.M.Timepicker.init(input, pickerOptions);
  },
  beforeDestroy() {
    if (this.instance) {
      this.instance.destroy();
    }
  },
  methods: {
    overrideMaterializeStyle() {
      const colorCode = getMaterializeColorCode(
        DEFAULT_COLOR,
        DEFAULT_LUMINOSITY
      );

      if (colorCode) {
        const CSSStyleSheet = `
          .timepicker-digital-display {
            background-color: ${colorCode};
          }
          .timepicker-clear,
          .timepicker-close {
            color: ${colorCode};
          }
          .timepicker-canvas-bg,
          .timepicker-canvas-bearing {
            fill: ${colorCode};
          }
          .timepicker-canvas line {
            stroke: ${colorCode};
          }
          .timepicker-tick.active,
          .timepicker-tick:hover {
            background-color: ${transparentize(
              colorCode,
              0.75
            )}; /* Materialize original: transparentize($secondary-color, .75); */
          }
        `;

        createStyleElement({
          id: "stylesheet-timepicker",
          type: "text/css",
          innerHTML: CSSStyleSheet
        });
      }
    },
    onChange(event) {
      const { value } = event.target;

      this.onInput(value);
      this.$emit("change", value);
    },
    onInput(event) {
      this.$emit("input", event);
    }
  }
};
</script>

<style scoped>
.contrast .timepicker-wrapper /deep/ .timepicker-canvas-bg,
.contrast .timepicker-wrapper /deep/ .timepicker-canvas-bearing {
  fill: white !important;
}
.contrast .timepicker-wrapper /deep/ .timepicker-canvas line {
  stroke: white !important;
}
.contrast .timepicker-wrapper /deep/ .timepicker-dial,
.contrast .timepicker-wrapper /deep/ .timepicker-tick {
  background-color: transparent !important;
}
.contrast .timepicker-wrapper /deep/ .timepicker-tick.active,
.contrast .timepicker-wrapper /deep/ .timepicker-tick:hover {
  background-color: white !important;
  color: black !important;
}
</style>
