/**
 * @module store/mutations
 * @category Gerenciador de estados
 * @summary Módulo de _mutations_ do estado do sistema.
 */

/**
 * Adiciona no estado o objeto com os dados de uma permissão/_role_.
 * @function
 * @param {object} state - Estado global do sistema.
 * @param {object} permission - Objeto com os dados de uma permissão/_role_.
 */
export const addPermission = function(state, permission) {
  if (!permission || !permission.nome) {
    return;
  }

  if (!state.permissions) {
    state.permissions = {};
  }

  state.permissions[permission.nome] = permission;
};

/**
 * Limpa as permissões do usuário logado.
 * @function
 */
export const clearPermissions = function(state) {
  state.permissions = null;
};

/**
 * Atribui ao estado os dados de autenticação do usuário logado.
 * @function
 * @param {object} state - Estado global do sistema.
 * @param {object} authenticationData - Dados de autenticação.
 */
export const setAuthenticationData = function(state, authenticationData) {
  state.authenticationData = authenticationData;
};

/**
 * Atribui ao estado o modo de autenticação utilizado.
 * @function
 * @param {object} state - Estado global do sistema.
 * @param {object} authenticationMode - Modo de autenticação.
 */
export const setAuthenticationMode = function(state, authenticationMode) {
  state.authenticationMode = authenticationMode;
};

/**
 * Atribui ao estado a lista com dados para construir itens de menu extras.
 * @function
 * @param {object} state - Estado global do sistema.
 * @param {Array<object>} extraNavbar - Lista com dados para construir itens de
 * menu extras.
 */
export const setExtraNavbar = function(state, extraNavbar) {
  state.extraNavbar = extraNavbar;
};

/**
 * Atribui ao estado o ticket retornado pelo {@link http://cas.ufrj.br CAS} da UFRJ.
 * @function
 * @param {object} state - Estado global do sistema.
 * @param {string} ticket - Ticket do {@link http://cas.ufrj.br CAS} da UFRJ.
 */
export const setTicket = function(state, ticket) {
  state.ticket = ticket;
};

/**
 * Atribui ao estado os dados de login e dados pessoais do usuário logado no
 * sistema.
 * @function
 * @param {object} state - Estado global do sistema.
 * @param {object} user - Dados de login e dados pessoais do usuário logado no
 * sistema.
 */
export const setUser = function(state, user) {
  state.user = user;
};
