<template>
  <section class="section">
    <component v-bind:is="tag" class="pae-title pae-header">
      <i v-if="secao.icone" class="material-icons left">{{ secao.icone }}</i
      >{{ secao.nome }}
    </component>

    <div v-if="modeloSecao.permiteFiltro">
      <BaseInfoCard title="Filtro">
        <p>
          Você pode filtrar os itens digitando completamente (ou parte de) seu
          nome, assim como escolhendo um ou mais de seus assuntos, caso
          disponíveis.
        </p>
      </BaseInfoCard>

      <div class="row-fix row">
        <BaseTextInput v-bind="form.nome" v-model="form.nome.value" />

        <BaseDatePicker v-bind="form.inicio" v-model="form.inicio.value" />
        <BaseDatePicker v-bind="form.termino" v-model="form.termino.value" />

        <div class="col s12">
          <label v-bind:for="form.tipos.id">Tipos</label>
          <div v-bind:id="form.tipos.id" class="row">
            <BaseCheckbox
              v-for="(item, index) in Object.values(form.tipos.tipos)"
              v-bind:key="index"
              v-bind:id="item.id"
              v-bind:name="item.name"
              v-bind:content="item.content"
              v-bind:v-value="item.value"
              v-model="item.value"
              class="col s12 m6 l4"
              tabindex="0"
            />
          </div>
        </div>

        <div v-if="loadingAssuntos" class="col s12">
          <BaseProgressBar v-bind="progressBar"
            >Carregando assuntos...</BaseProgressBar
          >
        </div>
        <div v-else class="col s12">
          <label v-bind:for="form.assuntos.id">{{ form.assuntos.label }}</label>
          <div v-bind:id="form.assuntos.id" class="row">
            <BaseCheckbox
              v-for="(item, index) in form.assuntos.assuntos"
              v-bind:key="index"
              v-bind:id="`estandesfilter-assunto-${item.id}`"
              v-bind:name="`estandesfilter-assunto-${item.id}`"
              v-bind:content="item.nome"
              v-bind:v-value="item.id"
              v-model="form.assuntos.value"
              class="col s12 m6 l4"
              tabindex="0"
            />
          </div>
        </div>

        <div class="col s12">
          <BaseButton
            class="right"
            v-bind="form.button"
            v-on:click="onClickSearch"
          />
        </div>
      </div>
    </div>

    <div class="row-fix row">
      <div class="col s12">
        <BaseProgressBar v-if="loading" v-bind="progressBar" />
        <p v-else-if="!hasEstandes" class="flow-text center">
          {{
            modeloSecao.permiteFiltro
              ? "Não há resultados disponíveis para os parâmetros informados."
              : "Nenhum estande foi cadastrado."
          }}
        </p>
        <div v-else>
          <EstandesPanel v-bind="estandesPanel" />
          <BasePagination
            v-if="hasEstandes"
            class="center"
            v-bind="form.pagination"
            v-on:change="onPageChange"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { isEmpty } from "../../../utils/lang";
import { toISODate } from "../../../utils/datetime";
import {
  AssuntoService,
  EventoService,
  ModeloService,
  StatusService
} from "../../../services";
import EstandesPanel from "../EstandesPanel.vue";

const SEARCH_PAGINATION_SIZE = 12;

export default {
  name: "SectionEstandes",
  components: {
    EstandesPanel
  },
  inheritAttrs: false,
  props: {
    tag: {
      type: String,
      default: "h3",
      validator: value =>
        ["h1", "h2", "h3", "h4", "h5", "h6"].indexOf(value) !== -1
    },
    entidadeId: {
      type: Number,
      required: true
    },
    modelo: {
      type: Object,
      required: true
    },
    secao: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      loadingAssuntos: false,
      assuntos: null,
      modeloSecao: ModeloService.findModeloSecao(
        this.modelo,
        this.secao.modeloSecaoId
      ),
      progressBar: {
        useContainer: false
      },
      estandesPanel: {
        estandes: null
      },
      form: {
        nome: {
          id: "estandesfilter-nome",
          label: "Nome",
          placeholder: "Preencha um trecho do nome",
          gridExpression: "col s12 xl6",
          value: null
        },
        inicio: {
          id: "estandesfilter-inicio",
          label: "Data início",
          gridExpression: "col s12 m6 xl3",
          value: null
        },
        termino: {
          id: "estandesfilter-termino",
          label: "Data término",
          gridExpression: "col s12 m6 xl3",
          value: null
        },
        tipos: {
          id: "estandesfilter-tipos",
          label: "Tipos",
          tipos: {
            continuo: {
              id: "tipo-continuo",
              name: "tipo-continuo",
              content: "Estande contínuo",
              value: false
            },
            online: {
              id: "tipo-online",
              name: "tipo-online",
              content: "Estande online",
              value: false
            }
          }
        },
        assuntos: {
          id: "estandesfilter-assuntos",
          label: "Assuntos",
          assuntos: null,
          value: [] // Precisa ser definido inicialmente como array
        },
        button: {
          iconName: "search",
          content: "Buscar"
        },
        pagination: {
          value: 1,
          numPages: 0
        }
      }
    };
  },
  computed: {
    hasEstandes() {
      return !isEmpty(this.estandesPanel.estandes);
    }
  },
  created() {
    this.getAssuntos();
    this.doSearch();
  },
  methods: {
    getAssuntos() {
      this.loadingAssuntos = true;

      AssuntoService.getAll()
        .then(response => (this.form.assuntos.assuntos = response.data || []))
        .catch(error =>
          this.$notification.pushError(
            `Houve um erro ao obter os assuntos: ${error.message}`
          )
        )
        .finally(() => (this.loadingAssuntos = false));
    },
    resetPagination() {
      this.form.pagination.value = 1;
      this.form.pagination.numPages = 0;
    },
    doSearch() {
      const params = {
        status: StatusService.PUBLICADO,
        page: this.form.pagination.value - 1,
        size: SEARCH_PAGINATION_SIZE
      };

      if (this.modeloSecao.permiteFiltro) {
        params.nome = this.form.nome.value;
        params.inicio = !isEmpty(this.form.inicio.value)
          ? toISODate(this.form.inicio.value)
          : null;
        params.termino = !isEmpty(this.form.termino.value)
          ? toISODate(this.form.termino.value)
          : null;
        params.assuntos = this.form.assuntos.value;

        Object.entries(this.form.tipos.tipos).forEach(
          item => (params[item[0]] = item[1].value)
        );
      }

      const idEvento = this.entidadeId;

      const idSecao = this.secao.id;

      this.loading = true;

      let estandes = null;

      EventoService.searchEstandesFromSecao(idEvento, idSecao, params)
        .then(response => {
          const pagina = response.data;

          estandes = pagina.elementos || [];

          this.form.pagination.numPages = pagina.totalPaginas;

          this.estandesPanel.estandes = estandes;
        })
        .catch(error =>
          this.$notification.pushError(
            `Houve um erro ao buscar os itens: ${error.message}`
          )
        )
        .finally(() => (this.loading = false));
    },
    onPageChange(event) {
      this.form.pagination.value = event;
      this.doSearch();
    },
    onClickSearch() {
      this.resetPagination();
      this.doSearch();
    }
  }
};
</script>
