<template>
  <ul id="pae-sidenav" ref="sidenav" class="sidenav hide-on-large-only">
    <li>
      <div v-if="isUserLoggedIn" class="user-view">
        <div class="background" v-bind:class="[color, luminosity]">
          <img
            v-if="user.backgroundImage"
            v-bind:src="user.backgroundImage"
            v-bind:alt="user.name"
            v-bind:title="user.name"
          />
        </div>
        <a href="#!">
          <img
            class="circle"
            v-if="user.photo"
            v-bind:src="user.photo"
            v-bind:alt="`Imagem de fundo de ${user.name}`"
            v-bind:title="`Imagem de fundo de ${user.name}`"
          />
        </a>
        <a href="#!">
          <span class="white-text name">{{ user.name }}</span>
        </a>
        <a href="#!">
          <span class="white-text email">{{ user.email }}</span>
        </a>
      </div>
    </li>
    <li>
      <TheAccessibilityMenu v-bind:class="[color, luminosity]" />
    </li>
    <li>
      <BaseRouterLink v-bind:to="{ name: 'home' }" class="sidenav-close"
        >Home</BaseRouterLink
      >
    </li>
    <li>
      <BaseRouterLink v-bind:to="{ name: 'listEventos' }" class="sidenav-close"
        >Eventos</BaseRouterLink
      >
    </li>
    <li v-for="(item, index) in extraNavbar" v-bind:key="index">
      <a v-bind:href="`#${item.id}`" class="sidenav-close">{{ item.title }}</a>
    </li>
    <li>
      <a href="#!" class="sidenav-close" v-on:click="openHelp">Ajuda</a>
    </li>
    <li v-if="!isUserLoggedIn" class="divider" tabindex="-1"></li>
    <li v-if="!isUserLoggedIn">
      <ul ref="collapsible" class="collapsible">
        <li>
          <a class="collapsible-header">Entrar</a>
          <div class="collapsible-body">
            <ul>
              <li>
                <a
                  href="#!"
                  title="Acesso ao sistema via intranet da UFRJ"
                  class="sidenav-close"
                  v-on:click.prevent="authenticateByCAS"
                  >Acesso Interno (UFRJ)</a
                >
              </li>
              <li>
                <BaseRouterLink
                  title="Acesso ao sistema para usuários externos de apoio aos eventos e estandes"
                  v-bind:to="{ name: 'login' }"
                  class="sidenav-close"
                >
                  Acesso Externo (Apoio)
                </BaseRouterLink>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </li>
    <li v-if="isGestorSistema">
      <BaseRouterLink
        class="sidenav-close"
        v-bind:to="{ name: 'controleAcesso' }"
        >Controle de Acesso</BaseRouterLink
      >
    </li>
    <li v-if="isGestorEventos || isGestorEstandes">
      <BaseRouterLink class="sidenav-close" v-bind:to="{ name: 'adminEventos' }"
        >Gestão de Eventos</BaseRouterLink
      >
    </li>
    <li class="expander" tabindex="-1"></li>
    <li v-if="isUserLoggedIn" class="divider" tabindex="-1"></li>
    <li v-if="isUserLoggedIn" class="logout-item">
      <a href="#!" class="sidenav-close" v-on:click="logout">Sair</a>
    </li>
  </ul>
</template>

<script>
import {
  APP_INFO,
  DEFAULT_COLOR,
  DEFAULT_LUMINOSITY,
  HELP_INFOTIC_URL,
  HOST_URL
} from "../constants";
import { AuthenticationService, PermissaoService } from "../services";
import TheAccessibilityMenu from "./TheAccessibilityMenu.vue";

export default {
  name: "TheHeaderSidenav",
  components: {
    TheAccessibilityMenu
  },
  inheritAttrs: false,
  data() {
    return {
      color: DEFAULT_COLOR,
      luminosity: DEFAULT_LUMINOSITY,
      helpUrl: `${HELP_INFOTIC_URL}/duvidas-sistema/${APP_INFO.name}`
    };
  },
  computed: {
    activeModule() {
      return this.$store.state.activeModule || {};
    },
    extraNavbar() {
      return this.$store.state.extraNavbar || [];
    },
    isGestorSistema() {
      return (
        this.isUserLoggedIn &&
        this.$authorization.hasPermission(PermissaoService.GESTAO_SISTEMA)
      );
    },
    isGestorEventos() {
      return (
        this.isUserLoggedIn &&
        this.$authorization.hasPermission(PermissaoService.GESTAO_EVENTO)
      );
    },
    isGestorEstandes() {
      return (
        this.isUserLoggedIn &&
        this.$authorization.hasPermission(PermissaoService.GESTAO_ESTANDE)
      );
    },
    isUserLoggedIn() {
      // Não utiliza o método `AuthenticationService.isAuthenticated` pois
      // ele também verifica se a sessão ainda está ativa. Aqui, apenas
      // preciso saber se o usuário acessou o sistema recentemente
      return !this.$utils.Lang.isNullOrUndefined(
        this.$store.state.authenticationData
      );
    },
    user() {
      const { user } = this.$store.state;

      if (!user) {
        return {};
      }

      const pessoa = user.pessoa;

      return {
        name: pessoa.nomeExibicao,
        email: pessoa.email ? pessoa.email : "E-mail não informado"
      };
    }
  },
  mounted() {
    let { sidenav, collapsible } = this.$refs;

    if (sidenav) {
      window.M.Sidenav.init(sidenav);

      if (collapsible) {
        window.M.Collapsible.init(collapsible);
      }
    }
  },
  methods: {
    authenticateByCAS() {
      return AuthenticationService.authenticateByCAS(HOST_URL);
    },
    logout() {
      AuthenticationService.deauthenticate()
        .then(() => {
          if (window._paq) {
            window._paq.push(["resetUserId"]);
          }

          this.$store.commit("setUser", null);
        })
        .catch(error => this.$notification.pushError(error.message));
    },
    openHelp() {
      window.open(this.helpUrl);
    }
  }
};
</script>

<style scoped>
.sidenav {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 0;
}
.sidenav li.expander {
  flex-grow: 1;
}
.sidenav .collapsible li a.collapsible-header {
  padding: 0 32px;
}
.sidenav .collapsible li div.collapsible-body li a {
  font-weight: 400;
  padding: 0 37.5px 0 45px;
}
.sidenav .user-view {
  margin-bottom: 0;
}
.sidenav .divider {
  margin: 0;
  flex: 0 0 auto;
}
.sidenav li /deep/ .accessibility-menu {
  height: 48px;
}
.sidenav li /deep/ .accessibility-menu a {
  color: white;
}
.sidenav li /deep/ .accessibility-menu a i.material-icons {
  margin: 0;
}

/* Ajuste para aumentar a seletividade das regras de alto contraste */
.contrast .sidenav .accessibilitymenu-wrapper {
  background-color: black !important;
}
</style>

<style>
/* Ajuste para aumentar a seletividade das regras de alto contraste */
.contrast .sidenav-overlay {
  background-color: transparent !important;
}
</style>
