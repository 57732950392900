/**
 * @module services/Permissao
 * @category Serviços
 * @summary Módulo de importação do serviço de permissões.
 *
 * @description
 * Expõe o serviço de permissões do sistema para utilização interna.
 *
 * @example <caption>Importando o serviço de permissões como membro default do módulo</caption>
 * import PermissaoService from "./services/Permissao";
 *
 * @example <caption>Importando o serviço de permissões como membro do módulo</caption>
 * import { PermissaoService } from "./services/Permissao";
 */
import { default as PermissaoService } from "./Permissao";

export default PermissaoService;

export { PermissaoService };
