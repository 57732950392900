/**
 * @module directives/Pushpin/Pushpin
 * @category Diretivas
 * @summary Módulo da diretiva de _pushpin_.
 *
 * @description
 * Expõe o objeto/_namespace_ da diretiva de _pushpin_ para utilização pelo
 * container e pelos módulos.
 *
 * Esta diretiva ativa a funcionalidade de _pushpin_ a um elemento HTML ou
 * a um componente. No caso do uso em componentes, seu elemento mais externo
 * será a referência para a funcionalidade de _pushpin_.
 *
 * Para correto funcionamento, é necessário que o _framework_ {@link external:Materialize Materialize}
 * tenha sido importado, tornando seu objeto principal acessível globalmente.
 *
 * @requires module:utils/lang.isNullOrUndefined
 *
 * @param {object} [value]
 * @param {string} [value.targetId] - Identificador do elemento HTML para
 * referência (posição base) para o _pushpin_. Quando não informado, o elemento
 * pai do elemento onde a diretiva foi aplicada será utilizado.
 * @param {object} [value.pushpinOptions] - Opções aceitas pelo _pushpin_ do
 * {@link external:Materialize Materialize}. Caso as propriedades `top` e
 * `bottom` não sejam informadas, as mesmas são calculadas pela diretiva, caso
 * os modificadores correspondentes sejam usados.
 *
 * @example <caption>Utilizando a diretiva em um componente</caption>
 * <CustomComponent v-pushpin.top class="pin-top">
 *  <div id="container">...</div>
 * </CustomComponent>
 *
 * @example <caption>Utilizando a diretiva em um elemento HTML</caption>
 * <div v-pushpin.top class="pin-top">
 *  <ul class="section table-of-contents">
 *    ...
 *  </ul>
 *  ...
 * </div>
 */

import { isNullOrUndefined } from "../../utils/lang";

/**
 * Objeto principal do {@link external:Materialize Materialize}.
 * @type {object}
 * @ignore
 */
const M = window ? window.M : null;

/**
 * @namespace Pushpin
 * @category Diretivas
 * @summary Objeto/_namespace_ da diretiva de _pushpin_.
 */
const Pushpin = {
  /**
   * Realiza validações gerais e cria uma folha de estilos com as regras CSS
   * utilizadas pelo _pushpin_ do {@link external:Materialize Materialize}.
   * @param {external:Element} el - Elemento HTML em que a diretiva foi atribuída.
   * @param {object} binding - Objeto contendo propriedades inerentes à diretiva.
   */
  bind() {
    if (!M) {
      window.console.error(
        "Objeto do Materialize não encontrado. Verifique se essa biblioteca foi carregada corretamente"
      );
    }

    const pushpinCSSStyleSheet = `
      .pin-top,
      .pin-bottom {
        position: relative;
      }

      .pinned {
        position: fixed !important;
      }
    `;

    this.$utils.Web.createStyleElement({
      id: "stylesheet-pushpin",
      type: "text/css",
      innerHTML: pushpinCSSStyleSheet
    });
  },

  /**
   * Cria uma instância de _pushpin_ para o elemento HTML em que a diretiva
   * foi atribuída.
   * @param {external:Element} el - Elemento HTML em que a diretiva foi atribuída.
   * @param {object} binding - Objeto contendo propriedades inerentes à diretiva.
   */
  inserted(el, binding) {
    const { targetId, pushpinOptions } = binding.value || {};

    const { top, bottom } = binding.modifiers;

    const newOptions = Object.assign({}, pushpinOptions);

    const pushpinTarget = targetId ? document.getElementById(targetId) : null;

    if (top && isNullOrUndefined(newOptions.top)) {
      newOptions.top = (pushpinTarget || el.parentNode).offsetTop;
    }

    if (bottom && isNullOrUndefined(newOptions.bottom)) {
      newOptions.bottom =
        el.offsetTop -
        (pushpinTarget || el.parentNode).offsetHeight -
        el.offsetHeight;
    }

    window.M.Pushpin.init(el, newOptions);
  },

  /**
   * Destrói a instância de _pushpin_ do {@link external:Materialize Materialize}
   * associada ao elemento HTML em que a diretiva foi atribuída.
   * @param {external:Element} el - Elemento HTML em que a diretiva foi atribuída.
   */
  unbind(el) {
    if (!M) {
      return;
    }

    const instance = M.Autocomplete.getInstance(el);

    if (instance) {
      instance.destroy();
    }
  }
};

export default Pushpin;
