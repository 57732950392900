<template>
  <div class="estandespanel-result row">
    <div
      v-for="(itemEstande, indexEstande) in estandesCard"
      v-bind:key="indexEstande"
      class="card-container col s12 m4 l3"
    >
      <a
        href="#!"
        v-bind:title="`Acessar estande ${itemEstande.title}`"
        v-on:keyup.enter="onKeyup(itemEstande.actions)"
        v-on:click.prevent="onClick(itemEstande.actions)"
      >
        <BaseCard
          v-bind:is-hoverable="itemEstande.isHoverable"
          v-bind:disabled="itemEstande.disabled"
        >
          <template v-if="itemEstande.image" v-slot:card-image>
            <div class="image-wrapper valign-wrapper">
              <img
                class="responsive-img"
                v-bind="itemEstande.image"
                v-on:error="showFallbackImage"
              />
            </div>
          </template>
          <template v-slot:card-content>
            <div
              v-if="!itemEstande.image"
              class="estande-banner valign-wrapper"
            >
              <i
                class="material-icons large"
                v-bind:class="[textColor, textLuminosity]"
                >event</i
              >
            </div>
            <div class="badges-container">
              <BaseBadge
                v-for="(itemBadge, indexBadge) in itemEstande.badges"
                v-bind:key="indexBadge"
                v-bind="itemBadge"
              />
            </div>
            <div class="estande-info-container">
              <h6>{{ itemEstande.cardTitle }}</h6>
              <p v-if="itemEstande.content" class="grey-text text-darken-1">
                <i class="material-icons left">event</i
                >{{ itemEstande.content }}
              </p>
            </div>
          </template>
        </BaseCard>
      </a>
    </div>
  </div>
</template>

<script>
import { getDateString } from "../../utils/datetime";
import {
  DEFAULT_COLOR,
  DEFAULT_LUMINOSITY,
  NOPIC_IMG_SRC
} from "../../constants";
import { EstandeService } from "../../services";

export default {
  name: "EstandesPanel",
  inheritAttrs: false,
  props: {
    estandes: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      textColor: `${DEFAULT_COLOR}-text`,
      textLuminosity: DEFAULT_LUMINOSITY ? `text-${DEFAULT_LUMINOSITY}` : ""
    };
  },
  computed: {
    estandesCard() {
      return (this.estandes || []).map(item => this.mapToCard(item));
    },
    isAdminRoute() {
      return (
        (this.$route.matched || []).findIndex(item => item.name === "admin") !==
        -1
      );
    }
  },
  methods: {
    mapToCard(estande) {
      if (!estande) {
        return null;
      }

      const badges = [];

      if (estande.online) {
        badges.push({
          color: DEFAULT_COLOR,
          luminosity: DEFAULT_LUMINOSITY,
          content: "Online",
          caption: ""
        });
      }

      if (estande.continuo) {
        badges.push({
          color: DEFAULT_COLOR,
          luminosity: DEFAULT_LUMINOSITY,
          content: "Contínuo",
          caption: ""
        });
      }

      const image = {
        src: EstandeService.getBannerConteudoURL(estande.id),
        title: estande.nome,
        alt: estande.nome
      };

      const routeName = this.isAdminRoute ? "editEstande" : "viewEstande";

      const routeParams = this.isAdminRoute
        ? { idEvento: this.$route.params.idEvento, idEstande: estande.id }
        : {
            slugEvento: this.$route.params.slugEvento,
            slugEstande: estande.slug
          };

      return {
        cardTitle: estande.nome,
        title: estande.nome,
        badges,
        image,
        content: estande.inicio ? getDateString(estande.inicio) : null,
        actions: [
          {
            name: routeName,
            params: routeParams,
            content: "Acessar",
            iconName: "launch"
          }
        ]
      };
    },
    accessRoute(actions) {
      if (!actions || !actions.length) {
        return;
      }

      this.$router.push({ name: actions[0].name, params: actions[0].params });
    },
    showFallbackImage(event) {
      const image = event.target;

      image.src = NOPIC_IMG_SRC;
      image.title = "Sem foto";
      image.alt = "Sem foto";
    },
    onClick(actions) {
      this.accessRoute(actions);
    },
    onKeyup(actions) {
      this.accessRoute(actions);
    }
  }
};
</script>

<style scoped>
.estandespanel-result {
  display: flex;
  flex-wrap: wrap;
  align-content: stretch;
}

.estandespanel-result div.col.card-container {
  margin: 0.5rem 0 1rem 0;
}

.card {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.card /deep/ .card-image {
  /* Manter a resolução da imagem do card em, no máximo, 16:9 */
  padding-top: 56.25%;
}
.card /deep/ .card-image .image-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.card /deep/ .card-content {
  padding: 14px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.card /deep/ .card-content .estande-info-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.card /deep/ .card-content .estande-info-container i.material-icons.left {
  margin-right: 5px;
}
.card .estande-banner {
  justify-content: center;
}
</style>
