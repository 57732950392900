/**
 * @module services/Authentication
 * @category Serviços
 * @summary Módulo de importação do serviço de autenticação.
 *
 * @description
 * Expõe o serviço de autenticação do sistema para utilização interna.
 *
 * @example <caption>Importando o serviço de autenticação como membro default do módulo</caption>
 * import AuthenticationService from "./services/Authentication";
 *
 * @example <caption>Importando o serviço de autenticação como membro do módulo</caption>
 * import { AuthenticationService } from "./services/Authentication";
 */
import { default as AuthenticationService } from "./Authentication";

export default AuthenticationService;

export { AuthenticationService };
