<template>
  <article>
    <div ref="slider" class="slider" role="slider">
      <ul class="slides">
        <li>
          <img
            src="../assets/images/capa.jpg"
            alt="Mesa de abertura da 10º SIAc, auditório Roxinho (CCMN/UFRJ), 2019"
            title="Mesa de abertura da 10º SIAc, auditório Roxinho (CCMN/UFRJ), 2019"
          />
          <div class="caption-wrapper valign-wrapper">
            <div class="caption center-align">
              <header>
                <h1 class="white-text">
                  <span class="title-wrapper">
                    <span>{{ title }}</span>
                  </span>
                </h1>
              </header>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <section class="section container">
      <h4 class="pae-title center-align">Objetivos</h4>

      <div class="row-fix row cards-wrapper">
        <div class="col s12 m4">
          <BaseCard color="white" class="center">
            <template v-slot:card-content>
              <i
                class="material-icons medium"
                v-bind:class="[textColor, textLuminosity]"
                >event_note</i
              >
              <p>
                Auxiliar na divulgação e apresentação dos eventos promovidos
                pela Universidade, especialmente para atividades desenvolvidas
                virtualmente.
              </p>
            </template>
          </BaseCard>
        </div>
        <div class="col s12 m4">
          <BaseCard color="white" class="center">
            <template v-slot:card-content>
              <i
                class="material-icons medium"
                v-bind:class="[textColor, textLuminosity]"
                >build</i
              >
              <p>
                Garantir aos organizadores de evento um ambiente confortável
                para a submissão e gerenciamento de conteúdo.
              </p>
            </template>
          </BaseCard>
        </div>
        <div class="col s12 m4">
          <BaseCard color="white" class="center">
            <template v-slot:card-content>
              <i
                class="material-icons medium"
                v-bind:class="[textColor, textLuminosity]"
                >people</i
              >
              <p>
                Possibilitar a gestão colaborativa do material exposto na
                plataforma, permitindo o envolvimento das unidades acadêmicas e
                institucionais.
              </p>
            </template>
          </BaseCard>
        </div>
      </div>
    </section>

    <section class="section container">
      <h4 class="pae-title pae-header">
        <i class="material-icons left">event</i>
        Próximos eventos
      </h4>

      <div class="row-fix row">
        <div class="col s12">
          <BaseProgressBar v-if="loadingEventos" v-bind="progressBar" />
          <p v-else-if="!hasEventos" class="flow-text center">
            Não há eventos disponíveis para os parâmetros informados.
          </p>
          <EventosPanel v-else v-bind="eventosPanel" />

          <div class="buttons-row">
            <BaseButton
              v-bind="buttonVerMais"
              v-on:click="$router.push({ name: 'listEventos' })"
            />
          </div>
        </div>
      </div>
    </section>
  </article>
</template>

<script>
import { APP_INFO, DEFAULT_COLOR, DEFAULT_LUMINOSITY } from "../constants";
import { isEmpty } from "../utils";
import { EventoService, StatusService } from "../services";
import EventosPanel from "../components/Common/EventosPanel.vue";

export default {
  name: "Home",
  components: {
    EventosPanel
  },
  inheritAttrs: false,
  data() {
    return {
      loadingEventos: false,
      textColor: `${DEFAULT_COLOR}-text`,
      textLuminosity: DEFAULT_LUMINOSITY ? `text-${DEFAULT_LUMINOSITY}` : "",
      title: `Bem-vindo(a) à ${APP_INFO.name} da UFRJ!`,
      progressBar: {
        useContainer: false
      },
      eventosPanel: {
        eventos: null
      },
      buttonVerMais: {
        iconName: "search",
        title: "Ver mais eventos",
        content: "Ver mais"
      }
    };
  },
  computed: {
    hasEventos() {
      return !isEmpty(this.eventosPanel.eventos);
    }
  },
  beforeCreate() {
    if (!window.M) {
      window.console.error(
        "Objeto do Materialize não encontrado. Verifique se essa biblioteca foi carregada corretamente"
      );
    }
  },
  created() {
    this.$store.commit("setExtraNavbar", null);
  },
  mounted() {
    if (!window.M) {
      return;
    }

    const { slider } = this.$refs;

    window.M.Slider.init(slider, {
      indicators: false,
      height: 510,
      transition: 600,
      interval: 6000
    });

    this.getProximosEventos();
  },
  beforeDestroy() {
    if (!window.M) {
      return;
    }

    const { slider } = this.$refs;
    const instance = window.M.Slider.getInstance(slider);

    if (instance) {
      instance.destroy();
    }
  },
  methods: {
    getProximosEventos() {
      this.loadingEventos = true;

      const params = { status: StatusService.PUBLICADO, page: 0, size: 4 };

      EventoService.search(params)
        .then(response => (this.eventosPanel.eventos = response.data.elementos))
        .catch(error =>
          this.$notification.pushError(
            `Houve um erro ao obter a lista de próximos eventos: ${error.message}`
          )
        )
        .finally(() => (this.loadingEventos = false));
    }
  }
};
</script>

<style scoped>
.slider .slides li .caption-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.slider .slides li .caption {
  position: static;
  top: unset;
  left: unset;
  width: 100%;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.3);
}
.slider .slides li .caption h1 {
  margin: 0;
  /* border-top: 2px solid white;
  border-bottom: 2px solid white; */
}
.slider .slides li .caption h1 span.title-wrapper {
  display: block;
  /* background-image: linear-gradient(
    to right,
    rgba(130, 4, 248, 0.7),
    rgba(253, 7, 199, 0.7)
  ); */
}
.slider .slides li .caption h1 span.title-wrapper span {
  display: block;
  margin: 4px auto;
  padding: 0.75rem 0;
  width: 70%;
}

@media screen and (min-width: 0px) {
  .slider .slides li .caption h1 {
    font-size: 3rem;
    font-weight: 300;
  }
}

@media screen and (min-width: 601px) {
  .slider .slides li .caption h1 {
    font-size: 3.5rem;
    font-weight: inherit;
  }
}

.cards-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-content: stretch;
}
.cards-wrapper div.col {
  margin: 0.5rem 0 1rem 0;
}
.cards-wrapper div.col /deep/ .card {
  height: 100%;
}

.buttons-row {
  justify-content: center;
}

/* Ajuste para aumentar a seletividade das regras de alto contraste */
.contrast .slider .slides li .caption-wrapper,
.contrast .slider .slides li .caption-wrapper {
  background-color: transparent !important;
}
.contrast .slider .slides li .caption,
.contrast .slider .slides li .caption header,
.contrast .slider .slides li .caption h1 {
  background: transparent !important;
}
.contrast .slider .slides li .caption h1 span.title-wrapper {
  background-image: none;
  /* background-color: rgba(0, 0, 0, 0.5); */
}

.contrast .cards-wrapper i.material-icons {
  color: white !important;
}
</style>
