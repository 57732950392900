<template>
  <BaseTextInput
    v-bind:id="id"
    v-bind:grid-expression="gridExpression"
    class="typeahead-wrapper"
    v-bind:name="name"
    v-bind:disabled="disabled"
    v-bind:required="required"
    v-bind:placeholder="placeholder"
    v-bind:label-class="labelClass"
    v-bind:show-icon="showIcon"
    v-bind:icon-name="iconName"
    v-bind:icon-position="iconPosition"
    v-bind:icon-title="iconTitle"
    v-bind:icon-click="iconClick"
    v-bind:autofocus="autofocus"
    v-bind:extra-attrs="{ title: $attrs.title, 'data-target': dropdownId }"
    v-bind:value="searchValue"
    v-on="listeners"
  >
    <template v-slot:default>
      <slot>{{ label }}</slot>
    </template>
    <template v-slot:extra-content>
      <a
        v-if="!disabled"
        href="#!"
        class="clear-btn"
        title="Limpar campo"
        v-on:click="clear"
      >
        <i class="material-icons">close</i>
      </a>
      <BaseDropdown
        v-if="showDropdown"
        v-bind:id="dropdownId"
        v-bind:trigger-element-id="id"
        v-bind:show-icon="showDropdownIcons"
        v-bind:dropdown-content="dropdownContentWithEvents"
        v-bind:dropdown-options="dropdownOptionsOverridden"
        v-bind:on-load="dropdownOnLoad"
      />
      <BaseProgressBar
        v-show="loading"
        v-bind="progressBar"
        v-bind:class="{
          'with-prefix': showIcon && iconName && iconPosition === 'left'
        }"
      />
      <div
        v-if="chosenItem && chosenItem !== searchValue"
        class="chosen-item right-align truncate"
      >
        <b>Selecionado:</b>
        {{ chosenItem }}
      </div>
    </template>
  </BaseTextInput>
</template>

<script>
/**
 * @module components/base/BaseTypeahead/BaseTypeahead
 * @category Componentes-base
 * @summary _Single File Component_ (SFC) de _typeahead_.
 *
 * @description
 * Este componente, a partir de um valor textual, carrega e abre um _dropdown_
 * com as opções possíveis de acordo com a função de busca definida pela _prop_
 * `searchFunction`.
 *
 * Internamente, utiliza os componentes {@link module:components/base/BaseTextInput/BaseTextInput BaseTextInput}
 * e {@link module:components/base/BaseDropdown/BaseDropdown BaseDropdown}, recebendo, em grande parte,
 * as mesmas props desses componentes.
 *
 * O _slot_ _default_ é repassado para o do componente {@link module:components/base/BaseTextInput/BaseTextInput BaseTextInput}.
 *
 * Atributos HTML de acessibilidade, e.g., `title`, podem ser usados
 * diretamente no componente.
 *
 * @requires module:components/base/BaseTextInput/BaseTextInput
 * @requires module:components/base/BaseDropdown/BaseDropdown
 * @requires module:utils/components.fromDropdownItem
 *
 * @vue-prop {string} [gridExpression="col s12"] - Classes CSS de grid do {@link external:Materialize Materialize}.
 * @vue-prop {string} id - Vide {@link module:components/base/BaseTextInput/BaseTextInput BaseTextInput}.
 * @vue-prop {string} [name] - Vide {@link module:components/base/BaseTextInput/BaseTextInput BaseTextInput}.
 * @vue-prop {boolean} [disabled] - Vide {@link module:components/base/BaseTextInput/BaseTextInput BaseTextInput}.
 * @vue-prop {boolean} [required] - Vide {@link module:components/base/BaseTextInput/BaseTextInput BaseTextInput}.
 * @vue-prop {string} [placeholder] - Vide {@link module:components/base/BaseTextInput/BaseTextInput BaseTextInput}.
 * @vue-prop {string} [label] - Vide {@link module:components/base/BaseTextInput/BaseTextInput BaseTextInput}.
 * @vue-prop {string|Array|object} [labelClass] - Vide {@link module:components/base/BaseTextInput/BaseTextInput BaseTextInput}.
 * @vue-prop {boolean} [showIcon] - Vide {@link module:components/base/BaseTextInput/BaseTextInput BaseTextInput}.
 * @vue-prop {string} [iconName] - Vide {@link module:components/base/BaseTextInput/BaseTextInput BaseTextInput}.
 * @vue-prop {string} [iconPosition] - Vide {@link module:components/base/BaseTextInput/BaseTextInput BaseTextInput}.
 * @vue-prop {function} [iconTitle] - Vide {@link module:components/base/BaseTextInput/BaseTextInput BaseTextInput}.
 * @vue-prop {function} [iconClick] - Vide {@link module:components/base/BaseTextInput/BaseTextInput BaseTextInput}.
 * @vue-prop {boolean} [autofocus] - Vide {@link module:components/base/BaseTextInput/BaseTextInput BaseTextInput}.
 * @vue-prop {string} dropdownId - Atributo `id` do componente-base de
 * _dropdown_.
 * @vue-prop {boolean} [showDropdownIcons] - Vide prop `showIcon` de {@link module:components/base/BaseDropdown/BaseDropdown BaseDropdown}.
 * @vue-prop {object} [dropdownOptions] - Vide {@link module:components/base/BaseDropdown/BaseDropdown BaseDropdown}.
 * @vue-prop {number} [typingTimeout=500] - Tempo (em milissegundos) aguardado
 * para chamar o método `searchFunction`.
 * @vue-prop {function} searchFunction - Função que executará a busca.
 * @vue-prop {string|object} [value] - Valor inicial do componente e utilizado
 * internamente para a diretiva `v-model` do {@link external:Vue Vue}.
 * @vue-event {string} onInput - Emite o valor atual do componente. Pode ser
 * capturado pela diretiva `v-on:input` (ou `v-model`) do {@link external:Vue Vue}.
 * @vue-event {string} onChange - Emite o valor atual do componente. Pode ser
 * capturado pela diretiva `v-on:change` do {@link external:Vue Vue}.
 *
 * @example
 * <BaseTypeahead
 *  id="lotacao"
 *  name="lotacao"
 *  placeholder="Busque pelo nome da lotação"
 *  dropdown-id="lotacao-dropdown"
 *  v-bind:search-function="..."
 *  v-model="lotacao">
 *  Lotação
 * </BaseTypeahead>
 */
import BaseTextInput from "../BaseTextInput/BaseTextInput.vue";
import BaseDropdown from "../BaseDropdown/BaseDropdown.vue";
import { fromDropdownItem } from "../../../utils/components";

export default {
  name: "BaseTypeahead",
  components: {
    BaseDropdown,
    BaseTextInput
  },
  inheritAttrs: false,
  props: {
    gridExpression: {
      type: String,
      default: "col s12"
    },
    id: {
      type: String,
      required: true
    },

    name: String,
    disabled: Boolean,
    required: Boolean,
    placeholder: String,
    label: String,
    labelClass: [String, Array, Object],
    showIcon: Boolean,
    iconName: String,
    iconPosition: String,
    iconTitle: String,
    iconClick: Function,
    autofocus: Boolean,

    dropdownId: {
      type: String,
      required: true
    },

    showDropdownIcons: Boolean,
    dropdownOptions: Object,

    typingTimeout: {
      type: Number,
      default: 500
    },
    searchFunction: {
      type: Function,
      required: true
    },

    value: [String, Object]
  },
  data() {
    return {
      chosenItem: null,
      dropdownActiveIndex: -1,
      dropdownContent: null,
      dropdownInstance: null,
      inputInstance: null,
      loading: false,
      progressBar: {
        useContainer: false
      },
      searchValue: null,
      showDropdown: false,
      timeout: null
    };
  },
  computed: {
    dropdownContentWithEvents() {
      return (this.dropdownContent || []).map(item => {
        return {
          ...item,
          onClick: () => this.dropdownItemOnClick(item)
        };
      });
    },
    dropdownOptionsOverridden() {
      const dropdownOptions = Object.assign({}, this.dropdownOptions, {
        // Permitir que seja possível alterar o texto mesmo com o dropdown aberto
        autoFocus: false,
        // Não exibir o dropdown por cima do elemento HTML trigger
        coverTrigger: false
      });

      const originalOnCloseEnd = (this.dropdownOptions || {}).onCloseEnd;

      // Forçar a destruição do componente BaseDropdown após ele ser fechado.
      dropdownOptions.onCloseEnd =
        originalOnCloseEnd && typeof originalOnCloseEnd === "function"
          ? () => {
              originalOnCloseEnd();
              this.destroyDropdown();
            }
          : () => this.destroyDropdown();

      return dropdownOptions;
    },
    listeners() {
      return Object.assign({}, this.$listeners, {
        input: this.onInput,
        keydown: this.onKeydown,
        // É necessário um evento "dummy" para que outros disparos do BaseTextInput
        // não gerem problemas em componentes que utilizam o BaseTypeahead
        change: () => {}
      });
    }
  },
  watch: {
    value: {
      immediate: true,

      handler(newValue) {
        if (
          newValue === null ||
          newValue === undefined ||
          typeof newValue === "string"
        ) {
          this.searchValue = newValue;
        } else {
          this.searchValue = newValue.content;
        }

        this.chosenItem = this.searchValue;
      }
    }
  },
  mounted() {
    this.inputInstance = document.getElementById(this.id);
  },
  methods: {
    doSearch(event) {
      this.loading = true;

      this.searchFunction(event)
        .then(response => {
          this.doTypeahead(response);
        })
        .catch(error => {
          // TODO: tratar erro
          window.console.error(error);
        })
        .finally(() => (this.loading = false));
    },
    doTypeahead(data) {
      this.dropdownContent = data;

      this.initDropdown();
    },
    dropdownItemOnClick(item) {
      const dropdownItem = fromDropdownItem(item, "id", "content");
      this.searchValue = !dropdownItem ? null : dropdownItem.content;

      if (item.onClick) {
        item.onClick(item);
      }

      this.destroyDropdown();

      this.$emit("input", item);
      this.$emit("change", item);
    },
    dropdownOnLoad(instance) {
      instance.open();

      this.dropdownInstance = instance;
    },
    clear() {
      this.searchValue = null;

      this.$emit("input", null);
      this.$emit("change", null);

      this.destroyDropdown();
    },
    focusInput() {
      if (this.inputInstance) {
        this.inputInstance.focus();
      }
    },
    initDropdown() {
      this.showDropdown = true;
      this.dropdownActiveIndex = -1;
    },
    openDropdown() {
      if (this.dropdownInstance) {
        this.dropdownInstance.open();
      }
    },
    destroyDropdown() {
      this.showDropdown = false;
      this.dropdownInstance = null;

      this.focusInput();
    },
    navigateDropdown(direction) {
      if (this.dropdownInstance && this.dropdownInstance.isOpen) {
        const { dropdownEl, isScrollable } = this.dropdownInstance;

        const items = dropdownEl.getElementsByTagName("li");

        if (items.length) {
          const newIndex = this.dropdownActiveIndex + direction;

          if (newIndex >= 0 && newIndex < items.length) {
            if (
              this.dropdownActiveIndex >= 0 &&
              this.dropdownActiveIndex < items.length
            ) {
              items[this.dropdownActiveIndex].classList.remove("active");
            }

            const item = items[newIndex];

            item.classList.add("active");

            if (isScrollable) {
              const dropdownHeight = dropdownEl.offsetHeight;

              // Posição e altura do item escolhido
              const offsetBottom = item.offsetTop + item.offsetHeight;

              // `direction` vale 1 ou -1, indicando se vai realizar
              // o scroll para baixo ou para cima, respectivamente
              if (direction === 1) {
                const scrollAreaBottom = dropdownHeight + dropdownEl.scrollTop;

                if (offsetBottom > scrollAreaBottom) {
                  dropdownEl.scrollTop += offsetBottom - scrollAreaBottom;
                }
              } else if (direction === -1) {
                if (item.offsetTop < dropdownEl.scrollTop) {
                  dropdownEl.scrollTop -= dropdownEl.scrollTop - item.offsetTop;
                }
              }
            }

            this.dropdownActiveIndex = newIndex;
          }
        }
      }
    },
    typeaheadFunction(event) {
      window.clearTimeout(this.timeout);

      if (event) {
        this.timeout = window.setTimeout(() => {
          this.doSearch(event);
          this.timeout = null;
        }, this.typingTimeout);
      }
    },
    onInput(event) {
      this.destroyDropdown();

      this.searchValue = event;

      if (!event) {
        this.clear();
        return;
      }

      this.typeaheadFunction(event);
    },
    onKeydown(event) {
      const { key } = event;

      switch (key) {
        case "Tab":
        case "Esc":
        case "Escape":
          if (this.dropdownInstance && this.dropdownInstance.isOpen) {
            event.preventDefault();
            this.dropdownInstance.close();
          }
          break;
        case "Enter":
          if (this.dropdownInstance && this.dropdownInstance.isOpen) {
            const { dropdownEl } = this.dropdownInstance;

            const items = dropdownEl.getElementsByTagName("li");

            if (items.length && this.dropdownActiveIndex >= 0) {
              event.preventDefault();

              items[this.dropdownActiveIndex]
                .getElementsByTagName("a")[0]
                .click();
            }
            // Não propaga o evento de keydown
            return;
          }
          break;
        case "ArrowUp":
        case "ArrowDown":
          // A primeira vez que aperta a seta para baixo, abre o dropdown
          // Caso o dropdown esteja aberto, realiza a navegação entre os itens
          if (
            key === "ArrowDown" &&
            !this.dropdownInstance &&
            (this.dropdownContent || []).length
          ) {
            this.initDropdown();
          } else if (this.dropdownInstance && this.dropdownInstance.isOpen) {
            event.preventDefault();
            this.navigateDropdown(key === "ArrowDown" ? 1 : -1);
          }

          break;
        default:
          break;
      }

      // Propaga o evento de keydown caso algum componente externo queira capturar
      this.$emit("keydown", event);
    }
  }
};
</script>

<style scoped>
.clear-btn {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 1rem;
  height: 3rem;
  color: inherit;
}
.clear-btn i.material-icons {
  height: 3rem;
  line-height: 3rem;
  background-color: transparent !important;
}

/* Workaround para colocar a barra de progresso e o item escolhido adjacente ao input */
.progressbar-wrapper {
  margin-top: -8px;
  margin-bottom: 8px;
}
.progressbar-wrapper /deep/ .progress {
  margin: 0;
  border-radius: 0;
}
.progressbar-wrapper.with-prefix {
  margin-left: 3rem;
}

.chosen-item {
  font-size: 0.8rem;
}

.contrast .typeahead-wrapper /deep/ .dropdown-content li:hover,
.contrast .typeahead-wrapper /deep/ .dropdown-content li.active {
  background-color: white !important;
  color: black !important;
}
.contrast .typeahead-wrapper /deep/ .dropdown-content li:hover a,
.contrast .typeahead-wrapper /deep/ .dropdown-content li.active a {
  color: black !important;
}
</style>
