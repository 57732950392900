<template>
  <BaseModal v-bind="modalUsuario">
    <template v-slot:header>
      <h4>{{ title }}</h4>
    </template>

    <template v-slot:default>
      <BaseInfoCard v-bind="infoCard" />

      <form class="row" v-on:submit.prevent>
        <BaseTextInput
          v-bind="form.nome"
          v-bind:disabled="saving"
          v-model="form.nome.value"
        />
        <BaseTextInput
          v-bind="form.nomeSocial"
          v-bind:disabled="saving"
          v-model="form.nomeSocial.value"
        />
        <BaseTextInput
          v-bind="form.email"
          v-bind:disabled="saving"
          v-model="form.email.value"
        />

        <BaseSelect
          v-bind="form.nacionalidade.tipoCodigo"
          v-bind:disabled="saving"
          v-model="form.nacionalidade.tipoCodigo.value"
        />
        <BaseTypeahead
          v-if="hasNacionalidade && !isBrasileiroNato"
          v-bind="form.nacionalidade.paisOrigem"
          v-bind:disabled="saving"
          v-model="form.nacionalidade.paisOrigem.value"
        />

        <div v-if="showNaoTemCpf" class="col s12">
          <p>
            <BaseCheckbox
              v-bind="form.naoTemCpf"
              v-bind:disabled="saving"
              v-model="form.naoTemCpf.value"
            />
          </p>
        </div>
        <BaseTextInput
          v-if="showCpf"
          v-bind="form.cpf"
          v-bind:disabled="saving"
          v-model="form.cpf.value"
        />
        <BaseTextInput
          v-else
          v-bind="form.passaporte"
          v-bind:disabled="saving"
          v-model="form.passaporte.value"
        />

        <BaseSelect
          v-bind="form.escolaridade"
          v-bind:disabled="saving"
          v-model="form.escolaridade.value"
        />

        <BaseTypeahead
          v-bind="form.localizacao"
          v-bind:disabled="saving"
          v-model="form.localizacao.value"
        />
      </form>
    </template>

    <template v-slot:footer>
      <BaseButton
        v-bind:disabled="saving"
        class="modal-close"
        material-type="flat"
        title="Cancelar alterações na pessoa responsável"
        >Cancelar</BaseButton
      >
      <BaseButton
        v-bind:disabled="saving"
        material-type="flat"
        title="Salvar alterações na pessoa responsável"
        v-on:click="save"
        >Salvar</BaseButton
      >
    </template>
  </BaseModal>
</template>

<script>
import {
  PessoaService,
  SisPessoalService,
  UsuarioService
} from "../../services";

export default {
  name: "PessoaModal",
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      required: true
    },
    tiposNacionalidade: {
      type: Array,
      required: true
    },
    escolaridades: {
      type: Array,
      required: true
    },
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      saving: false,
      pessoa: null,
      modalUsuario: {
        id: this.id,
        hasFixedFooter: true,
        modalOptions: {
          onOpenStart: this.onModalOpen,
          dismissible: false
        }
      },
      buttonCancel: {},
      buttonSave: {},
      progressBar: {
        useContainer: false
      },
      form: {
        nome: {
          id: `${this.id}-usuario-nome`,
          name: "nome",
          required: true,
          label: "Nome",
          value: null
        },
        nomeSocial: {
          id: `${this.id}-usuario-nomesocial`,
          name: "nomesocial",
          label: "Nome social",
          value: null
        },
        email: {
          id: `${this.id}-usuario-email`,
          name: "email",
          required: true,
          label: "E-mail",
          value: null
        },
        nacionalidade: {
          tipoCodigo: {
            id: `${this.id}-usuario-nacionalidade-tipocodigo`,
            name: "nacionalidade_tipocodigo",
            label: "Nacionalidade",
            hasUnselectedOption: true,
            optionElements: [],
            value: null
          },
          paisOrigem: {
            id: `${this.id}-usuario-nacionalidade-paisorigem`,
            name: "nacionalidade-paisorigem",
            label: "País de origem",
            placeholder: "Busque pelo nome (ou trecho) do país",
            dropdownId: `${this.id}-usuario-paisorigem-dropdown`,
            searchFunction: this.searchPais,
            value: null
          }
        },
        naoTemCpf: {
          id: `${this.id}-usuario-naotemcpf`,
          name: "naotemcpf",
          content: "Não tem CPF",
          tabindex: "0",
          vValue: false,
          value: null
        },
        cpf: {
          id: `${this.id}-usuario-cpf`,
          name: "cpf",
          label: "CPF",
          dataLength: 11,
          value: null
        },
        passaporte: {
          id: `${this.id}-usuario-passaporte`,
          name: "passaporte",
          label: "Passaporte",
          dataLength: 9,
          value: null
        },
        escolaridade: {
          id: `${this.id}-usuario-escolaridade`,
          name: "escolaridade",
          label: "Escolaridade",
          hasUnselectedOption: true,
          optionElements: [],
          value: null
        },
        localizacao: {
          id: `${this.id}-usuario-localizacao`,
          name: "localizacao",
          label: "Localização",
          placeholder: "Busque pelo nome (ou trecho) da localização",
          dropdownId: `${this.id}-usuario-localizacao-dropdown`,
          searchFunction: this.searchLocal,
          value: null
        }
      }
    };
  },
  computed: {
    isCreation() {
      return !this.pessoa || !this.pessoa.id;
    },
    title() {
      return this.isCreation
        ? "Criação de pessoa responsável"
        : "Edição de pessoa responsável";
    },
    infoCard() {
      const infoCard = {
        title: this.title
      };

      if (this.isCreation) {
        infoCard.content =
          "Para cadastrar uma usuário externo (e.g. funcionários terceirizados da UFRJ), primeiramente, preencha os dados pessoais da pessoa " +
          'responsável pelo usuário a ser criado e clique no botão "Salvar". Obrigatoriamente, ' +
          "é necessário preencher o nome, a documentação (CPF ou passaporte do país de origem, dependendo da nacionalidade) e um e-mail de contato. " +
          "Esse e-mail será utilizado automaticamente como login do usuário. " +
          "Pode-se preencher, também, dados auxiliares, como escolaridade e a localização do funcionário na UFRJ.";
      } else {
        infoCard.content =
          "Utilize o formulário abaixo para alterar os dados da pessoa responsável. " +
          'Primeiramente, realize as alterações necessárias e, em seguida, clique no botão "Salvar" para efetivar as alterações.';
      }

      return infoCard;
    },
    hasNacionalidade() {
      return !!this.form.nacionalidade.tipoCodigo.value;
    },
    isBrasileiroNato() {
      return (
        this.form.nacionalidade.tipoCodigo.value ===
        PessoaService.BRASILEIRO_NATO
      );
    },
    showNaoTemCpf() {
      return !this.hasNacionalidade || !this.isBrasileiroNato;
    },
    showCpf() {
      return !this.form.naoTemCpf.value || this.isBrasileiroNato;
    }
  },
  watch: {
    tiposNacionalidade: {
      immediate: true,

      handler(newValue) {
        this.form.nacionalidade.tipoCodigo.optionElements = (
          newValue || []
        ).map(item =>
          this.$utils.Components.toOptionElement(item, "codigo", "nome")
        );
      }
    },
    escolaridades: {
      immediate: true,

      handler(newValue) {
        this.form.escolaridade.optionElements = (newValue || []).map(item =>
          this.$utils.Components.toOptionElement(
            { ...item, id: String(item.id) },
            "id",
            "nome"
          )
        );
      }
    }
  },
  methods: {
    onModalOpen() {
      // Reseta algumas variáveis
      this.saving = false;

      this.pessoa = {
        ...this.value
      };

      this.pessoa.nacionalidade = this.value.nacionalidade
        ? { ...this.value.nacionalidade }
        : null;

      const pessoa = this.pessoa;

      const {
        cpf,
        passaporte,
        nome,
        nomeSocial,
        email,
        nacionalidade,
        escolaridade,
        localizacao,
        naoTemCpf
      } = this.form;

      if (pessoa) {
        cpf.value = pessoa.cpf;
        passaporte.value = pessoa.passaporte;
        nome.value = pessoa.nome;
        nomeSocial.value = pessoa.nomeSocial;
        email.value = pessoa.email;

        if (pessoa.nacionalidade) {
          nacionalidade.tipoCodigo.value = pessoa.nacionalidade.tipoCodigo;

          if (
            pessoa.nacionalidade.tipoCodigo === PessoaService.BRASILEIRO_NATO
          ) {
            naoTemCpf.value = null;
          } else {
            naoTemCpf.value = !pessoa.cpf && pessoa.passaporte;
          }

          this.fillPaisOrigem(pessoa.nacionalidade.paisOrigemId);
        } else {
          nacionalidade.tipoCodigo.value = null;
          nacionalidade.paisOrigem.value = null;

          naoTemCpf.value = !pessoa.cpf && pessoa.passaporte;
        }

        escolaridade.value = pessoa.escolaridadeId
          ? String(pessoa.escolaridadeId)
          : null;

        this.fillLocalizacao(pessoa.localizacaoCodigo);
      } else {
        cpf.value = null;
        passaporte.value = null;
        naoTemCpf.value = null;
        nome.value = null;
        nomeSocial.value = null;
        email.value = null;
        nacionalidade.tipoCodigo.value = null;
        nacionalidade.paisOrigem.value = null;
        escolaridade.value = null;
        localizacao.value = null;
      }
    },
    fillPaisOrigem(id) {
      const { paisOrigem } = this.form.nacionalidade;

      if (!id) {
        paisOrigem.value = null;
        return;
      }

      SisPessoalService.getPais(id)
        .then(response => {
          const pais = response.data;

          paisOrigem.value = pais;
          paisOrigem.value.content = pais.nome;
        })
        .catch(error =>
          this.$notification.pushError(
            new Error(`Houve um erro ao obter o país: ${error.message}`)
          )
        );
    },
    fillLocalizacao(codigo) {
      const { localizacao } = this.form;

      if (!codigo) {
        localizacao.value = null;
        return;
      }

      SisPessoalService.getLocal(codigo)
        .then(response => {
          const local = response.data;

          localizacao.value = local;
          localizacao.id = local.codigo;
          localizacao.value.content = local.nome;
        })
        .catch(error =>
          this.$notification.pushError(
            new Error(`Houve um erro ao obter o país: ${error.message}`)
          )
        );
    },
    searchPais(event) {
      return SisPessoalService.searchPais(event)
        .then(response =>
          (response.data || []).map(item => ({ ...item, content: item.nome }))
        )
        .catch(error =>
          this.$notification.pushError(
            new Error(`Houve um erro ao buscar pelos países: ${error.message}`)
          )
        );
    },
    searchLocal(event) {
      return SisPessoalService.searchLocal(event)
        .then(response =>
          response.data.elementos.map(item => ({
            ...item,
            id: item.codigo,
            content: item.nome
          }))
        )
        .catch(error =>
          this.$notification.pushError(
            new Error(`Houve um erro ao buscar pelos países: ${error.message}`)
          )
        );
    },
    save() {
      const {
        cpf,
        passaporte,
        nome,
        nomeSocial,
        email,
        nacionalidade,
        escolaridade,
        localizacao,
        naoTemCpf
      } = this.form;

      const newCpf = naoTemCpf.value ? null : cpf.value;
      const newPassaporte = naoTemCpf.value ? passaporte.value : null;
      const newNome = nome.value;
      const newNomeSocial = nomeSocial.value;
      const newEmail = email.value;
      const newNacionalidadeTipoCodigo = nacionalidade.tipoCodigo.value;
      const newNacionalidadePaisOrigemId = nacionalidade.paisOrigem.value
        ? nacionalidade.paisOrigem.value.id
        : null;
      const newEscolaridadeId = escolaridade.value
        ? window.parseInt(escolaridade.value)
        : null;
      const newLocalizacaoCodigo = localizacao.value
        ? localizacao.value.codigo
        : null;

      let pessoa = this.pessoa;

      if (
        newCpf === pessoa.cpf &&
        newPassaporte === pessoa.passaporte &&
        newNome === pessoa.nome &&
        newNomeSocial === pessoa.nomeSocial &&
        newEmail === pessoa.email &&
        newNacionalidadeTipoCodigo ===
          (pessoa.nacionalidade ? pessoa.nacionalidade.tipoCodigo : null) &&
        newNacionalidadePaisOrigemId ===
          (pessoa.nacionalidade ? pessoa.nacionalidade.paisOrigemId : null) &&
        newEscolaridadeId === pessoa.escolaridadeId &&
        newLocalizacaoCodigo === pessoa.localizacaoCodigo
      ) {
        return;
      }

      pessoa = {
        cpf: newCpf,
        passaporte: newPassaporte,
        nome: newNome,
        nomeSocial: newNomeSocial,
        email: newEmail,
        nacionalidade: newNacionalidadeTipoCodigo
          ? {
              tipoCodigo: newNacionalidadeTipoCodigo,
              paisOrigemId: newNacionalidadePaisOrigemId
            }
          : null,
        escolaridadeId: newEscolaridadeId,
        localizacaoCodigo: newLocalizacaoCodigo
      };

      let errors = PessoaService.validate(pessoa);

      if (errors.length) {
        errors.forEach(item => this.$notification.pushError(item.message));
        return;
      }

      let promise = null;

      this.saving = true;

      if (this.isCreation) {
        promise = PessoaService.create(pessoa).then(response => {
          this.$notification.pushSuccess(
            "Dados da pessoa responsável cadastrados com sucesso"
          );

          const usuario = {
            tipoUsuarioId: UsuarioService.EXTERNO
          };

          let errors = UsuarioService.validate(usuario);

          if (errors.length) {
            errors.forEach(item => this.$notification.pushError(item.message));
            return;
          }

          return PessoaService.addUsuario(response.data.id, usuario)
            .then(() =>
              this.$notification.pushSuccess(
                "Usuário externo adicionado com sucesso"
              )
            )
            .catch(error =>
              this.$notification.pushError(
                `Houve um erro ao adicionar usuário externo: ${error.message}`
              )
            )
            .finally(() => this.$utils.Components.closeModal(this.id));
        });
      } else {
        promise = PessoaService.update(this.pessoa.id, pessoa).then(() => {
          this.$notification.pushSuccess(
            "Dados da pessoa responsável atualizados com sucesso"
          );

          this.$utils.Components.closeModal(this.id);
        });
      }

      promise
        .catch(error =>
          this.$notification.pushError(
            `Houve um erro ao salvar dados pessoais do usuário: ${error.message}`
          )
        )
        .finally(() => (this.saving = false));
    }
  }
};
</script>
