/**
 * @module directives/Scrollspy/Scrollspy
 * @category Diretivas
 * @summary Módulo da diretiva de _scrollspy_ do {@link external:Materialize Materialize}.
 *
 * @description
 * Expõe o objeto/_namespace_ da diretiva de _scrollspy_ do
 * {@link external:Materialize Materialize} para utilização pelo container e
 * pelos módulos.
 *
 * Esta diretiva ativa a funcionalidade de _scrollspy_ do {@link external:Materialize Materialize}
 * em todos os elementos HTML que possuírem a classe CSS "scrollspy" que
 * estejam localizados internamente ao elemento HTML ou ao componente em que
 * foi aplicada esta diretiva.
 *
 * Esta diretiva verifica por mudanças no elemento/componente em que ela foi
 * usada, de forma a identificar se houve remoção ou adição de algum elemento
 * HTML com a classe "scrollspy". Em caso positivo, ela atualiza o _scrollspy_
 * do {@link external:Materialize Materialize} para esse elemento HTML,
 * destruindo-o e instanciando-o novamente. Um controle interno é feito para
 * evitar degradação de desempenho.
 *
 * Para correto funcionamento, é necessário que o _framework_ {@link external:Materialize Materialize}
 * tenha sido importado, tornando seu objeto principal acessível globalmente.
 *
 * @param {object} [value] - Opções aceitas pelo _scrollspy_ do {@link external:Materialize Materialize}.
 *
 * @example <caption>Utilizando a diretiva em um componente</caption>
 * <CustomComponent v-scrollspy>
 *  <template v-slot:content>
 *    <div class="section scrollspy">
 *      ...
 *    </div>
 *    ...
 *  </template>
 * </CustomComponent>
 *
 * @example <caption>Utilizando a diretiva em um elemento HTML</caption>
 * <div v-scrollspy>
 *  <div class="section scrollspy">
 *    ...
 *  </div>
 *  ...
 * </div>
 */

/**
 * Objeto principal do {@link external:Materialize Materialize}.
 * @type {object}
 * @ignore
 */
const M = window ? window.M : null;

/**
 * Array para controlar quais elementos HTML ({@link external:Node Node}) já tiveram a
 * funcionalidade de _scrollspy_ do {@link external:Materialize Materialize} associada.
 * @type {Array<external:Node>}
 * @ignore
 */
const oldNodes = [];

/**
 * Ativa a funcionalidade de _scrollspy_ do {@link external:Materialize Materialize}
 * em um elemento HTML.
 * @function
 * @param {external:Node} el - Elemento HTML.
 * @param {object} [scrollspyOptions] - Opções aceitas pelo _scrollspy_ do {@link external:Materialize Materialize}.
 * @ignore
 */
const init = function(el, scrollspyOptions) {
  M.ScrollSpy.init(el, scrollspyOptions);
};

/**
 * Destrói um objeto Scrollspy do {@link external:Materialize Materialize} previamente
 * associado a um elemento HTML.
 * @function
 * @param {external:Node} el - Elemento HTML.
 * @ignore
 */
const destroy = function(el) {
  const instance = M.ScrollSpy.getInstance(el);

  if (instance) {
    instance.destroy();
  }
};

/**
 * @namespace Scrollspy
 * @category Diretivas
 * @summary Objeto/_namespace_ da diretiva de _scrollspy_ do {@link external:Materialize Materialize}.
 */
const Scrollspy = {
  /**
   * Realiza validações gerais.
   */
  bind() {
    if (!M) {
      window.console.error(
        "Objeto do Materialize não encontrado. Verifique se essa biblioteca foi carregada corretamente"
      );
    }
  },

  /**
   * Cria uma instância de _scrollspy_ do {@link external:Materialize Materialize}
   * para o elemento HTML em que a diretiva foi atribuída.
   * @param {external:Element} el - Elemento HTML em que a diretiva foi atribuída.
   * @param {object} binding - Objeto contendo propriedades inerentes à diretiva.
   */
  inserted(el, binding) {
    if (!M) {
      return;
    }

    el.querySelectorAll(".scrollspy").forEach(item => {
      init(item, binding.value);
      oldNodes.push(item);
    });
  },

  /**
   * Atualiza o _scrollspy_ do {@link external:Materialize Materialize}
   * para o elemento HTML em que a diretiva foi atribuída, destruindo-o e
   * instanciando-o novamente, sempre em que ele (ou seus elementos filhos) for
   * atualizado.
   *
   * Esta diretiva contém um controle interno para verificar se houve remoção
   * ou adição de algum elemento HTML com a classe "scrollspy", para evitar
   * degradação de desempenho.
   * @param {external:Element} el - Elemento HTML em que a diretiva foi atribuída.
   * @param {object} binding - Objeto contendo propriedades inerentes à diretiva.
   */
  componentUpdated(el, binding) {
    const newNodes = Array.from(el.querySelectorAll(".scrollspy"));

    // Destrói o Scrollspy associado para cada elemento (com classe scrollspy)
    // que não existe mais
    oldNodes.forEach(oldNode => {
      const node = newNodes.find(newNode => newNode.isEqualNode(oldNode));

      if (!node) {
        const index = oldNodes.findIndex(item => item.isEqualNode(oldNode));

        if (index !== -1) {
          oldNodes.splice(index, 1);
        }

        destroy(oldNode);
      }
    });

    // Inicializa o Scrollspy para cada elemento (com classe scrollspy) novo
    newNodes.forEach(newNode => {
      const node = oldNodes.find(oldNode => oldNode.isEqualNode(newNode));

      if (!node) {
        oldNodes.push(newNode);

        init(newNode, binding.value);
      }
    });
  },

  /**
   * Destrói a instância de _scrollspy_ do {@link external:Materialize Materialize}
   * associada ao elemento HTML em que a diretiva foi atribuída.
   */
  unbind() {
    if (!M) {
      return;
    }

    oldNodes.forEach(item => destroy(item));

    oldNodes.splice(0, oldNodes.length);
  }
};

export default Scrollspy;
