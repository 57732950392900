<template>
  <label v-bind:for="id">
    <input
      v-bind:id="id"
      type="checkbox"
      v-bind:name="name"
      v-bind:disabled="disabled"
      v-bind:class="{ 'filled-in': filledIn }"
      v-bind:value="value"
      v-bind:checked="isChecked"
      v-bind:title="$attrs.title"
      v-on="listeners"
    />
    <span>
      <slot>{{ content }}</slot>
    </span>
  </label>
</template>

<script>
/**
 * @module components/base/BaseCheckbox/BaseCheckbox
 * @category Componentes-base
 * @summary _Single File Component_ (SFC) de _checkbox_ do {@link external:Materialize Materialize}.
 *
 * @description
 * A cor de preenchimento e de marcação do _checkbox_ é obtida automaticamente
 * a partir da configuração do ambiente.
 *
 * O _slot_ _default_ recebe o conteúdo/_label_ do _checkbox_.
 *
 * Atributos HTML de acessibilidade, e.g., `title`, podem ser usados
 * diretamente no componente.
 *
 * @requires module:constants.DEFAULT_COLOR
 * @requires module:constants.DEFAULT_LUMINOSITY
 * @requires module:utils/web.createStyleElement
 * @requires module:utils/web.getMaterializeColorCode
 *
 * @vue-prop {string} [id] - Atributo `id` do elemento HTML utilizado
 * internamente pelo componente.
 * @vue-prop {string} [name] - Atributo `name` do elemento HTML utilizado
 * internamente pelo componente.
 * @vue-prop {boolean} [disabled=false] - Indica se o componente estará
 * desabilitado.
 * @vue-prop {boolean} [filledIn=true] - Indica se a caixa de seleção terá um
 * estilo preenchido.
 * @vue-prop {string} [content] - Conteúdo a ser exibido no elemento HTML
 * utilizado iternamente pelo componente. É usado como substituto quando nenhum
 * dado é passado através do _slot_ _default_.
 * @vue-prop {*} [value] - Valor inicial do componente e utilizado internamente
 * para a diretiva `v-model` do {@link external:Vue Vue}.
 * @vue-prop {boolean|string|number|object} [vValue=false] - Valor intrínseco e
 * representativo do componente.
 * @vue-event {boolean|string|number|object} onInput - Emite o valor
 * intrínseco e representativo do componente ou `null` (`false` no caso do
 * tipo ser `boolean`). Pode ser capturado pela diretiva `v-on:input`
 * (ou `v-model`) do {@link external:Vue Vue}.
 * @vue-event {boolean|string|number|object} onChange - Emite o valor
 * intrínseco e representativo do componente ou `null` (`false` no caso do
 * tipo ser `boolean`). Pode ser capturado pela diretiva `v-on:change` do {@link external:Vue Vue}.
 *
 * @example
 * <BaseCheckbox id="status-novo" name="exibir-novo" v-model="filtro.statusTarefa.novo">
 *  Novo
 * </BaseCheckbox>
 * <BaseCheckbox id="status-andamento" name="exibir-andamento" v-model="filtro.statusTarefa.andamento">
 *  Em andamento
 * </BaseCheckbox>
 * <BaseCheckbox id="status-pronto-teste" name="exibir-pronto-teste" v-model="filtro.statusTarefa.prontoTeste">
 *  Pronto para teste
 * </BaseCheckbox>
 * <BaseCheckbox id="status-fechado" name="exibir-fechado" v-model="filtro.statusTarefa.fechado">
 *  Fechado
 * </BaseCheckbox>
 * <BaseCheckbox id="status-precisa-info" name="exibir-precisa-info" v-model="filtro.statusTarefa.precisaInfo">
 *  Precisa de informação
 * </BaseCheckbox>
 */
import { DEFAULT_COLOR, DEFAULT_LUMINOSITY } from "../../../constants";
import {
  createStyleElement,
  getMaterializeColorCode
} from "../../../utils/web";

export default {
  name: "BaseCheckbox",
  inheritAttrs: false,
  props: {
    id: String,
    name: String,

    disabled: {
      type: Boolean,
      default: false
    },
    filledIn: {
      type: Boolean,
      default: true
    },

    content: String,

    value: {},
    vValue: {
      type: [Boolean, String, Number, Object],
      default: false
    }
  },
  computed: {
    isArray() {
      return Array.isArray(this.value);
    },
    isChecked() {
      return this.isArray ? this.value.indexOf(this.vValue) !== -1 : this.value;
    },
    listeners() {
      return Object.assign({}, this.$listeners, {
        change: this.onChange,
        // É necessário um evento "dummy" para que outros disparos da tag input
        // não gerem problemas em componentes que utilizam o BaseCheckbox
        input: () => {}
      });
    }
  },
  created() {
    this.overrideMaterializeStyle();
  },
  methods: {
    overrideMaterializeStyle() {
      const colorCode = getMaterializeColorCode(
        DEFAULT_COLOR,
        DEFAULT_LUMINOSITY
      );

      if (colorCode) {
        const CSSStyleSheet = `
            [type="checkbox"]:checked + span:not(.lever)::before {
                border-right-color: ${colorCode};
                border-bottom-color: ${colorCode};
            }
            [type="checkbox"].filled-in:checked + span:not(.lever)::after {
                border-color: ${colorCode};
                background-color: ${colorCode};
            }
            [type="checkbox"].filled-in.tabbed:checked:focus + span:not(.lever)::after {
                border-color: ${colorCode};
                background-color: ${colorCode};
            }
            [type="checkbox"]:indeterminate + span:not(.lever)::before {
                border-right-color: ${colorCode};
            }
      `;

        createStyleElement({
          id: "stylesheet-checkbox",
          type: "text/css",
          innerHTML: CSSStyleSheet
        });
      }
    },
    onChange() {
      if (this.isArray) {
        let index = this.value.indexOf(this.vValue);

        if (index !== -1) {
          this.value.splice(index, 1);
          this.$emit("input", this.value);
          this.$emit("change", this.value);
        } else {
          this.value.push(this.vValue);
          this.$emit("input", this.value);
          this.$emit("change", this.value);
        }
      } else if (
        typeof this.vValue === "string" ||
        typeof this.vValue === "number"
      ) {
        if (this.value === this.vValue) {
          this.$emit("input", null);
          this.$emit("change", null);
        } else {
          this.$emit("input", this.vValue);
          this.$emit("change", this.vValue);
        }
      } else {
        this.$emit("input", !this.value);
        this.$emit("change", !this.value);
      }
    }
  }
};
</script>

<style scoped>
.contrast [type="checkbox"] + span:not(.lever)::after {
  border-color: black;
  background-color: black;
}
/*
.contrast [type="checkbox"] + span:not(.lever)::before,
.contrast [type="checkbox"]:not(.filled-in) + span:not(.lever)::after {
  border-color: black;
}
.contrast [type="checkbox"].tabbed + span:not(.lever)::before,
.contrast [type="checkbox"].tabbed:not(.filled-in) + span:not(.lever)::after {
  border-color: white;
}
.contrast [type="checkbox"].tabbed:not(.filled-in) + span:not(.lever)::before {
  border: none;
  border-radius: 50%;
}
.contrast
  [type="checkbox"].tabbed:focus:not(.filled-in)
  + span:not(.lever)::after {
  border: 2px solid;
}
.contrast [type="checkbox"]:not(.filled-in):checked + span:not(.lever)::before {
  border: 2px solid;
  border-right-color: white;
  border-bottom-color: white;
} */

.contrast
  [type="checkbox"].filled-in.tabbed:not(:checked)
  + span:not(.lever)::after {
  border-color: white;
}
.contrast [type="checkbox"].filled-in:checked + span:not(.lever)::after {
  border-color: black;
  background-color: black;
}
.contrast
  [type="checkbox"].filled-in.tabbed:checked:focus
  + span:not(.lever)::after {
  border-color: white;
  background-color: black;
}
.contrast [type="checkbox"]:indeterminate + span:not(.lever)::before {
  border-right-color: white;
}
</style>
