<template>
  <component
    v-bind:is="component"
    v-bind:id="id"
    v-bind:tag="tag"
    v-bind:entidade-id="entidadeId"
    v-bind:modelo="modelo"
    v-bind:readonly="readonly"
    v-bind:secao="value"
  ></component>
</template>

<script>
import { TipoSecaoService } from "../../../services";
import EditableSectionDescricao from "./EditableSectionDescricao.vue";
import EditableSectionLinks from "./EditableSectionLinks.vue";
import EditableSectionGaleria from "./EditableSectionGaleria.vue";
import EditableSectionEstandes from "./EditableSectionEstandes.vue";
import EditableSectionConteudosProgramados from "./EditableSectionConteudosProgramados.vue";
import EditableSectionConteudosExtras from "./EditableSectionConteudosExtras.vue";

export default {
  name: "EditableSection",
  inheritAttrs: false,
  props: {
    readonly: Boolean,

    tag: {
      type: String,
      default: "h3",
      validator: value =>
        ["h1", "h2", "h3", "h4", "h5", "h6"].indexOf(value) !== -1
    },
    entidadeId: {
      type: Number,
      required: true
    },
    modelo: {
      type: Object,
      required: true
    },
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      id: this.getId(),
      component: this.getComponent()
    };
  },
  methods: {
    getId() {
      switch (this.value.tipoSecaoId) {
        case TipoSecaoService.DESCRICAO:
          return `descricao-${this.value.id}`;
        case TipoSecaoService.LINKS:
          return `links-${this.value.id}`;
        case TipoSecaoService.GALERIA:
          return `galeria-${this.value.id}`;
        case TipoSecaoService.ESTANDES:
          return `estandes-${this.value.id}`;
        case TipoSecaoService.CONTEUDOS_PROGRAMADOS:
          return `conteudos-programados-${this.value.id}`;
        case TipoSecaoService.CONTEUDOS_EXTRAS:
          return `conteudos-extras-${this.value.id}`;
        default:
          return null;
      }
    },
    getComponent() {
      switch (this.value.tipoSecaoId) {
        case TipoSecaoService.DESCRICAO:
          return EditableSectionDescricao;
        case TipoSecaoService.LINKS:
          return EditableSectionLinks;
        case TipoSecaoService.GALERIA:
          return EditableSectionGaleria;
        case TipoSecaoService.ESTANDES:
          return EditableSectionEstandes;
        case TipoSecaoService.CONTEUDOS_PROGRAMADOS:
          return EditableSectionConteudosProgramados;
        case TipoSecaoService.CONTEUDOS_EXTRAS:
          return EditableSectionConteudosExtras;
        default:
          return null;
      }
    }
  }
};
</script>
