<template>
  <div class="switch">
    <label v-bind:for="id">
      <slot name="off">{{ offContent }}</slot>
      <input
        v-bind:id="id"
        type="checkbox"
        v-bind:name="name"
        v-bind:disabled="disabled"
        v-bind:value="value"
        v-bind:checked="isChecked"
        v-on="listeners"
      />
      <span class="lever" v-bind:title="$attrs.title"></span>
      <slot name="on">{{ onContent }}</slot>
    </label>
  </div>
</template>

<script>
/**
 * @module components/base/BaseSwitch/BaseSwitch
 * @category Componentes-base
 * @summary _Single File Component_ (SFC) de _switch_ do {@link external:Materialize Materialize}.
 *
 * @description
 * A cor do _handler_ do _switch_ é obtida automaticamente a partir da
 * configuração do ambiente.
 *
 * Os _slots_ "off" e "on" recebem os conteúdos textuais correspondentes aos
 * valores negativos e positivo, respectivamente. Este componente não possui
 * _slot_ _default_.
 *
 * @requires module:constants.DEFAULT_COLOR
 * @requires module:constants.DEFAULT_LUMINOSITY
 * @requires module:utils/web.createStyleElement
 * @requires module:utils/web.desaturate
 * @requires module:utils/web.getMaterializeColorCode
 * @requires module:utils/web.lighten
 * @requires module:utils/web.transparentize
 *
 * Atributos HTML de acessibilidade, e.g., `title`, podem ser usados
 * diretamente no componente.
 *
 * @vue-prop {string} [id] - Atributo `id` do elemento HTML utilizado
 * internamente pelo componente.
 * @vue-prop {string} [name] - Atributo `name` do elemento HTML utilizado
 * internamente pelo componente.
 * @vue-prop {boolean} [disabled=false] - Indica se o componente estará desabilitado.
 * @vue-prop {string} [offContent] - Conteúdo textual quando o _switch_ está
 * "desligado". É usado como substituto quando nenhum dado é passado através do _slot_ "off".
 * @vue-prop {string} [onContent] - Conteúdo textual quando o _switch_ está
 * "ligado". É usado como substituto quando nenhum dado é passado através do _slot_ "on".
 * @vue-prop {boolean} [value] - Valor inicial do componente e utilizado internamente
 * para a diretiva `v-model` do {@link external:Vue Vue}.
 * @vue-event {boolean} onInput - Emite o valor intrínseco e representativo do
 * componente. Pode ser capturado pela diretiva `v-on:input` (ou `v-model`) do
 * {@link external:Vue Vue}.
 * @vue-event {boolean} onChange - Emite o valor intrínseco e representativo do
 * componente. Pode ser capturado pela diretiva `v-on:change` do {@link external:Vue Vue}.
 *
 * @example
 * <BaseSwitch id="switch" name="switch" v-model="servidor.brasileiro">
 *  <template v-slot:off>
 *    Não
 *  </template>
 *  <template v-slot:on>
 *    Sim
 *  </template>
 * </BaseSwitch>
 */
import { DEFAULT_COLOR, DEFAULT_LUMINOSITY } from "../../../constants";
import {
  createStyleElement,
  desaturate,
  getMaterializeColorCode,
  lighten,
  transparentize
} from "../../../utils/web";

export default {
  name: "BaseSwitch",
  inheritAttrs: false,
  props: {
    id: String,
    name: String,

    disabled: {
      type: Boolean,
      default: false
    },

    offContent: String,
    onContent: String,

    value: Boolean
  },
  computed: {
    isChecked() {
      return this.value;
    },
    listeners() {
      return Object.assign({}, this.$listeners, {
        change: this.onChange,
        // É necessário um evento "dummy" para que outros disparos da tag input
        // não gerem problemas em componentes que utilizam o BaseSwitch
        input: () => {}
      });
    }
  },
  created() {
    this.overrideMaterializeStyle();
  },
  methods: {
    overrideMaterializeStyle() {
      const colorCode = getMaterializeColorCode(
        DEFAULT_COLOR,
        DEFAULT_LUMINOSITY
      );

      if (colorCode) {
        const CSSStyleSheet = `
        .switch label input[type="checkbox"]:checked + .lever {
          background-color: ${desaturate(
            lighten(colorCode, 0.25),
            0.25
          )}; /* $switch-checked-lever-bg (desaturate(lighten($switch-bg-color, 25%), 25%)) */
        }
        .switch label input[type="checkbox"]:checked + .lever::after {
          background-color: ${colorCode}; /* $switch-bg-color ($secondary-color) */
        }
        .switch label .lever::before {
          background-color: ${transparentize(
            colorCode,
            0.85
          )}; /* transparentize($switch-bg-color, .85) */
        }
        input[type="checkbox"]:checked:not(:disabled) ~ .lever:active::before,
        input[type="checkbox"]:checked:not(:disabled).tabbed:focus ~ .lever::before {
          background-color: ${transparentize(
            colorCode,
            0.85
          )}; /* transparentize($switch-bg-color, .85) */
        }
      `;

        createStyleElement({
          id: "stylesheet-switch",
          type: "text/css",
          innerHTML: CSSStyleSheet
        });
      }
    },
    onChange() {
      this.$emit("input", !this.value);
      this.$emit("change", !this.value);
    }
  }
};
</script>

<style scoped>
.contrast .switch label input[type="checkbox"] + .lever {
  background-color: black !important;
  border: 1px solid white;
}
.contrast .switch label input[type="checkbox"] + .lever::after {
  background-color: white !important;
}
.contrast .switch label .lever::before {
  background-color: white !important;
}
</style>
