<template>
  <nav class="z-depth-0" v-bind:class="[color, luminosity]">
    <div class="nav-wrapper">
      <TheAccessibilitySkipMenu />

      <BaseRouterLink v-bind:to="{ name: 'home' }" class="brand-logo">
        <img
          class="responsive-img"
          src="../assets/images/logos/pae-logo.png"
          v-bind:title="appName"
          v-bind:alt="appName"
        />
      </BaseRouterLink>

      <a href="#!" data-target="pae-sidenav" class="sidenav-trigger">
        <i class="material-icons">menu</i>
      </a>

      <ul id="pae-navbar-menu" class="right hide-on-med-and-down">
        <li>
          <BaseRouterLink v-bind:to="{ name: 'home' }">Home</BaseRouterLink>
        </li>
        <li>
          <BaseRouterLink v-bind:to="{ name: 'listEventos' }"
            >Eventos</BaseRouterLink
          >
        </li>
        <li v-for="(item, index) in extraNavbar" v-bind:key="index">
          <a v-bind:href="`#${item.id}`">{{ item.title }}</a>
        </li>
        <li>
          <a id="pae-navbar-menu-ajuda" href="#!" v-on:click="openHelp"
            >Ajuda</a
          >
        </li>
        <li>
          <TheAccessibilityMenu />
        </li>
        <TheHeaderNavbarLogin v-if="!isUserLoggedIn" />
        <TheHeaderNavbarUser v-else />
      </ul>
    </div>
  </nav>
</template>

<script>
import {
  APP_INFO,
  DEFAULT_COLOR,
  DEFAULT_LUMINOSITY,
  HELP_INFOTIC_URL
} from "../constants";
import TheAccessibilitySkipMenu from "./TheAccessibilitySkipMenu.vue";
import TheAccessibilityMenu from "./TheAccessibilityMenu.vue";
import TheHeaderNavbarLogin from "./TheHeaderNavbarLogin.vue";
import TheHeaderNavbarUser from "./TheHeaderNavbarUser.vue";

export default {
  name: "TheHeaderNavbar",
  components: {
    TheAccessibilitySkipMenu,
    TheAccessibilityMenu,
    TheHeaderNavbarLogin,
    TheHeaderNavbarUser
  },
  inheritAttrs: false,
  data() {
    return {
      color: DEFAULT_COLOR,
      luminosity: DEFAULT_LUMINOSITY,
      appName: APP_INFO.name,
      helpUrl: `${HELP_INFOTIC_URL}/duvidas-sistema/${APP_INFO.name}`
    };
  },
  computed: {
    extraNavbar() {
      return this.$store.state.extraNavbar || [];
    },
    isUserLoggedIn() {
      // Não utiliza o método `AuthenticationService.isAuthenticated` pois
      // ele também verifica se a sessão ainda está ativa. Aqui, apenas
      // preciso saber se o usuário acessou o sistema recentemente
      return !this.$utils.Lang.isNullOrUndefined(
        this.$store.state.authenticationData
      );
    }
  },
  methods: {
    openHelp() {
      window.open(this.helpUrl);
    }
  }
};
</script>

<style scoped>
.brand-logo {
  line-height: 1rem;
  height: 100%;
}
.brand-logo img {
  height: 100%;
}

.nav-wrapper /deep/ .accessibilitymenu-wrapper a {
  float: left;
}
</style>
