<template>
  <table
    v-bind:id="id"
    v-bind:class="{
      stripe: isStriped,
      highlight: isHighlighted,
      centered: isCentered,
      'responsive-table': isResponsive
    }"
  >
    <thead>
      <slot name="head">
        <BaseTableRow v-bind="head" />
      </slot>
    </thead>
    <tbody>
      <slot name="body">
        <BaseTableRow
          v-for="(item, index) in body"
          v-bind:key="index"
          v-bind:columns="item"
        />
      </slot>
    </tbody>
  </table>
</template>

<script>
/**
 * @module components/base/BaseTable/BaseTable
 * @category Componentes-base
 * @summary _Single File Component_ (SFC) de tabela do {@link external:Materialize Materialize}.
 *
 * @description
 * O _slot_ "head" recebe o cabeçalho da tabela. O _slot_ "body" serve para
 * exibir as linhas da tabela. Este componente não possui _slot_ _default_.
 *
 * @vue-prop {string} [id] - Atributo `id` do elemento HTML utilizado
 * internamente pelo componente.
 * @vue-prop {boolean} [isStriped] - Indica se a tabela apresentará as linhas
 * com fundo escuro alternado.
 * @vue-prop {boolean} [isHighlighted] - Indica se a tabela apresentará a linha
 * que estiver com o _mouse_ por cima com fundo escuro.
 * @vue-prop {boolean} [isCentered] - Indica se a tabela apresentará seu
 * conteúdo centralizado.
 * @vue-prop {boolean} [isResponsive] - Indica se a tabela apresentará seu
 * conteúdo rotacionado (linhas em colunas) para dispositivos com telas menores.
 * @vue-prop {object} [head] - Objeto contendo as `props` aceitas pelo
 * componente-base {@link module:components/base/BaseTableHead/BaseTableRow BaseTableRow}.
 * @vue-prop {Array<object>} [body=[]] - Lista de objetos que contêm as `props`
 * aceitas pelo componente-base {@link module:components/base/BaseTableRow/BaseTableRow BaseTableRow}.
 *
 * @example
 * <BaseTable
 *  v-bind:is-striped="true"
 *  v-bind:head="[{ content: 'Nome', isHeader: true }, { content: 'Preço', isHeader: true }]"
 *  v-bind:body="[[{ content: 'Bomba' }, { content: 'R$ 8,00' }], [{ content: 'Croissant' }, { content: 'R$ 7,00' }]]"
 * />
 */
import BaseTableRow from "../BaseTableRow/BaseTableRow.vue";

export default {
  name: "BaseTable",
  components: {
    BaseTableRow
  },
  inheritAttrs: false,
  props: {
    id: String,
    isStriped: Boolean,
    isHighlighted: Boolean,
    isCentered: Boolean,
    isResponsive: Boolean,
    head: Object,

    body: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style scoped>
.contrast tr {
  border-bottom: 1px solid white !important;
}
.contrast table.striped tr {
  border-bottom: none !important;
}
.contrast table.striped > tbody > tr:nth-child(2n + 1) {
  background-color: white !important;
  color: black !important;
}
.contrast table.highlight > tbody > tr:hover {
  background-color: white !important;
  color: black !important;
}

@media screen and (max-width: 992px) {
  .contrast table.responsive-table tr {
    border-bottom: none !important;
  }
  .contrast table.responsive-table thead {
    border-right: 1px solid white !important;
  }
}
</style>
