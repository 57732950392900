/**
 * @module services/Estande
 * @category Serviços
 * @summary Módulo de importação do serviço de estandes.
 *
 * @description
 * Expõe o serviço de estandes do sistema para utilização interna.
 *
 * @example <caption>Importando o serviço de estandes como membro default do módulo</caption>
 * import EstandeService from "./services/Estande";
 *
 * @example <caption>Importando o serviço de estandes como membro do módulo</caption>
 * import { EstandeService } from "./services/Estande";
 */
import { default as EstandeService } from "./Estande";

export default EstandeService;

export { EstandeService };
