/**
 * @module services/Status
 * @category Serviços
 * @summary Módulo de importação do serviço de _status_.
 *
 * @description
 * Expõe o serviço de _status_ do sistema para utilização interna.
 *
 * @example <caption>Importando o serviço de _status_ como membro default do módulo</caption>
 * import StatusService from "./services/Status";
 *
 * @example <caption>Importando o serviço de _status_ como membro do módulo</caption>
 * import { StatusService } from "./services/Status";
 */
import { default as StatusService } from "./Status";

export default StatusService;

export { StatusService };
