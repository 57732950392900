/**
 * @module utils/array
 * @category Utilidades
 * @summary Módulo de utilidades para manipulação de _arrays_.
 *
 * @description
 * Expõe funções de utilidades para manipulação de _arrays_.
 */

/**
 * Busca um elemento de um _array_ de objetos a partir de um filtro de busca.
 * @function
 * @param {Array<object>} arr - _array_ com os elementos.
 * @param {string} propName - Nome da propriedade utilizada como filtro de
 * busca.
 * @param {*} propValue - Valor da propriedade utilizada como filtro de busca.
 * @returns {object} Primeiro elemento do _array_ cujo filtro de busca foi
 * satisfeito.
 */
export const findByObjectProp = function(arr, propName, propValue) {
  return (arr || []).find(function(value) {
    return value && value[propName] === propValue;
  });
};

export default { findByObjectProp };
