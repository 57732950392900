/**
 * @module components/base/BaseRadioButton
 * @category Componentes-base
 * @summary Módulo de importação do componente-base de radiobutton do {@link external:Materialize Materialize}.
 *
 * @description
 * Expõe o componente-base de radiobutton do {@link external:Materialize Materialize} para utilização pelo container e pelos
 * módulos.
 *
 * @example <caption>Importando como plug-in</caption>
 * // main.js
 * import BaseRadioButton from "./components/base/BaseRadioButton";
 *
 * Vue.use(BaseRadioButton);
 *
 * @example <caption>Importando diretamente em um componente</caption>
 * // componente.vue
 * // ou import BaseRadioButton from "./components/base/BaseRadioButton/BaseRadioButton.vue";
 * import { BaseRadioButton } from "./components/base/BaseRadioButton";
 *
 * export default {
 *  name: // ...
 *  components: {
 *    BaseRadioButton
 *  },
 *  // ...
 * }
 */
import { default as BaseRadioButton } from "./BaseRadioButton.vue";

/**
 * Associa o componente-base de radiobutton à instância do {@link external:Vue Vue}.
 * @param {external:Vue} Vue - Instância do {@link external:Vue Vue}.
 */
const install = Vue => Vue.component(BaseRadioButton.name, BaseRadioButton);

if (window && window.Vue) {
  install(window.Vue);
}

export default install;

export { BaseRadioButton };
