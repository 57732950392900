/**
 * @module services/TipoLink/TipoLink
 * @category Serviços
 * @summary Módulo do serviço de tipos de _link_.
 *
 * @description
 * Expõe o objeto/_namespace_ do serviço de tipos de _link_ do sistema para
 * utilização interna.
 *
 * Para correto funcionamento, é necessário que o _plug-in_ de requisições HTTP
 * seja registrado a este serviço.
 *
 * @requires module:constants.API_URL
 *
 * @example
 * import TipoLinkService from "./services/TipoLink/TipoLink";
 *
 * // Registrando o _plug-in_ de requisiçõe HTTP do container.
 * TipoLinkService.registerHttp(Http);
 *
 * // Obtendo a lista de tipos de _link_
 * TipoLinkService.getAll()
 *  .then(response => {
 *    // ...
 *  })
 *  .catch(error => {
 *    // ...
 *  })
 */
import { API_URL } from "../../constants";

/**
 * @namespace TipoLinkService
 * @category Serviços
 * @summary Objeto/_namespace_ do serviço de tipos de _link_.
 */
const TipoLinkService = {
  /**
   * URL de acesso à API para obtenção de tipos de _link_.
   * @type {string}
   * @private
   * @readonly
   */
  baseUrl: `${API_URL}/tipos-link`,

  /**
   * _Plug-in_ de requisições HTTP.
   * @type {module:plugins/Http/Http}
   * @private
   * @readonly
   */
  http: null,

  /**
   * Tipo de _link_ "Institucional".
   * @type {number}
   * @readonly
   */
  INSTITUCIONAL: 1,

  /**
   * Tipo de _link_ "Externo".
   * @type {number}
   * @readonly
   */
  EXTERNO: 2,

  /**
   * Tipo de _link_ "YouTube".
   * @type {number}
   * @readonly
   */
  YOUTUBE: 3,

  /**
   * Tipo de _link_ "Facebook".
   * @type {number}
   * @readonly
   */
  FACEBOOK: 4,

  /**
   * Tipo de _link_ "Instagram".
   * @type {number}
   * @readonly
   */
  INSTAGRAM: 5,

  /**
   * Tipo de _link_ "Twitter".
   * @type {number}
   * @readonly
   */
  TWITTER: 6,

  /**
   * Tipo de _link_ "TikTok".
   * @type {number}
   * @readonly
   */
  TIKTOK: 7,

  /**
   * Tipo de _link_ "Spotify".
   * @type {number}
   * @readonly
   */
  SPOTIFY: 8,

  /**
   * Tipo de _link_ "Podcast".
   * @type {number}
   * @readonly
   */
  PODCAST: 9,

  /**
   * Nome do ícone para quando o tipo de _link_ é "Externo".
   * @type {string}
   * @readonly
   */
  ICON_EXTERNO: "open_in_new",

  /**
   * Nome do ícone para quando o tipo de _link_ é "Podcast".
   * @type {string}
   * @readonly
   */
  ICON_PODCAST: "mic",

  /**
   * Nome do ícone para quando o tipo de _link_ é "TikTok".
   * @type {string}
   * @readonly
   */
  ICON_TIKTOK: "tiktok",

  /**
   * Imagem (em Base64) para quando o tipo de _link_ é "Institucional".
   * @type {string}
   * @readonly
   */
  IMG_INSTITUCIONAL: require("../../assets/images/logos/minerva-positivo.png"),

  /**
   * Imagem (em Base64) para quando o tipo de _link_ é "YouTube".
   * @type {string}
   * @readonly
   */
  IMG_YOUTUBE: require("../../assets/images/logos/youtube-icon-color.png"),

  /**
   * Imagem (em Base64) para quando o tipo de _link_ é "Facebook".
   * @type {string}
   * @readonly
   */
  IMG_FACEBOOK: require("../../assets/images/logos/facebook-color.png"),

  /**
   * Imagem (em Base64) para quando o tipo de _link_ é "Instagram".
   * @type {string}
   * @readonly
   */
  IMG_INSTAGRAM: require("../../assets/images/logos/instagram-glyph-color.png"),

  /**
   * Imagem (em Base64) para quando o tipo de _link_ é "Twitter".
   * @type {string}
   * @readonly
   */
  IMG_TWITTER: require("../../assets/images/logos/twitter-roundedsquare-blue.png"),

  /**
   * Imagem (em Base64) para quando o tipo de _link_ é "Spotify".
   * @type {string}
   * @readonly
   */
  IMG_SPOTIFY: require("../../assets/images/logos/spotify-color.png"),

  /**
   * Envia uma requisição para obter todos os tipos de _link_, retornando uma Promise.
   * @returns {external:Promise}
   */
  getAll() {
    return this.http.get(`${this.baseUrl}/`);
  },

  /**
   * Envia uma requisição para obter um tipo de _link_, retornando uma Promise.
   * @param {number} id - Identificador do tipo de _link_.
   * @returns {external:Promise}
   */
  get(id) {
    return this.http.get(`${this.baseUrl}/${id}`);
  },

  /**
   * Registra o _plug-in_ de requisições HTTP no _namespace_ do serviço.
   * @param {module:plugins/Http/Http} http - _Plug-in_ de requisições HTTP.
   * @returns {module:services/TipoLink/TipoLink~TipoLinkService} O próprio namespace.
   */
  registerHttp(http) {
    this.http = http;
    return this;
  }
};

export default TipoLinkService;
