/**
 * @module components/base/BaseInfoCard
 * @category Componentes-base
 * @summary Módulo de importação do componente utilitário de _card_ informativo.
 *
 * @description
 * Expõe o componente de _card_ informativo, baseado no {@link module:components/base/BaseCard/BaseCard BaseCard},
 * para utilização pelo container e pelos módulos.
 *
 * @example <caption>Importando como plug-in</caption>
 * // main.js
 * import BaseInfoCard from "./components/base/BaseInfoCard";
 *
 * Vue.use(BaseInfoCard);
 *
 * @example <caption>Importando diretamente em um componente</caption>
 * // componente.vue
 * // ou import BaseInfoCard from "./components/base/BaseInfoCard/BaseInfoCard.vue";
 * import { BaseInfoCard } from "./components/base/BaseInfoCard";
 *
 * export default {
 *  name: // ...
 *  components: {
 *    BaseInfoCard
 *  },
 *  // ...
 * }
 */
import { default as BaseInfoCard } from "./BaseInfoCard.vue";

/**
 * Associa o componente-base de _card_ informativo à instância do {@link external:Vue Vue}.
 * @param {external:Vue} Vue - Instância do {@link external:Vue Vue}.
 */
const install = Vue => Vue.component(BaseInfoCard.name, BaseInfoCard);

if (window && window.Vue) {
  install(window.Vue);
}

export default install;

export { BaseInfoCard };
