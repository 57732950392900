/**
 * @module services/Estande/Estande
 * @category Serviços
 * @summary Módulo do serviço de estandes.
 *
 * @description
 * Expõe o objeto/_namespace_ do serviço de estandes do sistema para
 * utilização interna.
 *
 * Para correto funcionamento, é necessário que o _plug-in_ de requisições HTTP
 * seja registrado a este serviço.
 *
 * @requires module:constants.API_URL
 * @requires module:utils/lang.isEmpty
 * @requires module:utils/datetime.isISODate
 *
 * @example
 * import EstandeService from "./services/Estande/Estande";
 *
 * // Registrando o _plug-in_ de requisiçõe HTTP do container.
 * EstandeService.registerHttp(Http);
 *
 * const estande = // ...
 *
 * // Obtendo a lista de seções
 * EstandeService.getSecoes(estande.id)
 *  .then(response => {
 *    // ...
 *  })
 *  .catch(error => {
 *    // ...
 *  })
 */
import { API_URL } from "../../constants";
import { isEmpty } from "../../utils/lang";
import { isAfter, isISODate } from "../../utils/datetime";

/**
 * @namespace EstandeService
 * @category Serviços
 * @summary Objeto/_namespace_ do serviço de estandes.
 */
const EstandeService = {
  /**
   * URL de acesso à API para obtenção de estandes.
   * @type {string}
   * @private
   * @readonly
   */
  baseUrl: `${API_URL}/estandes`,

  /**
   * _Plug-in_ de requisições HTTP.
   * @type {module:plugins/Http/Http}
   * @private
   * @readonly
   */
  http: null,

  /**
   * Realiza a validação de um estande.
   * @param {object} val - Estande a ser validado.
   * @returns {Array<Error>} - _Array_ de possíveis erros.
   */
  validate(val) {
    const errors = [];

    if (isEmpty(val)) {
      errors.push(new Error("Estande não preenchido"));
    } else {
      if (isEmpty(val.nome)) {
        errors.push(new Error("Nome do estande inválido"));
      }

      const { inicio, termino } = val;
      const oneNull = (inicio && !termino) || (!inicio && termino);
      const noneNull = inicio && termino;

      if (oneNull) {
        errors.push(
          new Error("Data de início ou de término do estande em branco")
        );
      } else if (noneNull) {
        let isInicioValid = false;
        let isTerminoValid = false;

        try {
          if (!isISODate(inicio)) {
            errors.push(new Error("Data início do estande inválida"));
          } else {
            isInicioValid = true;
          }
        } catch (e) {
          errors.push(new Error("Data início do estande inválida"));
        }

        try {
          if (!isISODate(termino)) {
            errors.push(new Error("Data término do estande inválida"));
          } else {
            isTerminoValid = true;
          }
        } catch (e) {
          errors.push(new Error("Data término do estande inválida"));
        }

        if (isInicioValid && isTerminoValid && isAfter(inicio, termino)) {
          errors.push(
            new Error("Datas de início e de término do estande conflitantes")
          );
        }
      }
    }

    return errors;
  },

  /**
   * Envia uma requisição para definir o _status_ de um estande, retornando uma
   * Promise.
   * @param {number} idEstande - Identificador do estande.
   * @param {number} idStatus - Identificador do _status_.
   * @returns {external:Promise}
   */
  setStatus(idEstande, idStatus) {
    return this.http.put(`${this.baseUrl}/${idEstande}/status/${idStatus}`);
  },

  /**
   * Envia uma requisição para obter seções de um estande, retornando uma
   * Promise.
   * @param {number} id - Identificador do estande.
   * @returns {external:Promise}
   */
  getSecoes(id) {
    return this.http.get(`${this.baseUrl}/${id}/secoes`);
  },

  /**
   * Envia uma requisição para obter o _banner_ de um estande, retornando uma
   * Promise.
   * @param {number} id - Identificador do estande.
   * @param {object} [options={}] - Opções aceitas pelo {@link external:axios axios}.
   * @returns {external:Promise}
   */
  getBanner(id, options = {}) {
    return this.http.get(`${this.baseUrl}/${id}/banner`, options);
  },

  /**
   * Constrói a URL de obtenção do conteúdo do _banner_ de um estande.
   * @param {number} id - Identificador do estande.
   * @returns {string}
   */
  getBannerConteudoURL(id) {
    return `${this.baseUrl}/${id}/banner/conteudo`;
  },

  /**
   * Envia uma requisição para definir o _banner_ de um estande, retornando uma
   * Promise.
   * @param {number} id - Identificador do estande.
   * @param {object} banner - Dados do banner.
   * @returns {external:Promise}
   */
  setBanner(id, banner) {
    return this.http.put(`${this.baseUrl}/${id}/banner`, banner);
  },

  /**
   * Envia uma requisição para excluir o _banner_ de um estande, retornando uma
   * Promise.
   * @param {number} id - Identificador do estande.
   * @returns {external:Promise}
   */
  deleteBanner(id) {
    return this.http.delete(`${this.baseUrl}/${id}/banner`);
  },

  /**
   * Envia uma requisição para obter os assuntos de um estande, retornando uma
   * Promise.
   * @param {number} id - Identificador do estande.
   * @returns {external:Promise}
   */
  getAssuntos(id) {
    return this.http.get(`${this.baseUrl}/${id}/assuntos`);
  },

  /**
   * Envia uma requisição para associar um assunto ao estande, retornando uma
   * Promise.
   * @param {number} idEstande - Identificador do estande.
   * @param {number} idAssunto - Identificador do assunto.
   * @returns {external:Promise}
   */
  addAssunto(idEstande, idAssunto) {
    return this.http.put(`${this.baseUrl}/${idEstande}/assuntos/${idAssunto}`);
  },

  /**
   * Envia uma requisição para desassociar um assunto ao estande, retornando uma
   * Promise.
   * @param {number} idEstande - Identificador do estande.
   * @param {number} idAssunto - Identificador do assunto.
   * @returns {external:Promise}
   */
  removeAssunto(idEstande, idAssunto) {
    return this.http.delete(
      `${this.baseUrl}/${idEstande}/assuntos/${idAssunto}`
    );
  },

  /**
   * Envia uma requisição para obter os _links_ de um estande, retornando uma
   * Promise.
   * @param {number} id - Identificador do estande.
   * @returns {external:Promise}
   */
  getLinks(id) {
    return this.http.get(`${this.baseUrl}/${id}/links`);
  },

  /**
   * Envia uma requisição para criar um _link_ no estande, retornando uma
   * Promise.
   * @param {number} id - Identificador do estande.
   * @param {object} link - Dados do _link_.
   * @returns {external:Promise}
   */
  createLink(id, link) {
    return this.http.post(`${this.baseUrl}/${id}/links`, link);
  },

  /**
   * Envia uma requisição para atualizar um _link_ do estande, retornando uma
   * Promise.
   * @param {number} idEstande - Identificador do estande.
   * @param {number} idLink - Identificador do _link_.
   * @param {object} link - Dados do _link_.
   * @returns {external:Promise}
   */
  updateLink(idEstande, idLink, link) {
    return this.http.put(`${this.baseUrl}/${idEstande}/links/${idLink}`, link);
  },

  /**
   * Envia uma requisição para excluir um _link_ do estande, retornando uma
   * Promise.
   * @param {number} idEstande - Identificador do estande.
   * @param {number} idLink - Identificador do _link_.
   * @returns {external:Promise}
   */
  deleteLink(idEstande, idLink) {
    return this.http.delete(`${this.baseUrl}/${idEstande}/links/${idLink}`);
  },

  /**
   * Envia uma requisição para obter uma das possíveis descrições de um estande,
   * retornando uma Promise.
   * @param {number} idEstande - Identificador do estande.
   * @param {number} idSecao - Identificador da seção.
   * @param {object} [options={}] - Opções aceitas pelo {@link external:axios axios}.
   * @returns {external:Promise}
   */
  getDescricao(idEstande, idSecao, options = {}) {
    return this.http.get(
      `${this.baseUrl}/${idEstande}/secoes/${idSecao}/descricao`,
      options
    );
  },

  /**
   * Envia uma requisição para definir uma das possíveis descrições de um
   * estande, retornando uma Promise.
   * @param {number} idEstande - Identificador do estande.
   * @param {number} idSecao - Identificador da seção.
   * @param {object} descricao - Dados da descrição.
   * @returns {external:Promise}
   */
  setDescricao(idEstande, idSecao, descricao) {
    return this.http.put(
      `${this.baseUrl}/${idEstande}/secoes/${idSecao}/descricao`,
      descricao
    );
  },

  /**
   * Envia uma requisição para excluir uma das possíveis descrições de um
   * estande, retornando uma Promise.
   * @param {number} idEstande - Identificador do estande.
   * @param {number} idSecao - Identificador da seção.
   * @returns {external:Promise}
   */
  deleteDescricao(idEstande, idSecao) {
    return this.http.delete(
      `${this.baseUrl}/${idEstande}/secoes/${idSecao}/descricao`
    );
  },

  /**
   * Envia uma requisição para obter os itens de galeria de um estande,
   * retornando uma Promise.
   * @param {number} id - Identificador do estande.
   * @returns {external:Promise}
   */
  getGaleriaItens(id) {
    return this.http.get(`${this.baseUrl}/${id}/galeria-itens`);
  },

  /**
   * Envia uma requisição para obter a imagem de um item de galeria do estande,
   * retornando uma Promise.
   * @param {number} idEstande - Identificador do estande.
   * @param {number} idGaleriaItem - Identificador do item de galeria.
   * @param {object} [options={}] - Opções aceitas pelo {@link external:axios axios}.
   * @returns {external:Promise}
   */
  getImagemOfGaleriaItem(idEstande, idGaleriaItem, options = {}) {
    return this.http.get(
      `${this.baseUrl}/${idEstande}/galeria-itens/${idGaleriaItem}/imagem`,
      options
    );
  },

  /**
   * Constrói a URL de obtenção do conteúdo da imagem de um item de galeria do
   * estande.
   * @param {number} idEstande - Identificador do estande.
   * @param {number} idGaleriaItem - Identificador do item de galeria.
   * @returns {string}
   */
  getImagemConteudoURLOfGaleriaItem(idEstande, idGaleriaItem) {
    return `${this.baseUrl}/${idEstande}/galeria-itens/${idGaleriaItem}/imagem/conteudo`;
  },

  /**
   * Envia uma requisição para criar um item de galeria no estande, retornando
   * uma Promise.
   * @param {number} id - Identificador do estande.
   * @param {object} galeriaItem - Dados do item de galeria.
   * @returns {external:Promise}
   */
  createGaleriaItem(id, galeriaItem) {
    return this.http.post(`${this.baseUrl}/${id}/galeria-itens`, galeriaItem);
  },

  /**
   * Envia uma requisição para atualizar um item de galeria do estande,
   * retornando uma Promise.
   * @param {number} idEstande - Identificador do estande.
   * @param {number} idGaleriaItem - Identificador do item de galeria.
   * @param {object} galeriaItem - Dados do item de galeria.
   * @returns {external:Promise}
   */
  updateGaleriaItem(idEstande, idGaleriaItem, link) {
    return this.http.put(
      `${this.baseUrl}/${idEstande}/galeria-itens/${idGaleriaItem}`,
      link
    );
  },

  /**
   * Envia uma requisição para excluir um item de galeria do estande, retornando
   * uma Promise.
   * @param {number} idEstande - Identificador do estande.
   * @param {number} idGaleriaItem - Identificador do item de galeria.
   * @returns {external:Promise}
   */
  deleteGaleriaItem(idEstande, idGaleriaItem) {
    return this.http.delete(
      `${this.baseUrl}/${idEstande}/galeria-itens/${idGaleriaItem}`
    );
  },

  /**
   * Envia uma requisição para obter os conteúdos programados de um estande,
   * retornando uma Promise.
   * @param {number} id - Identificador do estande.
   * @returns {external:Promise}
   */
  getConteudosProgramados(id) {
    return this.http.get(`${this.baseUrl}/${id}/conteudos-programados`);
  },

  /**
   * Envia uma requisição para obter um conteúdo programado do estande,
   * retornando uma Promise.
   * @param {number} idEstande - Identificador do estande.
   * @param {number} idConteudo - Identificador do conteúdo.
   * @returns {external:Promise}
   */
  getConteudoProgramado(idEstande, idConteudo) {
    return this.http.get(
      `${this.baseUrl}/${idEstande}/conteudos-programados/${idConteudo}`
    );
  },

  /**
   * Envia uma requisição para obter os apresentadores de um conteúdo
   * programado do estande, retornando uma Promise.
   * @param {number} idEstande - Identificador do estande.
   * @param {number} idConteudo - Identificador do conteúdo.
   * @returns {external:Promise}
   */
  getApresentadoresOfConteudoProgramado(idEstande, idConteudo) {
    return this.http.get(
      `${this.baseUrl}/${idEstande}/conteudos-programados/${idConteudo}/apresentadores`
    );
  },

  /**
   * Envia uma requisição para criar um conteúdo programado no estande,
   * retornando uma Promise.
   * @param {number} id - Identificador do estande.
   * @param {object} conteudo - Dados do conteúdo.
   * @returns {external:Promise}
   */
  createConteudoProgramado(id, conteudo) {
    return this.http.post(
      `${this.baseUrl}/${id}/conteudos-programados`,
      conteudo
    );
  },

  /**
   * Envia uma requisição para atualizar um conteúdo programado do estande,
   * retornando uma Promise.
   * @param {number} idEstande - Identificador do estande.
   * @param {number} idConteudo - Identificador do conteúdo.
   * @param {object} conteudo - Dados do conteúdo.
   * @returns {external:Promise}
   */
  updateConteudoProgramado(idEstande, idConteudo, conteudo) {
    return this.http.put(
      `${this.baseUrl}/${idEstande}/conteudos-programados/${idConteudo}`,
      conteudo
    );
  },

  /**
   * Envia uma requisição para excluir um conteúdo programado do estande,
   * retornando uma Promise.
   * @param {number} idEstande - Identificador do estande.
   * @param {number} idConteudo - Identificador do conteúdo.
   * @returns {external:Promise}
   */
  deleteConteudoProgramado(idEstande, idConteudo) {
    return this.http.delete(
      `${this.baseUrl}/${idEstande}/conteudos-programados/${idConteudo}`
    );
  },

  /**
   * Envia uma requisição para associar um apresentador a um conteúdo
   * programado do estande, retornando uma Promise.
   * @param {number} idEstande - Identificador do estande.
   * @param {number} idConteudo - Identificador do conteúdo.
   * @param {number} idApresentador - Identificador do apresentador.
   * @returns {external:Promise}
   */
  addApresentadorToConteudoProgramado(idEstande, idConteudo, idApresentador) {
    return this.http.put(
      `${this.baseUrl}/${idEstande}/conteudos-programados/${idConteudo}/apresentadores/${idApresentador}`
    );
  },

  /**
   * Envia uma requisição para desassociar um apresentador de um conteúdo
   * programado do estande, retornando uma Promise.
   * @param {number} idEstande - Identificador do estande.
   * @param {number} idConteudo - Identificador do conteúdo.
   * @param {number} idApresentador - Identificador do apresentador.
   * @returns {external:Promise}
   */
  removeApresentadorFromConteudoProgramado(
    idEstande,
    idConteudo,
    idApresentador
  ) {
    return this.http.delete(
      `${this.baseUrl}/${idEstande}/conteudos-programados/${idConteudo}/apresentadores/${idApresentador}`
    );
  },

  /**
   * Envia uma requisição para obter os conteúdos extras de um estande,
   * retornando uma Promise.
   * @param {number} id - Identificador do estande.
   * @returns {external:Promise}
   */
  getConteudosExtras(id) {
    return this.http.get(`${this.baseUrl}/${id}/conteudos-extras`);
  },

  /**
   * Envia uma requisição para obter um conteúdo extra do estande,
   * retornando uma Promise.
   * @param {number} idEstande - Identificador do estande.
   * @param {number} idConteudo - Identificador do conteúdo.
   * @returns {external:Promise}
   */
  getConteudoExtra(idEstande, idConteudo) {
    return this.http.get(
      `${this.baseUrl}/${idEstande}/conteudos-extras/${idConteudo}`
    );
  },

  /**
   * Envia uma requisição para obter os apresentadores de um conteúdo
   * extra do estande, retornando uma Promise.
   * @param {number} idEstande - Identificador do estande.
   * @param {number} idConteudo - Identificador do conteúdo.
   * @returns {external:Promise}
   */
  getApresentadoresFromConteudoExtra(idEstande, idConteudo) {
    return this.http.get(
      `${this.baseUrl}/${idEstande}/conteudos-extras/${idConteudo}/apresentadores`
    );
  },

  /**
   * Envia uma requisição para criar um conteúdo extra no estande,
   * retornando uma Promise.
   * @param {number} id - Identificador do estande.
   * @param {object} conteudo - Dados do conteúdo.
   * @returns {external:Promise}
   */
  createConteudoExtra(id, conteudo) {
    return this.http.post(`${this.baseUrl}/${id}/conteudos-extras`, conteudo);
  },

  /**
   * Envia uma requisição para atualizar um conteúdo extra do estande,
   * retornando uma Promise.
   * @param {number} idEstande - Identificador do estande.
   * @param {number} idConteudo - Identificador do conteúdo.
   * @param {object} conteudo - Dados do conteúdo.
   * @returns {external:Promise}
   */
  updateConteudoExtra(idEstande, idConteudo, conteudo) {
    return this.http.put(
      `${this.baseUrl}/${idEstande}/conteudos-extras/${idConteudo}`,
      conteudo
    );
  },

  /**
   * Envia uma requisição para excluir um conteúdo extra do estande,
   * retornando uma Promise.
   * @param {number} idEstande - Identificador do estande.
   * @param {number} idConteudo - Identificador do conteúdo.
   * @returns {external:Promise}
   */
  deleteConteudoExtra(idEstande, idConteudo) {
    return this.http.delete(
      `${this.baseUrl}/${idEstande}/conteudos-extras/${idConteudo}`
    );
  },

  /**
   * Envia uma requisição para associar um apresentador a um conteúdo
   * extra do estande, retornando uma Promise.
   * @param {number} idEstande - Identificador do estande.
   * @param {number} idConteudo - Identificador do conteúdo.
   * @param {number} idApresentador - Identificador do apresentador.
   * @returns {external:Promise}
   */
  addApresentadorToConteudoExtra(idEstande, idConteudo, idApresentador) {
    return this.http.put(
      `${this.baseUrl}/${idEstande}/conteudos-extras/${idConteudo}/apresentadores/${idApresentador}`
    );
  },

  /**
   * Envia uma requisição para desassociar um apresentador de um conteúdo extra
   * do estande, retornando uma Promise.
   * @param {number} idEstande - Identificador do estande.
   * @param {number} idConteudo - Identificador do conteúdo.
   * @param {number} idApresentador - Identificador do apresentador.
   * @returns {external:Promise}
   */
  removeApresentadorFromConteudoExtra(idEstande, idConteudo, idApresentador) {
    return this.http.delete(
      `${this.baseUrl}/${idEstande}/conteudos-extras/${idConteudo}/apresentadores/${idApresentador}`
    );
  },

  /**
   * Envia uma requisição para obter os arquivos de um conteúdo
   * extra do estande, retornando uma Promise.
   * @param {number} idEstande - Identificador do estande.
   * @param {number} idConteudo - Identificador do conteúdo.
   * @returns {external:Promise}
   */
  getArquivosFromConteudoExtra(idEstande, idConteudo) {
    return this.http.get(
      `${this.baseUrl}/${idEstande}/conteudos-extras/${idConteudo}/arquivos`
    );
  },

  /**
   * Envia uma requisição para obter um arquivo de um conteúdo
   * extra do estande, retornando uma Promise.
   * @param {number} idEstande - Identificador do estande.
   * @param {number} idConteudo - Identificador do conteúdo.
   * @param {number} idArquivo - Identificador do arquivo.
   * @returns {external:Promise}
   */
  getArquivoFromConteudoExtra(idEstande, idConteudo, idArquivo) {
    return this.http.get(
      `${this.baseUrl}/${idEstande}/conteudos-extras/${idConteudo}/arquivos/${idArquivo}`
    );
  },

  /**
   * Constrói a URL de obtenção de um arquivo de um conteúdo extra do estande.
   * @param {number} idEstande - Identificador do estande.
   * @param {number} idConteudo - Identificador do conteúdo.
   * @param {number} idArquivo - Identificador do arquivo.
   * @returns {string}
   */
  getArquivoConteudoURLFromConteudoExtra(idEstande, idConteudo, idArquivo) {
    return `${this.baseUrl}/${idEstande}/conteudos-extras/${idConteudo}/arquivos/${idArquivo}/conteudo`;
  },

  /**
   * Envia uma requisição para criar um arquivo para um conteúdo extra no
   * estande, retornando uma Promise.
   * @param {number} idEstande - Identificador do estande.
   * @param {number} idConteudo - Identificador do conteúdo.
   * @param {object} conteudo - Dados do conteúdo.
   * @returns {external:Promise}
   */
  createArquivoToConteudoExtra(idEstande, idConteudo, arquivo) {
    return this.http.post(
      `${this.baseUrl}/${idEstande}/conteudos-extras/${idConteudo}/arquivos`,
      arquivo
    );
  },

  /**
   * Envia uma requisição para excluir um conteúdo extra do estande,
   * retornando uma Promise.
   * @param {number} idEstande - Identificador do estande.
   * @param {number} idConteudo - Identificador do conteúdo.
   * @param {number} idArquivo - Identificador do arquivo.
   * @returns {external:Promise}
   */
  deleteArquivoFromConteudoExtra(idEstande, idConteudo, idArquivo) {
    return this.http.delete(
      `${this.baseUrl}/${idEstande}/conteudos-extras/${idConteudo}/arquivos/${idArquivo}`
    );
  },

  /**
   * Envia uma requisição para obter os editores de um estande,
   * retornando uma Promise.
   * @param {number} id - Identificador do estande.
   * @returns {external:Promise}
   */
  getEditores(id) {
    return this.http.get(`${this.baseUrl}/${id}/editores`);
  },

  /**
   * Envia uma requisição para associar editor ao estande, retornando uma
   * Promise.
   * @param {number} idEstande - Identificador do estande.
   * @param {number} idUsuario - Identificador do usuário.
   * @returns {external:Promise}
   */
  addEditor(idEstande, idUsuario) {
    return this.http.put(`${this.baseUrl}/${idEstande}/editores/${idUsuario}`);
  },

  /**
   * Envia uma requisição para remover editor do estande, retornando uma
   * Promise.
   * @param {number} idEstande - Identificador do estande.
   * @param {number} idUsuario - Identificador do usuário.
   * @returns {external:Promise}
   */
  removeEditor(idEstande, idUsuario) {
    return this.http.delete(
      `${this.baseUrl}/${idEstande}/editores/${idUsuario}`
    );
  },

  /**
   * Registra o _plug-in_ de requisições HTTP no _namespace_ do serviço.
   * @param {module:plugins/Http/Http} http - _Plug-in_ de requisições HTTP.
   * @returns {module:services/Estande/Estande~EstandeService} O próprio namespace.
   */
  registerHttp(http) {
    this.http = http;
    return this;
  }
};

export default EstandeService;
