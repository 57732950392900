<template>
  <section>
    <div v-if="banner" class="banner-wrapper hide-on-med-and-up">
      <img
        v-bind="banner"
        class="responsive-img"
        v-on:error="hideImageOnError"
      />
    </div>

    <div class="container">
      <div v-if="banner" class="row-fix banner-wrapper hide-on-small-only">
        <img
          v-bind="banner"
          class="responsive-img"
          v-on:error="hideImageOnError"
        />
      </div>

      <header>
        <h1 class="pae-title">{{ value.nome }}</h1>
      </header>

      <p v-if="dateInterval" class="flow-text pae-title">
        <!-- <i class="material-icons left">event</i> -->
        {{ dateInterval }}
      </p>

      <div v-if="hasFlags" class="badges-container row-fix row">
        <div class="col s12">
          <BaseBadge
            v-for="(item, index) in flags"
            v-bind:key="index"
            v-bind="item"
          />
        </div>
      </div>

      <div v-if="hasAssuntos" class="assuntos-container row-fix row">
        <div class="col s12">
          <BaseChip
            v-for="(item, index) in assuntos"
            v-bind:key="index"
            v-bind:id="`assunto-${item.id}`"
            v-bind:content="item.nome"
            v-bind:is-closeable="false"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { DEFAULT_COLOR, DEFAULT_LUMINOSITY } from "../../../constants";
import { isEmpty } from "../../../utils/lang";
import { getIntervalString } from "../../../utils/datetime";
import { TipoModeloService } from "../../../services";

export default {
  name: "SectionPrincipal",
  inheritAttrs: false,
  props: {
    tipoModeloId: {
      type: Number,
      required: true
    },
    value: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      loadingBanner: false,
      loadingAssuntos: false,
      progressBar: {
        useContainer: false
      }
    };
  },
  computed: {
    hasFlags() {
      return !isEmpty(this.flags);
    },
    hasAssuntos() {
      return !isEmpty(this.value.assuntos);
    },
    dateInterval() {
      return (
        this.value && getIntervalString(this.value.inicio, this.value.termino)
      );
    },
    assuntos() {
      return this.value.assuntos || [];
    },
    banner() {
      if (isEmpty(this.value.banner)) {
        return null;
      }

      const banner = {};

      banner.src = this.value.banner;
      banner.alt = this.value.nome;
      banner.title = this.value.nome;

      return banner;
    },
    flags() {
      const badges = [];

      if (!isEmpty(this.value)) {
        if (
          this.tipoModeloId === TipoModeloService.EVENTO ||
          this.tipoModeloId === TipoModeloService.ESTANDE
        ) {
          if (this.value.continuo) {
            badges.push({
              color: DEFAULT_COLOR,
              luminosity: DEFAULT_LUMINOSITY,
              content: "Contínuo",
              caption: ""
            });
          }

          if (this.value.online) {
            badges.push({
              color: DEFAULT_COLOR,
              luminosity: DEFAULT_LUMINOSITY,
              content: "Online",
              caption: ""
            });
          }
        }
      }

      return badges;
    }
  },
  methods: {
    hideImageOnError(event) {
      event.target.parentNode.style.display = "None";
    }
  }
};
</script>

<style scoped>
/* p.flow-text i.material-icons {
  line-height: 110%;
  font-size: inherit;
} */

.badges-container .badge {
  float: left;
  margin: 0 14px 0 0;
}

.banner-wrapper {
  display: flex;
}
.banner-wrapper img.responsive-img {
  width: 100%;
}

section .container {
  background-color: white;
  padding: 0 0.75rem;
}

/* Ajuste para aplicar fundo branco (troca de margin por padding) */
h1.pae-title {
  margin: 0;
  padding: 2.8rem 0 1.68rem 0;
}

/* Ajuste para aplicar fundo branco (troca de margin por padding) */
section .container .row:last-of-type {
  margin-bottom: 0;
  padding-bottom: 20px;
}

/* Ajuste para aumentar a seletividade das regras de alto contraste */
.contrast .badge {
  color: black !important;
  background-color: white !important;
}
</style>
