<template>
  <footer class="page-footer" v-bind:class="[color, luminosity]">
    <div class="center-align logo-wrapper">
      <img
        class="responsive-img"
        src="../assets/images/logos/devtic.png"
        v-bind:title="appOwnerShort"
        v-bind:alt="appOwnerShort"
      />
    </div>
    <div class="footer-copyright">
      <div class="container center-align">
        Copyright © 2021-{{ year }} - {{ appOwner }} | {{ appName }}&nbsp;
        <span v-bind:title="appVersionAndBuild">{{ appVersion }}</span>
      </div>
    </div>
  </footer>
</template>

<script>
import { APP_INFO, DEFAULT_COLOR, DEFAULT_LUMINOSITY } from "../constants";

export default {
  name: "TheFooter",
  inheritAttrs: false,
  data() {
    return {
      color: DEFAULT_COLOR,
      luminosity: DEFAULT_LUMINOSITY,
      appName: APP_INFO.name,
      appOwner: `${APP_INFO.owner.fullName} - ${APP_INFO.owner.shortName}`,
      appOwnerShort: APP_INFO.owner.shortName,
      appVersion: `V.: ${APP_INFO.version}`,
      appVersionAndBuild: `V.: ${APP_INFO.version} - ${APP_INFO.buildDateTime}`,
      year: new Date().getFullYear()
    };
  }
};
</script>

<style scoped>
.logo-wrapper img {
  max-height: 84px;
  margin: 20px 0px;
}
</style>

<style>
/* Ajuste para aumentar a seletividade das regras de alto contraste */
.contrast #pae-wrapper footer.page-footer {
  background: black !important;
  color: white !important;
  border-top: 1px solid white;
}
</style>
