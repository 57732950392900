<template>
  <span
    class="badge"
    v-bind:class="[color, luminosity, { new: isNew }]"
    v-bind:data-badge-caption="caption"
    v-bind:title="$attrs.title"
  >
    <slot>{{ content }}</slot>
  </span>
</template>

<script>
/**
 * @module components/base/BaseBadge/BaseBadge
 * @category Componentes-base
 * @summary _Single File Component_ (SFC) de _badge_ do {@link external:Materialize Materialize}.
 *
 * @description
 * O _slot_ _default_ recebe o conteúdo/_label_ do _badge_.
 *
 * Atributos HTML de acessibilidade, e.g., `title`, podem ser usados
 * diretamente no componente.
 *
 * @vue-prop {string} [color] - Classe CSS do {@link external:Materialize Materialize} que
 * indica a cor de fundo do _badge_.
 * @vue-prop {string} [luminosity] - Classe do {@link external:Materialize Materialize}
 * para a luminosidade/nível de cor de fundo padrão do componente.
 * @vue-prop {boolean} [isNew=true] - Indica se o componente utilizará a
 * classe CSS "new" do {@link external:Materialize Materialize}, exibindo a
 * cor de fundo.
 * @vue-prop {string} [content] - Conteúdo a ser exibido no _badge_. É usado
 * como substituto quando nenhum dado é passado através do _slot_ _default_.
 * @vue-prop {string} [caption] - Legenda a ser exibida dentro do _badge_.
 *
 * @example
 * <BaseBadge color="red" caption="item(ns)" />4</BaseBadge>
 */
export default {
  name: "BaseBadge",
  inheritAttrs: false,
  props: {
    color: String,
    luminosity: String,

    isNew: {
      type: Boolean,
      default: true
    },

    content: String,
    caption: String
  }
};
</script>

<style scoped>
.contrast span.badge {
  background-color: none !important;
  color: white !important;
}
.contrast span.badge.new {
  background-color: white !important;
  color: black !important;
}
</style>
