<template>
  <div class="alert" v-bind:class="classes">
    <p class="left-align">
      <slot>{{ content }}</slot>
    </p>
  </div>
</template>

<script>
/**
 * @module components/base/BaseAlert/BaseAlert
 * @category Componentes-base
 * @summary _Single File Component_ (SFC) de _alert_ do {@link external:Materialize Materialize}.
 *
 * @description
 * O _slot_ _default_ recebe o conteúdo do _alert_.
 *
 * @vue-prop {string} [severity] - Nível de severidade do _alert_. Aceita
 * "info", "success", "warning" e "error".
 * @vue-prop {number} [zDepth=0] - Indica o nível de sombra que o componente
 * terá aplicando a classe CSS "z-depth-#" do {@link external:Materialize Materialize},
 * onde "#" é o valor desta propriedade.
 * @vue-prop {string} [content] - Conteúdo a ser exibido no _alert_. É usado
 * como substituto quando nenhum dado é passado através do _slot_ _default_.
 *
 * @example
 * <BaseAlert level="error" />Houve um erro!</BaseAlert>
 */

const INFO = "info";
const SUCCESS = "success";
const WARNING = "warning";
const ERROR = "error";

export default {
  name: "BaseAlert",
  inheritAttrs: false,
  props: {
    severity: {
      type: String,
      default: INFO,
      validator: value => [INFO, SUCCESS, WARNING, ERROR].indexOf(value) !== -1
    },
    zDepth: {
      type: Number,
      default: 0,
      validator: value => value >= 0 && value <= 5
    },

    content: String
  },
  computed: {
    classes() {
      let classes = [];

      switch (this.severity) {
        case INFO:
          classes = ["blue", "lighten-3", "blue-text", "text-darken-4"];
          break;
        case SUCCESS:
          classes = ["green", "lighten-3", "green-text", "text-darken-4"];
          break;
        case WARNING:
          classes = ["yellow", "lighten-3", "yellow-text", "text-darken-4"];
          break;
        case ERROR:
          classes = ["red", "lighten-3", "red-text", "text-darken-4"];
          break;
        default:
          break;
      }

      classes.push(`z-depth-${this.zDepth}`);

      return classes;
    }
  }
};
</script>

<style scoped>
/* Baseado nas regras CSS do toast do Materialize */
.alert {
  border-radius: 2px;
  width: auto;
  max-width: 100%;
  height: auto;
  min-height: 48px;
  line-height: 1.5em;
  padding: 10px 25px;
  font-size: 1.1rem;
  font-weight: 300;
}
.alert p {
  margin: 0;
}

.contrast .alert {
  background-color: black !important;
  color: white !important;
  border: 1px solid white !important;
}
</style>
