/**
 * @module utils/mask
 * @category Utilidades
 * @summary Módulo de utilidades para criação de máscaras em elementos de
 * _input_.
 *
 * @description
 * Expõe funções de utilidades para criação de máscaras em elementos de _input_.
 */
import Mask from "./Mask.js";

/**
 * Instancia um objeto de máscara a partir da expressão informada.
 * @function
 * @param {string} expression - Expressão a ser usada pelo objeto de máscara.
 * @returns {Mask}
 */
export const create = function(expression) {
  return Object.freeze(new Mask(expression));
};

export default create;
