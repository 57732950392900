<template>
  <section>
    <BaseInfoCard title="Seção principal">
      <p v-if="isCreate">
        Para cadastro deste {{ name }}, inicialmente, preencha o campo "nome".
        As datas de início e de término são opcionais. Você também pode ligar ou
        desligar os interruptores correspondentes às características deste
        {{ name }}. Por fim, clique no botão "Salvar" para persistir os dados
        preenchidos.
      </p>
      <p v-if="!isCreate">
        Para edição deste {{ name }}, inicialmente, caso ele não esteja "Em
        edição", clique no botão "Editar". O status do {{ name }} será alterado
        de acordo. Em seguida, você pode-se alterar o campo "nome", as datas de
        início e de término e ligar ou desligar os interruptores correspondentes
        às características deste {{ name }}. Além disso, pode-se definir um
        banner, clicando no botão "Imagem" e escolhendo o arquivo desejado. É
        recomendado selecionar os assuntos a fim de facilitar futuras buscas no
        sistema.
        {{
          isEvento
            ? 'Utilize a seção "Apoiadores" para definir os apoiadores do evento, caso haja.'
            : ""
        }}
        Por fim, clique no botão "Salvar" para persistir os dados preenchidos.
      </p>
      <p v-if="!isCreate">
        Quando os dados do {{ name }} estiverem preenchidos de acordo com o
        desejado e sua edição finalizada, clique no botão "Publicar" para que o
        {{ name }} seja publicado e exibido nas buscas.
      </p>
    </BaseInfoCard>

    <div v-if="!isCreate" class="status-wrapper valign-wrapper">
      <div class="status-alert">
        <BaseAlert v-bind="statusAlert" />
      </div>
      <div v-if="!isCreate && mayChangeStatus" class="buttons-row">
        <BaseButton
          v-if="!isEmEdicao"
          v-bind="form.buttonEditar"
          v-on:click="editar"
        />
        <BaseButton
          v-if="!isPublicado"
          v-bind="form.buttonPublicar"
          v-on:click="publicar"
        />
      </div>
    </div>

    <form class="row-fix row">
      <div v-if="!isCreate" class="input-field banner-wrapper valign-wrapper">
        <div class="image-container col valign-wrapper">
          <img class="responsive-img" v-bind="thumbnailEvento" />
        </div>

        <BaseFileInput
          class="input-container"
          v-bind="form.banner"
          v-bind:disabled="readonly"
          v-on:input="onFileInputEvento"
        />
      </div>

      <BaseTextInput
        v-bind="form.nome"
        v-bind:disabled="readonly"
        v-on:input="form.nome.value = $event"
      />

      <BaseDatePicker
        v-if="!form.flags.continuo.value"
        v-bind="form.inicio"
        v-bind:value="formattedInicio"
        v-bind:disabled="readonly"
        v-on:input="form.inicio.value = $event"
      />
      <BaseDatePicker
        v-if="!form.flags.continuo.value"
        v-bind="form.termino"
        v-bind:value="formattedTermino"
        v-bind:disabled="readonly"
        v-on:input="form.termino.value = $event"
      />

      <div class="col s12">
        <div
          v-for="(item, index) in Object.values(form.flags)"
          v-bind:key="index"
          class="switch-container left"
        >
          <label v-bind:for="item.id" class="left">{{ item.label }}</label>
          <BaseSwitch
            class="right"
            v-bind="item"
            v-bind:disabled="readonly"
            v-on:input="item.value = $event"
          />
        </div>
      </div>

      <div v-if="!isCreate" class="col s12">
        <BaseProgressBar v-if="loadingAssuntos" v-bind="progressBar" />
        <div v-else>
          <label v-bind:for="form.assuntos.id">{{ form.assuntos.label }}</label>
          <div v-bind:id="form.assuntos.id" class="row">
            <BaseCheckbox
              v-for="(item, index) in form.assuntos.assuntos"
              v-bind:key="index"
              v-bind:id="`assunto-${item.id}`"
              v-bind:name="`assunto-${item.id}`"
              v-bind:content="item.nome"
              v-bind:disabled="readonly"
              v-bind:v-value="item.id"
              v-bind:value="form.assuntos.value"
              v-on:input="form.assuntos.value = $event"
              class="col s12 m6 l4"
              tabindex="0"
            />
          </div>
        </div>
      </div>

      <section v-if="isEvento && !isCreate" class="section col s12">
        <h4 class="pae-title">Estilo</h4>

        <BaseInfoCard title="Estilo do evento">
          <p>
            Nesta seção, você pode definir a estilização do evento, de forma
            que, quando o usuário acessar sua página, ela terá seu estilo
            determinado pelos dados preenchidos abaixo. Para ativar a
            estilização do evento, ative o interruptor abaixo. Por enquanto,
            apenas a cor do evento pode ser definida. Ela será aplicada nos
            cabeçalho e rodapé da página, nos títulos e em outros componentes
            exibidos na página do evento.
          </p>
          <p>
            <strong>Obs.:</strong> a estilização definida para o evento também é
            aplicada nas páginas de seus estandes.
          </p>
        </BaseInfoCard>

        <div class="row">
          <div class="col s12">
            <div class="switch-container left">
              <label v-bind:for="form.estilo.ativo.id" class="left">{{
                form.estilo.ativo.label
              }}</label>
              <BaseSwitch
                class="right"
                v-bind="form.estilo.ativo"
                v-bind:disabled="readonly"
                v-on:input="form.estilo.ativo.value = $event"
              />
            </div>
          </div>

          <BaseColorPicker
            v-bind="form.estilo.cor"
            v-bind:disabled="readonly || !form.estilo.ativo.value"
            v-on:change="form.estilo.cor.value = $event"
          />
        </div>
      </section>

      <section v-if="isEvento && !isCreate" class="section col s12">
        <h4 class="pae-title">Apoiadores</h4>

        <BaseInfoCard title="Apoiadores do evento">
          <p>
            Nesta seção, você pode adicionar o banner com os apoiadores do
            evento. Por questões de acessibilidade, recomenda-se preencher o
            campo "descrição" com os nomes das organizações/instituições que
            estão apoiando o evento. Para remover o banner, basta limpar o campo
            de upload de arquivo. Com isso, o campo "descrição" também será
            apagado.
          </p>
        </BaseInfoCard>

        <div class="input-field banner-wrapper valign-wrapper row-fix">
          <div class="image-container col valign-wrapper">
            <img class="responsive-img" v-bind="thumbnailApoiadores" />
          </div>

          <BaseFileInput
            class="input-container"
            v-bind="form.apoiadores.banner"
            v-bind:disabled="readonly"
            v-on:input="onFileInputApoiadores"
          />
        </div>

        <div class="row">
          <BaseTextarea
            v-if="!isCreate"
            v-bind="form.apoiadores.descricao"
            v-bind:disabled="readonly"
            v-model="form.apoiadores.descricao.value"
          />
        </div>
      </section>

      <div class="col s12 buttons-row main-buttons">
        <BaseButton
          v-bind="form.buttonSave"
          v-bind:disabled="readonly"
          v-on:click="save"
        />
      </div>
    </form>
  </section>
</template>

<script>
import { NOPIC_IMG_SRC } from "../../../constants";
import {
  AssuntoService,
  PermissaoService,
  StatusService,
  TipoModeloService
} from "../../../services";

export default {
  name: "EditableSectionPrincipal",
  inheritAttrs: false,
  props: {
    tipoModeloId: {
      type: Number,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },

    value: Object
  },
  data() {
    return {
      loadingAssuntos: false,
      name: null,
      statusMap: null,
      progressBar: {
        useContainer: false
      },
      form: {
        banner: {
          id: "editablesection-banner",
          name: "editablesection-banner",
          placeholder: "Selecione uma imagem para o banner",
          multiple: false,
          validate: true,
          accept: "image/*",
          size: 1024 * 1024,
          buttonContent: "Imagem",
          gridExpression: "col",
          value: null
        },
        nome: {
          id: "editablesection-nome",
          name: "editablesection-nome",
          required: true,
          label: "Nome",
          value: null
        },
        inicio: {
          id: "editablesection-inicio",
          name: "editablesection-inicio",
          label: "Data início",
          gridExpression: "col s12 m6",
          value: null
        },
        termino: {
          id: "editablesection-termino",
          name: "editablesection-termino",
          label: "Data término",
          gridExpression: "col s12 m6",
          value: null
        },
        flags: {
          continuo: {
            id: `editablesection-continuo`,
            name: `editablesection-continuo`,
            label: "Contínuo",
            value: null
          },
          online: {
            id: `editablesection-online`,
            name: `editablesection-online`,
            label: "Online",
            value: null
          }
        },
        assuntos: {
          id: "editablesection-assuntos",
          label: "Assuntos",
          assuntos: null,
          value: []
        },
        estilo: {
          ativo: {
            id: `editablesection-estilo-ativo`,
            name: `editablesection-estilo-ativo`,
            label: "Ativo",
            value: null
          },
          cor: {
            id: "editablesection-estilo-cor",
            name: "editablesection-estilo-cor",
            label: "Cor",
            gridExpression: "col s12 m3",
            value: null
          }
        },
        apoiadores: {
          banner: {
            id: "editablesection-apoiadores-banner",
            name: "editablesection-apoiadores-banner",
            placeholder: "Selecione uma imagem para o banner dos apoiadores",
            multiple: false,
            validate: true,
            accept: "image/*",
            size: 1024 * 1024,
            buttonContent: "Imagem",
            gridExpression: "col",
            value: null
          },
          descricao: {
            id: "editablesection-apoiadores-descricao",
            name: "editablesection-apoiadores-descricao",
            label: "Descrição",
            value: null
          }
        },
        buttonSave: {
          iconName: "save",
          content: "Salvar"
        },
        buttonEditar: {
          iconName: "edit",
          content: "Editar"
        },
        buttonPublicar: {
          iconName: "done",
          content: "Publicar"
        }
      },
      bannerTmpEvento: null,
      thumbnailEvento: {
        src: null,
        alt: null,
        title: null
      },
      bannerTmpApoiadores: null,
      thumbnailApoiadores: {
        src: null,
        alt: null,
        title: null
      }
    };
  },
  computed: {
    isEvento() {
      return this.tipoModeloId === TipoModeloService.EVENTO;
    },
    isEstande() {
      return this.tipoModeloId === TipoModeloService.ESTANDE;
    },
    isCreate() {
      return !this.value || !this.value.id;
    },
    isEmEdicao() {
      return this.value && this.value.statusId === StatusService.EM_EDICAO;
    },
    isPublicado() {
      return this.value && this.value.statusId === StatusService.PUBLICADO;
    },
    isGestorEstandes() {
      return this.$authorization.hasPermission(PermissaoService.GESTAO_ESTANDE);
    },
    isGestorEventos() {
      return this.$authorization.hasPermission(PermissaoService.GESTAO_EVENTO);
    },
    mayChangeStatus() {
      return this.tipoModeloId === TipoModeloService.EVENTO
        ? this.isGestorEventos
        : this.isGestorEventos || this.isGestorEstandes;
    },
    formattedInicio() {
      return this.formatComputedDateProp(
        this.$utils.DateTime.toBrazilianDate,
        this.form.inicio.value
      );
    },
    formattedTermino() {
      return this.formatComputedDateProp(
        this.$utils.DateTime.toBrazilianDate,
        this.form.termino.value
      );
    },
    statusAlert() {
      const props = {
        severity: "info",
        content: "Status: "
      };

      if (!this.value.statusId || !this.statusMap) {
        props.content += "N/A";
      } else {
        const status = this.statusMap[this.value.statusId];

        if (!status) {
          props.content += "N/A";
        } else {
          props.content += status.nome;
        }

        switch (this.value.statusId) {
          case StatusService.EM_EDICAO:
            props.severity = "warning";
            break;
          case StatusService.PUBLICADO:
            props.severity = "success";
            break;
          default:
            props.severity = "info";
            break;
        }
      }

      return props;
    }
  },
  watch: {
    value: {
      deep: true,
      immediate: true,

      handler(newValue) {
        const {
          nome,
          inicio,
          termino,
          flags,
          assuntos,
          banner,
          apoiadores,
          estilo
        } = this.form;

        const newApoiadores = newValue ? newValue.apoiadores : null;
        const newEstilo = newValue ? newValue.estilo : null;

        if (newValue) {
          nome.value = newValue.nome;
          inicio.value = newValue.inicio;
          termino.value = newValue.termino;
          flags.continuo.value = newValue.continuo;
          flags.online.value = newValue.online;
          assuntos.value = (newValue.assuntos || []).map(item => item);
          banner.value = newValue.banner;
        } else {
          nome.value = null;
          inicio.value = null;
          termino.value = null;
          flags.continuo.value = null;
          flags.online.value = null;
          assuntos.value = [];
          banner.value = null;
        }

        if (newApoiadores) {
          apoiadores.descricao.value = newApoiadores.descricao;
          apoiadores.banner.value = newApoiadores.banner;
        } else {
          apoiadores.descricao.value = null;
          apoiadores.banner.value = null;
        }

        if (newEstilo) {
          estilo.ativo.value = newEstilo.ativo;
          estilo.cor.value = newEstilo.cor;
        } else {
          estilo.ativo.value = null;
          estilo.cor.value = null;
        }
      }
    },
    "form.flags.continuo.value": {
      immediate: true,

      handler(newValue) {
        if (newValue) {
          this.form.inicio.value = null;
          this.form.termino.value = null;
        }
      }
    },
    "form.banner.value": {
      immediate: true,

      handler(newValue) {
        const { nome } = this.form;

        if (newValue) {
          this.thumbnailEvento.src = newValue.conteudo;
          this.thumbnailEvento.alt = nome.value;
          this.thumbnailEvento.title = nome.value;
          this.bannerTmpEvento = newValue;
        } else {
          this.thumbnailEvento.src = NOPIC_IMG_SRC;
          this.thumbnailEvento.alt = "Sem foto";
          this.thumbnailEvento.title = "Sem foto";
          this.bannerTmpEvento = null;
        }
      }
    },
    "form.apoiadores.banner.value": {
      immediate: true,

      handler(newValue) {
        const { descricao } = this.form.apoiadores;

        if (newValue) {
          this.thumbnailApoiadores.src = newValue.conteudo;
          this.thumbnailApoiadores.alt = descricao.value;
          this.thumbnailApoiadores.title = descricao.value;
          this.bannerTmpApoiadores = newValue;
        } else {
          this.thumbnailApoiadores.src = NOPIC_IMG_SRC;
          this.thumbnailApoiadores.alt = "Sem foto";
          this.thumbnailApoiadores.title = "Sem foto";
          this.bannerTmpApoiadores = null;
        }
      }
    }
  },
  created() {
    if (this.isEvento) {
      this.name = "Evento";
    } else if (this.isEstande) {
      this.name = "Estande";
    } else {
      this.$notification.pushError("Tipo de modelo de página inválido");
      return;
    }

    this.getStatus();

    if (!this.isCreate) {
      this.getAssuntos();
    }
  },
  methods: {
    formatComputedDateProp(formatter, value) {
      let newValue = null;

      try {
        newValue = formatter(value);
      } catch (e) {
        // TODO: verificar a necessidade de tratamento
        window.console.error(e.message);
      }

      return newValue;
    },
    getStatus() {
      StatusService.getAll()
        .then(response => {
          this.statusMap = {};

          (response.data || []).forEach(
            item => (this.statusMap[item.id] = item)
          );
        })
        .catch(error =>
          this.$notification.pushError(
            `Houve um erro ao obter os status: ${error.message}`
          )
        )
        .finally(() => (this.loadingAssuntos = false));
    },
    getAssuntos() {
      this.loadingAssuntos = true;

      AssuntoService.getAll()
        .then(response => (this.form.assuntos.assuntos = response.data || []))
        .catch(error =>
          this.$notification.pushError(
            `Houve um erro ao obter os assuntos: ${error.message}`
          )
        )
        .finally(() => (this.loadingAssuntos = false));
    },
    editar() {
      if (!this.isCreate) {
        this.$emit("statuschange", StatusService.EM_EDICAO);
      }
    },
    publicar() {
      if (!this.isCreate) {
        this.$emit("statuschange", StatusService.PUBLICADO);
      }
    },
    save() {
      const { nome, inicio, termino, flags, assuntos, apoiadores, estilo } =
        this.form;

      const data = {
        nome: nome.value,
        inicio: null,
        termino: null,
        assuntos: assuntos.value,
        banner: this.bannerTmpEvento,
        apoiadores: this.bannerTmpApoiadores
          ? {
              descricao: apoiadores.descricao.value,
              banner: this.bannerTmpApoiadores
            }
          : null
      };

      if (this.isEvento && !this.isCreate) {
        data.estilo = {
          ativo: estilo.ativo.value,
          cor: estilo.cor.value
        };
      }

      Object.entries(flags).forEach(item => (data[item[0]] = item[1].value));

      if (!data.continuo) {
        data.inicio = this.formatComputedDateProp(
          this.$utils.DateTime.toISODate,
          inicio.value
        );
        data.termino = this.formatComputedDateProp(
          this.$utils.DateTime.toISODate,
          termino.value
        );
      }

      this.$emit("save", data);
    },
    onFileInputEvento(event) {
      if (event) {
        const { nome } = this.form;
        const file = event[0];
        const fileReader = new FileReader();

        fileReader.onerror = () => {
          this.$notification.pushError(
            `Houve um erro ao carregar imagem: ${fileReader.error}`
          );
        };

        fileReader.onload = event => {
          this.thumbnailEvento.src = event.target.result;
          this.thumbnailEvento.alt = nome.value;
          this.thumbnailEvento.title = nome.value;
          this.bannerTmpEvento = {
            nome: file.name,
            tamanho: file.size,
            conteudo: event.target.result,
            tipo: file.type
          };
        };

        fileReader.readAsDataURL(file);
      } else {
        this.thumbnailEvento.src = NOPIC_IMG_SRC;
        this.thumbnailEvento.alt = "Sem foto";
        this.thumbnailEvento.title = "Sem foto";
        this.bannerTmpEvento = null;
      }
    },
    onFileInputApoiadores(event) {
      const { descricao } = this.form.apoiadores;

      if (event) {
        const file = event[0];
        const fileReader = new FileReader();

        fileReader.onerror = () => {
          this.$notification.pushError(
            `Houve um erro ao carregar imagem: ${fileReader.error}`
          );
        };

        fileReader.onload = event => {
          this.thumbnailApoiadores.src = event.target.result;
          this.thumbnailApoiadores.alt = descricao.value;
          this.thumbnailApoiadores.title = descricao.value;
          this.bannerTmpApoiadores = {
            nome: file.name,
            tamanho: file.size,
            conteudo: event.target.result,
            tipo: file.type
          };
        };

        fileReader.readAsDataURL(file);
      } else {
        this.thumbnailApoiadores.src = NOPIC_IMG_SRC;
        this.thumbnailApoiadores.alt = "Sem foto";
        this.thumbnailApoiadores.title = "Sem foto";
        this.bannerTmpApoiadores = null;

        // Limpa-se o campo descrição também
        descricao.value = null;
      }
    }
  }
};
</script>

<style scoped>
.buttons-row.main-buttons {
  justify-content: right;
}

.banner-wrapper .image-container {
  flex: 0 1 auto;
  height: 175px;
}
.banner-wrapper .image-container img.responsive-img {
  max-height: 100%;
}
.banner-wrapper .input-container {
  flex: 1 1 auto;
}

@media screen and (max-width: 600px) {
  .banner-wrapper {
    flex-direction: column;
  }
  .banner-wrapper .input-container {
    min-width: 100%;
  }
}

.status-wrapper {
  flex-wrap: wrap;
}
.status-wrapper .status-alert {
  flex: 1 0 auto;
}
.status-wrapper .buttons-row .btn {
  margin-left: 20px;
}

@media screen and (max-width: 600px) {
  .status-wrapper .buttons-row {
    min-width: 100%;
  }
  .status-wrapper .buttons-row .btn {
    margin-left: 0;
  }
  .status-wrapper .status-alert {
    margin-bottom: 20px;
  }

  .buttons-row .btn {
    display: block;
    width: 100%;
  }
}

.switch-container {
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
}
</style>
