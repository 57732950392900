/**
 * @module services/TipoModelo/TipoModelo
 * @category Serviços
 * @summary Módulo do serviço de tipos de modelo.
 *
 * @description
 * Expõe o objeto/_namespace_ do serviço de tipos de modelo do sistema para
 * utilização interna.
 *
 * Para correto funcionamento, é necessário que o _plug-in_ de requisições HTTP
 * seja registrado a este serviço.
 *
 * @requires module:constants.API_URL
 *
 * @example
 * import TipoModeloService from "./services/TipoModelo/TipoModelo";
 *
 * // Registrando o _plug-in_ de requisiçõe HTTP do container.
 * TipoModeloService.registerHttp(Http);
 *
 * // Obtendo a lista de tipos de modelo
 * TipoModeloService.getAll()
 *  .then(response => {
 *    // ...
 *  })
 *  .catch(error => {
 *    // ...
 *  })
 */
import { API_URL } from "../../constants";

/**
 * @namespace TipoModeloService
 * @category Serviços
 * @summary Objeto/_namespace_ do serviço de tipos de modelo.
 */
const TipoModeloService = {
  /**
   * URL de acesso à API para obtenção de tipos de modelo.
   * @type {string}
   * @private
   * @readonly
   */
  baseUrl: `${API_URL}/tipos-modelo`,

  /**
   * _Plug-in_ de requisições HTTP.
   * @type {module:plugins/Http/Http}
   * @private
   * @readonly
   */
  http: null,

  /**
   * Tipo de modelo/template para eventos.
   * @type {number}
   * @readonly
   */
  EVENTO: 1,

  /**
   * Tipo de modelo/template para estandes.
   * @type {number}
   * @readonly
   */
  ESTANDE: 2,

  /**
   * Envia uma requisição para obter todos os tipos de modelo, retornando uma Promise.
   * @returns {external:Promise}
   */
  getAll() {
    return this.http.get(`${this.baseUrl}/`);
  },

  /**
   * Envia uma requisição para obter um tipo de modelo, retornando uma Promise.
   * @param {number} id - Identificador do tipo de modelo.
   * @returns {external:Promise}
   */
  get(id) {
    return this.http.get(`${this.baseUrl}/${id}`);
  },

  /**
   * Registra o _plug-in_ de requisições HTTP no _namespace_ do serviço.
   * @param {module:plugins/Http/Http} http - _Plug-in_ de requisições HTTP.
   * @returns {module:services/TipoModelo/TipoModelo~TipoModeloService} O próprio namespace.
   */
  registerHttp(http) {
    this.http = http;
    return this;
  }
};

export default TipoModeloService;
