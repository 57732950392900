/**
 * @module services/TipoSecao
 * @category Serviços
 * @summary Módulo de importação do serviço de modelo/template.
 *
 * @description
 * Expõe o serviço de modelo/template do sistema para utilização interna.
 *
 * @example <caption>Importando o serviço de modelo/template como membro default do módulo</caption>
 * import TipoSecaoService from "./services/TipoSecao";
 *
 * @example <caption>Importando o serviço de modelo/template como membro do módulo</caption>
 * import { TipoSecaoService } from "./services/TipoSecao";
 */
import { default as TipoSecaoService } from "./TipoSecao";

export default TipoSecaoService;

export { TipoSecaoService };
