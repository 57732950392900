/**
 * @module services/Conteudo/Conteudo
 * @category Serviços
 * @summary Módulo do serviço de conteúdos.
 *
 * @description
 * Expõe o objeto/_namespace_ do serviço de conteúdos do sistema para
 * utilização interna.
 *
 * Para correto funcionamento, é necessário que o _plug-in_ de requisições HTTP
 * seja registrado a este serviço.
 *
 * @requires module:constants.API_URL
 * @requires module:utils/lang.isEmpty
 * @requires module:utils/string.isUrl
 * @requires module:utils/datetime.isISODateTime
 *
 * @example
 * import ConteudoService from "./services/Conteudo/Conteudo";
 *
 * // Registrando o _plug-in_ de requisiçõe HTTP do container.
 * ConteudoService.registerHttp(Http);
 *
 * const conteudo = // ...
 *
 * // Validando um conteúdo
 * ConteudoService.validate(conteudo)
 *  .then(response => {
 *    // ...
 *  })
 *  .catch(error => {
 *    // ...
 *  })
 */
import { isEmpty } from "../../utils/lang";
import { isUrl } from "../../utils/string";
import { isISODateTime } from "../../utils/datetime";

const REGEXP_YOUTUBE = /^(http:\/\/|https:\/\/){0,1}(www\.){0,1}(youtube(-nocookie){0,1}\.com\/(watch\?v=|embed\/)|youtu.be\/)([^\s"?&/<>]+)/i;

/**
 * @namespace ConteudoService
 * @category Serviços
 * @summary Objeto/_namespace_ do serviço de conteúdos.
 */
const ConteudoService = {
  /**
   * Tipo de conteúdo "Programado".
   * @type {number}
   * @readonly
   */
  PROGRAMADO: 1,

  /**
   * Tipo de conteúdo "Extra".
   * @type {number}
   * @readonly
   */
  EXTRA: 2,

  /**
   * Realiza a validação de um conteúdo (programado/extra).
   * @param {object} val - Conteúdo a ser validado.
   * @returns {Array<Error>} - _Array_ de possíveis erros.
   */
  validate(val) {
    const errors = [];

    if (isEmpty(val)) {
      errors.push(new Error("Conteúdo não preenchido"));
    } else {
      if (isEmpty(val.nome)) {
        errors.push(new Error("Nome do conteúdo inválido"));
      }

      if (val.url && !isUrl(val.url)) {
        errors.push(new Error("URL do conteúdo inválida"));
      }

      // Para conteúdos programados
      try {
        if (val.inicio && !isISODateTime(val.inicio)) {
          errors.push(new Error("Data/hora início do conteúdo inválida"));
        }
      } catch (e) {
        errors.push(new Error("Data/hora início do estande inválida"));
      }

      try {
        if (val.termino && !isISODateTime(val.termino)) {
          errors.push(new Error("Data/hora término do estande inválida"));
        }
      } catch (e) {
        errors.push(new Error("Data/hora término do estande inválida"));
      }
    }

    return errors;
  },

  /**
   * Indica se uma URL genérica de um recurso _web_ é passível de conversão
   * para exibição de conteúdo embutido (_embedded_).
   * @param {string} url - URL de um recurso _web_.
   * @returns {boolean}
   */
  isEmbeddableUrl(url) {
    if (!url) {
      return false;
    }

    return REGEXP_YOUTUBE.exec(url) !== null;
  },

  /**
   * Constrói um objeto com os atributos para uso em um _iframe_ a partir de
   * uma URL genérica de um recurso _web_, por exemplo, de um vídeo do YouTube.
   * @param {string} url - URL de um recurso _web_.
   * @returns {object}
   */
  buildEmbeddedContent(url) {
    if (!url) {
      return null;
    }

    const attrs = {
      src: url,
      title: null,
      frameborder: "0",
      allow: null
    };

    let match = null;

    if ((match = REGEXP_YOUTUBE.exec(url)) !== null) {
      const index = match.length - 1;

      attrs.src = `https://www.youtube.com/embed/${match[index]}`;
      attrs.title = "YouTube video player";
      attrs.allow =
        "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture";
      attrs.allowfullscreen = "true";
    }

    return attrs;
  }
};

export default ConteudoService;
