/**
 * @module utils/string
 * @category Utilidades
 * @summary Módulo de utilidades para manipulação de _strings_.
 *
 * @description
 * Expõe funções de utilidades para manipulação de _strings_.
 *
 * @requires module:utils/lang.isEmpty
 * @requires module:utils/lang.isString
 */

import { isEmpty, isString } from "../lang";

/**
 * Remove expaços em branco extras de um texto.
 * @function
 * @param {string} value - Texto a ter espaços em branco extras removidos.
 * @param {boolean} nullified[true] - Indica se `null` deve ser retornado caso o valor
 * final esteja vazio.
 * @returns {string}
 */
export const clear = function(value, nullified = true) {
  const cleared = !isString(value) || isEmpty(value) ? "" : value.trim();

  return nullified && !cleared ? null : cleared;
};

/**
 * Indica se o texto está no formato de endereço de e-mail.
 * @function
 * @param {string} value - Texto a ser verificado.
 * @returns {boolean}
 */
export const isEmail = function(value) {
  return !!value && /^[a-z0-9-_.+]+@[a-z0-9-]+(\.[a-z0-9-]+)+$/.test(value);
};

/**
 * Indica se o texto está no formato de URL.
 * @function
 * @param {string} value - Texto a ser verificado.
 * @returns {boolean}
 */
export const isUrl = function(value) {
  return (
    !!value &&
    /^((http|https|ftp|sftp):\/\/){0,1}(ww(w|w{0,1}[0-9]+)\.){0,1}([a-z0-9-]+\.[a-z0-9-]+(.*))$/.test(
      value
    )
  );
};

export default {
  clear,
  isEmail,
  isUrl
};
