<template>
  <ul v-bind:id="id" class="dropdown-content">
    <slot>
      <li v-for="(item, index) in dropdownContentWithEvents" v-bind:key="index">
        <a
          v-bind:href="item.href || '#!'"
          v-bind:title="item.title"
          v-on:click="item.onClick"
        >
          <i
            v-if="showIcon && item.iconName"
            class="material-icons"
            v-bind:class="item.iconPosition || 'left'"
            >{{ item.iconName }}</i
          >
          {{ item.content }}
        </a>
      </li>
    </slot>
  </ul>
</template>

<script>
/**
 * @module components/base/BaseDropdown/BaseDropdown
 * @category Componentes-base
 * @summary _Single File Component_ (SFC) de _dropdown_ do {@link external:Materialize Materialize}.
 *
 * @description
 * Este componente ativa a funcionalidade de _dropdown_ do {@link external:Materialize Materialize}
 * a partir do elemento HTML cujo `id` seja o valor passado para a prop
 * `triggerElementId`. Esse elemento precisa usar o atributo `data-target` com
 * valor igual ao da prop `id` deste componente.
 *
 * A cor de texto dos itens do _dropdown_ é obtida automaticamente a partir da
 * configuração do ambiente.
 *
 * O _slot_ _default_ recebe os elementos HTML `li`, que são os itens do
 * _dropdown_.
 *
 * Para correto funcionamento, é necessário que o _framework_ {@link external:Materialize Materialize}
 * tenha sido importado, tornando seu objeto principal acessível globalmente.
 *
 * @requires module:constants.DEFAULT_COLOR
 * @requires module:constants.DEFAULT_LUMINOSITY
 * @requires module:utils/web.createStyleElement
 * @requires module:utils/web.getMaterializeColorCode
 *
 * @vue-prop {string} id - Atributo `id` do elemento HTML utilizado
 * internamente pelo componente.
 * @vue-prop {string} triggerElementId - Atributo `id` do elemento HTML
 * disparador do _dropdown_.
 * @vue-prop {boolean} [showIcon=true] - Indica se o componente deve exibir os
 * ícones dos itens do _dropdown_.
 * @vue-prop {Array<object>} [dropdownContent=[]] - _Array_ com os itens do
 * _dropdown_. É usado como substituto quando nenhum dado é passado através do
 * _slot_ _default_.
 * @vue-prop {string} [dropdownContent[].content] - Conteúdo textual do item do
 * _dropdown_.
 * @vue-prop {string} [dropdownContent[].iconName] - Nome do
 * [ícone do Material Design]{@link external:MaterialDesignIcons}.
 * @vue-prop {string} [dropdownContent[].iconPosition="left"] - Posição em que
 * o ícone do item do _dropdown_ será localizado. Aceita "left" ou "right".
 * @vue-prop {string} [dropdownContent[].href="#!"] - URL do _link_ do item do
 * _dropdown_.
 * @vue-prop {string} [dropdownContent[].title] - Atributo HTML de
 * acessibilidade.
 * @vue-prop {function} [dropdownContent[].onClick] - Função que será associada
 * ao evento de _click_ do item do _dropdown_. O item que recebeu o _click_ e
 * seu índice são passados como primeiro e segundos parâmetros para a função,
 * respectivamente.
 * @vue-prop {function} [onLoad] - Função que será chamada após a instanciação
 * do _dropdown_ do {@link external:Materialize Materialize}, passando-o como
 * primeiro parâmetro da função.
 * @vue-prop {object} [dropdownOptions] - Opções aceitas pelo _dropdown_ do {@link external:Materialize Materialize}.
 *
 * @example
 * <BaseDropdown id="dropdown-id" trigger-element-id="trigger-id">
 *  <li>
 *    <a href="#!">...</a>
 *  </li>
 *  ...
 * </BaseDropdown>
 */
import { DEFAULT_COLOR, DEFAULT_LUMINOSITY } from "../../../constants";
import {
  createStyleElement,
  getMaterializeColorCode
} from "../../../utils/web";

export default {
  name: "BaseDropdown",
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      required: true
    },
    triggerElementId: {
      type: String,
      required: true
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    dropdownContent: {
      type: Array,
      default: () => []
    },

    onLoad: Function,

    dropdownOptions: Object
  },
  data() {
    return {
      instance: null,
      timeout: null
    };
  },
  computed: {
    dropdownContentWithEvents() {
      return this.dropdownContent.map((item, index, arr) => {
        return {
          ...item,
          onClick: () => {
            if (item.onClick && typeof item.onClick === "function") {
              item.onClick(arr[index], index);
            }
          }
        };
      });
    }
  },
  beforeCreate() {
    if (!window.M) {
      window.console.error(
        "Objeto do Materialize não encontrado. Verifique se essa biblioteca foi carregada corretamente"
      );
    }
  },
  created() {
    this.overrideMaterializeStyle();
  },
  mounted() {
    if (!window.M) {
      return;
    }

    const dropdownTrigger = document.getElementById(this.triggerElementId);

    this.instance = window.M.Dropdown.init(
      dropdownTrigger,
      this.dropdownOptions
    );

    if (this.onLoad) {
      this.onLoad(this.instance);
    }
  },
  beforeDestroy() {
    if (this.instance) {
      this.instance.destroy();
    }
  },
  methods: {
    overrideMaterializeStyle() {
      const colorCode = getMaterializeColorCode(
        DEFAULT_COLOR,
        DEFAULT_LUMINOSITY
      );

      if (colorCode) {
        const CSSStyleSheet = `
        .dropdown-content li > a,
        .dropdown-content li > span {
          color: ${colorCode};
        }
      `;

        createStyleElement({
          id: "stylesheet-dropdown",
          type: "text/css",
          innerHTML: CSSStyleSheet
        });
      }
    }
  }
};
</script>

<style scoped>
.contrast .dropdown-content li:hover,
.contrast .dropdown-content li:focus {
  background-color: white !important;
  color: black !important;
}
.contrast .dropdown-content li:hover a i.material-icons,
.contrast .dropdown-content li:focus a i.material-icons {
  background-color: inherit !important;
  color: inherit !important;
}
.contrast .dropdown-content li:hover a,
.contrast .dropdown-content li:focus a,
.contrast .dropdown-content li:hover span,
.contrast .dropdown-content li:focus span {
  color: black !important;
}
</style>
