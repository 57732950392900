<template>
  <div class="help-infotic-wrapper">
    <BaseFloatingActionButton v-bind="fab"></BaseFloatingActionButton>
    <BaseModal ref="contactsModal" v-bind:id="contactsModalId">
      <template v-slot:header>
        <div
          class="
            help-infotic-modal-header
            thin
            indigo
            darken-4
            white-text
            center
          "
        >
          <h4>Contato</h4>
        </div>
      </template>
      <template v-slot:default>
        <h6>E-mails de contato</h6>
        <ul>
          <li v-for="(item, index) in contacts" v-bind:key="index">
            <b>{{ item.name }}:&nbsp;</b>
            <a v-bind:href="`mailto:${item.email}`">{{ item.email }}</a>
          </li>
        </ul>
      </template>
      <template v-slot:footer>
        <BaseButton class="modal-close" material-type="flat">Fechar</BaseButton>
      </template>
    </BaseModal>
    <BaseModal
      ref="helpModal"
      v-bind:id="helpModalId"
      v-bind:hasFixedFooter="true"
    >
      <template v-slot:header>
        <div
          class="help-infotic-modal-header thin teal darken-4 white-text center"
        >
          <h4>Ajuda</h4>
        </div>
      </template>
      <template v-slot:default>
        <iframe v-bind:src="helpUrl" width="100%" height="100%"></iframe>
      </template>
      <template v-slot:footer>
        <BaseButton class="modal-close" material-type="flat">Fechar</BaseButton>
      </template>
    </BaseModal>
  </div>
</template>

<script>
/**
 * @module components/base/BaseHelpInfotic/BaseHelpInfotic
 * @category Componentes-base
 * @summary _Single File Component_ (SFC) do botão "HelpInfoTIC".
 *
 * @description
 * A cor dos botões é obtida automaticamente a partir da configuração do ambiente.
 *
 * Este componente não possui _slot_ _default_.
 *
 * Para correto funcionamento, é necessário que o _framework_ {@link external:Materialize Materialize}
 * tenha sido importado, tornando seu objeto principal acessível globalmente.
 *
 * @requires module:components/base/BaseButton/BaseButton
 * @requires module:components/base/BaseFloatingActionButton/BaseFloatingActionButton
 * @requires module:components/base/BaseModal/BaseModal
 * @requires module:constants.DEFAULT_COLOR
 *
 * @vue-prop {string} appName - Nome do sistema (ou módulo) em que o botão
 * será exibido. Utilizado para montar os atributos HTML `title` para
 * acessibilidade.
 * @vue-prop {Array<object>} [contacts] - _Array_ de objetos com os dados dos
 * contatos. Esses objetos devem possuir as propriedades `name` e `email`.
 * Caso não seja informado, não será aberto o modal de contatos.
 * @vue-prop {string} [helpUrl] - Endereço (URL) de dúvidas frequentes do
 * {@link external:HelpInfoTIC HelpInfoTIC}. Caso não seja informado, não será exibido o botão para abertura do modal
 * de ajuda.
 * @vue-prop {string} [manualPath] - Caminho para o manual de instruções de
 * uso. Caso não seja informado, não será exibido o botão para abertura do
 * manual.
 *
 * @example
 * <BaseHelpInfotic
 *  contacts="[{ name: '...', email: '...'}]"
 *  help-url="https://help-infotic.tic.ufrj.br/duvidas-sistema/SisPessoal" />
 */
import { DEFAULT_COLOR } from "../../../constants";
import BaseButton from "../BaseButton/BaseButton.vue";
import BaseFloatingActionButton from "../BaseFloatingActionButton/BaseFloatingActionButton.vue";
import BaseModal from "../BaseModal/BaseModal.vue";

export default {
  name: "BaseHelpInfotic",
  components: {
    BaseButton,
    BaseFloatingActionButton,
    BaseModal
  },
  inheritAttrs: false,
  props: {
    appName: {
      type: String,
      required: true
    },
    contacts: {
      type: Array,
      default: () => []
    },

    helpUrl: String,
    manualPath: String
  },
  data() {
    return {
      contactsModalId: "help-infotic-modal-contatos",
      helpModalId: "help-infotic-modal-ajuda",
      fab: {
        color: DEFAULT_COLOR,
        luminosity: "darken-4",
        iconName: "info_outline",
        title: `Acessar às opções do HelpInfoTIC para o ${this.appName}`,
        actions: [],
        floatingActionButtonOptions: { hoverEnabled: false }
      }
    };
  },
  created() {
    const { actions } = this.fab;

    if (this.manualPath) {
      actions.push({
        color: DEFAULT_COLOR,
        luminosity: "accent-4",
        iconName: "description",
        onClick: this.openManual,
        title: `Abrir o manual com as instruções de uso do ${this.appName}`
      });
    }

    if (this.helpUrl) {
      actions.push({
        color: DEFAULT_COLOR,
        iconName: "help",
        onClick: this.openHelp,
        title: `Abrir o modal com as dúvidas frequentes do ${this.appName}`
      });
    }

    if (this.contacts) {
      actions.push({
        color: "grey",
        iconName: "mail",
        onClick: this.openContacts,
        title: `Abrir o modal com a lista de contatos dos responsáveis do ${this.appName}`
      });
    }
  },
  methods: {
    openContacts() {
      this.openModal(this.contactsModalId);
    },
    openHelp() {
      this.openModal(this.helpModalId);
    },
    openManual() {
      window.open(this.manualPath);
    },
    openModal(modalId) {
      if (!window.M) {
        return;
      }

      const instance = window.M.Modal.getInstance(
        document.getElementById(modalId)
      );

      if (instance) {
        instance.open();
      }
    }
  }
};
</script>

<style scoped>
.help-infotic-wrapper h6 {
  font-weight: bold;
}

.contrast .help-infotic-wrapper .help-infotic-modal-header {
  background-color: black !important;
}

.contrast .help-infotic-wrapper .modal-close {
  border: 1px solid white;
}
</style>
