<template>
  <section class="section">
    <component v-bind:is="tag" class="pae-title pae-header">
      <i v-if="secao.icone" class="material-icons left">{{ secao.icone }}</i
      >{{ secao.nome }}
    </component>

    <BaseInfoCard v-if="modeloSecao.instrucoes" title="Seção de estandes">
      <p>{{ modeloSecao.instrucoes }}</p>
    </BaseInfoCard>

    <div v-if="showOnlyEstandesOfUsuario" class="row-fix row">
      <div class="col s12">
        <BaseAlert severity="warning">
          Apenas são exibidos os estandes que estão sob sua gestão.
        </BaseAlert>
      </div>
    </div>

    <BaseProgressBar v-if="loading" v-bind="progressBar" />
    <p v-else-if="!hasEstandes" class="flow-text center">
      Nenhum estande foi cadastrado.
    </p>
    <BaseEditableCollection v-else v-bind="form.estandeCollection" />

    <ModeloChooser v-bind="modeloChooser" v-on:choose="add" />

    <EstandeEditoresModal v-bind="modalEditores" />

    <div class="right-align">
      <BaseButton
        v-bind="form.buttonAdd"
        v-bind:disabled="readonly || disabled"
        style="margin-right: 20px"
        v-on:click="openModeloChooser"
      />
      <BaseButton
        v-bind="form.buttonSave"
        v-bind:disabled="readonly || disabled"
        v-on:click="save"
      />
    </div>
  </section>
</template>

<script>
import { isEmpty } from "../../../utils/lang";
import {
  EventoService,
  EstandeService,
  ModeloService,
  PermissaoService,
  TipoModeloService
} from "../../../services";
import EditableSectionEstandeItem from "./EditableSectionEstandeItem.vue";
import ModeloChooser from "../ModeloChooser.vue";
import EstandeEditoresModal from "../EstandeEditoresModal.vue";

// TODO: implementar um endpoint para trazer todos os estandes de uma só vez
const SEARCH_PAGINATION_SIZE = 1000;

const TIPO_MODELO_ID = TipoModeloService.ESTANDE;

export default {
  name: "EditableSectionEstandes",
  components: {
    ModeloChooser,
    EstandeEditoresModal
  },
  inheritAttrs: false,
  props: {
    readonly: Boolean,

    tag: {
      type: String,
      default: "h3",
      validator: value =>
        ["h1", "h2", "h3", "h4", "h5", "h6"].indexOf(value) !== -1
    },
    entidadeId: {
      type: Number,
      required: true
    },
    modelo: {
      type: Object,
      required: true
    },
    secao: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      disabled: false,
      modeloSecao: ModeloService.findModeloSecao(
        this.modelo,
        this.secao.modeloSecaoId
      ),
      modalEditores: {
        id: `pae-editablesection-editores-${this.secao.id}-modal`,
        value: null
      },
      modeloChooser: {
        id: `pae-editablesection-estande-${this.secao.id}-chooser`,
        value: TIPO_MODELO_ID
      },
      progressBar: {
        useContainer: false
      },
      form: {
        estandeCollection: {
          component: EditableSectionEstandeItem,
          itemOperations: [
            {
              iconName: "edit",
              color: "green",
              title: "Editar estande",
              onClick: this.edit
            },
            {
              iconName: "person_add",
              color: "blue",
              title: "Definir editores do estande",
              onClick: this.editEditores
            }
            // ,
            // {
            //   iconName: "delete",
            //   color: "red",
            //   disabled: false,
            //   title: "Excluir estande",
            //   onClick: this.delete
            // }
          ],
          isSortable: false,
          items: []
        },
        buttonAdd: {
          iconName: "add",
          content: "Novo"
        },
        buttonSave: {
          iconName: "save",
          content: "Salvar"
        }
      },
      estandesOriginais: []
    };
  },
  computed: {
    hasEstandes() {
      return !isEmpty(this.form.estandeCollection.items);
    },
    isAdmin() {
      return this.$authorization.isAdmin();
    },
    isGestorEventos() {
      return this.$authorization.hasPermission(PermissaoService.GESTAO_EVENTO);
    },
    isGestorEstandes() {
      return this.$authorization.hasPermission(PermissaoService.GESTAO_ESTANDE);
    },
    isReadonly() {
      return !this.isGestorEventos;
    },
    showOnlyEstandesOfUsuario() {
      return !this.isAdmin && !this.isGestorEventos && this.isGestorEstandes;
    }
  },
  created() {
    this.doSearch();
  },
  methods: {
    doSearch() {
      this.loading = true;

      const params = {
        page: 0,
        size: SEARCH_PAGINATION_SIZE
      };

      if (this.showOnlyEstandesOfUsuario) {
        params.usuarios = [this.$store.state.user.id];
      }

      const idEvento = this.entidadeId;

      const idSecao = this.secao.id;

      let estandes = null;

      EventoService.searchEstandesFromSecao(idEvento, idSecao, params)
        .then(response => {
          estandes = response.data.elementos || [];

          return Promise.allSettled(
            estandes.map(item =>
              EstandeService.getBanner(item.id, {
                validateStatus: status =>
                  this.$http.isSuccessfulStatus(status) ||
                  status === this.$http.HTTP_STATUS_CLIENT_ERROR_NOT_FOUND
              })
            )
          );
        })
        .then(responses => {
          responses.map((item, index) => {
            if (item.status === "fulfilled") {
              estandes[index].banner = item.value.data;
            } else {
              // TODO: verificar se a mensagem de erro não deve ser silenciosa
              window.console.error(
                `Houve um erro ao obter a imagem: ${item.reason.message}`
              );
            }
          });

          this.form.estandeCollection.items = estandes.map(item => ({
            id: item.id,
            readonly: true,
            value: item
          }));

          this.estandesOriginais = estandes.map(item =>
            this.$utils.Object.copy(item)
          );
        })
        .catch(error =>
          this.$notification.pushError(
            `Houve um erro ao buscar os itens: ${error.message}`
          )
        )
        .finally(() => (this.loading = false));
    },
    openModeloChooser() {
      this.$nextTick().then(() =>
        this.$utils.Components.openModal(this.modeloChooser.id)
      );
    },
    add(event) {
      if (!this.readonly) {
        this.form.estandeCollection.items.push({
          id: null,
          readonly: this.readonly,
          value: {
            id: null,
            nome: null,
            eventoId: this.entidadeId,
            modeloId: event
          }
        });
      }
    },
    edit(item, index) {
      if (index >= 0) {
        const estande = this.form.estandeCollection.items[index].value;

        if (estande.id) {
          this.$router.push({
            name: "editEstande",
            params: {
              idEvento: this.$route.params.idEvento,
              idEstande: estande.id
            }
          });
        } else {
          this.$notification.pushInfo("O estande ainda não foi cadastrado");
        }
      }
    },
    editEditores(item, index) {
      if (!this.isReadonly && index >= 0) {
        const estande = this.form.estandeCollection.items[index].value;

        if (estande.id) {
          this.modalEditores.value = estande;

          this.$nextTick().then(() =>
            this.$utils.Components.openModal(this.modalEditores.id)
          );
        } else {
          this.$notification.pushInfo("O estande ainda não foi cadastrado");
        }
      }
    },
    // delete(item, index) {
    //   if (!this.readonly && index >= 0) {
    //     this.form.estandeCollection.items.splice(index, 1);
    //   }
    // },
    getPostData() {
      const postData = [];
      const { items } = this.form.estandeCollection;

      items.forEach(item => {
        if (!item.readonly && !item.value.id) {
          postData.push({ ...item.value });
        }
      });

      return postData;
    },
    getDeleteData() {
      const deleteData = [];
      const { items } = this.form.estandeCollection;

      this.estandesOriginais.forEach(originalItem => {
        const isItemFound =
          items.findIndex(item => originalItem.id === item.value.id) >= 0;

        if (!isItemFound) {
          deleteData.push({ ...originalItem });
        }
      });

      return deleteData;
    },
    isValid() {
      return (
        (this.form.estandeCollection.items || []).findIndex(item => {
          return isEmpty(item.value.nome);
        }) === -1
      );
    },
    isUnique() {
      const obj = {};

      this.form.estandeCollection.items.forEach(item => {
        const key = item.value.nome;

        if (!obj[key]) {
          obj[key] = 0;
        }

        obj[key]++;
      });

      return Object.values(obj).findIndex(item => item > 1) === -1;
    },
    save() {
      if (this.readonly) {
        return;
      }

      if (!this.isValid()) {
        this.$notification.pushError(
          "Alguns estandes não foram preenchidos corretamente"
        );
        return;
      }

      if (!this.isUnique()) {
        this.$notification.pushError("Alguns estandes possuem nomes repetidos");
        return;
      }

      const postData = this.getPostData();
      //   const deleteData = this.getDeleteData();

      const estandesOriginais = this.estandesOriginais;
      const estandeCollection = this.form.estandeCollection.items;

      const id = this.entidadeId;
      const idSecao = this.secao.id;

      const saveData = [].concat(
        postData.map(item =>
          EventoService.createEstande(id, item).then(response => {
            const estande = response.data;

            // Atualiza os registros originais cujos correspondentes foram
            // criados com sucesso para que, em caso de erro, os mesmos não
            // sejam reenviados ao servidor desnecessariamente.
            if (estande && estande.id) {
              const s = { ...estande };

              estandesOriginais.push(s);

              for (let i = 0; i < estandeCollection.length; i++) {
                const sc = estandeCollection[i].value;

                if (!sc.id && sc.nome === s.nome) {
                  sc.id = s.id;
                  sc.nome = s.nome;

                  estandeCollection[i].readonly = true;
                  break;
                }
              }

              EventoService.addEstande(id, idSecao, s.id);
            }

            this.$notification.pushSuccess(
              `Estande "${item.nome}" cadastrado com sucesso`
            );
          })
        )
        // ,
        // deleteData.map(item =>
        //   EventoService.removeEstande(id, item.id).then(() => {
        //     const id = item.id;

        //     // Atualiza os registros originais cujos correspondentes foram
        //     // excluídos com sucesso para que, em caso de erro, os mesmos não
        //     // sejam reenviados ao servidor desnecessariamente.
        //     for (let i = 0; i < estandesOriginais.length; i++) {
        //       if (estandesOriginais[i].id === id) {
        //         estandesOriginais.splice(i, 1);
        //         break;
        //       }
        //     }

        //     this.$notification.pushSuccess(
        //       `Estande "${item.nome}" excluído com sucesso`
        //     );
        //   })
        // )
      );

      if (saveData.length) {
        this.disabled = true;

        Promise.all(saveData)
          .catch(error =>
            this.$notification.pushError(
              `Houve um erro ao salvar os estandes: ${error.message}`
            )
          )
          .finally(() => (this.disabled = false));
      }
    }
  }
};
</script>
