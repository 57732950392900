<template>
  <ul class="pagination">
    <li
      v-if="showBorderButtons"
      class="waves-effect"
      v-bind:class="{ disabled: curPage === minPage }"
    >
      <a href="#!" title="Ir à primeira página" v-on:click="rewind">
        <i class="material-icons">first_page</i>
      </a>
    </li>
    <li class="waves-effect" v-bind:class="{ disabled: curPage === minPage }">
      <a href="#!" title="Voltar uma página" v-on:click="step(-1)">
        <i class="material-icons">chevron_left</i>
      </a>
    </li>
    <li
      v-for="(item, index) in shownPages"
      v-bind:key="index"
      v-bind:class="{
        active: item.pageNumber === curPage,
        'waves-effect': item.pageNumber
      }"
    >
      <a href="#!" v-on:click="goto(item.pageNumber)">{{ item.content }}</a>
    </li>
    <li class="waves-effect" v-bind:class="{ disabled: curPage === numPages }">
      <a href="#!" title="Avançar uma página" v-on:click="step(1)">
        <i class="material-icons">chevron_right</i>
      </a>
    </li>
    <li
      v-if="showBorderButtons"
      class="waves-effect"
      v-bind:class="{ disabled: curPage === numPages }"
    >
      <a href="#!" title="Ir à última página" v-on:click="forward">
        <i class="material-icons">last_page</i>
      </a>
    </li>
  </ul>
</template>

<script>
/**
 * @module components/base/BasePagination/BasePagination
 * @category Componentes-base
 * @summary _Single File Component_ (SFC) de paginação do {@link external:Materialize Materialize}.
 *
 * @description
 * A cor de fundo do item ativo é obtida automaticamente a partir da
 * configuração do ambiente.
 *
 * Este componente não possui _slot_ _default_.
 *
 * @requires module:constants.DEFAULT_COLOR
 * @requires module:constants.DEFAULT_LUMINOSITY
 * @requires module:utils/web.createStyleElement
 * @requires module:utils/web.getMaterializeColorCode
 *
 * @vue-prop {number} numPages - Número total de páginas.
 * @vue-prop {number} [numShownPages=7] - Número máximo de páginas a serem exibidas.
 * @vue-prop {boolean} [showBorderButtons=true] - Indica se os botões de acesso
 * às primeiras e últimas páginas devem ser exibidos.
 * @vue-prop {boolean} [showEllipsis=true] - Indica se reticências serão
 * exibidas caso `numPages` seja maior que `numShownPages`.
 * @vue-prop {string} [value] - Valor inicial do componente, i.e., a página
 * inicial da paginação, e utilizado para a diretiva `v-model` do {@link external:Vue Vue}.
 * @vue-event {string} onInput - Emite o valor atual do componente, i.e., a
 * página atual da paginação. Pode ser capturado pela diretiva `v-on:input`
 * (ou `v-model`) do {@link external:Vue Vue}.
 * @vue-event {string} onChange - Emite o valor atual do componente, i.e., a
 * página atual da paginação. Pode ser capturado pela diretiva `v-on:change` do {@link external:Vue Vue}.
 *
 * @example
 * <BasePagination
 *  v-bind:num-pages="15"
 *  v-bind:color="teal"
 *  v-bind:luminosity="darken-1"
 *  v-model="page" />
 */
import { DEFAULT_COLOR, DEFAULT_LUMINOSITY } from "../../../constants";
import {
  createStyleElement,
  getMaterializeColorCode
} from "../../../utils/web";

export default {
  name: "BasePagination",
  inheritAttrs: false,
  props: {
    numPages: {
      type: Number,
      required: true
    },
    numShownPages: {
      type: Number,
      default: 7
    },
    showBorderButtons: {
      type: Boolean,
      default: true
    },
    showEllipsis: {
      type: Boolean,
      default: true
    },

    value: Number
  },
  data() {
    return {
      shownPages: [],
      curPage: 1,
      minPage: 1
    };
  },
  watch: {
    numPages: {
      immediate: true,

      handler(newNumPages) {
        this.buildShownPages(newNumPages);
      }
    },
    value: {
      immediate: true,

      handler(newValue) {
        if (newValue === null || newValue === undefined) {
          this.curPage = 1;
        } else {
          if (newValue > this.numPages) {
            this.curPage = this.minPage;
          }

          this.curPage = newValue;
          this.buildShownPages(this.numPages);
        }
      }
    }
  },
  created() {
    this.overrideMaterializeStyle();
  },
  methods: {
    overrideMaterializeStyle() {
      const colorCode = getMaterializeColorCode(
        DEFAULT_COLOR,
        DEFAULT_LUMINOSITY
      );

      if (colorCode) {
        const CSSStyleSheet = `
        .pagination li.active {
          background-color: ${colorCode};
        }
      `;

        createStyleElement({
          id: "stylesheet-pagination",
          type: "text/css",
          innerHTML: CSSStyleSheet
        });
      }
    },
    buildShownPages(numPages) {
      const shownPages = [];
      const ellipsis = {
        pageNumber: null,
        content: "..."
      };

      // Há menos páginas (ou o mesmo número) do que o permitido para ser exibido
      if (numPages <= this.numShownPages) {
        for (let i = 1; i <= numPages; i++) {
          shownPages.push({
            pageNumber: i,
            content: i.toString()
          });
        }
      }
      // Há mais páginas do que o permitido para ser exibido
      else {
        const halfShownPages = window.parseInt(this.numShownPages / 2);

        const start =
          this.curPage > this.numPages - halfShownPages
            ? this.numPages - this.numShownPages + 1
            : Math.max(this.curPage - halfShownPages, 1);

        const stop = Math.min(start + this.numShownPages - 1, this.numPages);

        // Mostra as reticências no início da lista para indicar que há mais páginas para a esquerda
        if (start > 1 && this.showEllipsis) {
          shownPages.push(ellipsis);
        }

        for (let i = start; i <= stop; i++) {
          shownPages.push({
            pageNumber: i,
            content: i.toString()
          });
        }

        if (stop < this.numPages) {
          // Mostra as reticências no final da lista para indicar que há mais páginas para a direita
          if (this.showEllipsis) {
            shownPages.push(ellipsis);
          }
        }
      }

      this.shownPages = shownPages;
    },
    emitCurPage() {
      this.$emit("input", this.curPage);
      this.$emit("change", this.curPage);
    },
    forward() {
      this.goto(this.numPages);
    },
    goto(pageNumber) {
      if (
        pageNumber &&
        pageNumber != this.curPage &&
        pageNumber >= this.minPage &&
        pageNumber <= this.numPages
      ) {
        this.curPage = pageNumber;
        this.emitCurPage();
        this.buildShownPages(this.numPages);
      }
    },
    rewind() {
      this.goto(this.minPage);
    },
    step(delta) {
      if (this.curPage + delta > this.numPages) {
        this.forward();
      } else if (this.curPage + delta < this.minPage) {
        this.rewind();
      } else {
        this.goto(this.curPage + delta);
      }
    }
  }
};
</script>

<style scoped>
.contrast .pagination li a,
.contrast .pagination li a i.material-icons {
  background-color: transparent !important;
}
.contrast .pagination li.active {
  background-color: white !important;
  color: black !important;
}
.contrast .pagination li.active a {
  color: black !important;
}
.contrast .pagination li:hover {
  background-color: white !important;
  color: black !important;
}
.contrast .pagination li:hover a,
.contrast .pagination li:hover a i.material-icons {
  color: black !important;
}
</style>
