<template>
  <div ref="floatingActionButton" class="fixed-action-btn">
    <BaseButton
      class="btn-floating"
      v-bind:class="classes"
      v-bind:title="$attrs.title"
      v-bind:tag="'button'"
    >
      <i class="material-icons" v-bind:class="iconSize">{{ iconName }}</i>
    </BaseButton>
    <ul>
      <slot>
        <li v-for="(item, index) in actions" v-bind:key="index">
          <BaseButton
            class="btn-floating"
            v-bind="item"
            v-on:click="item.onClick"
          >
            <i class="material-icons">{{ item.iconName }}</i>
          </BaseButton>
        </li>
      </slot>
    </ul>
  </div>
</template>

<script>
/**
 * @module components/base/BaseFloatingActionButton/BaseFloatingActionButton
 * @category Componentes-base
 * @summary _Single File Component_ (SFC) de _Floating Action Button_ do {@link external:Materialize Materialize}.
 *
 * @description
 * A cor de fundo é obtida automaticamente a partir da configuração do
 * ambiente, mas pode ser sobrescrita através das _props_ `color` e
 * `luminosity`.
 *
 * O _slot_ _default_ recebe os botões de ação do _Floating Action Button_.
 *
 * Atributos HTML de acessibilidade, e.g., `title`, podem ser usados
 * diretamente no componente. Nesse caso, o atributo é exibido para o botão
 * principal do _Floating Action Button_. Cada _action_ do _Floating Action
 * Button_ pode ter um atributo `title` através da propriedade `action[].title`
 * correspondente.
 *
 * Para correto funcionamento, é necessário que o _framework_ {@link external:Materialize Materialize}
 * tenha sido importado, tornando seu objeto principal acessível globalmente.
 *
 * @requires module:constants.DEFAULT_COLOR
 * @requires module:constants.DEFAULT_LUMINOSITY
 * @requires module:utils/web.createStyleElement
 * @requires module:utils/web.darken
 * @requires module:utils/web.getMaterializeColorCode
 * @requires module:utils/web.lighten
 *
 * @vue-prop {string} [color] - Classe do {@link external:Materialize Materialize}
 * para a cor de fundo padrão do componente.
 * @vue-prop {string} [luminosity] - Classe do {@link external:Materialize Materialize}
 * para a luminosidade/nível de cor de fundo padrão do componente.
 * @vue-prop {string} [size="large"] - Classe CSS do {@link external:Materialize Materialize} que
 * indica o tamanho do botão. Aceita "small" e "large".
 * @vue-prop {string} iconName - Nome do [ícone do Material Design]{@link external:MaterialDesignIcons}.
 * @vue-prop {string} [iconSize="large"] - Classe CSS do {@link external:Materialize Materialize} que
 * indica o tamanho do ícone. Aceita "tiny", "small", "medium" e "large".
 * @vue-prop {Array<object>} [actions] - _Array_ com as as propriedades dos
 * botões (vide {@link module:components/base/BaseButton/BaseButton BaseButton}) do _Floating Action Button_. É usado
 * como substituto quando nenhum dado é passado através do _slot_ _default_.
 * @vue-prop {object} [floatingActionButtonOptions] - Opções aceitas pelo
 * _Floating Action Button_ do {@link external:Materialize Materialize}.
 *
 * @example <caption>Informando as ações por props</caption>
 * <BaseFloatingActionButton
 *  icon-name="mode_edit"
 *  v-bind:actions="[{ color: 'yellow', luminosity: 'darken-3', iconName: 'format_quote', onClick='...' }]" />
 *
 * @example <caption>Informando as ações por slot</caption>
 * <BaseFloatingActionButton
 *  icon-name="mode_edit">
 *  <li v-on:click="onClick">
 *    <a class="btn-floating yellow darken-3">
 *      <i class="material-icons">format_quote</i>
 *    </a>
 *  </li>
 * </BaseFloatingActionButton>
 */
import { DEFAULT_COLOR, DEFAULT_LUMINOSITY } from "../../../constants";
import {
  createStyleElement,
  darken,
  getMaterializeColorCode,
  lighten
} from "../../../utils/web";
import BaseButton from "../BaseButton/BaseButton.vue";

export default {
  name: "BaseFloatingActionButton",
  components: {
    BaseButton
  },
  inheritAttrs: false,
  props: {
    color: String,
    luminosity: String,

    size: {
      type: String,
      default: "large",
      validator: value => ["small", "large"].indexOf(value) !== -1
    },
    iconName: {
      type: String,
      required: true
    },
    iconSize: {
      type: String,
      default: "large",
      validator: value =>
        ["tiny", "small", "medium", "large"].indexOf(value) !== -1
    },

    actions: Array,
    floatingActionButtonOptions: Object
  },
  data() {
    return {
      instance: null
    };
  },
  computed: {
    classes() {
      let classes = [];

      if (this.color) {
        classes.push(this.color);
      }

      if (this.luminosity) {
        classes.push(this.luminosity);
      }

      if (this.size) {
        classes.push(`btn-${this.size}`);
      }

      if (this.floatingActionButtonOptions) {
        if (!this.floatingActionButtonOptions.hoverEnabled) {
          classes.push("click-to-toggle");
        }

        if (this.floatingActionButtonOptions.toolbarEnabled) {
          classes.push("toolbar");
        }
      }

      return classes;
    },
    hasPropsActions() {
      return this.actions && this.actions.length;
    },
    hasSlotActions() {
      return this.$slots.default && this.$slots.default.length;
    }
  },
  beforeCreate() {
    if (!window.M) {
      window.console.error(
        "Objeto do Materialize não encontrado. Verifique se essa biblioteca foi carregada corretamente"
      );
    }
  },
  created() {
    this.overrideMaterializeStyle();
  },
  mounted() {
    if (!window.M) {
      return;
    }

    const { floatingActionButton } = this.$refs;

    this.instance = window.M.FloatingActionButton.init(
      floatingActionButton,
      this.floatingActionButtonOptions
    );
  },
  beforeDestroy() {
    if (this.instance) {
      this.instance.destroy();
    }
  },
  methods: {
    overrideMaterializeStyle() {
      const colorCode = getMaterializeColorCode(
        DEFAULT_COLOR,
        DEFAULT_LUMINOSITY
      );

      if (colorCode) {
        const CSSStyleSheet = `
        .btn:focus,
        .btn-floating:focus {
          background-color: ${darken(
            colorCode,
            0.1
          )}; /* darken($button-raised-background, 10%) */
        }
        .btn {
          background-color: ${colorCode}; /* $button-raised-background ($secondary-color) */
        }
        .btn:hover {
          background-color: ${lighten(
            colorCode,
            0.05
          )}; /* $button-raised-background-hover (lighten($button-raised-background, 5%)) */
        }
        .btn-floating:hover {
          background-color: ${colorCode}; /* $button-floating-background-hover ($button-floating-background) */
        }
        .btn-floating,
        .fixed-action-btn ul .fab-backdrop {
          background-color: ${colorCode}; /* $button-floating-background ($secondary-color) */
        }
      `;

        // Utiliza-se o mesmo `id` de stylesheet do BaseButton
        createStyleElement({
          id: "stylesheet-button",
          type: "text/css",
          innerHTML: CSSStyleSheet
        });
      }
    }
  }
};
</script>

<style scoped>
.contrast .fixed-action-btn,
.contrast .fixed-action-btn ul,
.contrast .fixed-action-btn li {
  background-color: transparent !important;
}
</style>
