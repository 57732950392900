/**
 * @module components/base/BaseTypeahead
 * @category Componentes-base
 * @summary Módulo de importação do componente-base de _typeahead_.
 *
 * @description
 * Expõe o componente-base de _typeahead_ para utilização pelo container e
 * pelos módulos.
 *
 * @example <caption>Importando como plug-in</caption>
 * // main.js
 * import BaseTypeahead from "./components/base/BaseTypeahead";
 *
 * Vue.use(BaseTypeahead);
 *
 * @example <caption>Importando diretamente em um componente</caption>
 * // componente.vue
 * // ou import BaseTypeahead from "./components/base/BaseTypeahead/BaseTypeahead.vue";
 * import { BaseTypeahead } from "./components/base/BaseTypeahead";
 *
 * export default {
 *  name: // ...
 *  components: {
 *    BaseTypeahead
 *  },
 *  // ...
 * }
 */
import { default as BaseTypeahead } from "./BaseTypeahead.vue";

/**
 * Associa o componente-base de input à instância do {@link external:Vue Vue}.
 * @param {external:Vue} Vue - Instância do {@link external:Vue Vue}.
 */
const install = Vue => Vue.component(BaseTypeahead.name, BaseTypeahead);

if (window && window.Vue) {
  install(window.Vue);
}

export default install;

export { BaseTypeahead };
