<template>
  <section class="section">
    <!-- <h3 class="pae-title pae-header">
        <i class="material-icons left">account_balance</i>Apoiadores
      </h3> -->

    <BaseProgressBar v-if="loading" v-bind="progressBar" />
    <!-- <p v-else-if="!hasApoiadores" class="flow-text center">
      Nenhum apoiador foi cadastrado.
    </p> -->
    <div v-else-if="hasApoiadores" class="row-fix center">
      <img v-bind="banner" class="responsive-img" />
    </div>
  </section>
</template>

<script>
import { isEmpty } from "../../../utils/lang";
import { EventoService } from "../../../services";

export default {
  name: "SectionApoiadores",
  inheritAttrs: false,
  props: {
    entidadeId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      progressBar: {
        useContainer: false
      },
      apoiadores: null
    };
  },
  computed: {
    hasApoiadores() {
      return !isEmpty(this.apoiadores);
    },
    banner() {
      if (!this.hasApoiadores || isEmpty(this.apoiadores.banner)) {
        return null;
      }

      const banner = {};

      banner.src = this.apoiadores.banner.conteudo;
      banner.alt = this.apoiadores.descricao;
      banner.title = this.apoiadores.descricao;

      return banner;
    }
  },
  created() {
    this.getApoiadores();
  },
  methods: {
    getApoiadores() {
      if (!this.entidadeId) {
        return;
      }

      this.loading = true;

      this.apoiadores = null;

      const id = this.entidadeId;

      EventoService.getApoiadores(id, {
        validateStatus: status =>
          this.$http.isSuccessfulStatus(status) ||
          status === this.$http.HTTP_STATUS_CLIENT_ERROR_NOT_FOUND
      })
        .then(response => (this.apoiadores = response.data))
        .catch(error =>
          this.$notification.pushError(
            `Houve um erro ao obter o banner de apoiadores: ${error.message}`
          )
        )
        .finally(() => (this.loading = false));
    }
  }
};
</script>
