<template>
  <BaseModal v-bind="modeloChooser">
    <template v-slot:header>
      <header>
        <h3 class="pae-title">Modelos</h3>
      </header>
    </template>

    <BaseInfoCard title="Escolha de modelo">
      <p>
        Escolha um dos modelos na lista abaixo clicando no radiobutton
        correspondente. Em seguida, para efetivar a escolha, basta clicar no
        botão "Salvar".
      </p>
    </BaseInfoCard>

    <BaseProgressBar v-if="loading" v-bind="progressBar"
      >Carregando modelos...</BaseProgressBar
    >
    <p v-else-if="!hasModelos" class="flow-text center">
      Não há modelos disponíveis para os parâmetros informados.
    </p>
    <div v-else>
      <BaseCollection>
        <template v-slot:items>
          <li
            v-for="(item, index) in modelos"
            v-bind:key="index"
            class="collection-item modelo"
          >
            <div class="modelo-descricao">
              <h5>{{ item.nome }}</h5>
              <p>{{ item.descricao }}</p>
            </div>
            <div class="modelo-radiobutton">
              <BaseRadioButton
                v-bind="form.radiobutton"
                v-bind:v-value="item.id"
                v-on:change="form.radiobutton.value = $event"
              />
            </div>
          </li>
        </template>
      </BaseCollection>
      <BasePagination
        class="center"
        v-bind="form.pagination"
        v-on:change="onPageChange"
      />
    </div>

    <template v-slot:footer>
      <BaseButton
        v-bind:disabled="disabled"
        class="modal-close"
        material-type="flat"
        >Cancelar</BaseButton
      >
      <BaseButton
        v-bind:disabled="disabled"
        material-type="flat"
        v-on:click="choose"
        >Salvar</BaseButton
      >
    </template>
  </BaseModal>
</template>

<script>
import { ModeloService, TipoModeloService } from "../../services";

const SEARCH_PAGINATION_SIZE = 5;

export default {
  name: "ModeloChooser",
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      required: true
    },
    value: {
      type: Number,
      required: true,
      validator: value =>
        [TipoModeloService.EVENTO, TipoModeloService.ESTANDE].indexOf(value) >=
        0
    }
  },
  data() {
    return {
      loading: false,
      disabled: false,
      modelos: null,
      progressBar: {
        useContainer: false
      },
      modeloChooser: {
        id: this.id,
        hasFixedFooter: true,
        modalOptions: {
          onOpenStart: this.onModalOpen,
          dismissible: false
        }
      },
      form: {
        modelos: null,
        radiobutton: {
          name: "modelo",
          withGap: true,
          value: null
        },
        pagination: {
          value: 1,
          numPages: 0
        }
      }
    };
  },
  computed: {
    title() {
      switch (this.value) {
        case TipoModeloService.EVENTO:
          return "Escolha o modelo para o evento";
        case TipoModeloService.ESTANDE:
          return "Escolha o modelo para o estande";
        default:
          this.$notification.pushError("Tipo de modelo inválido");
          return null;
      }
    },
    hasModelos() {
      return !this.$utils.Lang.isEmpty(this.modelos);
    }
  },
  methods: {
    onModalOpen() {
      this.form.radiobutton.value = null;
      this.form.pagination.value = 1;
      this.form.pagination.numPages = 0;
      this.doSearch();
    },
    doSearch() {
      this.loading = true;

      this.modelos = null;

      const params = {
        tipoModelo: this.value,
        page: this.form.pagination.value - 1,
        size: SEARCH_PAGINATION_SIZE
      };

      ModeloService.search(params)
        .then(response => {
          const pagina = response.data;

          this.modelos = pagina.elementos;

          this.form.pagination.numPages = pagina.totalPaginas;
        })
        .catch(error =>
          this.$notification.pushError(
            `Houve um erro ao obter os modelos disponíveis: ${error.message}`
          )
        )
        .finally(() => (this.loading = false));
    },
    onPageChange(event) {
      this.form.pagination.value = event;
      this.doSearch();
    },
    choose() {
      if (!this.form.radiobutton.value) {
        return this.$notification.pushError("Modelo não selecionado");
      }

      this.$emit("choose", this.form.radiobutton.value);

      this.$utils.Components.closeModal(this.id);
    }
  }
};
</script>

<style scoped>
.modelo {
  display: flex;
  min-width: 100%;
  justify-content: space-between;
}
.modelo .modelo-radiobutton {
  display: flex;
  align-items: center;
  flex: 0 1 auto;
  padding-left: 10px;
}
</style>
