<template>
  <component
    v-bind:is="tag"
    v-bind:id="id"
    v-bind:name="name"
    v-bind:class="classes"
    v-bind:type="type"
    v-bind:disabled="disabled"
    v-bind:title="$attrs.title"
    v-bind:data-target="$attrs['data-target']"
    v-bind="conditionalAttrs"
    v-on="listeners"
  >
    <i
      v-if="showIcon && iconName"
      class="material-icons"
      v-bind:class="computedIconPosition"
      >{{ iconName }}</i
    >
    <slot>{{ content }}</slot>
  </component>
</template>

<script>
/**
 * @module components/base/BaseButton/BaseButton
 * @category Componentes-base
 * @summary _Single File Component_ (SFC) de botão do {@link external:Materialize Materialize}.
 *
 * @description
 * A cor de fundo é obtida automaticamente a partir da configuração do
 * ambiente, mas pode ser sobrescrita através das _props_ `color` e
 * `luminosity`.
 *
 * O _slot_ _default_ recebe o conteúdo do botão.
 *
 * Atributos HTML de acessibilidade, e.g., `title`, podem ser usados
 * diretamente no componente.
 *
 * Para que o botão seja utilizado como elemento disparador do {@link module:components/base/BaseDropdown/BaseDropdown BaseDropdown},
 * é aceito o atributo HTML `data-target`.
 *
 * @requires module:constants.DEFAULT_COLOR
 * @requires module:constants.DEFAULT_LUMINOSITY
 * @requires module:utils/web.createStyleElement
 * @requires module:utils/web.darken
 * @requires module:utils/web.getMaterializeColorCode
 * @requires module:utils/web.lighten
 *
 * @vue-prop {string} [id] - Atributo `id` do elemento HTML utilizado
 * internamente pelo componente.
 * @vue-prop {string} [name] - Atributo `name` do elemento HTML utilizado
 * internamente pelo componente.
 * @vue-prop {string} [tag="button"] - Nome do elemento HTML em que o botão será
 * renderizado. Aceita "a" e "button".
 * @vue-prop {string} [type="button"] - Tipo de botão quando utilizado em
 * formulários. Aceita "button", "submit" e "reset".
 * @vue-prop {string} [href="#!"] - Atributo `href` para quando `tag` é "a".
 * @vue-prop {boolean} [disabled=false] - Indica se o componente estará
 * desabilitado.
 * @vue-prop {string} [content] - Conteúdo a ser exibido no elemento HTML
 * utilizado iternamente pelo componente. É usado como substituto quando
 * nenhum dado é passado através do _slot_ _default_.
 * @vue-prop {string} [color] - Classe do {@link external:Materialize Materialize}
 * para a cor de fundo padrão do componente.
 * @vue-prop {string} [luminosity] - Classe do {@link external:Materialize Materialize}
 * para a luminosidade/nível de cor de fundo padrão do componente.
 * @vue-prop {string} [size] - Classe CSS do {@link external:Materialize Materialize} que
 * indica o tamanho do botão. Aceita "small" e "large".
 * @vue-prop {string} [materialType="raised"] - Classe CSS do {@link external:Materialize Materialize}
 * para o tipo de botão. Aceita "raised", "floating" e "flat".
 * @vue-prop {boolean} [hasWaves=true] - Indica se o botão apresentará o efeito wave
 * do {@link external:Materialize Materialize} quando clicado.
 * @vue-prop {string} [wavesColor] - Classe CSS do {@link external:Materialize Materialize}
 * que indica a cor do efeito wave.
 * @vue-prop {boolean} [hasCircledWaves=false] - Indica se o efeito wave do
 * {@link external:Materialize Materialize} será circular.
 * @vue-prop {boolean} [showIcon=true] - Indica se o componente deve exibir o
 * ícone especificado.
 * @vue-prop {string} [iconName] - Nome do [ícone do Material Design]{@link external:MaterialDesignIcons}.
 * @vue-prop {string} [iconPosition="right"] - Posição em que o ícone será
 * localizado. Aceita "left" ou "right". Não considerada quando `content` ou o
 * _slot_ default não possui conteúdo.
 * @vue-event {external:Event} onClick - Emite o evento de click. Pode ser capturado
 * pela diretiva `v-on:click` do {@link external:Vue Vue}.
 *
 * @example
 * <BaseButton
 *  tag="button"
 *  type="submit"
 *  color="teal"
 *  luminosity="darken-1"
 *  icon-name="save">
 *  Salvar
 * </BaseSelect>
 */
import { DEFAULT_COLOR, DEFAULT_LUMINOSITY } from "../../../constants";
import {
  createStyleElement,
  darken,
  getMaterializeColorCode,
  lighten
} from "../../../utils/web";

export default {
  name: "BaseButton",
  inheritAttrs: false,
  props: {
    id: String,
    name: String,

    tag: {
      type: String,
      default: "button",
      validator: value => ["a", "button"].indexOf(value) !== -1
    },
    type: {
      type: String,
      default: "button",
      validator: value => ["button", "submit", "reset"].indexOf(value) !== -1
    },
    href: {
      type: String,
      default: "#!"
    },
    disabled: {
      type: Boolean,
      default: false
    },

    content: String,

    color: String,
    luminosity: String,

    size: {
      type: String,
      validator: value => ["small", "large"].indexOf(value) !== -1
    },
    materialType: {
      type: String,
      default: "raised",
      validator: value => ["raised", "floating", "flat"].indexOf(value) !== -1
    },
    hasWaves: {
      type: Boolean,
      default: true
    },

    wavesColor: String,

    hasCircledWaves: {
      type: Boolean,
      default: false
    },
    showIcon: {
      type: Boolean,
      default: true
    },

    iconName: String,

    iconPosition: {
      type: String,
      default: "right",
      validator: value => ["left", "right"].indexOf(value) !== -1
    }
  },
  computed: {
    isForm() {
      return ["submit", "reset"].indexOf(this.type) !== -1;
    },
    computedIconPosition() {
      return this.content || this.$slots.default ? this.iconPosition : "";
    },
    conditionalAttrs() {
      const attrs = {};

      if (this.tag === "a") {
        attrs.href = this.href;
      }

      return attrs;
    },
    classes() {
      let classes = [];

      if (this.color) {
        classes.push(this.color);
      }

      if (this.luminosity) {
        classes.push(this.luminosity);
      }

      if (this.materialType !== "raised") {
        classes.push(`btn-${this.materialType}`);
      } else {
        classes.push("btn");
      }

      if (this.size) {
        classes.push(`btn-${this.size}`);
      }

      if (this.hasWaves) {
        classes.push("waves-effect");

        if (this.wavesColor) {
          classes.push(`waves-${this.wavesColor}`);
        }
      }

      if (this.hasCircledWaves) {
        classes.push(`waves-circle`);
      }

      return classes;
    },
    listeners() {
      return Object.assign({}, this.$listeners, { click: this.onClick });
    }
  },
  created() {
    this.overrideMaterializeStyle();
  },
  methods: {
    overrideMaterializeStyle() {
      const colorCode = getMaterializeColorCode(
        DEFAULT_COLOR,
        DEFAULT_LUMINOSITY
      );

      if (colorCode) {
        const CSSStyleSheet = `
        .btn:focus,
        .btn-floating:focus {
          background-color: ${darken(
            colorCode,
            0.1
          )}; /* darken($button-raised-background, 10%) */
        }
        .btn {
          background-color: ${colorCode}; /* $button-raised-background ($secondary-color) */
        }
        .btn:hover {
          background-color: ${lighten(
            colorCode,
            0.05
          )}; /* $button-raised-background-hover (lighten($button-raised-background, 5%)) */
        }
        .btn-floating:hover {
          background-color: ${colorCode}; /* $button-floating-background-hover ($button-floating-background) */
        }
        .btn-floating,
        .fixed-action-btn ul .fab-backdrop {
          background-color: ${colorCode}; /* $button-floating-background ($secondary-color) */
        }
      `;

        createStyleElement({
          id: "stylesheet-button",
          type: "text/css",
          innerHTML: CSSStyleSheet
        });
      }
    },
    onClick($event) {
      this.$emit("click", $event);
    }
  }
};
</script>
