<template>
  <ul class="accessibility-skip-menu hide-on-med-and-down">
    <li>
      <a
        href="#pae-content"
        class="skip-anchor center-align"
        v-on:click="onClick"
      >
        &raquo; Ir para conteúdo
      </a>
    </li>
    <li>
      <a
        href="#pae-navbar-menu"
        class="skip-anchor center-align"
        v-on:click="onClick"
      >
        &raquo; Ir para menu
      </a>
    </li>
    <li>
      <a
        href="#pae-navbar-menu-ajuda"
        class="skip-anchor center-align"
        v-on:click="onClick"
      >
        &raquo; Ir para ajuda
      </a>
    </li>
  </ul>
</template>

<script>
import { isNullOrUndefined } from "../utils";

export default {
  name: "TheAccessibilitySkipMenu",
  inheritAttrs: false,
  methods: {
    onClick($event) {
      // Evita a remoção do foco após este evento ser completado.
      // Um ponto negativo é que o conteúdo do hash não é aplicado
      // na barra de endereço do browser.
      $event.preventDefault();

      const { href } = $event.target;

      if (href) {
        const id = href.split("#")[1];

        const elem = document.getElementById(id);

        if (elem) {
          const tabIndex = elem.getAttribute("tabIndex");

          // Permite que o foco seja aplicado programaticamente,
          // caso `elem` seja um elemento que não recebe focus
          // naturalmente (e.g., div, main, etc.)
          elem.setAttribute("tabIndex", -1);
          elem.focus();

          ["blur", "focusout"].forEach(item => {
            elem.addEventListener(
              item,
              () => {
                // Caso `elem` não possuía o atributo `tabIndex`, remove-o.
                // Caso contrário, retorna ao seu valor anterior.
                if (isNullOrUndefined(tabIndex)) {
                  elem.removeAttribute("tabIndex");
                } else {
                  elem.setAttribute("tabIndex", tabIndex);
                }
              },
              // Aplica o evento a `elem` apenas uma vez.
              // Retirado de: https://codepen.io/2kool2/pen/bxdzEJ
              { once: true }
            );
          });
        }
      }
    }
  }
};
</script>

<style scoped>
.accessibility-skip-menu {
  position: relative;
}
.accessibility-skip-menu li a.skip-anchor {
  position: absolute;
  top: -1000px;
  left: -1000px;
  font-weight: 700;
  overflow: hidden;
}
.accessibility-skip-menu li a.skip-anchor:active,
.accessibility-skip-menu li a.skip-anchor:hover,
.accessibility-skip-menu li a.skip-anchor:focus {
  left: 150px;
  top: 0;
  overflow: visible;
}

/* Baseado em: https://codepen.io/2kool2/pen/JjYGdmZ */
@media print {
  .accessibility-skip-menu li a.skip-anchor {
    display: none;
  }
}
</style>
