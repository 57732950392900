<template>
  <article class="container">
    <header>
      <h1 class="thin center">
        {{ isAdminRoute ? "Gestão de eventos" : "Eventos" }}
      </h1>
    </header>

    <BaseInfoCard title="Busca de eventos">
      <p>
        Antes de realizar a busca por eventos, você pode preencher completamente
        (ou parte de) o nome do evento, assim como filtrar por eventos que sejam
        online ou escolher um ou mais assuntos em que os eventos se enquadram.
        Em seguida, para efetivar a busca, basta clicar no botão "Buscar".
      </p>

      <p v-if="isAdminRoute">
        Clicando no botão "Novo", você será redirecionado para a tela de
        cadastro de eventos.
      </p>
    </BaseInfoCard>

    <div class="row-fix row">
      <BaseTextInput v-bind="form.nome" v-model="form.nome.value" />

      <BaseDatePicker v-bind="form.inicio" v-model="form.inicio.value" />
      <BaseDatePicker v-bind="form.termino" v-model="form.termino.value" />

      <div class="col s12">
        <label v-bind:for="form.tipos.id">Tipos</label>
        <div v-bind:id="form.tipos.id" class="row">
          <BaseCheckbox
            v-for="(item, index) in Object.values(form.tipos.tipos)"
            v-bind:key="index"
            v-bind:id="item.id"
            v-bind:name="item.name"
            v-bind:content="item.content"
            v-bind:v-value="item.value"
            v-model="item.value"
            class="col s12 m6 l4"
            tabindex="0"
          />
        </div>
      </div>

      <div v-if="loadingAssuntos" class="col s12">
        <BaseProgressBar v-bind="progressBar"
          >Carregando assuntos...</BaseProgressBar
        >
      </div>
      <div v-else class="col s12">
        <label v-bind:for="form.assuntos.id">{{ form.assuntos.label }}</label>
        <div v-bind:id="form.assuntos.id" class="row">
          <BaseCheckbox
            v-for="(item, index) in form.assuntos.assuntos"
            v-bind:key="index"
            v-bind:id="`assunto-${item.id}`"
            v-bind:name="`assunto-${item.id}`"
            v-bind:content="item.nome"
            v-bind:v-value="item.id"
            v-model="form.assuntos.value"
            class="col s12 m6 l4"
            tabindex="0"
          />
        </div>
      </div>

      <div
        class="col s12 buttons-row"
        v-bind:class="{ 'spaced-buttons': isAdminRoute }"
      >
        <BaseButton
          v-if="isAdminRoute"
          v-bind="form.buttonNew"
          v-on:click="openModeloChooser"
        />
        <BaseButton v-bind="form.buttonSearch" v-on:click="onClickSearch" />
      </div>

      <ModeloChooser v-bind="modeloChooser" v-on:choose="createEvento" />
    </div>

    <div v-if="searched" class="row-fix row">
      <div class="col s12">
        <BaseProgressBar v-if="loadingEventos" v-bind="progressBar" />
        <p v-else-if="!hasEventos" class="flow-text center">
          Não há eventos disponíveis para os parâmetros informados.
        </p>
        <div v-else>
          <EventosPanel v-bind="eventosPanel" />
          <BasePagination
            v-if="hasEventos"
            class="center"
            v-bind="form.pagination"
            v-on:change="onPageChange"
          />
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import { isEmpty } from "../utils/lang";
import { toISODate } from "../utils/datetime";
import {
  AssuntoService,
  EventoService,
  StatusService,
  TipoModeloService
} from "../services";
import EventosPanel from "../components/Common/EventosPanel.vue";
import ModeloChooser from "../components/Common/ModeloChooser.vue";

const SEARCH_PAGINATION_SIZE = 10;

export default {
  name: "Eventos",
  components: {
    EventosPanel,
    ModeloChooser
  },
  inheritAttrs: false,
  data() {
    return {
      loadingEventos: false,
      loadingAssuntos: false,
      searched: false,
      modeloChooser: {
        id: "pae-modelo-evento-chooser",
        value: TipoModeloService.EVENTO
      },
      eventosPanel: {
        eventos: null
      },
      progressBar: {
        useContainer: false
      },
      form: {
        nome: {
          id: "eventossearch-nome",
          label: "Nome",
          placeholder: "Preencha um trecho do nome do evento",
          gridExpression: "col s12 xl6",
          value: null
        },
        inicio: {
          id: "eventossearch-inicio",
          label: "Data início",
          gridExpression: "col s12 m6 xl3",
          value: null
        },
        termino: {
          id: "eventossearch-termino",
          label: "Data término",
          gridExpression: "col s12 m6 xl3",
          value: null
        },
        tipos: {
          id: "eventossearch-tipos",
          label: "Tipos",
          tipos: {
            continuo: {
              id: "tipo-continuo",
              name: "tipo-continuo",
              content: "Evento contínuo",
              value: false
            },
            online: {
              id: "tipo-online",
              name: "tipo-online",
              content: "Evento online",
              value: false
            }
          }
        },
        assuntos: {
          id: "eventossearch-assuntos",
          label: "Assuntos",
          assuntos: null,
          value: [] // Precisa ser definido inicialmente como array
        },
        buttonSearch: {
          iconName: "search",
          content: "Buscar"
        },
        buttonNew: {
          iconName: "event",
          content: "Novo"
        },
        pagination: {
          value: 1,
          numPages: 0
        }
      }
    };
  },
  computed: {
    hasEventos() {
      return !isEmpty(this.eventosPanel.eventos);
    },
    isAdminRoute() {
      return (
        (this.$route.matched || []).findIndex(item => item.name === "admin") !==
        -1
      );
    }
  },
  created() {
    this.$store.commit("setExtraNavbar", null);

    this.getAssuntos();
  },
  methods: {
    getAssuntos() {
      this.loadingAssuntos = true;

      AssuntoService.getAll()
        .then(response => (this.form.assuntos.assuntos = response.data || []))
        .catch(error =>
          this.$notification.pushError(
            `Houve um erro ao obter os assuntos: ${error.message}`
          )
        )
        .finally(() => (this.loadingAssuntos = false));
    },
    resetPagination() {
      this.form.pagination.value = 1;
      this.form.pagination.numPages = 0;
    },
    doSearch() {
      const params = {
        nome: this.form.nome.value,
        inicio: !isEmpty(this.form.inicio.value)
          ? toISODate(this.form.inicio.value)
          : null,
        termino: !isEmpty(this.form.termino.value)
          ? toISODate(this.form.termino.value)
          : null,
        assuntos: this.form.assuntos.value,
        page: this.form.pagination.value - 1,
        size: SEARCH_PAGINATION_SIZE
      };

      Object.entries(this.form.tipos.tipos).forEach(
        item => (params[item[0]] = item[1].value)
      );

      if (!this.isAdminRoute) {
        params.status = StatusService.PUBLICADO;
      }

      this.searched = true;

      this.loadingEventos = true;

      EventoService.search(params)
        .then(response => {
          const pagina = response.data;

          this.eventosPanel.eventos = pagina.elementos || [];

          this.form.pagination.numPages = pagina.totalPaginas;
        })
        .catch(error =>
          this.$notification.pushError(
            `Houve um erro ao obter a lista de eventos: ${error.message}`
          )
        )
        .finally(() => (this.loadingEventos = false));
    },
    onPageChange(event) {
      this.form.pagination.value = event;
      this.doSearch();
    },
    onClickSearch() {
      this.resetPagination();
      this.doSearch();
    },
    openModeloChooser() {
      this.$nextTick().then(() =>
        this.$utils.Components.openModal(this.modeloChooser.id)
      );
    },
    createEvento(event) {
      if (!this.isAdminRoute) {
        return;
      }

      this.$router.push({
        name: "editEvento",
        params: { modeloId: event }
      });
    }
  }
};
</script>

<style scoped>
.buttons-row {
  justify-content: right;
}
.buttons-row.spaced-buttons {
  justify-content: space-between;
}

@media screen and (max-width: 600px) {
  .buttons-row .btn {
    display: block;
    width: 100%;
  }

  .buttons-row .col {
    margin-bottom: 20px;
  }
}
</style>
