<template>
  <BaseConfirmModal v-bind="confirmModal" v-on:confirm="onConfirm">
    <template v-slot:header>
      <h4>Criação de usuário externo</h4>
    </template>

    <template v-slot:default>
      <p>
        Confirma a criação de usuário externo para "{{ value.nomeExibicao }}"
        ({{ identificacao.title }}: {{ identificacao.value }})?
      </p>
    </template>
  </BaseConfirmModal>
</template>

<script>
export default {
  name: "AddUsuarioConfirmModal",
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      required: true
    },
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      confirmModal: {
        id: this.id
      }
    };
  },
  computed: {
    identificacao() {
      const identificacao = {};

      if (this.value.cpf) {
        identificacao.title = "CPF";
        identificacao.value = this.value.cpf;
      } else {
        identificacao.title = "Passaporte";
        identificacao.value = this.value.passaporte;
      }

      return identificacao;
    }
  },
  methods: {
    onConfirm(event) {
      this.$emit("confirm", event);
    }
  }
};
</script>
