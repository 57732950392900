<template>
  <BaseModal v-bind="apresentadorModal">
    <template v-slot:header>
      <header>
        <h3 class="pae-title">
          {{ isCreate ? "Criar" : "Editar" }} apresentador
        </h3>
      </header>
    </template>

    <BaseInfoCard title="Seção principal">
      <p>
        Para {{ isCreate ? "criar" : "editar" }} este apresentador,
        inicialmente, preencha o campo "nome". Os campos "nome social" e
        "descrição" são opcionais. Você também pode adicionar uma foto, clicando
        no botão "Imagem" e escolhendo o arquivo desejado. Por fim, clique no
        botão "Salvar" para persistir os dados preenchidos.
      </p>
    </BaseInfoCard>

    <div v-if="!loading" class="row-fix row">
      <div class="foto-wrapper valign-wrapper">
        <div class="image-container col valign-wrapper">
          <img class="responsive-img" v-bind="thumbnail" />
        </div>
        <BaseFileInput
          ref="fileInput"
          class="input-container"
          v-bind="form.foto"
          v-bind:disabled="isReadonly"
          v-on:input="onFileInput"
        />
      </div>

      <BaseTextInput v-bind="form.nome" v-on:input="form.nome.value = $event" />
      <BaseTextInput
        v-bind="form.nomeSocial"
        v-bind:disabled="isReadonly"
        v-on:input="form.nomeSocial.value = $event"
      />

      <BaseTextarea
        v-bind="form.descricao"
        v-bind:disabled="isReadonly"
        v-on:input="form.descricao.value = $event"
      />
    </div>

    <template v-slot:footer>
      <BaseButton
        v-bind:disabled="isReadonly || disabled"
        class="modal-close"
        material-type="flat"
        title="Cancelar alterações no apresentador"
        >Cancelar</BaseButton
      >
      <BaseButton
        v-bind:disabled="isReadonly || disabled"
        material-type="flat"
        title="Salvar alterações no apresentador"
        v-on:click="save"
        >Salvar</BaseButton
      >
    </template>
  </BaseModal>
</template>

<script>
import { ApresentadorService, PermissaoService } from "../../../services";
import { NOPIC_IMG_SRC } from "../../../constants";

const equalsApresentador = (a, b) => {
  if ((!a && b) || (a && !b)) {
    return false;
  }

  if (!a && !b) {
    return true;
  }

  return (
    a.nome === b.nome &&
    a.nomeSocial === b.nomeSocial &&
    a.descricao === b.descricao
  );
};

const equalsApresentadorFoto = (a, b) => {
  if ((!a && b) || (a && !b)) {
    return false;
  }

  if (!a && !b) {
    return true;
  }

  return (
    a.nome === b.nome &&
    a.tamanho === b.tamanho &&
    a.tipo === b.tipo &&
    a.conteudo === b.conteudo
  );
};

export default {
  name: "EditableApresentadorModal",
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      required: true
    },

    value: Number
  },
  data() {
    return {
      loading: false,
      disabled: false,
      apresentadorModal: {
        id: this.id,
        hasFixedFooter: true,
        modalOptions: {
          onOpenStart: this.onModalOpen,
          dismissible: false
        }
      },
      form: {
        foto: {
          id: `${this.id}-apresentador-foto`,
          name: `${this.id}-apresentador-foto`,
          placeholder: "Selecione uma imagem para a foto",
          multiple: false,
          validate: true,
          accept: "image/*",
          size: 1024 * 1024,
          buttonContent: "Imagem",
          gridExpression: "col",
          value: null
        },
        nome: {
          id: `${this.id}-apresentador-nome`,
          name: `${this.id}-apresentador-nome`,
          required: true,
          label: "Nome",
          value: null
        },
        nomeSocial: {
          id: `${this.id}-apresentador-nomesocial`,
          name: `${this.id}-apresentador-nomesocial`,
          label: "Nome social",
          value: null
        },
        descricao: {
          id: `${this.id}-apresentador-descricao`,
          name: `${this.id}-apresentador-descricao`,
          label: "Descrição",
          value: null
        }
      },
      fotoTmp: null,
      thumbnail: {
        src: null,
        alt: null,
        title: null
      },
      apresentadorOriginal: null
    };
  },
  computed: {
    isCreate() {
      return !this.apresentadorOriginal || !this.apresentadorOriginal.id;
    },
    isGestorEstandes() {
      return this.$authorization.hasPermission(PermissaoService.GESTAO_ESTANDE);
    },
    isReadonly() {
      return !this.isGestorEstandes;
    }
  },
  watch: {
    "form.foto.value": {
      immediate: true,

      handler(newValue) {
        const { nome } = this.form;

        if (newValue) {
          this.thumbnail.src = newValue.conteudo;
          this.thumbnail.alt = nome.value;
          this.thumbnail.title = nome.value;
          this.fotoTmp = newValue;
        } else {
          this.thumbnail.src = NOPIC_IMG_SRC;
          this.thumbnail.alt = "Sem foto";
          this.thumbnail.title = "Sem foto";
          this.fotoTmp = null;
        }
      }
    }
  },
  methods: {
    onModalOpen() {
      const { nome, nomeSocial, descricao, foto } = this.form;

      this.disabled = false;

      if (!this.value) {
        nome.value = null;
        nomeSocial.value = null;
        descricao.value = null;
        foto.value = null;

        this.apresentadorOriginal = null;

        const { fileInput } = this.$refs;

        // TODO: melhorar
        if (fileInput) {
          fileInput.clear();
        }
      } else {
        this.getApresentador(this.value);
      }
    },
    getApresentador() {
      const idApresentador = this.value;

      this.loading = true;

      let apresentador = null;

      ApresentadorService.get(idApresentador)
        .then(response => {
          apresentador = response.data;

          return ApresentadorService.getFoto(idApresentador, {
            validateStatus: status =>
              this.$http.isSuccessfulStatus(status) ||
              status === this.$http.HTTP_STATUS_CLIENT_ERROR_NOT_FOUND
          });
        })
        .then(response => {
          const { nome, nomeSocial, descricao, foto } = this.form;

          apresentador.foto = response.data;

          nome.value = apresentador.nome;
          nomeSocial.value = apresentador.nomeSocial;
          descricao.value = apresentador.descricao;
          foto.value = apresentador.foto;

          this.apresentadorOriginal = this.$utils.Object.copy(apresentador);
        })
        .catch(error =>
          this.$notification.pushError(
            `Houve um erro ao obter os dados do apresentador: ${error.message}`
          )
        )
        .finally(() => (this.loading = false));
    },
    onFileInput(event) {
      if (event) {
        const { nome } = this.form;
        const file = event[0];
        const fileReader = new FileReader();

        fileReader.onerror = () => {
          this.$notification.pushError(
            `Houve um erro ao carregar imagem: ${fileReader.error}`
          );
        };

        fileReader.onload = event => {
          this.thumbnail.src = event.target.result;
          this.thumbnail.alt = nome.value;
          this.thumbnail.title = nome.value;
          this.fotoTmp = {
            nome: file.name,
            tamanho: file.size,
            conteudo: event.target.result,
            tipo: file.type
          };
        };

        fileReader.readAsDataURL(file);
      } else {
        this.thumbnail.src = NOPIC_IMG_SRC;
        this.thumbnail.alt = "Sem foto";
        this.thumbnail.title = "Sem foto";
        this.fotoTmp = null;
      }
    },
    save() {
      if (this.isReadonly) {
        return;
      }

      const { nome, nomeSocial, descricao } = this.form;

      const apresentador = {
        nome: nome.value,
        nomeSocial: nomeSocial.value,
        descricao: descricao.value,
        foto: this.fotoTmp
      };

      const errors = ApresentadorService.validate(apresentador);

      if (errors.length) {
        errors.forEach(item => this.$notification.pushError(item.message));
        return;
      }

      this.disabled = true;

      let promise = null;

      if (this.isCreate) {
        promise = this.createApresentador(apresentador);
      } else {
        promise = this.updateApresentador(apresentador);
      }

      promise.finally(() => (this.disabled = false));
    },
    createApresentador(apresentador) {
      return ApresentadorService.create(apresentador)
        .then(response => {
          this.$notification.pushSuccess("Apresentador cadastrado com sucesso");

          const newApresentador = response.data;
          newApresentador.foto = apresentador.foto
            ? { ...apresentador.foto }
            : null;

          this.$emit("create", newApresentador);

          this.$utils.Components.closeModal(this.id);
        })
        .catch(error =>
          this.$notification.pushError(
            `Houve um erro ao cadastrar o apresentador: ${error.message}`
          )
        );
    },
    updateApresentador(apresentador) {
      const idApresentador = this.value;

      const equalApresentadores = equalsApresentador(
        apresentador,
        this.apresentadorOriginal
      );
      const equalFotos = equalsApresentadorFoto(
        apresentador.foto,
        this.apresentadorOriginal.foto
      );

      const newApresentador = { id: idApresentador, ...apresentador };

      let hadError = false;
      let promises = [];

      if (!equalFotos && !apresentador.foto) {
        promises.push(
          ApresentadorService.deleteFoto(idApresentador)
            .then(() => {
              this.$notification.pushSuccess(
                "Foto do apresentador excluída com sucesso"
              );

              delete newApresentador.foto;

              this.$emit("update", newApresentador);
            })
            .catch(error => {
              this.$notification.pushError(
                `Houve um erro ao excluir a foto do apresentador: ${error.message}`
              );

              hadError = true;
            })
        );
      } else if (equalFotos) {
        delete apresentador.foto;
      }

      if (!equalApresentadores || !equalFotos) {
        promises.push(
          ApresentadorService.update(idApresentador, apresentador)
            .then(() => {
              this.$notification.pushSuccess(
                "Apresentador atualizado com sucesso"
              );

              this.$emit("update", newApresentador);
            })
            .catch(error => {
              this.$notification.pushError(
                `Houve um erro ao atualizar o apresentador: ${error.message}`
              );

              hadError = true;
            })
        );
      }

      if (promises.length) {
        return Promise.allSettled(promises).then(() => {
          if (!hadError) {
            this.$utils.Components.closeModal(this.id);
          }
        });
      }

      return Promise.resolve();
    }
  }
};
</script>

<style scoped>
.foto-wrapper .image-container {
  flex: 0 1 auto;
  height: 175px;
}
.foto-wrapper .image-container img.responsive-img {
  max-height: 100%;
}
.foto-wrapper .input-container {
  flex: 1 1 auto;
}

@media screen and (max-width: 600px) {
  .foto-wrapper {
    flex-direction: column;
  }
  .foto-wrapper .input-container {
    min-width: 100%;
  }
}
</style>
