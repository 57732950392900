/**
 * @module plugins/Notification
 * @category Plug-ins
 * @summary Módulo de importação do _plug-in_ de notificações.
 *
 * @description
 * Expõe o _plug-in_ de notificações para utilização pelo container e pelos
 * módulos.
 *
 * Ao chamar o método `use` do objeto `Vue` global, torna-se possível
 * acessar o _plug-in_ dentro de componentes através da propriedade
 * `$notification`.
 *
 * @example <caption>Importando o plug-in de notificações como membro default do módulo</caption>
 * import Notification from "./plugins/Notification";
 *
 * Vue.use(Notification);
 *
 * @example <caption>Importando o plug-in de notificações como membro do módulo</caption>
 * import { Notification } from "./plugins/Notification";
 *
 * Vue.use(Notification);
 */
import { default as Notification } from "./Notification";

/**
 * Associa este _plug-in_ à instância do {@link external:Vue Vue}, atribuindo a
 * ela a propriedade `$notification`, tornando-se acessível pelos componentes.
 * Carrega, automaticamente, as folhas de estilo deste _plug-in_.
 * @param {external:Vue} Vue - Instância do {@link external:Vue Vue}.
 * @param {object} options - Objeto com as opções deste _plug-in_.
 */
const install = function(Vue, options = {}) {
  Notification.options = Object.assign({}, Notification.options, options);

  Object.defineProperty(Vue.prototype, "$notification", {
    get() {
      return Notification;
    }
  });

  Notification.loadCSSStyleSheet();
};

// Atribui a função install ao objeto do _plug-in_.
Notification.install = install;

export default install;

export { Notification };
