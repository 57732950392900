<template>
  <BaseModal v-bind="editoresModal">
    <template v-slot:header>
      <header>
        <h3 class="pae-title">Editores de "{{ nomeEstande }}"</h3>
      </header>
    </template>

    <BaseInfoCard title="Adição de editores">
      <p>
        Para adicionar um ou mais editores, i.e., usuários responsáveis pela
        edição do estande, primeiramente, realize uma busca pelas pessoas
        responsáveis por esses usuários. Em seguida, clique no botão de adição
        correspondente ao usuário a ser adicionado como editor do estande. Para
        remover um editor da lista, basta clicar no ícone de lixeira
        correspondente. Por fim, clique no botão "Salvar" para efetuar as
        alterações.
      </p>
    </BaseInfoCard>

    <div class="row-fix row">
      <BaseTextInput v-bind="form.nome" v-model="form.nome.value" />
      <BaseTextInput v-bind="form.cpf" v-model="form.cpf.value" />
      <BaseTextInput v-bind="form.passaporte" v-model="form.passaporte.value" />

      <div class="col s12">
        <BaseButton
          class="right"
          v-bind="form.buttonSearch"
          v-on:click="onClickSearch"
        />
      </div>
    </div>

    <div v-if="searched">
      <BaseProgressBar v-if="loadingPessoas" v-bind="progressBar"
        >Carregando pessoas e seus usuários...</BaseProgressBar
      >
      <p v-else-if="!hasPessoas" class="flow-text center">
        Não foram encontradas pessoas (e seus usuários) para os parâmetros
        informados.
      </p>
      <div v-else class="usuarios-list">
        <BaseCollection>
          <template v-slot:items>
            <li
              v-for="(item, index) in pessoaCollection"
              v-bind:key="index"
              class="collection-item"
            >
              <BaseBuscaPessoaItem v-bind="item" v-on="listenersPessoa" />
            </li>
          </template>
        </BaseCollection>

        <BasePagination
          class="center"
          v-bind="pagination"
          v-on:change="onPageChange"
        />
      </div>
    </div>

    <section>
      <h4 class="pae-title">Editores adicionados</h4>

      <BaseProgressBar v-if="loadingEditores" v-bind="progressBar" />
      <p v-else-if="!hasEditores" class="flow-text center">
        Nenhum editor foi adicionado.
      </p>
      <BaseCollection v-else-if="hasEditores">
        <template v-slot:items>
          <li
            v-for="(item, index) in editorCollection"
            v-bind:key="index"
            class="collection-item"
          >
            <BaseUsuario v-bind="item" v-on="listenersEditor" />
          </li>
        </template>
      </BaseCollection>
    </section>

    <template v-slot:footer>
      <BaseButton
        v-bind:disabled="saving"
        class="modal-close"
        material-type="flat"
        title="Cancelar alterações de editores do estande"
        >Cancelar</BaseButton
      >
      <BaseButton
        v-bind:disabled="saving"
        material-type="flat"
        title="Salvar alterações de editores do estande"
        v-on:click="save"
        >Salvar</BaseButton
      >
    </template>
  </BaseModal>
</template>

<script>
import { EstandeService, PessoaService } from "../../services";
import BaseBuscaPessoaItem from "../ControleAcesso/BaseBuscaPessoaItem.vue";
import BaseUsuario from "../ControleAcesso/BaseUsuario.vue";

const SEARCH_PAGINATION_SIZE = 5;

export default {
  name: "EstandeEditoresModal",
  components: {
    BaseBuscaPessoaItem,
    BaseUsuario
  },
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      required: true
    },

    value: Object
  },
  data() {
    return {
      loadingPessoas: false,
      loadingEditores: false,
      saving: false,
      searched: false,
      progressBar: {
        useContainer: false
      },
      editoresModal: {
        id: this.id,
        hasFixedFooter: true,
        modalOptions: {
          onOpenStart: this.onModalOpen,
          dismissible: false
        }
      },
      pessoaCollection: [],
      editorCollection: [],
      editoresOriginais: [],
      form: {
        nome: {
          id: `${this.id}-nome`,
          label: "Nome/Nome social",
          placeholder:
            "Preencha um trecho do nome/nome social da pessoa responsável",
          gridExpression: "col s12",
          value: null
        },
        cpf: {
          id: `${this.id}-cpf`,
          label: "CPF",
          gridExpression: "col s12 m6",
          value: null
        },
        passaporte: {
          id: `${this.id}-passaporte`,
          label: "Passaporte",
          gridExpression: "col s12 m6",
          value: null
        },
        buttonSearch: {
          iconName: "search",
          content: "Buscar"
        }
      },
      pagination: {
        value: 1,
        numPages: 0
      }
    };
  },
  computed: {
    hasPessoas() {
      return !this.$utils.Lang.isEmpty(this.pessoaCollection);
    },
    hasEditores() {
      return !this.$utils.Lang.isEmpty(this.editorCollection);
    },
    nomeEstande() {
      return this.value ? this.value.nome : "";
    },
    listenersPessoa() {
      const listeners = {};

      listeners["add-editor"] = this.addEditor;

      return listeners;
    },
    listenersEditor() {
      const listeners = {};

      listeners["delete-editor"] = this.deleteEditor;

      return listeners;
    }
  },
  methods: {
    onModalOpen() {
      this.loadingPessoas = false;
      this.loadingEditores = false;
      this.saving = false;
      this.searched = false;

      const { nome, cpf, passaporte } = this.form;

      nome.value = null;
      cpf.value = null;
      passaporte.value = null;

      this.pessoaCollection = null;
      this.editorCollection = null;

      this.editoresOriginais = null;

      this.getEditores();
    },
    getEditores() {
      if (!this.value) {
        return;
      }

      this.loadingEditores = true;

      this.editoresOriginais = [];
      this.editorCollection = [];

      EstandeService.getEditores(this.value.id)
        .then(response => {
          this.editoresOriginais = response.data || [];

          this.editorCollection = this.editoresOriginais.map(item => ({
            ...item
          }));
        })
        .catch(error =>
          this.$notification.pushError(
            `Houve um erro ao obter a lista de editores do estande: ${error.message}`
          )
        )
        .finally(() => (this.loadingEditores = false));
    },
    resetPagination() {
      this.pagination.value = 1;
      this.pagination.numPages = 0;
    },
    doSearch() {
      const { nome, cpf, passaporte } = this.form;

      const params = {
        nome: nome.value,
        cpf: cpf.value,
        passaporte: passaporte.value,
        page: this.pagination.value - 1,
        size: SEARCH_PAGINATION_SIZE
      };

      this.searched = true;

      this.loadingPessoas = true;

      PessoaService.search(params)
        .then(response => {
          const pagina = response.data;

          this.pessoaCollection = pagina.elementos;

          this.pagination.numPages = pagina.totalPaginas;
        })
        .catch(error =>
          this.$notification.pushError(
            `Houve um erro ao obter a lista de pessoas e seus usuários: ${error.message}`
          )
        )
        .finally(() => (this.loadingPessoas = false));
    },
    onPageChange(event) {
      this.pagination.value = event;
      this.doSearch();
    },
    onClickSearch() {
      this.resetPagination();
      this.doSearch();
    },
    addEditor(event) {
      let usuario = null;

      const pessoas = this.pessoaCollection;

      for (let i = 0; i < pessoas.length; i++) {
        const pessoa = pessoas[i];

        usuario = pessoa.usuarios.find(item => item.id === event);

        if (usuario) {
          const index = this.editorCollection.findIndex(
            item => item.id === event
          );

          if (index === -1) {
            this.editorCollection.push({ ...usuario, pessoa });
          }
        }
      }
    },
    deleteEditor(event) {
      const index = this.editorCollection.findIndex(item => item.id === event);

      if (index >= 0) {
        this.editorCollection.splice(index, 1);
      }
    },
    getPutData() {
      const putData = [];
      const editores = this.editoresOriginais;

      this.editorCollection.forEach(item => {
        const isItemFound =
          editores.findIndex(originalItem => originalItem.id === item.id) >= 0;

        if (!isItemFound) {
          putData.push(item);
        }
      });

      return putData;
    },
    getDeleteData() {
      const deleteData = [];
      const editores = this.editorCollection;

      this.editoresOriginais.forEach(originalItem => {
        const isItemFound =
          editores.findIndex(item => originalItem.id === item.id) >= 0;

        if (!isItemFound) {
          deleteData.push(originalItem);
        }
      });

      return deleteData;
    },
    save() {
      if (!this.value) {
        return;
      }

      const putData = this.getPutData();
      const deleteData = this.getDeleteData();

      const editoresOriginais = this.editoresOriginais;

      const idEstande = this.value.id;

      const saveData = [].concat(
        putData.map(item =>
          EstandeService.addEditor(idEstande, item.id).then(() => {
            editoresOriginais.push({ ...item });

            this.$notification.pushSuccess(
              `Editor "${item.pessoa.nomeExibicao}" através do usuário "${item.login}" incluído com sucesso`
            );
          })
        ),
        deleteData.map(item =>
          EstandeService.removeEditor(idEstande, item.id).then(() => {
            const id = item.id;

            // Atualiza os registros originais cujos correspondentes foram
            // excluídos com sucesso para que, em caso de erro, os mesmos não
            // sejam reenviados ao servidor desnecessariamente.
            for (let i = 0; i < editoresOriginais.length; i++) {
              if (editoresOriginais[i].id === id) {
                editoresOriginais.splice(i, 1);
                break;
              }
            }

            this.$notification.pushSuccess(
              `Editor "${item.pessoa.nomeExibicao}" através do usuário "${item.login}" removido com sucesso`
            );
          })
        )
      );

      if (saveData.length) {
        this.saving = true;

        Promise.all(saveData)
          .then(() => this.$utils.Components.closeModal(this.id))
          .catch(error =>
            this.$notification.pushError(
              `Houve um erro ao adicionar/remover os editores: ${error.message}`
            )
          )
          .finally(() => (this.saving = false));
      }
    }
  }
};
</script>
