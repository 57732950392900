<template>
  <div v-bind:class="gridExpression">
    <div class="input-field">
      <i
        v-if="showIcon && iconName && iconPosition === 'left'"
        class="material-icons prefix"
        tabindex="0"
        v-bind:class="{ clickable: !!iconListeners.click }"
        v-bind:title="iconTitle"
        v-on="iconListeners"
        >{{ iconName }}</i
      >
      <input
        v-bind:id="id"
        type="color"
        v-bind:name="name"
        v-bind:disabled="disabled"
        v-bind:required="required"
        v-bind:class="{ 'before-prefix': iconPosition === 'right' }"
        v-bind:title="$attrs.title"
        v-bind="extraAttrs"
        v-bind:value="value"
        v-on:click.prevent="open"
      />
      <i
        v-if="showIcon && iconName && iconPosition === 'right'"
        class="material-icons prefix"
        tabindex="0"
        v-bind:class="{ clickable: !!iconListeners.click }"
        v-bind:title="iconTitle"
        v-on="iconListeners"
        >{{ iconName }}</i
      >
      <label v-bind:for="id" class="active" v-bind:class="labelClass">
        <slot>{{ label }}</slot>
      </label>
      <slot name="extra-content"></slot>
    </div>

    <div
      ref="modal"
      v-bind:id="modalOptions.id"
      class="modal colorpicker-modal"
    >
      <div class="modal-content colorpicker-container">
        <div class="colorpicker-color-display">
          <div v-if="showPalettes && color">
            <span v-show="color.name" class="colorname-text">{{
              color.name
            }}</span>
            <span class="colorcode-text">{{ color.colorCode }}</span>
          </div>
          <div v-else>
            <span class="colorcode-text">{{ form.color.value }}</span>
          </div>

          <div
            v-show="!showPalettes"
            class="hue-saturation-value-container valign-wrapper"
          >
            <div class="saturation-value-wrapper">
              <div ref="saturationValue" class="saturation-value"></div>
              <img
                class="saturation-value-gradient"
                src="./saturation_value_gradient.png"
                v-on:click="clickSaturationValue"
                v-on:dragstart.prevent
              />
              <div
                ref="saturationValueMarker"
                class="saturation-value-marker"
              ></div>
            </div>
            <div class="hue-wrapper">
              <div class="hue" v-on:click="clickHue"></div>
              <div ref="hueMarker" class="hue-marker"></div>
            </div>
          </div>
        </div>

        <div class="colorpicker-controls-container">
          <div v-show="showPalettes" class="colorpicker-palettes">
            <div class="palettes-select row">
              <BaseSelect v-bind="palettesSelect" v-on:change="changePalette" />
            </div>

            <p
              v-if="palette && !hasPaletteColors"
              class="flow-text center valign-wrapper"
            >
              A paleta de cores está vazia. Defina uma cor através dos controles
              ou escolha uma cor através de outra paleta.
            </p>
            <div v-else-if="palette" class="colorpicker-palette">
              <a
                href="#!"
                v-for="(item, index) in palette"
                v-bind:key="index"
                v-bind="item"
                class="palette-color"
                v-on:click.prevent="chooseColor(item)"
              ></a>
            </div>
          </div>

          <div v-show="!showPalettes" class="colorpicker-inputs">
            <div class="rgb-wrapper row">
              <BaseTextInput
                v-bind="form.r"
                v-on:change="changeRgb($event.target.value, 'r')"
              />
              <BaseTextInput
                v-bind="form.g"
                v-on:change="changeRgb($event.target.value, 'g')"
              />
              <BaseTextInput
                v-bind="form.b"
                v-on:change="changeRgb($event.target.value, 'b')"
              />
            </div>
            <div class="hsv-wrapper row">
              <BaseTextInput
                v-bind="form.h"
                v-on:change="changeHsv($event.target.value, 'h')"
              />
              <BaseTextInput
                v-bind="form.s"
                v-on:change="changeHsv($event.target.value, 's')"
              />
              <BaseTextInput
                v-bind="form.v"
                v-on:change="changeHsv($event.target.value, 'v')"
              />
            </div>
            <div class="cmyk-wrapper row">
              <BaseTextInput
                v-bind="form.c"
                v-on:change="changeCmyk($event.target.value, 'c')"
              />
              <BaseTextInput
                v-bind="form.m"
                v-on:change="changeCmyk($event.target.value, 'm')"
              />
              <BaseTextInput
                v-bind="form.y"
                v-on:change="changeCmyk($event.target.value, 'y')"
              />
              <BaseTextInput
                v-bind="form.k"
                v-on:change="changeCmyk($event.target.value, 'k')"
              />
            </div>

            <div class="color-wrapper row">
              <BaseTextInput
                v-bind="form.color"
                v-bind:value="formattedColor"
                v-on:change="changeColor($event.target.value)"
              />
              <div class="col s6">
                <div class="input-field">
                  <div ref="colorPreview" class="colorpicker-color"></div>
                </div>
              </div>
            </div>
          </div>

          <div class="colorpicker-footer">
            <BaseButton
              v-show="showPalettes"
              v-bind="buttonInputs"
              v-on:click="showPalettes = !showPalettes"
            />
            <BaseButton
              v-show="!showPalettes"
              v-bind="buttonPalettes"
              v-on:click="showPalettes = !showPalettes"
            />

            <div class="confirmation-btns">
              <BaseButton v-bind="buttonCancel" />
              <BaseButton v-bind="buttonSave" v-on:click="save" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @module components/base/BaseColorPicker/BaseColorPicker
 * @category Componentes-base
 * @summary _Single File Component_ (SFC) de _colorpicker.
 *
 * @description
 * Componente de _colorpicker_ baseado nos outros _pickers_ do {@link external:Materialize Materialize}
 * e utilizando elementos visuais do _colorpicker_ do {@link https://duckduckgo.com/?q=color+picker DuckDuckGo}.
 *
 * Atributos HTML de acessibilidade, e.g., `title`, podem ser usados
 * diretamente no componente.
 *
 * @requires module:constants.DEFAULT_COLOR
 * @requires module:constants.DEFAULT_LUMINOSITY
 * @requires module:utils/lang.isEmpty
 * @requires module:utils/lang.isFunction
 * @requires module:utils/components.toOptionElement
 * @requires module:utils/object.uuid
 * @requires module:utils/web.createStyleElement
 * @requires module:utils/web.getMaterializeColorCode
 * @requires module:utils/web.cmyk
 * @requires module:utils/web.cmykToRgb
 * @requires module:utils/web.hsv
 * @requires module:utils/web.hsvToRgb
 * @requires module:utils/web/Storage/Local~Local
 *
 * @vue-prop {string} [gridExpression="col s12"] - Classes CSS de grid do {@link external:Materialize Materialize}.
 * @vue-prop {string} [id] - Atributo `id` do elemento HTML utilizado
 * internamente pelo componente.
 * @vue-prop {string} [name] - Atributo `name` do elemento HTML utilizado
 * internamente pelo componente.
 * @vue-prop {boolean} [disabled=false] - Indica se o componente estará desabilitado.
 * @vue-prop {boolean} [required=false] - Indica se o componente possui
 * preenchimento obrigatório.
 * @vue-prop {string} [label] - Conteúdo do _label_ do elemento HTML `input`
 * utilizado internamente. É usado como substituto quando nenhum dado é passado
 * através do _slot_ _default_.
 * @vue-prop {string|Array|object} [labelClass] - Classes CSS para o elemento
 * HTML `label` utilizado internamente pelo componente.
 * @vue-prop {boolean} [showIcon=true] - Indica se o componente deve exibir o
 * ícone especificado.
 * @vue-prop {string} [iconName] - Nome do [ícone do Material Design]{@link external:MaterialDesignIcons}.
 * @vue-prop {string} [iconPosition="left"] - Posição em que o ícone será
 * localizado. Aceita "left" ou "right".
 * @vue-prop {string} [iconTitle] - Contéudo para o atributo `title` do ícone.
 * @vue-prop {function} [iconClick] - Função para ser chamada após evento de
 * click do ícone.
 * @vue-prop {object} [extraAttrs] - Atributos extras a serem usados no
 * elemento HTML utilizado internamente pelo componente.
 * @vue-prop {Array<object>} [palettes=[]] - _Array_ de paletas de cores.
 * @vue-prop {string} [palettes[].id] - Identificador da paleta.
 * @vue-prop {string} [palettes[].name] - Nome da paleta.
 * @vue-prop {Array<object>} [palettes[].colors] - _Array_ de cores da paleta.
 * @vue-prop {string} [palettes[].colors[].name] - Nome da cor.
 * @vue-prop {string} [palettes[].colors[].colorCode] - Código hexadecimal
 * (iniciando com "#") da cor.
 * @vue-prop {object} [pickerOptions] - Opções aceitas pelo _colorpicker_.
 * @vue-prop {boolean} [pickerOptions.autoClose=false] - Automaticamente fecha
 * o _colorpicker_ quando uma cor é selecionada de uma paleta.
 * @vue-prop {function} [pickerOptions.onOpen=null] - Função de _callback_
 * quando o _colorpicker_ é aberto.
 * @vue-prop {function} [pickerOptions.onClose=null] - Função de _callback_
 * quando o _colorpicker_ é fechado.
 * @vue-prop {string} [value] - Valor inicial do componente.
 * @vue-event {string} onInput - Emite o valor atual do componente. Pode ser
 * capturado pela diretiva `v-on:input` (ou `v-model`) do {@link external:Vue Vue}.
 * @vue-event {string} onChange - Emite o valor atual do componente. Pode ser
 * capturado pela diretiva `v-on:change` do {@link external:Vue Vue}.
 *
 * @example
 * <BaseColorPicker
 *  id="cor-evento"
 *  name="cor-evento"
 *  grid-expression="col s12 m6"
 *  v-bind:value="evento.cor"
 *  v-on:change="evento.cor = $event">
 *  Cor temática do evento
 * </BaseColorPicker>
 */
import { DEFAULT_COLOR, DEFAULT_LUMINOSITY } from "../../../constants";
import { toOptionElement } from "../../../utils/components";
import { isEmpty, isFunction } from "../../../utils/lang";
import { uuid } from "../../../utils/object";
import {
  createStyleElement,
  getMaterializeColorCode,
  cmyk,
  hsv,
  hsvToRgb,
  cmykToRgb
} from "../../../utils/web";
import { Local } from "../../../utils/web/Storage";

const DEFAULT_PALETTES = require("./palettes.json");
const STORAGE_KEY_PREFIX = "components/base/BaseColorPicker";
const RECENT_LIMIT = 20;

const DEFAULT_OPTIONS = {
  autoClose: false,
  onOpen: null,
  onClose: null
};

const isRgb = function (value) {
  return /^#[A-Fa-f0-9]{6}$/.test(value);
};

const toHex = function (value) {
  if (!value) {
    return null;
  }

  const r = value.r.toString(16).padStart(2, "0");
  const g = value.g.toString(16).padStart(2, "0");
  const b = value.b.toString(16).padStart(2, "0");

  return `#${r}${g}${b}`;
};

const updateSaturationValueMarker = function (vm, value) {
  const { s, v } = value; //hsv(value);
  const { style } = vm.$refs.saturationValueMarker;

  style.top = `${Math.round((1 - v) * 256) - 3}px`;
  style.left = `${Math.round(s * 256) - 3}px`;
};

const updateHueMarker = function (vm, value) {
  const { h } = value; //hsv(value);
  const { style } = vm.$refs.hueMarker;

  style.top = `${(h * 256) / 360 - 4}px`;
  style.left = "-1px";
};

const updateBackground = function (vm, value) {
  const { h, s, v } = value; //hsv(value);
  const { saturationValue, colorPreview } = vm.$refs;

  saturationValue.style.backgroundColor = toHex(hsvToRgb(h, 1, 1));
  colorPreview.style.backgroundColor = toHex(hsvToRgb(h, s, v));
};

const updateInputs = function (vm, value) {
  const { r, g, b, h, s, v, c, m, y, k, color } = vm.form;

  const rgbValue = hsvToRgb(value.h, value.s, value.v);
  const hsvValue = value; //hsv(value);
  const cmykValue = cmyk(toHex(rgbValue));

  r.value = rgbValue.r.toString();
  g.value = rgbValue.g.toString();
  b.value = rgbValue.b.toString();

  h.value = Math.round(hsvValue.h).toString();
  s.value = Math.round(hsvValue.s * 100).toString();
  v.value = Math.round(hsvValue.v * 100).toString();

  c.value = Math.round(cmykValue.c * 100).toString();
  m.value = Math.round(cmykValue.m * 100).toString();
  y.value = Math.round(cmykValue.y * 100).toString();
  k.value = Math.round(cmykValue.k * 100).toString();

  color.value = toHex(rgbValue);
};

const updateAll = function (vm, value) {
  updateInputs(vm, value);
  updateBackground(vm, value);
  updateHueMarker(vm, value);
  updateSaturationValueMarker(vm, value);
};

const toPaletteItem = function (item) {
  const style = `background-color: ${item.colorCode}`;

  let title = null;

  if (item.name) {
    title = `${item.name} (${item.colorCode})`;
  } else {
    title = item.colorCode;
  }

  return { ...item, style, title };
};

const getRecentPalette = function () {
  const key = `${STORAGE_KEY_PREFIX}/palettes/recent`;

  const colors = JSON.parse(Local.getOrSet(key, "[]")).slice(0, RECENT_LIMIT);

  return { id: "recent", name: "Recentes", colors };
};

const updateRecentPalette = function (vm, value) {
  let { colors } = getRecentPalette();

  let index = colors.findIndex(item => item.colorCode === value);

  if (index === -1) {
    colors.unshift(value);

    const key = `${STORAGE_KEY_PREFIX}/palettes/recent`;

    Local.set(key, colors.slice(0, RECENT_LIMIT));
  }

  const recent = vm.usedPalettes.find(item => item.id === "recent");

  if (recent) {
    colors = recent.colors;

    index = colors.findIndex(item => item.colorCode === value);

    if (index === -1) {
      colors.unshift(value);

      recent.colors = colors.slice(0, RECENT_LIMIT);
    }
  }
};

export default {
  name: "BaseColorPicker",
  inheritAttrs: false,
  props: {
    gridExpression: {
      type: String,
      default: "col s12"
    },

    id: String,
    name: String,

    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },

    label: String,
    labelClass: [String, Array, Object],

    showIcon: {
      type: Boolean,
      default: true
    },

    iconName: String,

    iconPosition: {
      type: String,
      default: "left",
      validator: value => ["left", "right"].indexOf(value) !== -1
    },

    iconTitle: String,
    iconClick: Function,

    extraAttrs: Object,

    palettes: {
      type: Array,
      default: () => []
    },

    pickerOptions: Object,
    value: String
  },
  data() {
    return {
      showPalettes: true,
      modalInstance: null,
      options: null,
      modalOptions: {
        id: uuid()
      },
      buttonPalettes: {
        class: "colorpicker-viewpalettes",
        materialType: "flat",
        content: "Paletas"
      },
      buttonInputs: {
        class: "colorpicker-viewinputs",
        materialType: "flat",
        content: "Controles"
      },
      buttonCancel: {
        class: "modal-close colorpicker-cancel",
        materialType: "flat",
        content: "Cancelar"
      },
      buttonSave: {
        class: "colorpicker-done",
        materialType: "flat",
        content: "OK"
      },
      form: {
        r: {
          id: null,
          label: "R",
          maxlength: 3,
          showIcon: false,
          gridExpression: "col s3",
          value: null
        },
        g: {
          id: null,
          label: "G",
          maxlength: 3,
          showIcon: false,
          gridExpression: "col s3",
          value: null
        },
        b: {
          id: null,
          label: "B",
          maxlength: 3,
          showIcon: false,
          gridExpression: "col s3",
          value: null
        },
        h: {
          id: null,
          label: "H",
          maxlength: 3,
          showIcon: false,
          gridExpression: "col s3",
          value: null
        },
        s: {
          id: null,
          label: "S",
          maxlength: 3,
          showIcon: false,
          gridExpression: "col s3",
          value: null
        },
        v: {
          id: null,
          label: "V",
          maxlength: 3,
          showIcon: false,
          gridExpression: "col s3",
          value: null
        },
        c: {
          id: null,
          label: "C",
          maxlength: 3,
          showIcon: false,
          gridExpression: "col s3",
          value: null
        },
        m: {
          id: null,
          label: "M",
          maxlength: 3,
          showIcon: false,
          gridExpression: "col s3",
          value: null
        },
        y: {
          id: null,
          label: "Y",
          maxlength: 3,
          showIcon: false,
          gridExpression: "col s3",
          value: null
        },
        k: {
          id: null,
          label: "K",
          maxlength: 3,
          showIcon: false,
          gridExpression: "col s3",
          value: null
        },
        color: {
          id: null,
          label: "#",
          maxlength: 6,
          showIcon: false,
          gridExpression: "col s6",
          value: null
        }
      },
      palettesSelect: {
        id: null,
        optionElements: [],
        title: "Paleta",
        value: null
      },
      usedPalettes: [],
      palette: [],
      color: null
    };
  },
  computed: {
    hasPaletteColors() {
      return !isEmpty(this.palette);
    },
    iconListeners() {
      const listeners = {};

      if (isFunction(this.iconClick)) {
        listeners.click = this.iconClick;

        listeners.keydown = this.iconClickByKey;
      }

      return listeners;
    },
    formattedColor() {
      const { value } = this.form.color;

      if (isRgb(value)) {
        return value.substring(1);
      }

      return value;
    }
  },
  beforeCreate() {
    if (!window.M) {
      window.console.error(
        "Objeto do Materialize não encontrado. Verifique se essa biblioteca foi carregada corretamente"
      );
    }
  },
  created() {
    this.overrideMaterializeStyle();

    const recent = getRecentPalette();

    this.usedPalettes = (this.palettes || [])
      .concat(DEFAULT_PALETTES)
      .concat(recent);

    this.palettesSelect.optionElements = this.usedPalettes.map(item =>
      toOptionElement(item, "id", "name")
    );
  },
  mounted() {
    if (!window.M) {
      return;
    }

    this.options = Object.assign({}, DEFAULT_OPTIONS, this.pickerOptions);

    const { modal } = this.$refs;

    if (modal) {
      const modalOptions = Object.assign({}, this.modalOptions);

      modalOptions.onCloseStart = () => {
        if (typeof this.options.onClose === "function") {
          this.options.onClose();
        }
      };

      this.modalInstance = window.M.Modal.init(modal, modalOptions);
    }
  },
  beforeDestroy() {
    if (this.modalInstance) {
      this.modalInstance.destroy();
    }
  },
  methods: {
    overrideMaterializeStyle() {
      const colorCode = getMaterializeColorCode(
        DEFAULT_COLOR,
        DEFAULT_LUMINOSITY
      );

      if (colorCode) {
        const CSSStyleSheet = `
          input[type="color"]:not(.browser-default):focus:not([disabled]) {
            border: 1px solid ${colorCode};
            box-shadow: 1px 1px 0 0 ${colorCode}; /* A regra inteira foi reescrita, pois ainda não existe uma regra do tipo \`box-shadow-color\` */
          }

          input[type="color"]:not(.browser-default):focus:not([disabled]) + label {
            color: ${colorCode};
          }

          .input-field .prefix.active {
            color: ${colorCode};
          }

          .colorpicker-color-display {
            background-color: ${colorCode};
          }

          .colorpicker-viewpalettes,
          .colorpicker-viewinputs,
          .colorpicker-cancel,
          .colorpicker-done {
            color: ${colorCode};
          }
        `;

        createStyleElement({
          id: "stylesheet-colorpicker",
          type: "text/css",
          innerHTML: CSSStyleSheet
        });
      }
    },
    reset() {
      this.showPalettes = true;
      this.palette = null;

      this.resetColor();

      this.changePalette("recent");

      this.resetForm();
    },
    resetColor() {
      this.color = null;
    },
    resetForm() {
      const value = hsv(this.value || "#000000");

      updateAll(this, value);
    },
    iconClickByKey($event) {
      if (["Enter", " ", "Spacebar"].indexOf($event.key) !== -1) {
        if (isFunction(this.iconClick)) {
          this.iconClick();
        }
      }
    },
    open() {
      this.reset();

      if (typeof this.options.onOpen === "function") {
        this.options.onOpen();
      }

      this.modalInstance.open();
    },
    close() {
      this.modalInstance.close();
    },
    changePalette(event) {
      this.palettesSelect.value = event;

      const palette = this.usedPalettes.find(item => item.id === event);

      if (palette) {
        this.palette = palette.colors.map(item => toPaletteItem(item));
      }
    },
    chooseColor(event) {
      this.color = event;

      const value = hsv(event.colorCode);

      updateAll(this, value);

      if (this.options.autoClose) {
        this.close();
      }
    },
    clickSaturationValue(event) {
      // TODO: utilizar outras propriedades do evento, já que `layerX` e `layerY` são "non-standard"
      const { layerX, layerY } = event;
      const { saturationValueMarker, hueMarker } = this.$refs;

      saturationValueMarker.style.top = `${layerY - 3}px`;
      saturationValueMarker.style.left = `${layerX - 3}px`;

      const h = ((window.parseInt(hueMarker.style.top) + 4) * 360) / 256;
      const s = layerX / 256;
      const v = (256 - layerY) / 256;

      const value = { h, s, v };

      updateInputs(this, value);
      updateBackground(this, value);

      this.resetColor();
    },
    clickHue(event) {
      // TODO: utilizar outras propriedades do evento, já que `layerY` é "non-standard"
      const { layerY } = event;
      const { saturationValueMarker, hueMarker } = this.$refs;

      hueMarker.style.top = `${layerY - 4}px`;

      const h = (layerY * 360) / 256;
      const s = (window.parseInt(saturationValueMarker.style.left) + 3) / 256;
      const v =
        (256 - window.parseInt(saturationValueMarker.style.top) + 3) / 256;

      const value = { h, s, v };

      updateInputs(this, value);
      updateBackground(this, value);

      this.resetColor();
    },
    changeRgb(event, field) {
      const f = this.form[field];

      const n = window.parseInt(event);

      if (!event || window.isNaN(n)) {
        f.value = "0";
      } else {
        if (n < 0) {
          f.value = "0";
        } else if (n > 255) {
          f.value = "255";
        } else {
          f.value = event;
        }
      }

      const { r, g, b } = this.form;

      const value = hsv(
        toHex({ r: Number(r.value), g: Number(g.value), b: Number(b.value) })
      );

      this.$nextTick(() => updateAll(this, value));

      this.resetColor();
    },
    changeHsv(event, field) {
      const f = this.form[field];

      const n = window.parseInt(event);

      if (!event || window.isNaN(n)) {
        f.value = "0";
      } else {
        if (n < 0) {
          f.value = "0";
        } else if (field === "h" && n > 360) {
          f.value = "360";
        } else if (field !== "h" && n > 100) {
          f.value = "100";
        } else {
          f.value = event;
        }
      }

      const { h, s, v } = this.form;

      const value = {
        h: Number(h.value),
        s: Number(s.value) / 100,
        v: Number(v.value) / 100
      };

      this.$nextTick(() => updateAll(this, value));

      this.resetColor();
    },
    changeCmyk(event, field) {
      const f = this.form[field];

      const n = window.parseInt(event);

      if (!event || window.isNaN(n)) {
        f.value = "0";
      } else {
        if (n < 0) {
          f.value = "0";
        } else if (n > 100) {
          f.value = "100";
        } else {
          f.value = event;
        }
      }

      const { c, m, y, k } = this.form;

      const { r, g, b } = cmykToRgb(
        Number(c.value) / 100,
        Number(m.value) / 100,
        Number(y.value) / 100,
        Number(k.value) / 100
      );

      const value = hsv(toHex({ r, g, b }));

      this.$nextTick(() => updateAll(this, value));

      this.resetColor();
    },
    changeColor(event) {
      const rgb = "#" + event;

      if (!isRgb(rgb)) {
        return;
      }

      this.form.color.value = rgb;

      const value = hsv(rgb);

      this.$nextTick(() => updateAll(this, value));

      this.resetColor();
    },
    save() {
      const { value } = this.form.color;

      this.$emit("input", value);
      this.$emit("change", value);

      updateRecentPalette(this, { colorCode: value });

      this.close();
    }
  }
};
</script>

<style scoped>
input[type="color"]:not(.browser-default) {
  background-color: transparent;
  border: none;
  border-radius: 0;
  outline: none;
  height: 3rem;
  height: calc(3rem - 8px);
  width: 100%;
  margin: 8px 0;
  padding: 0;
  box-sizing: content-box;
}
input[type="color"]:not(.browser-default):not([disabled]) {
  cursor: pointer;
}

.colorpicker-modal {
  width: 675px;
  height: 400px;
  max-height: 400px;
}

.colorpicker-container.modal-content {
  display: flex;
  flex-direction: row;
  padding: 0;
  height: 100%;
  min-width: 675px;
}

.colorpicker-color-display {
  display: flex;
  flex-direction: column;
  padding: 20px 22px;
  flex: 0 1 auto;
  color: white;
  font-weight: 500;
  justify-content: space-between;
  min-width: 350px;
}
.colorpicker-color-display .colorname-text {
  display: block;
  font-size: 1.5rem;
  line-height: 25px;
  color: rgba(255, 255, 255, 0.7);
}
.colorpicker-color-display .colorcode-text {
  display: block;
  font-size: 2.8rem;
  line-height: 47px;
  font-weight: 500;
}
.colorpicker-color-display .hue-saturation-value-container {
  flex: 1 0 auto;
  margin: 0 auto;
}

.colorpicker-color-display .saturation-value-wrapper,
.colorpicker-color-display .hue-wrapper {
  position: relative;
  border: 1px solid #9e9e9e;
  box-sizing: content-box;
}
.colorpicker-color-display .saturation-value-wrapper {
  min-width: 256px;
  height: 256px;
  cursor: crosshair;
}

.colorpicker-color-display .saturation-value,
.colorpicker-color-display .saturation-value-gradient,
.colorpicker-color-display .hue {
  position: absolute;
  width: 100%;
  height: 100%;
}

.colorpicker-color-display .saturation-value-marker,
.colorpicker-color-display .hue-marker {
  position: absolute;
  border: 1px solid white;
  outline: 1px solid black;
  box-sizing: content-box;
}
.colorpicker-color-display .saturation-value-marker {
  width: 5px;
  height: 5px;
}

.colorpicker-color-display .hue-wrapper {
  margin-left: 0.75rem;
  margin-top: 0;
  min-width: 32px;
  max-width: 32px;
  height: 256px;
}
.colorpicker-color-display .hue {
  background-image: linear-gradient(
    to bottom,
    #ff0000,
    #ffff00,
    #00ff00,
    #00ffff,
    #0000ff,
    #ff00ff,
    #ff0000
  );
}
.colorpicker-color-display .hue-marker {
  min-width: 32px;
  max-width: 32px;
  height: 7px;
}

.colorpicker-controls-container {
  flex: 2.5 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* min-height: 0; */
}

.colorpicker-palettes {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 0;
}
.colorpicker-palettes p {
  width: 290px;
  margin-left: auto;
  margin-right: auto;
  flex: 1 0 auto;
}

.palettes-select {
  margin: 0 auto;
}
.palettes-select /deep/ .input-field {
  margin: 0;
}
.palettes-select /deep/ .select-wrapper input {
  border-bottom: none;
  text-align: center;
  margin: 0;
}
.palettes-select /deep/ .select-wrapper .caret {
  display: none;
}

.colorpicker-palette {
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
}

.palette-color {
  width: 17.5%;
  height: 32px;
  border: 1px solid #9e9e9e;
  margin: 0.75rem;
}

.colorpicker-inputs {
  margin: 0 auto;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.colorpicker-inputs .row {
  margin-bottom: 0;
}

.colorpicker-color {
  height: 56px;
  border: 1px solid #9e9e9e;
}

.colorpicker-footer {
  display: flex;
  padding-bottom: 5px;
  margin: 0 auto;
  justify-content: space-between;
}

/* .colorpicker-cancel,
.colorpicker-done {
  padding: 0 1rem;
} */

.palettes-select,
.colorpicker-inputs,
.colorpicker-footer {
  width: 290px;
}
</style>
