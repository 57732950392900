<template>
  <div v-bind:class="gridExpression">
    <div class="input-field">
      <select
        v-bind:id="id"
        ref="select"
        v-bind:name="name"
        v-bind:disabled="disabled"
        v-bind:multiple="multiple"
        v-bind:required="required"
        v-bind:class="{ 'browser-default': useBrowserDefault }"
        v-on="listeners"
      >
        <option v-if="hasUnselectedOption" disabled value>Selecione</option>
        <option
          v-for="(item, index) in options"
          v-bind:key="index"
          v-bind:value="item.value"
          v-bind:disabled="item.disabled"
          v-bind:data-icon="item.iconImage"
          v-bind:class="item.iconPosition || 'left'"
          v-bind:selected="item.selected"
          v-bind:title="item.title"
        >
          {{ item.content }}
        </option>
      </select>
      <label v-bind:for="id">
        <slot>{{ label }}</slot>
      </label>
    </div>
  </div>
</template>

<script>
/**
 * @module components/base/BaseSelect/BaseSelect
 * @category Componentes-base
 * @summary _Single File Component_ (SFC) de _select_ do {@link external:Materialize Materialize}.
 *
 * @description
 * Este componente possui um elemento HTML `div` com classe `input-field` do
 * {@link external:Materialize Materialize}, e, externamente, como _wrapper_,
 * outra `div` com as configurações de responsividade definidas por
 * `gridExpression`.
 *
 * A cor de texto dos itens do _dropdown_ (do _select_) é obtida automaticamente
 * a partir da configuração do ambiente.
 *
 * O _slot_ _default_ recebe o _label_ do _select_.
 *
 * Para correto funcionamento, é necessário que o _framework_ {@link external:Materialize Materialize}
 * tenha sido importado, tornando seu objeto principal acessível globalmente.
 *
 * @requires module:constants.DEFAULT_COLOR
 * @requires module:constants.DEFAULT_LUMINOSITY
 * @requires module:utils/web.createStyleElement
 * @requires module:utils/web.getMaterializeColorCode
 *
 * @vue-prop {string} [gridExpression="col s12"] - Classes CSS do {@link external:Materialize Materialize}.
 * @vue-prop {string} [id] - Atributo `id` do elemento HTML utilizado
 * internamente pelo componente.
 * @vue-prop {string} [name] - Atributo `name` do elemento HTML utilizado
 * internamente pelo componente.
 * @vue-prop {boolean} [disabled=false] - Indica se o componente estará desabilitado.
 * @vue-prop {boolean} [required=false] - Indica se o componente possui
 * preenchimento obrigatório.
 * @vue-prop {boolean} [multiple=false] - Indica se o _select_ permitirá múltipla
 * seleção.
 * @vue-prop {boolean} [useBrowserDefault=false] - Indica se o estilo do _select_
 * será o padrão do browser, não aplicando o estilo do {@link external:Materialize Materialize}.
 * @vue-prop {boolean} [hasUnselectedOption=true] - Indica se haverá uma opção
 * em branco no _select_.
 * @vue-prop {Array<object>} [optionElements=[]] - _Array_ de opções para o
 * _select_.
 * @vue-prop {string} [optionElements[].value] - Valor do item do _select_.
 * @vue-prop {boolean} [optionElements[].disabled] - Indica se o item do
 * _select_ está desabilitado.
 * @vue-prop {string} [optionElements[].content] - Conteúdo textual do item do
 * _select_.
 * @vue-prop {string} [optionElements[].iconImage] - Caminho da imagem a ser
 * exibida como ícone em cada opção do _select_.
 * @vue-prop {string} [optionElements[].iconPosition="left"] - Posição em que
 * o ícone do item do _select_ será localizado. Aceita "left" ou "right".
 * @vue-prop {string} [optionElements[].title] - Atributo HTML de
 * acessibilidade.
 * @vue-prop {string} [label] - Conteúdo do _label_ do elemento HTML utilizado
 * iternamente pelo componente. Deve ser usado quando não é possível passar
 * dados através do _slot_ _default_.
 * @vue-prop {object} [selectOptions] - Opções aceitas pelo `select`
 * do {@link external:Materialize Materialize}.
 * @vue-prop {boolean} [value] - Valor inicial do componente e utilizado internamente
 * para a diretiva `v-model` do {@link external:Vue Vue}.
 * @vue-event {string|Array<string>} onInput - Emite o valor atual do
 * componente. Em casos de seleção múltipla, o retorno possui os valores de
 * cada seleção em um _Array_. Pode ser capturado pela diretiva `v-on:input`
 * (ou `v-model`) do {@link external:Vue Vue}.
 * @vue-event {string|Array<string>} onChange - Emite o valor atual do
 * componente. Em casos de seleção múltipla, o retorno possui os valores de
 * cada seleção em um _Array_. Pode ser capturado pela diretiva `v-on:change` do {@link external:Vue Vue}.
 *
 * @example
 * <BaseSelect
 *  id="estado-civil"
 *  name="estado-civil"
 *  grid-expression="col s12 m6"
 *  v-bind:option-elements="[{ value: 'ec-solteiro', content: 'Solteiro(a)' }, { value: 'ec-casado', content: 'Casado(a)' }, { value: 'ec-divorciado', content: 'Divorciado(a)' }]"
 *  v-model="servidor.estadoCivil">
 *  Estado civil
 * </BaseSelect>
 */
import { DEFAULT_COLOR, DEFAULT_LUMINOSITY } from "../../../constants";
import {
  createStyleElement,
  getMaterializeColorCode
} from "../../../utils/web";

export default {
  name: "BaseSelect",
  inheritAttrs: false,
  props: {
    gridExpression: {
      type: String,
      default: "col s12"
    },

    id: String,
    name: String,

    disabled: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    useBrowserDefault: {
      type: Boolean,
      default: false
    },
    hasUnselectedOption: {
      type: Boolean,
      default: true
    },
    optionElements: {
      type: Array,
      default: () => []
    },

    label: String,

    selectOptions: Object,
    value: [String, Array]
  },
  data() {
    return {
      instance: null,
      options: this.setSelected(this.optionElements, this.value)
    };
  },
  computed: {
    listeners() {
      return Object.assign({}, this.$listeners, { change: this.onChange });
    }
  },
  watch: {
    disabled() {
      // Reconstrói o componente de select quando haver mudança dinâmica das opções
      this.resetSelect();
    },
    optionElements(newOptionElements) {
      if (!newOptionElements || !this.value) {
        this.options = newOptionElements;
      } else {
        // Marca como selecionado(s) o(s) item(ns) em casos onde há mudança dinâmica das opções
        this.options = this.setSelected(newOptionElements, this.value);
      }

      // Reconstrói o componente de select quando haver mudança dinâmica das opções
      this.resetSelect();
    },
    value(newValue) {
      this.options = this.setSelected(this.optionElements, newValue);

      this.resetSelect();
    }
  },
  beforeCreate() {
    if (!window.M) {
      window.console.error(
        "Objeto do Materialize não encontrado. Verifique se essa biblioteca foi carregada corretamente"
      );
    }
  },
  created() {
    this.overrideMaterializeStyle();
  },
  mounted() {
    if (!window.M) {
      return;
    }

    this.initSelect();
  },
  beforeDestroy() {
    this.destroySelect();
  },
  methods: {
    overrideMaterializeStyle() {
      const colorCode = getMaterializeColorCode(
        DEFAULT_COLOR,
        DEFAULT_LUMINOSITY
      );

      if (colorCode) {
        let CSSStyleSheet = `
        .select-wrapper input.select-dropdown:focus {
          border-color: ${colorCode};
        }
        `;

        createStyleElement({
          id: "stylesheet-select",
          type: "text/css",
          innerHTML: CSSStyleSheet
        });

        CSSStyleSheet = `
        .dropdown-content li > a,
        .dropdown-content li > span {
          color: ${colorCode};
        }
      `;

        // Utiliza-se o mesmo `id` de stylesheet do BaseDropdown
        createStyleElement({
          id: "stylesheet-dropdown",
          type: "text/css",
          innerHTML: CSSStyleSheet
        });
      }
    },
    destroySelect() {
      if (this.instance) {
        this.instance.destroy();
      }
    },
    initSelect() {
      const { select } = this.$refs;

      if (!this.value) {
        if (this.hasUnselectedOption) {
          select.selectedIndex = 0;
        } else {
          select.selectedIndex = -1;
        }
      }

      this.instance = window.M.FormSelect.init(select, this.selectOptions);
    },
    resetSelect() {
      if (this.$refs.select) {
        this.$nextTick().then(() => {
          this.destroySelect();
          this.initSelect();
        });
      }
    },
    onChange($event) {
      let selected = null;

      if (this.instance) {
        if (this.multiple) {
          selected = this.instance.getSelectedValues();
        } else {
          selected = $event.target.value;
        }
      }

      this.$emit("input", selected);
      this.$emit("change", selected);
    },
    setSelected(items, value) {
      // Marca como selecionado(s) o(s) item(ns) em casos onde há mudança dinâmica das opções
      if (this.multiple) {
        const tmpValue = {};

        value.forEach(item => (tmpValue[item] = item));

        return items.map(item => ({
          ...item,
          selected: tmpValue[item.value] !== undefined
        }));
      } else {
        return items.map(item => ({
          ...item,
          selected: item.value === value
        }));
      }
    }
  }
};
</script>

<style scoped>
.select-wrapper + label {
  top: -28px;
}

/* TODO: alterar regras para select com seleção múltipla */
.contrast .input-field /deep/ .dropdown-content li:not(.disabled):hover,
.contrast .input-field /deep/ .dropdown-content li:not(.disabled):focus {
  background-color: white !important;
  color: black !important;
}
.contrast .input-field /deep/ .dropdown-content li:not(.disabled):hover span,
.contrast .input-field /deep/ .dropdown-content li:not(.disabled):focus span {
  color: black !important;
}
</style>
