<template>
  <section class="section">
    <component v-bind:is="tag" class="pae-title pae-header">
      <i v-if="secao.icone" class="material-icons left">{{ secao.icone }}</i
      >{{ secao.nome }}
    </component>

    <BaseInfoCard
      v-if="modeloSecao.instrucoes"
      title="Seção de conteúdos programados"
    >
      <p>{{ modeloSecao.instrucoes }}</p>
    </BaseInfoCard>

    <BaseProgressBar v-if="loading" v-bind="progressBar" />
    <p v-else-if="!hasConteudos" class="flow-text center">
      Nenhum conteúdo programado foi cadastrado.
    </p>
    <BaseEditableCollection v-else v-bind="form.conteudoCollection" />

    <div class="right-align">
      <BaseButton
        v-bind="form.buttonAdd"
        v-bind:disabled="readonly || disabled"
        style="margin-right: 20px"
        v-on:click="add"
      />
      <BaseButton
        v-bind="form.buttonSave"
        v-bind:disabled="readonly || disabled"
        v-on:click="save"
      />
    </div>
  </section>
</template>

<script>
import { isEmpty } from "../../../utils/lang";
import {
  isAfter,
  isBefore,
  isEqual,
  isISODateTime
} from "../../../utils/datetime";
import {
  EstandeService,
  ModeloService,
  PermissaoService
} from "../../../services";
import EditableSectionConteudoProgramadoItem from "./EditableSectionConteudoProgramadoItem.vue";

export default {
  name: "EditableSectionConteudosProgramados",
  inheritAttrs: false,
  props: {
    readonly: Boolean,

    tag: {
      type: String,
      default: "h3",
      validator: value =>
        ["h1", "h2", "h3", "h4", "h5", "h6"].indexOf(value) !== -1
    },
    entidadeId: {
      type: Number,
      required: true
    },
    modelo: {
      type: Object,
      required: true
    },
    secao: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      disabled: false,
      modeloSecao: ModeloService.findModeloSecao(
        this.modelo,
        this.secao.modeloSecaoId
      ),
      progressBar: {
        useContainer: false
      },
      form: {
        conteudoCollection: {
          component: EditableSectionConteudoProgramadoItem,
          items: [],
          itemOperations: [
            {
              iconName: "edit",
              color: "green",
              disabled: false,
              title: "Editar conteúdo programado",
              onClick: this.edit
            },
            {
              iconName: "delete",
              color: "red",
              disabled: false,
              title: "Excluir conteúdo programado",
              onClick: this.delete
            }
          ]
        },
        buttonAdd: {
          iconName: "more_time",
          content: "Novo",
          disabled: this.readonly
        },
        buttonSave: {
          iconName: "save",
          content: "Salvar",
          disabled: this.readonly
        }
      },
      conteudosOriginais: []
    };
  },
  computed: {
    hasConteudos() {
      return !isEmpty(this.form.conteudoCollection.items);
    },
    isGestorEstandes() {
      return this.$authorization.hasPermission(PermissaoService.GESTAO_ESTANDE);
    },
    isReadonly() {
      return !this.isGestorEstandes;
    }
  },
  created() {
    this.getConteudosProgramados();
  },
  methods: {
    getConteudosProgramados() {
      if (!this.entidadeId) {
        return;
      }

      this.loading = true;

      this.form.conteudoCollection.items = [];

      const id = this.entidadeId;

      EstandeService.getConteudosProgramados(id)
        .then(response => {
          const conteudos = response.data || [];

          this.form.conteudoCollection.items = conteudos.map(item => ({
            id: item.id,
            readonly: true,
            value: item
          }));

          this.conteudosOriginais = conteudos.map(item =>
            this.$utils.Object.copy(item)
          );
        })
        .catch(error =>
          this.$notification.pushError(
            `Houve um erro ao obter os contéudos programados: ${error.message}`
          )
        )
        .finally(() => (this.loading = false));
    },
    add() {
      if (!this.readonly) {
        this.form.conteudoCollection.items.push({
          id: null,
          readonly: this.readonly,
          value: {
            id: null,
            nome: null,
            descricao: null,
            inicio: null,
            termino: null
          }
        });
      }
    },
    edit(item, index) {
      if (!this.readonly && index >= 0) {
        const conteudo = this.form.conteudoCollection.items[index].value;

        if (conteudo.id) {
          this.$router.push({
            name: "editConteudoProgramado",
            params: {
              idEvento: this.$route.params.idEvento,
              idEstande: this.$route.params.idEstande,
              idConteudo: conteudo.id
            }
          });
        } else {
          this.$notification.pushInfo("O conteúdo ainda não foi cadastrado");
        }
      }
    },
    delete(item, index) {
      if (!this.readonly && index >= 0) {
        this.form.conteudoCollection.items.splice(index, 1);
      }
    },
    getPostData() {
      const postData = [];
      const { items } = this.form.conteudoCollection;

      items.forEach(item => {
        if (!item.readonly && !item.value.id) {
          postData.push({ ...item.value });
        }
      });

      return postData;
    },
    // getPutData() {
    //   const putData = [];
    //   const { items } = this.form.conteudoCollection;

    //   items.forEach(item => {
    //     if (item.value.id) {
    //       const obj = this.conteudosOriginais.find(
    //         originalItem => originalItem.id === item.value.id
    //       );

    //       if (obj && !equalsConteudo(obj, item.value)) {
    //         putData.push({ ...item.value });
    //       }
    //     }
    //   });

    //   return putData;
    // },
    getDeleteData() {
      const deleteData = [];
      const { items } = this.form.conteudoCollection;

      this.conteudosOriginais.forEach(originalItem => {
        const isItemFound =
          items.findIndex(item => originalItem.id === item.value.id) >= 0;

        if (!isItemFound) {
          deleteData.push({ ...originalItem });
        }
      });

      return deleteData;
    },
    validate() {
      const errors = [];

      const conteudosOriginais = this.conteudosOriginais || [];

      (this.form.conteudoCollection.items || []).forEach((item, index) => {
        const conteudoNo = index + 1;

        let isInicioValid = false;
        let isTerminoValid = false;

        const { nome, inicio, termino } = item.value;

        if (isEmpty(nome)) {
          errors.push(
            new Error(`${conteudoNo}º conteúdo possui um nome inválido`)
          );
        }

        try {
          if (!isISODateTime(inicio)) {
            errors.push(
              new Error(
                `${conteudoNo}º conteúdo possui uma data/hora de início inválida`
              )
            );
          } else {
            isInicioValid = true;
          }
        } catch (e) {
          errors.push(
            new Error(
              `${conteudoNo}º conteúdo possui uma data/hora de início inválida`
            )
          );
        }

        try {
          if (!isISODateTime(termino)) {
            errors.push(
              new Error(
                `${conteudoNo}º conteúdo possui uma data/hora de término inválida`
              )
            );
          } else {
            isTerminoValid = true;
          }
        } catch (e) {
          errors.push(
            new Error(
              `${conteudoNo}º conteúdo possui uma data/hora de término inválida`
            )
          );
        }

        try {
          if (isInicioValid && isTerminoValid) {
            const outros = conteudosOriginais.filter(
              (origItem, origIndex) => origIndex !== index
            );

            const hasInicioConflitante = outros.findIndex(
              orig =>
                isEqual(inicio, orig.inicio) ||
                (isAfter(inicio, orig.inicio) && isBefore(inicio, orig.termino))
            );

            const hasTerminoConflitante = outros.findIndex(
              orig =>
                isEqual(termino, orig.termino) ||
                (isBefore(termino, orig.termino) &&
                  isAfter(termino, orig.inicio))
            );

            if (hasInicioConflitante >= 0 || hasTerminoConflitante >= 0) {
              errors.push(
                new Error(
                  `${conteudoNo}º conteúdo possui uma horário conflitante com outro(s) conteúdo(s)`
                )
              );
            }
          }
        } catch (e) {
          // TODO: verificar se a mensagem de erro não deve ser silenciosa
          window.console.error(
            `Houve um erro ao realizar a validação do(s) conteúdo(s) programado(s): ${e.message}`
          );
        }
      });

      return errors;
    },
    isUnique() {
      const obj = {};

      this.form.conteudoCollection.items.forEach(item => {
        const key = item.value.nome;

        if (!obj[key]) {
          obj[key] = 0;
        }

        obj[key]++;
      });

      return Object.values(obj).findIndex(item => item > 1) === -1;
    },
    save() {
      if (this.readonly) {
        return;
      }

      const errors = this.validate();

      if (errors.length) {
        errors.forEach(item => this.$notification.pushError(item.message));
        return;
      }

      if (!this.isUnique()) {
        this.$notification.pushError(
          "Alguns contéudos possuem nomes repetidos"
        );
        return;
      }

      const conteudosOriginais = this.conteudosOriginais;
      const conteudoCollection = this.form.conteudoCollection.items;

      const postData = this.getPostData();
      //   const putData = this.getPutData();
      const deleteData = this.getDeleteData();

      const id = this.entidadeId;

      const saveData = [].concat(
        postData.map(item =>
          EstandeService.createConteudoProgramado(id, item).then(response => {
            const conteudo = response.data;

            // Atualiza os registros originais cujos correspondentes foram
            // criados com sucesso para que, em caso de erro, os mesmos não
            // sejam reenviados ao servidor desnecessariamente.
            if (conteudo && conteudo.id) {
              const c = { ...conteudo };

              conteudosOriginais.push(c);

              for (let i = 0; i < conteudoCollection.length; i++) {
                const cc = conteudoCollection[i].value;

                if (!cc.id && cc.nome === c.nome) {
                  cc.id = c.id;
                  cc.nome = c.nome;

                  conteudoCollection[i].readonly = true;
                  break;
                }
              }
            }

            this.$notification.pushSuccess(
              `Conteúdo "${item.nome}" cadastrado com sucesso`
            );
          })
        ),
        // putData.map(item =>
        //   this.$http.put(`${API_URL}/${item.id}`, item).then(response => {
        //     const conteudo = response.data;

        //     // Atualiza os registros originais cujos correspondentes foram
        //     // atualizados com sucesso para que, em caso de erro, os mesmos
        //     // não sejam reenviados ao servidor desnecessariamente.
        //     if (conteudo && conteudo.id) {
        //       const c = { ...conteudo };

        //       for (let i = 0; i < conteudosOriginais.length; i++) {
        //         const original = conteudosOriginais[i];

        //         if (original.id === c.id) {
        //           original.nome = c.nome;
        //           break;
        //         }
        //       }
        //     }

        //     this.$notification.pushSuccess(
        //       `Conteúdo "${item.nome}" atualizado com sucesso`
        //     );
        //   })
        // ),
        deleteData.map(item =>
          EstandeService.deleteConteudoProgramado(id, item.id).then(() => {
            const id = item.id;

            // Atualiza os registros originais cujos correspondentes foram
            // excluídos com sucesso para que, em caso de erro, os mesmos não
            // sejam reenviados ao servidor desnecessariamente.
            for (let i = 0; i < conteudosOriginais.length; i++) {
              if (conteudosOriginais[i].id === id) {
                conteudosOriginais.splice(i, 1);
                break;
              }
            }

            this.$notification.pushSuccess(
              `Conteúdo "${item.nome}" excluído com sucesso`
            );
          })
        )
      );

      if (saveData.length) {
        this.disabled = true;

        Promise.all(saveData)
          .catch(error =>
            this.$notification.pushError(
              `Houve um erro ao salvar os conteudos: ${error.message}`
            )
          )
          .finally(() => (this.disabled = false));
      }
    }
  }
};
</script>
