/**
 * @module services/TipoModelo
 * @category Serviços
 * @summary Módulo de importação do serviço de modelo.
 *
 * @description
 * Expõe o serviço de modelo do sistema para utilização interna.
 *
 * @example <caption>Importando o serviço de modelo como membro default do módulo</caption>
 * import TipoModeloService from "./services/TipoModelo";
 *
 * @example <caption>Importando o serviço de modelo como membro do módulo</caption>
 * import { TipoModeloService } from "./services/TipoModelo";
 */
import { default as TipoModeloService } from "./TipoModelo";

export default TipoModeloService;

export { TipoModeloService };
