/**
 * @module services/Status/Status
 * @category Serviços
 * @summary Módulo do serviço de _status_.
 *
 * @description
 * Expõe o objeto/_namespace_ do serviço de _status_ do sistema para
 * utilização interna.
 *
 * Para correto funcionamento, é necessário que o _plug-in_ de requisições HTTP
 * seja registrado a este serviço.
 *
 * @requires module:constants.API_URL
 * @requires module:utils/lang.isEmpty
 *
 * @example
 * import StatusService from "./services/Status/Status";
 *
 * // Registrando o _plug-in_ de requisiçõe HTTP do container.
 * StatusService.registerHttp(Http);
 *
 * // Obtendo a lista de _status_
 * StatusService.getAll()
 *  .then(response => {
 *    // ...
 *  })
 *  .catch(error => {
 *    // ...
 *  })
 */
import { API_URL } from "../../constants";

/**
 * @namespace StatusService
 * @category Serviços
 * @summary Objeto/_namespace_ do serviço de _status_.
 */
const StatusService = {
  /**
   * _Status_ "Criado".
   * @type {number}
   * @private
   * @readonly
   */
  CRIADO: 1,

  /**
   * _Status_ "Em edição".
   * @type {number}
   * @private
   * @readonly
   */
  EM_EDICAO: 2,

  /**
   * _Status_ "Publicado".
   * @type {number}
   * @private
   * @readonly
   */
  PUBLICADO: 3,

  /**
   * URL de acesso à API para obtenção de _status_.
   * @type {string}
   * @private
   * @readonly
   */
  baseUrl: `${API_URL}/status`,

  /**
   * _Plug-in_ de requisições HTTP.
   * @type {module:plugins/Http/Http}
   * @private
   * @readonly
   */
  http: null,

  /**
   * Envia uma requisição para obter todos os _status_, retornando uma Promise.
   * @returns {external:Promise}
   */
  getAll() {
    return this.http.get(`${this.baseUrl}/`);
  },

  /**
   * Envia uma requisição para obter um _status_, retornando uma Promise.
   * @param {number} id - Identificador do _status_.
   * @returns {external:Promise}
   */
  get(id) {
    return this.http.get(`${this.baseUrl}/${id}`);
  },

  /**
   * Registra o _plug-in_ de requisições HTTP no _namespace_ do serviço.
   * @param {module:plugins/Http/Http} http - _Plug-in_ de requisições HTTP.
   * @returns {module:services/Status/Status~StatusService} O próprio namespace.
   */
  registerHttp(http) {
    this.http = http;
    return this;
  }
};

export default StatusService;
