<template>
  <BaseModal v-bind="conteudoModal">
    <template v-slot:header
      ><h3 class="pae-title">{{ modalTitle }}</h3></template
    >

    <BaseProgressBar v-if="loading" v-bind="progressBar" />
    <div v-else-if="hasConteudo">
      <p class="flow-text justify-align">Horário: {{ horario }}</p>

      <p class="flow-text justify-align descricao">{{ conteudo.descricao }}</p>

      <SectionApresentadores v-bind:apresentadores="conteudo.apresentadores" />

      <SectionConteudoMidiaExterna v-bind:value="conteudo.url" />
    </div>

    <template v-slot:footer>
      <BaseButton class="modal-close" material-type="flat">Fechar</BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import { toBrazilianDate, toISOTime } from "../../../utils/datetime";
import { EstandeService } from "../../../services";
import SectionApresentadores from "./SectionApresentadores.vue";
import SectionConteudoMidiaExterna from "./SectionConteudoMidiaExterna.vue";

export default {
  name: "SectionConteudoProgramadoModal",
  components: {
    SectionApresentadores,
    SectionConteudoMidiaExterna
  },
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      required: true
    },
    entidadeId: {
      type: Number,
      required: true
    },

    value: Number
  },
  data() {
    return {
      loading: false,
      conteudoModal: {
        id: this.id,
        hasFixedFooter: true,
        modalOptions: {
          onOpenStart: this.getConteudo
        }
      },
      progressBar: {
        useContainer: false
      },
      conteudo: null
    };
  },
  computed: {
    hasConteudo() {
      return this.conteudo;
    },
    modalTitle() {
      return this.conteudo ? this.conteudo.nome : "";
    },
    horario() {
      if (!this.hasConteudo) {
        return null;
      }

      const { inicio, termino } = this.conteudo;

      const inicioDate = toBrazilianDate(inicio);
      const terminoDate = toBrazilianDate(termino);

      const inicioHora = toISOTime(inicio).substr(0, 5);
      const terminoHora = toISOTime(termino).substr(0, 5);

      if (inicioDate === terminoDate) {
        return `${inicioDate}, de ${inicioHora} às ${terminoHora}`;
      }

      return `de ${inicioDate}, ${inicioHora} à ${terminoDate}, ${terminoHora}`;
    }
  },
  methods: {
    getConteudo() {
      if (!this.entidadeId) {
        return;
      }

      const idEstande = this.entidadeId;
      const idConteudo = this.value;

      this.loading = true;

      this.conteudo = null;

      EstandeService.getConteudoProgramado(idEstande, idConteudo)
        .then(response => {
          this.conteudo = response.data;

          return EstandeService.getApresentadoresOfConteudoProgramado(
            idEstande,
            idConteudo
          );
        })
        .then(response => (this.conteudo.apresentadores = response.data || []))
        .catch(error =>
          this.$notification.pushError(
            `Houve um erro ao obter os dados do conteúdo: ${error.message}`
          )
        )
        .finally(() => (this.loading = false));
    }
  }
};
</script>

<style scoped>
.descricao {
  white-space: pre-wrap;
}
</style>
