/**
 * @module services/Log
 * @category Serviços
 * @summary Módulo de importação do serviço de _log_.
 *
 * @description
 * Expõe o serviço de _log_ do sistema para utilização interna.
 *
 * @example <caption>Importando o serviço de _log_ como membro default do módulo</caption>
 * import LogService from "./services/Log";
 *
 * @example <caption>Importando o serviço de _log_ como membro do módulo</caption>
 * import { LogService } from "./services/Log";
 */
import { default as LogService } from "./Log";

export default LogService;

export { LogService };
